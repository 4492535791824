// Actions
// ========================================================
export const CLEAR_FORM = 'go4ellis/CreateEvent/CLEAR_FORM';
export const INIT_FORM = 'go4ellis/CreateEvent/INIT_FORM';
export const VALIDATE_FORM = 'go4ellis/CreateEvent/VALIDATE_FORM';

export const CREATE_EVENT = 'go4ellis/CreateEvent/CREATE_EVENT';
export const CREATE_EVENT_SUCCESS = 'go4ellis/CreateEvent/CREATE_EVENT_SUCCESS';
export const CREATE_EVENT_ERROR = 'go4ellis/CreateEvent/CREATE_EVENT_ERROR';

export const SAVE_AS_DRAFT = 'go4ellis/CreateEvent/SAVE_AS_DRAFT';
export const SAVE_AS_DRAFT_SUCCESS = 'go4ellis/CreateEvent/SAVE_AS_DRAFT_SUCCESS';
export const SAVE_AS_DRAFT_ERROR = 'go4ellis/CreateEvent/SAVE_AS_DRAFT_ERROR';

export const UPDATE_EVENT = 'go4ellis/CreateEvent/UPDATE_EVENT';
export const UPDATE_EVENT_SUCCESS = 'go4ellis/CreateEvent/UPDATE_EVENT_SUCCESS';
export const UPDATE_EVENT_ERROR = 'go4ellis/CreateEvent/UPDATE_EVENT_ERROR';

export const AUTO_UPDATE_EVENT = 'go4ellis/CreateEvent/AUTO_UPDATE_EVENT';
export const AUTO_UPDATE_EVENT_SUCCESS = 'go4ellis/CreateEvent/AUTO_UPDATE_EVENT_SUCCESS';
export const AUTO_UPDATE_EVENT_ERROR = 'go4ellis/CreateEvent/AUTO_UPDATE_EVENT_ERROR';

export const CANCEL_EVENT = 'go4ellis/CreateEvent/CANCEL_EVENT';
export const CANCEL_EVENT_SUCCESS = 'go4ellis/CreateEvent/CANCEL_EVENT_SUCCESS';
export const CANCEL_EVENT_ERROR = 'go4ellis/CreateEvent/CANCEL_EVENT_ERROR';

export const PUBLISH_DRAFT = 'go4ellis/CreateEvent/PUBLISH_DRAFT';
export const PUBLISH_DRAFT_SUCCESS = 'go4ellis/CreateEvent/PUBLISH_DRAFT_SUCCESS';
export const PUBLISH_DRAFT_ERROR = 'go4ellis/CreateEvent/PUBLISH_DRAFT_ERROR';

export const RELOAD_FORM = 'go4ellis/CreateEvent/RELOAD_FORM';
export const RELOAD_FORM_SUCCESS = 'go4ellis/CreateEvent/RELOAD_FORM_SUCCESS';
export const RELOAD_FORM_ERROR = 'go4ellis/CreateEvent/RELOAD_FORM_ERROR';

export const DUPLICATE_EVENT = 'go4ellis/CreateEvent/DUPLICATE_EVENT';
export const PREPOPULATE_FIELDS = 'go4ellis/CreateEvent/PREPOPULATE_FIELDS';