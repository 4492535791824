import React, { Component } from 'react';
import { connect } from 'react-redux';
import ActionBar from '../ActionBar';
import { googlePlaceSelected } from 'redux/ducks/CreateEvent/Shifts';
import * as locationActions from 'redux/ducks/Post/Locations/actions';
import { validateForm } from 'redux/ducks/Post';
import EventLocation from './EventLocation';
import isEmail from 'validator/lib/isEmail';
import { trackProspectUser } from 'redux/ducks/User';
import { saveAsDraft } from 'redux/ducks/Post/actions';
import { shiftsByLocation, trackMixpanelPageView, locationValid } from 'helpers/post';
import { get } from 'lodash';
import { updateEap } from 'redux/ducks/Post/EAP/actions';

class LocationScreen extends Component {
  constructor(props) {
    super(props);
    props.initialize('LocationScreen');
  }

  componentDidMount() {
    if (!this.props.editMode) trackMixpanelPageView('create-event/location', this.props.user);
  }

  pageIsValid = () => {
    return locationValid(this.props.shiftLocation, this.props.post.profile);
  };

  trackProspectUsers = (location) => {
    const contacts = location.reportToContacts.filter(({ email }) => isEmail(email));
    contacts.forEach(this.props.trackProspectUser);
  };

  getNextStep = () => {
    const { editMode, shifts, baseUrl } = this.props;
    if (editMode && shifts.length > 0) return `${baseUrl}/schedule-summary`;

    return `${baseUrl}/schedule/type`;
  };

  onSaveAndContinue = () => {
    if (!this.pageIsValid()) {
      this.props.validateForm();
      return;
    }

    if (!this.props.authenticated) this.trackProspectUsers(this.props.shiftLocation);

    const callback = () => this.props.history.push(this.getNextStep());

    if (
      (this.props.editMode && this.props.shiftLocation.id) ||
      get(this.props, 'location.state.from', '') === 'venue-location') {
      this.props.saveUpdates(this.props.post, callback);
      return;
    }

    callback();
  };

  render() {
    const { shiftLocation, baseUrl, wasValidated } = this.props;
    return (
      <div className="locations-screen">
        <div className="nav-placeholder" />
        <main>
          <header>
            <div className="screen-title">
              Location
            </div>
          </header>
          <EventLocation
            key={shiftLocation.idx}
            shiftLocation={shiftLocation}
            profile={this.props.post.profile}
            wasValidated={wasValidated}
            onChangeLocation={this.props.onChangeLocation(shiftLocation.idx)}
            onGooglePlaceSelected={this.props.onGooglePlaceSelected(shiftLocation.idx)}
            onAddNewReportToContact={this.props.onAddNewReportToContact(shiftLocation.idx)}
            onRemoveReportFromContact={this.props.onRemoveReportFromContact(shiftLocation.idx)}
            onChangeReportFromContact={this.props.onChangeReportFromContact(shiftLocation.idx)}
          />
          <ActionBar
            editMode={this.props.editMode}
            currentStep="location"
            backTo={`${baseUrl}/profile`}
            onSaveAndContinue={this.onSaveAndContinue}
            nextDisabled={() => !this.pageIsValid()}
            nextText="SAVE & CONTINUE"
          />
        </main>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const { post } = state;
  const { authenticated, currentUser } = state.session;
  const { locations, navigation, validations } = post;
  const { locationIdx } = navigation;
  const { wasValidated } = validations.locations;
  const shiftLocation = locations[locationIdx];
  const shifts = shiftsByLocation(post.shifts, shiftLocation);

  return {
    post,
    wasValidated,
    shiftLocation,
    authenticated,
    shifts,
    user: currentUser,
  };
};

const mapDispatchToProps = (dispatch) => ({
  onChangeLocation: (idx) => (args) =>
    dispatch(locationActions.changeLocationField({ idx, ...args })),
  onGooglePlaceSelected:
    (idx) =>
      ({ placeId }) => {
        dispatch(googlePlaceSelected(idx, placeId, 'post-location'));
      },
  onAddNewReportToContact: (locationIndex) => () =>
    dispatch(locationActions.onAddNewReportToContact({ locationIndex })),
  onRemoveReportFromContact: (locationIndex) => (contactIndex) =>
    dispatch(locationActions.removeReportFromContact({ locationIndex, contactIndex })),
  onChangeReportFromContact: (locationIndex) => (idx, contact) =>
    dispatch(locationActions.onChangeReportFromContact({ locationIndex, idx, contact })),
  validateForm: () => dispatch(validateForm('locations')),
  trackProspectUser: (contact) => dispatch(trackProspectUser({ contact, event_name: 'job_contact_added' })),
  saveUpdates: (post, callback) => dispatch(saveAsDraft({ post, callback, from: 'location' })),
  updateEap: (callback) => dispatch(updateEap({ callback })),
});

export default connect(mapStateToProps, mapDispatchToProps)(LocationScreen);
