import React, { Component } from 'react';
import { connect } from 'react-redux';
import { removeShift } from 'redux/ducks/Post/Shifts/actions';
import { editEvent, publishDraft, saveAsDraft, saveLocalForm } from 'redux/ducks/Post/actions';
import LocationList from 'scenes/V2/Shared/LocationList';
import { postValid, serviceFee, totalEventRate, eventDuration, completedEAP } from 'helpers/post';
import { trackMixpanelPageView } from 'helpers/post';
import { Link } from 'react-router-dom/cjs/react-router-dom.min';
import { Button } from 'reactstrap';
import classNames from 'classnames';
import moment from 'moment';
import { validateForm } from 'redux/ducks/Post';
import BillingInfo from './BillingInfo';
import HorizontalRule from 'components/HorizontalRule';
import WhatHappensNext from './WhatHappensNext';
import { hasPaymentToPublish } from '../../../../helpers/payment';
import ToolTip from 'scenes/V2/ToolTip';
import { MILITARY_FORMAT } from 'helpers/datetime';

class PublishOrUpdateStep extends Component {
  constructor(props) {
    super(props);
    props.initialize('PublishOrUpdateStep');
    this.state = { locationIdx: 0 };
  }

  componentDidMount() {
    if (!this.props.editMode) trackMixpanelPageView('create-event/post', this.props.currentUser);

    this.props.validateForm();
    this.props.saveLocalForm();
  }

  saveAsDraft = () => {
    trackMixpanelPageView('SaveAsDraftClick', this.props.currentUser);
    this.props.onSaveAsDraft(this.props.post, () => this.props.history.push('/dashboard'));
  };

  onPublish = () => {
    if (this.publishDisabled()) return;

    trackMixpanelPageView('ListJobClick', this.props.currentUser);
    this.props.onPublish({ history: this.props.history });
  };

  publishDisabled = () => {
    const { payment, authenticated, post } = this.props;
    const { shifts, eap } = post;
    const hasCompletedEAP = completedEAP(eap, shifts);

    return (
      !postValid(post) ||
      !authenticated ||
      !hasPaymentToPublish(payment) ||
      !hasCompletedEAP ||
      !shifts.every(
        (s) =>
          moment(s.startTime, MILITARY_FORMAT).isValid() &&
          moment(s.endTime, MILITARY_FORMAT).isValid()
      ) ||
      shifts.some(
        (s) =>
          moment(s.startTime, MILITARY_FORMAT).isBefore(moment()) ||
          moment(s.endTime, MILITARY_FORMAT).isBefore(moment())
      )
    );
  };

  setPrecision = (amount, dec = 2) => Number(amount).toFixed(dec);

  updateDisabled = () => {
    const { shifts, eap } = this.props.post;
    const hasCompletedEAP = completedEAP(eap, shifts);

    return (
      !postValid(this.props.post) ||
      !this.props.authenticated ||
      !hasPaymentToPublish(this.props.payment) ||
      !hasCompletedEAP ||
      !shifts.every((s) => moment(s.startTime).isValid() && moment(s.endTime).isValid())
    );
  };

  onUpdateEvent = () => {
    if (this.updateDisabled()) return;

    this.props.onUpdateEvent({ history: this.props.history });
  };

  hasMultiDayShifts = () => this.props.post.shifts.some((shift) => shift.isMultiDay);

  render() {
    const { authenticated, payment, currentUser, editMode, post } = this.props;
    const { locations, profile } = post;
    const shifts = post.shifts.filter(({ cancelled }) => !cancelled);
    const disabled = editMode ? this.updateDisabled() : this.publishDisabled();
    const eventServiceFee = serviceFee({ shifts, payment, currentUser });
    const totalPostRate = totalEventRate({ shifts });

    return (
      <div className="publish-or-update-step">
        <div className="nav-placeholder" />
        <main>
          <div className="row m-0">
            <div className="summary col-md-8 mb-5">
              <header>
                <div className="screen-title">Summary</div>
                <HorizontalRule dashed margin="0" />
                <WhatHappensNext />
              </header>
              <div className="job-name">
                <div className="screen-title">{profile.title} BILLING ESTIMATE</div>
              </div>
              <LocationList
                locations={locations}
                shifts={shifts}
                onDeleteShift={this.props.onDeleteShift}
                editMode={editMode}
                history={this.props.history}
              />
            </div>
            <div className="billing-estimate col-md-4 p-2 pt-4">
              <div className="post-section">
                <div className="bg-gray">
                  <div className="title gotham-bold-font">NO LISTING FEES</div>
                </div>
                <div className="no-listing-text nimbus-regular-font">
                  There are no fees to list a job and receive applications. Payment is made only
                  after the job has been worked.
                </div>
                <HorizontalRule margin="3px" />
                <div className="estimated-total-title gotham-bold-font">ESTIMATED TOTAL</div>
                <div className="event-info nimbus-regular-font">
                  {!this.hasMultiDayShifts() && (
                    <div className="info-item">
                      <p>Total Hours</p>
                      <p>{eventDuration({ shifts })}</p>
                    </div>
                  )}
                  <div className="info-item">
                    <p>Subtotal</p>
                    <p>${this.setPrecision(totalPostRate)}</p>
                  </div>
                  <div className="info-item">
                    <p>
                      Service Fee
                      <ToolTip
                        id="service-fee-info"
                        top="3px"
                        bodyText="The service fee covers processing fees and our technology and administrative fees."
                      />
                    </p>
                    <p>${this.setPrecision(eventServiceFee)}</p>
                  </div>
                  <div className="info-item gotham-bold-font">
                    <p>ESTIMATED TOTAL</p>
                    <p>${this.setPrecision(totalPostRate + eventServiceFee)}</p>
                  </div>
                </div>
                <BillingInfo />
                <div className="actions">
                  <Button
                    onClick={editMode ? this.onUpdateEvent : this.onPublish}
                    className={classNames('actionBar__continue', {
                      disabled: disabled,
                    })}
                    disabled={disabled}
                    block
                    color="success"
                  >
                    <div>{editMode ? 'UPDATE JOB' : 'LIST JOB'}</div>
                  </Button>
                  {!editMode && (
                    <Link
                      className="actionBar__save__draft"
                      to="/v2/create-event/post"
                      onClick={this.saveAsDraft}
                      disabled={!authenticated}
                    >
                      <div>SAVE AS DRAFT</div>
                    </Link>
                  )}
                </div>
              </div>
            </div>
          </div>
        </main>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  post: state.post,
  currentUser: state.session.currentUser,
  payment: state.payment,
  authenticated: state.session.authenticated,
});

const mapDispatchToProps = (dispatch) => ({
  onDeleteShift: (idx: number, reason) => dispatch(removeShift({ index: idx, reason })),
  onPublish: (payload) => dispatch(publishDraft(payload)),
  onSaveAsDraft: (post, callback) => dispatch(saveAsDraft({ post, callback, from: 'publish' })),
  onUpdateEvent: (payload) => dispatch(editEvent(payload)),
  validateForm: () => dispatch(validateForm('post')),
  saveLocalForm: () => dispatch(saveLocalForm()),
});

export default connect(mapStateToProps, mapDispatchToProps)(PublishOrUpdateStep);
