import React from 'react';
import classNames from 'classnames';
import './HorizontalRule.scss';

const HorizontalRule = ({ hideResponsive = false, dashed = false, margin = '20px', white = false }) => {
  return (
    <div
      style={{ marginBlock: margin }}
      className={classNames('horizontal-rule', {
        'hidden-responsive': hideResponsive,
        'dashed': dashed,
        'white': white,
      })}
    ></div>
  );
};

export default HorizontalRule;
