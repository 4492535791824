import React, { Component } from 'react';
import { connect } from 'react-redux';
import './LocationSetup.scss';
import ActionBar from '../../ActionBar';
import { trackMixpanelPageView } from 'helpers/post';

export class LocationSetup extends Component {
  componentDidMount() {
    if (!this.props.editMode) trackMixpanelPageView('create-event/location-setup', this.props.user);
  }
  render() {
    const { locationIdx, baseUrl } = this.props;
    return (
      <div className="location-setup">
        <div className="nav-placeholder" />
        <main>
          <header>
            <div className="screen-title">Let's setup location {locationIdx + 1}.</div>
          </header>
          <ActionBar
            editMode={this.props.editMode}
            onSaveAndContinue={() => this.props.history.push(`${baseUrl}/location`)}
            currentStep="location-setup"
            backDisabled
          />
        </main>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const { locationIdx } = state.post.navigation;
  const user = state.session.currentUser;

  return { locationIdx, user };
};

export default connect(mapStateToProps)(LocationSetup);
