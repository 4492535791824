import React, { Component } from "react";
import ExpandableSection from "../ExpandableSection";

const panelData = [
  {
    id: 1,
    title: "What is the staffing/transaction fee for event operators?",
    content:
      "Go4 charges Event Operators a 12.5% staffing transaction fee on the hours an AT works. By comparison, traditional per diem Athletic Trainer staffing companies markup what they pay ATs by 25% to 100%. So, for example, while you may be paying an AT staffing company $50 an hour for an Athletic Trainer, the AT is only getting $25 an hour.",
    open: false,
  },
  {
    id: 2,
    title: "Are there any membership fees?",
    content:
      "There are no membership fees. Creating and holding an account on Go4 is completely free.",
    open: false,
  },
  {
    id: 3,
    title:
      "Do I need to post a minimum amount of hours or jobs? Are there any contracts?",
    content:
      "As an event operator you don’t need to post a minimum amount of hours. You could sign up, post one event and never post again. Of course, we hope you find the service so convenient and easy to use that you’ll become a Go4 power user.",
    open: false,
  },
  {
    id: 4,
    title:
      "At the end of the year, do I need to track down the ATs that worked my events to file 1099s?",
    content:
      "The Go4 platform handles all the 1099s for the ATs that worked your event. Saving your organization the time and man-hours previously wasted tracking down ATs who worked your event and filing copious tax forms.",
    open: false,
  },
];
const nursesPanelData = [
  {
    id: 1,
    title: "What is the staffing/transaction fee for event operators?",
    content:
      "Go4 charges Event Operators a 17.5% staffing transaction fee on the hours an RN works. By comparison, traditional per diem Registered Nurse staffing companies markup what they pay RNs by 25% to 100%. So, for example, while you may be paying an RN staffing company $50 an hour for an Registered Nurse, the RN is only getting $25 an hour.",
    open: false,
  },
  {
    id: 2,
    title: "Are there any membership fees?",
    content:
      "There are no membership fees. Creating and holding an account on Go4 is completely free.",
    open: false,
  },
  {
    id: 3,
    title:
      "Do I need to post a minimum amount of hours or jobs? Are there any contracts?",
    content:
      "As an event operator you don’t need to post a minimum amount of hours. You could sign up, post one event and never post again. Of course, we hope you find the service so convenient and easy to use that you’ll become a Go4 power user.",
    open: false,
  },
  {
    id: 4,
    title:
      "At the end of the year, do I need to track down the RNs that worked my events to file 1099s?",
    content:
      "The Go4 platform handles all the 1099s for the RNs that worked your event. Saving your organization the time and man-hours previously wasted tracking down RNs who worked your event and filing copious tax forms.",
    open: false,
  },
];
const AtPanelData = [
  {
    id: 1,
    title: "What is the fee for ATs?",
    content:
      "ATs are charged a flat 3% on hours worked. That 3% fee is a pass through cost from our payment processor, Stripe, and is fee they charge to move money from one account to another.",
    open: false,
  },
  {
    id: 2,
    title: "Are there any membership fees?",
    content:
      "There are no membership fees. Creating and holding an account on Go4 is completely free.",
    open: false,
  },
  {
    id: 3,
    title:
      "As an AT do I need to work a certain amount of hours or work certain events?",
    content:
      "As an AT, you could work one hour a year and it would it all be good. Also, you are free to pick and choose the events you want to work. It is completely up to you.",
    open: false,
  },
  {
    id: 4,
    title: "Do I need my own liability insurance?",
    content:
      "Yes, you need liability insurance to work events. Mercer Insurance is NATA’s preferred provider. As an NATA member, you receive 10% off your policy.",
    open: false,
  },
];

const getPanelData = (type) => {
  switch (type) {
    case "atScene":
      return AtPanelData;
    case "nurses":
      return nursesPanelData;
    default:
      return panelData;
  }
};
class FAQS extends Component {
  constructor(props) {
    super(props);

    this.state = { panelData: getPanelData(props.type) };
  }

  handleClick = (id) => () => {
    const panelData = this.state.panelData.map((item) => {
      if (id === item.id) {
        return {
          ...item,
          open: !item.open,
        };
      }
      return item;
    });
    this.setState({ panelData });
  };
  render() {
    return (
      <section className="faq">
        <div className="inner-faq">
          <div className="faq-banner">
            <span>FAQS</span>
          </div>
          <div className="accordion-container">
            {this.state.panelData.map((item) => (
              <ExpandableSection
                key={item.id}
                item={item}
                handleClick={this.handleClick(item.id)}
              />
            ))}
          </div>
        </div>
      </section>
    );
  }
}

export default FAQS;
