import ApiService from './ApiService';
import { ReviewSerializer } from './serializers';

class ReviewService extends ApiService {
  constructor(session) {
    super(session);

    this.url = '/reviews';
  }

  getAll() {
    return super.get(`${this.url}.json?page=1&per_page=100`, (response) =>
      response.reviews.map((item) => ReviewSerializer.deserialize(item))
    );
  }

  getReviews(params) {
    return super.search(`${this.url}.json`, params, (response) => {
      return {
        per_page: response.per_page,
        total_pages: response.total_pages,
        total: response.total,
        current_page: response.current_page,
        reviews: response.reviews.map(ReviewSerializer.deserialize),
      };
    });
  }

  update(review) {
    return super.patch(
      `${this.url}/${review.id}.json`,
      { review: ReviewSerializer.serialize(review) },
      () => true
    );
  }
}

export default ReviewService;
