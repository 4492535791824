import React from "react";
import { useSelector } from "react-redux";
import { Redirect, useParams, withRouter } from "react-router-dom";
import Ghost from "../../../components/UI/Button/Ghost";

const SingleBlog = () => {
  const { id } = useParams();
  const { data = [] } = useSelector((s) => s.blog);
  const selected = data.find((b) => b.id === id);
  if (!selected) {
    return <Redirect to={"/blog"} />;
  }
  return (
    <>
      <div className="d-flex justify-content-end mt-4 mr-4">
        <Ghost asLink to={"/blog"}>
          Back to Blog
        </Ghost>
      </div>
      <article className="p-4 single-blog" key={selected.id}>
        <h2 className="mb-0">{selected.title}</h2>
        <p className="mb-4 pb-4 date">{selected.date}</p>
        <div dangerouslySetInnerHTML={{ __html: selected.content }} />
      </article>
    </>
  );
};

export default withRouter(SingleBlog);
