// @flow
import EventLocationSerializer from './EventLocationSerializer';
import EmergencyContactSerializer from './EmergencyContactSerializer';
import EmergencyEquipmentSerializer from './EmergencyEquipmentSerializer';
import { startsWith } from 'lodash';

import type { SerializedEventLocation } from './EventLocationSerializer';
import type { EAPFormType } from 'redux/ducks/CreateEvent/EAP';
import { AVAILABLE_EQUIPMENTS } from 'helpers/post';

class EmergencyActionPlanSerializer {
  static deserialize(emergencyActionPlan: SerializedEmergencyActionPlan): EAPFormType {
    const result = {
      id: emergencyActionPlan.id,
      instructions: emergencyActionPlan.instructions,
      facilityInformation: emergencyActionPlan.details?.facility_information,
      ambulanceAccessPoint: emergencyActionPlan.details?.ambulance_access_point,
      weatherPlans: emergencyActionPlan.details?.weather_plans,
      otherDetails: emergencyActionPlan.details?.other_details,
      eventLocation: EventLocationSerializer.deserialize(emergencyActionPlan.event_location),
      hospital: EventLocationSerializer.deserialize(emergencyActionPlan.hospital),
      policeStation: EventLocationSerializer.deserialize(emergencyActionPlan.police_station),
      fireDepartment: EventLocationSerializer.deserialize(emergencyActionPlan.fire_department),
      emergencyEquipment: emergencyActionPlan.emergency_equipments
        .map((eq) => EmergencyEquipmentSerializer.deserialize(eq))
        .sort(this.prioritySort),
      emergencyContacts: emergencyActionPlan.emergency_contacts.map(
        EmergencyContactSerializer.deserialize
      ),
      venueMapUrl: emergencyActionPlan.venue_map_url,
      venueMapFileName: emergencyActionPlan.venue_map_file_name,
      generateOrUpload: emergencyActionPlan.url ? 'upload' : 'generate',
      uploadedPlan: null,
      uploadedPlanPreview: emergencyActionPlan.url,
      hasNavigatedForward: false,
    };

    return result;
  }

  static serialize(emergencyActionPlan: EAPFormType): SerializedEmergencyActionPlan | FormData {
    if (
      emergencyActionPlan.generateOrUpload === 'upload' &&
      emergencyActionPlan.uploadedPlan &&
      emergencyActionPlan.eventLocation.id
    ) {
      const formData = new FormData();
      formData.set('emergency_action_plan[uploaded_pdf]', emergencyActionPlan.uploadedPlan);
      formData.set(
        'emergency_action_plan[event_location_id]',
        ((emergencyActionPlan.eventLocation.id: any): string)
      );
      return formData;
    } else if (
      emergencyActionPlan.generateOrUpload === 'upload' &&
      emergencyActionPlan.uploadedPlanPreview
    ) {
      return {
        uploaded_pdf: emergencyActionPlan.uploadedPlanPreview,
        event_location_id: emergencyActionPlan.eventLocation.id,
        event_location_attributes: EventLocationSerializer.serialize(
          emergencyActionPlan.eventLocation
        ),
      };
    } else {
      const result: Object = {
        emergency_action_plan: {
          id: emergencyActionPlan.id,
          instructions: emergencyActionPlan.instructions,
          facility_information: emergencyActionPlan.facilityInformation,
          ambulance_access_point: emergencyActionPlan.ambulanceAccessPoint,
          weather_plans: emergencyActionPlan.weatherPlans,
          other_details: emergencyActionPlan.otherDetails,
          event_location_id: emergencyActionPlan.eventLocation.id,
          hospital_attributes: EventLocationSerializer.serialize(emergencyActionPlan.hospital),
          police_station_attributes: EventLocationSerializer.serialize(
            emergencyActionPlan.policeStation
          ),
          fire_department_attributes: EventLocationSerializer.serialize(
            emergencyActionPlan.fireDepartment
          ),
          emergency_equipments_attributes: emergencyActionPlan.emergencyEquipment.map(
            EmergencyEquipmentSerializer.serialize
          ),
        },
      };

      if (emergencyActionPlan.emergencyContacts) {
        result.emergency_action_plan.emergency_contacts_attributes =
          emergencyActionPlan.emergencyContacts.map(EmergencyContactSerializer.serialize);
      }

      if (emergencyActionPlan.eventLocation.id) {
        result.event_location_id = emergencyActionPlan.eventLocation.id;
      } else {
        result.event_location_attributes = EventLocationSerializer.serialize(
          emergencyActionPlan.eventLocation
        );
      }

      let mapUrl = emergencyActionPlan.venueMapUrl;

      if (mapUrl && startsWith(mapUrl, 'data:')) {
        result.emergency_action_plan.venue_map = mapUrl;
      }

      if (emergencyActionPlan.venueMapFileName) {
        const fileName = emergencyActionPlan.venueMapFileName.split('.')[0];
        result.emergency_action_plan.venue_map_file_name = fileName;
      }

      return (result: SerializedEmergencyActionPlan);
    }
  }

  static prioritySort(e1, e2) {
    if (AVAILABLE_EQUIPMENTS.includes(e1.name) && !AVAILABLE_EQUIPMENTS.includes(e2.name))
      return -1;
    if (!AVAILABLE_EQUIPMENTS.includes(e1.name) && AVAILABLE_EQUIPMENTS.includes(e2.name)) return 1;

    return 0;
  }
}

export default EmergencyActionPlanSerializer;

type SerializedEmergencyActionPlan = {
  id?: ?number,
  url?: ?string,
  instructions: ?string,
  event_location: SerializedEventLocation,
  event_location_id?: number,
  hospital: SerializedEventLocation,
  fire_department: SerializedEventLocation,
  police_station: SerializedEventLocation,
  hospital_attributes: SerializedEventLocation,
  fire_department_attributes: SerializedEventLocation,
  police_station_attributes: SerializedEventLocation,
  venue_map_url?: ?string,
  venue_map?: ?string,
  emergency_contacts: any[],
  emergency_equipments: any[],
  emergency_contacts_attributes: any[],
  emergency_equipments_attributes: any[],
};
