import React from "react";
import SectionBannerAlt from "../SectionBannerAlt";
import howItWorks3 from "images/phone-pick-job.svg";
import howItWorks1 from "images/phone-download.svg";
import howItWorks2 from "images/documents.svg";
import howItWorks4 from "images/money.svg";

const groupings = [
  {
    title: "Download the app",
    body:
      "Download the Go4 App onto your phone. Get it on the App Store or Google Play.",
    image: howItWorks1,
  },
  {
    title: "Upload your credentials",
    body:
      "Upload your BOC, proof of liability insurance and other docs required by your state practice act. ",
    image: howItWorks2,
  },
  {
    title: "Pick your shift / rate",
    body:
      "Pick the shifts you want to work, for the pay rates you want and apply.",
    image: howItWorks3,
  },
  {
    title: "Get paid",
    body:
      "All payments on Go4 are through direct deposit. No more chasing down people for money.",
    image: howItWorks4,
  },
];
const HowItWorks = ({ groups, png, banner, id }) => (
  <section className="howItWorks">
    {id && <div id={id} />}
    {banner ? banner : <SectionBannerAlt dark text="How it works" />}
    <div className="grouping-container">
      {(groups || groupings).map((group) => {
        const img = <img alt={group.title} src={group.image} />;
        return (
          <div className={`grouping ${png ? "png" : ""}`} key={group.title}>
            {!png ? <div className={`img-wrapper`}>{img}</div> : img}
            <span />
            <h3>{group.title}</h3>
            <p>{group.body}</p>
          </div>
        );
      })}
    </div>
  </section>
);

export default HowItWorks;
