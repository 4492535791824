import React, { Component } from 'react';

import { connect } from 'react-redux';

import { Route, Switch, Redirect } from 'react-router-dom';

import { resetRegistration } from 'redux/ducks/Registration';
import { getEnums } from 'redux/ducks/Enum';

import Parent from './Parent';
import Patient from './Patient';

import Layout from './Layout/Layout';

export class RegistrationScene extends Component {
  componentWillMount() {
    this.props.getEnums();
  }

  componentWillUnmount() {
    this.props.resetRegistration();
  }

  render() {
    const { pathname } = this.props.location;
    const profile = pathname.includes("/post-event/profile");
    const HEADING = profile ?
      "Next, tell us a bit about yourself" :
      "Access your injury report"

    return (
      <Layout>
        <div className="registrationScene__left">
          <h1 className="registrationScene__heading">{HEADING}</h1>
        </div>
        <div className="registrationScene__right">
          <Switch>
            <Route path={`${this.props.match.url}/parent`} component={Parent} />
            <Route path={`${this.props.match.url}/patient`} component={Patient} />
            <Redirect to='/v2/create-event/type' />
          </Switch>
        </div>
      </Layout>
    );
  }
}

function mapStateToProps(state) {
  return {
    role: state.registration.role,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    resetRegistration: () => dispatch(resetRegistration()),
    getEnums: () => dispatch(getEnums()),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(RegistrationScene);
