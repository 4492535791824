import React, { Component } from 'react';
import moment from 'moment';
import { Collapse, UncontrolledTooltip } from 'reactstrap';
import {
  jobsInStateFromEvent,
  formatCurrency,
  EMPTY_DATE,
  SHOW_TOOLTIP_COLUMNS,
  createOverflowChecker,
  activeShifts,
} from 'helpers/dashboard';
import FinancesJobItem from './components/FinancesJobItem';
import classNames from 'classnames';
import { sortBy } from 'lodash';
import { connect } from 'react-redux';
import { clearV2Form, duplicatePost } from 'redux/ducks/Post/actions';
import { withRouter } from 'react-router-dom/cjs/react-router-dom';
import isEnabledFeature, { features } from 'helpers/feature_flags';

class FinancesListItem extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isOverflowing: {},
      isOpen: false,
    };
    this.columnRefs = {};
    SHOW_TOOLTIP_COLUMNS.finances.forEach((column) => {
      this.columnRefs[column] = React.createRef();
    });
  }

  componentDidMount() {
    const checkOverflow = createOverflowChecker(
      'finances',
      this.columnRefs,
      this.setState.bind(this)
    );

    this.resizeObserver = new ResizeObserver(checkOverflow);
    SHOW_TOOLTIP_COLUMNS.finances.forEach((column) => {
      this.resizeObserver.observe(this.columnRefs[column].current);
    });
  }

  componentWillUnmount() {
    this.resizeObserver.disconnect();
  }

  handleCollapse = () => {
    this.setState((prevState) => ({ isOpen: !this.state.isOpen }));
  };

  onDuplicate = () => {
    const { item } = this.props;
    this.props.clearForm();
    this.props.duplicatePost(item);
    this.props.history.push(`/v2/create-event/profile`);
  };

  computeTopSpaceForAtsColumn = (idx) => {
    return idx === 0 ? '-9px' : '-9.5px';
  };

  eventEndDate = () => {
    const { item } = this.props;
    const shifts = Object.values(item.shifts.byId);

    if (shifts.every((shift) => !shift.isMultiDay)) return '';

    if (item && shifts.length > 0) {
      const lastEndDate = activeShifts(shifts).reduce((acc, shift) => {
        const endDate = moment(shift.endTime);
        return acc.isBefore(endDate) ? endDate : acc;
      }, moment(item.createdAt));

      return ` - ${lastEndDate.format('MM/DD/YY')}`;
    }

    return '';
  };

  getAllFinancesJobs = () => {
    const { item, eventOperator } = this.props;
    const paidJobs = jobsInStateFromEvent(item, ['paid']);

    if (!isEnabledFeature(features.CANCELATION_POLICY, eventOperator))
      return sortBy(paidJobs, (job) => job.payment?.paidAt);

    const cancelledOrRemovedJobs = jobsInStateFromEvent(item, [
      'cancelled',
      'eo_removed_at',
    ]).filter((job) => moment(job.cancelledAt).isValid());

    const allFinancesJobs = paidJobs.concat(cancelledOrRemovedJobs);
    return sortBy(allFinancesJobs, (job) => job.payment?.paidAt);
  };

  render() {
    const { item, columns, idx } = this.props;
    const financesJobs = this.getAllFinancesJobs();
    const totalHired = financesJobs.length;

    return (
      <React.Fragment>
        <div
          className={classNames('finances-list-item', {
            'collapse-border-bottom': !this.state.isOpen,
          })}
        >
          <div
            className={`${columns['startDate'].size}`}
            id={`startDate-${item.id}`}
            ref={this.columnRefs['startDate']}
          >
            {item.formattedFirstStartTime || EMPTY_DATE}
            {this.eventEndDate()}
            {this.state.isOverflowing.startDate && (
              <UncontrolledTooltip target={`startDate-${item.id}`}>
                {item.formattedFirstStartTime || EMPTY_DATE}
                {this.eventEndDate()}
              </UncontrolledTooltip>
            )}
          </div>
          <div
            className={classNames(`${columns['jobName'].size}`, {
              'bold job-title': this.state.isOpen,
            })}
            id={`jobName-${item.id}`}
            ref={this.columnRefs['jobName']}
          >
            {item.title}
            {this.state.isOverflowing.jobName && (
              <UncontrolledTooltip target={`jobName-${item.id}`}>{item.title}</UncontrolledTooltip>
            )}
          </div>
          <div
            className={classNames(`${columns['ATs'].size} p-0 ats-container`, {
              'expand': totalHired > 0,
              'grey-expand': totalHired === 0,
              'ats-margin': this.state.isOpen,
            })}
            onClick={totalHired > 0 ? this.handleCollapse : () => {}}
          >
            <div
              className={classNames('ats-section')}
              style={{
                top: this.computeTopSpaceForAtsColumn(idx),
              }}
            >
              <p
                className={classNames(`ats m-0`, {
                  'open-collapse': this.state.isOpen,
                })}
              >
                ATs
                {this.state.isOpen && <i className="material-icons">expand_more</i>}
                {!this.state.isOpen && <i className="material-icons">chevron_right</i>}
              </p>
            </div>
          </div>
          <div
            className={`${columns['totalHired'].size} `}
            id={`totalHired-${item.id}`}
            ref={this.columnRefs['totalHired']}
          >
            {totalHired}
            {this.state.isOverflowing.totalHired && (
              <UncontrolledTooltip target={`totalHired-${item.id}`}>
                {totalHired}
              </UncontrolledTooltip>
            )}
          </div>
          <div
            className={`${columns['totalPayment'].size} `}
            id={`totalPayment-${item.id}`}
            ref={this.columnRefs['totalPayment']}
          >
            {formatCurrency({
              currency: item.totalPaymentToDate,
              dollarSign: true,
            })}
            {this.state.isOverflowing.totalPayment && (
              <UncontrolledTooltip target={`totalPayment-${item.id}`}>
                {formatCurrency({
                  currency: item.totalPaymentToDate,
                  dollarSign: true,
                })}
              </UncontrolledTooltip>
            )}
          </div>
          <div
            className={`${columns['lastPayment'].size} `}
            id={`lastPayment-${item.id}`}
            ref={this.columnRefs['lastPayment']}
          >
            {item.lastPaymentIssued
              ? moment(item.lastPaymentIssued).format('MM/DD/YY')
              : EMPTY_DATE}
            {this.state.isOverflowing.lastPayment && (
              <UncontrolledTooltip target={`lastPayment-${item.id}`}>
                {item.lastPaymentIssued
                  ? moment(item.lastPaymentIssued).format('MM/DD/YY')
                  : EMPTY_DATE}
              </UncontrolledTooltip>
            )}
          </div>
          <div className={`${columns['actions'].size}`}>
            <button onClick={this.onDuplicate} className="plain-button duplicate">
              DUPLICATE
            </button>
          </div>
        </div>
        {totalHired > 0 && (
          <Collapse
            isOpen={this.state.isOpen}
            timeout={500}
            style={{
              width: '100%',
              textAlign: 'left',
              paddingInline: '0',
            }}
          >
            <div className="finances-jobs">
              <div className="row finances-jobs-headers">
                <div className="col-md-2">AT NAME</div>
                <div className="col-md-1">SHIFT DATE</div>
                <div className="col-md-2">SHIFT TIME</div>
                <div className="col-md-3">LOCATION</div>
                <div className="col-md-1">RATE</div>
                <div className="col-md-2">TOTAL PAYMENT</div>
                <div className="col-md-1">DATE PAID</div>
              </div>
              {financesJobs.map((job) => {
                return <FinancesJobItem key={job.id} job={job} {...this.props} />;
              })}
            </div>
          </Collapse>
        )}
      </React.Fragment>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  duplicatePost: (event) => dispatch(duplicatePost(event)),
  clearForm: () => dispatch(clearV2Form()),
});

export default withRouter(connect(null, mapDispatchToProps)(FinancesListItem));
