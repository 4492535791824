import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { getMedicalRecord, downloadMedicalRecord } from "redux/ducks/MedicalRecord";
import { Table, List, Avatar } from "antd";
import { camelCase } from 'lodash';
import moment from 'moment';
import { get, isString } from 'lodash';

const formatDate = (date) => date ? moment(date).format('M/D/Y') : date;

function flattenDict(dictToFlatten) {
  const mapKey = (key) => isNaN(key) ? key : Number(key) + 1

  function flatten(dict, parent) {
    var keys = [];
    var values = [];

    for (var key in dict) {
      if (typeof dict[key] === 'object') {
        var result = flatten(dict[key], parent ? parent + '_' + mapKey(key) : mapKey(key));
        keys = keys.concat(result.keys);
        values = values.concat(result.values);
      } else {
        keys.push(parent ? parent + '_' + mapKey(key) : mapKey(key));
        values.push(dict[key]);
      }
    }

    return {
      keys: keys,
      values: values,
    };
  }

  var result = flatten(dictToFlatten);
  var flatDict = {};

  for (var i = 0, end = result.keys.length; i < end; i++) {
    flatDict[result.keys[i]] = result.values[i];
  }

  return flatDict;
}

export class MedicalRecordScene extends Component {
  componentDidMount() {
    const medicalRecordId = this.props.match.params.medicalRecordId;

    this.props.getMedicalRecord(medicalRecordId);
  }

  componentWillReceiveProps(newProps) {
    if (newProps.error && !this.props.error) {
      this.props.onError("An error occurred when loading the medical record.");
    }
  }

  onClickDownload = (e) => {
    e.preventDefault();

    const medicalRecordId = this.props.match.params.medicalRecordId;
    this.props.downloadMedicalRecord(medicalRecordId);
  }

  BLOCK_LIST = [
    'id',
    'createdById',
    'eventId',
    'createdAt',
    'updatedAt',
    'eventType',
    'sportType',
    'eventTitle',
    'createdBy',
    'createdByEmail',
    'createdByFullName',
    'createdByPhoneNo',
    'patientInfo_first_name',
    'patientInfo_last_name',
    'patientInfo_date_of_birth',
    'patientInfo_guardian_first_name',
    'patientInfo_guardian_last_name',
    'patientInfo_phone_number',
    'patientInfo_team_name',
    'patientInfo_sex',
    'patientInfo_gender',
    'patientInfo_zip',
  ]

  renderKeyValues = (obj) => {
    let flatten = flattenDict(obj);
    let recordKeyValueList = [];
    Object.keys(flatten).forEach(key => {
      if (this.BLOCK_LIST.includes(key)) {
        return;
      }
      if (flatten[key] && flatten[key].length) {
        const flatKey = camelCase(key).replace(/([A-Z0-9]+)/g, " $1").replace(/([A-Z][a-z])/g, " $1").toLowerCase()
        const flatKeyReplace = flatKey.replace(/ other$/, '');
        recordKeyValueList.push({key: flatKeyReplace, value: flatten[key]})
      }
    });

    return <List
        itemLayout="horizontal"
        dataSource={recordKeyValueList}
        grid={{ gutter: 20, column: 2 }}
        renderItem={(item) => (
          <List.Item style={{color: 'black'}}>
            <strong style={{fontSize: '1.2em'}}>{item.key}</strong>
            <p>{item.value}</p>
          </List.Item>
        )} />
  }

  render() {
    const { medicalRecord, error, user } = this.props;

    // Parse patientInfo.injuries if it exists
    if(medicalRecord.patientInfo && typeof medicalRecord.patientInfo.injuries == 'string') {
      medicalRecord.patientInfo.injuries = JSON.parse(medicalRecord.patientInfo.injuries)
    }

    const { patientInfo, notes } = medicalRecord;
    const role = user.role.toLowerCase();

    return <div className="medicalRecord">
      <div className="header">
        <h2>Medical Record {medicalRecord.id}</h2>
        <div className="actions">
          <a href="#" className="btn btn-danger bold-button pt-2 pb-2 pl-4 pr-4" onClick={this.onClickDownload}>
            Download
          </a>
          <Link className="ml-2 btn btn-danger bold-button pt-2 pb-2 pl-4 pr-4" to={`/${role}/dashboard`}>
            Dashboard
          </Link>
        </div>
      </div>
      <div className="mt-4">
        <table>
          <thead className="table">
            <tr aria-colspan="12" className="header">
              <th colSpan="12"><b>MEDICAL REPORT #{medicalRecord.id}</b></th>
            </tr>
          </thead>
          <tbody>
            <tr aria-colspan="12">
              <td className="subheader" colSpan="12"><b>PATIENT INFO</b></td>
            </tr>

            <tr aria-colspan="12">
              <td colSpan="7"><b>PATIENT NAME:</b> {get(patientInfo, 'first_name')} {get(patientInfo, 'last_name')}</td>
              <td colSpan="2"><b>SEX:</b> {get(patientInfo, 'sex')}</td>
              <td colSpan="3"><b>GENDER:</b> {get(patientInfo, 'gender')}</td>
            </tr>
            <tr aria-colspan="12">
              <td colSpan="2"><b>DOB:</b> {formatDate(new Date(get(patientInfo, 'date_of_birth')))}</td>
              <td colSpan="3"><b>Date of injury:</b> {formatDate(medicalRecord.eventDate || medicalRecord.createdAt)}</td>
              <td className="bg-gray" colSpan="7"><b>Patient contact info</b></td>
            </tr>
            <tr aria-colspan="12">
              <td colSpan="5"><b>Sport:</b> {medicalRecord.sportType} </td>
              <td colSpan="7"><b>Phone:</b> {get(patientInfo, 'phone_number')} </td>
            </tr>
            <tr aria-colspan="12">
              <td colSpan="5"><b>Team:</b> {get(patientInfo, 'team_name')}</td>
              <td colSpan="7"><b>Parent / Guardian:</b> {get(patientInfo, 'guardian_first_name')} {get(patientInfo, 'guardian_last_name')}</td>
            </tr>

            <tr aria-colspan="12">
              <td className="subheader" colSpan="12"><b>EVENT INFO</b></td>
            </tr>
            <tr aria-colspan="12">
              <td colSpan="7"><b>Event Name:</b> {medicalRecord.eventTitle}</td>
              <td colSpan="5"><b>Event Type:</b> {medicalRecord.eventType}</td>
            </tr>
            <tr aria-colspan="12">
              {
                medicalRecord.eventStreet &&
                  <td colSpan="4"><b>Street:</b> {medicalRecord.eventStreet}</td>
              }
              {
                medicalRecord.eventVenue &&
                  <td colSpan="4"><b>Venue:</b> {medicalRecord.eventVenue}</td>
              }
              <td colSpan="4"><b>City:</b> {medicalRecord.eventCity}</td>
              <td colSpan="2"><b>State:</b> {medicalRecord.eventState}</td>
              <td colSpan="2"><b>Zip:</b> {medicalRecord.eventZip} </td>
            </tr>

            {
              patientInfo && patientInfo.injuries && patientInfo.injuries.map((injury, i) => {
                return <React.Fragment key={`injury-${i}`}>
                  <tr aria-colspan="12">
                    <td className="subheader" colSpan="12"><b>INJURY {i + 1} DETAILS</b></td>
                  </tr>
                  <tr aria-colspan="12">
                    <td colSpan="12">
                      <div>{this.renderKeyValues(injury)}</div>
                    </td>
                  </tr>
                </React.Fragment>
              })
            }

            {
              notes && notes.length > 0 &&
                <React.Fragment>
                  <tr aria-colspan="12">
                    <td className="subheader" colSpan="12"><b>Addendum notes</b></td>
                  </tr>
                  {
                    notes.map((note, i) =>
                      <tr aria-colspan="12">
                        <td colSpan="12">
                          <div>{note.body}</div>
                        </td>
                      </tr>
                    )
                  }
                </React.Fragment>
            }
            <tr aria-colspan="12">
              <td className="subheader" colSpan="12"><b>RECORD COMPLETED BY</b></td>
            </tr>
            <tr aria-colspan="12">
              <td colSpan="12"><b>Athletic Trainer Name:</b> { medicalRecord.createdByFullName } </td>
            </tr>
            <tr aria-colspan="12">
              <td colSpan="9" style={{textTransform: 'none'}}><b>Email:</b> { medicalRecord.createdByEmail } </td>
              <td colSpan="3"><b>Phone:</b> { medicalRecord.createdByPhoneNo } </td>
            </tr>
          </tbody>
        </table>
        <div className="header mt-4">
          <div className="actions">
            <a href="#" className="btn btn-danger bold-button pt-2 pb-2 pl-4 pr-4" onClick={this.onClickDownload}>
              Download
            </a>
            <Link className="ml-2 btn btn-danger bold-button pt-2 pb-2 pl-4 pr-4" to={`/${role}/dashboard`}>
              Dashboard
            </Link>
          </div>
        </div>
      </div>
    </div>
  }
}

function mapStateToProps(state) {
  return {
    user: state.session.currentUser,
    medicalRecord: state.medicalRecord.medicalRecord,
    error: state.medicalRecord.error,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    getMedicalRecord: (id) => dispatch(getMedicalRecord(id)),
    downloadMedicalRecord: (id) => dispatch(downloadMedicalRecord(id)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(MedicalRecordScene);
