import ApiService from './ApiService';

import { InvitationSerializer } from 'services/serializers';

class InvitationService extends ApiService {
  constructor(session) {
    super(session);
  }

  validateInvitationToken(payload) {
    if(payload.location.includes('parent')){
      return super.get(`/parents/invitation/accept.json?invitation_token=${payload.token}`,
          (response) => InvitationSerializer.deserializeValidTokenResponse(response));
    }
    else {
      return super.get(`/patients/invitation/accept.json?invitation_token=${payload.token}`,
          (response) => InvitationSerializer.deserializeValidTokenResponsePatient(response));
    }
  };

  accept(user, payload) {
    if(payload.location.includes('parent')){
      return super.put('/parents/invitation.json', InvitationSerializer.serializeParent(user, payload.token),
          (response) => InvitationSerializer.deserializeAcceptResponse(response));
    }
    else {
      return super.put('/patients/invitation.json', InvitationSerializer.serializePatient(user, payload.token),
          (response) => InvitationSerializer.deserializeAcceptResponse(response));
    }
  }
}

export default InvitationService;
