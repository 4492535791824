import HorizontalRule from 'components/HorizontalRule';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Modal, ModalHeader, ModalBody, ModalFooter, UncontrolledTooltip } from 'reactstrap';
import { DEGREES, PRIORITY_FILTER } from 'components/AthleticTrainerModal/credentialHelpers';
import { downloadAllCredentials } from 'redux/ducks/Credentials';
import { get, sortBy } from 'lodash';
import CredentialModal from '../CredentialModal';
import { computeReliabilityScore } from 'helpers/reliabilityScore';
import classNames from 'classnames';
import { AthleticTrainerService } from 'services/v2';
import { SHOW_TOOLTIP_COLUMNS, createOverflowChecker } from 'helpers/dashboard';
import { AddButton, DeleteButton } from 'components/V2/Buttons';
import CredentialsIssueModal from '../CredentialsIssueModal/CredentialsIssueModal';
import ToolTip from 'scenes/V2/ToolTip';
import moment from 'moment';

class ExperienceModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
      isOverflowing: {},
      jobCount: 0,
      message: 'N/A',
      issueModalOpen: false,
    };
    this.columnRefs = {};
    SHOW_TOOLTIP_COLUMNS.experienceModal.forEach((column) => {
      this.columnRefs[column] = React.createRef();
    });
  }

  toggleIssueModal = () => {
    this.setState({ ...this.state, issueModalOpen: !this.state.issueModalOpen });
  };

  componentDidMount() {
    const checkOverflow = createOverflowChecker(
      'experienceModal',
      this.columnRefs,
      this.setState.bind(this)
    );
    this.resizeObserver = new ResizeObserver(checkOverflow);
    SHOW_TOOLTIP_COLUMNS.experienceModal.forEach((column) => {
      this.resizeObserver.observe(this.columnRefs[column].current);
    });
  }

  componentWillUnmount() {
    this.resizeObserver.disconnect();
  }

  onOpened = () => {
    const athleticTrainerService = new AthleticTrainerService(this.props.session);
    athleticTrainerService
      .getATStatistics(this.props.id)
      .then((data) => {
        this.setState({
          jobCount: data.job_count,
          message: data.message_for_eo,
        });
      })
      .catch((error) => {
        console.log(error);
      });
  };

  toggle = () => {
    this.setState({ open: !this.state.open });
    if (!this.state.open) {
      this.props.onClick();
      document.body.removeEventListener('touchmove', (e) => e.preventDefault(), { passive: false });
    } else document.body.addEventListener('touchmove', (e) => e.preventDefault());
  };

  onDownloadAllCredentials = () => {
    this.props.downloadAllCredentials(this.props.id);
  };

  getAT = () => {
    const { pendingApplicants, id } = this.props;
    const AT = pendingApplicants.find((at) => at.id === id);
    return AT;
  };

  showActions = () => {
    return this.props.from === 'applicants' || this.props.canDoAction;
  };

  onApprove = () => {
    this.toggle();
    this.props.onApprove();
  };

  onDecline = () => {
    this.toggle();
    this.props.onDecline();
  };

  showContactInformation = () => {
    const { job } = this.props;
    if (!job) return false;

    return ['confirmed', 'checked_in'].includes(job.currentState);
  };

  render() {
    const { id, companyName, reviewId } = this.props;
    const at = this.getAT();
    const profile = get(at, 'profile', {});

    if (at?.id !== id)
      return (
        <div className="experienceModal">
          <div
            className={classNames('plain-button', {
              'feedback-button': this.props.from === 'reviews',
              'modal-button': this.props.from !== 'reviews',
              'upcoming-button': this.props.from === 'upcoming',
            })}
            onClick={this.toggle}
            id={`atName-${reviewId}`}
            ref={this.columnRefs['atName']}
          >
            {this.props.children}
            {this.state.isOverflowing.atName && this.props.from === 'reviews' && (
              <UncontrolledTooltip target={`atName-${reviewId}`}>
                {this.props.children}
              </UncontrolledTooltip>
            )}
          </div>
        </div>
      );

    const { firstYearCertified, isCurrentStudent, graduationYear } =
      profile?.educationExperience || {};

    const reliabilityScore = computeReliabilityScore({
      worked: Number(at.shiftsWorked) || 0,
      callouts: Number(at.deCommits) || 0,
      decommits: Number(at.callouts) || 0,
    });

    let credentials = get(at, 'credentials', {});
    const resume = credentials.find((c) => c.type === 'resume');
    const education = sortBy(
      credentials.filter((c) => c.type === 'education'),
      [(c) => Number(c.first_year_certified)]
    ).reverse();
    const workExperience = sortBy(
      credentials.filter((c) => c.type === 'work_experience'),
      [(c) => new Date(c.approved_on)]
    ).reverse();
    credentials = credentials.filter(
      (c) => !['resume', 'education', 'work_experience'].includes(c.type)
    );

    const sortedCredentialKeys = Object.keys(credentials).sort((a, b) => {
      let priorityA = PRIORITY_FILTER[credentials[a].type];
      let priorityB = PRIORITY_FILTER[credentials[b].type];

      if (!priorityA) priorityA = 20;
      if (!priorityB) priorityB = 20;

      return priorityA - priorityB;
    });

    return (
      <div className="experienceModal">
        <div
          className={classNames('plain-button', {
            'feedback-button': this.props.from === 'reviews',
            'modal-button': this.props.from !== 'reviews',
            'upcoming-button': this.props.from === 'upcoming',
          })}
          onClick={this.toggle}
          id={`atName-${reviewId}`}
          ref={this.columnRefs['atName']}
        >
          {this.props.children}
          {this.state.isOverflowing.atName && this.props.from === 'reviews' && (
            <UncontrolledTooltip target={`atName-${reviewId}`}>
              {this.props.children}
            </UncontrolledTooltip>
          )}
        </div>
        <Modal
          isOpen={this.state.open}
          onOpened={this.onOpened}
          toggle={this.toggle}
          className="experience-modal-content"
          size="lg"
        >
          <ModalHeader className="header" toggle={this.toggle}>
            <div>
              <img src={at.profilePic || require('styles/BlankAvatar.svg')} alt={'AT'} />
              <p className="athletic-trainer-name">{at.name}</p>
            </div>
          </ModalHeader>
          <ModalBody>
            <div className="row pb-3">
              <div className="col-md-8">
                <div className="at-container">
                  <div className="at-info">
                    <p>
                      <b>Go4 Shifts</b>
                      <ToolTip
                        id="at-shifts"
                        bodyText="Total count of shifts worked by this AT on Go4."
                      />
                      <b>:</b>
                      <span>{at?.shiftsWorked}</span>
                    </p>
                    <p>
                      <b>Callouts</b>
                      <ToolTip
                        id="at-callouts"
                        bodyText="Total count of times this AT has called out within 7 days of a shift."
                      />
                      <b>:</b>
                      <span>{get(at, 'callouts', 0) + get(at, 'deCommits', 0)}</span>
                    </p>
                    <p>
                      <b>Reliability Score</b>
                      <ToolTip
                        id="at-reliability"
                        bodyText="This score measures the ratio of callouts within 7 days of a shift to the total number of shifts worked by the AT."
                      />
                      <b>:</b>
                      <span>
                        {reliabilityScore === 'rookie'
                          ? 'Go4 Rookie. No jobs worked yet.'
                          : reliabilityScore + '%'}
                      </span>
                    </p>
                    <p>
                      <b>{companyName} Shifts:</b>
                      <span>{this.state.jobCount}</span>
                    </p>
                    <p>
                      <b>Would I hire again?:</b>
                      <span>{this.state.message}</span>
                    </p>
                  </div>
                  <HorizontalRule dashed margin={10} />
                  {this.showContactInformation() && (
                    <div className="contact-information">
                      <h5>Contact information</h5>
                      <p>
                        <b>Email:</b> <span>{at.email}</span>
                      </p>
                      <p>
                        <b>Phone:</b> <span>{at.phoneNumber}</span>
                      </p>
                      <HorizontalRule dashed margin={10} />
                    </div>
                  )}
                  <div className="credentials">
                    <h5>Credentials</h5>
                    <ul>
                      {sortedCredentialKeys?.map((key, idx) => {
                        if (credentials[key].type === 'npi_number' && at.npiNumber)
                          return (
                            <li key={idx}>
                              <p className="credential-type">
                                NPI Number: <span>{at.npiNumber}</span>
                              </p>
                            </li>
                          );
                        if (credentials[key].type === 'nata_number' && at.nataNumber)
                          return (
                            <li key={idx}>
                              <p className="credential-type">
                                NATA Number: <span>{at.nataNumber}</span>
                              </p>
                            </li>
                          );
                        if (['nata', 'npi'].includes(key)) return null;
                        return (
                          <li key={idx}>
                            <CredentialModal
                              key={idx}
                              credential={credentials[key]}
                              at={at}
                              usStates={this.props.usStates}
                            />
                          </li>
                        );
                      })}
                    </ul>
                    <div className="actions-container">
                      <AddButton onClick={this.onDownloadAllCredentials} className="at-button">
                        DOWNLOAD ALL CREDENTIALS
                      </AddButton>
                      <DeleteButton onClick={this.toggleIssueModal} className="at-button">
                        REPORT CREDENTIAL ISSUE
                      </DeleteButton>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-md-4">
                {(profile || resume || education || workExperience) && (
                  <div className="experience">
                    <h5>EXPERIENCE</h5>
                    <div className="col-md-12">
                      <p className="experience-item">
                        {`${isCurrentStudent ? 'Graduation year:' : '1st Year Certified:'}`}
                      </p>
                      <p className="item">
                        {isCurrentStudent ? graduationYear : firstYearCertified}
                      </p>
                      <HorizontalRule dashed />

                      {!!education.length && (
                        <React.Fragment>
                          <p className="experience-item">Education:</p>
                          {education.map((edu, idx) => {
                            return (
                              <React.Fragment key={idx}>
                                {education.length > 1 && idx > 0 && <br />}
                                <p>{edu.institution}</p>
                                <p>{DEGREES[edu.license_type]}</p>
                                <p>Graduation year: {edu.first_year_certified}</p>
                              </React.Fragment>
                            );
                          })}
                          {(workExperience || profile) && <HorizontalRule dashed />}
                        </React.Fragment>
                      )}

                      {!!workExperience.length && (
                        <React.Fragment>
                          <p className="experience-item">Work Experience:</p>
                          {workExperience.map((exp, idx) => {
                            return (
                              <React.Fragment key={idx}>
                                {workExperience.length > 1 && idx > 0 && <br />}
                                <p>{exp.institution}</p>
                                <p>{exp.role}</p>
                                <p>
                                  {moment(exp.approved_on).format('MM/YYYY')} -{' '}
                                  {exp.end_date
                                    ? moment(exp.end_date).format('MM/YYYY')
                                    : 'Present'}
                                </p>
                              </React.Fragment>
                            );
                          })}
                          {profile && <HorizontalRule dashed />}
                        </React.Fragment>
                      )}

                      {resume && (
                        <>
                          <CredentialModal credential={resume} usStates={this.props.usStates} />
                          {profile && <HorizontalRule dashed />}
                        </>
                      )}
                      {profile && (
                        <React.Fragment>
                          <p className="experience-item">Settings:</p>
                          {profile.settingExperience.fields.map((setting, idx) => {
                            return (
                              <p key={idx} className="item">
                                {setting}
                              </p>
                            );
                          })}
                          <HorizontalRule dashed />
                          <p className="experience-item">Sports:</p>
                          {profile.sportExperience.fields.map((sport, idx) => {
                            return <p key={idx}>{sport}</p>;
                          })}
                        </React.Fragment>
                      )}
                    </div>
                  </div>
                )}
              </div>
            </div>
          </ModalBody>
          {this.showActions() && (
            <ModalFooter className="actions">
              <button className="btn decline-button" onClick={this.onDecline}>
                DECLINE
              </button>
              <button
                className={classNames('btn accept-button', {
                  acceptDisabled: this.props.acceptDisabled,
                })}
                disabled={this.props.acceptDisabled}
                onClick={this.onApprove}
              >
                ACCEPT
              </button>
            </ModalFooter>
          )}
        </Modal>
        <CredentialsIssueModal
          isOpen={this.state.issueModalOpen}
          toggle={this.toggleIssueModal}
          job={this.props.job}
          at={at}
          eo={this.props.session.currentUser}
          session={this.props.session}
        />
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    usStates: state.enums.usStates,
    companyName: state.session.currentUser.companyName,
    session: state.session,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    downloadAllCredentials: (ATId) => dispatch(downloadAllCredentials(ATId)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ExperienceModal);
