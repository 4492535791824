import React, { Component } from 'react';
import { connect } from 'react-redux';
import { groupBy, map, sum } from 'lodash';
import moment from 'moment';
import { get } from 'lodash';
/* eslint radix: "off" */

import { rateTypeNameFinder } from 'helpers/rateTypeName';
import { isEventCamp } from '../../../../helpers/isEventCamp';

import './BillingEstimateTableMobile.scss'
import { formatter } from 'helpers/formatter';
import { getEstimatedFee } from "helpers/getEstimatedFee";

export class BillingEstimateTable extends Component {
  formattedTime(time) {
    return moment(time).format('M/D/Y LT');
  }
  shiftDuration(shift) {
    const momentStart = moment(shift.start);
    const momentEnd = moment(shift.end);
    const duration = moment.duration(momentEnd.diff(momentStart));
    return duration.asHours();
  }

  shiftRate = shift => {
    const rate = this.props.payRate;
    if (this.props.rateTypeId === '1') {
      return rate * shift.capacity;
    } else {
      return rate * this.shiftDuration(shift) * shift.capacity;
    }
  }

  totalShiftRate = () => {
    return sum(this.props.shifts.map(this.shiftRate));
  }

  estimatedServiceFee = () => {
    if (this.props.currentUser.paymentFee != null && this.props.currentUser.paymentFee != undefined) {
      return this.props.currentUser.paymentFee * 100;
    }

    const { shifts, payment, eventProfessionId } = this.props;
    const maxStartTime = Math.max(...shifts.map(s => moment(s.start)));

    const isAmex = payment &&
        payment.source &&
        payment.source.object === "card" &&
        payment.source.brand === "American Express";

    return getEstimatedFee(maxStartTime, eventProfessionId, isAmex);
  }

  render() {
    const { shifts } = this.props;
    return (
      <div className="billingEstimateTableMobile">
        {shifts.map((shift, idx) =>
          <div className="item">
            <p>
              <h6>Time:</h6>
              {this.formattedTime(shift.start)} - {this.formattedTime(shift.end)}
            </p>

            <p>
              <h6>Location:</h6>
              {shift.eventLocation.formattedAddress.replace(', USA', '')}
              <br />
              {shift.eventLocation.name}{shift.eventLocation.details && ` / ${shift.eventLocation.details}`}
            </p>

            <p>
              <h6>Staff Needed:</h6>
              {shift.capacity}
            </p>

            <p>
              <h6>Shift hours:</h6>
              {!this.props.isEventCamp && this.shiftDuration(shift)}
              {this.props.isEventCamp && <span>-</span>}
            </p>

            <p>
              <h6>Rate:</h6>
              {formatter.format(this.props.payRate)} / {rateTypeNameFinder(this.props.rateTypes, this.props.rateTypeId)}
            </p>

            <p>
              <h6>Shift Sum:</h6>
              {formatter.format(this.shiftRate(shift))}
            </p>
          </div>
        )}
        <p>
          <h6>Service Fee:</h6>
          {formatter.format(this.totalShiftRate() * this.estimatedServiceFee() / 100)}
        </p>
        <p>
          <h6>Total:</h6>
          {formatter.format(this.totalShiftRate() * (1 + this.estimatedServiceFee() / 100))}
        </p>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    currentUser: state.session.currentUser,
    rateTypes: state.enums.rateTypes,
    payment: state.payment,
    eventTypeId: parseInt(state.createEvent.details.eventTypeId),
    isEventCamp: isEventCamp(state.createEvent.details.eventTypeId, state.enums.eventTypes),
    eventProfessionId: state.createEvent.details.eventProfessionId,
  }
}

export default connect(mapStateToProps)(BillingEstimateTable);
