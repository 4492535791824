import classNames from 'classnames';
import HorizontalRule from 'components/HorizontalRule';
import React from 'react';
import { Card, CardBody, CardHeader, Collapse } from 'reactstrap';

const WhatHappensNext = () => {
  const [open, setOpen] = React.useState(false);

  return (
    <div className="what-happens-next nimbus-regular-font">
      <Card>
        <CardHeader
          className={classNames('', { 'add-color': open })}
          onClick={() => setOpen(!open)}
        >
          What Happens Next
          <i
            className={classNames('fa arrow', {
              'fa-caret-down': open,
              'fa-caret-right': !open,
              'add-color': open,
            })}
          ></i>
        </CardHeader>
        <Collapse isOpen={open}>
          <CardBody>
            <span className="text-title">List your job on the Go4 AT Job Board / </span>
            <span className="text-content">
              There’s no listing fee and once your job is listed, ATs in your area can apply.
            </span>
            <HorizontalRule dashed margin="10px" />
            <span className="text-title">You control the hiring process / </span>
            <span className="text-content">
              When an AT applies to your job; you have seven days to accept the application. After
              you accept the application, the AT must confirm the job.
            </span>
            <HorizontalRule dashed margin="10px" />
            <span className="text-title">Payment / </span>
            <span className="text-content">
              At the end of the shift, Go4 charges your verified payment source to pay the AT.
            </span>
            <HorizontalRule dashed margin="10px" />
            <span className="text-title">Need to make changes? / </span>
            <span className="text-content">
              You can edit the job after it's posted. If shift times change we have a shift
              modification tool so you and the AT stay in-sync.
            </span>
          </CardBody>
        </Collapse>
      </Card>
    </div>
  );
};

export default WhatHappensNext;
