import * as postActions from 'redux/ducks/Post/actions';
import { pick, get } from 'lodash';

// Actions
// ========================================================
const constForName = (name) => `go4ellis/Post/Profile/${name}`;
export const FIELD_CHANGE = constForName('FIELD_CHANGE');

// Reducer Functions
// ========================================================
const fieldChangeReducer = (state, payload: { name: string, value: any }) => {
  return {
    ...state,
    [payload.name]: payload.value,
  };
};

const reloadFormReducer = (state, payload) => {
  return payload?.profile ? { ...payload.profile } : state;
};

const validateProfileReducer = (state, { page }) => {
  return {
    ...state,
    wasValidated: page === 'profile',
  };
};

const initProfileReduces = (state, payload) => {
  let eventSettingId = get(payload, 'eventSettingId', -1);
  if (Number(eventSettingId) === 3) eventSettingId = 4;

  return { ...DEFAULT_STATE, ...pick({ ...payload, eventSettingId }, Object.keys(DEFAULT_STATE)) };
};

// Action Creators
// ========================================================
export const changeProfileField = (payload) => {
  return { type: FIELD_CHANGE, payload };
};

// Reducer
// ========================================================
const DEFAULT_STATE = {
  eventSettingId: '',
  settingDetailId: '',
  title: '',
  sportId: '',
  genderId: '',
  ageBracketIds: [],
  jobDescriptionIds: ['0'],
  eventCode: '',
  eventLogo: null,
  participantNumbersId: '',
  imagePreview: null,
  instructions: '',
  jobDescriptionOther: '',
  settingDetailOther: '',
  sportOther: '',
  wasValidated: false,
  isFirstEvent: false,
};

export type ProfileType = typeof DEFAULT_STATE;

export default function reducer(state = DEFAULT_STATE, action = {}) {
  switch (action.type) {
    case FIELD_CHANGE:
      return fieldChangeReducer(state, action.payload);

    case postActions.V2_RELOAD_FORM_SUCCESS:
      return reloadFormReducer(state, action.payload);
    case postActions.V2_VALIDATE_FORM:
      return validateProfileReducer(state, action.payload);
    case postActions.V2_CLEAR_FORM:
      return DEFAULT_STATE;
    case postActions.SAVE_AS_DRAFT_SUCCESS:
    case postActions.SAVE_PROFILE_SUCCESS:
      return { ...state, ...action.payload.profile };
    case postActions.DUPLICATE_POST:
    case postActions.INITIALIZE_POST:
      return initProfileReduces(state, action.payload);
    default:
      return state;
  }
}
