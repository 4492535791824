import React, { Component } from 'react';
import EmergencyServices from '../EmergencyServices';
import VenueMap from '../VenueMap';
import ContactList from '../ContactList';
import EmergencyEquipment from '../EmergencyEquipment';

import {
  fetchFireDeptSuccess,
  fetchHospitalSuccess,
  fetchPoliceSuccess,
  mapUpdated,
  instructionsUpdated,
  addContact,
  removeContact,
  contactUpdated,
  addEquipment,
  removeEquipment,
  equipmentUpdated,
  clearContacts,
  clearEquipment,
} from 'redux/ducks/CreateEvent/EAP/actions';

class GenerateEAPForm extends Component {
  onMapLoad = (map) => {
    this.props.dispatch(mapUpdated({ map }))
  }

  onHospitalChange = (hospital) => {
    this.props.dispatch(fetchHospitalSuccess({ hospital }))
  }

  onFireDeptChange = fireDepartment => {
    this.props.dispatch(fetchFireDeptSuccess({ fireDepartment }))
  }

  onPoliceChange = policeStation => {
    this.props.dispatch(fetchPoliceSuccess({ policeStation }))
  }

  onInstructionsChange = ev => {
    const instructions = ev.currentTarget.value;
    this.props.dispatch(instructionsUpdated(instructions))
  }

  onAddContact = () => this.props.dispatch(addContact());
  onRemoveContact = (index) => this.props.dispatch(removeContact(index));
  onChangeContact = (index, contact) => this.props.dispatch(contactUpdated({ index, contact }));
  clearContacts = () => this.props.dispatch(clearContacts())

  onAddEquipment = () => this.props.dispatch(addEquipment());
  onRemoveEquipment = (index) => this.props.dispatch(removeEquipment(index));
  onChangeEquipment = (index, equipment) => this.props.dispatch(equipmentUpdated({ index, equipment }));
  clearEquipment = () => this.props.dispatch(clearEquipment())

  render() {
    return (
      <div className="generateEAPForm">
        <VenueMap
          onMapLoad={this.onMapLoad}
          imageSrc={this.props.formInstance.venueMapUrl}
        />
        <EmergencyServices
          hospital={this.props.formInstance.hospital}
          policeStation={this.props.formInstance.policeStation}
          fireDepartment={this.props.formInstance.fireDepartment}
          onHospitalChange={this.onHospitalChange}
          onFireDeptChange={this.onFireDeptChange}
          onPoliceChange={this.onPoliceChange}
        />
        <ContactList
          contacts={this.props.formInstance.emergencyContacts}
          addContact={this.onAddContact}
          removeContact={this.onRemoveContact}
          changeContact={this.onChangeContact}
          clearContacts={this.clearContacts}
        />
        <EmergencyEquipment
          equipmentList={this.props.formInstance.emergencyEquipment}
          onChange={this.onChangeEquipment}
          addEquipment={this.onAddEquipment}
          removeEquipment={this.onRemoveEquipment}
          clearEquipment={this.clearEquipment}
        />
        <div className="section-header col facility">Facility-Specific Instructions</div>
        <div>
          <p>Please enter in any safety information that is specific to the facility or not clearly demonstrated on the venue map.  This could include: ambulance access points to each field or building, lightning-safe shelter instructions, inclement weather plans, or other similar information.</p>
          <textarea onChange={this.onInstructionsChange} value={this.props.formInstance.instructions} type="text" className="form-control" placeholder="Instructions" />
        </div>
        <div id="attribution"></div>
      </div>
    );
  }
}

export default GenerateEAPForm;