import React from 'react';
import { FOUR, INNERCIRCLE, OUTERCIRCLE } from './SpinnerSVG';

const Go4Spinner = ({ style = {} }) => {
  return (
    <div className="loading-modal">
      <div className="spinner-container" style={style}>
        <FOUR className="four" style={style} />
        <INNERCIRCLE className="four spin-clockwise" style={style} />
        <OUTERCIRCLE className="four spin-trigonometric" style={style} />
      </div>
    </div>
  );
};

export default Go4Spinner;
