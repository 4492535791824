import React, { Component } from "react";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";
import { destroySession } from "redux/ducks/Session";

export class LogoutScene extends Component {
  state = {};

  componentDidMount() {
    this.props.logout();
    this.setState({ role: this.props.user.role });
  }

  getRoute() {
    return this.state.role === "Parent"
      ? "/v2/parent/login"
      : this.state.role === "Patient"
        ? "/v2/patient/login"
        : "/v2/login";
  }

  render() {
    return this.props.authenticated ? null : <Redirect to={this.getRoute()} />;
  }
}

function mapStateToProp(state) {
  return {
    authenticated: state.session.authenticated,
    user: state.session.currentUser,
  };
}

export function mapDispatchToProps(dispatch) {
  return {
    logout: () => dispatch(destroySession()),
  };
}

export default connect(mapStateToProp, mapDispatchToProps)(LogoutScene);
