import { Modal, ModalBody, ModalHeader, UncontrolledAlert } from 'reactstrap';
import HorizontalRule from 'components/HorizontalRule';
import React from 'react';
import { connect } from 'react-redux';
import { createSession } from 'redux/ducks/Session';
import LoginForm from '../LoginScene/LoginForm';

const LogInModal = ({ history, isOpen, toggleLogInModal, ...props }) => {
  const [email, setEmail] = React.useState('');
  const onSubmit = ({ email, password, rememberMe, role }) => {
    props.login({
      email: email,
      password: password,
      rememberMe: rememberMe,
      role: role,
      history: history,
    });
  };

  let modalOpen = isOpen;
  if (!props.error && props.session && props.session.authenticated) {
    modalOpen = false;
  }

  return (
    <Modal
      isOpen={modalOpen}
      toggle={toggleLogInModal}
      className="login-modal"
      size="sm"
      style={{ maxWidth: '500px' }}
      backdrop="static"
    >
      <ModalHeader toggle={toggleLogInModal}>Login to Your Account</ModalHeader>
      <ModalBody className={'px-4 bg-white py-0'}>
        <HorizontalRule margin={0} />
        <main className="container">
          {props.error &&
            (props.error.message === 'unconfirmed' ? (
              <UncontrolledAlert color="danger" className="alert-margin">
                You need to verify your email before accessing your account. Didn’t receive the
                email?&nbsp;
                <button
                  onClick={() => {
                    history.push({
                      pathname: '/v2/resend-confirmation',
                      state: { email },
                    });
                  }}
                  className="btn-link gotham-bold-font plain-button"
                >
                  Click here
                </button>
                .
              </UncontrolledAlert>
            ) : (
              <UncontrolledAlert color="danger" className="alert-margin">
                {props.error.message || 'Invalid email or password'}
              </UncontrolledAlert>
            ))}
          <LoginForm {...props} onSubmit={onSubmit} setConfirmationEmail={setEmail} />
        </main>
      </ModalBody>
    </Modal>
  );
};

const mapStateToProp = (state, ownProps) => {
  const { session } = state;
  const { error, authenticated, currentUser } = session;

  if (ownProps.from === 'action-bar') return { error, session };

  if (!error && session && authenticated) {
    if (currentUser.role === 'Parent') {
      ownProps.history.push('/parent/dashboard');
    } else if (currentUser.role === 'Patient') {
      ownProps.history.push('/patient/dashboard');
    } else {
      ownProps.history.push('/dashboard');
    }
  }

  return {
    error,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    login: (credentials) => dispatch(createSession(credentials)),
  };
};

export default connect(mapStateToProp, mapDispatchToProps)(LogInModal);
