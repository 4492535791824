// @flow
import React, { Component } from 'react';
import { Modal, ModalHeader, ModalBody } from 'reactstrap';
import { startsWith } from 'lodash';

const domain = process.env.REACT_APP_DOMAIN;

type VenueMapProps = {
  onMapLoad: (any) => any;
  imageSrc: ?string;
}

class VenueMap extends Component<VenueMapProps, { modalOpen: boolean }> {
  fileReader = new FileReader();
  state = {
    modalOpen: false
  }
  constructor() {
    super();
    this.fileReader.addEventListener('load', () => {
      this.props.onMapLoad(this.fileReader.result);
    });
  }

  togglePreviewModal = () => {
    this.setState({ modalOpen: !this.state.modalOpen })
  }

  onMapChange = (ev: SyntheticEvent<HTMLInputElement>) => {
    this.fileReader.readAsDataURL(ev.currentTarget.files[0]);
  }

  urlFor(previewUrl: string) {
    return startsWith(previewUrl, '/') ? domain + previewUrl : previewUrl;
  }

  render() {
    return (
      <div className="venueMap">
        <div className="section-header col">Venue Map</div>
        <section>
          <div className="row">
            <div className="col-sm-4">
              <p className="upload-vmap-label">If you have a venue map, upload here.</p>
              <section>
                <input onChange={this.onMapChange} type="file" name="venue_map" id='venue_map' className="custom-file-input" accept="image/*" />
                <label className="btn btn-primary custom-file-label" htmlFor={["file_label_type", "venue_map"]}>Upload Venue Map</label>
              </section>
            </div>
            <div className="col-sm-4 offset-sm-2">
              {this.props.imageSrc && <img className="venue-map-preview" src={this.urlFor(this.props.imageSrc)} alt="A preview of the venue map" onClick={this.togglePreviewModal} />}
            </div>
          </div>
        </section>
        <Modal
          modalClassName="venue-map-modal"
          isOpen={this.state.modalOpen}
          toggle={this.togglePreviewModal} >
          <ModalHeader toggle={this.togglePreviewModal}>
            Venue Map
          </ModalHeader>
          <ModalBody>
            <img src={this.props.imageSrc || '/event-logo-default.png'} alt="A preview of the venue map" />
          </ModalBody>
        </Modal>
      </div>
    );
  }
}

export default VenueMap;