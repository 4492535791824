import React, { useState } from 'react';
import { useStripe, useElements, CardElement } from "@stripe/react-stripe-js";
import { connect } from 'react-redux';
import {
  updateToken,
  submit,
} from 'redux/ducks/Payment';
import classnames from 'classnames';

const CARD_OPTIONS = {
  iconStyle: "solid",
  style: {
    base: {
      fontWeight: 500,
      fontSize: "16px",
      fontSmoothing: "antialiased",
      color: '#424770',
      letterSpacing: '0.025em',
      '::placeholder': {
        color: '#aab7c4',
      },
    },
    invalid: {
      color: '#f33',
    },
  }
};

const ErrorMessage = ({ children }) => (
  <div className="errorMessage" role="alert">
    <svg width="16" height="16" viewBox="0 0 17 17">
      <path
        fill="#FFF"
        d="M8.5,17 C3.80557963,17 0,13.1944204 0,8.5 C0,3.80557963 3.80557963,0 8.5,0 C13.1944204,0 17,3.80557963 17,8.5 C17,13.1944204 13.1944204,17 8.5,17 Z"
      />
      <path
        fill="#6772e5"
        d="M8.5,7.29791847 L6.12604076,4.92395924 C5.79409512,4.59201359 5.25590488,4.59201359 4.92395924,4.92395924 C4.59201359,5.25590488 4.59201359,5.79409512 4.92395924,6.12604076 L7.29791847,8.5 L4.92395924,10.8739592 C4.59201359,11.2059049 4.59201359,11.7440951 4.92395924,12.0760408 C5.25590488,12.4079864 5.79409512,12.4079864 6.12604076,12.0760408 L8.5,9.70208153 L10.8739592,12.0760408 C11.2059049,12.4079864 11.7440951,12.4079864 12.0760408,12.0760408 C12.4079864,11.7440951 12.4079864,11.2059049 12.0760408,10.8739592 L9.70208153,8.5 L12.0760408,6.12604076 C12.4079864,5.79409512 12.4079864,5.25590488 12.0760408,4.92395924 C11.7440951,4.59201359 11.2059049,4.59201359 10.8739592,4.92395924 L8.5,7.29791847 L8.5,7.29791847 Z"
      />
    </svg>
    {children}
  </div>
);

const NewCreditCard = ({ userId, updateToken, submit }) => {
  const stripe = useStripe();
  const elements = useElements();
  const [error, setError] = useState(null);

  const handleSubmit = async event => {
    event.preventDefault();

    if (!stripe || !elements) {
      // Stripe.js has not loaded yet. Make sure to disable
      // form submission until Stripe.js has loaded.
      return;
    }

    if (error) {
      elements.getElement("card").focus();
      return;
    }

    const payload = await stripe.createToken(
      elements.getElement(CardElement)
    );

    if (payload.error) {
      setError(payload.error);
    } else {
      updateToken(payload.token);
      submit(userId, payload.token);
      setError(null);
    }
  };

  return (
    <form onSubmit={handleSubmit} className="newCreditCard">
      <div className="stripeCard">
        <CardElement
          options={CARD_OPTIONS}
          onReady={() => {
            console.log("CardElement [ready]");
          }}
          onChange={event => {
            setError(null);
            console.log("CardElement [change]", event);
          }}
          onBlur={() => {
            console.log("CardElement [blur]");
          }}
          onFocus={() => {
            console.log("CardElement [focus]");
          }}
        />
      </div>
      {error && <ErrorMessage>{error.message}</ErrorMessage>}
      <div className="actions">
        <button className={classnames("btn btn-danger bold-button", {
          'disabled': !stripe || !elements
        })} type="submit" disabled={!stripe || !elements}>
          Update Credit Card
        </button>
      </div>
    </form >
  );
};

const mapStateToProps = (state) => ({
  userId: state.session.currentUser.id,
});
const mapDispatchToProps = (dispatch) => ({
  updateToken: (token) => dispatch(updateToken(token)),
  submit: (userId, token) => dispatch(submit(userId, token)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(NewCreditCard);
