// @flow
import React from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";

import type { AppState } from "redux/ducks";
import type { Enums } from "redux/ducks/helpers";
import { getEnumName, getJobDescriptionNames } from "helpers/enums";
import type { EventDetailsFormType } from "redux/ducks/CreateEvent/Details";
import { formatter } from "helpers/formatter";
import classnames from "classnames";

type EventDetailsPanelProps = {
  details: EventDetailsFormType,
  enums: Enums,
  onFieldChange: (name: string, newValue: any) => void,
};

const EventDetailsPanel = (props) => {
  const editUrl = /edit/.test(window.location.href)
    ? window.location.href.match(/\/events\/\d+\//)[0] + "edit"
    : "1";
  const { details, enums, onFieldChange, newJobCreationFlow } = props;
  const { eventSettings, sports, settingDetails, jobDescriptions } = enums;
  const { eventSettingId, sportId, settingDetailId, jobDescriptionIds } =
    details;
  const eventSetting = getEnumName(eventSettings, eventSettingId);
  const settingDetail = getEnumName(settingDetails, settingDetailId);
  const sportType = getEnumName(sports, sportId);
  const jobDescriptionNames = getJobDescriptionNames(
    jobDescriptions,
    jobDescriptionIds
  );

  const fileReader = (() => {
    const reader = new FileReader()
    reader.addEventListener('load', () => {
      console.log(reader.result)
      onFieldChange('imagePreview', reader.result);
    });
    return reader;
  })();


  const onEventImageChange = (event: SyntheticInputEvent<HTMLInputElement>) => {
    const target = event.target;
    const file = target.files[0];
    fileReader.readAsDataURL(file);
    onFieldChange('eventLogo', file);
  };

  return (
    <div className="row eventDetailsPanel">
      <div className={classnames("event-logo-preview image-preview col-sm-2", { "col-lg-1": props.newJobCreationFlow })}>
        <input
          className={classnames('custom-file-input event-logo-input', {
            'v2-event-logo-input': props.newJobCreationFlow
          })}
          type="file"
          name="event_logo"
          accept="image/*"
          onChange={onEventImageChange}
        />
        <img
          alt="A preview of the event logo"
          className="image-preview"
          src={
            details.imagePreview || require("styles/EventLogoPlaceholder.png")
          }
        ></img>
        {!details.imagePreview && <span className="image-preview-text">Add{"\n"} event logo</span>}
      </div>
      <div className={`col d-flex flex-column justify-content-around eap-details-v2`}>
        <div className="event-title">{details.title}</div>
        {/* TODO: See how to introduce pay rate ranges */}
        {!newJobCreationFlow && <div className="pay-rate">
          {formatter.format(details.payRate)} /{" "}
          {details.rateTypeId === "1"
            ? "Shift"
            : getEnumName(enums.rateTypes, details.rateTypeId)}
        </div>}
        <div>
          {`${eventSetting} / ${settingDetail}`}
          {eventSetting === "Sports" &&
            (details.eventProfessionId === 0 ||
              settingDetail === "Sporting Event") &&
            ` / ${sportType}`}
        </div>
        <div>{jobDescriptionNames}</div>
        {!newJobCreationFlow && <div>
          <Link to={editUrl}>
            <i className="fas fa-pencil-alt" /> EDIT
          </Link>
        </div>}
      </div>
    </div>
  );
};

function mapStateToProps(state: AppState): EventDetailsPanelProps {
  return {
    enums: state.enums,
  };
}


export default connect(mapStateToProps)(EventDetailsPanel);
