import React, { Component, useState, useEffect, Fragment } from 'react';

import { startCase } from 'lodash';
import {
  TITLES,
  HEAD_TITLES,
  DOCUMENTS,
  SPECIALTIES,
} from '../AthleticTrainerModal/credentialHelpers';
import { Modal, ModalHeader, ModalBody } from 'reactstrap';
import heic2any from 'heic2any';
import moment from 'moment';
import { stateLinks } from './Constants';
import { pdfjs } from 'react-pdf';
import PdfDocument from '../AthleticTrainerModal/PdfDocument';
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;

function MaybeHeicImage(props) {
  const [value, setState] = useState({
    loading: true,
    imageSrc: null,
    rotation: 90,
  });

  function usePromise(url) {
    useEffect(() => {
      let isMounted = true;

      fetch(url)
        .then((res) => res.blob())
        .then((blob) =>
          heic2any({
            blob,
            toType: 'image/jpeg',
            quality: 0.5,
          })
        )
        .then((conversionResult) => {
          var url = URL.createObjectURL(conversionResult);
          if (isMounted) {
            setState({
              ...state,
              loading: false,
              imageSrc: url,
            });
          }
        })
        .catch((e) => {
          if (isMounted) {
            setState({
              ...state,
              loading: false,
              imageSrc: props.src,
            });
            console.error(e);
          }
        });

      return () => {
        isMounted = false;
      };
    }, [url]);

    return value;
  }

  const state = usePromise(props.src);
  if (state.loading) {
    return (
      <p>
        <a href={props.src} target="_blank" rel="noopener noreferrer">
          Loading image. This might take a while... Alternatively click here to download locally.
        </a>
      </p>
    );
  }

  let styles = {
    rotateIcons: {
      height: 30,
      width: 30,
    },
    imageWrapper: {
      minHeight: '800px',
      backgroundImage: `url(${state.imageSrc})`,
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'center',
      backgroundSize: 'contain',
      width: 'auto',
      transform: `rotate(${state.rotation}deg)`,
    },
  };

  let setRotation = (deg) => {
    setState({
      ...state,
      rotation: state.rotation + deg,
    });
  };

  return (
    <div>
      <img
        style={styles.rotateIcons}
        src={require('./rotate-left.svg')}
        onClick={() => setRotation(-90)}
        alt="rotate left"
      />
      <img
        style={styles.rotateIcons}
        src={require('./rotate-right.svg')}
        onClick={() => setRotation(90)}
        alt="rotate right"
      />
      <div
        style={styles.imageWrapper}
        {...props}
        src={state.imageSrc}
        onClick={() => window.open(props.src, '_blank')}
      ></div>
      <hr></hr>
    </div>
  );
}

class CredentialModal extends Component {
  state = {
    modalOpen: false,
    numPages: null,
    pageNumber: 1,
  };

  constructor() {
    super();

    this.onToggle = this.onToggle.bind(this);
  }

  onToggle() {
    this.setState({ modalOpen: !this.state.modalOpen });
  }

  prettyPrintJson = (json) =>
    Object.keys(json).map((key) => {
      const value = json[key];
      return (
        <div className="addon" key={key}>
          {key !== 'other' && (
            <>
              <strong>{SPECIALTIES[key]}</strong> - {value}
            </>
          )}
          {key === 'other' && (
            <>
              <strong>{startCase(value.specialty)}</strong> - {value.timeFrame}
            </>
          )}
        </div>
      );
    });

  findState = (state) => {
    const found = this.props.usStates.find((element) => element.abbreviation === state);
    return !!found ? found.name : state;
  };

  render() {
    const { credential } = this.props;

    return (
      <div className="credentialModal">
        <p>
          {!!TITLES[credential.type] && (
            <div>
              {' '}
              <i className="fa fa-check" />
              <strong>{TITLES[credential.type]}</strong>
              {!!credential.specialty_type &&
                this.prettyPrintJson(JSON.parse(credential.specialty_type))}
              {!!credential.role && <span> {credential.role}</span>}
            </div>
          )}

          {!!credential.license_type && (
            <div>
              {' '}
              <i className="fa fa-check" />
              <strong>License Type: </strong>
              {credential.license_type}
            </div>
          )}
          {!!credential.states_valid && (
            <div className="addon">
              <strong>License State: </strong>
              {this.findState(credential.states_valid)}
            </div>
          )}
          {!!credential.license_number && (
            <div className="addon">
              <strong>License Number: </strong>#{credential.license_number}
            </div>
          )}
          {!!credential.certification && (
            <div>
              <i className="fa fa-check" />
              <strong>Certification: </strong>
              {credential.certification}
            </div>
          )}
          {!!credential.institution && (
            <div className="addon">
              <strong>Certifying Institution: </strong>
              {credential.institution}
            </div>
          )}
          {credential.valid_through && (
            <div className="addon">
              <strong>{DOCUMENTS[credential.type]} Valid Until: </strong>
              {moment(new Date(credential.valid_through)).format('MM/DD/YYYY')}
            </div>
          )}
          {!!credential.states_valid && (
            <div className="addon">
              {credential.states_valid.split(',').map((state, i) => {
                return (
                  <Fragment key={i}>
                    <a key={i} href={stateLinks[state]} target="_blank" rel="noopener noreferrer">
                      {`Click for ${state} License/Certification Verification`}
                    </a>
                  </Fragment>
                );
              })}
            </div>
          )}
          <div className="addon">
            <a className="toggle" href="#" onClick={this.onToggle} rel="noopener noreferrer">
              {credential.type !== 'recent_work_experience' &&
                credential.type !== 'current_role' && (
                  <span>Click to view {DOCUMENTS[credential.type]}</span>
                )}
            </a>
          </div>
        </p>

        <Modal
          isOpen={this.state.modalOpen}
          toggle={this.onToggle}
          modalClassName="credential-modal"
        >
          <ModalHeader toggle={this.onToggle}>
            {HEAD_TITLES[credential.type]}
            {credential.license_number && <p>License number: {credential.license_number}</p>}
          </ModalHeader>
          <ModalBody>
            {!!credential.certification && (
              <div>
                <strong>Certification: </strong> {credential.certification}
              </div>
            )}
            {!!credential.institution && (
              <div>
                <strong>Institution: </strong> {credential.institution}
              </div>
            )}
            {credential.document && (
              <div>
                <a href={credential.document.url}>Attachment {credential.document.fileName}</a>

                <PdfDocument pdfFile={credential.document.url} />
              </div>
            )}
            {credential.pages.map((page) => (
              <MaybeHeicImage src={page.url} alt={page.pageNumber} key={page.pageNumber} />
            ))}
          </ModalBody>
        </Modal>
      </div>
    );
  }
}

export default CredentialModal;
