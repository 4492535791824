import { ofType } from 'redux-observable';
import { catchError, mergeMap, filter, mapTo, map } from 'rxjs/operators';
import { of } from 'rxjs/observable/of';
import { endsWith, get } from 'lodash';
import { SessionService, UserService } from 'services';
import { SESSION_EMAIL_KEY } from 'services/SessionService';
import { mappingEpic } from 'redux/ducks/helpers';
import {
  CREATE_SESSION,
  CREATE_SESSION_ERROR,
  CREATE_SESSION_SUCCESS,
  DESTROY_SESSION,
  DESTROY_SESSION_ERROR,
  DESTROY_SESSION_SUCCESS,
  RELOAD_SESSION,
  RELOAD_SESSION_ERROR,
  RELOAD_SESSION_FAILURE,
  RELOAD_SESSION_SUCCESS,
  RELOAD_USER_SUCCESS,
  RELOAD_USER_ERROR,
  UPDATE_SESSION,
  INVALID_SESSION,
} from './index';
import { UPDATE_USER_SUCCESS } from 'redux/ducks/User';
import { SHOW_SPINNER, HIDE_SPINNER } from 'redux/ducks/LoadingSpinner';

// Constants
// ========================================================
const STORAGE_KEY = 'session';
const sessionService = SessionService ? new SessionService() : null;

// Epics
// ========================================================
export const createSessionEpic = (action$) => {
  return action$.pipe(
    ofType(CREATE_SESSION),
    mergeMap((action) => {
      window.localStorage.clear();
      window.sessionStorage.clear();

      const history = action.payload.history;
      return sessionService
        .login(action.payload)
        .then((payload) => {
          payload.session.rememberMe = action.payload.rememberMe;
          const stringPayload = JSON.stringify(payload.session);

          if (action.payload.rememberMe) {
            window.localStorage.setItem(STORAGE_KEY, stringPayload);
            window.localStorage.setItem(SESSION_EMAIL_KEY, payload.session.uid);
          } else {
            window.sessionStorage.setItem(STORAGE_KEY, stringPayload);
          }

          return {
            type: CREATE_SESSION_SUCCESS,
            payload: {
              ...payload,
              history,
              callbackAction: action.payload.callbackAction,
              callbackPayload: action.payload.callbackPayload,
            },
          };
        })
        .catch((error) => {
          let { status, message, headers } = error.response;
          if (status === 403) {
            if (headers && headers['location']) {
              const url = new URL(headers['location']);
              const role = url.pathname.split('/').pop();

              if (role === 'athletic-trainer') {
                message = 'Please login as a Job Poster';
              } else {
                history.push(url.pathname);
                message = 'Please login via this other portal';
              }
            } else {
              message = 'Please login via this other portal';
            }
          } else if ([401, 404].includes(status)) {
            message = 'Invalid email or password';
          } else if (status === 500) {
            message = 'Something went wrong';
          }

          return {
            type: CREATE_SESSION_ERROR,
            payload: {
              ...error,
              message,
            },
          };
        });
    })
  );
};

export const callCreateSessionCallbackEpic = (action$) => {
  return action$.pipe(
    ofType(CREATE_SESSION_SUCCESS),
    filter((action) => action.payload.callbackAction),
    map((action) => {
      return {
        type: action.payload.callbackAction,
        payload: action.payload.callbackPayload,
      };
    })
  );
};

export const destroySessionEpic = (action$, store) => {
  return action$.pipe(
    ofType(DESTROY_SESSION),
    mergeMap((action) => {
      const session = store.getState().session;
      window.localStorage.clear();
      window.sessionStorage.clear();

      if (session.rememberMe) {
        window.localStorage.setItem(SESSION_EMAIL_KEY, session.uid);
      }

      return sessionService
        .logout(session)
        .then(() => {
          return { type: DESTROY_SESSION_SUCCESS, payload: action.payload };
        })
        .catch((error) => ({
          type: DESTROY_SESSION_ERROR,
          payload: error,
        }));
    })
  );
};

export const reloadSessionEpic = (action$) => {
  return action$.pipe(
    ofType(RELOAD_SESSION),
    mergeMap(() => {
      const localStorage = window.localStorage.getItem(STORAGE_KEY);
      const sessionStorage = window.sessionStorage.getItem(STORAGE_KEY);

      let session = localStorage ? localStorage : sessionStorage;

      if (session) {
        return of({
          type: RELOAD_SESSION_SUCCESS,
          payload: JSON.parse(session),
        });
      } else {
        return of({ type: RELOAD_SESSION_FAILURE });
      }
    }),
    catchError((error) => of({ type: RELOAD_SESSION_ERROR, payload: error }))
  );
};

export const reloadUserInSessionEpic = (action$, store) => {
  return action$.pipe(
    ofType(RELOAD_SESSION_SUCCESS),
    mergeMap((action) => {
      const currentUser = action.payload.currentUser;

      return new UserService(action.payload)
        .get(currentUser.id)
        .then((user) => ({ type: RELOAD_USER_SUCCESS, payload: user }))
        .catch((error) => ({ type: RELOAD_USER_ERROR, payload: error }));
    })
  );
};

export const trackAppVersionEpic = (action$, store) => {
  return action$.pipe(
    ofType(RELOAD_SESSION_SUCCESS, CREATE_SESSION_SUCCESS),
    mergeMap((action) => {
      const currentUserId = get(store.getState(), 'session.currentUser.id');
      return new Promise(() => {
        if (window._cio) {
          window._cio.identify({
            id: currentUserId,
            app_version: process.env.REACT_APP_VERSION,
          });
        }
      });
    })
  );
};

export const updateUserInSessionEpic = (action$) => {
  return action$.pipe(
    ofType(UPDATE_USER_SUCCESS, RELOAD_USER_SUCCESS),
    mergeMap((action) => {
      const user = action.payload;
      const localStorage = window.localStorage.getItem(STORAGE_KEY);
      const sessionStorage = window.sessionStorage.getItem(STORAGE_KEY);

      let session = null;
      let storageType = null;

      if (localStorage) {
        session = JSON.parse(localStorage);
        storageType = 'local';
      } else {
        session = JSON.parse(sessionStorage);
        storageType = 'session';
      }

      if (session.currentUser.id === user.id) {
        session.currentUser = user;

        const stringSession = JSON.stringify(session);

        if (storageType === 'local') {
          window.localStorage.setItem(STORAGE_KEY, stringSession);
        } else {
          window.sessionStorage.setItem(STORAGE_KEY, stringSession);
        }

        return of({
          type: UPDATE_SESSION,
          payload: { session: session },
        });
      }
    })
  );
};

export const catchApiErrorsEpic = (action$, store) => {
  return action$.pipe(
    filter((action) => action.type !== CREATE_SESSION_ERROR),
    filter((action) => action.type !== DESTROY_SESSION_ERROR),
    filter((action) => endsWith(action.type, 'ERROR')),
    filter((action) => get(action, 'payload.response.status') === 401),
    mapTo({
      type: INVALID_SESSION,
      payload: {
        message: 'Your session has expired. Please log in again.',
      },
    })
  );
};

export const showSpinnerEpic = mappingEpic([CREATE_SESSION, DESTROY_SESSION], SHOW_SPINNER);

export const hideSpinnerEpic = mappingEpic(
  [CREATE_SESSION_ERROR, DESTROY_SESSION_ERROR, DESTROY_SESSION_SUCCESS, INVALID_SESSION],
  HIDE_SPINNER
);
