import React, { Component } from 'react';
import { values, get } from 'lodash';

import ShiftFinancesJobListItem from './ShiftFinancesJobListItem';

const moment = require('moment');

class ShiftFinancesListItem extends Component {
  formatTime = (time) => {
    return moment(time).format("M/D/YY h:mmA");
  };

  get jobs() {
    return values(this.props.shift.jobs.byId)
      .filter(job => {
        return get(job, 'payment')
      })
  }

  render() {
    return (
      <div className="shiftFinancesListItem">
        <div className="header">
          <div>
            {this.formatTime(this.props.shift.startTime)} - {this.formatTime(this.props.shift.endTime)}
          </div>
          <div>{this.props.shift.eventLocation.name}</div>
        </div>
        <div className="details">
          {this.jobs.map((job, index) => {
            return <ShiftFinancesJobListItem job={job} key={index} />
          })}
        </div>
      </div>
    );
  }
}

export default ShiftFinancesListItem;