import React, { Component } from 'react';

import { connect } from 'react-redux';

import classnames from 'classnames';

import { get, isString } from 'lodash';

import {
  applyPastFilters,
  resetPastFilters,
  updatePastFilters,
} from 'redux/ducks/Event';
import { getAthleticTrainers } from 'redux/ducks/User';
import { sportsFilter } from '../../helpers/enums';

import CollapsibleFilterPanel from 'components/CollapsibleFilterPanel';
import InputOrFlatpickr from 'components/InputOrFlatpickr';

export class FinanceFiltersPanel extends Component {

  constructor(props) {
    super(props);

    this.props.getAthleticTrainers();
  }

  componentWillMount() {
    this.props.updateFilter('past', true);
  }

  componentWillUnmount() {
    this.props.reset();
  }

  inputColor = (field) => {
    return this.props.filters[field] ? 'selected' : 'blank';
  };

  onFieldChange = (field, event) => {
    const value = isString(event) ? event : get(event, 'target.value');
    this.props.updateFilter(field, value);
  };

  onReset = () => {
    this.props.reset({ past: true });
  };

  render() {
    return (
      <div className="eventFiltersPanel">
        <CollapsibleFilterPanel title='Event Filters'
          onCancel={this.onReset}
          applyFilters={this.props.applyFilters}
          onSubmit={this.props.submit}>
          <div className='form-group search-group'>
            <i className='fa fa-search' />
            <input className='form-control'
              placeholder='SEARCH EVENT NAME'
              onChange={this.onFieldChange.bind(this, 'title')}
              value={this.props.filters.title} />
          </div>
          <div className='form-group'>
            <label>Event Code</label>
            <input className='form-control'
              placeholder='Enter Event Code'
              onChange={this.onFieldChange.bind(this, 'eventCode')}
              value={this.props.filters.eventCode} />
          </div>
          <div className='form-group'>
            <label>Job Start Date</label>
            <InputOrFlatpickr className={classnames('form-control picker', this.inputColor('startDate'))}
              placeholder='Select Start Date'
              onChange={this.onFieldChange.bind(this, 'startDate')}
              value={this.props.filters.startDate}
              type='date' />
          </div>
          <div className='form-group'>
            <label>Job End Date</label>
            <InputOrFlatpickr className={classnames('form-control picker', this.inputColor('endDate'))}
              placeholder='Select End Date'
              onChange={this.onFieldChange.bind(this, 'endDate')}
              value={this.props.filters.endDate}
              type='date' />
          </div>
          <div className='form-group'>
            <label>Payment Succeeded Date</label>
            <InputOrFlatpickr className={classnames('form-control picker', this.inputColor('stripePaidAt'))}
              placeholder='Select Payment Succeeded Date'
              onChange={this.onFieldChange.bind(this, 'stripePaidAt')}
              value={this.props.filters.stripePaidAt}
              type='date' />
          </div>
          <div className='form-group'>
            <label>Total Payment</label>
            <input className='form-control'
              type="number"
              step="0.01"
              onChange={this.onFieldChange.bind(this, 'eoTotalCharge')}
              value={this.props.filters.eoTotalCharge} />
          </div>
          <div className='form-group'>
            <label>Healthcare Professional</label>
            <select className={classnames('form-control', 'custom-select', this.inputColor('healthcareProfessional'))}
              onChange={this.onFieldChange.bind(this, 'healthcareProfessional')}
              value={this.props.filters.healthcareProfessional}>
              <option value='' disabled hidden>Select Option</option>
              <option value=''></option>
              {this.props.athleticTrainers.map((trainer) => {
                return <option value={trainer.id} key={trainer.id}>{trainer.name}</option>
              })}
            </select>
          </div>
          <div className='form-group'>
            <label>Setting</label>
            <select className={classnames('form-control', 'custom-select', this.inputColor('eventSetting'))}
              onChange={this.onFieldChange.bind(this, 'eventSetting')}
              value={this.props.filters.eventSetting}>
              <option value='' disabled hidden>Select Option</option>
              {optionsForEnum(this.props.eventSettings)}
            </select>
          </div>
          <div className='form-group'>
            <label>Setting Detail</label>
            <select className={classnames('form-control', 'custom-select', this.inputColor('settingDetail'))}
              onChange={this.onFieldChange.bind(this, 'settingDetail')}
              value={this.props.filters.settingDetail}>
              <option value='' disabled hidden>Select Option</option>
              {optionsForEnum(this.props.settingDetails)}
            </select>
          </div>
          <div className='form-group'>
            <label>Sport</label>
            <select className={classnames('form-control', 'custom-select', this.inputColor('sport'))}
              onChange={this.onFieldChange.bind(this, 'sport')}
              value={this.props.filters.sport}>
              <option value='' disabled hidden>Select Option</option>
              {optionsForEnum(sportsFilter(this.props.sports))}
            </select>
          </div>
          {/* TODO: These filters reset themselves after filters apply. We need
            * a new API to get all HCPs that ever worked for you. See this vide
            * for more information https://vimeo.com/667923098.
            */}
        </CollapsibleFilterPanel>
      </div>
    );
  }
}

function optionsForEnum(enum_vals) {
  return [<option value="" key=''></option>].concat(enum_vals.map(e => <option value={e.id} key={e.name}>{e.name}</option>))
}

function mapStateToProps(state) {
  const {
    eventSettings,
    settingDetails,
    sports,
  } = state.enums;
  return {
    athleticTrainers: state.user.athleticTrainers,
    filters: state.event.filters.past,
    eventSettings,
    settingDetails,
    sports,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    getAthleticTrainers: () => dispatch(getAthleticTrainers()),
    reset: () => dispatch(resetPastFilters()),
    submit: () => dispatch(applyPastFilters()),
    updateFilter: (filter, value) => dispatch(updatePastFilters(filter, value)),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(FinanceFiltersPanel);