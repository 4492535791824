import React, { Component } from 'react';
import ReportToContact from '../ReportToContact';
import './ReportToContactList.scss';

class ReportToContactList extends Component {
  contactsLength = () => this.props.contacts.filter((contact) => !contact._destroy).length;

  render() {
    const { contacts } = this.props;

    return (
      <div className="reportToContactList">
        {this.props.wasValidated && (
          <div className="invalid-feedback-displayed nimbus-regular-font">{this.props.emptyError}</div>
        )}
        {contacts.map((contact, idx) => {
          return (
            !contact._destroy && (
              <ReportToContact
                key={idx}
                contacts={contacts}
                contact={contact}
                idx={idx}
                errors={this.props.errors[idx]}
                changeContact={this.props.changeContact}
                removeContact={this.props.removeContact}
                wasValidated={this.props.wasValidated}
              />
            )
          );
        })}
      </div>
    );
  }
}

export default ReportToContactList;
