import React, { Component } from 'react';
import { connect } from 'react-redux';
import { isEmpty } from 'lodash';

import EventList from 'components/EventList';

class EventDraftsScene extends Component {
  render() {
    return (
      <div className="eventDraftsScene">
        <div className="headers">
          <h1>Drafts</h1>
          <h2>Event Drafts</h2>
        </div>
        <p>These events are incomplete.  Finish filling out the details to post the events to the job board.</p>
        {(this.props.loaded && this.props.emptyEvents) ?
          <div className='empty-events'>
            <h3>You currently have no draft events</h3>
          </div>
          :
          <div>
            <EventList drafts={true} />
          </div>}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    loaded: state.event.loaded.draft,
    emptyEvents: isEmpty(state.event.events.draft)
  }
}

export default connect(mapStateToProps)(EventDraftsScene);