import { ofType } from 'redux-observable';
import { mergeMap } from 'rxjs/operators';
import { UserService, DeeplinkSmsService } from 'services';
import { AthleticTrainerService } from 'services/v2';

import {
  APPLY_FILTERS,
  GET_ATHLETIC_TRAINERS,
  GET_ATHLETIC_TRAINERS_SUCCESS,
  GET_EVENT_OPERATORS,
  GET_EVENT_OPERATORS_SUCCESS,
  GET_USERS,
  GET_USERS_SUCCESS,
  GET_USERS_ERROR,
  RESET_FILTERS,
  UPDATE_USER,
  UPDATE_USER_SUCCESS,
  UPDATE_USER_ERROR,
  SEND_DEEPLINK_SMS,
  SEND_DEEPLINK_SMS_SUCCESS,
  SEND_DEEPLINK_SMS_ERROR,
  GET_ATHLETIC_TRAINER,
  TRACK_PROSPECT_USER,
  GET_ATHLETIC_TRAINER_ERROR,
  GET_ATHLETIC_TRAINER_SUCCESS,
} from './index';

import { basicEpic, mappingEpic } from 'redux/ducks/helpers';
import { SHOW_SPINNER, HIDE_SPINNER } from 'redux/ducks/LoadingSpinner';

// Epics
// ========================================================
export function getAthleticTrainersEpic(action$, store) {
  return action$.pipe(
    ofType(GET_ATHLETIC_TRAINERS),
    mergeMap((action) => {
      return new UserService(store.getState().session)
        .getAll({ type: 'AthleticTrainer' })
        .then((result) => ({
          type: GET_ATHLETIC_TRAINERS_SUCCESS,
          payload: result,
        }))
        .catch((error) => {
          return {
            type: GET_USERS_ERROR,
            payload: error.response.data.errors,
          };
        });
    })
  );
}

export function getEventOperatorsEpic(action$, store) {
  return action$.pipe(
    ofType(GET_EVENT_OPERATORS),
    mergeMap((action) => {
      return new UserService(store.getState().session)
        .getAll({ type: 'EventOperator' })
        .then((result) => ({
          type: GET_EVENT_OPERATORS_SUCCESS,
          payload: result,
        }))
        .catch((error) => {
          return {
            type: GET_USERS_ERROR,
            payload: error.response.data.errors,
          };
        });
    })
  );
}

export function getUsersEpic(action$, store) {
  return action$.pipe(
    ofType(APPLY_FILTERS, GET_USERS, RESET_FILTERS),
    mergeMap((action) => {
      return new UserService(store.getState().session)
        .getAll({
          ...store.getState().user.filters,
        })
        .then((result) => ({ type: GET_USERS_SUCCESS, payload: result }))
        .catch((error) => {
          return {
            type: GET_USERS_ERROR,
            payload: error.response.data.errors,
          };
        });
    })
  );
}

export function updateUserEpic(action$, store) {
  return action$.pipe(
    ofType(UPDATE_USER),
    mergeMap((action) => {
      return new UserService(store.getState().session)
        .update(action.payload)
        .then((result) => ({ type: UPDATE_USER_SUCCESS, payload: result }))
        .catch((error) => {
          return {
            type: UPDATE_USER_ERROR,
            payload: error.response.data.errors,
          };
        });
    })
  );
}

export function sendDeeplinkSms(action$, store) {
  return action$.pipe(
    ofType(SEND_DEEPLINK_SMS),
    mergeMap((action) => {
      return new DeeplinkSmsService()
        .create(action.payload)
        .then((result) => ({ type: SEND_DEEPLINK_SMS_SUCCESS, payload: result }))
        .catch((error) => {
          return {
            type: SEND_DEEPLINK_SMS_ERROR,
            payload: error.response.data,
          };
        });
    })
  );
}

export const getAthleticTrainerEpic = basicEpic(GET_ATHLETIC_TRAINER, (payload, state) =>
  new AthleticTrainerService(state.session).getAT(payload)
);

export const showSpinnerEpic = mappingEpic(
  [APPLY_FILTERS, GET_USERS, RESET_FILTERS, GET_ATHLETIC_TRAINER],
  SHOW_SPINNER
);

export const hideSpinnerEpic = mappingEpic(
  [
    GET_USERS_ERROR,
    GET_USERS_SUCCESS,
    GET_ATHLETIC_TRAINER_SUCCESS,
    GET_ATHLETIC_TRAINER_ERROR,
  ],
  HIDE_SPINNER
);

export const trackProspectUserEpic = basicEpic(TRACK_PROSPECT_USER, (payload, state) =>
  new UserService(state.session).trackProspectUsers(payload)
);
