import React, { Component } from 'react';
import moment from 'moment-timezone';
import zipcode_to_timezone from 'zipcode-to-timezone';

import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  FormGroup,
  Label,
  Input,
  ListGroup,
  ListGroupItem,
} from 'reactstrap';
import ReactTooltip from "react-tooltip";
import InputOrFlatpickr from 'components/InputOrFlatpickr';
import { RRule } from 'rrule';
import classnames from 'classnames';
import { connect } from 'react-redux';
import {
  addShift
} from 'redux/ducks/CreateEvent/Shifts';

class ShiftRepeatModal extends Component {
  state = {
    open: false,
    repeat: 'daily',
    startFrom: new Date(),
    untilAt: new Date(),
    count: null,
    interval: null,
    weekDays: { 0: 1, 1: 1, 2: 1, 3: 1, 4: 1, 5: 1, 6: 1 },
  };

  rule = new RRule()

  generateShiftDates = () => {
    let shiftToCopy = this.props.shifts[this.props.shiftId]
    let copiedShiftDates = []
    let momentStart = moment(shiftToCopy.start, 'YYYY-MM-DD HH:mm');
    let momentEnd = moment(shiftToCopy.end, 'YYYY-MM-DD HH:mm');
    let deltaT = (momentEnd.toDate()).getTime() - (momentStart.toDate()).getTime();

    for (let shiftStartDate of this.rule.all()) {
      let copyShiftStartDate = new Date(shiftStartDate)
      let copyShiftEndDate = new Date(copyShiftStartDate.getTime() + deltaT)
      copiedShiftDates.push([copyShiftStartDate, copyShiftEndDate])
    }

    return copiedShiftDates
  }

  addGeneratedShifts = () => {
    let shiftToCopy = this.props.shifts[this.props.shiftId]
    let shiftsToAdd = []

    for (let shiftStartEndPair of this.generateShiftDates()) {
      let newShift = JSON.parse(JSON.stringify(shiftToCopy));
      newShift.start = moment.tz(shiftStartEndPair[0], 'UTC').format('YYYY-MM-DD HH:mm')
      newShift.end = moment.tz(shiftStartEndPair[1], 'UTC').format('YYYY-MM-DD HH:mm')
      newShift.id = null;
      shiftsToAdd.push(newShift)
    }

    for (let shift of shiftsToAdd) {
      this.props.addShift(shift)
    }

  }

  onConfirm = () => {
    this.toggle();
    this.addGeneratedShifts()
  };

  toggle = () => {
    let momentPropStartDate = moment(this.props.shifts[this.props.shiftId].start, "YYYY-MM-DD HH:mm");
    let newStart = momentPropStartDate.toDate()
    newStart = new Date(newStart.setDate(newStart.getDate() + 1))

    let momentPropEndDate = moment(this.props.shifts[this.props.shiftId].end, "YYYY-MM-DD HH:mm");
    let newEnd = momentPropEndDate.toDate()
    newEnd = new Date(newEnd.setDate(newEnd.getDate() + 1))

    this.setState({
      open: !this.state.open,
      weekDays: { 0: 1, 1: 1, 2: 1, 3: 1, 4: 1, 5: 1, 6: 1 },
      startFrom: newStart,
      untilAt: newEnd,
    });
  };

  componentDidMount() {
    this.props.onRef(this)
  }

  componentWillUnmount() {
    this.props.onRef(undefined)
  }

  setRepeat = (repeat) => {
    let weekDays = { 0: 0, 1: 0, 2: 0, 3: 0, 4: 0, 5: 0, 6: 0 }
    if (repeat === 'daily') {
      weekDays = { 0: 1, 1: 1, 2: 1, 3: 1, 4: 1, 5: 1, 6: 1 }
    }

    this.setState({
      ...this.state,
      repeat,
      weekDays
    })
  }

  setStartFrom = (startFrom) => {
    this.setState({
      ...this.state,
      startFrom: moment(startFrom, 'YYYY-MM-DD').toDate()
    })
  }

  setUntilAt = (untilAt) => {
    this.setState({
      ...this.state,
      untilAt: moment(untilAt, 'YYYY-MM-DD').toDate(),
    })
  }

  setCount = (count) => {
    this.setState({
      ...this.state,
      count: parseInt(count.replace(/\D/g, '')) || ''
    })
  }

  setInterval = (interval) => {
    this.setState({
      ...this.state,
      interval: parseInt(interval.replace(/\D/g, '')) || ''
    })
  }

  setWeekDays = (elem) => {
    let weekdays = this.state.weekDays
    weekdays[elem] = (weekdays[elem] + 1) % 2

    this.setState({
      ...this.state,
      weekDays: weekdays,
      changedAt: new Date()
    })
  }

  formRuleObject() {
    let configs = {}

    // freq
    switch (this.state.repeat) {
      case "daily":
        configs.freq = RRule.DAILY
        break
      case "weekly":
        configs.freq = RRule.WEEKLY
        break
      case "monthly":
        configs.freq = RRule.MONTHLY
        break
      default:
        configs.freq = RRule.DAILY
        break
    }

    // startFrom
    configs.dtstart = new Date(this.state.startFrom)

    // until
    configs.until = new Date(moment(this.state.untilAt).add(1, 'days'));

    let tz = zipcode_to_timezone.lookup(this.props.shifts[this.props.shiftId].eventLocation.address.zipCode) || moment.tz.guess();

    // startFrom
    let startDate = moment.tz(this.state.startFrom, tz).utc().toDate()
    configs.dtstart = new Date(Date.UTC(startDate.getFullYear(), startDate.getMonth(), startDate.getDate(), startDate.getHours(), startDate.getMinutes()))

    // until
    let endDate = moment.tz(this.state.untilAt, tz).add(1, 'days').utc().toDate()
    configs.until = new Date(Date.UTC(endDate.getFullYear(), endDate.getMonth(), endDate.getDate(), endDate.getHours(), endDate.getMinutes()))

    // count
    if (this.state.count) {
      configs.count = this.state.count
    }

    // interval
    configs.interval = this.state.interval || 1

    // by weekday
    let actDays = []
    for (let i = 0; i <= 6; i++) {
      if (this.state.weekDays[i] % 2 == 1) {
        switch (i) {
          case 0:
            actDays.push(RRule.MO)
            break
          case 1:
            actDays.push(RRule.TU)
            break
          case 2:
            actDays.push(RRule.WE)
            break
          case 3:
            actDays.push(RRule.TH)
            break
          case 4:
            actDays.push(RRule.FR)
            break
          case 5:
            actDays.push(RRule.SA)
            break
          case 6:
            actDays.push(RRule.SU)
            break
        }
      }
    }
    if (actDays.length > 0) {
      configs.byweekday = actDays
    }

    this.rule = new RRule(configs)
  }

  render() {
    this.formRuleObject()

    return (
      <div className="shiftRepeatModal">
        <button className='modal-button plain-button' onClick={this.toggle}>{this.props.children}</button>
        <Modal isOpen={this.state.open} toggle={this.toggle} className='confirmation-modal-content' size="lg">
          <ModalHeader toggle={this.toggle}>
            Repeat Event
          </ModalHeader>
          <ModalBody>
            <ReactTooltip />
            <div className="shiftForm">
              <form noValidate onSubmit={e => e.preventDefault()}>
                <div className="row">
                  <div className="form-group col-md-12">
                    <b><label data-tip="Defines the weekdays on which the event will occur" htmlFor="name">Repeat on</label></b>

                    <FormGroup tag="fieldset" onChange={(elem) => this.setWeekDays(elem.target.value)}>
                      <FormGroup name="repeat" check inline>
                        <Label check>
                          <Input value="0" type="checkbox" name="week1" checked={this.state.weekDays[0] == 1} />{' '}
                          Monday
                        </Label>

                      </FormGroup>

                      <FormGroup check inline>
                        <Label check>
                          <Input value="1" type="checkbox" name="week1" checked={this.state.weekDays[1] == 1} />{' '}
                          Tuesday
                        </Label>
                      </FormGroup>

                      <FormGroup check inline>
                        <Label check>
                          <Input value="2" type="checkbox" name="week1" checked={this.state.weekDays[2] == 1} />{' '}
                          Wednesday
                        </Label>
                      </FormGroup>

                      <FormGroup check inline>
                        <Label check>
                          <Input value="3" type="checkbox" name="week1" checked={this.state.weekDays[3] == 1} />{' '}
                          Thursday
                        </Label>
                      </FormGroup>

                      <FormGroup check inline>
                        <Label check>
                          <Input value="4" type="checkbox" name="week1" checked={this.state.weekDays[4] == 1} />{' '}
                          Friday
                        </Label>
                      </FormGroup>

                      <FormGroup check inline>
                        <Label check>
                          <Input value="5" type="checkbox" name="week1" checked={this.state.weekDays[5] == 1} />{' '}
                          Saturday
                        </Label>
                      </FormGroup>

                      <FormGroup check inline>
                        <Label check>
                          <Input value="6" type="checkbox" name="week1" checked={this.state.weekDays[6] == 1} />{' '}
                          Sunday
                        </Label>
                      </FormGroup>
                    </FormGroup>
                  </div>
                  {/* <div className="form-group col-md-12">
                    <label data-tip="Frequency of the event" htmlFor="repeat">Repeat Frequency</label>
                    <FormGroup tag="fieldset" onChange={(el) => this.setRepeat(el.target.value)}>
                      <FormGroup name="repeat" check>
                        <Label check>
                          <Input value="daily" type="radio" name="radio1" defaultChecked />{' '}
                                                    Daily
                                                </Label>

                      </FormGroup>

                      <FormGroup check>
                        <Label check>
                          <Input value="weekly" type="radio" name="radio1" />{' '}
                                                    Weekly
                                                </Label>
                      </FormGroup>

                      <FormGroup check >
                        <Label check>
                          <Input value="monthly" type="radio" name="radio1" />{' '}
                                                    Monthly
                                                </Label>
                      </FormGroup>
                    </FormGroup>
                  </div> */}

                  {/* <div data-tip="Start of the requrence. If not given, the current date will be used." className="form-group col-md-6">
                    <b><label htmlFor="date">From</label></b>
                    <div className="input-group">
                      <InputOrFlatpickr type="datetime" name="start"
                        value={this.state.startFrom}
                        options={dateTimePickerOptions}
                        onChange={(val) => { this.setStartFrom(val) }}
                        className={classnames(
                          "form-control",
                        )}
                        placeholder="Pick start time"
                      />
                      <div className="input-group-append">
                        <span className="input-group-text"><i className="far fa-calendar-alt"></i></span>
                      </div>
                    </div>
                  </div> */}

                  <div className="form-group col-md-12">
                    <b><label data-tip="It will specify the end date of the recurrence" htmlFor="date">Until</label></b>
                    <div className="input-group">
                      <InputOrFlatpickr type="datetime" name="start"
                        value={this.state.untilAt}
                        options={dateTimePickerOptions}
                        onChange={(val) => { this.setUntilAt(val) }}
                        className={classnames(
                          "form-control",
                        )}
                        placeholder="Pick start time"
                      />
                      <div className="input-group-append">
                        <span className="input-group-text"><i className="far fa-calendar-alt"></i></span>
                      </div>
                    </div>
                  </div>

                  {/* <div className="form-group col-md-6">
                    <label data-tip="How many events will be generated" htmlFor="name">Count</label>
                    <input type="text" name="name" value={this.state.count}
                      onChange={(elem) => { this.setCount(elem.target.value) }}
                      className={classnames(
                        'form-control',
                      )}
                      placeholder="How many occurences will be generated"
                    />
                  </div>


                  <div className="form-group col-md-6">
                    <label data-tip="The interval between each repetition. Eg for 2 weeks it means 'one in every two weeks'" htmlFor="name">Interval</label>
                    <input type="text" name="name" value={this.state.interval}
                      onChange={(elem) => { this.setInterval(elem.target.value) }}
                      className={classnames(
                        'form-control',
                      )}
                      placeholder="The interval between each iteration"
                    />
                  </div> */}

                </div>
              </form>
            </div>
            <h5 style={{ marginTop: 20 }} > Event Dates</h5>
            <ListGroup style={{ marginTop: 20 }}>
              {
                this.generateShiftDates().map((startEndPair, i) => {
                  return (
                    <ListGroupItem key={i}>{moment.tz(startEndPair[0], 'UTC').format("dddd, MMMM Do YYYY, h:mm:ss a")} <b> -> </b> {moment.tz(startEndPair[1], 'UTC').format("dddd, MMMM Do YYYY, h:mm:ss a")}</ListGroupItem>
                  )
                })

              }
            </ListGroup>

          </ModalBody>
          <ModalFooter>
            <button className='btn btn-danger bold-button' onClick={this.onConfirm}>
              {this.props.confirmText || 'Confirm'}
            </button>
          </ModalFooter>
        </Modal>
      </div>
    );
  }
}

const dateTimePickerOptions = {
  enableTime: false,
  dateFormat: "Y-m-d",
  altFormat: "m/d/y",
  altInput: true,
};


const mapStateToProps = (state: AppState) => ({
  shifts: state.createEvent.shifts.shifts,
  reuseLocation: state.createEvent.shifts.reuseLocation
})

const mapDispatchToProps = dispatch => ({
  addShift: (shift) => { dispatch(addShift({ shift })) },
})

export default connect(mapStateToProps, mapDispatchToProps)(ShiftRepeatModal);