import { ofType } from 'redux-observable';

import { mergeMap } from 'rxjs/operators';

import { EnumService } from 'services';

import {
  GET_ENUMS,
  LOAD_ENUMS_SUCCESS,
  LOAD_ENUMS_ERROR,
} from './index';

import {
  RELOAD_SESSION_SUCCESS
} from 'redux/ducks/Session';

// Epics
// ========================================================
export function loadEnumsEpic(action$, store) {
  return action$.pipe(
    ofType(RELOAD_SESSION_SUCCESS, GET_ENUMS),
    mergeMap(action => {
      return new EnumService(store.getState().session).getAll()
        .then(enums => ({ type: LOAD_ENUMS_SUCCESS, payload: enums }))
        .catch(error => ({
          type: LOAD_ENUMS_ERROR,
          payload: error,
        }));
    })
  )
}