import React from "react";
import InputLabel from "../InputLabel";

const InputWrapper = ({ label, children }) => (
  <div className="inputWrapper">
    <InputLabel label={label} />
    {children}
  </div>
);

export default InputWrapper;
