import React, { Component } from 'react';
import classNames from 'classnames';
import { withRouter } from 'react-router-dom/cjs/react-router-dom';
import { connect } from 'react-redux';
import moment from 'moment-timezone';
import { uniqBy, sortBy } from 'lodash';
import { Collapse, UncontrolledTooltip } from 'reactstrap';
import ShiftJobComponent from '../ShiftJobComponent/ShiftJobComponent';
import { SHOW_TOOLTIP_COLUMNS, createOverflowChecker } from 'helpers/dashboard';
import zipcode_to_timezone from 'zipcode-to-timezone';

class ApplicantsToReviewItem extends Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
      isOverflowing: {},
    };
    this.columnRefs = {};
    SHOW_TOOLTIP_COLUMNS.applicants.forEach((column) => {
      this.columnRefs[column] = React.createRef();
    });
  }

  handleExpand = () => {
    this.setState((prevState) => ({
      ...prevState,
      open: !this.state.open,
    }));
  };

  componentDidMount() {
    const checkOverflow = createOverflowChecker(
      'applicants',
      this.columnRefs,
      this.setState.bind(this)
    );

    this.resizeObserver = new ResizeObserver(checkOverflow);
    SHOW_TOOLTIP_COLUMNS.applicants.forEach((column) => {
      this.resizeObserver.observe(this.columnRefs[column].current);
    });
  }

  componentWillUnmount() {
    this.resizeObserver.disconnect();
  }

  startDate = (shifts) => {
    const firstShift = shifts[0];
    const location = firstShift.eventLocation;
    const tz = zipcode_to_timezone.lookup(location.address.zipCode) || moment.tz.guess();
    return moment.tz(firstShift.startTime, tz).format('MM/DD/YY');
  };

  handleLocationName = () => {
    const { item } = this.props;
    const uniqLocations = uniqBy(
      item.shifts.map((shift) => shift.eventLocation),
      'id'
    );

    if (uniqLocations.length > 1) return 'Multiple Locations';

    return uniqLocations[0].name;
  };

  render() {
    const { item, columns } = this.props;
    const { eventCapacity, shiftRequests, shiftsFilled } = item.statistics;
    const shiftsWithPending = item.shifts.filter(
      (shift) => shift.jobs.filter((job) => job.currentState === 'pending').length > 0
    );
    const sortedShifts = sortBy(shiftsWithPending, (shift) => moment(shift.startTime));

    return (
      <React.Fragment>
        <div
          className={classNames('applicants-list-item', {
            'collapse-border-bottom': !this.state.open,
          })}
        >
          <div
            className={`${columns['jobName'].size} job-name`}
            id={`jobName-${item.id}`}
            ref={this.columnRefs['jobName']}
          >
            {item.title}
            {this.state.isOverflowing.jobName && (
              <UncontrolledTooltip target={`jobName-${item.id}`}>{item.title}</UncontrolledTooltip>
            )}
          </div>
          <div
            className={`${columns['startDate'].size}`}
            id={`startDate-${item.id}`}
            ref={this.columnRefs['startDate']}
          >
            {this.startDate(sortedShifts)}
            {this.state.isOverflowing.startDate && (
              <UncontrolledTooltip target={`startDate-${item.id}`}>
                {this.startDate(sortedShifts)}
              </UncontrolledTooltip>
            )}
          </div>
          <div
            className={`${columns['location'].size}`}
            id={`location-${item.id}`}
            ref={this.columnRefs['location']}
          >
            {this.handleLocationName()}
            {this.state.isOverflowing.location && (
              <UncontrolledTooltip target={`location-${item.id}`}>
                {this.handleLocationName()}
              </UncontrolledTooltip>
            )}
          </div>
          <div
            className={`${columns['filled'].size}`}
            id={`filled-${item.id}`}
            ref={this.columnRefs['filled']}
          >
            {shiftsFilled + '/' + eventCapacity}
            {this.state.isOverflowing.filled && (
              <UncontrolledTooltip target={`filled-${item.id}`}>
                {shiftsFilled + '/' + eventCapacity}
              </UncontrolledTooltip>
            )}
          </div>
          <div
            className={`${columns['apps'].size}`}
            id={`apps-${item.id}`}
            ref={this.columnRefs['apps']}
          >
            {shiftRequests}
            {this.state.isOverflowing.apps && (
              <UncontrolledTooltip target={`apps-${item.id}`}>{shiftRequests}</UncontrolledTooltip>
            )}
          </div>
          <div className={classNames(`${columns['viewApplicants'].size} view-applicants`)}>
            <button className="dropdown-button plain-button" onClick={this.handleExpand}>
              VIEW APPLICANTS
            </button>
            {this.state.open && <i className="material-icons">expand_more</i>}
            {!this.state.open && <i className="material-icons">chevron_right</i>}
          </div>
        </div>
        <Collapse
          isOpen={this.state.open}
          style={{
            width: '100%',
            textAlign: 'left',
          }}
        >
          <ShiftJobComponent
            shifts={sortedShifts}
            athleticTrainers={this.props.athleticTrainers}
            handleExpand={this.handleExpand}
            isOpen={this.state.open}
          />
        </Collapse>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    athleticTrainers: state.dashboard.applicants.uniqApplicants || [],
  };
};

export default withRouter(connect(mapStateToProps)(ApplicantsToReviewItem));
