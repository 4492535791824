import ApiService from "./ApiService";

import { LeadSerializer } from "services/serializers";

class LeadService extends ApiService {
  constructor(session) {
    super(session);

    this.url = "/lead_contact";
  }

  create(lead) {
    return super.post(
      `${this.url}.json`,
      LeadSerializer.serialize(lead),
      (response) => LeadSerializer.deserialize(response)
    );
  }
}

export default LeadService;
