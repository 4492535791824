import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Collapse } from 'reactstrap';
import classnames from 'classnames';
import ShiftJobItem from './ShiftJobItem';
import zipcode_to_timezone from 'zipcode-to-timezone';
import moment from 'moment-timezone';

export class ShiftListItem extends Component {
  constructor() {
    super();

    this.jobs = [];
    this.waitlist = [];
  }

  componentWillMount() {
    this.processShift(this.props.shift);
  }

  componentWillUpdate(newProps) {
    this.processShift(newProps.shift);
  }

  state = {
    waitlistExpanded: false,
  };

  formatShiftTime = (shift) => {
    const tz = zipcode_to_timezone.lookup(shift.eventLocation.address.zipCode) || moment.tz.guess();
    let startTime = moment.tz(shift.startTime, tz).format('M/D/YY h:mmA');
    let endTime = moment.tz(shift.endTime, tz).format('M/D/YY h:mmA z');
    return startTime + ' - ' + endTime;
  };

  processShift = (shift) => {
    let jobs = [];
    let spotsFilled = 0;
    let waitlist = [];

    for (const jobId of shift.jobs.ids) {
      const job = shift.jobs.byId[jobId];

      if (
        job.currentState === 'checked_in' ||
        job.currentState === 'paid' ||
        job.currentState === 'confirmed'
      ) {
        spotsFilled += 1;
      }

      if (job.currentState === 'waitlist') {
        waitlist.push(job);
      } else if (!['declined', 'eo_removed_at'].includes(job.currentState)) {
        jobs.push(job);
      }
    }

    this.jobs = jobs;
    this.spotsFilled = spotsFilled;
    this.waitlist = waitlist;
  };

  toggleWaitlist = () => {
    this.setState({ waitlistExpanded: !this.state.waitlistExpanded });
  };

  shouldShow = (job) =>
    !['withdrawn', 'unapplied', 'expired_no_eo_confirm', 'expired_no_at_confirm'].includes(
      job.currentState
    );

  getProfessionAcronym = (professionId) => {
    switch (professionId) {
      case 0:
        return 'AT';
      case 1:
        return 'RN';
      default:
        return '';
    }
  };

  eapTo = () => {
    const { shift, event } = this.props;
    return event.version === 'v2'
      ? `/v2/events/${event.id}/eap/${!shift.emergencyActionPlanId ? 'eap-type' : 'review'}`
      : `/events/${event.id}/shifts/${shift.id}/emergency-action-plan/edit`;
  };

  render() {
    const { shift, event } = this.props;
    return (
      <div className="shiftListItem">
        <div className="header">
          <div>{this.formatShiftTime(shift)}</div>
          <div>
            {this.getProfessionAcronym(event.eventProfessionId)} | {this.spotsFilled} of{' '}
            {shift.shiftCapacity} spots filled
          </div>
          <div>{shift.eventLocation.name}</div>
          <div className="eap-link">
            <Link to={this.eapTo()} className={classnames({ alert: !shift.emergencyActionPlanId })}>
              {event.version === 'v2' ? (
                <>
                  EAP<small className="eap-feature-v2">v2</small>
                </>
              ) : (
                'EAP'
              )}
            </Link>
          </div>
        </div>
        <div className="details">
          {this.jobs.filter(this.shouldShow).map((job) => {
            return <ShiftJobItem event={event} shift={shift} job={job} key={job.id} />;
          })}
        </div>
        <Collapse isOpen={this.state.waitlistExpanded}>
          <div className="waitlist">
            {this.waitlist.map((job, index) => {
              return <ShiftJobItem event={event} shift={shift} job={job} key={job.id} />;
            })}
          </div>
        </Collapse>
        {this.waitlist.length > 0 && (
          <button className="plain-button waitlist-toggle" onClick={this.toggleWaitlist}>
            {this.state.waitlistExpanded ? 'Hide' : 'View'} Waitlist
          </button>
        )}
      </div>
    );
  }
}

export default ShiftListItem;
