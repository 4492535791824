import React, { Component } from "react";
import { ContactService } from "../../services";
import HomeContactConfirmation from "../HomeContactConfirmation";
import FormErrorMessage from "../FormErrorMessage";

const intialState = {
  date: "",
  sport: "",
  eventType: "",
  name: "",
  email: "",
  notes: "",
  phone: "",
  showErrorMessage: false,
  showConfirmation: false,
};

class HomeContactForm extends Component {
  state = intialState;

  handleChange = (e) => {
    const { value, name } = e.target;
    this.setState({ [name]: value, [`${name}Error`]: false });
  };

  handleSubmit = (e) => {
    e.preventDefault();
    const { date, sport, eventType, name, email, phone, notes } = this.state;
    let allValid = true;
    if (!date) {
      allValid = false;
      this.setState({ dateError: true });
    }
    if (!sport) {
      allValid = false;
      this.setState({ sportError: true });
    }
    if (!eventType) {
      allValid = false;
      this.setState({ eventTypeError: true });
    }
    if (!name) {
      allValid = false;
      this.setState({ nameError: true });
    }
    if (!phone) {
      allValid = false;
      this.setState({ phoneError: true });
    }
    if (!this.validateEmail(email)) {
      allValid = false;
      this.setState({ emailError: true });
    }
    if (allValid) {
      const payload = { date, sport, eventType, name, email, notes, phone };
      new ContactService().post(payload);
      this.setState({ ...intialState, showConfirmation: true });
    } else this.setState({ showErrorMessage: true });
  };

  validateEmail(email) {
    let re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  }

  render() {
    const {
      date,
      sport,
      eventType,
      name,
      email,
      phone,
      notes,
      dateError,
      sportError,
      eventTypeError,
      nameError,
      emailError,
      phoneError,
      showErrorMessage,
      showConfirmation,
    } = this.state;
    if (showConfirmation) return <HomeContactConfirmation />;
    return (
      <div className="homeContactForm">
        <div className="header">Hire an athletic trainer</div>
        <form className="body">
          <div className="date-picker">
            <span>Date Needed</span>
            <input
              value={date}
              className={dateError ? "error" : ""}
              type="date"
              placeholder="MM/DD/YYYY"
              name="date"
              onChange={this.handleChange}
            />
          </div>
          <input
            className={sportError ? "error" : ""}
            type="text"
            name="sport"
            value={sport}
            placeholder="Sport"
            onChange={this.handleChange}
          />
          <input
            type="text"
            name="eventType"
            className={eventTypeError ? "error" : ""}
            value={eventType}
            placeholder="Event Type (Game, Tournament, etc.)"
            onChange={this.handleChange}
          />
          <input
            type="text"
            name="name"
            className={nameError ? "error" : ""}
            value={name}
            placeholder="Your Name"
            onChange={this.handleChange}
          />
          <input
            type="email"
            name="email"
            className={emailError ? "error" : ""}
            value={email}
            placeholder="Email"
            onChange={this.handleChange}
          />
          <input
            type="text"
            name="phone"
            className={phoneError ? "error" : ""}
            value={phone}
            placeholder="Phone number"
            onChange={this.handleChange}
          />
          <textarea
            type="text"
            name="notes"
            value={notes}
            placeholder="other notes"
            onChange={this.handleChange}
          />
          <button onClick={this.handleSubmit} type="submit">
            Submit
          </button>
          {showErrorMessage && <FormErrorMessage />}
        </form>
      </div>
    );
  }
}

export default HomeContactForm;
