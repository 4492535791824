import React from "react";
import IndustrialHowItWorks from "../../IndustrialScene/IndustrialHowItWorks";
import howItWorks1 from "images/laptop.svg";
import howItWorks2 from "images/money.svg";
import howItWorks3 from "images/person-check.svg";
import howItWorks4 from "images/documents.svg";

const HowItWorks = () => (
  <IndustrialHowItWorks
    svg={true}
    altGroups={[
      {
        title: "POST YOUR SHIFTS",
        body: "Post your shift and hours you need an RN on the Go4 platform.",
        image: howItWorks1,
      },
      {
        title: "SET YOUR PAY RATE",
        body: "Go4 let's you set the pay rate that fits into your budget.",
        image: howItWorks2,
      },
      {
        title: "HIRE YOUR RNs",
        body: "Hire your nurses from the RNs that apply to work your shift.",
        image: howItWorks3,
      },
      {
        title: "WE HANDLE THE PAPERWORK",
        body: "We handle the 1099s for the RNs that work your shifts.",
        image: howItWorks4,
      },
    ]}
  />
);

export default HowItWorks;
