import moment from 'moment';

function getEstimatedFee(startTime, eventProfessionId, isAmex) {
  if (isAmex) {
    if (eventProfessionId == 0) {
      if (startTime >= moment("1/1/2024")) {
        return 21.0;
      } else {
        return 15.0;
      }
    } else {
      if (startTime >= moment("1/1/2024")) {
        return 21.0;
      } else {
        return 20.0;
      }
    }
  } else {
    if (eventProfessionId == 0) {
      if (startTime >= moment("1/1/2024")) {
        return 17.5;
      } else {
        return 12.5;
      }
    } else {
      return 17.5;
    }
  }
}


export {
  getEstimatedFee
}
