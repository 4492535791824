import React, { Component } from 'react';
import { withRouter } from "react-router-dom";
import { connect } from 'react-redux';
import { Collapse } from 'reactstrap';
import classnames from 'classnames';
import { min, max, map, values, get } from 'lodash';
import ShiftList from 'components/ShiftList';
import ShiftFinancesListItem from 'components/ShiftFinancesListItem';
import { duplicateEvent } from 'redux/ducks/CreateEvent';
import { rateTypeNameFinder } from 'helpers/rateTypeName';
import { getEnumName, getJobDescriptionNames } from 'helpers/enums';
import { formatter } from 'helpers/formatter';
import moment from 'moment';

export class EventFinancesListItem extends Component {

  componentWillMount() {
    this.processEvent();
  }

  state = {
    expanded: false
  };

  processEvent = () => {
    let atsHired = [];
    let serviceFeeTotal = 0;

    for (const shiftId of this.props.item.shifts.ids) {
      const shift = this.props.item.shifts.byId[shiftId];

      for (const jobId of shift.jobs.ids) {
        const job = shift.jobs.byId[jobId];

        if (!atsHired.includes(job.userId) && job.currentState === 'paid') {
          atsHired.push(job.userId);
        }

        const jobPayment = get(job, 'payment.eoServiceCharge', 0);
        const jobModificationPayment = get(job, 'jobModification.payment.eoServiceCharge', 0);
        serviceFeeTotal += (Number(jobPayment) + Number(jobModificationPayment));
      }
    }

    this.atsHired = atsHired.length;
    this.serviceFeeTotal = serviceFeeTotal;
  };

  caretClass = () => {
    return this.state.expanded ? 'fa-angle-up' : 'fa-angle-down';
  };

  formatLastPayment = () => {
    let result = '';

    if (this.props.item.lastPaymentIssued) {
      result = moment(this.props.item.lastPaymentIssued).format("M/D/YY - h:mmA");
    }

    return result;
  };

  rateType = () => {
    return rateTypeNameFinder(this.props.rateTypes, this.props.item)
  };

  startDate = () => {
    let result = '';
    const shifts = values(this.props.item.shifts.byId)

    if (shifts.length > 0) {
      const startTime = min(map(shifts, 'startTime'));

      result = this.formatDate(startTime);
    }

    if (shifts.length > 1) {
      const lastTime = max(map(shifts, 'startTime'));
      result += ` - ${this.formatDate(lastTime)}`
    }

    return result;
  };

  formatDate = (time) => `${moment(time).format("ddd").toUpperCase()}, ${moment(time).format("M/D/YY")}`;

  toggleExpanded = () => {
    this.setState({ expanded: !this.state.expanded });
  };

  duplicateEvent = (e) => {
    e.preventDefault();
    const { history } = this.props;
    const event = this.props.item;
    this.props.duplicateEvent({ event, history });
  }

  render() {
    const { item } = this.props;
    const { eventSettings, sports, settingDetails, jobDescriptions } = this.props.enums;
    const { eventSettingId, sportId, settingDetailId, jobDescriptionIds } = this.props.item;
    const eventSetting = getEnumName(eventSettings, eventSettingId);
    const settingDetail = getEnumName(settingDetails, settingDetailId);
    const sport = getEnumName(sports, sportId);
    const jobDescriptionNames = getJobDescriptionNames(jobDescriptions, jobDescriptionIds);
    return (
      <li className={classnames('eventFinancesListItem', { expanded: this.state.expanded })}>
        <div className="summary" onClick={this.toggleExpanded}>
          <div>
            <div className={item.alerts ? 'alert-bar' : 'no-alert-bar'}></div>
            <i className={classnames('fas', this.caretClass())} />
          </div>
          <div>
            <b>{item.title}</b>
            <p>
              {`${eventSetting}`}
              {` / ${settingDetail}`}
              {(eventSetting === "Sports" && (item.eventProfessionId == 0 || (settingDetail === "Sporting Event"))) && ` / ${sport}`}
            </p>
            <p>{jobDescriptionNames}</p>
            <p>{this.startDate()}</p>
          </div>
          <div>
            <b>{item.shifts.ids.length} Shift{item.shifts.ids.length === 1 ? '' : 's'}</b>
            <p>{formatter.format(item.payRate)} / {this.rateType()}</p>
          </div>
          <div>{this.atsHired}</div>
          <div>{this.formatLastPayment()}</div>
          <div>{formatter.format(item.totalPaymentToDate)}</div>
          <div>
            <a onClick={this.duplicateEvent} href="" className='edit-link'>
              <i className="fas fa-copy" />
            </a>
          </div>
        </div>
        <div className='mobile-controls'>
          <i className={classnames('fas', this.caretClass())} />
          <a onClick={this.duplicateEvent} href="" className='edit-link'>
            <i className="fas fa-copy" />
          </a>
        </div>
        <Collapse isOpen={this.state.expanded}>
          <ShiftList shifts={values(item.shifts.byId).filter(shift => moment() > moment(shift.endTime))}
            ListItem={ShiftFinancesListItem} />
          <div className='service-charge'>
            <div>Service Fee</div>
            <div>Paid: {formatter.format(this.serviceFeeTotal)}</div>
          </div>
        </Collapse>
      </li>
    );
  }
}

function mapStateToProps(state) {
  const { enums } = state;
  return {
    rateTypes: state.enums.rateTypes,
    enums
  }
}

function mapDispatchToProps(dispatch) {
  return {
    duplicateEvent: (id) => dispatch(duplicateEvent(id)),
  };
}


export default withRouter(connect(
  mapStateToProps,
  mapDispatchToProps,
)(EventFinancesListItem));
