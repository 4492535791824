import React, { Component } from 'react';

import { connect } from 'react-redux';

import { Link, withRouter } from 'react-router-dom';

import { Collapse } from 'reactstrap';

import classnames from 'classnames';

import { isEmpty } from 'lodash';

import { deleteEvent } from 'redux/ducks/Event';
import { duplicateEvent } from 'redux/ducks/CreateEvent';

import { values, get } from 'lodash';

import ConfirmationModal from 'components/ConfirmationModal';
import ShiftList from 'components/ShiftList';
import ShiftListItem from 'components/ShiftList/ShiftListItem';

import { rateTypeNameFinder } from 'helpers/rateTypeName';
import { getEnumName, getJobDescriptionNames } from 'helpers/enums';
import { formatter } from 'helpers/formatter';
import { initForm } from 'redux/ducks/Post/actions';

const moment = require('moment');

export class EventListItem extends Component {
  componentWillMount() {
    this.processEvent(this.props.item);
  }

  componentWillUpdate(newProps) {
    this.processEvent(newProps.item);
  }

  state = {
    expanded: false,
  };

  caretClass = () => {
    return this.state.expanded ? 'fa-angle-up' : 'fa-angle-down';
  };

  formatDate = (date) => {
    return moment(date).format('MM/DD/YYYY  -  h:mmA');
  };

  processEvent = () => {
    let shiftRequests = 0;
    let shiftsAvailable = 0;
    let shiftsFilled = 0;

    for (const shiftId of this.props.item.shifts.ids) {
      const shift = this.props.item.shifts.byId[shiftId];

      // Shift has Passed => Do not count anything
      if (moment().subtract(1, 'days') >= moment(shift.endTime)) {
        continue;
      }

      let jobsAccepted = 0;
      for (const jobId of shift.jobs.ids) {
        const job = shift.jobs.byId[jobId];

        if (job.currentState === 'pending') {
          shiftRequests += 1;
        }

        if (
          job.currentState === 'checked_in' ||
          job.currentState === 'confirmed' ||
          job.currentState === 'paid'
        ) {
          jobsAccepted += 1;
          shiftsFilled += 1;
        }
      }

      if (jobsAccepted < shift.shiftCapacity) {
        shiftsAvailable += shift.shiftCapacity - jobsAccepted;
      }
    }

    this.shiftRequests = shiftRequests;
    this.shiftsAvailable = shiftsAvailable;
    this.shiftsFilled = shiftsFilled;
  };

  onDelete = () => {
    this.props.onDelete(this.props.item);
  };

  rateType = () => {
    return rateTypeNameFinder(this.props.enums.rateTypes, this.props.item);
  };

  startDate = () => {
    let result = '';

    if (this.props.item && this.props.item.shifts.ids.length > 0) {
      const shift = this.props.item.shifts.byId[this.props.item.shifts.ids[0]];
      result =
        `${moment(shift.startTime).format('ddd').toUpperCase()}, ` +
        moment(shift.startTime).format('M/D/YY');
    }
    return result;
  };

  toggleExpanded = () => {
    if (!this.props.item.isDraft) {
      this.setState({ expanded: !this.state.expanded });
    }
  };

  duplicateEvent = (e) => {
    e.preventDefault();
    this.props.duplicateEvent(this.props.item, this.props.history);
  };

  editTo = (item) => {
    if (item.version !== 'v2') return `/events/${item.id}/edit`;

    this.props.initialize({ ...item, shifts: Object.values(get(item, 'shifts.byId', {})) });
    return {
      pathname: item.isDraft ? `/v2/create-event/profile` : `/v2/events/${item.id}/profile`,
      fromDrafts: true,
    };
  };

  onClickEdit = (item) => {
    if (item.version !== 'v2' || !item.isDraft) return;

    this.props.initialize({ ...item, shifts: Object.values(get(item, 'shifts.byId', {})) });
  };

  render() {
    const { item } = this.props;
    const { eventSettings, sports, settingDetails, jobDescriptions } = this.props.enums;
    const { eventSettingId, sportId, settingDetailId, jobDescriptionIds } = this.props.item;
    const eventSetting = getEnumName(eventSettings, eventSettingId);
    const settingDetail = getEnumName(settingDetails, settingDetailId);
    const sport = getEnumName(sports, sportId);
    const jobDescriptionNames = getJobDescriptionNames(jobDescriptions, jobDescriptionIds);
    return (
      <li
        className={classnames(
          'eventListItem',
          { expanded: this.state.expanded },
          { draft: item.isDraft }
        )}
      >
        <div className="summary" onClick={this.toggleExpanded}>
          <div>
            {item.isDraft ? (
              <div className="draft-alert-bar">Draft</div>
            ) : (
              <div>
                <div className={!isEmpty(item.alerts) ? 'alert-bar' : 'no-alert-bar'}></div>
                <i className={classnames('fas', this.caretClass())} />
              </div>
            )}
          </div>
          <div>
            <b>{item.title}</b>
            <p>
              {`${eventSetting}`}
              {` / ${settingDetail}`}
              {eventSetting === 'Sports' &&
                (item.eventProfessionId == 0 || settingDetail === 'Sporting Event') &&
                ` / ${sport}`}
            </p>
            <p>{jobDescriptionNames}</p>
            <p>{this.startDate()}</p>
          </div>
          <div>
            <b>
              {item.shifts.ids.length} Shift{item.shifts.ids.length === 1 ? '' : 's'}
            </b>
            <p>
              {formatter.format(item.payRate)} / {this.rateType()}
            </p>
          </div>
          <div className={this.shiftsAvailable > 0 ? 'bold' : ''}>
            {item.isDraft ? '-' : this.shiftsAvailable}
          </div>
          {item.isDraft ? (
            <div>-</div>
          ) : this.shiftRequests > 0 ? (
            <div className="pending-request-text">{this.shiftRequests}</div>
          ) : (
            <div>{this.shiftRequests}</div>
          )}
          <div>{item.isDraft ? '-' : this.shiftsFilled}</div>
          <div>
            {item.alerts &&
              item.alerts.map((alert, i) => {
                return <p key={i}>{alert}</p>;
              })}
            {!item.alerts && <span>{item.isDraft ? 'Draft' : 'No Alerts'}</span>}
          </div>
          <div className="item-controls">
            <Link
              to={this.editTo(item)}
              onClick={() => this.onClickEdit(item)}
              className="edit-link"
            >
              {item.version === 'v2' && <small className="feature-v2">v2</small>}
              <i className="fas fa-pencil-alt" />
            </Link>
            <a onClick={this.duplicateEvent} href="" className="edit-link">
              <i className="fas fa-copy" />
            </a>
            <ConfirmationModal
              title="Are you sure?"
              body={`You are about to delete a draft of the event named ${item.title}.  Deleting this draft will remove it entirely.`}
              onConfirm={this.onDelete}
              confirmText="Delete this draft"
            >
              <i className="delete-link fas fa-trash" />
            </ConfirmationModal>
          </div>
        </div>
        <div className="mobile-controls">
          <div className={!isEmpty(item.alerts) ? 'alert-bar' : 'no-alert-bar'}></div>
          {!item.isDraft && <i className={classnames('fas', this.caretClass())} />}
          {item.alerts && <p className="alert">{item.alerts[0]}</p>}
          {item.isDraft && !item.alerts && <p className="alert">Draft</p>}
          <Link to={this.editTo(item)} className="edit-link" onClick={() => this.onClickEdit(item)}>
            {item.version === 'v2' && <small className="feature-v2">v2</small>}
            <i className="fas fa-pencil-alt" />
          </Link>
          <a onClick={this.duplicateEvent} href="" className="edit-link">
            <i className="fas fa-copy" />
          </a>
        </div>
        <Collapse isOpen={this.state.expanded}>
          <div className="shift-list">
            <ShiftList
              shifts={values(item.shifts.byId).filter(
                (shift) => moment().subtract(1, 'days') < moment(shift.endTime)
              )}
              ListItem={ShiftListItem}
              event={item}
              eventId={item.id}
            />
          </div>
        </Collapse>
      </li>
    );
  }
}

function mapStateToProps(state) {
  return {
    enums: state.enums,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    onDelete: (event) => dispatch(deleteEvent(event)),
    duplicateEvent: (event, history) => dispatch(duplicateEvent({ event, history })),
    initialize: (originalEvent) => dispatch(initForm(originalEvent)),
  };
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(EventListItem));
