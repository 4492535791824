import moment from "moment";
import { formatter } from 'helpers/formatter'
class JobsAvailableSerializer {
  static deserialize(data) {
    const { address = {} } = data;
    const result = {
      sport: data.sport || "",
      eventType: data.event_type || "",
      startTime: data.start_time
        ? moment(data.start_time).format("M/DD/YYYY h:mm A")
        : "Start time current unavailable",
      address: `${address.street_address || ""}`,
      addressLine2: `${address.city || ""}, ${address.state ||
        ""} ${address.zip || ""}`,
      country: address.country || "",
      rate: `${formatter.format(data.pay_rate)}/${data.rate_type}`,
      id: data.id
    };

    return result;
  }
}

export default JobsAvailableSerializer;
