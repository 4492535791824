import React from "react";
import nataImg from "NATA-Logo.svg";
import { Link } from "react-router-dom";
import logoRed from "Go4-Logo-Red.svg";

export default () => (
  <div className="homeCTAForm">
    <div className="nata-logo-container">
      <p>Preferred Provider</p>
      <img src={nataImg} alt="NATA" className="nata-img" />
    </div>
    <img src={logoRed} alt="Go4" className="go4ellis-logo-img" />
    <h5 className="athletic-trainers">ATHLETIC TRAINERS ON-DEMAND</h5>
    <div className="content-description">
      <p>
        The Go4 platform connects schools, teams and sports organizations
        with on-demand Athletic Trainers. All Athletic Trainers on Go4 are
        members of the National Athletic Trainers' Association.
      </p>
      <p>
        Post jobs for free. Pay only when jobs are completed. No Contracts. No
        minimums.
      </p>
    </div>
    <div className="actions">
      <Link className="btn btn-danger bold-button" to="/sign-up/role">
        Get Started
      </Link>
      <a className="contactus-go4ellis" href="mailto:contactus@go4.io">
        Contact Us
      </a>
    </div>
  </div>
);
