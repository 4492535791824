import ApiService from "./ApiService";

import { MedicalRecordWaiverSerializer } from "services/serializers";

class MedicalRecordWaiverService extends ApiService {
  constructor(session) {
    super(session);

    this.url = "/medical_record_waivers";
  }

  getAll() {
    return super.get(`${this.url}.json`, response =>
        response.map(mrw => MedicalRecordWaiverSerializer.deserialize(mrw))
    );
  }

  resend(id) {
    return super.get(`${this.url}/${id}/resend.json`, response =>
        response.map(mrw => MedicalRecordWaiverSerializer.deserialize(mrw))
    );
  }

  sign(id) {
    return super.get(`${this.url}/${id}/sign.json`, response => {
      return {
        id: id,
        url: response.url
      }
    });
  }

  checkSigned(id) {
    return super.get(`${this.url}/${id}/check_signed.json`, response => response.url);
  }

}

export default MedicalRecordWaiverService;
