import ApiService from './ApiService';
import { UserSerializer } from 'services/serializers';
import { toLower } from 'lodash';

class UserService extends ApiService {
  constructor(session) {
    super(session);

    this.url = '/users';
  }

  checkAccountExists(email) {
    return super.search(`${this.url}/email_taken.json`, { email }, (resp) => resp);
  }

  get(id) {
    return super.get(`${this.url}/${id}.json`, (response) => UserSerializer.deserialize(response));
  }

  getAll(params) {
    return super.search(`${this.url}.json`, params, (response) =>
      response.map((item) => UserSerializer.deserialize(item))
    );
  }

  create(user) {
    const payload = { user: UserSerializer.serialize(user) };
    if (user.token) {
      payload['g-recaptcha-response-data'] = {
        signup_eo: user.token,
      };
    }
    return super.post(`${this.url}.json`, payload, (response) =>
      UserSerializer.deserialize(response)
    );
  }

  update(user) {
    return super.patch(
      `${this.url}/${user.id}.json`,
      { user: UserSerializer.serialize(user) },
      (response) => UserSerializer.deserialize(response)
    );
  }

  delete(user) {
    return super.delete(`${this.url}/${user.id}.json`, () => true);
  }

  trackProspectUsers({ contact, event_name }) {
    let data;
    switch (event_name) {
      case 'job_contact_added':
        data = {
          event_name: 'job_contact_added',
          event_data: {
            job_contact_email: toLower(contact.email),
            job_contact_name: contact.name,
            job_contact_phone: contact.phoneNumber,
          },
        };
        break;
      case 'prospect_jp':
        data = {
          event_name: 'prospect_jp',
          event_data: {
            email: toLower(contact.email),
            first_name: contact.firstName,
            last_name: contact.lastName,
            organization_name: contact.organizationName,
            zip_code: contact.zipCode,
          },
        };
        break;
      default:
        break;
    }

    return super.post(`/tracking/prospect_user.json`, data, (response) => response);
  }
}

export default UserService;
