import ApiService from '../ApiService';
import { DashboardSerializer } from 'services/serializers/v2';

class DashboardService extends ApiService {
  constructor(session) {
    super(session);

    this.url = '/v2/dashboard';
  }

  getNoApplicants(params) {
    return super.search(
      `${this.url}/shifts/no_applicants.json`,
      params,
      (response) => {
        return {
          per_page: response.per_page,
          total_pages: response.total_pages,
          total: response.total,
          current_page: response.current_page,
          shifts: response.shifts.map(
            DashboardSerializer.deserializeShiftWithNoApps
          ),
        };
      }
    );
  }

  getShiftModifications(params) {
    return super.search(
      `${this.url}/job_modifications.json`,
      params,
      (response) => {
        return {
          per_page: response.per_page,
          total_pages: response.total_pages,
          total: response.total,
          current_page: response.current_page,
          modifications: response.job_modifications.map((modification) =>
            DashboardSerializer.deserializeJobModification({
              ...modification,
              athletic_trainers: response.athletic_trainers,
            })
          ),
        };
      }
    );
  }
}

export default DashboardService;
