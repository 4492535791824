export const CARD_OPTIONS = {
  iconStyle: 'solid',
  style: {
    base: {
      'fontWeight': 500,
      'fontSize': '16px',
      'fontSmoothing': 'antialiased',
      'color': '#424770',
      'letterSpacing': '0.025em',
      '::placeholder': {
        color: '#aab7c4',
      },
    },
    invalid: {
      color: '#f33',
    },
  },
};
