import React, { Component } from 'react';
import { connect } from 'react-redux';
import { UncontrolledAlert } from 'reactstrap';
import { clearResetPassword, resetPassword } from 'redux/ducks/ResetPassword';
import { get } from 'lodash';
import HorizontalRule from 'components/HorizontalRule';
import { Link } from 'react-router-dom';

class V2ForgotPasswordScene extends Component {
  state = {
    email: '',
    success: undefined
  }

  componentDidMount() {
    this.props.clearResetPassword()
  }

  handleEmailChange = (e) => {
    this.setState({ email: e.currentTarget.value })
  }

  onSubmit = (e) => {
    e.preventDefault();

    this.props.resetPassword(this.state.email)
  }

  successMessage = () => {
    return (
      <div className="reset-success-message gotham-medium-font">
        <div><i className="material-icons" style={{ verticalAlign: 'text-bottom' }}>check</i> Your Reset Password Link Has Been Sent</div>
        <div className='nimbus-regular-font'>Check your email to reset your password</div>
      </div>
    )
  }

  failureMessage = () => {
    return (
      <div className="reset-failure-message nimbus-regular-font">Sorry, we didn't find an account under that email.</div>
    )
  }

  render() {
    return (
      <form className="v2ForgotPasswordScene nimbus-regular-font" onSubmit={this.onSubmit}>
        <div className="v2-title gotham-bold-font">
          <p className='v2-title-p'>
            forgot password
          </p>
        </div>
        {get(this.props, 'location.state.redirected') &&
          <UncontrolledAlert color="danger">Sorry, there was a problem with that link. Please enter your email below to try again.</UncontrolledAlert>}
        <div className='resetPasswordForm'>
          <label>Enter your email below and we’ll send you a link to <span className="text-danger">reset</span> your password.</label>
          <input type="email"
            name="email"
            value={this.state.email}
            onChange={this.handleEmailChange}
            className='nimbus-regular-font' />
          {this.props.submitted && !this.props.success && this.failureMessage()}
          {this.props.success ? this.successMessage() : <button className="btn plain-button gotham-bold-font" disabled={!this.state.email} >reset password</button>}
        </div>
      </form>
    );
  }
}

const mapStateToProps = (state) => ({
  submitted: state.resetPassword.success || state.resetPassword.error,
  success: state.resetPassword.success && (!state.resetPassword.error)
})

const mapDispatchToProps = (dispatch) => ({
  resetPassword: (email) => dispatch(resetPassword(email)),
  clearResetPassword: () => dispatch(clearResetPassword())
})

export default connect(mapStateToProps, mapDispatchToProps)(V2ForgotPasswordScene);