import React, { Component } from 'react';
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  UncontrolledTooltip,
} from 'reactstrap';

class ReviewModal extends Component {
  state = {
    open: false,
    review: {
      id: this.props.item.id,
      rating: null,
      additionalFeedback: '',
    },
  };

  toggle = () => {
    this.setState((prevState) => ({
      open: !prevState.open,
      review: !prevState.open
        ? {
            id: this.props.item.id,
            rating: null,
            additionalFeedback: '',
          }
        : prevState.review,
    }));
  };

  onSave = () => {
    const { review } = this.state;
    this.props.onSubmit(review);
    this.setState({
      open: false,
    });
  };

  onSkip = () => {
    this.props.onSkip(this.props.item);
    this.setState({
      open: false,
    });
  };

  render() {
    const { item } = this.props;
    const { review } = this.state;
    return (
      <div className="reviewModal">
        <button className="modal-button plain-button" onClick={this.toggle}>
          {this.props.children}
        </button>
        <Modal
          isOpen={this.state.open}
          toggle={this.toggle}
          className="review-modal-content"
        >
          <ModalHeader className="header" toggle={this.toggle}>
            <img
              src={item.ATPhoto || require('styles/BlankAvatar.svg')}
              alt="Athletic Trainer"
            />
            <div className="modal-title">
              {item.ATName} <h6>/ REVIEW</h6>
              <p>
                {
                  'Feedback will not be shared with the AT or other organizations.'
                }
              </p>
            </div>
          </ModalHeader>
          <ModalBody>
            <h6>
              Would you hire {item.ATName} again?{' '}
              <i className="material-icons" id="info">
                info
              </i>
            </h6>
            <UncontrolledTooltip placement="top" target="info">
              The AT’s most recent rating from your organization is visible on
              their profile to inform your future hiring decisions.
            </UncontrolledTooltip>
            <div className="row">
              <div className="col-md-3">
                <div
                  className={`rate-choice ${
                    review.rating === 1 ? 'selected' : ''
                  }`}
                  onClick={() =>
                    this.setState({ review: { ...review, rating: 1 } })
                  }
                >
                  <div className="material-icons icon">check</div>
                  YES
                </div>
              </div>
              <div className="col-md-3">
                <div
                  className={`rate-choice ${
                    review.rating === 0 ? 'selected' : ''
                  }`}
                  onClick={() =>
                    this.setState({ review: { ...review, rating: 0 } })
                  }
                >
                  <div className="material-icons icon">close</div>
                  NO
                </div>
              </div>
            </div>
            <div className="row">
              <div className="form-group col-md-12">
                <label htmlFor="instructions" style={{ marginBottom: 0 }}>
                  Any more feedback?
                </label>
                <p>
                  For customer service questions, please email{' '}
                  <a href="mailto:contactus@go4.io">contactus@go4.io</a>.
                </p>
                <textarea
                  className="form-control"
                  style={{
                    width: '100%',
                    minHeight: '120px',
                  }}
                  type="text"
                  name="feedback"
                  value={review.additionalFeedback}
                  rows={5}
                  onChange={(e) =>
                    this.setState({
                      review: { ...review, additionalFeedback: e.target.value },
                    })
                  }
                />
              </div>
            </div>
          </ModalBody>
          <ModalFooter className="actions">
            <button
              disabled={review.rating === null}
              className="btn btn-success"
              onClick={this.onSave}
            >
              SUBMIT
            </button>
            <button className="plain-button skip-button" onClick={this.onSkip}>
              SKIP
            </button>
          </ModalFooter>
        </Modal>
      </div>
    );
  }
}

export default ReviewModal;
