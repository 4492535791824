import React, { Component } from 'react';
import { connect } from 'react-redux';
import { parse } from 'query-string';

import { updatePassword, validateToken } from 'redux/ducks/ResetPassword';

import ResetPasswordForm from './ResetPasswordForm';
import ResetPasswordSuccess from './ResetPasswordSuccess';

class ResetPasswordScene extends Component {
  componentWillMount() {
    const session = parse(this.props.location.search);
    this.props.validateToken(session['token']);
  }

  render() {
    return (
      <div className="resetPasswordScene">
        {this.props.success ?
          <ResetPasswordSuccess {...(this.props.data)} /> :
          <ResetPasswordForm {...this.props} />}
      </div>
    );
  }
}

function mapStateToProps(state) {
  return state.resetPassword;
}

function mapDispatchToProps(dispatch) {
  return {
    updatePassword: (password, confirmation, headers, token) => dispatch(updatePassword({ headers, password, confirmation, token })),
    validateToken: (token) => dispatch(validateToken(token)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ResetPasswordScene);