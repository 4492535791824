import React, { Component } from 'react';
import { connect } from 'react-redux';
import { MODIFICATIONS_COLUMNS } from 'helpers/dashboard';
import EventList, { scenes } from 'components/V2/EventList/EventList';
import {
  changeShiftModificationsPage,
  changeShiftModificationsSearch,
  changeShiftModificationsSort,
  getShiftModifications,
  resetPagination,
} from 'redux/ducks/Dashboard';

class ShiftModifications extends Component {
  onChangePage = (data) => {
    const page = data.selected + 1;
    this.props.onChangePage(page);
  };

  reloadData = () => {
    this.props.resetPagination();
    this.props.getShiftModifications();
  };

  render() {
    const { modifications, pagination, search, sort } = this.props;
    const key = Object.keys(sort)[0];
    const value = sort[key];
    return (
      <div className="shiftModifications">
        <EventList
          title="Review within 24 Hrs"
          onChangeSearch={this.props.changeSearch}
          searchValue={search}
          data={modifications}
          reloadData={this.reloadData}
          pagination={pagination}
          onChangePage={this.onChangePage}
          column={scenes.MODIFICATIONS}
          columns={MODIFICATIONS_COLUMNS}
          emptyMessage="No shift modifications to review."
          sort={{ key, value }}
          onChangeSort={this.props.onChangeSort}
        />
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  modifications: state.dashboard.modifications.modifications,
  sort: state.dashboard.modifications.sort,
  pagination: state.dashboard.modifications.pagination,
  search: state.dashboard.modifications.search,
});

const mapDispatchToProps = (dispatch) => ({
  getShiftModifications: (params) => dispatch(getShiftModifications(params)),
  onChangePage: (page) => dispatch(changeShiftModificationsPage(page)),
  changeSearch: (search) => dispatch(changeShiftModificationsSearch(search)),
  onChangeSort: (key, value) =>
    dispatch(changeShiftModificationsSort({ key, value })),
  resetPagination: () => dispatch(resetPagination('modifications')),
});

export default connect(mapStateToProps, mapDispatchToProps)(ShiftModifications);
