import React, { Component } from 'react';
import classNames from 'classnames';
import { withRouter } from 'react-router-dom/cjs/react-router-dom';
import { connect } from 'react-redux';
import moment from 'moment';
import ExperienceModal from 'scenes/Dashboard/V2/ExperienceModal/ExperienceModal';
import ReviewModal from 'scenes/Dashboard/V2/ReviewModal/ReviewModal';
import { updateReview } from 'redux/ducks/Dashboard';
import { getAthleticTrainer } from 'redux/ducks/User';
import { UncontrolledTooltip } from 'reactstrap';
import { SHOW_TOOLTIP_COLUMNS, createOverflowChecker } from 'helpers/dashboard';

class ATFeedbackItem extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isOverflowing: {},
    };
    this.columnRefs = {};
    SHOW_TOOLTIP_COLUMNS.feedback.forEach((column) => {
      this.columnRefs[column] = React.createRef();
    });
  }

  componentDidMount() {
    const checkOverflow = createOverflowChecker(
      'feedback',
      this.columnRefs,
      this.setState.bind(this)
    );

    this.resizeObserver = new ResizeObserver(checkOverflow);
    SHOW_TOOLTIP_COLUMNS.feedback.forEach((column) => {
      this.resizeObserver.observe(this.columnRefs[column].current);
    });
  }

  componentWillUnmount() {
    this.resizeObserver.disconnect();
  }

  onSubmitReview = (review) => {
    if (review.rating === null) return;

    this.props.evaluateReview(review);
  };

  getAtTById = (ATId, pendingApplicants) => {
    const ATIndex = pendingApplicants.findIndex((at) => at.id === ATId);

    if (ATIndex !== -1) return;

    this.props.getAthleticTrainer(ATId);
  };

  onSkip = (item) => {
    this.props.evaluateReview({
      id: item.id,
      rating: 2,
      additionalFeedback: '',
    });
  };

  render() {
    const { item, columns, pendingApplicants } = this.props;

    return (
      <div className="feedback-list-item">
        <div className={`${columns['atName'].size}`}>
          <ExperienceModal
            onClick={() => this.getAtTById(item.ATId, pendingApplicants)}
            id={item.ATId}
            pendingApplicants={pendingApplicants}
            from={'reviews'}
            reviewId={item.id}
          >
            {item.ATName}
          </ExperienceModal>
        </div>
        <div
          className={`${columns['jobWorked'].size}`}
          id={`jobWorked-${item.id}`}
          ref={this.columnRefs['jobWorked']}
        >
          {item.eventTitle}
          {this.state.isOverflowing.jobWorked && (
            <UncontrolledTooltip target={`jobWorked-${item.id}`}>
              {item.eventTitle}
            </UncontrolledTooltip>
          )}
        </div>
        <div
          className={`${columns['location'].size}`}
          id={`location-${item.id}`}
          ref={this.columnRefs['location']}
        >
          {item.location}
          {this.state.isOverflowing.location && (
            <UncontrolledTooltip target={`location-${item.id}`}>
              {item.location}
            </UncontrolledTooltip>
          )}
        </div>
        <div
          className={`${columns['dateWorked'].size}`}
          id={`dateWorked-${item.id}`}
          ref={this.columnRefs['dateWorked']}
        >
          {moment(item.shiftDate).format('MM/DD/YY')}
          {this.state.isOverflowing.dateWorked && (
            <UncontrolledTooltip target={`dateWorked-${item.id}`}>
              {moment(item.shiftDate).format('MM/DD/YY')}
            </UncontrolledTooltip>
          )}
        </div>
        <div className={classNames(`${columns['actions'].size} actions`)}>
          <ReviewModal
            item={item}
            onSubmit={this.onSubmitReview}
            onSkip={this.onSkip}
          >
            REVIEW
          </ReviewModal>
          <button
            className="plain-button skip"
            onClick={() => this.onSkip(item)}
          >
            SKIP
          </button>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    pendingApplicants: state.user.pendingApplicants,
  };
};

const mapDispatchToProps = (dispatch) => ({
  evaluateReview: (review) => dispatch(updateReview(review)),
  getAthleticTrainer: (id) => dispatch(getAthleticTrainer(id)),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(ATFeedbackItem)
);
