import ApiService from "./ApiService";
import { ApplySerializer } from "services/serializers";

class ApplyService extends ApiService {
  constructor() {
    super();

    this.url = "/apply";
  }

  post(data) {
    return super.post(
      `${this.url}.json`,
      ApplySerializer.serialize(data),
      (response) => ApplySerializer.deserialize(response)
    );
  }
}

export default ApplyService;
