import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Elements } from 'react-stripe-elements';
import { withRouter } from 'react-router-dom';
import { success } from 'redux/ducks/Flash';
import { retrieveSource, submit, updateField } from 'redux/ducks/Payment';
import CreditCard from './CreditCard';
import Checking from './Checking';
import Go4Spinner from 'components/LoadingSpinner/Go4Spinner';
import { getErrorMessage } from 'helpers/payment';
import CustomRadioCheckBox from 'components/V2/CustomInputs/CustomRadioCheckBox';

export class EditPaymentScene extends Component {
  componentWillReceiveProps(newProps) {
    if (newProps.payment.success) {
      this.props.history.push('/dashboard');
    }
  }

  enableSubmit = () => {
    const { payment } = this.props;
    const { card, validation, object, token } = payment;
    const { validator } = validation.card;

    const isCardValid = validator.activeFields.every((field) => {
      const value = card[field];
      const config = validator.config[field];
      return (
        (config.required ? !!value : true) &&
        (config.minLength ? value.length >= config.minLength : true) &&
        (config.maxLength ? value.length <= config.maxLength : true) &&
        (config.ofType === 'number' ? !isNaN(value) : true)
      );
    });

    return object === 'card'
      ? isCardValid && !!token
      : object === 'bank_account' && validation.check.valid;
  };

  hasSource = () => !!this.props.payment.source.id;

  onCancel = () => {
    this.props.history.push('/dashboard');
  };

  onFieldChange = (field, value) => {
    this.props.updateField(field, value);
  };

  onSubmit = () => {
    this.props.submit(this.props.user.id, this.props.payment.token, this.props.payment.check.plaid);
  };

  onTermsChanged = (event) => {
    this.props.updateField('stripeTerms', event.target.checked);
  };

  render() {
    const { payment } = this.props;

    if (!payment.loadingSource && !payment.loadedSource) {
      return (
        <div className="editPaymentScene mt-5">
          <Go4Spinner />
        </div>
      );
    }

    return (
      <div className="editPaymentScene">
        <div className="title-wrapper">
          <h2>Payment Info</h2>
        </div>

        <form>
          <h3>SELECT PAYMENT TYPE</h3>
          <div className="form-group column payment-types">
            <CustomRadioCheckBox
              id="cardSelected"
              name="cardSelected"
              value="card"
              checked={payment.object === 'card'}
              onChange={(e) => this.onFieldChange('object', e.target.value)}
            >
              Credit Card
            </CustomRadioCheckBox>
            <CustomRadioCheckBox
              id="ACHSelected"
              name="ACHSelected"
              value="bank_account"
              checked={payment.object === 'bank_account'}
              onChange={(e) => this.onFieldChange('object', e.target.value)}
            >
              ACH
            </CustomRadioCheckBox>
          </div>
          {payment.object === 'card' && (
            <Elements>
              <CreditCard user={this.props.user} />
            </Elements>
          )}
          {payment.object === 'bank_account' && (
            <Elements>
              <Checking user={this.props.user} />
            </Elements>
          )}
          <div className="separator"> </div>

          <div className="row">
            {!!payment.cardError && (
              <div className="col-lg-12">
                <div className="flashMessage w-100">
                  <div className="message error">
                    <span className="message-text">{getErrorMessage(payment.cardError)}</span>
                  </div>
                </div>
              </div>
            )}
          </div>
          <div className="controls row">
            <div className="cancel-container col-sm-3">
              <button className="cancel-button" onClick={this.onCancel}>
                Cancel
              </button>
            </div>
            <div className="btn-wrapper update-payment-button col-sm-6 col-lg-6">
              <button
                type="button"
                className="btn bold-button float-right"
                onClick={this.onSubmit}
                disabled={!this.enableSubmit()}
              >
                {'UPDATE PAYMENT'}
              </button>
            </div>
          </div>
        </form>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  payment: state.payment,
  user: state.session.currentUser,
  validation: state.payment.validation,
});

const mapDispatchToProps = (dispatch) => ({
  retrieveSource: (userId) => dispatch(retrieveSource(userId)),
  submit: (userId, token, plaid) => dispatch(submit(userId, token, plaid)),
  success: (message) => dispatch(success(message)),
  updateField: (field, value) => dispatch(updateField(field, value)),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(EditPaymentScene));
