import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import {
  registrationFieldUpdate,
} from "redux/ducks/Registration";

class HomeContactForm extends Component {
  handleSubmit = (e, type) => {
    e.preventDefault();
    const { history } = this.props;
    this.props.updateField("postEventMasterType", type);
    if (type === "industrial") {
      this.props.updateField("eventTypeId", "7");
      this.props.updateField("sportId", "35");
    }
    if (type === "contract") {
      history.push("/sign-up/post-event/profile")
    } else {
      history.push("/sign-up/post-event/account")
    }
  }

  render() {
    return (
      <div className="homePostEventForm">
        <div className="header">What do you need to hire healthcare professionals for?</div>
        <form className="body">
          <button onClick={e => this.handleSubmit(e, "sports")} type="submit">
            Sports/Non-Sports
          </button>
          <button onClick={e => this.handleSubmit(e, "industrial")} type="submit">
            Covid-19 Related
          </button>
          <button onClick={e => this.handleSubmit(e, "contract")} type="submit">
            Short-Term Contract
          </button>
          <hr />
          <a className="contact-us-btn btn btn-primary" href="mailto:contactus@go4.io">Contact Us</a>
          {
            /*
            <button onClick={this.handleSubmit} type="submit">
              Submit
            </button>
            */
          }
        </form>
      </div>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return {
    updateField: (field, value) =>
      dispatch(registrationFieldUpdate(field, value)),
  };
}

export default withRouter(connect(null, mapDispatchToProps)(HomeContactForm))
