import { combineReducers, createStore, applyMiddleware } from 'redux';
import { createEpicMiddleware } from 'redux-observable';
import { composeWithDevTools } from 'redux-devtools-extension';

import * as reducers from './ducks';
import { rootEpic } from './ducks/epics';

import { DESTROY_SESSION_SUCCESS, DESTROY_SESSION_ERROR } from './ducks/Session';
import LogRocket from 'logrocket';

const appReducer = combineReducers(reducers);

const rootReducer = (state, action) => {
  if (action.type === DESTROY_SESSION_SUCCESS ||
    action.type === DESTROY_SESSION_ERROR) {
    const { routing } = state;
    state = { routing };
  }

  return appReducer(state, action);
};

const epicMiddleware = createEpicMiddleware(rootEpic);

const store = createStore(rootReducer, composeWithDevTools(applyMiddleware(epicMiddleware, LogRocket.reduxMiddleware())));

export default store;