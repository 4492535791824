import React from 'react';
import HomeList from '../HomeList';

const list = [
  'Baseball',
  'Basketball',
  'BMX',
  'Bowling',
  'Boxing',
  'Cheer',
  'Cross Country',
  'Cycling',
  'Dance',
  'Diving',
  'Equestrian',
  'Extreme Sports',
  'Fencing',
  'Field Hockey',
  'Flag Football',
  'Football',
  'Golf',
  'Gymnastics',
  'Handball',
  'Ice Hockey',
  'Industrial',
  'Lacrosse',
  'Martial Arts',
  'Quidditch',
  'Roller Sports',
  'Rowing',
  'Rugby',
  'Running',
  'Skateboarding',
  'Soccer ',
  'Softball ',
  'Squash',
  'Swimming',
  'Tennis',
  'Track and Field',
  'Ultimate',
  'Volleyball',
  'Water Polo',
  'Wresting'
];

const SportsList = () => <HomeList list={list}/>;

export default SportsList;