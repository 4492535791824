import React from "react";
import PropTypes from "prop-types";

import "./SectionBannerAlt.scss";

const SectionBannerAlt = ({ text, dark }) => {
  return <div className="sectionBannerAlt">{text}</div>;
};

export default SectionBannerAlt;

SectionBannerAlt.propTypes = {
  text: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired
};
