import React, { Component } from 'react';
import { Route } from 'react-router-dom';
import ScheduleChoice from './ScheduleChoice';
import OneTimeSchedule from './OneTimeSchedule';
import RecurringSchedule from './RecurringSchedule';
import classNames from 'classnames';
import { last } from 'lodash';

class EventSchedule extends Component {
  constructor(props) {
    super(props);
    props.initialize('EventSchedule');
  }

  pathname = () => {
    const { location } = this.props;
    return last(location.pathname.split('/'));
  }

  render() {
    this.pathname()
    return (
      <div className={classNames("eventSchedule")}>
        <div className="nav-placeholder" />
        <main className={classNames({
          center: this.pathname() === 'type'
        })}>
          <Route
            path={`${this.props.baseUrl}/schedule/type`}
            render={() => <ScheduleChoice {...this.props} />}
          />
          <Route
            path={`${this.props.baseUrl}/schedule/one-time`}
            render={() => <OneTimeSchedule {...this.props} />}
          />
          <Route
            path={`${this.props.baseUrl}/schedule/recurring`}
            render={() => <RecurringSchedule {...this.props} />}
          />
        </main>
      </div>
    );
  }
}

export default EventSchedule;
