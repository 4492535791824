import React, { Component } from 'react';
import { isEmpty, get } from 'lodash';
import { Collapse } from 'reactstrap';
import zipcode_to_timezone from 'zipcode-to-timezone';
import { formatDateTimeTz } from 'helpers/datetime';
import { inactiveStates } from './ShiftPanelList';
import moment from 'moment-timezone';

const ShiftJobsListItem = ({ shift, job, onRemove, waitlisted }) => {
  const tz = zipcode_to_timezone.lookup(shift.eventLocation.address.zipCode) || moment.tz.guess();

  return (
    <li key={job.id} className="col-md-12 jobs-list-item">
      <div className="row align-items-center">
        <div className="col-md-1">
          <img src={get(job, 'user.profilePic') || require('styles/BlankAvatar.svg')} alt="profile" />
        </div>
        <div className="col-md-3 text">
          {`${get(job, 'user.firstName')} ${get(job, 'user.lastName')}`}
        </div>
        <div className="col-md-4 text">
          {formatDateTimeTz(job.startTime, tz)} - {formatDateTimeTz(job.endTime, tz)}
        </div>
        <div className="col-md-4">
          {job.currentState !== 'decommitted' &&
            <button
              type="button"
              className="btn text-danger btn-link"
              onClick={() => onRemove(job.id)} >
              {
                waitlisted ? 'remove from waitlist' : 'remove from shift'
              }
            </button>
          }
        </div>
      </div>
    </li>
  )
}

class ShiftJobsList extends Component {
  state = {
    waitlistExpanded: false,
  }
  get activeJobs() {
    return this.props.jobs.filter(job => !inactiveStates.includes(job.currentState));
  }
  get waitlistJobs() {
    return this.props.jobs.filter(job => job.currentState === "waitlist");
  }
  toggleWaitlist = () => {
    this.setState({ waitlistExpanded: !this.state.waitlistExpanded });
  }
  render() {
    const { onRemove, shift } = this.props;
    return (
      <ul className="jobs-list">
        {this.activeJobs.map(job => ShiftJobsListItem({ shift, job, onRemove, waitlisted: false }))}
        <li>
          <button type="button"
            onClick={this.toggleWaitlist}
            disabled={isEmpty(this.waitlistJobs)}
            className="btn btn-link"
          >
            View Waitlist
          </button>
        </li>
        <Collapse isOpen={this.state.waitlistExpanded}>
          {this.waitlistJobs.map(job => ShiftJobsListItem({ shift, job, onRemove, waitlisted: true }))}
        </Collapse>
      </ul>
    );
  }
}

export default ShiftJobsList;