import React, { Component } from 'react';
import Flatpickr from 'react-flatpickr';
import { ZONE_ABBREVIATIONS } from 'helpers/datetime';

const pickerHandler = (handler) => (dates, dateStr) => handler(dateStr);

class InputOrFlatpickr extends Component<Object, Object> {
  state = {
    supportsDate: true,
  };

  addTimezone = () => {
    const { timezone } = this.props;
    if (!timezone) return;

    const timeFragments = document.querySelectorAll('.flatpickr-am-pm');

    if (timeFragments.length) {
      timeFragments.forEach((timeFragment) => {
        const existingSpan = timeFragment.parentNode.querySelector(`.flatpickr-timezone`);
        if (existingSpan) return;

        const newSpan = document.createElement('span');
        newSpan.innerText = ZONE_ABBREVIATIONS[timezone] || 'ET';
        newSpan.className = `flatpickr-am-pm flatpickr-timezone`;
        newSpan.tabIndex = -1;

        timeFragment.parentNode.appendChild(newSpan);
      });
    }
  };

  render() {
    if (this.state.supportsDate) {
      return <input ref="input" {...this.props} />;
    } else {
      return (
        <Flatpickr
          onClick={() => this.setState({ placeholder: false })}
          {...this.props}
          onChange={this.props.onChange && pickerHandler(this.props.onChange)}
          onValueUpdate={this.props.onValueUpdate && pickerHandler(this.props.onValueUpdate)}
          onClose={this.props.onBlur}
          onFocus={this.addTimezone}
        />
      );
    }
  }

  componentDidMount() {
    const { input } = this.refs;
    if (input.type === 'text') {
      this.setState({ supportsDate: false });
    }
  }
}

export default InputOrFlatpickr;
