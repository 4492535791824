import React, { Component } from 'react';
import { connect } from 'react-redux';
import { isEmpty, isEqual, omit } from 'lodash';
import ReportToContact from './ReportToContact'

class ReportToContactList extends Component {
  state = {
    idRandom: Math.floor(Math.random() * 1E7),
    useEoData: false,
  }

  componentDidMount() {
    const { addContact, contacts, disabled, } = this.props;
    if (!disabled && contacts.length === 0) addContact();
  }

  contactsLength = () => this.props.contacts.filter((contact) => (!contact._destroy)).length;

  toggleUseEoData = () => {
    const { contacts, addContact, changeContact, removeContact, email, name, phoneNumber } = this.props;
    const eoContact = { name, email, phoneNumber };
    const { length } = contacts;
    if (!this.state.useEoData) {
      const index = contacts.findIndex((contact) => !contact._destroy && Object.values(contact).some(isEmpty));

      if (index === -1) addContact();
      changeContact(index === -1 ? length - 1 : index, eoContact);
    } else {
      const index = contacts.findIndex(contact => isEqual(omit(contact, 'id'), eoContact));
      if (index > -1) {
        removeContact(index);
        if (this.contactsLength() < 2) addContact();
      }
    }
    this.setState({ ...this.state, useEoData: !this.state.useEoData });
  }

  render() {
    const {
      idx,
      contacts,
      changeContact,
      errors,
      emptyError,
      showReuseOnsiteContactCheckbox,
      onsiteContactsDisabled,
      onReuseOnsiteContactChanged,
      disabled,
    } = this.props;

    return (
      <div className="reportToContactList">
        <label> <b>Job Contact(s)</b></label>
        <div><label>Contact information is shared with healthcare providers once they are confirmed to work.</label></div>
        <div className="invalid-feedback-displayed">
          {emptyError}
        </div>
        {
          contacts.map((contact, idx) => {
            return !contact._destroy && (
              <ReportToContact
                key={idx}
                disabled={disabled}
                canRemove={!disabled && this.contactsLength() > 1}
                contact={contact}
                idx={idx}
                errors={errors[idx]}
                changeContact={changeContact}
                removeContact={this.props.removeContact}
                wasValidated={this.props.wasValidated}
              />
            )
          })
        }
        {(idx == 0 || !onsiteContactsDisabled) && <div className="d-flex flex-row-reverse mt-3">
          <div className="form-group col onsite-contacts-check-col">
            <div className="custom-control custom-checkbox">
              <input
                checked={this.state.useEoData}
                onChange={this.toggleUseEoData}
                type="checkbox"
                className="custom-control-input"
                id={`eoContact-${this.state.idRandom}`} />
              <label className="custom-control-label"
                htmlFor={`eoContact-${this.state.idRandom}`}>
                Same as the Go4 Account Owner
              </label>
            </div>
          </div>
        </div>}
        {showReuseOnsiteContactCheckbox &&
          <div className="d-flex flex-row-reverse mt-3">
            <div className="form-group col onsite-contacts-check-col">
              <div className="custom-control custom-checkbox">
                <input
                  checked={onsiteContactsDisabled}
                  onChange={onReuseOnsiteContactChanged}
                  type="checkbox"
                  className="custom-control-input"
                  id="reuseOnsiteContact" />
                <label className="custom-control-label"
                  htmlFor="reuseOnsiteContact">
                  Use this contact(s) for all shifts
                </label>
              </div>
            </div>
          </div>
        }
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const { currentUser: { email, name, phone: phoneNumber } } = state.session;

  return { email, name, phoneNumber };
}

export default connect(mapStateToProps)(ReportToContactList);