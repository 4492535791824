import React, { Component } from 'react';
import PhoneInput from 'react-phone-input-2';
import validator from 'validator';
import { AddButton } from 'components/V2/Buttons';
import HorizontalRule from 'components/HorizontalRule';

class ContactList extends Component {
  onRemove = (contactIndex) => () => {
    this.props.removeContact(contactIndex);
  };

  onPhoneNumberChange(idx, contact, newNumber) {
    const phoneNo = newNumber.length === 1 && newNumber.at(0) !== '1' ? `1${newNumber}` : newNumber;

    this.props.changeContact(idx, { ...contact, phoneNumber: phoneNo });
  }

  renderMobileContactListItem({ idx, contact, length }) {
    return (
      <div key={`mobile-${idx}`} className="contact-mobile-row form-row">
        <div className="form-group col-12 m-0">
          <label htmlFor="contact_role">Role</label>
          <input
            type="text"
            className="form-control nimbus-regular-font italic-placeholder"
            placeholder="Tournament Director"
            onChange={(ev) =>
              this.props.changeContact(idx, { ...contact, role: ev.currentTarget.value })
            }
            value={contact.role}
          />
          {(contact.phoneNumber || contact.name) && !contact.role && this.props.wasValidated && (
            <div className="invalid-feedback-displayed nimbus-regular-font">
              This field is required
            </div>
          )}
        </div>
        <div className="form-group col-12 m-0">
          <label htmlFor="contact_name">Name</label>
          <input
            type="text"
            className="form-control nimbus-regular-font"
            onChange={(ev) =>
              this.props.changeContact(idx, { ...contact, name: ev.currentTarget.value })
            }
            value={contact.name}
          />
          {(contact.phoneNumber || contact.role) && !contact.name && this.props.wasValidated && (
            <div className="invalid-feedback-displayed nimbus-regular-font">
              This field is required
            </div>
          )}
        </div>
        <div className="merge-phone form-group col-12 nimbus-regular-font">
          <label className="text-nowrap" htmlFor="contact_role">
            Phone Number
          </label>
          <PhoneInput
            name="phone"
            onlyCountries={['us']}
            preferredCountries={['us']}
            enableAreaCodes={false}
            autoFormat={true}
            inputStyle={{ width: '100%' }}
            placeholder="Enter phone number"
            country="us"
            onChange={(newNumber) => this.onPhoneNumberChange(idx, contact, newNumber)}
            value={contact.phoneNumber}
          />
          {(contact.name || contact.role) &&
            !validator.isMobilePhone(contact.phoneNumber) &&
            this.props.wasValidated && (
              <div className="invalid-feedback-displayed nimbus-regular-font">
                This field is required
              </div>
            )}
        </div>
        {length !== 1 && (
          <div className="col-12 m-0 delete-button">
            <AddButton onClick={this.onRemove(idx)}>Delete</AddButton>
          </div>
        )}
        <HorizontalRule dashed margin="10px" />
      </div>
    );
  }

  renderContactListItem = ({ contact, idx, length }) => {
    return (
      <div key={idx} className="contact-row form-row">
        <div className="form-group col-4 m-0">
          <label htmlFor="contact_role">Role</label>
          <input
            type="text"
            className="form-control nimbus-regular-font italic-placeholder"
            placeholder="Tournament Director"
            onChange={(ev) =>
              this.props.changeContact(idx, { ...contact, role: ev.currentTarget.value })
            }
            value={contact.role}
          />
          {(contact.phoneNumber || contact.name) && !contact.role && this.props.wasValidated && (
            <div className="invalid-feedback-displayed nimbus-regular-font">
              This field is required
            </div>
          )}
        </div>
        <div className="form-group col-3 m-0">
          <label htmlFor="contact_name">Name</label>
          <input
            type="text"
            className="form-control nimbus-regular-font"
            onChange={(ev) =>
              this.props.changeContact(idx, { ...contact, name: ev.currentTarget.value })
            }
            value={contact.name}
          />
          {(contact.phoneNumber || contact.role) && !contact.name && this.props.wasValidated && (
            <div className="invalid-feedback-displayed nimbus-regular-font">
              This field is required
            </div>
          )}
        </div>
        <div className="merge-phone form-group col-4 nimbus-regular-font">
          <label className="text-nowrap" htmlFor="contact_role">
            Phone Number
          </label>
          <PhoneInput
            name="phone"
            onlyCountries={['us']}
            preferredCountries={['us']}
            enableAreaCodes={false}
            autoFormat={true}
            inputStyle={{ width: '100%' }}
            placeholder="Enter phone number"
            country="us"
            onChange={(newNumber) => this.onPhoneNumberChange(idx, contact, newNumber)}
            value={contact.phoneNumber}
          />
          {(contact.name || contact.role) &&
            !validator.isMobilePhone(contact.phoneNumber) &&
            this.props.wasValidated && (
              <div className="invalid-feedback-displayed nimbus-regular-font">
                This field is required
              </div>
            )}
        </div>
        {length !== 1 && (
          <div className="col-1 px-0 delete-button">
            <AddButton onClick={this.onRemove(idx)}>Delete</AddButton>
          </div>
        )}
        <HorizontalRule dashed margin="5px" />
      </div>
    );
  };

  render() {
    const length = this.props.contacts.filter((contact) => !contact._destroy).length;
    return (
      <div className="contact-list">
        <div className="section-header gotham-bold-font col">Contact List</div>
        {this.props.contacts.map((contact, idx) => {
          if (contact._destroy) return null;

          return (
            <React.Fragment key={idx}>
              {this.renderMobileContactListItem({ idx, contact, length })}
              {this.renderContactListItem({ idx, contact, length })}
            </React.Fragment>
          );
        })}
        <div className="actions">
          <AddButton className="add" onClick={this.props.addContact}>
            Add Emergency Contact
          </AddButton>
        </div>
      </div>
    );
  }
}

export default ContactList;
