import React from "react";

const Go4EllisText = ({ className = "" }) => {
  return (
    <strong className={`go4EllisText ${className || ""}`}>
      Go<span className="red">4</span>
    </strong>
  );
};

export default Go4EllisText;
