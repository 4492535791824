import { connect } from 'react-redux';
import React, { Component } from 'react';
import ActionBar from 'scenes/V2/Shared/ActionBar';
import classNames from 'classnames';
import { changeProfileField } from 'redux/ducks/Post/Profile';
import { getSettingDetail, getSettingName } from 'helpers/enums';
import { isId, trackMixpanelPageView } from 'helpers/post';

class EventSettingDetail extends Component {
  constructor(props) {
    super(props);
    props.initialize('EventSettingDetail');
  }

  componentDidMount() {
    trackMixpanelPageView('create-event/setting-detail', this.props.user);
  }

  onSelect = (setting) => () => {
    this.props.onChangeProfile('settingDetailId', setting.id);
  };

  onSaveAndContinue = () => {
    if (!Number.isInteger(this.props.profile.settingDetailId)) return;

    this.props.history.push(`${this.props.baseUrl}/profile`);
  };

  getScreenTitle = (eventSettingId) => {
    const setting = getSettingName(eventSettingId);
    const SCREEN_TITLES = {
      'Sports': 'What type of sporting event is it?',
      'Industrial': 'Where will the job take place?',
      'Medical': 'Where will the job take place?',
      'Performing Arts': 'Where will the job take place?',
    };

    return SCREEN_TITLES[setting] || 'What type of job is it?';
  };

  render() {
    const { profile, baseUrl } = this.props;
    const settingDetails = getSettingDetail(profile.eventSettingId);

    return (
      <div className="eventSettingDetail">
        <header>
          <div className="screen-title">{this.getScreenTitle(profile.eventSettingId)}</div>
        </header>
        <main>
          {settingDetails.map((setting) => (
            <button
              key={setting.id}
              className={classNames('setting-detail-button gotham-bold-font', {
                selected: setting.id === profile.settingDetailId,
              })}
              onClick={this.onSelect(setting)}
            >
              {setting.name}
            </button>
          ))}
        </main>
        <ActionBar
          editMode={this.props.editMode}
          currentStep="setting-detail"
          backTo={`${baseUrl}/setting`}
          onSaveAndContinue={this.onSaveAndContinue}
          nextDisabled={() => !isId(profile.settingDetailId)}
        />
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => ({
  eventProfessionId: 0,
  settingDetails: state.enums.settingDetails,
  profile: state.post.profile,
  details: state.post.details,
  user: state.session.currentUser,
});

const mapDispatchToProps = (dispatch) => ({
  onChangeProfile: (name, value) => dispatch(changeProfileField({ name, value })),
});

export default connect(mapStateToProps, mapDispatchToProps)(EventSettingDetail);
