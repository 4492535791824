import { get } from 'lodash';

function isEventCamp(eventTypeId, eventTypes) {
  if(!eventTypes) {
    return false;
  }
  let dayCamp = get(eventTypes.find(elem => elem.name == "Camp Day"), 'id', -1)
  let overnightCamp = get(eventTypes.find(elem => elem.name == "Camp Overnight"), 'id', -1)
  let camp = get(eventTypes.find(elem => elem.name == "Camp"), 'id', -1)
  return (parseInt(eventTypeId) === dayCamp || parseInt(eventTypeId) === overnightCamp || parseInt(eventTypeId) === camp)
}

export {
  isEventCamp
}
