// @flow
// Actions
// --------------------------------
const constForName = (name) => `go4ellis/CreateEvent/Shifts/${name}`
export const ADD_SHIFT = constForName('ADD_SHIFT');
export const REMOVE_SHIFT = constForName('REMOVE_SHIFT');
export const FIELD_CHANGE = constForName('FIELD_CHANGE');

export const ADD_REPORT_TO_CONTACT = constForName('ADD_REPORT_TO_CONTACT');
export const REMOVE_REPORT_TO_CONTACT = constForName('REMOVE_REPORT_TO_CONTACT');
export const REPORT_TO_CONTACT_UPDATED = constForName('REPORT_TO_CONTACT_UPDATED');

export const NEW_ADDRESS_INPUT = constForName('NEW_ADDRESS_INPUT');
export const ADDRESS_TYPEAHEAD_RESULTS_RECEIVED = constForName('ADDRESS_TYPEAHEAD_RESULTS_RECEIVED');
export const ADDRESS_TYPEAHEAD_RESULTS_ERROR = constForName('ADDRESS_TYPEAHEAD_RESULTS_ERROR');
export const GOOGLE_PLACE_SELECTED = constForName('GOOGLE_PLACE_SELECTED');
export const INVALID_MSA_SELECTED = constForName('INVALID_MSA_SELECTED');
export const NO_ZIPCODE_SELECTED = constForName('NO_ZIPCODE_SELECTED');
export const CLEAR_INVALID_MSA_MESSAGE = constForName('CLEAR_INVALID_MSA_MESSAGE');
export const CLEAR_NO_ZIPCODE_MESSAGE = constForName('CLEAR_NO_ZIPCODE_MESSAGE');

export const PLACE_DETAILS_SUCCESS = constForName('PLACE_DETAILS_SUCCESS');
export const PLACE_DETAILS_ERROR = constForName('PLACE_DETAILS_ERROR');

export const REUSE_LOCATION_CHECKED = constForName('REUSE_LOCATION_CHECKED');
export const REUSE_LOCATION_UNCHECKED = constForName('REUSE_LOCATION_UNCHECKED');

export const REUSE_ONSITE_CONTACTS_CHECKED = constForName('REUSE_ONSITE_CONTACTS_CHECKED');
export const REUSE_ONSITE_CONTACTS_UNCHECKED = constForName('REUSE_ONSITE_CONTACTS_UNCHECKED');

export const SAVE_SHIFTS_AND_CONTINUE = constForName('SAVE_SHIFTS_AND_CONTINUE');
export const SAVE_SHIFTS_AND_CONTINUE_SUCCESS = constForName('SAVE_SHIFTS_AND_CONTINUE_SUCCESS');
export const SAVE_SHIFTS_AND_CONTINUE_ERROR = constForName('SAVE_SHIFTS_AND_CONTINUE_ERROR');

export const ADDRESS_INPUT_BLUR = constForName('ADDRESS_INPUT_BLUR');
export const CANCEL_SHIFT = constForName('CANCEL_SHIFT');
