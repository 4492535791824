import { JobSerializer } from 'services/serializers/v2';
import { ApiService } from '..';

class JobService extends ApiService {
  constructor(session) {
    super(session);

    this.url = '/jobs';
  }

  update({ job, location }) {
    return super.patch(`${this.url}/${job.id}.json`, { job: JobSerializer.serialize(job, location) },
      (response) => JobSerializer.deserialize(response, location));
  }
}

export default JobService;