import HorizontalRule from "components/HorizontalRule";
import { parse } from "query-string";
import React, { Component } from "react";

class ResetPasswordForm extends Component {
  state = {
    password: '',
    confirmation: '',
    passwordError: '',
    confirmationError: ''
  }

  handlePasswordChange = (e: SyntheticEvent<HTMLInputElement>) => {
    this.setState({ password: e.currentTarget.value })
  }

  handleConfirmationChange = (e: SyntheticEvent<HTMLInputElement>) => {
    this.setState({ confirmation: e.currentTarget.value })
  }

  validatePassword = () => {
    if (this.state.password && this.state.password.length < 8) {
      this.setState(prevState => ({ passwordError: 'Password must be minimum 8 characters' }))
    } else {
      this.setState(prevState => ({ passwordError: '' }))
    }
  }

  validateConfirmation = () => {
    if (this.state.confirmation && this.state.password !== this.state.confirmation) {
      this.setState(prevState => ({ confirmationError: 'Passwords do not match' }))
    } else {
      this.setState(prevState => ({ confirmationError: '' }))
    }
  }

  canContinue = () => {
    return this.state.password.length >= 8 && this.state.password === this.state.confirmation
  }

  onSubmit = (e: SyntheticEvent<HTMLInputElement>) => {
    e.preventDefault();

    const session = parse(this.props.location.search);
    session.accessToken = session['access-token'];
    this.props.updatePassword(this.state.password, this.state.confirmation, session)
  }

  render() {
    return (
      <form onSubmit={this.onSubmit} className="v2resetPasswordForm">
        <div className="form-group">
          <label htmlFor="password">Enter and confirm your <span className="text-danger">new</span> password below.</label>
          <input
            type="password"
            className="form-control italic-placeholder"
            id="password"
            placeholder="Enter new password"
            value={this.state.password}
            onChange={this.handlePasswordChange}
            onBlur={this.validatePassword}
            required
          />
          {this.state.passwordError && <div className="text-danger">{this.state.passwordError}</div>}
        </div>
        <div className="form-group">
          <input
            type="password"
            className="form-control italic-placeholder"
            id="confirmation"
            placeholder="Confirmation password"
            value={this.state.confirmation}
            onChange={this.handleConfirmationChange}
            onBlur={this.validateConfirmation}
            required
          />
          {this.state.confirmationError && <div className="text-danger">{this.state.confirmationError}</div>}
        </div>
        <div className="password-tip">Your password needs to be at least 8 characters, and include one uppercase and one lowercase letter, plus a special character.</div>
        <button type="submit" className="btn btn-primary gotham-bold-font" disabled={!this.canContinue()}>Reset Password</button>
      </form>
    );
  }
}

export default ResetPasswordForm;