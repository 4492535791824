import React, { Component } from 'react';
import ActionBar from '../ActionBar';
import { connect } from 'react-redux';
import { changeRateField } from 'redux/ducks/Post/Rate';
import { rateTypeNameFinder } from 'helpers/rateTypeName';
import { stateRates } from 'components/AverageRatesModal/Constants';
import Tip from '../../Tip';
import moment from 'moment';
import { distributeShiftRate } from 'redux/ducks/Post/Shifts/actions';
import { validateForm } from 'redux/ducks/Post';
import { saveAsDraft } from 'redux/ducks/Post/actions';
import LatePostAlert from './LatePostAlert';
import { changeLocationField } from 'redux/ducks/Post/Locations/actions';
import { shiftsByLocation, rateValid, trackMixpanelPageView } from 'helpers/post';
import ToolTip from 'scenes/V2/ToolTip';
import { MILITARY_FORMAT } from 'helpers/datetime';
import CustomRadioCheckBox from 'components/V2/CustomInputs/CustomRadioCheckBox';
import ConfirmationModal from 'components/V2/ConfirmationModal';

class EventRate extends Component {
  onFieldChange = (name) => (ev: SyntheticInputEvent<HTMLInputElement>) => {
    this.props.onChangeRate(name, ev.target.value);
    this.props.onChangeLocation(name, ev.target.value);
  };

  componentDidMount() {
    if (!this.isNotMultiDay()) this.props.onChangeLocation('rateTypeId', '1');
  }

  componentDidUpdate() {
    if (!this.isNotMultiDay() && this.props.shiftLocation.rateTypeId == '0')
      this.props.onChangeLocation('rateTypeId', '1');
  }

  isNotMultiDay = () => this.props.shifts.every((s) => !s.isMultiDay);

  render() {
    const { shiftLocation, rateTypes, hasLateAlert, wasValidated } = this.props;
    const locationState = shiftLocation.address.state;

    const averageRateForLocationState = stateRates.find(
      (item) => item.state === locationState?.toUpperCase()
    );

    return (
      <div className="eventRate">
        <form id="rateForm" noValidate ref="form" className={wasValidated ? 'was-validated' : ''}>
          <div className="row">
            <div className="mb-3 responsive-alert mobile-screen">
              {hasLateAlert && <LatePostAlert />}
            </div>
            <div className="col-md-6 mb-3 rate-section">
              <div className="row">
                <div className="col-md-12">
                  <label htmlFor="rate_type">
                    Select a pay rate for each shift in the schedule
                  </label>
                  <div className="d-flex flex-row rate-labels">
                    <CustomRadioCheckBox
                      id="HourlyRateType"
                      name="rate_type"
                      value="0"
                      checked={`${shiftLocation.rateTypeId}` === '0'}
                      onChange={this.onFieldChange('rateTypeId')}
                      disabled={!this.isNotMultiDay()}
                    >
                      HOURLY{' '}
                      <p
                        style={{
                          display: 'inline-block',
                          marginBottom: 0,
                          marginTop: this.isNotMultiDay() ? 0 : '3px',
                        }}
                      >
                        RATE
                        {this.isNotMultiDay() && (
                          <ToolTip
                            id="tooltipHourlyRate"
                            bodyText="An athletic trainer is paid a set amount for each hour worked per shift. If multiple shifts are in the schedule, each shift will cost the hourly rate x the hours in the shift."
                            fontSize="18px"
                            top="4px"
                          />
                        )}
                      </p>
                    </CustomRadioCheckBox>
                    <CustomRadioCheckBox
                      id="GameRateType"
                      name="rate_type"
                      value="1"
                      checked={`${shiftLocation.rateTypeId}` === '1'}
                      onChange={this.onFieldChange('rateTypeId')}
                      required
                    >
                      {' '}
                      FIXED{' '}
                      <p
                        style={{
                          display: 'inline-block',
                          marginBottom: 0,
                        }}
                      >
                        RATE
                        <ToolTip
                          id="tooltipFixedRate"
                          bodyText="An athletic trainer is paid a set price for the shift. If multiple shifts are in the schedule, each shift will cost the fixed rate amount."
                          fontSize="18px"
                          top="4px"
                        />
                      </p>
                    </CustomRadioCheckBox>
                    <div className="invalid-feedback">
                      Please select a rate type for this shift.
                    </div>
                  </div>
                </div>
              </div>
              <div className="row mt-3">
                <div className="col-7">
                  <label htmlFor="pay_rate">Rate</label>
                  <div className="input-group responsive-input">
                    <div className="input-group-prepend">
                      <span className="input-group-text">$</span>
                    </div>
                    <input
                      className="form-control gotham-bold-font"
                      type="number"
                      name="pay_rate"
                      min="1"
                      max="100000"
                      step="1"
                      aria-label="Amount (to the nearest dollar)"
                      value={Number(shiftLocation.payRate) === 0 ? '' : shiftLocation.payRate}
                      placeholder="0"
                      onChange={this.onFieldChange('payRate')}
                      required
                    />
                    <div className="input-group-append">
                      <span className="input-group-text">
                        {rateTypeNameFinder(rateTypes, shiftLocation.rateTypeId) === 'hr'
                          ? 'hr'
                          : 'fixed'}
                      </span>
                    </div>
                    <div className="invalid-feedback nimbus-regular-font">
                      Pay rate is required to be between $0-$100,000
                    </div>
                  </div>
                </div>
              </div>
              <div className="row">
                {locationState && (
                  <Tip
                    text={`The avg. transacted rate in ${locationState.toUpperCase()} is ${
                      averageRateForLocationState.rate
                    }/hr`}
                  />
                )}
              </div>
            </div>
            <div className="mb-3 responsive-alert">{hasLateAlert && <LatePostAlert />}</div>
          </div>
        </form>
      </div>
    );
  }
}

class EventRateWithActions extends Component {
  constructor(props) {
    super(props);
    props.initialize('EventRate');
    this.state = {
      isModalOpen: false,
    };
  }

  componentDidMount() {
    if (!this.props.editMode) trackMixpanelPageView('create-event/rate', this.props.user);
  }

  hasRateAlert = () => {
    const dates = this.props.shifts.flatMap((shift) => moment(shift.startDate, MILITARY_FORMAT));
    const minDate = moment.min(dates);
    return moment(minDate, MILITARY_FORMAT).isBefore(moment().add(14, 'days'));
  };

  calculateLongestShift = () => {
    const { post, shiftLocation } = this.props;
    const shifts = post.shifts.filter((shift) => shift.locationId === shiftLocation.id);
    const longestShift = shifts.reduce(
      (acc, shift) => {
        const startTime = moment(shift.startTime);
        const endTime = moment(shift.endTime);
        const duration = endTime - startTime;

        if (acc.duration > duration) return acc;
        return { ...shift, duration };
      },
      { duration: 0 }
    );

    return longestShift;
  };

  hasLowFlatRate = () => {
    const { shiftLocation } = this.props;
    const stateRate = Number(
      stateRates.find((item) => item.state === shiftLocation.address.state)?.rate?.replace('$', '')
    );

    if(!stateRate) return false;

    if (shiftLocation.rateTypeId === '0') return shiftLocation.payRate * 1.1 < stateRate;

    const longestShift = this.calculateLongestShift();
    const startTime = moment(longestShift.startTime);
    const endTime = moment(longestShift.endTime);
    const durationInHours = Number(endTime.diff(startTime, 'hours', true)).toFixed(2);
    const hourlyRate = (Number(shiftLocation.payRate) / durationInHours).toFixed(2);

    return hourlyRate * 1.1 < stateRate;
  };

  onSaveAndContinue = () => {
    const { baseUrl, shiftLocation, post } = this.props;
    const lowRateLocations = JSON.parse(localStorage.getItem('showLowRatePopUp')) || [];

    const showLowRatePopUp =
      !this.state.isModalOpen &&
      this.hasLowFlatRate() &&
      !lowRateLocations.includes(shiftLocation.id);

    if (!!showLowRatePopUp) {
      this.toggle();
      const newLowRateLocations = lowRateLocations.concat(shiftLocation.id);
      localStorage.setItem('showLowRatePopUp', JSON.stringify(newLowRateLocations));
      return;
    }

    const callback = () => {
      this.props.history.push(`${baseUrl}/schedule-summary`);
    };

    if (!this.pageIsValid()) {
      this.props.validateForm();
      return;
    }

    this.props.onDistributeShiftRate({
      rate: shiftLocation.payRate,
      rateTypeId: shiftLocation.rateTypeId,
      locationId: shiftLocation.id || shiftLocation.idx,
    });

    if (!this.props.authenticated) {
      callback();
      return;
    }

    this.props.saveAsDraft(post, callback);
  };

  updateJobCallback = () => {
    const { shiftLocation } = this.props;
    if (!this.pageIsValid()) {
      this.props.validateForm();
      return false;
    }

    this.props.onDistributeShiftRate({
      rate: shiftLocation.payRate,
      rateTypeId: shiftLocation.rateTypeId,
      locationId: shiftLocation.id || shiftLocation.idx,
    });

    return true;
  };

  pageIsValid = () => rateValid(this.props.shiftLocation);

  toggle = () => this.setState({ isModalOpen: !this.state.isModalOpen });

  onContinue = () => {
    this.onSaveAndContinue();
    this.toggle();
  };

  render() {
    const { baseUrl, shiftLocation, rateTypes, locationIdx } = this.props;
    const backToUrl = shiftLocation.scheduleType
      ? `${baseUrl}/schedule/${shiftLocation.scheduleType}`
      : `${baseUrl}/schedule/type`;
    return (
      <div className="eventRateWithActions">
        <div className="nav-placeholder" />
        <main>
          <header>
            <div className="screen-title">Rate</div>
          </header>
          <EventRate
            shiftLocation={shiftLocation}
            shifts={this.props.shifts}
            rateTypes={rateTypes}
            wasValidated={this.props.wasValidated}
            onChangeRate={this.props.onChangeRate}
            onChangeLocation={this.props.onChangeLocation(locationIdx)}
            hasLateAlert={this.hasRateAlert()}
          />
          <ActionBar
            editMode={this.props.editMode}
            backTo={backToUrl}
            currentStep="rate"
            onSaveAndContinue={this.onSaveAndContinue}
            updateJobCallback={this.updateJobCallback}
            nextDisabled={() => !this.pageIsValid()}
          />
        </main>
        <ConfirmationModal
          isOpen={this.state.isModalOpen}
          toggle={this.toggle}
          title="Low Rate Warning"
          body="The rate you've entered is significantly lower than the average rate in this state. This may make it difficult to find athletic trainers, as many may not apply for jobs at this rate. We recommend adjusting your rate to improve your chances of securing an athletic trainer."
          onSave={this.toggle}
          cancelText="Continue"
          confirmText="Edit Rate"
          onCancel={this.onContinue}
        />
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const { post } = state;
  const { rateTypes } = state.enums;
  const { wasValidated } = post.validations.rate;
  const { locationIdx } = post.navigation;
  const { authenticated, currentUser } = state.session;
  const shiftLocation = post.locations[locationIdx];
  const shifts = shiftsByLocation(post.shifts, shiftLocation);
  return {
    post,
    rateTypes,
    wasValidated,
    locationIdx,
    shiftLocation,
    shifts,
    authenticated,
    user: currentUser,
  };
};

const mapDispatchToProps = (dispatch) => ({
  onChangeRate: (name, value) => dispatch(changeRateField({ name, value })),
  onChangeLocation: (idx) => (name, value) => dispatch(changeLocationField({ name, value, idx })),
  onDistributeShiftRate: (args) => dispatch(distributeShiftRate(args)),
  saveAsDraft: (post, callback) => dispatch(saveAsDraft({ post, callback, from: 'rate' })),
  validateForm: () => dispatch(validateForm('rate')),
});

export default connect(mapStateToProps, mapDispatchToProps)(EventRateWithActions);
