import React, { Component } from "react";
import { Link } from "react-router-dom";
import clsx from "classnames";

const Ghost = ({
  asAnchor,
  asLink,
  buttonType,
  children,
  className,
  href,
  to,
  ...rest
}) => {
  const buttonClass = clsx("ghostButton", "border-0", buttonType, className);
  if (asLink) {
    return (
      <Link className={buttonClass} to={to} {...rest}>
        {children}
      </Link>
    );
  }
  if (asAnchor) {
    return (
      <a className={buttonClass} href={href} {...rest}>
        {children}
      </a>
    );
  }
  return (
    <button className={buttonClass} {...rest}>
      {children}
    </button>
  );
};

export default Ghost;
