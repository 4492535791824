import React, { Component } from 'react';
import ServiceProvider from './ServiceProvider';
import HorizontalRule from 'components/HorizontalRule';
import VerticalRule from 'components/VerticalRule';

class EmergencyServices extends Component {
  renderMobileServices = () => {
    return (
      <div className="col mobile-services-section">
        <ServiceProvider name="Hospital" location={this.props.hospital} onChange={this.props.onHospitalChange} />
        <HorizontalRule dashed />
        <ServiceProvider name="Police" location={this.props.policeStation} onChange={this.props.onPoliceChange} />
        <HorizontalRule dashed />
        <ServiceProvider name="Fire Dept." location={this.props.fireDepartment} onChange={this.props.onFireDeptChange} />
      </div>
    )
  }

  renderServices = () => {
    return (
      <div className="col services-section">
        <ServiceProvider name="Hospital" location={this.props.hospital} onChange={this.props.onHospitalChange} />
        <VerticalRule dashed />
        <ServiceProvider name="Police" location={this.props.policeStation} onChange={this.props.onPoliceChange} />
        <VerticalRule dashed />
        <ServiceProvider name="Fire Dept." location={this.props.fireDepartment} onChange={this.props.onFireDeptChange} />
      </div>
    )
  }

  render() {
    return (
      <div className="emergencyServices">
        <div className="section-header col gotham-bold-font">Emergency Services</div>
        <section>
          <div className="row">
            {this.renderMobileServices()}
            {this.renderServices()}
          </div>
          <HorizontalRule margin='20px' />
          <div className="row cya-text nimbus-regular-font">All data from the Venue Location and Emergency Services section of this emergency action plan, including, without limitation, emergency room, police, and fire department locations and contact numbers, are automatically pulled into this emergency action plan from Google maps as a service to you. Go4 does not independently verify their accuracy. To ensure these are correct, we suggest you verify them in advance of this job.</div>
        </section>
      </div>
    );
  }
}

export default EmergencyServices;