import React, { useEffect, useRef } from "react";
import "./HomeContactConfirmation.scss";

function HomeContactConfirmation({ className, message }) {
  const scrollToRef = useRef(null);
  useEffect(() => {
    scrollToRef.current.scrollIntoView({
      alignToTop: false,
      behavior: "smooth",
    });
  }, []);
  return (
    <div className={`homeContactConfirmation ${className || ""}`}>
      <div id="home-contact-scroll-to" ref={scrollToRef} />
      <div className="confirmation-body">
        {message ||
          "Your info has been submitted. An account rep with follow up with you shorty to help you get connected with athletic trainers."}
      </div>
    </div>
  );
}

export default HomeContactConfirmation;
