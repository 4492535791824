import React, { Component } from 'react';

class InvalidResetTokenScene extends Component {

  componentWillMount() {
    document.body.classList.add('invalid-reset-token');
  }

  componentWillUnmount() {
    document.body.classList.remove('invalid-reset-token');
  }

  render() {
    return (
      <div className="invalidResetTokenScene">
        <h1>This link has expired</h1>
        <p>Please request a new one on the app.</p>
      </div>
    );
  }
}

export default InvalidResetTokenScene;