import React, { Component } from 'react';
import { connect } from 'react-redux';
import NewCreditCard from './NewCreditCard';
import { retrieveSource } from 'redux/ducks/Payment';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY);

class PaymentDetailsPanel extends Component {

  state = {
    editPaymentMethod: false,
  }

  cardBrandToIcon(brand) {
    const icons = {
      Visa: 'visa',
      'American Express': 'amex',
      Discover: 'discover',
      MasterCard: 'mastercard'
    }
    return icons[brand] || 'stripe'
  }

  onEditPaymentMethod = (e) => {
    e.preventDefault();
    this.setState({ editPaymentMethod: true });
  }

  render() {
    const { account } = this.props;
    return (
      <div className="paymentDetailsPanel">
        {account.id && <div className="row align-items-center">
          <span className="col-2 cc-icon">
            <i className={`fab fa-4x fa-cc-${this.cardBrandToIcon(account.brand)}`} />
          </span>
          <span className="col-md-6">
            {account.object === 'bank_account' ?
              <span>Account Number: **** **** **** {account.last4}</span>
              :
              <span>Card Number: **** **** **** {account.last4}</span>
            }
          </span>
          {account.object === 'card' &&
            <span className="col-4">Expiration: {account.expMonth} / {account.expYear}</span>
          }
        </div>}
        <Elements stripe={stripePromise}>
          {this.state.editPaymentMethod && <NewCreditCard />}
        </Elements>
        {
          !this.state.editPaymentMethod &&
          <button onClick={this.onEditPaymentMethod} className="btn btn-link add-card-btn" to="/edit-payment">
            + Add a new credit card
          </button>
        }
      </div>
    );
  }

  componentDidMount() {
    if (!this.props.account.id) {
      console.log("PaymentDetailsPanel::this.props.dispatch(retrieveSource(this.props.userId));");
      this.props.dispatch(retrieveSource(this.props.userId))
    }
  }
}

function mapStateToProps(state) {
  return {
    account: state.payment.source,
    userName: state.session.currentUser.name,
    userId: state.session.currentUser.id,
  }
}

export { PaymentDetailsPanel }

export default connect(mapStateToProps)(PaymentDetailsPanel);
