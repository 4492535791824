import React from "react";
import RedHomeButton from "../../components/RedHomeButton";
import { Route, Switch, useRouteMatch } from "react-router-dom";
import data from "./careersData";
import logo from "logo-4-white-blue.svg";
import SingleJob from "./SingleJob";
import JobDetails from "./JobDetails";
import JobsStateScene from "../JobsStateScene/JobsStateScene";

const CareersScene = () => {
  const { path, url } = useRouteMatch();
  return (
    <div className="careersScene">
      <Switch>
        <Route exact path={path}>
          <>
            <div className="careersScene-top">
              <div className="red-stripe">
                <img src={logo} alt="Go4" className="go4ellis-img" />
                <p>Careers</p>
              </div>
            </div>
            {data.length ? (
              <div className="body">
                <ul>
                  {data.map((item) => (
                    <li key={item.id}>
                      <JobDetails item={item} />
                      <div className="buttonWrapper">
                        <RedHomeButton
                          asLink
                          text={"LEARN MORE"}
                          to={`${url}/${item.id}`}
                          className="careers"
                        />
                      </div>
                    </li>
                  ))}
                </ul>
              </div>
            ) : (
              <JobsStateScene careers />
            )}
          </>
        </Route>
        <Route path={`${path}/:id`} component={SingleJob} />
      </Switch>
    </div>
  );
};

export default CareersScene;
