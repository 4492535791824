import React, { Component } from 'react';
import ActionBar from '../../ActionBar';
import classNames from 'classnames';
import { connect } from 'react-redux';
import { get } from 'lodash';
import { changeDetailsField } from 'redux/ducks/Post/Details';
import { removeMultipleShifts } from 'redux/ducks/Post/Shifts/actions';
import { changeLocationField } from 'redux/ducks/Post/Locations/actions';
import { scheduleTypes, shiftsByLocation, trackMixpanelPageView } from 'helpers/post';
import ConfirmationModal from 'components/V2/ConfirmationModal';
import HorizontalRule from 'components/HorizontalRule';

class ScheduleChoice extends Component {
  state = { open: false, nextType: '', scheduleType: '' };

  componentDidMount() {
    if (!this.props.editMode) trackMixpanelPageView('create-event/schedule/type', this.props.user);

    const scheduleType = get(this.props, 'shiftLocation.scheduleType');
    if (scheduleType) this.setState({ ...this.state, scheduleType });
  }

  toggle = (nextType = '') => {
    this.setState((prev) => ({ ...prev, open: !prev.open, nextType }));
  };

  onCancel = this.toggle;

  onSelect = (scheduleType) => {
    this.setState({ ...this.state, scheduleType });
    this.props.setLocationSchedule(this.props.shiftLocation.idx, scheduleType);
    this.props.onSelect(scheduleType);
  };

  onConfirm = () => {
    const { nextType } = this.state;
    if (nextType === this.props.shiftLocation.scheduleType || !nextType) return;

    this.props.removeMultipleShifts(this.props.shifts.flatMap((s) => s.idx));
    this.onSelect(nextType);
  };

  isSelectedType = (type) => this.state.scheduleType === type;

  onSelectType = (type) => () => {
    const { scheduleType } = this.state;
    if (scheduleType === type) return;

    if (scheduleType === scheduleTypes.RECURRING) {
      this.onSelect(type);
      return;
    }
    if (this.props.shifts.some((s) => s.id && !s.cancelled)) {
      this.toggle(type);
      return;
    }

    this.props.removeMultipleShifts(this.props.shifts.filter(({ id }) => !id).map((s) => s.idx));
    this.onSelect(type);
  };

  render() {
    const { scheduleType } = this.state;
    return (
      <div className="scheduleChoice">
        <header className="header-choice" style={{ border: 'none' }}>
          <div className="screen-title">What type of schedule do you want to create?</div>
        </header>
        <main>
          <div className="row buttons">
            <div className="choice">
              <button
                className={classNames('type-button', {
                  selected: this.isSelectedType(scheduleTypes.ONETIME),
                })}
                onClick={this.onSelectType(scheduleTypes.ONETIME)}
              >
                <p className='type gotham-bold-font'>One Time</p>
                <HorizontalRule dashed margin={0} />
                <p className="type-tip nimbus-regular-font">
                  One time shifts or a shift with consecutive days, such as a camp.
                </p>
              </button>
            </div>
            <div className="choice">
              <button
                className={classNames('type-button', {
                  selected: this.isSelectedType(scheduleTypes.RECURRING),
                })}
                onClick={this.onSelectType(scheduleTypes.RECURRING)}
              >
                <p className='type gotham-bold-font'>Repeats Weekly</p>
                <HorizontalRule dashed margin={0} />
                <p className="type-tip nimbus-regular-font">
                  Shifts repeat on set days every week.
                </p>
              </button>
            </div>
          </div>
        </main>
        <ConfirmationModal
          isOpen={this.state.open}
          toggle={this.toggle}
          title="Are you sure?"
          body="Changing the schedule type will remove all existing shifts. Are you sure you want to continue?"
          onSave={this.onConfirm}
          onCancel={this.onCancel}
        />
        <ActionBar
          editMode={this.props.editMode}
          backTo={`${this.props.baseUrl}/location`}
          currentStep="schedule/type"
          onSaveAndContinue={this.onSaveAndContinue}
          nextDisabled={() => !scheduleType}
        />
      </div>
    );
  }

  onSaveAndContinue = () => {
    this.props.history.push(
      `${this.props.baseUrl}/schedule/${this.props.shiftLocation.scheduleType}`
    );
  };
}

const mapStateToProps = (state) => {
  const shiftLocation = state.post.locations[state.post.navigation.locationIdx];
  const shifts = shiftsByLocation(state.post.shifts, shiftLocation);
  return {
    ...state.post,
    shiftLocation,
    authenticated: state.session.authenticated,
    shifts,
    user: state.session.currentUser,
  };
};

const mapDispatchToProps = (dispatch) => ({
  onSelect: (value, locationId) => dispatch(changeDetailsField({ name: 'scheduleType', value })),
  removeMultipleShifts: (indexes) => dispatch(removeMultipleShifts({ indexes })),
  setLocationSchedule: (idx, value) =>
    dispatch(changeLocationField({ idx, name: 'scheduleType', value })),
});

export default connect(mapStateToProps, mapDispatchToProps)(ScheduleChoice);
