import ApiService from './ApiService';
import { JobSerializer } from 'services/serializers';
import { assign } from 'lodash';

class JobService extends ApiService {
  constructor(session) {
    super(session);

    this.url = '/jobs';
  }

  get(id) {
    return super.get(`${this.url}/${id}.json`,
      (response) => JobSerializer.deserialize(response));
  }

  getAll() {
    return super.get(`${this.url}.json`,
      (response) => response.map(item => JobSerializer.deserialize(item)));
  }

  create(job) {
    return super.post(`${this.url}.json`, JobSerializer.serialize(job),
      (response) => JobSerializer.deserialize(response));
  }

  update(job) {
    return super.patch(`${this.url}/${job.id}.json`, { job: JobSerializer.serialize(job) },
      (response) => JobSerializer.deserialize(response));
  }

  delete(job) {
    return super.delete(`${this.url}/${job.id}.json`, () => true);
  }

  approveModification(job) {
    return super.patch(`${this.url}/${job.id}/job_modification.json`, {},
      (response) => assign(JobSerializer.deserialize(response), { jobId: job.id }));
  }

  declineModification(job) {
    return super.delete(`${this.url}/${job.id}/job_modification.json`, () => ({ jobId: job.id }));
  }
}

export default JobService;