import React, { Component } from 'react';

import { connect } from 'react-redux';

import classnames from 'classnames';

import {
  CardNumberElement,
  CardExpiryElement,
  CardCVCElement,
  injectStripe,
} from 'react-stripe-elements';

import { updateCardField, updateToken, validateCardField } from 'redux/ducks/Payment';

export class CreditCard extends Component {
  constructor() {
    super();

    this.elementStyles = {
      base: {
        'fontSize': '16px',
        'lineHeight': '1.5',
        '::placeholder': {
          color: '#C4C5C7',
        },
      },
    };

    this.state = {
      cardNumber: false,
      cardExpiry: false,
      cardCvc: false,
      cardCvcShowMsg: false,
      cardCvcValid: false,
      cardCvcErrorMsg: 'This field is required',
      editing: true,
    };
  }

  componentDidUpdate() {
    this.updateStripeToken();
  }

  componentWillMount() {
    if (this.props.loadedSource) {
      if (this.props.source.id && this.props.source.object === 'card') {
        this.setState({ ...this.state, editing: false });
      } else {
        this.setState({ ...this.state, editing: true });
      }
    }
  }

  componentWillReceiveProps(newProps) {
    if (!this.props.loadedSource && newProps.loadedSource) {
      if (newProps.source.id && newProps.source.object === 'card') {
        this.setState({ ...this.state, editing: false });
      } else {
        this.setState({ ...this.state, editing: true });
      }
    }
  }

  hasSource = () => this.props.source.id && this.props.source.object === 'card';

  onFieldBlur = (field) => {
    if (this.state.cardCvcValid) {
      this.setState({ ...this.state, cardCvcShowMsg: false });
    } else {
      this.setState({ ...this.state, cardCvcShowMsg: true });
    }
    this.props.validate(field);
  };

  onFieldChange = (field, event) => {
    this.props.updateField(field, event.currentTarget.value);
  };

  onSelectChange = (field, event) => {
    this.onFieldChange(field, event);
    this.onFieldBlur(field);
  };

  onStripeFieldChange = (event) => {
    let state = { ...this.state };
    state[event.elementType] = event.complete;

    this.setState(state);

    if (event.elementType === 'cardCvc') {
      if (!event.complete || event.empty) {
        this.setState({ ...this.state, cardCvcValid: false });
      } else {
        this.setState({ ...this.state, cardCvcValid: true, cardCvcShowMsg: false });
      }
    }

    this.updateStripeToken();
  };

  toggleEditing = () => {
    this.setState({ ...this.state, editing: !this.state.editing });
  };

  updateStripeToken = () => {
    if (
      this.state.cardNumber &&
      this.state.cardExpiry &&
      this.state.cardCvc &&
      this.props.validation.valid
    ) {
      this.props.stripe
        .createToken({
          name: `${this.props.card.firstName} ${this.props.card.lastName}`,
          address_line1: this.props.card.addressLine1,
          address_line2: this.props.card.addressLine2,
          address_city: this.props.card.addressCity,
          address_state: this.props.card.addressState,
          address_zip: this.props.card.addressZip,
        })
        .then((result) => this.props.updateToken(result.token));
    }
  };

  render() {
    return (
      <div className="creditCard">
        <div className="section">
          <h3>payment information</h3>
          <p>
            All financial transactions are processed securely through{' '}
            <a href="https://stripe.com/" target="_blank" rel="noopener noreferrer">
              Stripe
            </a>
            {'.'}
          </p>
          {this.hasSource() && (
            <div className="source-info">
              <p>Payment Information on File:</p>
              <div className="row">
                <div className="col-md-3">
                  <i>Name:</i>
                  <span>{this.props.source.name}</span>
                </div>
                <div className="col-md-3">
                  <i>Card Number:</i>
                  <span>xxxx xxxx xxxx {this.props.source.last4}</span>
                </div>
                <div className="col-md-3">
                  <i>Expiration Date:</i>
                  <span>
                    {this.props.source.expMonth}/{this.props.source.expYear}
                  </span>
                </div>
                <div className="col-md-3">
                  <i>Brand:</i>
                  <span>{this.props.source.brand}</span>
                </div>
              </div>
            </div>
          )}
          {this.state.editing ? (
            <div>
              <div className="row form-row">
                <div className="col-md-6">
                  <div className="form-group">
                    <label htmlFor="firstName">First Name</label>
                    <input
                      type="text"
                      className={classnames('form-control', {
                        'is-invalid': this.props.validation.errors.firstName,
                      })}
                      name="firstName"
                      onBlur={this.onFieldBlur.bind(null, 'firstName')}
                      onChange={this.onFieldChange.bind(null, 'firstName')}
                    />
                    <div className="invalid-feedback">{this.props.validation.errors.firstName}</div>
                  </div>
                </div>
              </div>
              <div className="row form-row">
                <div className="col-md-6">
                  <div className="form-group">
                    <label htmlFor="lastName">Last Name</label>
                    <input
                      type="text"
                      className={classnames('form-control', {
                        'is-invalid': this.props.validation.errors.lastName,
                      })}
                      name="lastName"
                      onBlur={this.onFieldBlur.bind(null, 'lastName')}
                      onChange={this.onFieldChange.bind(null, 'lastName')}
                    />
                    <div className="invalid-feedback">{this.props.validation.errors.lastName}</div>
                  </div>
                </div>
              </div>
              <div className="row form-row">
                <div className="col-md-6">
                  <div className="form-group">
                    <label htmlFor="number">Credit Card Number</label>
                    <CardNumberElement
                      type="text"
                      className={classnames('form-control', {
                        'is-invalid': this.props.validation.errors.cardNumber,
                      })}
                      onBlur={this.onFieldBlur.bind(null, 'cardNumber')}
                      onChange={this.onStripeFieldChange}
                      style={this.elementStyles}
                      placeholder=""
                    />
                    <div className="invalid-feedback">
                      {this.props.validation.errors.cardNumber}
                    </div>
                  </div>
                </div>
              </div>
              <div className="row form-row">
                <div className="col-md-2 pr-md-4 text-nowrap">
                  <div className="form-group">
                    <label htmlFor="cardExpiry">Expiration Date</label>
                    <CardExpiryElement
                      className={classnames('form-control', {
                        'is-invalid': this.props.validation.errors.cardExpiry,
                      })}
                      onBlur={this.onFieldBlur.bind(null, 'cardExpiry')}
                      onChange={this.onStripeFieldChange}
                      style={this.elementStyles}
                    />
                    <div className="invalid-feedback">
                      {this.props.validation.errors.cardExpiry}
                    </div>
                  </div>
                </div>
                <div className="col-md-2 pr-md-4">
                  <div className="form-group">
                    <label htmlFor="cardCvc">CVC Code</label>
                    <CardCVCElement
                      className={classnames('form-control', {
                        'is-invalid': this.props.validation.errors.cardCvc,
                      })}
                      onBlur={this.onFieldBlur.bind(null, 'cardCvc')}
                      onChange={this.onStripeFieldChange}
                      style={this.elementStyles}
                      placeholder=""
                    />
                    <div className={this.state.cardCvcShowMsg ? 'show-error' : 'hide-error'}>
                      {this.state.cardCvcErrorMsg}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <div></div>
          )}
        </div>

        {!this.hasSource && (
          <div className="section">
            <div className="dashed-line"></div>
          </div>
        )}

        <div className="section-no-border">
          {this.hasSource() && (
            <div className="source-info">
              <div className="row">
                <div className="col-md-3">
                  <i>Address:</i>
                  <span>
                    {this.props.source.addressLine1} {this.props.source.addressLine2}
                  </span>
                </div>
                <div className="col-md-3">
                  <i>City:</i>
                  <span>{this.props.source.addressCity}</span>
                </div>
                <div className="col-md-3">
                  <i>State:</i>
                  <span>{this.props.source.addressState}</span>
                </div>
                <div className="col-md-3">
                  <i>Zip Code:</i>
                  <span>{this.props.source.addressZip}</span>
                </div>
              </div>
            </div>
          )}
          {this.state.editing ? (
            <div>
              <div className="row form-row">
                <div className="col-md-6">
                  <div className="form-group">
                    <label htmlFor="addressLine1">Address 1</label>
                    <input
                      type="text"
                      className={classnames('form-control', {
                        'is-invalid': this.props.validation.errors.addressLine1,
                      })}
                      name="addressLine1"
                      onBlur={this.onFieldBlur.bind(null, 'addressLine1')}
                      onChange={this.onFieldChange.bind(null, 'addressLine1')}
                    />
                    <div className="invalid-feedback">
                      {this.props.validation.errors.addressLine1}
                    </div>
                  </div>
                </div>
              </div>
              <div className="row form-row">
                <div className="col-md-6">
                  <div className="form-group">
                    <label htmlFor="addressLine2">Address 2</label>
                    <input
                      type="text"
                      className={classnames('form-control', {
                        'is-invalid': this.props.validation.errors.addressLine2,
                      })}
                      name="addressLine2"
                      onBlur={this.onFieldBlur.bind(null, 'addressLine2')}
                      onChange={this.onFieldChange.bind(null, 'addressLine2')}
                    />
                    <div className="invalid-feedback">
                      {this.props.validation.errors.addressLine2}
                    </div>
                  </div>
                </div>
              </div>
              <div className="row form-row">
                <div className="col-md-6">
                  <div className="form-group">
                    <label htmlFor="addressCity">City</label>
                    <input
                      type="text"
                      className={classnames('form-control', {
                        'is-invalid': this.props.validation.errors.addressCity,
                      })}
                      name="addressCity"
                      onBlur={this.onFieldBlur.bind(null, 'addressCity')}
                      onChange={this.onFieldChange.bind(null, 'addressCity')}
                    />
                    <div className="invalid-feedback">
                      {this.props.validation.errors.addressCity}
                    </div>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-2 state">
                  <div className="form-group">
                    <label htmlFor="addressState">State</label>
                    <select
                      name="addressState"
                      className={classnames('form-control', 'custom-select', {
                        'is-invalid': this.props.validation.errors.addressState,
                      })}
                      onChange={this.onSelectChange.bind(null, 'addressState')}
                    >
                      <option></option>
                      {this.props.states.map((state, index) => (
                        <option key={index} value={state.abbreviation}>
                          {state.abbreviation}
                        </option>
                      ))}
                    </select>
                    <div className="invalid-feedback">
                      {this.props.validation.errors.addressState}
                    </div>
                  </div>
                </div>
                <div className="col-md-2 zip">
                  <div className="form-group">
                    <label htmlFor="addressZip">Zip Code</label>
                    <input
                      type="text"
                      className={classnames('form-control', {
                        'is-invalid': this.props.validation.errors.addressZip,
                      })}
                      name="addressZip"
                      onBlur={this.onFieldBlur.bind(null, 'addressZip')}
                      onChange={this.onFieldChange.bind(null, 'addressZip')}
                    />
                    <div className="invalid-feedback">
                      {this.props.validation.errors.addressZip}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <button className="toggle-button-credit-card" onClick={this.toggleEditing}>
              UPDATE PAYMENT INFO
            </button>
          )}
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    card: state.payment.card,
    loadedSource: state.payment.loadedSource,
    source: state.payment.source,
    states: state.enums.usStates,
    validation: state.payment.validation.card,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    updateField: (field, value) => dispatch(updateCardField(field, value)),
    updateToken: (token) => dispatch(updateToken(token)),
    validate: (field) => dispatch(validateCardField(field)),
  };
}

export default injectStripe(connect(mapStateToProps, mapDispatchToProps)(CreditCard));
