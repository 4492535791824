class EmergencyContactSerializer {
  static serialize({id, role, name, phoneNumber, _destroy}) {
    return {
      id,
      title: role,
      name,
      phone_no: phoneNumber ?? '',
      _destroy,
    }
  }

  static deserialize({id, title, name, phone_no}) {
    return {
      id,
      role: title,
      name,
      phoneNumber: phone_no ?? ''
    }
  }
}

export default EmergencyContactSerializer;