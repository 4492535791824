import { ofType } from "redux-observable";

import { mergeMap } from "rxjs/operators";

import { AggregateInfoService } from "services";

import { GET_AGGREGATE_INFO, GET_AGGREGATE_INFO_SUCCESS, GET_AGGREGATE_INFO_FAIL } from "./index";

export function getAggregateInfoEpic(action$, store) {
  return action$.pipe(
    ofType(GET_AGGREGATE_INFO),
    mergeMap(action => {
      return new AggregateInfoService()
        .get()
        .then(result => ({ type: GET_AGGREGATE_INFO_SUCCESS, payload: result }))
        .catch(error => {
          return {
            type: GET_AGGREGATE_INFO_FAIL
          };
        });
    })
  );
}
