import React, { Component } from 'react';
import PhoneInput from 'react-phone-input-2';
import validator from 'validator'

class ContactList extends Component {
  state = {
    errors: {},
  };

  onRemove = (contactIndex) => () => {
    this.props.removeContact(contactIndex)
  }

  onPhoneNumberChange(idx, newNumber) {
    const phoneNo = newNumber.length === 1 && newNumber.at(0) !== '1' ? `1${newNumber}` : newNumber;

    if (!validator.isMobilePhone(phoneNo)) {
      this.setState({
        errors: {
          phone: "Phone number is not valid"
        }
      })
    } else {
      // phone number is valid
      this.setState({
        errors: {}
      })

      // send to parent component
      this.props.changeContact(idx, { phoneNumber: phoneNo })
    }
  }

  render() {
    return (
      <div className="contactList">
        <div className="section-header col">Contact List <button onClick={this.props.clearContacts} className="btn btn-link">CLEAR FIELDS</button></div>
        <p>Please enter the role (ie: tournament director, coach, athletic director), name, and phone numbers for all personnel at the venue that the healthcare professional may need to contact in the event of an emergency.</p>
        {this.props.contacts.map((contact, idx) => {
          return !contact._destroy && (
            <div key={idx} className="contact-row form-row">
              <div className="form-group col-sm-4">
                <label htmlFor="contact_role">Role</label>
                <input type="text" className="form-control" onChange={(ev) => this.props.changeContact(idx, { role: ev.currentTarget.value })} value={contact.role} />
              </div>
              <div className="form-group col-sm-4">
                <label htmlFor="contact_name">Name</label>
                <input type="text" className="form-control" onChange={(ev) => this.props.changeContact(idx, { name: ev.currentTarget.value })} value={contact.name} />
              </div>
              <div className="merge-phone form-group col-sm-3">
                <label htmlFor="contact_role">Phone Number</label>
                <PhoneInput
                  name="phone"
                  onlyCountries={["us"]}
                  preferredCountries={["us"]}
                  enableAreaCodes={false}
                  autoFormat={true}
                  inputStyle={{ width: "100%" }}
                  placeholder="Enter phone number"
                  country='us'
                  onChange={(newNumber) => this.onPhoneNumberChange(idx, newNumber)}
                  value={contact.phoneNumber}
                />
                <div className="invalid-feedback-displayed">
                  {this.state.errors.phone}
                </div>
              </div>
              <div className="merge-close col-md-1 d-flex flex-column align-items-center align-self-center">
                <i className="material-icons remove-contact-btn" onClick={this.onRemove(idx)}>close</i>
              </div>
            </div>
          )
        })}
        <button disabled={Object.keys(this.state.errors).length > 0} className="btn btn-lg btn-primary add-emergency-contact-btn" onClick={this.props.addContact}>Add Emergency Contact</button>
      </div>
    );
  }
}

export default ContactList;