// @flow
import { CLEAR_FORM, VALIDATE_FORM, INIT_FORM, RELOAD_FORM_SUCCESS, DUPLICATE_EVENT, PREPOPULATE_FIELDS } from '../actions';
import type { Action } from 'redux/ducks/helpers';
import { pick, includes } from 'lodash';
import * as registrationActions from '../../Registration';

// Actions
// ========================================================
export const FIELD_CHANGE = 'go4ellis/CreateEvent/Details/FIELD_CHANGE';
export const SAVE_DETAILS_AND_CONTINUE = 'go4ellis/CreateEvent/Details/SAVE_AND_CONTINUE';
export const SAVE_DETAILS_AND_CONTINUE_SUCCESS = 'go4ellis/CreateEvent/Details/SAVE_AND_CONTINUE_SUCCESS';
export const SAVE_DETAILS_AND_CONTINUE_ERROR = 'go4ellis/CreateEvent/Details/SAVE_AND_CONTINUE_ERROR';

export const DELETE_DOCUMENT = 'go4ellis/CreateEvent/Details/DELETE_DOCUMENT';
export const DELETE_DOCUMENT_SUCCESS = 'go4ellis/CreateEvent/Details/DELETE_DOCUMENT_SUCCESS';
export const DELETE_DOCUMENT_ERROR = 'go4ellis/CreateEvent/Details/DELETE_DOCUMENT_ERROR';

// Types
// ========================================================
export interface EventDetailsFormType {
  documents?: ?Array<File>;
  eventLogo: ?File;
  eventMasterType: ?string;
  eventOperatorId?: ?number;
  eventParticipantNumbersId: string;
  eventTypeId: string;
  existingDocuments?: ?Array<any>;
  genderId: string;
  id: ?number;
  imagePreview: ?string;
  invalid: boolean;
  instructions: ?string;
  isDraft?: ?boolean;
  payRate: number;
  maxPayRate?: ?number;
  minTotalPay?: ?number;
  maxTotalPay?: ?number;
  paymentToken?: ?string;
  rateTypeId: string;
  settingTypeId: string;
  sportId: string;
  title: string,
  wasValidated: boolean;
  eventProfessionId: string;
  eventSettingId: string;
  settingDetailId: string;
  jobDescriptionIds: Array<String>;
  settingDetailOther: string,
  jobDescriptionOther: string,
  sportOther: string,
  eventCode: string,
  contractInfo: { [key: string]: any },
}

class EventDetailsFormState implements EventDetailsFormType {
  documents = [];
  eventLogo = null;
  eventMasterType = null;
  eventParticipantNumbersId = '';
  eventProfessionId = '';
  eventSettingId = '';
  settingDetailId = '';
  jobDescriptionIds = [];
  eventTypeId = '';
  existingDocuments = [];
  genderId = '';
  id = null;
  imagePreview = null;
  instructions = '';
  invalid = true;
  payRate = '';
  maxPayRate = '';
  minTotalPay = '';
  maxTotalPay = '';
  rateTypeId = "0";
  sportId = '';
  title = "";
  jobDescriptionOther: '';
  settingDetailOther: '';
  sportOther: '';
  wasValidated = false;
  eventCode = '';
  contractInfo = {};
}


// Reducer Functions
// ========================================================
function fieldChangeReducer(state, payload) {
  if (payload.name.includes("contractInfo")) {
    let newName = payload.name.split(".")[1];
    return {
      ...state,
      contractInfo: {
        ...state.contractInfo,
        [newName]: payload.newValue
      }
    }
  }
  return {
    ...state,
    [payload.name]: payload.newValue
  };
}

function validateDetailsReducer(state, pageNum): EventDetailsFormType {
  return {
    ...state,
    wasValidated: pageNum === 1 || includes(pageNum, 1),
  };
}

function initDetailsReducer(state, event): EventDetailsFormType {
  const newState = new EventDetailsFormState();
  const attrsToPick = [
    'id', 'title', 'instructions', 'sportId', 'ageBracketIds', 'genderId', 'eventTypeId', 'settingTypeId',
    'eventParticipantNumbersId', 'eventProfessionId', 'eventSettingId', 'settingDetailId', 'jobDescriptionIds',
    'payRate', 'isDraft', 'eventOperatorId', 'settingDetailOther', 'jobDescriptionOther', 'sportOther', 'eventCode',
    'minTotalPay', 'maxTotalPay', 'maxPayRate', 'contractInfo'
  ];
  Object.assign(newState, pick(event, attrsToPick), {
    imagePreview: event.eventLogo,
    existingDocuments: event.existingDocuments,
    eventParticipantNumbersId: event.participantNumbersId,
    eventProfessionId: event.eventProfessionId,
    eventSettingId: event.eventSettingId,
    settingDetailId: event.settingDetailId,
    jobDescriptionId: event.jobDescriptionsId,
    rateTypeId: event.rateTypeId.toString(),
    contractInfo: event.contractInfo,
  });
  return newState;
}

function reloadFormReducer(state, form) {
  const newState = state;

  if (form.details) {
    const attrsToPick = [
      'id', 'title', 'instructions', 'sportId', 'ageBracketIds', 'genderId', 'eventTypeId', 'settingTypeId',
      'eventParticipantNumbersId', 'eventProfessionId', 'eventSettingId', 'settingDetailId', 'jobDescriptionIds',
      'payRate', 'isDraft', 'settingDetailOther', 'jobDescriptionOther', 'sportOther', 'eventCode',
      'minTotalPay', 'maxTotalPay', 'maxPayRate', 'contractInfo'
    ];
    Object.assign(newState, pick(form.details, attrsToPick), {
      imagePreview: form.details.eventLogo,
      existingDocuments: form.details.existingDocuments,
      eventParticipantNumbersId: form.details.participantNumbersId,
      eventProfessionId: form.details.eventProfessionId,
      eventSettingId: form.details.eventSettingId,
      settingDetailId: form.details.settingDetailId,
      jobDescriptionIds: form.details.jobDescriptionIds,
      rateTypeId: form.details.rateTypeId.toString(),
    });
  }

  return newState;
}

function deleteDocumentSuccessReducer(state, { documentId }) {
  if (state.existingDocuments) {
    return {
      ...state,
      existingDocuments: state.existingDocuments.filter(d => d.id !== documentId),
    }
  }

  return state;
}

function prepopulateFieldsReducer(state, { defaultEventProfessionId, defaultEventSettingId }) {
  const newState = { ...state };

  if (Number.isInteger(defaultEventProfessionId)) {
    newState['eventProfessionId'] = defaultEventProfessionId
  }
  if (Number.isInteger(defaultEventSettingId)) {
    newState['eventSettingId'] = defaultEventSettingId
  }

  return newState;
}

function duplicateEvent(state, payload) {
  const { event } = payload;
  const newState = new EventDetailsFormState();
  const attrsToPick = [
    'title', 'instructions', 'sportId', 'genderId', 'eventTypeId', 'ageBracketIds', 'settingTypeId',
    'eventParticipantNumbersId', 'eventProfessionId', 'eventSettingId', 'settingDetailId', 'jobDescriptionIds',
    'payRate', 'isDraft', 'eventOperatorId', 'settingDetailOther', 'jobDescriptionOther', 'sportOther', 'eventCode',
    'minTotalPay', 'maxTotalPay', 'maxPayRate', 'contractInfo'
  ];

  Object.assign(newState, pick(event, attrsToPick), {
    imagePreview: event.eventLogo,
    existingDocuments: event.existingDocuments,
    eventParticipantNumbersId: event.participantNumbersId,
    eventProfessionId: event.eventProfessionId,
    eventSettingId: event.eventSettingId,
    settingDetailId: event.settingDetailId,
    jobDescriptionIds: event.jobDescriptionIds,
    rateTypeId: event.rateTypeId.toString(),
  });

  return newState;
}

function registrationPostEventPrefillReducer(state, payload) {
  return {
    ...state,
    eventMasterType: payload.postEventMasterType,
    title: payload.eventName,
    sportId: payload.sportId,
    genderId: payload.genderId,
    eventTypeId: payload.eventTypeId,
    settingTypeId: payload.settingTypeId,
    ageBracketIds: payload.ageBracketIds,
    eventParticipantNumbersId: payload.eventParticipantNumbersId,
    eventProfessionId: payload.eventProfessionId,
    eventSettingId: payload.eventSettingId,
    settingDetailId: payload.settingDetailId,
    jobDescriptionIds: payload.jobDescriptionIds,
    settingDetailOther: payload.settingDetailOther,
    jobDescriptionOther: payload.jobDescriptionOther,
    sportOther: payload.sportOther,
  };
}

// Action Creators
// ========================================================
export function fieldChange(name: string, newValue: any): Action {
  return {
    type: FIELD_CHANGE,
    payload: {
      name,
      newValue
    }
  };
}

export function saveDetailsAndContinue(history: any): Action {
  return {
    type: SAVE_DETAILS_AND_CONTINUE,
    payload: {
      history
    }
  };
}

export function deleteAttachedDocument(documentId: string): Action {
  return {
    type: DELETE_DOCUMENT,
    payload: {
      documentId,
    }
  };
}


// Reducer
// ========================================================

function mainReducer(state: EventDetailsFormType = new EventDetailsFormState(), action: Action): EventDetailsFormType {
  switch (action.type) {
    case FIELD_CHANGE:
      return fieldChangeReducer(state, action.payload);
    case VALIDATE_FORM:
      return validateDetailsReducer(state, action.payload.pageNum);
    case CLEAR_FORM:
      return new EventDetailsFormState();
    case SAVE_DETAILS_AND_CONTINUE_SUCCESS:
      return { ...state, id: action.payload.id, existingDocuments: action.payload.existingDocuments, documents: [] }
    case INIT_FORM:
      return initDetailsReducer(state, action.payload);
    case DELETE_DOCUMENT_SUCCESS:
      return deleteDocumentSuccessReducer(state, action.payload);
    case RELOAD_FORM_SUCCESS:
      return reloadFormReducer(state, action.payload);
    case PREPOPULATE_FIELDS:
      return prepopulateFieldsReducer(state, action.payload);
    case DUPLICATE_EVENT:
      return duplicateEvent(state, action.payload);
    case registrationActions.REGISTRATION_POST_EVENT_PREFILL:
      return registrationPostEventPrefillReducer(state, action.payload.user);
    default: return state;
  }
}

export default mainReducer;