import React from 'react';
import HomeList from '../HomeList';

const list = [
  'Anaphylaxis Management',
  'Athletic Site Inspection',
  'Bracing',
  'Casting',
  'Clinical Diagnosis',
  'Clinical Education',
  'Clinical Evaluation',
  'Compliance',
  'Concussion Diagnosis',
  'CPR / AED Trained',
  'Diagnostic Testing',
  'Differential Diagnosis',
  'Documentation',
  'Emergency Action Plan',
  'Emergency Cardiac Care',
  'First Responder',
  'First Aid',
  'Gait Training',
  'Exertional Heat Stroke Treatment',
  'Injury Prevention',
  'Injury Treatment',
  'Joint Mobilization',
  'Orthopedic Assessment',
  'Patient Advocate',
  'Patient Education',
  'Patient Intervention',
  'Physician Referral',
  'Physician Liaison',
  'Plan of Care',
  'Post Surgical Treatment',
  'Pre-Participation Screening',
  'Preventative Services',
  'Protective Equipment Evaluation',
  'Rehabilitation',
  'Return to Play Decisions',
  'Risk Management',
  'Screening',
  'Splinting',
  'Therapeutic Intervention',
  'Treatment Planning',
  'Triage',
  'Weather Monitoring',
  'Wound Care'
];

const ATSkills = () => <HomeList list={list}/>;

export default ATSkills;