import React from 'react';
import { startsWith } from 'lodash';

const domain = process.env.REACT_APP_DOMAIN;

const UploadedEAP = (props) => {
  const urlFor = (previewUrl: string) => {
    return startsWith(previewUrl, '/') ? domain + previewUrl : previewUrl;
  };

  const onEditUploadedEAP = () => {
    props.history.push(`${props.baseUrl}/eap/eap-type`);
  };

  return (
    <div className="uploadedEAP">
      <div className="section-header gotham-bold-font col">
        Uploaded custom emergency action plan{' '}
        <span className="edit-uploaded-eap gotham-regular-font" onClick={onEditUploadedEAP}>
          (edit)
        </span>
      </div>
      <section>
        <div className="col text-center d-flex">
          {props.filePreview && (
            <object
              className="pdf-preview"
              data={urlFor(props.filePreview)}
              alt="A preview of the new plan"
            >
              <p>
                Looks like you can't view this file.{' '}
                <a target="_blank" rel="noopener noreferrer" href={urlFor(props.filePreview)}>
                  Click here to download.
                </a>
              </p>
            </object>
          )}
        </div>
      </section>
    </div>
  );
};

export default UploadedEAP;
