import { ofType } from 'redux-observable';
import { merge } from 'rxjs/observable/merge';
import { mergeMap, bufferTime, mergeAll, switchMap } from 'rxjs/operators';
import { of } from 'rxjs/observable/of';
import { basicEpic, mappingEpic } from 'redux/ducks/helpers';
import * as actions from './actions';
import { EmergencyActionPlanService, GooglePlacesService } from 'services';
import { INITIALIZE_POST } from 'redux/ducks/Post/actions';
import { get } from 'lodash';
import { HIDE_SPINNER, SHOW_SPINNER } from 'redux/ducks/LoadingSpinner';

// -------------- INITIALIZE --------------
export const triggerEapFormInitEpic = (action$, store) => {
  return action$.pipe(
    ofType(INITIALIZE_POST),
    switchMap((action) => {
      const { id } = action.payload;

      return of({
        type: actions.EAP_FORM_INIT,
        payload: { eventId: id },
      });
    })
  );
};

// STEP 2
export const EAPFormInitEpic = function EAPFormInitEpic(action$, store) {
  return action$.pipe(
    ofType(actions.EAP_FORM_INIT),
    mergeMap((action) => {
      const state = store.getState();
      const service = new EmergencyActionPlanService(state.session, get(action, 'payload.eventId'));

      return service
        .getAll()
        .then((emergencyActionPlans) => {
          return {
            type: actions.EAP_FORM_INIT_SUCCESS,
            payload: {
              emergencyActionPlans,
              locations: state.post.locations,
              eventLocationId: get(action, 'payload.eventLocationId'),
            },
          };
        })
        .catch((error) => ({ type: actions.EAP_FORM_INIT_ERROR, payload: error }));
    })
  );
};

// STEP 3
export const afterInitFormEpic = function afterInitFormEpic(action$, store) {
  return action$.pipe(
    ofType(actions.EAP_FORM_INIT_SUCCESS),
    mergeMap((action) => {
      const { forms, currentForm } = store.getState().post.eap;
      const fetchActions = [];
      const form = forms[currentForm];

      if (!form.hospital.name) {
        fetchActions.push(
          actions.fetchServices({
            index: currentForm,
            coordinates: {
              lat: form.eventLocation.latitude,
              lng: form.eventLocation.longitude,
            },
          })
        );
      }

      return fetchActions;
    })
  );
};

// STEP 4
export const fetchEmergencyServicesEpic = function fetchEmergencyServicesEpic(action$, store) {
  return action$.pipe(
    ofType(actions.FETCH_EMERGENCY_SERVICES),
    bufferTime(500),
    mergeMap((buffer) => {
      return buffer.map((action, index) => of(action).delay(index * 2000));
    }),
    mergeAll(),
    mergeMap((action) => {
      if (!document.querySelector('#attribution')) {
        return [];
      }

      const service = new GooglePlacesService(document.querySelector('#attribution'));
      const { index, coordinates } = action.payload;

      return merge(
        service
          .nearbySearch('hospital', coordinates, 'Emergency Room Hospital', 'distance')
          .then((results) => service.getDetails(results[0].placeId))
          .then((hospital) =>
            actions.fetchHospitalSuccess({
              index,
              hospital,
            })
          )
          .catch((error) => ({ type: actions.FETCH_NEAREST_HOSPITAL_ERROR, payload: error })),
        of(null)
          .delay(500)
          .mergeMap(() =>
            service
              .nearbySearch('fire_station', coordinates, 'fire department', 'distance')
              .then((results) => service.getDetails(results[0].placeId))
              .then((fireDepartment) =>
                actions.fetchFireDeptSuccess({
                  index,
                  fireDepartment,
                })
              )
              .catch((error) => ({
                type: actions.FETCH_NEAREST_FIRE_DEPARTMENT_ERROR,
                payload: error,
              }))
          ),
        of(null)
          .delay(1000)
          .mergeMap(() =>
            service
              .nearbySearch('police', coordinates)
              .then((results) => service.getDetails(results[0].placeId))
              .then((policeStation) =>
                actions.fetchPoliceSuccess({
                  index,
                  policeStation,
                })
              )
              .catch((error) => ({
                type: actions.FETCH_NEAREST_POLICE_STATION_ERROR,
                payload: error,
              }))
          )
      );
    })
  );
};

// -------------- CREATE OR UPDATE EAP --------------
export const createEapEpic = basicEpic(actions.CREATE_EAP, ({ callback }, state) => {
  const service = new EmergencyActionPlanService(state.session, state.post.details.id);
  const form = state.post.eap.forms[state.post.eap.currentForm];

  return service.create(form).then((eap) => {
    if (!!callback) callback();
    return eap;
  });
});

export const updateEapEpic = basicEpic(actions.UPDATE_EAP, ({ callback }, state) => {
  const service = new EmergencyActionPlanService(state.session, state.post.details.id);
  const form = state.post.eap.forms[state.post.eap.currentForm];

  return service.create(form).then((eap) => {
    if (!!callback) callback();
    return eap;
  });
});

export const showSpinner = mappingEpic([actions.EAP_FORM_INIT, actions.UPDATE_EAP], SHOW_SPINNER);

export const hideSpinner = mappingEpic(
  [
    actions.EAP_FORM_INIT_ERROR,
    actions.EAP_FORM_INIT_SUCCESS,
    actions.UPDATE_EAP_SUCCESS,
    actions.UPDATE_EAP_ERROR,
  ],
  HIDE_SPINNER
);
