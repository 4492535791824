import { basicEpic, loadFromStorage, writeToStorage } from 'redux/ducks/helpers';
import { GooglePlacesService, ShiftsService } from 'services';
import * as shiftActions from './actions';
import { mergeMap } from 'rxjs/operators';
import { ofType } from 'redux-observable';
import { FORM_STORAGE_KEY } from 'redux/ducks/CreateEvent';
import { isContractEo } from '../../../../helpers/contracts';

export const saveShiftsAndContinueEpic = basicEpic(
  shiftActions.SAVE_SHIFTS_AND_CONTINUE,
  ({ history }, state) => {
    const eventId = state.createEvent.details.id;
    const shifts = state.createEvent.shifts.shifts;
    return new ShiftsService(state.session, eventId).create(shifts).then((shifts) => {
      let formData = loadFromStorage(FORM_STORAGE_KEY);
      writeToStorage(FORM_STORAGE_KEY, { ...formData, shifts: shifts });

      history.push(isContractEo(state.session.currentUser.email) ? '4' : '3');
      return shifts;
    });
  }
);

export function googlePlacesTypeaheadSelectedEpic(action$, store) {
  return action$.pipe(
    ofType(shiftActions.GOOGLE_PLACE_SELECTED),
    mergeMap((action) => {
      const { index, from } = action.payload;
      return new GooglePlacesService(document.querySelector('#attribution'))
        .getDetails(action.payload.placeId)
        .then((result) => {
          if (result.address.zipCode) {
            return {
              type: shiftActions.PLACE_DETAILS_SUCCESS,
              payload: { result, index, from },
            };
          } else if (result.address.zipCode === undefined) {
            return {
              type: shiftActions.NO_ZIPCODE_SELECTED,
              payload: { index, from },
            };
          } else {
            return {
              type: shiftActions.INVALID_MSA_SELECTED,
              payload: { index, from },
            };
          }
        })
        .catch((error) => ({
          type: shiftActions.PLACE_DETAILS_ERROR,
          payload: error,
        }));
    })
  );
}
