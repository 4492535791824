import moment from 'moment-timezone';
import { MILITARY_FORMAT } from 'helpers/datetime';

// Validators
// -------------------------------
const startTimeIsAfterEndTime = (shift) =>
  moment(shift.startTime, MILITARY_FORMAT).isAfter(moment(shift.endTime));

const dateTimeIsInvalid = (time) => !moment(time, MILITARY_FORMAT).isValid();

const shiftTimeHasPassed = (shift) => {
  return moment(shift.startTime, MILITARY_FORMAT).isBefore(moment());
};

const shiftCapacityIsInvalid = (shift) => {
  return !shift.capacity || shift.capacity < 1;
};

const payRateIsInvalid = (shift) => {
  return !shift.payRate || shift.payRate < 1 || shift.payRate > 100000;
};

const rateTypeIsInvalid = (shift) => {
  return Number.isNaN(Number(shift.rateTypeId));
};

export const addErrorsToShift = ({ shift, validateRate = false }) => {
  const startTime = shift.startDate + ' ' + shift.start;
  const endTime = shift.endDate + ' ' + shift.end;

  let messages = {};
  if (shiftCapacityIsInvalid(shift)) {
    messages.capacity = 'You need at least one AT per shift';
  }
  if (startTimeIsAfterEndTime(shift)) {
    if (shift.startDate === shift.endDate) {
      messages.start = 'Start Time must be before End Time';
      messages.end = 'Start Time must be before End Time';
    } else {
      messages.startDate = 'Start Date must be before End Date';
      messages.endDate = 'Start Date must be before End Date';
    }
  }
  if (dateTimeIsInvalid(startTime)) {
    messages.startDate = 'Start Date is invalid';
  }
  if (dateTimeIsInvalid(endTime)) {
    messages.endDate = 'End Date is invalid';
  }

  if (!shift.start) {
    messages.start = 'Start Time is invalid';
  }

  if (!shift.end) {
    messages.end = 'End Time is invalid';
  }

  if (shiftTimeHasPassed(shift)) {
    messages.startDate = 'New shifts must be in the future';
  }
  if (validateRate && payRateIsInvalid(shift)) {
    messages.payRate = 'Pay rate is required to be between $0-$100,000';
  }
  if (validateRate && rateTypeIsInvalid(shift)) {
    messages.rateType = 'Rate Type is invalid';
  }

  return {
    ...shift,
    errorMessages: messages,
  };
};

export const addErrorsToJob = (job, originalJob = null) => {
  let messages = {};
  if (startTimeIsAfterEndTime(job)) {
    messages.start = 'Start Time must be before End Time';
    messages.end = 'Start Time must be before End Time';
  }
  if (!job.start) {
    messages.start = 'Start Time is invalid';
  }
  if (!job.end) {
    messages.end = 'End Time is invalid';
  }
  if (shiftTimeHasPassed(job) && !job.currentState && !job.id) {
    messages.start = 'New job must be in the future';
  }

  if (
    originalJob &&
    ['confirmed', 'checked_in'].includes(originalJob.currentState) &&
    Number(originalJob.payRate) > Number(job.payRate)
  ) {
    messages.payRate = 'The pay rate cannot be reduced after the AT is confirmed.';
  }

  return {
    ...job,
    errorMessages: messages,
  };
};
