import React, { Component } from 'react';

import { Collapse } from 'reactstrap';

export class FAQListItem extends Component {
  state = {
    expanded: false,
  };

  icon = () => {
    return this.state.expanded ? 'arrow_downward' : 'arrow_forward';
  }

  toggleExpanded = () => {
    this.setState({ expanded: !this.state.expanded });
  }

  render() {
    return (
      <div className="fAQListItem">
        <div className='question-line' onClick={this.toggleExpanded}>
          <p className='question'>
            {this.props.question}
          </p>
          <i className='material-icons'>{this.icon()}</i>
        </div>
        <Collapse isOpen={this.state.expanded}>
          <p className='answer'>{this.props.answer}</p>
        </Collapse>
      </div>
    );
  }
}

export default FAQListItem;