import React from "react";
import {
  Redirect,
  Route,
  Switch,
  useParams,
  useRouteMatch,
} from "react-router-dom";
import JobDetails from "../JobDetails";
import ApplyForm from "../ApplyForm";
import BackToListButton from "./BackToListButton";
import RedHomeButton from "../../../components/RedHomeButton";
import data from "../careersData";

const SingleJob = () => {
  const { id } = useParams();
  const { path, url, isExact } = useRouteMatch();
  const selected = data.find((j) => j.id === id);
  if (!selected) {
    return <Redirect to={"/careers"} />;
  }
  return (
    <div className="singleJob">
      <BackToListButton />
      <JobDetails item={selected} noDescription={!isExact} className="single" />
      <Switch>
        <Route exact path={path}>
          <>
            <ul className="bullits">
              {selected.bullits.map((b, i) => {
                if (b.head) {
                  return (
                    <p key={i} className="heading">
                      <b>{b.text}</b>
                    </p>
                  );
                }
                return <li key={i}>{b}</li>;
              })}
            </ul>
            <div className="buttonWrapper">
              <RedHomeButton
                asLink
                text={"APPLY"}
                to={`${url}/apply`}
                className="careers"
              />
            </div>
          </>
        </Route>
        <Route
          path={`${path}/apply`}
          render={() => <ApplyForm job={selected} />}
        />
      </Switch>
    </div>
  );
};

export default SingleJob;
