import React from 'react';
import { connect } from 'react-redux';
import { isEmpty } from 'lodash';
import { Link } from 'react-router-dom';
import EventList from 'components/EventList';
import './Dashboard.scss';
import { clearV2Form } from 'redux/ducks/Post/actions';
import DashboardV2 from './V2/Dashboard';
import { isContractEo } from 'helpers/contracts';

const DashboardBody = ({ loaded, emptyEvents, user, clearV2Form }) => {
  return (
    <React.Fragment>
      <h2>
        <img src={require('./bell-icon.svg')} alt="bell-icon" className="bell-icon" /> Shift Alerts
      </h2>
      {loaded && emptyEvents ? (
        <div className="empty-events">
          <Link className="display-mobile btn btn-danger bold-button" to="create-event/1">
            Create New Event
          </Link>
          <h3>
            You currently have no events on the job board. Click the <i>Create New Event</i> button
            above to get started posting your events!
          </h3>
          <p>Once you create an event, you can manage your shift requests and alerts here.</p>
        </div>
      ) : (
        <div>
          <p>Manage your shift requests and event alerts here.</p>
          <EventList />
        </div>
      )}
    </React.Fragment>
  );
};

const NormalDashboard = ({ user, clearV2Form }) => {
  return (
    <div className="header">
      <h1>dashboard</h1>
      <Link className="btn btn-danger bold-button" to="create-event/1">
        Create New Event
      </Link>
    </div>
  );
};

export const Dashboard = ({ emptyEvents, loaded, currentUser, reviews, clearV2Form }) => {
  if (!isContractEo(currentUser.email)) {
    return <DashboardV2 />;
  }

  return (
    <div className="dashboard">
      <NormalDashboard user={currentUser} clearV2Form={clearV2Form} />
      <DashboardBody
        emptyEvents={emptyEvents}
        loaded={loaded}
        user={currentUser}
        clearV2Form={clearV2Form}
      />
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    loaded: state.event.loaded.upcoming,
    emptyEvents: isEmpty(state.event.events.upcoming),
    currentUser: state.session.currentUser,
    reviews: state.dashboard.reviews.reviews,
  };
}

const mapDispatchToProps = (dispatch) => ({
  clearV2Form: () => dispatch(clearV2Form()),
});

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);
