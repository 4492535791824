import React, { Component } from 'react';
import { connect } from 'react-redux';
import EventList, { scenes } from 'components/V2/EventList/EventList';
import {
  changeShiftsWithNoApplicantsPage,
  changeShiftsWithNoApplicantsSearch,
  changeShiftsWithNoApplicantsSort,
  getShiftsWithNoApplicants,
  resetPagination,
} from 'redux/ducks/Dashboard';
import { NO_APPS_SHIFTS_COLUMNS } from 'helpers/dashboard';

class ShiftsWithNoApps extends Component {
  onChangePage = (data) => {
    const page = data.selected + 1;
    this.props.onChangePage(page);
  };

  reloadData = () => {
    this.props.resetPagination();
    this.props.getShiftsWithNoApplicants();
  }

  render() {
    const { shifts, pagination, search, text, sort } = this.props;
    const key = Object.keys(sort)[0];
    const value = sort[key];
    return (
      <div className="shiftsWithNoApps">
        <EventList
          title={text}
          onChangeSearch={this.props.changeSearch}
          searchValue={search}
          data={shifts}
          reloadData={this.reloadData}
          pagination={pagination}
          onChangePage={this.onChangePage}
          column={scenes.NO_APPS_SHIFTS}
          columns={NO_APPS_SHIFTS_COLUMNS}
          emptyMessage="No shifts to display."
          sort={{ key, value }}
          onChangeSort={this.props.onChangeSort}
        />
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  shifts: state.dashboard.shifts.shifts,
  pagination: state.dashboard.shifts.pagination,
  search: state.dashboard.shifts.search,
  sort: state.dashboard.shifts.sort,
});

const mapDispatchToProps = (dispatch) => ({
  onChangePage: (page) => dispatch(changeShiftsWithNoApplicantsPage(page)),
  onChangeSort: (key, value) =>
    dispatch(changeShiftsWithNoApplicantsSort({ key, value })),
  changeSearch: (payload) =>
    dispatch(changeShiftsWithNoApplicantsSearch(payload)),
  getShiftsWithNoApplicants: () => dispatch(getShiftsWithNoApplicants()),
  resetPagination: () => dispatch(resetPagination('shifts')),
});

export default connect(mapStateToProps, mapDispatchToProps)(ShiftsWithNoApps);
