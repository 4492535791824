import React, { useState } from "react";
import { withRouter } from "react-router-dom";
import { useDispatch } from "react-redux";
import Input from "./Input";
import InputWrapper from "./InputWrapper";
import UploadResume from "./UploadResume";
import ApplyTextarea from "./ApplyTextarea";
import FormErrorMessage from "../../../components/FormErrorMessage";
import isEmpty from "lodash/isEmpty";
import { validateEmail } from "../../../helpers/validate";
import { ApplyService } from "../../../services";
import { success, error } from "../../../redux/ducks/Flash";

const fields = [
  {
    type: "text",
    name: "firstName",
    label: "First Name",
  },
  {
    type: "text",
    name: "lastName",
    label: "Last Name",
  },
  {
    type: "tel",
    name: "phone",
    label: "Phone #",
  },
  {
    type: "email",
    name: "email",
    label: "Email",
  },
  {
    type: "text",
    name: "address",
    label: "Home Address",
  },
  {
    type: "text",
    name: "city",
    label: "City",
  },
  {
    type: "text",
    name: "state",
    label: "State",
  },
  {
    type: "text",
    name: "zip",
    label: "Zip",
  },
  {
    type: "text",
    name: "lead",
    label: "How did you hear about this position?",
  },
];
const initialState = {
  firstName: "",
  lastName: "",
  phone: "",
  email: "",
  address: "",
  city: "",
  state: "",
  zip: "",
  lead: "",
  resume: undefined,
  coverLetter: "",
};
const ApplyForm = ({ job, history }) => {
  const dispatch = useDispatch();
  const [data, setFormData] = useState(initialState);
  const [showErrorMessage, setShowErrorMessage] = useState(false);
  const handleSubmit = (e) => {
    e.preventDefault();
    const errors = Object.keys(initialState).reduce((acc, key) => {
      if ((key === "email" && !validateEmail(data.email)) || !data[key]) {
        acc[`${key}Error`] = true;
      }
      return acc;
    }, {});
    if (isEmpty(errors)) {
      try {
        new ApplyService()
          .post({ ...data, jobId: job.id })
          .then(() => {
            setFormData(initialState);
            dispatch(
              success(
                <>
                  Thank you for submitting your application. We will review it
                  and contact you if you are good fit for the position. Thanks
                  again for taking the time to apply. We appreciate it.{" "}
                  <span className="text-nowrap">- The Go4 Team</span>
                </>
              )
            );
            history.push("/careers");
          })
          .catch(() => {
            dispatch(
              error(
                "An error occurred while processing your application. Please try again. If the issue persists, please contact us at contactus@go4.io."
              )
            );
          });
      } catch (err) {
        dispatch(
          error(
            "An error occurred while processing your application. Please try again. If the issue persists, please contact us at contactus@go4.io."
          )
        );
      }
    } else {
      setFormData({ ...data, ...errors });
      setShowErrorMessage(true);
    }
  };
  const handleUpload = (e) => {
    const resume = e.target.files[0];
    setFormData({
      ...data,
      resume,
      resumeError: undefined,
    });
  };
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...data,
      [name]: value,
      [`${name}Error`]: undefined,
    });
  };
  return (
    <>
      <form className="applyForm" onSubmit={handleSubmit}>
        {fields.map((f) => (
          <InputWrapper key={f.name} label={f.label}>
            <Input
              hasError={data[`${f.name}Error`]}
              name={f.name}
              type={f.type}
              value={data[f.name]}
              handleChange={handleChange}
            />
          </InputWrapper>
        ))}
        <InputWrapper label={"Upload your resume"}>
          <UploadResume
            hasError={data.resumeError}
            file={data.resume}
            handleUpload={handleUpload}
          />
        </InputWrapper>
        <InputWrapper label={"Cover letter"}>
          <ApplyTextarea
            name={"coverLetter"}
            value={data.coverLetter}
            handleChange={handleChange}
            hasError={data.coverLetterError}
          />
        </InputWrapper>
        <button className="applySubmitButton" type="submit">
          Submit
        </button>
        {showErrorMessage && <FormErrorMessage />}
      </form>
    </>
  );
};

export default withRouter(ApplyForm);
