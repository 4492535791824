import * as postActions from '../actions';
import { pick } from 'lodash';

// Actions
// ========================================================
export const FIELD_CHANGE = 'go4ellis/Post/Rate/FIELD_CHANGE';

// Reducer Functions
// ========================================================
const initRateReducer = (state, payload) => {
  return { ...DEFAULT_STATE, ...pick(payload, Object.keys(DEFAULT_STATE)) };
};
const fieldChangeReducer = (state, payload: { name: string, value: string }) => {
  return {
    ...state,
    [payload.name]: payload.value,
  };
};

const reloadFormReducer = (state, payload) => {
  return payload?.rate ? { ...payload.rate } : state;
};

// Action Creators
// ========================================================
export const changeRateField = (payload) => {
  return { type: FIELD_CHANGE, payload };
};

// Reducer
// ========================================================
const DEFAULT_STATE = {
  payRate: '',
  rateTypeId: '0',
  wasValidated: false,
};

export type RateType = typeof DEFAULT_STATE;

export default function reducer(state = DEFAULT_STATE, action = {}) {
  switch (action.type) {
    case FIELD_CHANGE:
      return fieldChangeReducer(state, action.payload);
    case postActions.V2_RELOAD_FORM_SUCCESS:
      return reloadFormReducer(state, action.payload);
    case postActions.V2_VALIDATE_FORM:
      return { ...state, wasValidated: true };
    case postActions.V2_CLEAR_FORM:
      return DEFAULT_STATE;
    case postActions.SAVE_AS_DRAFT_SUCCESS:
    case postActions.SAVE_PROFILE_SUCCESS:
      return action.payload.rate;
    case postActions.INITIALIZE_POST:
    case postActions.DUPLICATE_POST:
      return initRateReducer(state, action.payload);
    default:
      return state;
  }
}
