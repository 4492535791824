import React, { Component } from 'react';

import EventListItem from 'components/EventList/EventListItem';

const moment = require('moment');

const DatePanel = ({
  date,
  events,
  ListItemComponent,
  rateTypes,
}) => {
  const formatDate = (date) => {
    return moment(date).format('dddd - MMMM D, YYYY').toUpperCase();
  };

  return (
    <div className="datePanel">
      <div className="header">{formatDate(date)}</div>
      {events.map((item) => {
        return (
          <ListItemComponent item={item}
                             rateTypes={rateTypes}
                             key={item.id}/>
        );
      })}
    </div>
  );
}

export default DatePanel;