/* global google */
import { GoogleSerializer } from './serializers';
export default class GooglePlacesService {
  client = null;
  constructor(node = document.createElement('div')) {
    this.client = new google.maps.places.PlacesService(node);
  }

  autocomplete(input, coordinates = { lat: 40, lng: -76 }) {
    return new Promise((resolve, reject) => {
      const autocompleteClient = new google.maps.places.AutocompleteService();
      let location = new google.maps.LatLng(coordinates.lat, coordinates.lng);
      const callback = function (results, status) {
        const statuses = google.maps.places.PlacesServiceStatus;
        if (status === statuses.OK) {
          resolve(results.map(GoogleSerializer.eventLocationFromAutocompleteResult));
        } else {
          reject(results);
        }
      };
      autocompleteClient.getPlacePredictions({
        input,
        location,
        radius: 10000,
        componentRestrictions: {
          country: 'us'
        },
        types: ['establishment']
      },
        callback)
    });
  }

  nearbySearch(type, location = { lat: 40, lng: -76 }, name, rankByStr) {
    let rankBy = google.maps.places.RankBy.PROMINENCE;
    let radius = 5000;
    if (rankByStr === 'distance') {
      rankBy = google.maps.places.RankBy.DISTANCE;
      radius = null;
    }
    return new Promise((resolve, reject) => {
      let onlyTriedOnce = true;
      const callback = (results, status) => {
        const statuses = google.maps.places.PlacesServiceStatus
        if (status === statuses.OK) {
          resolve(results.map(GoogleSerializer.eventLocationFromGooglePlace));
        } else if (status === statuses.ZERO_RESULTS && onlyTriedOnce) {
          onlyTriedOnce = false;
          this.client.nearbySearch({ location, radius: 20000, types: [type], name }, callback)
        } else {
          reject({ status, results });
        }
      };
      this.client.nearbySearch({ location, radius, types: [type], name, rankBy }, callback)
    });
  }

  getDetails(placeId) {
    return new Promise((resolve, reject) => {
      this.client.getDetails({ placeId },
        function (result, status) {
          const statuses = google.maps.places.PlacesServiceStatus
          if (status === statuses.OK) {
            resolve(GoogleSerializer.eventLocationFromGooglePlace(result));
          } else {
            reject({ status, result });
          }
        });
    });
  }
}