import React from "react";
import NursesContactForm from "../NursesContactForm";
import image from "images/white-go-4.svg";

const NursesTopSection = () => {
  return (
    <section className="nursesTopSection d-flex py-5">
      <div className="left-top top-sub-section d-flex align-items-center justify-content-start">
        <div className="text-container">
          <aside className="go-4-teal mr-5">
            <img alt="go-4" src={image} />
          </aside>
          <h1>
            <span className="teal-text d-block">ON-DEMAND</span>
            <span className="white-text d-block">REGISTERED NURSES</span>
            <span className="teal-text d-block">FOR MEDICAL &</span>
            <span className="teal-text d-block">INDUSTRIAL SETTINGS</span>
          </h1>
        </div>
      </div>
      <div className="right-bottom top-sub-section">
        <NursesContactForm />
        <div className="red-bar" />
      </div>
    </section>
  );
};

export default NursesTopSection;
