import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { trackMixpanelPageView } from 'helpers/post';

export class MobileNavBar extends Component {
  trackLogin = () => {
    trackMixpanelPageView('IntroLogInClick')
  }
  render() {
    const { pathname, logo, logoWidth } = this.props;
    const logoProps = {
      src: logo,
      style: { width: logoWidth },
    };
    const slug = pathname ? pathname.split('/').pop() : '';
    return (
      <div>
        <div className="mobileNavBarV2Placeholder"></div>
        <div className="mobileNavBarV2">
          {this.props.authenticated && (
            <i className="material-icons" onClick={this.props.toggleCollapsed}>
              reorder
            </i>
          )}
          <Link to={this.props.logoUrl ? this.props.logoUrl : '/'}>
            <img {...logoProps} className="logo" alt="Go4" />
          </Link>
          {slug === 'intro' && (
            <Link
              to="/"
              className="login-btn gotham-medium-font"
              onClick={this.trackLogin}
            >
              <div>LOG IN</div>
            </Link>
          )}
        </div>
      </div>
    );
  }
}

export default MobileNavBar;
