// @flow
import { AddressSerializer } from 'services/serializers';
import type { SerializedAddress } from './AddressSerializer';
import type { EventLocation, Address } from 'redux/ducks/helpers';

export default class EventLocationSerializer {
  static deserialize(eventLocation: SerializedEventLocation): EventLocation {
    if (!eventLocation) {
      return {};
    }
    const {id, name, details, address, latitude, longitude, phone_number} = eventLocation;
    const deserializedAddress: Address = AddressSerializer.deserialize(address)
    return {
      id,
      name,
      details,
      address: deserializedAddress,
      formattedAddress: formatAddress(deserializedAddress),
      latitude: parseFloat(latitude),
      longitude: parseFloat(longitude),
      phoneNumber: phone_number
    };
  }

  static serialize({id, name, details, latitude, longitude, address, phoneNumber}: EventLocation): SerializedEventLocation {
    const result: SerializedEventLocation = {
      id,
      name,
      details,
      latitude,
      longitude,
      phone_number: phoneNumber
    };

    if (address) {
      result['address_attributes'] = AddressSerializer.serialize(address);
    }

    return result;
  }
}

export function formatAddress({streetAddress, city, state, zipCode}: Address): string {
  return (streetAddress ? `${streetAddress}, ` : '') + `${city} ${state}, ${zipCode}`
}

export type SerializedEventLocation = {
  id?: ?number,
  name: string,
  details: string,
  latitude: ?number,
  longitude: ?number,
  phone_number: ?string,
  address_attributes?: SerializedAddress,
  address?: SerializedAddress,
}
