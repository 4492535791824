import React, { Fragment } from "react";
import { Link, withRouter } from "react-router-dom";
import HeaderText from "../HeaderText";
import stateList from "../statesTitle.json";
import MobileStateCityList from "./MobileStateCityList";

const StateJobLinkList = ({ location }) => {
  return (
    <div className="stateJobLinkList">
      <HeaderText text={"Find Your City"} noSlash />
      <div className="states">
        {stateList.map(s => (
          <Fragment key={s.name}>
            <h3 className="stateName">{s.name}</h3>
            <ul>
              {s.cities.map(city => (
                <li key={city}>
                  <Link
                    to={`${location.pathname}/${s.abbreviation}/${city}`}
                  >{`${city}`}</Link>
                </li>
              ))}
            </ul>
          </Fragment>
        ))}
      </div>
      <MobileStateCityList />
    </div>
  );
};

export default withRouter(StateJobLinkList);
