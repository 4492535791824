import React from "react";

const LandingUnderTop = ({ highLight, text, bold, noBoldText, altText }) => {
  return (
    <section className="landingUnderTop">
      {altText || (
        <>
          <span className="highlight-text">{highLight}</span> {text}{" "}
          {!noBoldText && (
            <strong>
              {bold || "National Athletic Trainers’ Association."}
            </strong>
          )}
        </>
      )}
    </section>
  );
};

export default LandingUnderTop;
