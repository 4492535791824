import React, { Component } from 'react';
import { connect } from 'react-redux';
import { isEmpty } from 'lodash';
import { Link } from 'react-router-dom';

import {
  applyUpcomingFilters,
  resetUpcomingFilters,
  updateUpcomingFilters,
} from 'redux/ducks/Event';

import EventFiltersPanel from 'components/EventFiltersPanel';
import EventList from 'components/EventList';
import { clearV2Form } from 'redux/ducks/Post/actions';

class EventDetailsScene extends Component {
  state = { appliedFilters: false };
  applyFilters = (value) => (this.setState({ appliedFilters: value }))
  onClearAll = () => {
    this.props.reset({ past: true });
    this.applyFilters(false);
  }
  render() {
    const { per_page, total } = this.props.pagination;

    return (
      <div className="eventDetailsScene">
        <div className="events">
          <div className="header">
            <h1>shifts</h1>
            <div className="actions row">
              <EventFiltersPanel filters={this.props.filters}
                reset={this.props.reset}
                submit={this.props.submit}
                updateFilter={this.props.updateFilter}
                applyFilters={this.applyFilters}
              />
              <Link className='btn btn-danger' to='create-event/1'>
                Create New Event
              </Link>
            </div>
          </div>
          <div className="header mb-2 align-items-center">
            <h6 className="results-pagination mr-2 mb-0">1-{per_page} of {total} Results</h6>
            {this.state.appliedFilters && <a className="clear-filters-link" href="#" onClick={this.onClearAll}>Clear all filters</a>}
          </div>
          <h2>upcoming shifts</h2>
          {(this.props.loaded && this.props.emptyEvents) ?
            <div className='empty-events'>
              <Link className='display-mobile btn btn-danger bold-button' to='create-event/1'>
                Create New Event
              </Link>
              {!this.state.appliedFilters ?
                <>
                  <h3>You currently have no shifts on the job board. Click the <i>Create New Event</i> button above to get started posting your events!</h3>
                  <p>Once you create an event, you can manage your shift requests and alerts here.</p>
                </>
                :
                <h3>No results.</h3>
              }
            </div>
            :
            <div>
              <p>Click the event name to see full shift details</p>
              <EventList grouping='date' />
            </div>}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    filters: state.event.filters.upcoming,
    loaded: state.event.loaded.upcomingShifts,
    emptyEvents: isEmpty(state.event.eventsGroupedByShifts.upcoming),
    pagination: state.event.eventsGroupedByShifts.upcomingPagination || { per_page: 1, total: 10 },
  }
}

function mapDispatchToProps(dispatch) {
  return {
    reset: () => dispatch(resetUpcomingFilters()),
    submit: () => dispatch(applyUpcomingFilters()),
    updateFilter: (filter, value) => dispatch(updateUpcomingFilters(filter, value)),
    clearV2Form: () => dispatch(clearV2Form()),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(EventDetailsScene);
