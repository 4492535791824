import ApiService from './ApiService';

import { SessionSerializer } from 'services/serializers';

export const SESSION_EMAIL_KEY = "currentSessionEmail";

class SessionService extends ApiService {
  login(data) {
    if (data.role === 'Parent') {
      return super.postWithResponse(`/parents/sign_in.json`,
        SessionSerializer.serialize(data),
        (response) => SessionSerializer.deserializeParentPatient(response));
    }
    else if (data.role === 'Patient') {
      return super.postWithResponse(`/patients/sign_in.json`,
        SessionSerializer.serialize(data),
        (response) => SessionSerializer.deserializeParentPatient(response));
    }
    return super.postWithResponse(`/event_operators/sign_in.json`,
      SessionSerializer.serialize(data),
      (response) => SessionSerializer.deserialize(response));
  }

  logout(session) {
    this.session = session;

    return super.delete(`/event_operators/sign_out.json`, () => this.session = null);
  }
}

export default SessionService;
