import React from "react";
import go4EMRSvg from "../../go4emr.svg";
import RedHomeButton from "../RedHomeButton";

const EMRBanner = ({ isHomePage }) => {
  return (
    <section className={`eMRBanner${isHomePage ? " home" : ""}`}>
      <div className="top-left">
        <img src={go4EMRSvg} alt="Go4E EMR" />
      </div>
      <div className="bottom-right">
        <h4>
          the go4{" "}
          <span>
            electronic <span className="text-nowrap">medical record</span>
          </span>
        </h4>
        {isHomePage ? (
          <p className="home-text">
            Athletic Trainers on Go4 have access to the only HIPAA
            compliant, youth-sport focused Electronic Medical Record (EMR).
            Should an athlete get hurt, the Go4 EMR helps your athletic
            trainers—and your organization—deliver patient-centered care by
            coordinating collaboration with the next clinician; e.g, emergency
            rooms, urgent cares and primary care physicians.
          </p>
        ) : (
          <p>
            <span>ONLY FOR ATHLETIC TRAINERS & ONLY ON THE GO4 APP.</span>{" "}
            Better patient care. Proof of action. Documentation. Research. The
            HIPAA-compliant Go4 EMR is the new, essential Athletic Training
            tool. The Go4 EMR is free to use for all Go4 account holders.
          </p>
        )}
        {!isHomePage && (
          <RedHomeButton
            asLink
            text="Learn More"
            to={"/emr"}
            className="emr-banner"
          />
        )}
      </div>
    </section>
  );
};

export default EMRBanner;
