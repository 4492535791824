class LeadSerializer {
  static deserialize(lead) {
    const result = {
      // TODO
    };

    return result;
  }

  static serialize(lead) {
    const result = {
      referrer_name: lead.referrerName,
      referrer_email: lead.referrerEmail,
      lead_name: lead.leadName,
      lead_title: lead.leadTitle,
      company_organization: lead.companyOrganization,
      sport: lead.sport,
      lead_phone: lead.leadPhone,
      lead_email: lead.leadEmail,
      city_state: lead.cityState,
      notes: lead.notes,
    };

    return result;
  }
}

export default LeadSerializer;
