import React, { Component } from 'react';
import classNames from 'classnames';
import './SortComponent.scss'

class SortComponent extends Component {
  render() {
    const { title } = this.props;

    return (
      <div className='sortComponent'>
        <i
          className={classNames('material-icons', {
            'active-arrow': title === 'asc',
          })}
        >
          arrow_drop_up
        </i>
        <i
          className={classNames('material-icons', {
            'active-arrow': title === 'desc',
          })}
        >
          arrow_drop_down
        </i>
      </div>
    );
  }
}

export default SortComponent;
