import { ofType } from 'redux-observable';
import { of } from 'rxjs/observable/of';
import { catchError, mergeMap, map, debounceTime } from 'rxjs/operators';
import { mappingEpic } from 'redux/ducks/helpers';
import * as actions from './index';
import { SHOW_SPINNER, HIDE_SPINNER } from 'redux/ducks/LoadingSpinner';
import MedicalRecordService from 'services/MedicalRecordService'
import { saveAs } from 'file-saver';

// Epics
// ========================================================
export function getMedicalRecordWaivers(action$, store) {
  return action$.pipe(
    ofType(actions.GET_MEDICAL_RECORD),
    debounceTime(200),
    mergeMap(action => new MedicalRecordService(store.getState().session).get(action.payload)),
    map((medicalRecordWaivers) => {
      return { type: actions.GET_MEDICAL_RECORD_SUCCESS, payload: medicalRecordWaivers };
    }),
    catchError((error) => {
      return of({
        type: actions.GET_MEDICAL_RECORD_ERROR,
        payload: error,
      });
    })
  )
}

function iOS() {
  return [
    'iPad Simulator',
    'iPhone Simulator',
    'iPod Simulator',
    'iPad',
    'iPhone',
    'iPod'
  ].includes(window.navigator.platform)
    // iPad on iOS 13 detection
    || (window.navigator.userAgent.includes("Mac") && "ontouchend" in document)
}

export function downloadMedicalRecordWaivers(action$, store) {
  return action$.pipe(
    ofType(actions.DOWNLOAD_MEDICAL_RECORD),
    debounceTime(200),
    mergeMap(action => new MedicalRecordService(store.getState().session).download(action.payload)),
    map((medicalRecord) => {
      const blob = medicalRecord.pdf;
      const filename = `medical_record_${medicalRecord.id}_${Date.now()}.pdf`;
      if (iOS()) {
        const file = new Blob([blob], { type: 'application/pdf' });
        saveAs(file, filename);
      } else if (window.navigator.msSaveOrOpenBlob) {
        window.navigator.msSaveBlob(blob, filename);
      } else {
        var elem = window.document.createElement('a');
        elem.href = window.URL.createObjectURL(blob);
        elem.download = filename;
        elem.target = 'blank';
        document.body.appendChild(elem);
        elem.click();
        document.body.removeChild(elem);
      }

      return { type: actions.DOWNLOAD_MEDICAL_RECORD_SUCCESS, payload: medicalRecord };
    }),
    catchError((error) => {
      return of({
        type: actions.DOWNLOAD_MEDICAL_RECORD_ERROR,
        payload: error,
      });
    })
  )
}

export function openMedicalRecordWaivers(action$, store) {
  return action$.pipe(
    ofType(actions.OPEN_MEDICAL_RECORD),
    debounceTime(200),
    mergeMap(action => new MedicalRecordService(store.getState().session).download(action.payload)),
    map((medicalRecord) => {
      const blob = medicalRecord.pdf;
      const filename = `medical_record_${medicalRecord.id}_${Date.now()}.pdf`;

      if (iOS()) {
        const file = new Blob([blob], { type: 'application/pdf' });
        saveAs(file, filename);
      } else {
        const file = new Blob([blob], { type: 'application/pdf' });
        const url = URL.createObjectURL(file);
        window.open(url);
      }

      return { type: actions.OPEN_MEDICAL_RECORD_SUCCESS, payload: medicalRecord };
    }),
    catchError((error) => {
      return of({
        type: actions.OPEN_MEDICAL_RECORD_ERROR,
        payload: error,
      });
    })
  )
}


export const showSpinnerEpic = mappingEpic(
  [
    actions.GET_MEDICAL_RECORD,
    actions.DOWNLOAD_MEDICAL_RECORD,
    actions.OPEN_MEDICAL_RECORD,
  ],
  SHOW_SPINNER
)

export const hideSpinnerEpic = mappingEpic(
  [
    actions.GET_MEDICAL_RECORD_SUCCESS,
    actions.GET_MEDICAL_RECORD_ERROR,
    actions.DOWNLOAD_MEDICAL_RECORD_SUCCESS,
    actions.DOWNLOAD_MEDICAL_RECORD_ERROR,
    actions.OPEN_MEDICAL_RECORD_SUCCESS,
    actions.OPEN_MEDICAL_RECORD_ERROR
  ],
  HIDE_SPINNER
)