class EnumSerializer {
  static deserialize(data) {
    const result = {
      sports: data.sports,
      genders: data.genders,
      eventTypes: data.event_types,
      settingTypes: data.setting_types,
      rateTypes: data.rate_types,
      eventParticipantNumbers: data.event_participant_numbers,
      ageBrackets: data.age_brackets,
      professions: data.professions,
      eventSettings: data.event_settings,
      settingDetails: data.setting_details,
      jobDescriptions: data.job_descriptions,
    };
    return result;
  }
}

export default EnumSerializer;
