/* global google */
import classNames from 'classnames';
import HorizontalRule from 'components/HorizontalRule';
import { AddButton } from 'components/V2/Buttons';
import React, { Component } from 'react';
import { GoogleMap, Marker, withGoogleMap } from 'react-google-maps';
import { GoogleSerializer } from 'services/serializers';

const MapContainer = withGoogleMap(({ latitude, longitude }) => (latitude && longitude && <GoogleMap
  center={{ lat: latitude, lng: longitude }}
  defaultZoom={12}
><Marker position={{ lat: latitude, lng: longitude }}></Marker></GoogleMap>))

class VenueLocation extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isEditing: false
    }
  }

  onPlaceSelected = (place) => {
    this.setState(prev => ({ isEditing: false }))
    this.props.onGooglePlaceSelected(GoogleSerializer.eventLocationFromGooglePlace(place));
  }

  toggleEditing = () => {
    this.props.history.push({
      pathname: `${this.props.baseUrl}/location`,
      state: { from: 'venue-location' }
    })
  }

  render() {
    const { name, details, latitude, longitude, address } = this.props.eventLocation;
    const { streetAddress, city, state, zipCode } = address;

    return (
      <div className="venueLocation">
        <div className="section-header col gotham-bold-font">Facility Location</div>
        <section>
          <div className="row">
            <div className="col-md-4 col-sm-12 nimbus-regular-font venue-location-section">
              <div className='gotham-medium-font location-header'>Address</div>
              <div className="location-name">{name}</div>
              <div className="location-name">{streetAddress}</div>
              <div className="location-name">{`${city}, ${state} ${zipCode}`}</div>
              <div className="location-details">
                {details &&
                  <span><i>Report to:</i> {details}</span>
                }
              </div>
              <AddButton onClick={this.toggleEditing} className='gotham-regular-font'>
                Edit
              </AddButton>
              <input
                ref='searchBox'
                className={classNames("form-control nimbus-regular-font italic-placeholder", {
                  'd-none': !this.state.isEditing
                })}
                onChange={(e) =>
                  this.props.onChangeLocation({
                    name: 'name',
                    value: e.target.value,
                  })
                }
              />
              <div className="p-0 m-0" id="attribution"></div>
              <HorizontalRule dashed />
              <div className="location-header gps gotham-medium-font">GPS Coordinates</div>
              <div className="location-coordinates nimbus-regular-font">({latitude}, {longitude})</div>
            </div>
            <div className="col-md" style={{ minHeight: '200px' }}>
              {!!latitude && !!longitude && <MapContainer
                latitude={latitude}
                longitude={longitude}
                containerElement={<div style={{ height: `100%`, width: '100%' }} />}
                mapElement={<div style={{ height: `100%` }} />}
              />}
            </div>
          </div>
        </section>
      </div>
    );
  }

  componentDidMount() {
    const autocompleteOptions = {
      componentRestrictions: {
        country: 'us'
      },
      types: ['establishment']
    }
    const autocomplete = new google.maps.places.Autocomplete(this.refs.searchBox, autocompleteOptions);
    autocomplete.addListener('place_changed', () => this.onPlaceSelected(autocomplete.getPlace()))
  }
}

export default VenueLocation;