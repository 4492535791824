class SettingSerializer {
  static deserialize(data) {
    const result = {
      resources: data.content_feed.map((item) => ({
        title: item.feed_article_title,
        imageLink: item.feed_article_image_link,
        link: item.feed_article_link,
      })),
    };
    return result;
  }
}

export default SettingSerializer;
