import React from 'react';
import moment from 'moment';
import InputOrFlatpickr from 'components/InputOrFlatpickr';
import classNames from 'classnames';
import Tip from 'scenes/V2/Tip';
import { DATE_PICKER_OPTIONS, TIME_PICKER_OPTIONS } from 'helpers/post';
import { hour24ToMeridiem, meridiemTo24 } from 'helpers/datetime';

const ShiftGenerator = (props) => {
  const { errorMessages, schedule, weekdays, valid, capacity, ...functions } = props;

  const onChange = (name) => (value) => {
    functions.setSchedule({ [name]: value });
  };

  const onTimeChange = (name) => (value) => {
    functions.setSchedule({ [name]: meridiemTo24(value) });
  };

  const showError = (field) => {
    return props.wasValidated && !!errorMessages[field];
  };

  const onDayPick = (day) => () => {
    const newWeekdays = weekdays.includes(day)
      ? weekdays.filter((d) => d !== day)
      : [...weekdays, day];

    functions.setWeekdays(newWeekdays);
  };

  const isChecked = (day) => {
    return weekdays.includes(day);
  };

  const renderDay = (day) => (
    <input
      onChange={onDayPick(day)}
      checked={isChecked(day)}
      type="checkbox"
      className="day"
      id={day}
      data-day={day.substring(0, 3)}
    />
  );

  const totalHours = () => {
    if (!schedule.start || !schedule.end) return 0;

    return Number(
      (24 + moment(schedule.end, 'HH:mm').diff(moment(schedule.start, 'HH:mm'), 'hours', true)) % 24
    )
      .toFixed(1)
      .replace(/\.0$/, '');
  };

  const add = (num) => () => {
    const newCapacity = Number(capacity || 1) + num;
    functions.setCapacity(newCapacity < 1 ? 1 : newCapacity);
  };

  return (
    <div className="shift-generator">
      <div className="row">
        <div className="col-sm-5">
          <label htmlFor="startDate">{SCHEDULE_FIELD_LABELS['startDate']}</label>
          <div className="input-group">
            <InputOrFlatpickr
              type="datetime"
              name="startDate"
              value={schedule.startDate}
              options={DATE_PICKER_OPTIONS}
              onChange={onChange('startDate')}
              className={classNames('form-control lbr nimbus-regular-font', {
                'is-invalid': showError('startDate'),
              })}
              placeholder={moment().format('MM-DD-YYYY')}
            />
            <div className="input-group-append">
              <span
                className="input-group-text rbr cursor-pointer"
                onClick={() => {
                  const input = document.querySelector(`input[name="startDate"]`);
                  if (input) input.focus();
                }}
              >
                <i className="far fa-calendar-alt"></i>
              </span>
            </div>
            <div className="invalid-feedback">{errorMessages['startDate']}</div>
          </div>
        </div>
        <span className="text-secondary mini-helper">
          <div className="col-md-1 text-center">
            <i className="fas fa-arrow-right"></i>
          </div>
        </span>
        <div className="col-sm-5">
          <label htmlFor="endDate">{SCHEDULE_FIELD_LABELS['endDate']}</label>
          <div className="input-group">
            <InputOrFlatpickr
              name="endDate"
              value={schedule.endDate}
              options={DATE_PICKER_OPTIONS}
              type="datetime"
              onChange={onChange('endDate')}
              className={classNames('form-control lbr nimbus-regular-font', {
                'is-invalid': showError('endDate'),
              })}
              placeholder={moment().format('MM-DD-YYYY')}
            />
            <div className="input-group-append">
              <span
                className="input-group-text rbr cursor-pointer"
                onClick={() => {
                  const input = document.querySelector(`input[name="endDate"]`);
                  if (input) input.focus();
                }}
              >
                <i className="far fa-calendar-alt"></i>
              </span>
            </div>
            <div className="invalid-feedback">{errorMessages['endDate']}</div>
          </div>
        </div>
      </div>
      <br />
      <div className="row">
        <div className="col-sm-5">
          <label htmlFor="start">{SCHEDULE_FIELD_LABELS['start']}</label>
          <div className="input-group">
            <InputOrFlatpickr
              type="datetime"
              name="start"
              value={hour24ToMeridiem(schedule.start)}
              options={TIME_PICKER_OPTIONS}
              onValueUpdate={onTimeChange('start')}
              className={classNames('form-control fbr nimbus-regular-font', {
                'is-invalid': showError('start'),
              })}
              placeholder="12:00 PM"
              timezone={props.timezone}
            />
            <div className="invalid-feedback">{errorMessages['start']}</div>
          </div>
        </div>
        <span className="text-secondary mini-helper">
          <div className="col-md-1 text-center">
            <p>{totalHours()}h</p>
          </div>
        </span>
        <div className="col-sm-5">
          <label htmlFor="end">{SCHEDULE_FIELD_LABELS['end']}</label>
          <div className="input-group">
            <InputOrFlatpickr
              name="end"
              value={hour24ToMeridiem(schedule.end)}
              options={TIME_PICKER_OPTIONS}
              type="datetime"
              onValueUpdate={onTimeChange('end')}
              className={classNames('form-control fbr nimbus-regular-font', {
                'is-invalid': showError('end'),
              })}
              placeholder="2:00 PM"
              timezone={props.timezone}
            />
            <div className="invalid-feedback">{errorMessages['end']}</div>
          </div>
        </div>
      </div>
      <br />
      <div className="row m-0">
        <div className="col-sm-12 pl-0">
          <label htmlFor="capacity">How many Athletic Trainers do you need for this shift?</label>
        </div>
      </div>
      <div className="row">
        <div className="col-md-4">
          <div className="input-group">
            <div className="input-group-append">
              <span
                type="button"
                onClick={add(-1)}
                className="input-group-text lbr remove-border-r"
              >
                <i className="fas fa-minus"></i>
              </span>
            </div>
            <input
              type="number"
              name="capacity"
              value={capacity}
              onChange={(e) => functions.setCapacity(e.target.value)}
              className={classNames('form-control text-center pl-4 gotham-bold-font', {
                'is-invalid': showError('capacity'),
              })}
              placeholder="1"
              min={1}
            />
            <div className="input-group-append">
              <span type="button" onClick={add(1)} className="input-group-text rbr">
                <i className="fas fa-plus"></i>
              </span>
            </div>
            <div className="invalid-feedback">{errorMessages['capacity']}</div>
          </div>
        </div>
      </div>
      <div className="pt-2 col-md-8 left-indent">
        <Tip text="It’s recommended to have 1 AT for every 2 fields" />
      </div>
      <div className="row">
        <div className="col-md-12">
          <div className="recurringDaysForm">
            <p>Shift repeats every:</p>
            <div className="days gotham-bold-font">
              {renderDay('Sunday')}
              {renderDay('Monday')}
              {renderDay('Tuesday')}
              {renderDay('Wednesday')}
              {renderDay('Thursday')}
              {renderDay('Friday')}
              {renderDay('Saturday')}
            </div>
            <div className="error">{props.wasValidated && errorMessages['weekdays']}</div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ShiftGenerator;

export const SCHEDULE_FIELD_LABELS = {
  startDate: 'Start Date',
  endDate: 'End Date',
  start: 'Start Time',
  end: 'End Time',
};
