import classNames from 'classnames';
import React from 'react';
import ShiftSummary from './ShiftSummary';
import ShiftActions from './ShiftActions';

const NonTournamentSummary = ({ shifts, ...props }) => {
  const { editMode, rateTypes } = props;

  return (
    <div className="shift-list">
      {shifts.map((shift, idx) => {
        return (
          <div
            key={idx + 1}
            className={classNames('shift', {
              borderBottom: editMode || idx !== shifts.length - 1,
            })}
            id={`s-${idx}`}
          >
            <div className="shift-name gotham-bold-font">{`Shift ${idx + 1}`}</div>
            <ShiftSummary
              timezone={props.timezone}
              shift={shift}
              rateTypes={rateTypes}
              editMode={editMode}
            />
            <ShiftActions
              {...props}
              shifts={shifts}
              idx={idx}
              shift={shift}
              canDelete={() => shifts.length > 1}
            />
          </div>
        );
      })}
    </div>
  );
};

export default NonTournamentSummary;
