// Actions
// ========================================================
export const CLEAR_RESET_PASSWORD = 'go4llis/ResetPassword/CLEAR_RESET_PASSWORD';

export const RESET_PASSWORD = 'go4ellis/ResetPassword/RESET_PASSWORD';
export const RESET_PASSWORD_SUCCESS = 'go4ellis/ResetPassword/RESET_PASSWORD_SUCCESS';
export const RESET_PASSWORD_ERROR = 'go4ellis/ResetPassword/RESET_PASSWORD_ERROR';

export const UPDATE_PASSWORD = 'go4ellis/ResetPassword/UPDATE_PASSWORD';
export const UPDATE_PASSWORD_SUCCESS = 'go4ellis/ResetPassword/UPDATE_PASSWORD_SUCCESS';
export const UPDATE_PASSWORD_ERROR = 'go4ellis/ResetPassword/UPDATE_PASSWORD_ERROR';

export const VALIDATE_TOKEN = 'go4ellis/ResetPassword/VALIDATE_TOKEN';
export const VALIDATE_TOKEN_SUCCESS = 'go4ellis/ResetPassword/VALIDATE_TOKEN_SUCCESS';
export const VALIDATE_TOKEN_ERROR = 'go4ellis/ResetPassword/VALIDATE_TOKEN_ERROR';

// Reducer Functions
// ========================================================
export function validateTokenReducer(state, headers) {
  return {
    ...state,
    validToken: true,
    headers,
  };
}

// Action Creators
// ========================================================
export function clearResetPassword() {
  return { type: CLEAR_RESET_PASSWORD };
}

export function resetPassword(email) {
  return { type: RESET_PASSWORD, payload: { email } };
}

export function updatePassword(payload) {
  return { type: UPDATE_PASSWORD, payload };
}

export function validateToken(token) {
  return { type: VALIDATE_TOKEN, payload: token };
}

// Reducer
// ========================================================
const DEFAULT_STATE = {
  validToken: false,
  headers: { },
};

export default function reducer(state = DEFAULT_STATE, action = {}) {
  switch (action.type) {
    case CLEAR_RESET_PASSWORD:
      return {...DEFAULT_STATE};
    case UPDATE_PASSWORD_SUCCESS:
      return {success: true, ...action.payload}
    case RESET_PASSWORD_SUCCESS:
      return {success: true}
    case RESET_PASSWORD_ERROR:
    case UPDATE_PASSWORD_ERROR:
    case VALIDATE_TOKEN_ERROR:
      return { error: action.payload.message }
    case VALIDATE_TOKEN_SUCCESS:
      return validateTokenReducer(state, action.payload);
    default: return state;
  }
}