import React, { Component } from 'react';
import { Link } from 'react-router-dom';

import PropTypes from 'prop-types';

import NavLink from 'components/NavLink';

class NavBar extends Component {
  render() {
    const logoProps = {
      src: this.props.logo,
      style: {width: this.props.logoWidth}
    };

    return (
      <nav className="navBar">
        <Link to={this.props.logoUrl ? this.props.logoUrl : '/'}>
          <img {...logoProps} className="logo" alt="Go4" />
        </Link>
        <ul className={this.props.authenticated ? 'links' : 'links unauthed-links'}>
          {this.props.links && this.props.links.map((link, index) => {
            return <NavLink key={index} link={link} />
          })}
        </ul>
      </nav>
    );
  }
}

NavLink.contextTypes = {
  currentUser: PropTypes.object,
  location: PropTypes.object,
  router: PropTypes.object,
}

export default NavBar;
