import React from "react";
import RedHomeButton from "../RedHomeButton";

const ScheduleADemo = ({ text, altStyle }) => (
  <section className={`scheduleADemo ${altStyle ? "altStyle" : ""}`}>
    <div className="inner">
      <span>
        {text ||
          "NEED HELP? SCHEDULE A DEMO AND WE’LL WALK YOU THROUGH POSTING A SHIFT."}
      </span>
      <RedHomeButton
        text="Schedule A Demo"
        href="mailto:demo@go4.io?subject=I%20would%20like%20to%20schedule%20a%20demo.&body=I%20would%20like%20to%20schedule%20a%20demo%20on:"
      />
    </div>
  </section>
);

export default ScheduleADemo;
