import HorizontalRule from 'components/HorizontalRule';
import React, { Component } from 'react';
import { get, isObject, isEmpty, values } from 'lodash';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import moment from 'moment';
import classNames from 'classnames';

class RateBoostModal extends Component {
  state = {
    open: false,
    addRate: 5,
    payRate: this.props.shift.payRate || this.props.shift.event.payRate,
    rateTypeId: this.props.shift.payRate
      ? this.props.shift.rateTypeId
      : this.props.shift.event.rateTypeId,
  };

  RATES = [5, 10, 15, 20];

  toggle = () => {
    const { shift } = this.props;
    this.setState((prevState) => ({
      open: !prevState.open,
      addRate: 5,
      payRate: prevState.open ? 0 : shift.payRate || shift.event.payRate,
      rateTypeId: prevState.open
        ? 0
        : shift.payRate
          ? shift.rateTypeId
          : shift.event.rateTypeId,
    }));
  };

  onChangeRate(e) {
    this.setState({ addRate: e === '' ? '' : Number(e) });
  }

  calculateNewRate = () => {
    return this.state.payRate + (this.state.addRate || 0);
  };

  onSave = () => {
    if (!this.isValid()) {
      return;
    }

    const { shift } = this.props;
    const payload = {
      rate: this.calculateNewRate(),
      rateTypeId: this.state.rateTypeId,
      shiftId: shift.id,
      eventId: shift.event.id,
      boostedAt: moment.utc().format(),
    };
    this.props.onSave(payload);
    this.toggle();
  };

  isValid = () => {
    return this.state.addRate && this.state.addRate <= 100000;
  };

  render() {
    const { payRate, rateTypeId, open, addRate } = this.state;
    return (
      <div className="rateBoostModal">
        <button className={classNames('plain-button', {
          'boost-shift': this.props.from === "upcomingShifts",
          "modal-button": this.props.from === "dashboard"
        })} onClick={this.toggle}>
          {this.props.children}
        </button>
        <Modal
          isOpen={open}
          toggle={this.toggle}
          className="rate-boost-modal-content"
        >
          <ModalHeader className="header" toggle={this.toggle}>
            <div className="title">Boost Rate</div>
          </ModalHeader>
          <ModalBody>
            <h6>CURRENT RATE: ${payRate.toFixed(2)}</h6>
            <HorizontalRule dashed />
            <p>Boost Rate By</p>
            <div className="row">
              {this.RATES.map((rate, idx) => {
                return (
                  <div key={idx} className="col-md-3">
                    <div
                      className={`rate ${addRate === rate ? 'active' : ''}`}
                      onClick={() => this.setState({ addRate: rate })}
                    >
                      <p>$</p>
                      <h5>{rate}</h5>
                      {rateTypeId === 0 && <p>hr</p>}
                    </div>
                  </div>
                );
              })}
            </div>
            <div className="row">
              <div className="col-md-2">
                <p>OTHER:</p>
              </div>
              <div className="col-md-6">
                <div className="input-group">
                  <div className="input-group-prepend">
                    <span className="input-group-text rate-input">$</span>
                  </div>
                  <input
                    className="form-control rate-input"
                    type="number"
                    name="rate"
                    min="1"
                    max="100000"
                    step="1"
                    value={addRate}
                    placeholder="1"
                    onChange={(e) =>
                      this.onChangeRate(
                        isObject(e) ? get(e, 'target.value') : e
                      )
                    }
                    required
                  />
                  <div className="input-group-append">
                    <span className="input-group-text rate-input">
                      {rateTypeId === 0 && 'hr'}
                      {rateTypeId === 1 && 'flat'}
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <HorizontalRule dashed />
            <h6>NEW BOOSTED RATE: ${this.calculateNewRate().toFixed(2)}</h6>
          </ModalBody>
          <ModalFooter className="actions">
            <button
              disabled={!this.isValid()}
              className="btn btn-success"
              onClick={this.onSave}
            >
              BOOST RATE!
            </button>
          </ModalFooter>
        </Modal>
      </div>
    );
  }
}

export default RateBoostModal;
