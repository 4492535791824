import React from "react";

const ApplyTextarea = ({ name, value, handleChange, hasError }) => (
  <textarea
    className={`applyTextarea ${hasError ? "error" : ""}`}
    name={name}
    value={value}
    onChange={handleChange}
  />
);

export default ApplyTextarea;
