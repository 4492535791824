import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { checkSignedMedicalRecordWaiver } from 'redux/ducks/MedicalRecordWaivers';
import queryString from 'query-string';

export class MedicalRecordWaiverScene extends Component {
  componentDidMount() {
    const medicalRecordWaiverId = this.props.match.params.medicalRecordWaiverId;

    const { checkSignedMedicalRecordWaiver } = this.props;

    checkSignedMedicalRecordWaiver(medicalRecordWaiverId);

    var eventMethod = window.addEventListener ? 'addEventListener' : 'attachEvent',
      messageEvent = eventMethod === 'attachEvent' ? 'onmessage' : 'message';

    window[eventMethod](messageEvent, function (e) {
      if (e && e.data) {
        try {
          console.log(e.data);
          var message = e.data;
          if (message && message.type) {
            if (message.type === "session_view.document.completed") {
              checkSignedMedicalRecordWaiver(medicalRecordWaiverId);
            }
            console.log(message.event, message.data);
          }
        } catch (e) {
          console.error(e);
        }
      }
    }, false);

  }

  componentWillReceiveProps(newProps) {
    if (newProps.error && !this.props.error) {
      this.props.onError('An error occurred when loading the medical record waiver.');
    }

    const role = newProps.user.role.toLowerCase();
    if (newProps.redirectUrl && newProps.redirectUrl.includes(`/${role}/dashboard`)) {
      window.location = newProps.redirectUrl;
    }
  }

  render() {
    const medicalRecordWaiverId = this.props.match.params.medicalRecordWaiverId;

    const urlParsed = queryString.parseUrl(this.props.redirectUrl || "");
    const sessionId = urlParsed.query['session_id'];
    const role = this.props.user.role.toLowerCase();

    return (
      <div className="medicalRecord">
        <div className="header">
          <h2>
            Medical Record Waiver {medicalRecordWaiverId}
          </h2>
          {
            sessionId != null &&
            <iframe
              src={`https://app.pandadoc.com/s/${sessionId}`}
              style={{
                border: 0,
                zIndex: 1,
                width: '100%',
                height: '100vh',
              }}>
              Your browser does not support iframes.
              </iframe>
          }
        </div>
      </div>
    )
  }
}

function mapStateToProps(state) {
  return {
    user: state.session.currentUser,
    error: state.medicalRecordWaivers.error,
    redirectUrl: state.medicalRecordWaivers.redirectUrl
  };
}

function mapDispatchToProps(dispatch) {
  return {
    checkSignedMedicalRecordWaiver: (id) => dispatch(checkSignedMedicalRecordWaiver(id)),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(MedicalRecordWaiverScene);
