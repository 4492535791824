import React, { Component } from 'react';

class Footer extends Component {
  render() {
    return (
      <div className="v2footer">
        <div className="v2lower row">
          <div>
            <span>&copy; {new Date().getFullYear()} Go4, Patent Pending</span>
          </div>

          <div>
            <span>
              <a target="_blank" rel="noopener noreferrer" href="https://go4.io/terms-and-conditions/">
                Terms &amp; Conditions
              </a>
            </span>
          </div>

          <div>
            <span>
              <a target="_blank" rel="noopener noreferrer" href="https://go4.io/privacy-policy/">
                Privacy Policy
              </a>
            </span>
          </div>

          <div>
            <span>
              <a target="_blank" href="/files/hipaa.pdf">
                HIPAA
              </a>
            </span>
          </div>
        </div>
      </div>
    );
  }
}

export default Footer;