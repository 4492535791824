import React, { Component } from 'react';
import { LocationScreen } from '../Shared/EventLocation';
import EventSchedule from '../Shared/EventSchedule';
import EventRate from '../Shared/EventRate';
import { Route } from 'react-router-dom';
import EventSetting from './EventSetting';
import EventSettingDetail from './EventSettingDetail';
import { connect } from 'react-redux';
import { POST_STORAGE_KEY, V2_RELOAD_FORM, saveLocalForm } from 'redux/ducks/Post/actions';
import EventType from './EventType';
import { GET_ENUMS } from 'redux/ducks/Enum';
import ScheduleSummary from '../Shared/ScheduleSummary';
import ProfileScreen from '../Shared/EventProfile/ProfileScreen';
import LocationsIntroScreen from '../Shared/EventLocation/LocationsIntroScreen';
import LocationDetails from '../Shared/LocationDetails';
import LocationEAP from '../Shared/LocationEAP';
import { get } from 'lodash';
import { loadFromStorage } from 'redux/ducks/helpers';
import LocationSetup from '../Shared/EventLocation/LocationSetup';
import PublishOrUpdateStep from './PublishOrUpdateStep';
import { visitStep } from 'redux/ducks/Post/Navigation';
import { stepsMapper } from 'helpers/post';
import Sidebar from 'react-sidebar';
import Navigation from '../Shared/Navigation';
import EventIntro from './EventIntro';
const SAVE_ON_INITIALIZE_STEPS = [
  'SettingDetail',
  'LocationScreen',
  'EventSchedule',
  'EventRate',
  'Supplies',
  'Details',
  'Credentials',
  'LocationEAP',
];

class CreatePostScene extends Component {
  state = { sideBarOpen: false };
  toggleSideBar = () => {
    this.setState({ sideBarOpen: !this.state.sideBarOpen }, () => {
      if (this.state.sideBarOpen) {
        document.body.style.overflow = 'hidden';
      } else {
        document.body.style.overflow = 'auto';
      }
    });
  };
  _currentStep = null;

  initialize = (name) => {
    this._currentStep = name;

    const { locationIdx } = this.props.navigation;
    if (name === 'PublishOrUpdateStep') this.props.visit('post');
    else this.props.visit(`L${locationIdx}-${stepsMapper(name)}`);

    const { profile, details, authenticated } = this.props;
    const localEvent = loadFromStorage(POST_STORAGE_KEY);

    if (
      (!authenticated && localEvent && profile.eventSettingId === '') ||
      (localEvent?.details?.id && !details.id)
    )
      this.props.reloadForm();
  };

  componentDidMount() {
    if (!get(this.props, 'enums.settingTypes.length')) this.props.getEnums();

    if (
      this.props.location.fromDrafts ||
      (this._currentStep &&
        SAVE_ON_INITIALIZE_STEPS.includes(this._currentStep) &&
        this.props.authenticated)
    ) {
      this.props.saveLocalForm();
    }
  }

  componentWillUnmount() {
    localStorage.removeItem('showLowRatePopUp');
  }

  renderRoute = (route, Component) => {
    const { match } = this.props;
    return (
      <Route
        path={`${match.url}/${route}`}
        render={(props) => (
          <Component
            {...props}
            initialize={this.initialize}
            baseUrl={match.url}
            showAlertCircle={this.showAlertCircle}
          />
        )}
      />
    );
  };

  sidebarStyle = () => {
    const topNavBarHeight = 78;
    const footerHeight = 30;

    if (window.innerWidth < 768)
      return {
        background: '#eef3f5',
        position: 'fixed',
        height: `calc(100vh - ${topNavBarHeight}px)`,
        minWidth: '200px',
        width: '20vw',
        zIndex: '100',
        overflowY: 'auto',
        overflowX: 'hidden',
        top: '78px',
        paddingBottom: `${topNavBarHeight}px`,
      };

    return {
      background: '#eef3f5',
      position: 'fixed',
      height: `calc(100vh - ${topNavBarHeight}px)`,
      maxWidth: '200px',
      minWidth: '130px',
      width: '16vw',
      overflowY: 'auto',
      overflowX: 'hidden',
      top: '78px',
      paddingBottom: `${footerHeight}px`,
    };
  };

  hideSidebar = () => {
    const slugs = this.props.location.pathname.split('/');
    const slug = slugs.pop();
    const flow = slugs.pop();
    return flow === 'create-event' && ['type', 'setting', 'setting-detail', 'intro'].includes(slug);
  };

  render() {
    return (
      <div className="v2CreatePostScene">
        {!this.hideSidebar() && (
          <button className="sidebar-button">
            <i className="fa fa-bars" onClick={this.toggleSideBar} />
          </button>
        )}
        <Sidebar
          sidebar={
            <div className="navigation-wrapper">
              <Navigation
                baseUrl={this.props.match.url}
                showAlertCircle={this.showAlertCircle}
                {...this.props}
              />
            </div>
          }
          open={this.state.sideBarOpen}
          docked={window.innerWidth > 768 && !this.hideSidebar()}
          onSetOpen={this.toggleSideBar}
          styles={{
            sidebar: this.sidebarStyle(),
            root: { overflow: 'initial', top: '78px' },
            content: { overflow: 'initial' },
            overlay: { zIndex: 99 },
          }}
          shadow={false}
        >
          <div />
        </Sidebar>
        <main className="content gotham-regular-font">
          {this.renderRoute('intro', (props) => (
            <EventIntro {...props} hideNavBarIcon />
          ))}
          {this.renderRoute('type', EventType)}
          {this.renderRoute('setting', EventSetting)}
          {this.renderRoute('setting-detail', EventSettingDetail)}
          {this.renderRoute('profile', ProfileScreen)}
          {this.renderRoute('location', LocationScreen)}
          {this.renderRoute('all-locations', LocationsIntroScreen)}
          {this.renderRoute('schedule', EventSchedule)}
          {this.renderRoute('schedule-summary', ScheduleSummary)}
          {this.renderRoute('rate', EventRate)}
          {this.renderRoute('details', LocationDetails)}
          {this.renderRoute('location-setup', LocationSetup)}
          {this.renderRoute('eap', LocationEAP)}
          {this.renderRoute('post', PublishOrUpdateStep)}
        </main>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  ...state.post,
  enums: state.enums,
  authenticated: state.session.authenticated,
});

const mapDispatchToProps = (dispatch) => ({
  reloadForm: () => dispatch({ type: V2_RELOAD_FORM }),
  getEnums: () => dispatch({ type: GET_ENUMS }),
  saveLocalForm: () => dispatch(saveLocalForm()),
  visit: (step) => dispatch(visitStep({ step })),
});

export default connect(mapStateToProps, mapDispatchToProps)(CreatePostScene);
