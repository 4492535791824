import React, { Component } from 'react';
import { connect } from 'react-redux';
import { sendDeeplinkSms } from 'redux/ducks/User';
import branch from 'branch-sdk';
import QRCode from "react-qr-code";
import PhoneInput from 'react-phone-input-2'

import 'react-phone-input-2/lib/style.css'

class DeeplinkScene extends Component {
  constructor(props) {
    super(props);
    this.state = {
      branchLink: this.props.deeplink || '',
      phoneNumber: '+1',
      phoneNumberSent: '+1',
    }
    if (!this.props.deeplink) {
      branch.init(process.env.REACT_APP_BRANCH_KEY, (err, data) => {
        if (!err) {
          console.log(err, data);
          const { data_parsed } = data;
          this.setState({
            branchLink: data_parsed['~referring_link']
          });
        } else {
          console.error(err);
        }
      })
    }
  }

  componentWillMount() {
    document.body.classList.add('deeplink');
  }

  componentWillUnmount() {
    document.body.classList.remove('deeplink');
  }

  onSubmit = () => {
    this.props.sendDeeplinkSms(
      this.state.phoneNumber,
      this.state.branchLink,
    );
    this.setState({ phoneNumberSent: this.state.phoneNumber });
  }

  isValid = (phoneNumber) => {
    if (phoneNumber.match(/^((\+1|1)?( |-)?)?(\([2-9][0-9]{2}\)|[2-9][0-9]{2})( |-)?([2-9][0-9]{2}( |-)?[0-9]{4})$/)) {
      return true;
    }
    return false;
  }

  canSubmit = () => {
    const {
      branchLink,
      phoneNumber,
      phoneNumberSent,
    } = this.state;

    const { deeplinkSmsSent } = this.props;
    if (!branchLink) {
      return false;
    }

    if (!this.isValid(phoneNumber)) {
      return false;
    }

    return phoneNumber !== phoneNumberSent || !deeplinkSmsSent;
  }

  render() {
    const { phoneNumber } = this.state;
    const { deeplinkSmsSent, deeplinkSmsError } = this.props;
    return (
      <div className="deeplinkScene mt-md-0 mt-4">
        <div class="container px-md-0 px-2 pt-4">
          <p className="text-left">
            {this.props.title || "This link needs to be viewed in the app."}
            <br />
            {this.props.subtitle || "To check it out, text it to yourself or scan the QR code."}
          </p>
          <hr class="dashed-line"></hr>
          <div className="center">
            <div className="col form-group input-group flex flex-row col justify-content-center mt-3 mb-3">
              <PhoneInput
                name='phone'
                onlyCountries={["us"]}
                preferredCountries={["us"]}
                enableAreaCodes={false}
                autoFormat={true}
                placeholder='Enter phone number'
                country='us'
                isValid={(pn) => this.isValid(pn) || ['1', '+1'].includes(pn)}
                onlyCountries={['us']}
                value={phoneNumber}
                onChange={(value) => {
                  const phoneNo = value.length === 1 && value.at(0) !== '1' ? `1${value}` : value;
                  this.setState({ phoneNumber: phoneNo, phoneChanged: true })
                }}
                containerStyle={{ display: 'flex', flex: 1, width: 'auto' }}
                inputStyle={{ height: '100%', width: '100%', borderRadius: 0 }}
              />
              <div class="input-group-append">
                <button disabled={!this.canSubmit()} class="btn btn-primary sm-btn-sm" type="button" onClick={() => this.onSubmit()}>Text me the link</button>
              </div>
            </div>
            {deeplinkSmsSent && (
              <div>
                <p className="text-danger">SMS sent successfully</p>
              </div>
            )}
            {deeplinkSmsError && (
              <div>
                <p className="text-danger">SMS could not be sent. Check your phone number.</p>
              </div>
            )}
            <hr class="dashed-line"></hr>
            {this.state.branchLink && (
              <div className="corners mt-4 mb-4 pt-4 pb-4">
                <QRCode value={this.state.branchLink} />
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  deeplinkSmsSent: state.user.deeplinkSmsSent,
  deeplinkSmsError: state.user.deeplinkSmsError,
});

const mapDispatchToProps = (dispatch) => ({
  sendDeeplinkSms: (phone, deeplink) => dispatch(sendDeeplinkSms(phone, deeplink)),
});

export default connect(mapStateToProps, mapDispatchToProps)(DeeplinkScene);