import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { clearV2Form } from 'redux/ducks/Post/actions';

export class SideBarComponent extends Component {
  isActive = (path) => {
    return this.props.pathname.includes(path);
  };
  render() {
    const { user } = this.props;
    return (
      <div className="sideBarComponentV2">
        {user.role === 'Event operator' && (
          <div className="create-job">
            <Link
              onClick={() => {
                this.props.click();
                this.props.clearV2Form();
              }}
              key={'create_event'}
              className="btn btn-primary"
              to="/v2/create-event/setting"
            >
              Create New Job
            </Link>
          </div>
        )}
        <ul className="links">
          {user.role === 'Patient' && (
            <>
              <li
                className={`${
                  this.isActive('/patient/dashboard') ? 'active' : ''
                }`}
              >
                <Link
                  onClick={() => this.props.click()}
                  key="dashboard"
                  to="/patient/dashboard"
                >
                  Dashboard
                </Link>
              </li>
              <li>
                <Link
                  onClick={() => this.props.click()}
                  key="logout"
                  to="/logout"
                >
                  Log Out
                </Link>
              </li>
            </>
          )}
          {user.role === 'Parent' && (
            <>
              <li
                className={`${
                  this.isActive('/parent/dashboard') ? 'active' : ''
                }`}
              >
                <Link
                  onClick={() => this.props.click()}
                  key="dashboard"
                  to="/parent/dashboard"
                >
                  Dashboard
                </Link>
              </li>
              <li>
                <Link
                  onClick={() => this.props.click()}
                  key="logout"
                  to="/logout"
                >
                  Log Out
                </Link>
              </li>
            </>
          )}
          {user.role === 'Event operator' && (
            <>
              <li className={`${this.isActive('/dashboard') ? 'active' : ''}`}>
                <Link
                  onClick={() => this.props.click()}
                  key="dashboard"
                  to="/dashboard"
                >
                  Dashboard
                </Link>
              </li>
              <li
                className={`${
                  this.isActive('/v2/upcoming-events') ? 'active' : ''
                }`}
              >
                <Link
                  onClick={() => this.props.click()}
                  key="upcoming_shifts"
                  to="/v2/upcoming-events"
                >
                  Upcoming Jobs
                </Link>
              </li>
              <li
                className={`${
                  this.isActive('/v2/event-drafts') ? 'active' : ''
                }`}
              >
                <Link
                  onClick={() => this.props.click()}
                  key="drafts"
                  to="/v2/event-drafts"
                >
                  Drafts
                </Link>
              </li>
              <li
                className={`${this.isActive('/v2/finances') ? 'active' : ''}`}
              >
                <Link
                  onClick={() => this.props.click()}
                  key="finances"
                  to="/v2/finances"
                >
                  Finances
                </Link>
              </li>
              <li
                className={`${this.isActive('/edit-profile') ? 'active' : ''}`}
              >
                <Link
                  onClick={() => this.props.click()}
                  key="edit_profile"
                  to="/edit-profile"
                >
                  Edit Profile
                </Link>
              </li>
              <li
                className={`${this.isActive('/edit-payment') ? 'active' : ''}`}
              >
                <Link
                  onClick={() => this.props.click()}
                  key="edit_payment_information"
                  to="/edit-payment"
                >
                  Edit Payment Information
                </Link>
              </li>
              <li>
                <Link
                  onClick={() => this.props.click()}
                  key="logout"
                  to="/logout"
                >
                  Log Out
                </Link>
              </li>
              <li>
                <a
                  href="https://support.go4.io/hc/en-us/categories/115000299368-Job-Posters/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Help
                </a>
              </li>
            </>
          )}
        </ul>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    user: state.session.currentUser,
  };
}

const mapDispatchToProps = (dispatch) => ({
  clearV2Form: () => dispatch(clearV2Form()),
});

export default connect(mapStateToProps, mapDispatchToProps)(SideBarComponent);
