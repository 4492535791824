import ApiService from './ApiService';

import { SettingSerializer } from 'services/serializers';

class SettingService extends ApiService {
  constructor(session) {
    super(session);

    this.url = '/settings';
  }

  getAll() {
    return super.get(`${this.url}.json`,
      (response) => SettingSerializer.deserialize(response));
  }
}

export default SettingService;
