import React from 'react';
import classNames from 'classnames';

const VerticalRule = ({ hideResponsive = false, dashed = false, margin = '20px', white = false }) => {
  return (
    <div
      style={{ marginInline: margin }}
      className={classNames('vertical-rule', {
        'hidden-responsive': hideResponsive,
        'dashed': dashed,
        'white': white,
      })}
    ></div>
  );
}

export default VerticalRule;