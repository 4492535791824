import React, { Component } from 'react';
import PhoneInput from 'react-phone-input-2';
import classnames from 'classnames';

class ReportToContact extends Component {

  onRemove = (contactIndex) => () => {
    this.props.removeContact(contactIndex)
  }

  onPhoneNumberChange(idx, newNumber) {
    const phoneNo = newNumber.length === 1 && newNumber.at(0) !== '1' ? `1${newNumber}` : newNumber;

    this.props.changeContact(idx, { phoneNumber: phoneNo })
  }

  constructor() {
    super();

    this.state = {
      wasFieldValidated: {
        name: false,
        email: false,
        phone: false,
      }
    };
  }

  onFieldBlur = (field: string) => {
    if (!this.state.wasFieldValidated[field]) {
      this.setState(prevState => {
        return {
          ...prevState,
          wasFieldValidated: {
            ...prevState.wasFieldValidated,
            [field]: true
          }
        }
      })
    }
  }

  showError = (fieldName: string) => {
    const { contact, wasValidated, errors } = this.props;
    return (wasValidated || this.state.wasFieldValidated[fieldName]) && !!errors[fieldName];
  }

  render() {
    const { contact, idx, changeContact, errors, disabled, canRemove } = this.props;
    return (
      <div key={idx} className="contact-row form-row">
        <div className="form-group col-md-4">
          <label htmlFor="contact_name">Name</label>
          <input
            type="text"
            disabled={disabled}
            className={classnames(
              "form-control",
              { 'is-invalid': this.showError('name') }
            )}
            onBlur={this.onFieldBlur.bind(this, 'name')}
            onChange={(ev) => changeContact(idx, { name: ev.currentTarget.value })}
            value={contact.name} />
          <div className="invalid-feedback">
            {errors['name']}
          </div>
        </div>
        <div className="form-group col-md-4">
          <label htmlFor="contact_email">Email</label>
          <input
            type="email"
            disabled={disabled}
            className={classnames(
              "form-control",
              { 'is-invalid': this.showError('email') }
            )}
            onBlur={this.onFieldBlur.bind(this, 'email')}
            onChange={(ev) => changeContact(idx, { email: ev.currentTarget.value })}
            value={contact.email} />
          <div className="invalid-feedback">
            {errors['email']}
          </div>
        </div>
        <div className="merge-phone form-group col-md-3">
          <label htmlFor="contact_phone">Phone Number</label>
          <PhoneInput
            name="phone"
            onlyCountries={["us"]}
            preferredCountries={["us"]}
            enableAreaCodes={false}
            autoFormat={true}
            inputStyle={{ width: "100%" }}
            placeholder="Enter phone number"
            disabled={disabled}
            country='us'
            isValid={() => !this.showError('phone')}
            onBlur={this.onFieldBlur.bind(this, 'phone')}
            onChange={(newNumber) => this.onPhoneNumberChange(idx, newNumber)}
            value={contact.phoneNumber}
          />
          {this.showError('phone') && <div className="invalid-feedback-displayed">
            {errors['phone']}
          </div>}
        </div>
        <div className="merge-close col-md-1 d-flex flex-column align-items-center align-self-center">
          {canRemove && <i className="material-icons remove-contact-btn" onClick={this.onRemove(idx)}>close</i>}
        </div>
      </div>
    );
  }
}

export default ReportToContact;