// @flow
import { VALIDATE_FORM, CLEAR_FORM, INIT_FORM } from '../actions';
import { isEmpty, pick, map, includes, omit, get, cloneDeep } from 'lodash';
import { addErrorsToShift } from './validators';
import { basicCreator } from 'redux/ducks/helpers';
import type { Action, ActionCreator, EventLocation } from 'redux/ducks/helpers';
import * as actions from './actions';
import * as eapActions from '../EAP/actions';
import { newEventLocation } from 'redux/ducks/helpers';
import { SAVE_DETAILS_AND_CONTINUE_SUCCESS } from '../Details';
import { UPDATE_JOB_SUCCESS, UPDATE_JOB_V2_SUCCESS } from 'redux/ducks/Job';
import { CREATE_EAP_SUCCESS } from '../EAP/actions';
import { RELOAD_FORM_SUCCESS, DUPLICATE_EVENT } from '../actions';
import moment from 'moment';
declare var jest: typeof undefined;

// Types
// --------------------------------

export class ReportToContact {
  email = '';
  name = '';
  phoneNumber = '';
}
export interface ShiftFormType {
  id: ?number;
  start: string;
  end: string;
  capacity: number;
  eventLocation: EventLocation;
  reportToContacts: ReportToContact[];
  addressSearchResults: any[];
  isSearching: boolean;
  emergencyActionPlanId: ?number;
  jobs: any[];
  cancelled: boolean;
  cancelReason: string;
  errorMessages: {
    [messageName: string]: string,
  };
  countType: ?String;
  countValue: ?number;
  notes: ?string;
  responsibilities: ?string;
  contractInfo: { [key: string]: any };
}

// Use a static date in test environment
const defaultDate = typeof jest === 'undefined' ? moment().format('YYYY-MM-DD') : '01-01-2001';

export class ShiftForm implements ShiftFormType {
  id = null;
  date = defaultDate;
  start = `${defaultDate} 12:00`;
  end = `${defaultDate} 14:00`;
  capacity = 1;
  eventLocation: EventLocation = newEventLocation();
  reportToContacts = [];
  addressSearchResults = [];
  isSearching = false;
  emergencyActionPlanId = null;
  jobs = [];
  cancelled = false;
  cancelReason = '';
  errorMessages = {};
  countType = 'noFields';
  approxHours = null;
  notes = '';
  responsibilities = '';
  contractInfo = {};
}

export interface ShiftFormStateType {
  shifts: ShiftFormType[];
  eventId: ?number;
  isEventIndustrial: ?boolean;
  wasValidated: boolean;
  valid: boolean;
  reuseLocation: boolean;
  reuseOnsiteContacts: boolean;
  invalidLocationSelected: boolean;
  noZipcodeSelected: boolean;
  inProgress: boolean;
}

export class ShiftFormState implements ShiftFormStateType {
  shifts = [new ShiftForm()];
  eventId = null;
  isEventIndustrial = null;
  wasValidated = false;
  valid = false;
  reuseLocation = false;
  reuseOnsiteContacts = false;
  invalidLocationSelected = false;
  noZipcodeSelected = false;
  inProgress = false;
}

// Action Creators
// --------------------------------

export const addShift: ActionCreator = basicCreator(actions.ADD_SHIFT);
export const removeShift: ActionCreator = (index: number) => ({
  type: actions.REMOVE_SHIFT,
  payload: { index },
});
export const fieldChange: ActionCreator = (index: number, name: string, newValue: any) => ({
  type: actions.FIELD_CHANGE,
  payload: { index, name, newValue },
});
export const newAddressInput: ActionCreator = ({ index, input }: any) => ({
  type: actions.NEW_ADDRESS_INPUT,
  payload: { input, index },
});
export const googlePlaceSelected: ActionCreator = (
  index: number,
  placeId: string,
  from?: string
) => ({ type: actions.GOOGLE_PLACE_SELECTED, payload: { index, placeId, from } });
export const reuseLocationChanged: ActionCreator = (reuse: boolean) =>
  reuse
    ? { type: actions.REUSE_LOCATION_CHECKED, payload: null }
    : { type: actions.REUSE_LOCATION_UNCHECKED, payload: null };
export const reuseOnsiteContactsChanged: ActionCreator = (reuse: boolean) =>
  reuse
    ? { type: actions.REUSE_ONSITE_CONTACTS_CHECKED, payload: null }
    : { type: actions.REUSE_ONSITE_CONTACTS_UNCHECKED, payload: null };
export const saveShiftsAndContinue: ActionCreator = basicCreator(actions.SAVE_SHIFTS_AND_CONTINUE);
export const clearInvalidMSAMessage = basicCreator(actions.CLEAR_INVALID_MSA_MESSAGE);
export const clearNoZipcodeMessage = basicCreator(actions.CLEAR_NO_ZIPCODE_MESSAGE);
export const cancelShift = basicCreator(actions.CANCEL_SHIFT);
export const addReportToContact: ActionCreator = (index: number, contactIndex: number) => ({
  type: actions.ADD_REPORT_TO_CONTACT,
  payload: { index, contactIndex },
});
export const reportToContactChanged: ActionCreator = (
  index: number,
  contactIndex: number,
  contact: any
) => ({ type: actions.REPORT_TO_CONTACT_UPDATED, payload: { index, contactIndex, contact } });
export const removeReportToContact: ActionCreator = (index: number, contactIndex: number) => ({
  type: actions.REMOVE_REPORT_TO_CONTACT,
  payload: { index, contactIndex },
});

export function shiftFormIsInvalid(shift: ShiftFormType, isEventIndustrial: boolean) {
  if (isEventIndustrial) {
    return !isEmpty(omit(shift.errorMessages, ['countValue']));
  }
  return !isEmpty(shift.errorMessages);
}

export function allShiftFormsAreValid(shifts: ShiftFormType[], isEventIndustrial: boolean) {
  for (let shift of shifts) {
    if (shiftFormIsInvalid(shift, isEventIndustrial)) {
      return false;
    }
  }
  if (shifts.length === 0) {
    return false;
  }

  return true;
}

// Reducers
// --------------------------------

function shiftMappingReducer(
  updateFn: (ShiftFormType, any) => ShiftFormType
): (ShiftFormStateType, Object) => ShiftFormStateType {
  return function (state: ShiftFormStateType, payload: any): ShiftFormStateType {
    const newShifts = state.shifts
      .map((shift, idx) => {
        if (payload && idx === payload.index) {
          return updateFn(shift, payload);
        } else {
          return shift;
        }
      })
      .map(addErrorsToShift);

    return {
      ...state,
      shifts: newShifts,
      valid: allShiftFormsAreValid(newShifts, state.isEventIndustrial),
    };
  };
}

function addShiftsReducer(state: ShiftFormStateType, payload): ShiftFormStateType {
  let newShift;
  let wasValidated;
  let valid;
  if (get(payload, 'shift') && payload.shift) {
    newShift = payload.shift;
    // Remember the previous status of form validation as it was before the shift was copied
    wasValidated = state.wasValidated;
    valid = state.valid;
  } else {
    newShift = new ShiftForm();
    wasValidated = false;
    valid = false;
  }

  if (state.reuseLocation) {
    newShift.eventLocation = state.shifts[0].eventLocation;
  }
  if (state.reuseOnsiteContacts) {
    newShift.reportToContacts = state.shifts[0].reportToContacts;
  }
  return {
    ...state,
    shifts: [...state.shifts, newShift],
    wasValidated,
    valid,
  };
}

function removeShiftsReducer(state, payload): ShiftFormStateType {
  let newShifts = state.shifts.filter((shift, idx) => idx !== payload.index);
  return {
    ...state,
    shifts: newShifts,
    valid: allShiftFormsAreValid(newShifts.map(addErrorsToShift)),
  };
}

const fieldChangeReducer = shiftMappingReducer(
  (shift, payload: { name: string, [key: string]: any }) => {
    if (payload.name.includes('contractInfo.')) {
      shift.contractInfo[payload.name.split('.')[1]] = payload.newValue;
    } else if (['name', 'details'].includes(payload.name)) {
      shift.eventLocation[payload.name] = payload.newValue;
    } else {
      shift[payload.name] = payload.newValue;
    }
    return shift;
  }
);

function validateFormReducer(state, payload): ShiftFormStateType {
  if (payload.pageNum === 2 || includes(payload.pageNum, 2)) {
    const shiftsWithErrorMessages = state.shifts.map(addErrorsToShift);

    return {
      ...state,
      shifts: shiftsWithErrorMessages,
      wasValidated: true,
      valid: allShiftFormsAreValid(shiftsWithErrorMessages),
    };
  } else {
    return state;
  }
}

const newAddressInputReducer = shiftMappingReducer((shift, { input }) => {
  shift.eventLocation = { ...shift.eventLocation, formattedAddress: input };
  shift.isSearching = input.length >= 3;
  return shift;
});

const resultsReceivedReducer = shiftMappingReducer((shift, { results }) => {
  return {
    ...shift,
    addressSearchResults: results,
  };
});

function placeDetailsSuccessReducer(state, { index, result, from }): ShiftFormStateType {
  if (from == 'post-location') return state;

  let updatedShifts = state.shifts
    .map((shift, idx) => {
      if (index === idx || state.reuseLocation) {
        shift.eventLocation = {
          ...shift.eventLocation,
          ...result,
          id: null,
          isSearching: false,
        };
      }
      return shift;
    })
    .map(addErrorsToShift);
  return {
    ...state,
    shifts: updatedShifts,
    valid: allShiftFormsAreValid(updatedShifts),
  };
}

function reuseCheckReducer(state, isChecked): ShiftFormStateType {
  const { eventLocation } = state.shifts[0];
  const newShifts = state.shifts
    .map((shift) => {
      shift.eventLocation = eventLocation;
      shift.addressSearchResults = [];
      return shift;
    })
    .map(addErrorsToShift);

  return {
    ...state,
    shifts: newShifts,
    valid: allShiftFormsAreValid(newShifts),
    reuseLocation: isChecked,
  };
}

function reuseOnsiteContactsCheckReducer(state, isChecked): ShiftFormStateType {
  const { reportToContacts } = state.shifts[0];
  const newShifts = state.shifts
    .map((shift) => {
      shift.reportToContacts = reportToContacts;
      return shift;
    })
    .map(addErrorsToShift);

  return {
    ...state,
    shifts: newShifts,
    valid: allShiftFormsAreValid(newShifts),
    reuseOnsiteContacts: isChecked,
  };
}

function invalidMSAReducer(state, { index, from }): ShiftFormStateType {
  const stateWithInvalidMSAFlag = {
    ...state,
    invalidLocationSelected: true,
  };
  return placeDetailsSuccessReducer(stateWithInvalidMSAFlag, {
    index,
    from,
    result: newEventLocation(),
  });
}

function noZipcodeReducer(state, { index, from }): ShiftFormStateType {
  const stateWithNoZipcodeFlag = {
    ...state,
    noZipcodeSelected: true,
  };
  return placeDetailsSuccessReducer(stateWithNoZipcodeFlag, {
    index,
    from,
    result: newEventLocation(),
  });
}

function updateWithSuccessDetailsReducer(state, payload): ShiftFormStateType {
  return {
    ...state,
    shifts: state.shifts.map((shift, idx) => {
      return {
        ...shift,
        id: payload[idx].id,
        emergencyActionPlanId: payload[idx].emergencyActionPlanId,
        eventLocation: {
          ...shift.eventLocation,
          id: payload[idx].eventLocation.id,
        },
      };
    }),
    inProgress: false,
  };
}

function eapSuccessReducer(state, payload): ShiftFormStateType {
  return {
    ...state,
    shifts: state.shifts.map((shift) => {
      if (shift.eventLocation.id === payload.eventLocation.id) {
        shift.emergencyActionPlanId = payload.id;
      }
      return shift;
    }),
  };
}

function initShiftsReducer(state, event): ShiftFormStateType {
  const newState = new ShiftFormState();
  newState.eventId = event.id;
  const forms = map(event.shifts.byId, (shift) => {
    const startTime = moment.parseZone(shift.startTime);
    const endTime = moment.parseZone(shift.endTime);
    const form = new ShiftForm();
    const timeAttrs = {
      start: startTime.format('YYYY-MM-DD HH:mm'),
      end: endTime.format('YYYY-MM-DD HH:mm'),
      capacity: shift.shiftCapacity,
      jobs: Object.values(shift.jobs.byId || []),
    };
    Object.assign(
      form,
      cloneDeep(
        pick(shift, [
          'id',
          'eventLocation',
          'emergencyActionPlanId',
          'reportToContacts',
          'startTime',
          'endTime',
          'shiftCapacity',
          'countType',
          'countValue',
          'schedule',
          'approxHours',
          'notes',
          'responsibilities',
          'contractInfo',
        ])
      ),
      timeAttrs
    );
    return form;
  });

  newState.shifts = forms;
  newState.valid = true;

  if (isEmpty(forms)) {
    forms.push(new ShiftForm());
    newState.valid = false;
  }

  return newState;
}

function updatedJobReducer(state, payload): ShiftFormStateType {
  return {
    ...state,
    shifts: state.shifts.map((form) => {
      return {
        ...form,
        jobs: form.jobs.map((job) => {
          if (job.id === payload.id) {
            return payload;
          } else {
            return job;
          }
        }),
      };
    }),
  };
}

function reloadFormReducer(state, form) {
  let newState = { ...state };

  if (form.shifts) {
    newState['shifts'] = form.shifts.map((shift) => {
      let result = {
        errorMessages: [],
      };

      const attrsToPick = ['id', 'eventLocation', 'emergencyActionPlanId'];

      Object.assign(result, pick(shift, attrsToPick), {
        end: moment.parseZone(shift.endTime).format('kk:mm'),
        start: moment.parseZone(shift.startTime).format('kk:mm'),
        date: moment.parseZone(shift.startTime).format('YYYY-MM-DD'),
        capacity: shift.shiftCapacity,
      });

      return result;
    });
  }

  return newState;
}

const cancelShiftReducer = shiftMappingReducer((form, payload) => ({
  ...form,
  cancelled: true,
  cancelReason: payload.cancelReason,
}));

function duplicateAllEapSuccessRedudcer(state, payload) {
  return {
    ...state,
    shifts: payload.payload.shifts,
  };
}

const removeReportToContactReducer = (state, payload) => {
  const updateFn = (shift, { contactIndex }) => {
    let reportToContacts: ReportToContact[] = [...shift.reportToContacts],
      contact: ReportToContact = reportToContacts[contactIndex];

    if (!!contact.id) {
      reportToContacts.splice(contactIndex, 1, ({ _destroy: true, id: contact.id }: any));
    } else {
      reportToContacts = reportToContacts.filter((val, i) => i !== contactIndex);
    }

    return { ...shift, reportToContacts };
  };

  if (state.reuseOnsiteContacts) {
    const newShifts = state.shifts.map((s) => updateFn(s, payload)).map(addErrorsToShift);
    return {
      ...state,
      shifts: newShifts,
      valid: allShiftFormsAreValid(newShifts),
    };
  }

  return shiftMappingReducer(updateFn)(state, payload);
};

const addReportToContactReducer = (state, payload) => {
  const updateFn = (shift) => ({
    ...shift,
    reportToContacts: [...shift.reportToContacts, new ReportToContact()],
  });

  if (state.reuseOnsiteContacts) {
    const newShifts = state.shifts.map(updateFn).map(addErrorsToShift);
    return {
      ...state,
      shifts: newShifts,
      valid: allShiftFormsAreValid(newShifts),
    };
  }

  return shiftMappingReducer(updateFn)(state, payload);
};

const reportToContactChangedReducer = (state, payload) => {
  const updateFn = (shift, { contactIndex, contact }) => ({
    ...shift,
    reportToContacts: shift.reportToContacts.map((c, i) => {
      if (i === contactIndex) {
        return Object.assign({}, c, contact);
      } else {
        return c;
      }
    }),
  });

  if (state.reuseOnsiteContacts) {
    const newShifts = state.shifts.map((s) => updateFn(s, payload)).map(addErrorsToShift);
    return {
      ...state,
      shifts: newShifts,
      valid: allShiftFormsAreValid(newShifts),
    };
  }

  return shiftMappingReducer(updateFn)(state, payload);
};

function duplicateEvent(state, payload) {
  const { event } = payload;
  const newState = new ShiftFormState();
  newState.eventId = event.id;

  const forms = map(event.shifts.byId, (shift) => {
    const form = new ShiftForm();
    const attr = {
      capacity: shift.shiftCapacity,
      start: null,
      end: null,
    };
    Object.assign(
      form,
      cloneDeep(
        pick(shift, [
          'eventLocation',
          'shiftCapacity',
          'countType',
          'countValue',
          'schedule',
          'approxHours',
          'notes',
          'responsibilities',
          'contractInfo',
        ])
      ),
      attr
    );

    return addErrorsToShift(form);
  });

  newState.shifts = forms;
  newState.valid = false;

  return newState;
}

// Main Reducer
// --------------------------------

export default function (
  state: ShiftFormStateType = new ShiftFormState(),
  action: Action
): ShiftFormStateType {
  switch (action.type) {
    case eapActions.DUPLICATE_ALL_EAP_SUCCESS:
      return duplicateAllEapSuccessRedudcer(state, action.payload);
    case INIT_FORM:
      return initShiftsReducer(state, action.payload);
    case actions.ADD_SHIFT:
      return addShiftsReducer(state, action.payload);
    case actions.REMOVE_SHIFT:
      return removeShiftsReducer(state, action.payload);
    case actions.FIELD_CHANGE:
      return fieldChangeReducer(state, action.payload);
    case VALIDATE_FORM:
      return validateFormReducer(state, action.payload);
    case CLEAR_FORM:
      return new ShiftFormState();
    case actions.ADDRESS_TYPEAHEAD_RESULTS_RECEIVED:
      return resultsReceivedReducer(state, action.payload);
    case actions.PLACE_DETAILS_SUCCESS:
      return placeDetailsSuccessReducer(state, action.payload);
    case actions.REUSE_LOCATION_CHECKED:
      return reuseCheckReducer(state, true);
    case actions.REUSE_LOCATION_UNCHECKED:
      return reuseCheckReducer(state, false);
    case actions.REUSE_ONSITE_CONTACTS_CHECKED:
      return reuseOnsiteContactsCheckReducer(state, true);
    case actions.REUSE_ONSITE_CONTACTS_UNCHECKED:
      return reuseOnsiteContactsCheckReducer(state, false);
    case actions.NEW_ADDRESS_INPUT:
      return newAddressInputReducer(state, action.payload);
    case actions.INVALID_MSA_SELECTED:
      return invalidMSAReducer(state, action.payload);
    case actions.NO_ZIPCODE_SELECTED:
      return noZipcodeReducer(state, action.payload);
    case actions.CLEAR_INVALID_MSA_MESSAGE:
      return { ...state, invalidLocationSelected: false };
    case actions.CLEAR_NO_ZIPCODE_MESSAGE:
      return { ...state, noZipcodeSelected: false };
    case SAVE_DETAILS_AND_CONTINUE_SUCCESS:
      const isEventIndustrial = action.payload.sport === 'Industrial';
      return { ...state, eventId: action.payload.id, isEventIndustrial };
    case actions.SAVE_SHIFTS_AND_CONTINUE:
      return { ...state, inProgress: true };
    case actions.SAVE_SHIFTS_AND_CONTINUE_ERROR:
      return { ...state, inProgress: false };
    case actions.SAVE_SHIFTS_AND_CONTINUE_SUCCESS:
      return updateWithSuccessDetailsReducer(state, action.payload);
    case CREATE_EAP_SUCCESS:
      return eapSuccessReducer(state, action.payload);
    case UPDATE_JOB_SUCCESS:
    case UPDATE_JOB_V2_SUCCESS:
      return updatedJobReducer(state, action.payload);
    case actions.CANCEL_SHIFT:
      return cancelShiftReducer(state, action.payload);
    case RELOAD_FORM_SUCCESS:
      return reloadFormReducer(state, action.payload);
    case actions.ADD_REPORT_TO_CONTACT:
      return addReportToContactReducer(state, action.payload);
    case actions.REMOVE_REPORT_TO_CONTACT:
      return removeReportToContactReducer(state, action.payload);
    case actions.REPORT_TO_CONTACT_UPDATED:
      return reportToContactChangedReducer(state, action.payload);
    case DUPLICATE_EVENT:
      return duplicateEvent(state, action.payload);
    default:
      return state;
  }
}
