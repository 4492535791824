import HorizontalRule from 'components/HorizontalRule';
import React from 'react';

const LatePostAlert = () => {
  return (
    <div className="late-post-alert nimbus-regular-font">
      <div className="title text-white bg-danger text-center">LATE POSTING ALERT</div>
      <HorizontalRule dashed />
      <div className="info-label gotham-bold-font">This job has shifts that start in 14 days</div>
      <div className="info">
        Shifts starting in 14 days are considered a <b>late posting</b> and may be harder to fill.
        We suggest posting at the high end of your budget to attract applicants.
      </div>
    </div>
  );
};

export default LatePostAlert;
