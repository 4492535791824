import React from 'react';
import LocationListItem from './LocationListItem';
import { shiftsByLocation } from 'helpers/post';

const LocationList = (props) => {
  const { locations, shifts, ...locationProps } = props;

  return (
    <ul className="location-list">
      {locations.map((location, idx) => {
        return (
          <LocationListItem
            key={`location-item-${idx}`}
            location={location}
            shifts={shiftsByLocation(shifts, location)}
            open={locations.length === 1}
            idx={idx}
            {...locationProps}
          />
        );
      })}
    </ul>
  );
};

export default LocationList;
