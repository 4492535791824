import React, { Component } from 'react';

import { Link } from "react-router-dom";
import { connect } from 'react-redux';
import { error } from 'redux/ducks/Flash';
import { getAllMedicalRecordWaivers } from 'redux/ducks/MedicalRecordWaivers';
import { openMedicalRecord } from 'redux/ducks/MedicalRecord';
import { Table } from 'antd';

const moment = require('moment');

export class MedicalRecordWaiverList extends Component {
  componentDidMount() {
    this.props.getAllMedicalRecordWaivers()
  }

  componentWillReceiveProps(newProps) {
    if (newProps.error && !this.props.error) {
      this.props.onError('An error occurred when loading the medical record waivers list');
    }
  }

  getMedicalRecordSignUrl = (id) => {
    this.props.getMedicalRecordSignUrl(id);
  }

  onClickShare = (e, id) => {
    e.preventDefault();

    this.props.share(id);
  }

  render() {
    const waivers = this.props.medicalRecordWaivers;

    const role = this.props.user.role.toLowerCase();

    const columns = [{
      key: 'id',
      title: 'ID',
      dataIndex: 'id',
      render: (text, record) =>
        <Link to={`/${role}/medical-record-waivers/${record.id}`}>
          {record.id}
        </Link>
    }, {
      key: 'event',
      title: 'Event Title',
      dataIndex: 'eventTitle',
    }, {
      key: 'injuryDate',
      title: 'Injury Date',
      dataIndex: 'injuryDate',
      render: (text, record) => moment(record.injuryDate || record.createdAt).format('MMMM D, YYYY')
    }, {
      title: 'Action',
      key: 'action',
      render: (text, record) => {
        const completed = record.status === "completed";
        return (<>
          <Link to={`/${role}/medical-record-waivers/${record.id}`} className={`ml-2 btn xs-btn-primary-xs ${completed ? "btn-primary" : "btn-primary btn-danger"}`}>
            {
              completed ?
                "VIEW AUTHORIZATION"
                :
                "SIGN AUTHORIZATION"
            }
          </Link>
          <Link onClick={e => completed && this.onClickShare(e, record.medicalRecordId)} to={`/${role}/medical-records/${record.medicalRecordId}`} className={`ml-2 btn xs-btn-primary-xs ${completed ? "btn-primary btn-danger" : "btn-primary btn-secondary disabled"}`}>
            VIEW/SHARE RECORD
          </Link>
        </>);
      }
    }]

    return (
      <div className="medicalRecordWaiverList">
        <h2>Medical Record Dashboard</h2>
        <p>A HIPAA Authorization form is required to view or share each medical record.</p>
        {(!waivers.length && !this.props.error) && <h3>Loading medical record waivers</h3>}
        {waivers.length > 0 && !this.props.error &&
          <Table
            columns={columns}
            rowKey="id"
            dataSource={waivers}
            pagination={false} />
        }
      </div>
    )
  }
}

function mapDispatchToProps(dispatch) {
  return {
    getAllMedicalRecordWaivers: () => dispatch(getAllMedicalRecordWaivers()),
    onError: (message) => dispatch(error(message)),
    share: (id) => dispatch(openMedicalRecord(id)),
  };
}

function mapStateToProps(state, ownProps) {
  const medicalRecordWaivers = state.medicalRecordWaivers.medicalRecordWaivers;

  return {
    medicalRecordWaivers,
    error: state.medicalRecordWaivers.error,
    user: state.session.currentUser
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(MedicalRecordWaiverList);
