export const SHOW_SPINNER = 'go4Ellis/LoadingSpinner/SHOW';
export const HIDE_SPINNER = 'go4Ellis/LoadingSpinner/HIDE';

export default (state=false, {type}) => {
  if (type === SHOW_SPINNER) {
    return true;
  } else if (type === HIDE_SPINNER) {
    return false;
  } else {
    return state;
  }
}