import React, { Component } from "react";
import { ContactService } from "../../../services";
import HomeContactConfirmation from "../../../components/HomeContactConfirmation";
import DatePicker from "react-datepicker";
import clsx from "classnames";
import StandardRed from "../../../components/UI/Button/StandardRed";

const intialState = {
  date: "",
  location: "",
  phone: "",
  name: "",
  email: "",
  jobDescription: "",
  showErrorMessage: false,
  showConfirmation: false,
};

class NurseslContactForm extends Component {
  state = intialState;

  handleChange = (e) => {
    const { value, name } = e.target;
    this.setState({ [name]: value, [`${name}Error`]: false });
  };

  handleDateChange = (date) => {
    this.setState({ date, dateError: false });
  };

  handleSubmit = (e) => {
    e.preventDefault();
    const { date, location, phone, name, email, jobDescription } = this.state;
    let allValid = true;
    if (!date) {
      allValid = false;
      this.setState({ dateError: true });
    }
    if (!location) {
      allValid = false;
      this.setState({ locationError: true });
    }
    if (!phone) {
      allValid = false;
      this.setState({ phoneError: true });
    }
    if (!name) {
      allValid = false;
      this.setState({ nameError: true });
    }
    if (!this.validateEmail(email)) {
      allValid = false;
      this.setState({ emailError: true });
    }
    if (allValid) {
      const payload = { date, location, phone, name, email, jobDescription };
      new ContactService().post(payload);
      this.setState({ ...intialState, showConfirmation: true });
    } else this.setState({ showErrorMessage: true });
  };

  validateEmail(email) {
    let re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  }

  render() {
    const {
      date,
      location,
      phone,
      name,
      email,
      jobDescription,
      dateError,
      locationError,
      phoneError,
      nameError,
      emailError,
      showErrorMessage,
      showConfirmation,
    } = this.state;
    if (showConfirmation)
      return (
        <HomeContactConfirmation message="Your info has been submitted. An account rep with follow up with you shorty to help you get connected with nurses" />
      );
    return (
      <div className="nursesContactForm">
        <div className="header">FIND NURSES</div>
        <form className="body">
          <input
            type="text"
            name="name"
            className={nameError ? "error" : ""}
            value={name}
            placeholder="Name"
            onChange={this.handleChange}
          />
          <input
            type="email"
            name="email"
            className={emailError ? "error" : ""}
            value={email}
            placeholder="Email"
            onChange={this.handleChange}
          />
          <input
            type="phone"
            name="phone"
            className={phoneError ? "error" : ""}
            value={phone}
            placeholder="Phone"
            onChange={this.handleChange}
          />
          <DatePicker
            selected={date}
            className={clsx("w-100", { error: dateError })}
            placeholderText="Date Needed"
            name="date"
            onChange={this.handleDateChange}
            onSelect={this.handleDateChange}
            closeOnScroll={() => true}
            minDate={new Date()}
            popperPlacement="bottom-start"
            showPopperArrow={false}
          />
          <input
            className={locationError ? "error" : ""}
            type="text"
            name="location"
            value={location}
            placeholder="CITY/STATE/ZIP"
            onChange={this.handleChange}
          />
          <textarea
            type="text"
            name="jobDescription"
            value={jobDescription}
            placeholder="Job Description"
            onChange={this.handleChange}
          />
          <StandardRed
            buttonType="large"
            className="submit-button w-100 m-0"
            onClick={this.handleSubmit}
            type="submit"
          >
            Submit
          </StandardRed>
          {showErrorMessage && (
            <span className="error-message">
              One or more fields have an error. Please check and try again.
            </span>
          )}
        </form>
      </div>
    );
  }
}

export default NurseslContactForm;
