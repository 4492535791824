import React from "react";
import HomeList from "../../../components/HomeList";
import SectionBanner from "../../../components/SectionBanner";

const list = [
  /*
  "HEALTHCARE SYSTEMS",
  "OFFICE BUILDINGS",
  "HOSPITALITY",
  "BANKS",
  "RETAIL",
  "MANUFACTURING",
  "DISTRIBUTION CENTERS",
  "FOOD PRODUCTION",
  "TRANSPORTATION",
  */
  "Schools", ,
  "Hospitals", ,
  "Long Term Care",
  "Surgicenter",
  "Infusion Clinics",
  "Summer Camps",
  "Ambulatory Care",
  "Urgent Care",
  "Outpatient Facilities",
];
const Settings = () => {
  return (
    <section className="settings">
      <SectionBanner text="Settings" />
      <HomeList
        maxThree
        uppercase
        center
        mobileSingleColumn
        largerText
        list={list}
      />
    </section>
  );
};

export default Settings;
