import React from "react";
import { Link } from "react-router-dom";

const BackToListButton = () => (
  <div className="backToListButton">
    <Link to={"/careers"}>BACK TO JOB LIST</Link>
  </div>
);

export default BackToListButton;
