import validator from 'validator';
import { trim } from 'lodash';
import { type Location } from '.';

// Validators
// -------------------------------

const validateReportToContact = (reportToContact) => {
  if (reportToContact._destroy) {
    return {};
  }
  let messages = {};
  if (!trim(reportToContact.name)) {
    messages.name = 'Contact name is required';
  }
  if (!trim(reportToContact.email)) {
    messages.email = 'Contact email is required';
  }
  if (!trim(reportToContact.phoneNumber)) {
    messages.phone = 'Contact phone is required';
  } else if (!validator.isMobilePhone(reportToContact.phoneNumber)) {
    messages.phone = 'Phone number is not valid';
  }
  return messages;
};

export const addErrorsToLocation = (location: Location) => {
  let messages = {};
  if (!trim(location.name)) {
    messages.name = 'Venue Name is required';
  }
  if (!(location.id || location.placeId)) {
    messages.address = 'You must select a place from the list of results';
  }
  if (!trim(location.formattedAddress)) {
    messages.address = 'Address is required';
  }

  if (Number(location.countValue) < 1 || !location.countValue) {
    messages.countValue = 'This field is required';
  }

  if (location.reportToContacts.length === 0) {
    messages.emptyReportToContacts = 'You need at least one contact';
  }
  let reportToContactsErrors = [];
  let hasError = false;
  for (let i = 0; i < location.reportToContacts.length; ++i) {
    let error = validateReportToContact(location.reportToContacts[i]);
    reportToContactsErrors.push(error);
    if (Object.keys(error).length !== 0) {
      hasError = true;
    }
  }
  if (hasError) {
    messages.reportToContacts = reportToContactsErrors;
  }

  return {
    ...location,
    errorMessages: messages,
  };
};
