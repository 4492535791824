// @flow
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { startsWith } from 'lodash';

import type { Enums } from 'redux/ducks/helpers';
import type { EventDetailsFormType } from 'redux/ducks/CreateEvent/Details';

import { deleteAttachedDocument } from 'redux/ducks/CreateEvent/Details';
import { rateTypeNameFinder } from 'helpers/rateTypeName';

import {
  multiSelectOptionsForEnum,
  optionsForEnum,
  getSettingDetail,
  getJobDescription,
  getSettingName,
  getEnumName,
  sportsFilter,
} from 'helpers/enums';
import Select from 'react-select';
import AverageRatesModal from 'components/AverageRatesModal';
import { isContractEo } from 'helpers/contracts';
import ContractsPayRange from './components/ContractsPayRange';

const domain = process.env.REACT_APP_DOMAIN;

type DetailsFormProps = {
  details: EventDetailsFormType,
  onFieldChange: (name: string, newValue: string | ArrayBuffer | File | Array<File>) => void,
  enums: Enums,
};

export class EventDetailsForm extends Component<DetailsFormProps> {
  fileReader = (() => {
    const reader = new FileReader();
    reader.addEventListener('load', () => {
      this.props.onFieldChange('imagePreview', reader.result);
    });
    return reader;
  })();

  componentDidMount() {
    if (isContractEo(this.props.user.email)) {
      this.props.onFieldChange('eventProfessionId', '0');
      this.props.onFieldChange('settingDetailId', '58');
      this.props.onFieldChange('jobDescriptionIds', ['0']);
    }
  }

  onFieldChange = (name: string) => (ev: SyntheticInputEvent<HTMLInputElement>) => {
    this.props.onFieldChange(name, ev.currentTarget.value);
  };

  urlFor(previewUrl: string) {
    return startsWith(previewUrl, '/') ? domain + previewUrl : previewUrl;
  }

  onEventImageChange = (event: SyntheticInputEvent<HTMLInputElement>) => {
    const target = event.target;
    const file = target.files[0];
    this.fileReader.readAsDataURL(file);
    this.props.onFieldChange('eventLogo', file);
  };

  onAddDocument = (event: SyntheticInputEvent<HTMLInputElement>) => {
    const target = event.target;
    if (target.files && target.files.length > 0) {
      const file = target.files[0];
      this.props.onFieldChange('documents', this.props.details.documents.concat([file]));
    }
  };

  onMultiSelectChangeJobDescriptions = (e) => {
    let items = [];
    e.map((it) => items.push(it.value));
    this.props.onFieldChange('jobDescriptionIds', items);
  };

  onMultiSelectChangeAgeBrackets = (e) => {
    let items = [];
    e.map((it) => items.push(it.value));
    this.props.onFieldChange('ageBracketIds', items);
  };

  onDeleteDocument = (event: SyntheticInputEvent<HTMLInputElement>, document: File) => {
    event.preventDefault();
    this.props.onFieldChange(
      'documents',
      this.props.details.documents.filter((d) => d !== document)
    );
  };

  onDeleteAttachedDocument = (event: SyntheticInputEvent<HTMLInputElement>, documentId: string) => {
    event.preventDefault();
    this.props.deleteDocument(documentId);
  };

  onSettingChange = (ev: SyntheticInputEvent<HTMLInputElement>) => {
    let value = ev.currentTarget.value;
    this.props.onFieldChange('eventSettingId', value);
    this.props.onFieldChange('settingDetailId', '');
    this.props.onFieldChange('jobDescriptionIds', []);
    if (value !== 'Sports') this.props.onFieldChange('sportId', '');
  };

  onSettingDetailsChange = (ev: SyntheticInputEvent<HTMLInputElement>) => {
    let value = ev.currentTarget.value;
    this.props.onFieldChange('settingDetailId', value);
    if (value !== 'Sporting Event') this.props.onFieldChange('sportId', '');
  };

  getJobDescriptionsByIds = (jobs, jobIds) => {
    let filteredItems = [];
    if (!!jobIds)
      jobIds.map((item) => {
        let job = jobs.find(({ id }) => id == item) || [{ value: '', label: '' }];
        filteredItems.push({ value: job.id, label: job.name });
      });
    return filteredItems;
  };

  getAgeBracketsByIds = (ageBrackets, ageBracketIds) => {
    let filteredItems = [];
    if (!!ageBracketIds)
      ageBracketIds.map((item) => {
        let ageBracket = ageBrackets.find(({ id }) => id == item) || [{ value: '', label: '' }];
        filteredItems.push({ value: ageBracket.id, label: ageBracket.name });
      });
    return filteredItems;
  };

  render() {
    const { details, enums, user } = this.props;
    const eventSettingOptions = optionsForEnum(enums.eventSettings);
    const settingDetailOptions = optionsForEnum(getSettingDetail(details.eventSettingId));
    const jobDescriptionOptions = multiSelectOptionsForEnum(
      getJobDescription(details.eventSettingId, details.settingDetailId)
    );
    const eventParticipantNumbersOptions = optionsForEnum(enums.eventParticipantNumbers);
    const sportOptions = optionsForEnum(sportsFilter(enums.sports));
    const genderOptions = optionsForEnum(enums.genders);
    const ageBracketOptions = multiSelectOptionsForEnum(this.props.enums.ageBrackets);

    return (
      <form
        id="detailsForm"
        noValidate
        ref="form"
        className={details.wasValidated ? 'was-validated' : ''}
      >
        {!isContractEo(user.email) && (
          <div className="row">
            <div className="form-group col-md-6">
              <label htmlFor="event_setting">Setting</label>
              <select
                className="form-control custom-select"
                name="event_setting"
                value={details.eventSettingId}
                onChange={this.onSettingChange}
                required
              >
                {eventSettingOptions}
              </select>
              <div className="invalid-feedback">Setting is required</div>
            </div>
            <div className="form-group col-md-6">
              <label htmlFor="setting_detail">Setting Detail</label>
              <select
                className="form-control custom-select"
                name="setting_detail"
                value={details.settingDetailId}
                onChange={this.onSettingDetailsChange}
                required
              >
                {settingDetailOptions}
              </select>
              <div className="invalid-feedback">Setting Detail is required</div>
            </div>
          </div>
        )}
        <div className="row">
          <div className="form-group col-sm-12">
            <label htmlFor="title">{isContractEo(user.email) ? 'Job Name' : 'Name of Event'}</label>
            <input
              className="form-control"
              type="text"
              name="title"
              value={details.title}
              onChange={this.onFieldChange('title')}
              required
              maxLength="255"
            />
            <div className="invalid-feedback">
              {isContractEo(user.email) ? 'Title' : 'Name'} is required
            </div>
          </div>
        </div>
        {isContractEo(user.email) && (
          <div className="row">
            <div className="form-group col-sm-12">
              <label htmlFor="contractInfo.organization_name">Organization Name</label>
              <input
                className="form-control"
                type="text"
                name="contractInfo.organization_name"
                value={details.contractInfo?.organization_name || ''}
                onChange={this.onFieldChange('contractInfo.organization_name')}
                required
                maxLength="255"
              />
              <div className="invalid-feedback">Organization Name</div>
            </div>
          </div>
        )}
        {!isContractEo(user.email) && (
          <div className="row">
            {details.eventProfessionId == 0 && (
              <div className="form-group col-md-6">
                <label htmlFor="event_participant_numbers">Estimated Number of Participants</label>
                <select
                  className="form-control custom-select"
                  name="event_participant_numbers"
                  value={details.eventParticipantNumbersId}
                  onChange={this.onFieldChange('eventParticipantNumbersId')}
                  required={details.eventProfessionId == 0}
                >
                  {eventParticipantNumbersOptions}
                </select>
                <div className="invalid-feedback">Estimated Number of Participants is required</div>
              </div>
            )}
            {getSettingName(details.eventSettingId) == 'Sports' &&
              (details.eventProfessionId == '0' ||
                (details.eventProfessionId == '1' &&
                  getEnumName(this.props.enums.settingDetails, details.settingDetailId) ==
                    'Sporting Event')) && (
                <div className="form-group col-md-6">
                  <label htmlFor="sport">Sport</label>
                  <select
                    className="form-control custom-select"
                    name="sport"
                    value={details.sportId}
                    onChange={this.onFieldChange('sportId')}
                    required
                  >
                    {sportOptions}
                  </select>
                  <div className="invalid-feedback">Sport is required</div>
                </div>
              )}
          </div>
        )}
        {!isContractEo(user.email) && getSettingName(details.eventSettingId) == 'Sports' && (
          <div className="row">
            <div className="form-group col-md-6">
              <label htmlFor="ageBracketId">Age</label>
              <input
                name="ageBracket"
                style={{ opacity: 0, height: 0 }}
                value={details.ageBracketIds}
                required
              />
              <Select
                isMulti
                name="ageBracket"
                placeholder="Select all that apply"
                onChange={this.onMultiSelectChangeAgeBrackets}
                value={this.getAgeBracketsByIds(
                  this.props.enums.ageBrackets,
                  details.ageBracketIds
                )}
                options={ageBracketOptions}
                classNamePrefix="select"
              />
              <div className="invalid-feedback">Age is required</div>
            </div>
            <div className="form-group col-md-6">
              <label htmlFor="gender">Gender</label>
              <select
                className="form-control custom-select"
                name="gender"
                value={details.genderId}
                onChange={this.onFieldChange('genderId')}
                required
              >
                {genderOptions}
              </select>
              <div className="invalid-feedback">Gender is required</div>
            </div>
          </div>
        )}
        {!isContractEo(user.email) && (
          <div className="row">
            <div className="form-group col-md-6">
              <label htmlFor="jobDescription">Job Description</label>
              <input
                name="jobDescription"
                style={{ opacity: 0, height: 0 }}
                value={details.jobDescriptionIds || []}
                required
              />
              <Select
                isMulti
                name="jobDescription"
                placeholder="Select all that apply"
                onChange={this.onMultiSelectChangeJobDescriptions}
                value={this.getJobDescriptionsByIds(
                  this.props.enums.jobDescriptions,
                  details.jobDescriptionIds
                )}
                options={jobDescriptionOptions}
                classNamePrefix="select"
              />
              <div className="invalid-feedback">Job Description is required</div>
            </div>
          </div>
        )}
        {!isContractEo(user.email) && (
          <div className="row">
            <div className="form-group col-md-6">
              <label htmlFor="title">Event code / optional</label>
              <input
                className="form-control"
                type="text"
                name="eventCode"
                value={details.eventCode}
                onChange={this.onFieldChange('eventCode')}
                maxLength="255"
              />
            </div>
          </div>
        )}
        <hr />
        {isContractEo(user.email) && (
          <ContractsPayRange
            details={details}
            rateTypes={this.props.rateTypes}
            onFieldChange={this.onFieldChange}
          />
        )}
        {!isContractEo(user.email) && (
          <div className="row pt-2">
            <div className="col-md-3">
              <div>
                <div>
                  <label htmlFor="rate_type">Please select a Pay Rate</label>
                </div>
                <div className="custom-control custom-radio custom-control-inline">
                  <input
                    className="custom-control-input"
                    id="HourlyRateType"
                    type="radio"
                    name="rate_type"
                    value="0"
                    checked={details.rateTypeId === '0'}
                    onChange={this.onFieldChange('rateTypeId')}
                  />
                  <label htmlFor="HourlyRateType" className="custom-control-label radio-inline">
                    Hourly Rate
                  </label>
                </div>
                <div className="custom-control custom-radio custom-control-inline">
                  <input
                    className="custom-control-input"
                    id="GameRateType"
                    type="radio"
                    name="rate_type"
                    value="1"
                    checked={details.rateTypeId === '1'}
                    onChange={this.onFieldChange('rateTypeId')}
                  />
                  <label htmlFor="GameRateType" className="custom-control-label radio-inline">
                    Flat Rate
                  </label>
                </div>
              </div>
            </div>
            <div className="form-group col-md-3">
              <label htmlFor="pay_rate">Pay Rate</label>
              <div className="input-group mb-3">
                <div className="input-group-prepend">
                  <span className="input-group-text">$</span>
                </div>
                <input
                  className="form-control"
                  type="number"
                  name="pay_rate"
                  min="1"
                  max="100000"
                  step="1"
                  aria-label="Amount (to the nearest dollar)"
                  value={details.payRate}
                  placeholder="0"
                  onChange={this.onFieldChange('payRate')}
                  required
                />
                <div className="input-group-append">
                  <span className="input-group-text">
                    {rateTypeNameFinder(this.props.rateTypes, details.rateTypeId)}
                  </span>
                </div>
                <div className="invalid-feedback">
                  Pay rate is required to be between $0-$100,000
                </div>
              </div>
            </div>
            <div className="col-md-6 align-self-center">
              <AverageRatesModal />
            </div>
          </div>
        )}
        <hr style={{ margin: '0 0 2rem' }} />
        {!isContractEo(user.email) && (
          <div className="row">
            <div className="col-sm-12">
              <p className="step-description" style={{ width: '100%' }}>
                Onboarding Documents
              </p>
              <label>
                Add onboarding instructions and attachments. The healthcare provider(s) will receive
                these materials via email once they are confirmed for the job.
              </label>
            </div>
          </div>
        )}
        {!isContractEo(user.email) && (
          <div className="row">
            <div
              className="col-md-6 col-sm-6 file-input-col"
              style={{ paddingLeft: 0, marginTop: 10 }}
            >
              <div className="form-group col-md-12">
                <label htmlFor="instructions">Instructions</label>
                <textarea
                  className="form-control"
                  style={{ width: '70%', minHeight: '120px', border: '1px solid #b9b6ba' }}
                  type="text"
                  name="instructions"
                  value={details.instructions}
                  rows={6}
                  onChange={this.onFieldChange('instructions')}
                />
              </div>
              <div className="file-input-box col-sm-12 m-2 p-2" style={{ width: '70%' }}>
                <input
                  className="custom-file-input"
                  type="file"
                  name="event_documents"
                  accept="*"
                  onChange={this.onAddDocument}
                />
                <label className="btn btn-primary custom-file-label" htmlFor="event_documents">
                  Upload Documents
                </label>
              </div>
            </div>
            <div className="col-md-9 col-sm-6">
              {this.props.details.existingDocuments &&
                this.props.details.existingDocuments.map((document) => (
                  <div className="py-2" key={document.id}>
                    <i className="fas fa-file-alt px-2"></i>
                    <a
                      className="px-2"
                      target="_blank"
                      rel="noopener noreferrer"
                      href={document.url}
                    >
                      {document.filename}
                    </a>
                    <button
                      className="btn btn-primary heading-font px-2"
                      onClick={(e) => this.onDeleteAttachedDocument(e, document.id)}
                    >
                      Delete
                    </button>
                  </div>
                ))}
              {this.props.details.documents.map((document, i) => (
                <div className="py-2" key={i}>
                  <i className="fas fa-file-alt px-2"></i>
                  <span className="px-2">{document.name}</span>
                  <button
                    className="btn btn-primary heading-font px-2"
                    onClick={(e) => this.onDeleteDocument(e, document)}
                  >
                    Delete
                  </button>
                </div>
              ))}
            </div>
          </div>
        )}
      </form>
    );
  }
}

function mapStateToProps(state) {
  const enums = state.enums;
  return {
    rateTypes: state.enums.rateTypes,
    rateTypeId: state.createEvent.details.rateTypeId,
    enums,
    user: state.session.currentUser,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    deleteDocument: (documentId) => dispatch(deleteAttachedDocument(documentId)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(EventDetailsForm);
