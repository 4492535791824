import ApiService from './ApiService';

import { StripeSerializer } from 'services/serializers';

class StripeService extends ApiService {
  constructor(session, userId) {
    super(session);

    this.url = `/users/${userId}/source.json`;
  }

  get() {
    return super.get(`${this.url}`,
      (response) => StripeSerializer.deserialize(response));
  }

  update(token) {
    return super.patch(`${this.url}`, { source: { id: token.id } },
      (response) => response ? StripeSerializer.deserialize(response) : null);
  }
}

export default StripeService;