import React from 'react';

const ExistingCreditCard = ({ account }) => {
  if (!account || !account.id || account.object !== 'card') return null;

  return (
    <div className="align-items-center card-details">
      <p>Card Number: **** **** **** {account.last4}</p>
      <p>
        Expiry date: {account.expMonth} / {account.expYear}
      </p>
    </div>
  );
};

export default ExistingCreditCard;
