class ReportToContactSerializer {
  static serialize({ id, email, name, phoneNumber, _destroy }) {
    return {
      id,
      email,
      name,
      phone_no: phoneNumber ?? '',
      _destroy,
    }
  }

  static deserialize({ id, email, name, phone_no }) {
    return {
      id,
      email,
      name,
      phoneNumber: phone_no ?? ''
    }
  }
}

export default ReportToContactSerializer;