import React, { Component } from "react";
import { SeminarContactServiceService } from "../../services";
import HomeContactConfirmation from "../HomeContactConfirmation";
import FormErrorMessage from "../FormErrorMessage";

const SeminarContactForm = () => (
  <div className="seminarContactForm">
    <div className="header">Get Soccer Safety Content</div>
    <form
      action="https://go4ellis.us19.list-manage.com/subscribe/post?u=0fb0f6e3c58ccc7b17ab112c8&amp;id=3a3440ff03"
      method="post"
      id="mc-embedded-subscribe-form"
      name="mc-embedded-subscribe-form"
      class="validate body"
      target="_blank"
      novalidate
    >
      <input type="email" name="EMAIL" placeholder="Email" />
      <input type="text" name="FNAME" placeholder="First Name" />
      <input type="text" name="LNAME" placeholder="Last Name" />
      <div class="role-radio">
        <strong>Role </strong>
        <ul>
          <li>
            <input
              type="radio"
              value="Parent"
              name="MMERGE5"
              id="mce-MMERGE5-0"
            />
            <label for="mce-MMERGE5-0">Parent</label>
          </li>
          <li>
            <input
              type="radio"
              value="Coach"
              name="MMERGE5"
              id="mce-MMERGE5-1"
            />
            <label for="mce-MMERGE5-1">Coach</label>
          </li>
          <li>
            <input
              type="radio"
              value="Tournament Operator"
              name="MMERGE5"
              id="mce-MMERGE5-2"
            />
            <label for="mce-MMERGE5-2">Tournament Operator</label>
          </li>
        </ul>
      </div>
      <button type="submit">Submit</button>
    </form>
  </div>
);

export default SeminarContactForm;
