import ApiService from "./ApiService";

import { JobsAvailableSerializer } from "services/serializers";

class JobsAvailableService extends ApiService {
  constructor(session) {
    super(session);

    this.url = "/shifts";
  }

  get(id) {
    return super.get(`${this.url}/${id}.json`, (response) =>
      JobsAvailableSerializer.deserialize(response)
    );
  }

  getAll({ state, city }) {
    return super.get(
      `${this.url}.json?state=${state ? state.toUpperCase() : ""}&city=${
        city ? city.toUpperCase() : ""
      }`,
      (response) =>
        response.map((item) => JobsAvailableSerializer.deserialize(item))
    );
  }
}

export default JobsAvailableService;
