import ApiService from './ApiService';

import { ContactSerializer } from 'services/serializers';

class ContactService extends ApiService {
  url = '/contact';

 post(formData) {
    return super.post(this.url, ContactSerializer.serialize(formData),
      (response) => ContactSerializer.deserialize(response));
  }
}

export default ContactService;