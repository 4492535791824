// @flow
import React from 'react';
import { Link } from 'react-router-dom';
import classnames from 'classnames';

type ButtonRowProps = {
  saveAsDraft: Function,
  continueDisabled: boolean,
  saveAndContinue: Function,
  saveAndContinueButtonText: string,
  skip: Function,
  cancel: Function,
};

const ButtonRow = ({ saveAsDraft, duplicateDisabled = false, continueDisabled, skip, saveAndContinue, duplicateEap, saveAndContinueButtonText = "Save and Continue", cancel = () => { } }: ButtonRowProps) => {
  return (
    <div className="buttonRow">
      <div className="button-row">
        <Link className="secondary col-md-1 link-button" to="/dashboard" onClick={cancel}>Cancel</Link>
        {saveAsDraft && !continueDisabled &&
          <Link className="link-button secondary col-md-2"
            to="/dashboard" onClick={saveAsDraft}>Save as Draft</Link>}
        {skip &&
          <button className="btn btn-link link-button secondary col-md-2"
            onClick={skip}>Skip</button>}
        <div className="col"></div>

        {duplicateEap &&
          <button className={
            classnames(
              "btn btn-info bold-button col-md-4",
              { disabled: duplicateDisabled }
            )}
            type="button"
            style={{ marginRight: '10px' }}
            onClick={duplicateEap}
          > {'Duplicate EAP to all shifts'} </button>
        }

        <button className={
          classnames(
            "btn btn-danger bold-button col-md-4",
            { disabled: continueDisabled }
          )}
          type="button"
          onClick={saveAndContinue}
        >{saveAndContinueButtonText}</button>
      </div>
    </div>
  );
}

export default ButtonRow;
