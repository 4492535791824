import { ofType } from 'redux-observable';

import { of } from 'rxjs/observable/of';
import { catchError, mergeMap, map, debounceTime, filter, mapTo } from 'rxjs/operators';

import { EventService, ShiftsService } from 'services';
import { basicEpic, repeatedEpic, loadFromStorage, mappingEpic } from 'redux/ducks/helpers';
import { SHOW_SPINNER, HIDE_SPINNER } from 'redux/ducks/LoadingSpinner';
import { get } from 'lodash';

import {
  APPLY_DRAFT_FILTERS,
  APPLY_PAST_FILTERS,
  APPLY_UPCOMING_FILTERS,
  DELETE_EVENT,
  DELETE_EVENT_SUCCESS,
  DELETE_EVENT_ERROR,
  GET_DASHBOARD_EVENTS,
  GET_DASHBOARD_EVENTS_SUCCESS,
  GET_DASHBOARD_EVENTS_ERROR,
  GET_DRAFT_EVENTS,
  GET_DRAFT_EVENTS_SUCCESS,
  GET_DRAFT_EVENTS_ERROR,
  GET_PAST_EVENTS,
  GET_PAST_EVENTS_SUCCESS,
  GET_PAST_EVENTS_ERROR,
  GET_UPCOMING_SHIFTS,
  GET_UPCOMING_SHIFTS_ERROR,
  GET_UPCOMING_SHIFTS_SUCCESS,
  GET_UPCOMING_EVENTS,
  GET_UPCOMING_EVENTS_SUCCESS,
  GET_UPCOMING_EVENTS_ERROR,
  RELOAD_DRAFT_EVENTS_SUCCESS,
  RELOAD_PAST_EVENTS_SUCCESS,
  RELOAD_UPCOMING_EVENTS_SUCCESS,
  RESET_DRAFT_FILTERS,
  RESET_PAST_FILTERS,
  RESET_UPCOMING_FILTERS,
  CHANGE_DRAFT_EVENTS_PAGE,
  CHANGE_UPCOMING_EVENTS_PAGE,
  CHANGE_UPCOMING_SHIFTS_PAGE,
  CHANGE_PAST_EVENTS_PAGE,
  EXPORT_EVENTS_SUCCESS,
  EXPORT_EVENTS_ERROR,
  EXPORT_EVENTS,
  RESET_UPCOMING_EVENTS,
} from './index';

import {
  REMOVE_ATHLETIC_TRAINER_SUCCESS,
  UPDATE_JOB_SUCCESS,
  UPDATE_JOB_V2_SUCCESS,
} from 'redux/ducks/Job';

import { RELOAD_SESSION, DESTROY_SESSION } from 'redux/ducks/Session';

import { SUCCESS_FLASH_MESSAGE, ERROR_FLASH_MESSAGE } from '../Flash';
import { isContractEo } from 'helpers/contracts';

const STORAGE_KEY = 'Go4Ellis/Events';
const DASHBOARD_STORAGE_KEY = `${STORAGE_KEY}/Dashboard`;
const DRAFT_STORAGE_KEY = `${STORAGE_KEY}/Drafts`;
const PAST_STORAGE_KEY = `${STORAGE_KEY}/Past`;
const UPCOMING_STORAGE_KEY = `${STORAGE_KEY}/Upcoming`;

// Epics
// ========================================================

export const repeatRefreshDashboardEpic = repeatedEpic(30000, GET_UPCOMING_EVENTS, {
  type: GET_UPCOMING_EVENTS,
  payload: { disableSpinner: true },
});

export const repeatRefreshUpcomingShiftsEpic = repeatedEpic(30000, GET_UPCOMING_SHIFTS, {
  type: GET_UPCOMING_SHIFTS,
  payload: { disableSpinner: true },
});

export function deleteEventEpic(action$, store) {
  return action$.pipe(
    ofType(DELETE_EVENT),
    mergeMap((action) => {
      return new EventService(store.getState().session)
        .cancelEvent(action.payload)
        .then(() => ({ type: DELETE_EVENT_SUCCESS }))
        .catch((error) => ({ type: DELETE_EVENT_ERROR, payload: error }));
    })
  );
}

export function getDashboardEventsEpic(action$, store) {
  return action$.pipe(
    ofType(GET_DASHBOARD_EVENTS),
    debounceTime(200),
    mergeMap((action) => {
      const state = store.getState();
      const service = new EventService(state.session);
      return service.getAll({
        upcoming: true,
        page: get(state, 'event.events.upcomingPagination.current_page') || 1,
        per_page: isContractEo(state.session.currentUser.email) ? 5 : 10,
      });
    }),
    map((events) => {
      try {
        localStorage.setItem(DASHBOARD_STORAGE_KEY, JSON.stringify(events));
      } catch (err) {
        console.log(err);
      }
      return { type: GET_DASHBOARD_EVENTS_SUCCESS, payload: events };
    }),
    catchError((error) => {
      return of({
        type: GET_DASHBOARD_EVENTS_ERROR,
        payload: error,
      });
    })
  );
}

export function getDraftEventsEpic(action$, store) {
  return action$.pipe(
    ofType(APPLY_DRAFT_FILTERS, GET_DRAFT_EVENTS, RESET_DRAFT_FILTERS, CHANGE_DRAFT_EVENTS_PAGE),
    debounceTime(200),
    mergeMap((action) => {
      const state = store.getState();
      const service = new EventService(state.session);

      const params = state.event.filters.draft;
      const sortParams = get(params, 'sort', {});
      const sortKey = Object.keys(sortParams).find((key) => sortParams[key]);

      return service.getAll({
        ...action.payload,
        ...params,
        page: get(state, 'event.events.draftPagination.current_page') || 1,
        per_page: isContractEo(state.session.currentUser.email) ? 5 : 10,
        order_by: `draft_${sortKey}_${sortParams[sortKey]}`,
      });
    }),
    map((events) => {
      if (events.current_page > events.total_pages && events.current_page > 1) {
        return {
          type: CHANGE_DRAFT_EVENTS_PAGE,
          payload: { page: events.total_pages },
        };
      }
      try {
        localStorage.setItem(DRAFT_STORAGE_KEY, JSON.stringify(events));
      } catch (err) {
        console.log(err);
      }
      return { type: GET_DRAFT_EVENTS_SUCCESS, payload: events };
    }),
    catchError((error) => {
      return of({
        type: GET_DRAFT_EVENTS_ERROR,
        payload: error,
      });
    })
  );
}

export function getPastEventsEpic(action$, store) {
  return action$.pipe(
    ofType(APPLY_PAST_FILTERS, GET_PAST_EVENTS, RESET_PAST_FILTERS, CHANGE_PAST_EVENTS_PAGE),
    debounceTime(200),
    mergeMap((action) => {
      const state = store.getState();
      const service = new EventService(state.session);

      const params = state.event.filters.past;
      const sortParams = get(params, 'sort', {});
      const sortKey = Object.keys(sortParams).find((key) => sortParams[key]);

      return service.getAll({
        past: true,
        ...action.payload,
        ...params,
        page: get(state, 'event.events.pastPagination.current_page') || 1,
        per_page: isContractEo(state.session.currentUser.email) ? 5 : 10,
        order_by: `past_${sortKey}_${sortParams[sortKey]}`,
      });
    }),
    map((events) => {
      try {
        localStorage.setItem(PAST_STORAGE_KEY, JSON.stringify(events));
      } catch (err) {
        console.log(err);
      }
      return { type: GET_PAST_EVENTS_SUCCESS, payload: events };
    }),
    catchError((error) => {
      return of({
        type: GET_PAST_EVENTS_ERROR,
        payload: error,
      });
    })
  );
}

export function getUpcomingEventsEpic(action$, store) {
  return action$.pipe(
    ofType(
      APPLY_UPCOMING_FILTERS,
      GET_UPCOMING_EVENTS,
      RESET_UPCOMING_FILTERS,
      UPDATE_JOB_SUCCESS,
      UPDATE_JOB_V2_SUCCESS,
      REMOVE_ATHLETIC_TRAINER_SUCCESS,
      CHANGE_UPCOMING_EVENTS_PAGE
    ),
    debounceTime(200),
    mergeMap((action) => {
      const state = store.getState();
      const service = new EventService(state.session);

      const params = state.event.filters.upcoming;
      const sortParams = get(params, 'sort', {});
      const sortKey = Object.keys(sortParams).find((key) => sortParams[key]);

      return service.getAll({
        upcoming: true,
        page: get(state, 'event.events.upcomingPagination.current_page') || 1,
        ...action.payload,
        ...params,
        per_page: isContractEo(state.session.currentUser.email) ? 5 : 10,
        order_by: `upcoming_${sortKey}_${sortParams[sortKey]}`,
      });
    }),
    map((events) => {
      try {
        localStorage.setItem(UPCOMING_STORAGE_KEY, JSON.stringify(events));
      } catch (err) {
        console.log(err);
      }
      return { type: GET_UPCOMING_EVENTS_SUCCESS, payload: events };
    }),
    catchError((error) => {
      return of({
        type: GET_UPCOMING_EVENTS_ERROR,
        payload: error,
      });
    })
  );
}

export function getUpcomingShiftsEpic(action$, store) {
  return action$.pipe(
    ofType(
      APPLY_UPCOMING_FILTERS,
      RESET_UPCOMING_FILTERS,
      UPDATE_JOB_SUCCESS,
      UPDATE_JOB_V2_SUCCESS,
      GET_UPCOMING_SHIFTS,
      CHANGE_UPCOMING_SHIFTS_PAGE
    ),
    debounceTime(200),
    mergeMap((action) => {
      const state = store.getState();
      const service = new ShiftsService(state.session);

      return service.index({
        upcoming: true,
        ...action.payload,
        ...state.event.filters.upcoming,
        page: get(state, 'event.eventsGroupedByShifts.upcomingPagination.current_page') || 1,
        per_page: isContractEo(state.session.currentUser.email) ? 5 : 10,
      });
    }),
    map((events) => {
      try {
        localStorage.setItem(UPCOMING_STORAGE_KEY, JSON.stringify(events));
      } catch (err) {
        console.log(err);
      }
      return { type: GET_UPCOMING_SHIFTS_SUCCESS, payload: events };
    }),
    catchError((error) => {
      return of({
        type: GET_UPCOMING_SHIFTS_ERROR,
        payload: error,
      });
    })
  );
}

export const reloadDraftEventsEpic = mappingEpic(RELOAD_SESSION, {
  type: RELOAD_DRAFT_EVENTS_SUCCESS,
  payload: loadFromStorage(DRAFT_STORAGE_KEY),
});

export const reloadPastEventsEpic = mappingEpic(RELOAD_SESSION, {
  type: RELOAD_PAST_EVENTS_SUCCESS,
  payload: loadFromStorage(PAST_STORAGE_KEY),
});

export const reloadUpcomingEventsEpic = mappingEpic(RELOAD_SESSION, {
  type: RELOAD_UPCOMING_EVENTS_SUCCESS,
  payload: loadFromStorage(UPCOMING_STORAGE_KEY),
});

export const exportEventsEpic = basicEpic(EXPORT_EVENTS, (_payload, state) =>
  new EventService(state.session).export(state.event.filters.past)
);

export const showFlashSuccessExportEpic = mappingEpic(EXPORT_EVENTS_SUCCESS, {
  type: SUCCESS_FLASH_MESSAGE,
  payload: {
    message:
      'Your request has been received. We will send you a report via email. This may take a few minutes. Thank you!',
  },
});

export const showFlashErrorExportEpic = mappingEpic(EXPORT_EVENTS_ERROR, {
  type: ERROR_FLASH_MESSAGE,
  payload: {
    message: 'There has been an error. Please try again. If the problem persist, contact us!',
  },
});

export const showSpinnerEpic = (action$) =>
  action$.pipe(
    ofType(
      GET_DRAFT_EVENTS,
      GET_PAST_EVENTS,
      GET_UPCOMING_EVENTS,
      GET_UPCOMING_SHIFTS,
      APPLY_DRAFT_FILTERS,
      RESET_DRAFT_FILTERS,
      APPLY_PAST_FILTERS,
      RESET_PAST_FILTERS,
      APPLY_UPCOMING_FILTERS,
      RESET_UPCOMING_FILTERS,
      CHANGE_PAST_EVENTS_PAGE,
      CHANGE_DRAFT_EVENTS_PAGE,
      CHANGE_UPCOMING_EVENTS_PAGE,
      CHANGE_UPCOMING_SHIFTS_PAGE,
      DELETE_EVENT
    ),
    filter((action) => {
      if (action.payload) {
        return !action.payload.disableSpinner;
      } else {
        return true;
      }
    }),
    mapTo({ type: SHOW_SPINNER })
  );

export const hideSpinnerEpic = mappingEpic(
  [
    GET_DRAFT_EVENTS_SUCCESS,
    GET_DRAFT_EVENTS_ERROR,
    GET_PAST_EVENTS_SUCCESS,
    GET_PAST_EVENTS_ERROR,
    GET_UPCOMING_EVENTS_SUCCESS,
    GET_UPCOMING_EVENTS_ERROR,
    GET_UPCOMING_SHIFTS_SUCCESS,
    GET_UPCOMING_SHIFTS_ERROR,
    EXPORT_EVENTS_SUCCESS,
    EXPORT_EVENTS_ERROR,
  ],
  HIDE_SPINNER
);

export const resetEpic = mappingEpic([DESTROY_SESSION], RESET_UPCOMING_EVENTS);
export const reloadDraftEventsOnDeleteEpic = mappingEpic([DELETE_EVENT_SUCCESS], GET_DRAFT_EVENTS);
