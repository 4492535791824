import React, { Component } from 'react';
import { AVAILABLE_EQUIPMENTS } from 'helpers/post';
import { AddButton } from 'components/V2/Buttons';
import HorizontalRule from 'components/HorizontalRule';

class EmergencyEquipment extends Component {
  equipmentTypeOptions = (equipment) =>
    [...AVAILABLE_EQUIPMENTS, equipment.name].map((name) => (
      <option key={Math.floor(Math.random() * 1e6)} value={name}>
        {name}
      </option>
    ));

  onRemove = (equipmentIndex) => () => {
    this.props.removeEquipment(equipmentIndex);
  };

  onNameChange = (idx) => (ev) => this.props.onChange(idx, { name: ev.currentTarget.value });

  onLocationChange = (idx) => (ev) => {
    ev.preventDefault();
    this.props.onChange(idx, { location: ev.currentTarget.value });
  };

  renderMobileEquipmentListItem = ({ equipment, idx }) => {
    return (
      <div key={`mobile-eq-${idx}`} className="eq-body-mobile">
        <div className="input-group col-12 px-0">
          {!AVAILABLE_EQUIPMENTS.includes(equipment.name) ? (
            <input
              key={`eq-name-${idx}`}
              type="text"
              name="equipment-name"
              placeholder="Other supplies & equipment"
              value={equipment.name || ''}
              onChange={this.onNameChange(idx)}
              className="form-control italic-placeholder"
            />
          ) : (
            <select
              key={`eq-name-${idx}`}
              name="equipment-name"
              className="custom-select bold"
              value={equipment.name || ''}
              onChange={this.onNameChange(idx)}
              disabled
            >
              {this.equipmentTypeOptions(equipment)}
            </select>
          )}
        </div>
        <div className="form-group location-input col-12 px-0">
          <input
            key={`eq-location-${idx}`}
            name="equipment-location"
            type="text"
            placeholder="On-Site Location"
            value={equipment.location || ''}
            onChange={this.onLocationChange(idx)}
            className="form-control italic-placeholder"
          />
        </div>
        <div className="col-12 px-0">
          <AddButton onClick={this.onRemove(idx)}>Delete</AddButton>
        </div>
        <HorizontalRule dashed />
      </div>
    );
  };

  renderEquipmentListItem = ({ equipment, idx }) => {
    return (
      <div key={idx} className="eq-body">
        <div className="eq-body-input">
          <div className="input-group col-5 pl-0">
            {!AVAILABLE_EQUIPMENTS.includes(equipment.name) ? (
              <input
                key={`eq-name-${idx}`}
                type="text"
                name="equipment-name"
                placeholder="Other supplies & equipment"
                value={equipment.name || ''}
                onChange={this.onNameChange(idx)}
                className="form-control italic-placeholder"
              />
            ) : (
              <select
                key={`eq-name-${idx}`}
                name="equipment-name"
                className="custom-select bold text-uppercase"
                value={equipment.name || ''}
                onChange={this.onNameChange(idx)}
                disabled
              >
                {this.equipmentTypeOptions(equipment)}
              </select>
            )}
          </div>
          <div className="form-group location-input col-6">
            <input
              key={`eq-location-${idx}`}
              name="equipment-location"
              type="text"
              placeholder="On-Site Location"
              value={equipment.location || ''}
              onChange={this.onLocationChange(idx)}
              className="form-control italic-placeholder"
            />
          </div>
          <div className="col-1 px-0 delete-button gotham-regular-font">
            <AddButton onClick={this.onRemove(idx)}>Delete</AddButton>
          </div>
        </div>
        <HorizontalRule dashed />
      </div>
    );
  };

  render() {
    const { equipmentList } = this.props;
    return (
      <div className="emergency-equipment nimbus-regular-font">
        <div className="section-header gotham-bold-font col">Supplies & Equipment</div>
        <p className="sub-text">
          Finalize the list of supplies and equipment provided for this job, along with the
          locations of each item.
        </p>
        {equipmentList.map((equipment, idx) => {
          if (equipment._destroy) return null;

          return (
            <React.Fragment key={idx}>
              {this.renderMobileEquipmentListItem({ equipment, idx })}
              {this.renderEquipmentListItem({ equipment, idx })}
            </React.Fragment>
          );
        })}
        <div className="actions gotham-regular-font">
          <AddButton className="add" onClick={() => this.props.addEquipment('')}>
            Add Equipment
          </AddButton>
        </div>
      </div>
    );
  }
}

export default EmergencyEquipment;
