import React, { Component } from 'react';
import { parse } from 'query-string';

class ConfimationScene extends Component {

  componentWillMount() {
    document.body.classList.add('confirmation-scene');
  }

  componentWillUnmount() {
    document.body.classList.remove('confirmation-scene');
  }

  render() {
    const params = parse(this.props.location.search);

    console.log(params);
    let errors = params["full_messages[]"];

    let alreadyConfirmed = false;
    if (errors) {
      if (errors instanceof Array) {
        alreadyConfirmed = errors.some(x => x.includes("was already verified"));
      } else {
        alreadyConfirmed = errors.includes("was already verified");
      }
    }

    if (errors && errors instanceof Array) {
      errors = errors.map(error => <p>{error}</p>);
    }

    const title = params.account_confirmation_success === 'true'
      ? "You have successfully verified your account."
      : errors;

    const button_text = alreadyConfirmed ? "LOGIN TO MY ACCOUNT" : params.button_text;
    const button_link = alreadyConfirmed ?
        (params.user_type === 'AthleticTrainer' ? `${process.env.REACT_APP_DEEPLINK_URL}` : "/v2/login")
        : params.button_link;

    return (
      <div className="confirmationScene">
        <h1>{title}</h1>
        <a href={button_link} className="btn btn-primary px-4">
          {button_text}
        </a>
      </div>
    );
  }
}

export default ConfimationScene;
