import ApiService from './ApiService';
import { EventSerializer, UserSerializer } from './serializers';

class EventService extends ApiService {
  constructor(session) {
    super(session);

    this.url = '/events';
    this.eventOperatorId = session.currentUser.id
  }

  get(id) {
    return super.get(`${this.url}/${id}.json`,
      (event) => EventSerializer.deserialize(event, event.athletic_trainers));
  }

  getAll(params) {
    return super.search(`${this.url}.json`, params,
      (response) => {
        return {
          total: response.total,
          per_page: response.per_page,
          total_pages: response.total_pages,
          current_page: response.current_page,
          events: response.events.map(event => EventSerializer.deserialize(event, response.athletic_trainers)),
          athletic_trainers: response.athletic_trainers.map(at => UserSerializer.deserializeHCT(at)),
        }
      });
  }

  deleteDocument({ eventId, documentId }) {
    return super.delete(`${this.url}/${eventId}/documents/${documentId}.json`, () => ({ eventId, documentId }));
  }

  create(event) {
    return super.post(`${this.url}.json`, EventSerializer.serialize(event, this.eventOperatorId),
      (event) => EventSerializer.deserialize(event, event.athletic_trainers));
  }

  update(event) {
    return super.patch(`${this.url}/${event.id || event.details.id}.json`, EventSerializer.serialize(event, this.eventOperatorId),
      (event) => EventSerializer.deserialize(event, event.athletic_trainers));
  }

  createOrUpdate(event) {
    if (!!event.details.id) {
      return this.update(event);
    } else {
      return this.create(event);
    }
  }

  cancelEvent(event) {
    return super.delete(`${this.url}/${event.id}.json?reason=${event.reason || ""}`, () => true);
  }

  export(params) {
    return super.search(`${this.url}/export.json`, params, () => true);
  }
}

export default EventService;