import React, { Component } from 'react';
import './Tip.scss';

class Tip extends Component {
  render() {
    return (
      <div className={`tip col-md-auto nimbus-regular-font`}>
        <div className="tip-title">
          <p>TIP</p>
        </div>
        <p className="tip-description">{this.props.text}</p>
      </div>
    );
  }
}

export default Tip;
