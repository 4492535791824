import classNames from 'classnames';
import React from 'react';
import { Card, CardBody, CardHeader, Collapse } from 'reactstrap';
import { ShiftDetails, ShiftDetailsMobile } from '../ShiftDetails';
import { formattedLocation } from 'helpers/post';

const LocationListItem = (props) => {
  const [open, setOpen] = React.useState(props.open);
  const { location, shifts, idx, editMode, history } = props;

  return (
    <li className="location-list-item nimbus-regular-font" key={`li-${idx}`}>
      <Card>
        <CardHeader
          className={`location-item-title`}
          onClick={() => setOpen(!open)}
        >
          <i
            className={classNames('fas arrow', {
              'fa-chevron-down': open,
              'fa-chevron-right': !open,
            })}
          ></i>
          Location {idx + 1}:{' '}
          <span className="location-name">{formattedLocation(location)}</span>
        </CardHeader>
        <Collapse isOpen={open}>
          <CardBody className={`location-item-body`}>
            {shifts.map((shift, mapIdx) => {
              return (
                <React.Fragment key={`card-body-${mapIdx}`}>
                  <ShiftDetails
                    shifts={shifts}
                    shift={shift}
                    idx={shift.idx}
                    onDeleteShift={props.onDeleteShift}
                    editMode={editMode}
                    history={history}
                  />
                  <ShiftDetailsMobile
                    shifts={shifts}
                    shift={shift}
                    idx={shift.idx}
                    onDeleteShift={props.onDeleteShift}
                    editMode={editMode}
                    history={history}
                  />
                </React.Fragment>
              );
            })}
          </CardBody>
        </Collapse>
      </Card>
    </li>
  );
};

export default LocationListItem;
