import { basicCreator } from 'redux/ducks/helpers';

const constForName = (name) => `go4ellis/Post/Shifts/${name}`;
export const SHIFT_ADDED = constForName('SHIFT_ADDED');
export const SHIFT_REMOVED = constForName('SHIFT_REMOVED');
export const SHIFT_FIELD_CHANGED = constForName('SHIFT_FIELD_CHANGED');
export const SHIFT_CHANGED = constForName('SHIFT_CHANGED');
export const REMOVE_MULTIPLE_SHIFTS = constForName('REMOVE_MULTIPLE_SHIFTS');
export const REPEAT_SHIFTS = constForName('REPEAT_SHIFTS');
export const DISTRIBUTE_RATE = constForName('DISTRIBUTE_RATE');

export const DELETE_SHIFT = constForName('DELETE_SHIFT');
export const DELETE_SHIFT_SUCCESS = constForName('DELETE_SHIFT_SUCCESS');
export const DELETE_SHIFT_ERROR = constForName('DELETE_SHIFT_ERROR');

export const REMOVE_LOCATION = constForName('REMOVE_LOCATION');
export const REMOVE_LOCATION_SUCCESS = constForName('REMOVE_LOCATION_SUCCESS');
export const REMOVE_LOCATION_ERROR = constForName('REMOVE_LOCATION_ERROR');

export const addShift = basicCreator(SHIFT_ADDED);
export const removeShift = basicCreator(SHIFT_REMOVED);
export const changeShiftField = basicCreator(SHIFT_FIELD_CHANGED);
export const changeShift = basicCreator(SHIFT_CHANGED);
export const repeatShifts = basicCreator(REPEAT_SHIFTS);
export const distributeShiftRate = basicCreator(DISTRIBUTE_RATE);
export const removeMultipleShifts = basicCreator(REMOVE_MULTIPLE_SHIFTS);

export const deleteShift = basicCreator(DELETE_SHIFT);
export const removeLocation = basicCreator(REMOVE_LOCATION);
