import React, { Component } from 'react';
import { groupBy, map } from 'lodash';
import moment from 'moment';

class ShiftDetailsTable extends Component {
  formattedTime(time) {
    return moment(time).format('M/D/Y LT');
  }
  render() {
    const { shifts } = this.props;
    return (
      <table className="shiftDetailsTable">
        <thead>
          <tr>
            <th>Time</th>
            <th>Location</th>
            <th>Staff Needed</th>
            <th>Emergency Action Plan</th>
          </tr>
        </thead>
        <tbody>
          {shifts.map((shift, idx) =>
            <tr key={idx}>
              <td style={{ textAlign: 'left' }}>
                {this.formattedTime(shift.start)} - {this.formattedTime(shift.end)}
                {shift.schedule && <p><strong>Schedule: </strong><div style={{ whiteSpace: 'pre-wrap' }}>{shift.schedule}</div></p>}
              </td>
              <td className="location-cell">
                {shift.eventLocation.formattedAddress.replace(', USA', '')}
                <br />
                {shift.eventLocation.name}{shift.eventLocation.details && ` / ${shift.eventLocation.details}`}
              </td>
              <td>{shift.capacity}</td>
              <td>{shift.emergencyActionPlanId ? 'Complete' : <span className="text-danger">Not Optimized</span>}</td>
            </tr>
          )}
        </tbody>
      </table>
    );
  }
}

export default ShiftDetailsTable;