import React from "react";
import { Link } from "react-router-dom";
import clsx from "classnames";

const StandardRed = ({
  asLink,
  className,
  href,
  asAnchor,
  children,
  to,
  buttonType,
  ...rest
}) => {
  if (asLink) {
    return (
      <Link
        className={clsx("standardRedButton", buttonType, className)}
        to={to}
        {...rest}
      >
        {children}
      </Link>
    );
  }
  if (asAnchor) {
    return (
      <a
        className={clsx("standardRedButton", buttonType, className)}
        href={href}
        {...rest}
      >
        {children}
      </a>
    );
  }
  return (
    <button
      className={clsx("standardRedButton", buttonType, className)}
      {...rest}
    >
      {children}
    </button>
  );
};

export default StandardRed;
