import { SERVICE_URL } from 'services/ApiService';
import { startsWith } from 'lodash';

export default class ReviewSerializer {
  static deserialize(response) {
    const result = {
      id: response.id,
      eventTitle: response.event_title,
      ATId: response.at_id,
      ATName: response.at_name,
      EOName: response.eo_name,
      shiftTime: response.shift_times,
      location: response.location.name,
      shiftDate: response.shift_date,
    };

    if (response.at_profile_pic) {
      result['ATPhoto'] = response.at_profile_pic.includes('s3.amazonaws.com')
        ? response.at_profile_pic
        : !startsWith(response.at_profile_pic, SERVICE_URL)
        ? `${SERVICE_URL}${response.at_profile_pic}`
        : response.at_profile_pic;
    }
    return result;
  }

  static serialize(review) {
    const result = {
      eo_additional_feedback: review.additionalFeedback,
      eo_rating: review.rating,
    };
    return result;
  }
}
