import VenueMap from 'components/V2/VenueMap';
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import {
  addContact,
  addEquipment,
  clearContacts,
  clearEquipment,
  contactUpdated,
  equipmentUpdated,
  fetchFireDeptSuccess,
  fetchHospitalSuccess,
  fetchPoliceSuccess,
  mapUpdated,
  removeContact,
  removeEquipment,
  fieldChange,
} from 'redux/ducks/Post/EAP/actions';
import EmergencyServices from 'scenes/CreateEventScene/CreateEventStep3/EAPForm/EmergencyServices';
import ContactList from './ContactList';
import EmergencyEquipment from './EmergencyEquipment';
import HorizontalRule from 'components/HorizontalRule';
import FacilityInstruction from './FacilityInstruction';
import { isEmpty, get } from 'lodash';

const GenerateEAP = (props) => {
  const { form, wasValidated, shiftLocation } = props;
  const FIELDS_TO_SYNC = [
    { from: 'facilityInformation', to: 'facilityInformation' },
    { from: 'ambulanceAccessPoint', to: 'ambulanceAccessPoint' },
    { from: 'weatherPlans', to: 'weatherPlans' },
  ];

  useEffect(() => {
    if (!form.facilityInformation && !!form.instructions) {
      props.dispatch(fieldChange({ name: 'facilityInformation', value: form.instructions }));
    }
  }, []);

  const syncFacilityInformation = () => {
    const { supplies } = props.shiftLocation;
    FIELDS_TO_SYNC.forEach(({ from, to }) => {
      if (!form[to] && !!supplies[from]) {
        props.dispatch(fieldChange({ name: to, value: supplies[from] }));
      }
    });
  };

  const syncJobContacts = () => {
    const { shiftLocation } = props;
    const { reportToContacts } = shiftLocation;
    if (!reportToContacts.length) return;

    // Remove empty contacts from emergency action plan
    const contacts = form.emergencyContacts.reduce((acc, contact) => {
      if (['name', 'phoneNumber', 'role'].every((field) => isEmpty(get(contact, `${field}`, ''))))
        return contact.id ? [...acc, { ...contact, _destroy: true }] : acc;

      return [...acc, contact];
    }, []);

    const filterExistingContacts = reportToContacts.filter((contact) => {
      return contacts.findIndex(({ name }) => name === contact.name) === -1;
    });

    filterExistingContacts.forEach((contact, index) => {
      contacts.push({
        name: contact.name,
        role: 'Job Contact',
        phoneNumber: contact.phoneNumber,
      });
    });

    props.dispatch(fieldChange({ name: 'emergencyContacts', value: contacts }));
  };

  useEffect(() => {
    if (props.loading) return;

    syncFacilityInformation();
    syncJobContacts();
  }, [props.loading]);

  const onHospitalChange = (hospital) => {
    props.dispatch(fetchHospitalSuccess({ hospital }));
  };

  const onFireDeptChange = (fireDepartment) => {
    props.dispatch(fetchFireDeptSuccess({ fireDepartment }));
  };

  const onPoliceChange = (policeStation) => {
    props.dispatch(fetchPoliceSuccess({ policeStation }));
  };

  const onMapLoad = (map) => {
    props.dispatch(mapUpdated({ map }));
  };
  const updateVenueMapName = (venueMap) =>
    props.dispatch(fieldChange({ name: 'venueMapFileName', value: venueMap }));
  const onAddContact = () => props.dispatch(addContact());
  const onRemoveContact = (index) => props.dispatch(removeContact({ index }));
  const onChangeContact = (index, contact) => props.dispatch(contactUpdated({ index, contact }));
  const clearAllContacts = () => props.dispatch(clearContacts());

  const onAddEquipment = (name = '') => props.dispatch(addEquipment({ name }));
  const onRemoveEquipment = (index) => props.dispatch(removeEquipment({ index }));
  const onChangeEquipment = (index, equipment) =>
    props.dispatch(equipmentUpdated({ index, equipment }));
  const clearEquipments = () => props.dispatch(clearEquipment());

  const onInstructionsChange = (ev) => {
    const { value, name } = ev.currentTarget;
    props.dispatch(fieldChange({ name, value }));
  };

  return (
    <div className="generateEAP">
      <HorizontalRule margin="20px" />
      <VenueMap
        onMapLoad={onMapLoad}
        imageSrc={form.venueMapUrl}
        updateVenueMapName={updateVenueMapName}
        fileName={form.venueMapFileName}
      />
      <HorizontalRule margin="20px" />
      <EmergencyServices
        hospital={form.hospital}
        policeStation={form.policeStation}
        fireDepartment={form.fireDepartment}
        onHospitalChange={onHospitalChange}
        onFireDeptChange={onFireDeptChange}
        onPoliceChange={onPoliceChange}
      />
      <HorizontalRule margin="20px" />
      <ContactList
        wasValidated={wasValidated}
        contacts={form.emergencyContacts}
        addContact={onAddContact}
        removeContact={onRemoveContact}
        changeContact={onChangeContact}
        clearContacts={clearAllContacts}
        shiftLocation={shiftLocation}
        updateEap={props.updateEap}
        form={form}
      />
      <HorizontalRule margin="20px" />
      <EmergencyEquipment
        wasValidated={wasValidated}
        equipmentList={form.emergencyEquipment}
        onChange={onChangeEquipment}
        addEquipment={onAddEquipment}
        removeEquipment={onRemoveEquipment}
        clearEquipment={clearEquipments}
      />
      <HorizontalRule margin="20px" />
      <div className="section-header gotham-bold-font col facility">
        Facility Specific Instructions
      </div>
      <div className="nimbus-regular-font">
        <FacilityInstruction
          field="facilityInformation"
          label="Facility Information"
          form={form}
          onChange={onInstructionsChange}
          placeHolder="Add safety instructions specific to the facility/venue."
        />

        <HorizontalRule margin="10px" dashed />

        <FacilityInstruction
          field="ambulanceAccessPoint"
          label="Ambulance Access Point"
          form={form}
          onChange={onInstructionsChange}
          placeHolder="Add ambulance access point information."
        />

        <HorizontalRule margin="10px" dashed />

        <FacilityInstruction
          field="weatherPlans"
          label="Weather Plans/Shelters"
          form={form}
          onChange={onInstructionsChange}
          placeHolder="Add weather plans or shelter information."
        />

        <HorizontalRule margin="10px" dashed />

        <FacilityInstruction
          field="otherDetails"
          label="Other"
          form={form}
          onChange={onInstructionsChange}
          placeHolder="If there is any facility info needed, add it here."
        />
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  const currentForm = state.post.eap.currentForm;
  const form = state.post.eap.forms[currentForm];
  const { locationIdx } = state.post.navigation;
  const shiftLocation = state.post.locations[locationIdx];
  const loading = !!state.post.eap.loading;

  return { forms: state.post.eap.forms, form, shiftLocation, locationIdx, loading };
};

const mapDispatchToProps = (dispatch) => ({
  dispatch,
});

export default connect(mapStateToProps, mapDispatchToProps)(GenerateEAP);
