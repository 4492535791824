
export const stateLinks = {
   'AL':'https://www.alabamainteractive.org/abat/verification/welcome.action',
   'AK': 'https://www.commerce.alaska.gov/cbp/Main/Search/Professional',
   'AZ': 'https://elicense.az.gov/ARDC_LicenseSearch',
   'AR': 'http://www.aratb.org/search.php',
   'CA': '',
   'CO': 'https://www.colorado.gov/dora/licensing/Lookup/LicenseLookup.aspx',
   'CT': 'https://www.elicense.ct.gov/',
   'DE': 'https://dpronline.delaware.gov/mylicense%20weblookup/Search.aspx',
   'FL': 'https://appsmqa.doh.state.fl.us/MQASearchServices/HealthCareProviders',
   'GA': 'http://verify.sos.ga.gov/Verification/',
   'HI': 'http://pvl.ehawaii.gov/pvlsearch/app',
   'ID': 'https://isecure.bom.idaho.gov/BOMPublic/LPRBrowser.aspx',
   'IL': 'https://ilesonline.idfpr.illinois.gov/DFPR/Lookup/LicenseLookup.aspx',
   'IN': 'http://www.in.gov/pla/3119.htm',
   'IA': 'https://ibplicense.iowa.gov/PublicPortal/Iowa/IBPL/publicsearch/publicsearch.jsp',
   'KS': 'https://www.accesskansas.org/ssrv-ksbhada/search.html',
   'KY': 'http://web1.ky.gov/gensearch/LicenseSearch.aspx?AGY=21',
   'LA': 'https://online.lasbme.org/#/verifylicense',
   'ME': 'http://pfr.informe.org/ALMSOnline/ALMSQuery/SearchIndividual.aspx?c=1',
   'MD': 'https://www.mbp.state.md.us/bpqapp/',
   'MA': 'https://elicensing.state.ma.us/CitizenAccess/_SearchaLicense.htm',
   'MI': 'https://aca-prod.accela.com/MILARA/GeneralProperty/PropertyLookUp.aspx?isLicensee=Y&TabName=APO',
   'MN': 'https://mn.gov/boards/medical-practice/public/find-practitioner/find-doctor.jsp',
   'MS': 'http://www.msdh.state.ms.us/msdhsite/_static/30,0,82.html#Verification',
   'MO': 'https://renew.pr.mo.gov/licensee-search.asp',
   'MT': 'https://ebiz.mt.gov/pol/',
   'NE': 'http://www.op.nysed.gov/opsearches.htm',
   'NV': 'http://nevadaathletictrainers.nv.gov/Licensees/Licensees/',
   'NH': 'https://newjersey.mylicense.com/verification/',
   'NJ': 'https://nhlicenses.nh.gov/MyLicense%20Verification/',
   'NM': 'http://rldverification.rld.state.nm.us/Verification/',
   'NY': 'http://at.bocatc.org/nd_licenses',
   'NC': 'https://elite.hlo.state.or.us/OHLOPublicR/LPRBrowser.aspx',
   'ND': 'http://www.okmedicalboard.org/display.php?content=md_search_advanced:md_search_advanced',
   'OH': 'https://elicense.ohio.gov/OH_HomePage',
   'OK': 'http://www.okmedicalboard.org/display.php?content=md_search_advanced:md_search_advanced',
   'OR': 'http://www.okmedicalboard.org/display.php?content=md_search_advanced:md_search_advanced',
   'PA': 'https://www.pals.pa.gov/#/page/search',
   'RI': 'https://healthri.mylicense.com/Verification/',
   'SC': 'https://scdhec.gov/health-regulation/athletic-trainer-certification',
   'SD': 'http://login.sdbmoe.gov/Public/Services',
   'TN': 'https://apps.health.tn.gov/Licensure/',
   'TX': 'https://vo.licensing.tdlr.texas.gov/datamart/login.do;jsessionid=B811B2E27794C654092482C2291ECADC.i-0f9f812973e23a73e',
   'UT': 'https://secure.utah.gov/llv/llv',
   'VT': 'https://secure.professionals.vermont.gov/prweb/PRServletCustom/V9csDxL3sXkkjMC_FR2HrA%5B%5B*/!STANDARD?UserIdentifier=LicenseLookupGuestUser',
   'VA': 'https://dhp.virginiainteractive.org/Lookup/Index',
   'WA': 'https://fortress.wa.gov/doh/providercredentialsearch/',
   'WV': 'http://www.wvbopt.com/licensesearch.cfm',
   'WI': 'http://online.drl.wi.gov/LicenseLookup/IndividualCredentialSearch.aspx',
   'WY': 'https://sites.google.com/a/wyo.gov/wyoming-board-of-athletic-training/public/license-verification',
  };
