import * as React from "react"

export const FOUR = (props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 300 300"
      shapeRendering="geometricPrecision"
      textRendering="geometricPrecision"
      {...props}
    >
      <g fill="#EA2127">
        <path d="M147.9 84.4l-58.6 81h22.8l58.7-81zM189.9 165.4h-28.6v-30.1h-20.1v30.1h-28.7v20.1h28.7v30.1h20.1v-30.1h28.6z" />
      </g>
    </svg>
  )
}

export const OUTERCIRCLE = (props) => {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 300 300"
        shapeRendering="geometricPrecision"
        textRendering="geometricPrecision"
        {...props}
      >
        <g fill="#48C1C4" stroke="#48C1C4" strokeMiterlimit={10}>
          <path d="M90.5 83.9c14.5-13 33.4-21.3 54.1-22.4v-9.9c-23.5 1.2-44.8 10.5-61.1 25.3l7 7zM83.4 209.1c-13-14.5-21.3-33.4-22.4-54.1h-9.9c1.2 23.5 10.5 44.8 25.3 61.1l7-7zM61 145c1.2-20.7 9.4-39.6 22.4-54.1l-7-7c-14.8 16.3-24.1 37.6-25.3 61.1H61zM154.6 51.6v9.9c20.7 1.2 39.6 9.4 54.1 22.4l7-7c-16.4-14.8-37.7-24.2-61.1-25.3zM215.7 90.9c13 14.5 21.3 33.4 22.4 54.1h9.9c-1.2-23.5-10.5-44.8-25.3-61.1l-7 7zM144.6 238.6c-20.7-1.2-39.6-9.4-54.1-22.4l-7 7c16.3 14.8 37.6 24.1 61.1 25.3v-9.9zM238.1 155c-1.2 20.7-9.4 39.6-22.4 54.1l7 7c14.8-16.3 24.1-37.6 25.3-61.1h-9.9zM208.7 216.1c-14.5 13-33.4 21.3-54.1 22.4v9.9c23.5-1.2 44.8-10.5 61.1-25.3l-7-7z" />
        </g>
      </svg>
    )
  }

  export const INNERCIRCLE = (props) => {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 300 300"
        shapeRendering="geometricPrecision"
        textRendering="geometricPrecision"
        {...props}
      >
        <g fill="#48C1C4">
          <path d="M75.7 67.3c18.2-16.2 41.7-26.6 67.6-28V27C114 28.4 87.4 40.1 67 58.6l8.7 8.7zM66.9 223.9c-16.2-18.2-26.6-41.7-28-67.6H26.5c1.5 29.3 13.2 55.9 31.6 76.4l8.8-8.8zM38.9 143.8c1.4-25.9 11.8-49.5 28-67.6l-8.7-8.7c-18.5 20.4-30.2 47-31.6 76.4h12.3zM155.8 27v12.3c25.9 1.4 49.5 11.8 67.6 28l8.7-8.7c-20.3-18.5-47-30.2-76.3-31.6zM232.3 76.1c16.2 18.2 26.6 41.7 28 67.6h12.3c-1.5-29.3-13.2-55.9-31.6-76.4l-8.7 8.8zM143.3 260.7c-25.9-1.4-49.5-11.8-67.6-28l-8.7 8.7c20.4 18.5 47 30.2 76.4 31.6v-12.3zM260.3 156.2c-1.4 25.9-11.8 49.5-28 67.6l8.7 8.7c18.5-20.4 30.2-47 31.6-76.4h-12.3zM223.4 232.7c-18.2 16.2-41.7 26.6-67.6 28V273c29.3-1.5 55.9-13.2 76.4-31.6l-8.8-8.7z" />
        </g>
      </svg>
    )
  }
