// @flow
import { ofType } from 'redux-observable';
import { mergeMap, bufferTime, mergeAll } from 'rxjs/operators';
import { merge } from 'rxjs/observable/merge'
import { of } from 'rxjs/observable/of';
import { GooglePlacesService, EmergencyActionPlanService } from 'services';
import { basicEpic, loadFromStorage, writeToStorage } from 'redux/ducks/helpers';
import type { AppEpic } from 'redux/ducks/helpers';
import * as actions from './actions';
import { fetchServices, fetchHospitalSuccess, fetchFireDeptSuccess, fetchPoliceSuccess } from './actions';
import { FORM_STORAGE_KEY } from 'redux/ducks/CreateEvent';
import { findIndex, get } from 'lodash';

// Epics
// -------------

export const EAPFormInitEpic: AppEpic = function EAPFormInitEpic(action$, store) {
  return action$.pipe(
    ofType(actions.EAP_FORM_INIT),
    mergeMap(action => {
      const state = store.getState();
      const service = new EmergencyActionPlanService(state.session, state.createEvent.details.id);

      return service.getAll()
        .then(emergencyActionPlans => {
          return {
            type: actions.EAP_FORM_INIT_SUCCESS,
            payload: {
              emergencyActionPlans,
              eventLocationId: get(action, 'payload.eventLocationId'),
            }
          }
        })
        .catch(error => ({ type: actions.EAP_FORM_INIT_ERROR, payload: error }))
    })
  )
}

export const afterInitFormEpic: AppEpic = function afterInitFormEpic(action$, store) {
  return action$.pipe(
    ofType(actions.EAP_FORM_INIT_SUCCESS),
    mergeMap(action => {
      const forms = store.getState().createEvent.eap.forms;
      const fetchActions = [];
      forms.forEach((form, index) => {
        if (!form.hospital.name) {
          fetchActions.push(
            fetchServices({
              index,
              coordinates: {
                lat: form.eventLocation.latitude,
                lng: form.eventLocation.longitude,
              }
            })
          );
        }
      });
      return fetchActions;
    })
  )
}

export const fetchEmergencyServicesEpic: AppEpic = function fetchEmergencyServicesEpic(action$, store) {
  return action$.pipe(
    ofType(actions.FETCH_EMERGENCY_SERVICES),
    bufferTime(500),
    mergeMap(buffer => {
      return buffer.map((action, index) => of(action).delay(index * 2000))
    }),
    mergeAll(),
    mergeMap((action) => {
      if (!document.querySelector('#attribution')) { return [] };

      const service = new GooglePlacesService(document.querySelector('#attribution'));
      const { index, coordinates } = action.payload;
      return merge(
        service
          .nearbySearch('hospital', coordinates, 'Emergency Room Hospital -psych, -anaplastology -radiology -gastroenterology -gynecology -immunology -neurology -oncology -ophthalmology -endocrinology -coloproctology -otolaryngology -proctology -toxicology -urology -cardiology', 'prominence')
          .then(results => service.getDetails(results[0].placeId))
          .then(hospital => fetchHospitalSuccess({
            index,
            hospital
          }))
          .catch(error => ({ type: actions.FETCH_NEAREST_HOSPITAL_ERROR, payload: error })),
        of(null).delay(500).mergeMap(() =>
          service
            .nearbySearch('fire_station', coordinates, 'fire department', 'distance')
            .then(results => service.getDetails(results[0].placeId))
            .then(fireDepartment => fetchFireDeptSuccess({
              index,
              fireDepartment
            }))
            .catch(error => ({ type: actions.FETCH_NEAREST_FIRE_DEPARTMENT_ERROR, payload: error }))),
        of(null).delay(1000).mergeMap(() =>
          service
            .nearbySearch('police', coordinates)
            .then(results => service.getDetails(results[0].placeId))
            .then(policeStation => fetchPoliceSuccess({
              index,
              policeStation
            }))
            .catch(error => ({ type: actions.FETCH_NEAREST_POLICE_STATION_ERROR, payload: error })))
      )
    }),
  )
}

export const createEapEpic: AppEpic = basicEpic(
  actions.CREATE_EAP,
  ({ history, goBack, form }, state) => {
    const eapState = state.createEvent.eap;
    const service = new EmergencyActionPlanService(state.session, state.createEvent.details.id);
    return service.create(form)
      .then(eap => {
        let formData = loadFromStorage(FORM_STORAGE_KEY);

        if (!formData.eap) {
          formData.eap = [];
        }

        if (formData.shifts) {
          formData.shifts = formData.shifts.map(shift => {
            if (shift.eventLocation.id === eap.eventLocation.id) {
              shift.emergencyActionPlanId = eap.id;
            }

            return shift;
          });
        }

        const cachedIndex = findIndex(formData.eap, { 'id': eap.id });

        if (cachedIndex > -1) {
          formData.eap.splice(cachedIndex, 1, eap);
        } else {
          formData.eap.push(eap);
        }

        writeToStorage(FORM_STORAGE_KEY, formData);

        if (goBack) {
          history.goBack();
        } else if (eapState.currentForm === (eapState.forms.length - 1)) {
          history.push('4');
        }
        return eap;
      })
  }
)