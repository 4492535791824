import { basicCreator } from 'redux/ducks/helpers';
// Actions
// -------------------------------------

const constForName = (name) => `go4ellis/CreateEvent/EAP/${name}`
export const EAP_FORM_INIT = constForName('EAP_FORM_INIT');
export const EAP_FORM_INIT_SUCCESS = constForName('EAP_FORM_INIT_SUCCESS');
export const EAP_FORM_INIT_ERROR = constForName('EAP_FORM_INIT_ERROR');

export const ADD_CONTACT = constForName('ADD_CONTACT');
export const REMOVE_CONTACT = constForName('REMOVE_CONTACT');
export const CLEAR_CONTACTS = constForName('CLEAR_CONTACTS');

export const ADD_EQUIPMENT = constForName('ADD_EQUIPMENT');
export const REMOVE_EQUIPMENT = constForName('REMOVE_EQUIPMENT');
export const CLEAR_EQUIPMENT = constForName('CLEAR_EQUIPMENT');

export const GENERATE_OR_UPLOAD_CHANGE = constForName("GENERATE_OR_UPLOAD_CHANGE");
export const NEW_PLAN_UPLOADED = constForName('NEW_PLAN_UPLOADED');
export const NEW_PLAN_PREVIEW = constForName('NEW_PLAN_PREVIEW');

export const DUPLICATE_ALL_EAP = constForName('DUPLICATE_ALL_EAP');
export const DUPLICATE_ALL_EAP_SUCCESS = constForName('DUPLICATE_ALL_EAP_SUCCESS');

export const SHOW_NEXT_EAP = constForName('SHOW_NEXT_EAP');
export const SHOW_PREV_EAP = constForName('SHOW_PREV_EAP');

export const MAP_UPDATED = constForName('MAP_UPDATED');
export const CONTACT_UPDATED = constForName('CONTACT_UPDATED');
export const EQUIPMENT_UPDATED = constForName('EQUIPMENT_UPDATED');
export const INSTRUCTIONS_UPDATED = constForName('INSTRUCTIONS_UPDATED');

export const FETCH_EMERGENCY_SERVICES = constForName('FETCH_EMERGENCY_SERVICES');

export const FETCH_NEAREST_HOSPITAL = constForName('FETCH_NEAREST_HOSPITAL');
export const FETCH_NEAREST_HOSPITAL_SUCCESS = constForName('FETCH_NEAREST_HOSPITAL_SUCCESS');
export const FETCH_NEAREST_HOSPITAL_ERROR = constForName('FETCH_NEAREST_HOSPITAL_ERROR');

export const FETCH_NEAREST_POLICE_STATION = constForName('FETCH_NEAREST_POLICE_STATION');
export const FETCH_NEAREST_POLICE_STATION_SUCCESS = constForName('FETCH_NEAREST_POLICE_STATION_SUCCESS');
export const FETCH_NEAREST_POLICE_STATION_ERROR = constForName('FETCH_NEAREST_POLICE_STATION_ERROR');

export const FETCH_NEAREST_FIRE_DEPARTMENT = constForName('FETCH_NEAREST_FIRE_DEPARTMENT');
export const FETCH_NEAREST_FIRE_DEPARTMENT_SUCCESS = constForName('FETCH_NEAREST_FIRE_DEPARTMENT_SUCCESS');
export const FETCH_NEAREST_FIRE_DEPARTMENT_ERROR = constForName('FETCH_NEAREST_FIRE_DEPARTMENT_ERROR');

export const CREATE_EAP = constForName('CREATE_EAP');
export const CREATE_EAP_SUCCESS = constForName('CREATE_EAP_SUCCESS');
export const CREATE_EAP_ERROR = constForName('CREATE_EAP_ERROR');

// Action Creators
// --------------------------

export const initForm = basicCreator(EAP_FORM_INIT);
export const initFormSuccess = basicCreator(EAP_FORM_INIT_SUCCESS);
export const addContact = basicCreator(ADD_CONTACT);
export const removeContact = (index: number) => ({ type: REMOVE_CONTACT, payload: { index } })
export const addEquipment = basicCreator(ADD_EQUIPMENT)
export const removeEquipment = (index: number) => ({ type: REMOVE_EQUIPMENT, payload: { index } })
export const equipmentUpdated = basicCreator(EQUIPMENT_UPDATED)
export const duplicateAllEap = basicCreator(DUPLICATE_ALL_EAP)
export const duplicateAllEapSuccess = basicCreator(DUPLICATE_ALL_EAP_SUCCESS)
export const showNextForm = basicCreator(SHOW_NEXT_EAP)
export const showPrevForm = basicCreator(SHOW_PREV_EAP)
export const fetchServices = basicCreator(FETCH_EMERGENCY_SERVICES);
export const fetchHospitals = basicCreator(FETCH_NEAREST_HOSPITAL);
export const fetchPolice = basicCreator(FETCH_NEAREST_POLICE_STATION);
export const fetchFireDept = basicCreator(FETCH_NEAREST_FIRE_DEPARTMENT);
export const fetchHospitalSuccess = basicCreator(FETCH_NEAREST_HOSPITAL_SUCCESS);
export const fetchFireDeptSuccess = basicCreator(FETCH_NEAREST_FIRE_DEPARTMENT_SUCCESS);
export const fetchPoliceSuccess = basicCreator(FETCH_NEAREST_POLICE_STATION_SUCCESS);
export const mapUpdated = basicCreator(MAP_UPDATED)
export const instructionsUpdated = basicCreator(INSTRUCTIONS_UPDATED)
export const contactUpdated = basicCreator(CONTACT_UPDATED);
export const generateOrUploadChange = basicCreator(GENERATE_OR_UPLOAD_CHANGE);
export const createEap = basicCreator(CREATE_EAP);
export const createEapSuccess = basicCreator(CREATE_EAP_SUCCESS);
export const createEapError = basicCreator(CREATE_EAP_ERROR);
export const newPlanUploaded = basicCreator(NEW_PLAN_UPLOADED);
export const newPlanPreview = basicCreator(NEW_PLAN_PREVIEW);
export const clearContacts = basicCreator(CLEAR_CONTACTS);
export const clearEquipment = basicCreator(CLEAR_EQUIPMENT);