export default class MedicalRecordSerializer {
  static deserialize(medicalRecord) {
    const data = {
      id: medicalRecord.id,
      createdById: medicalRecord.created_by_id,
      eventId: medicalRecord.event_id,
      createdAt: medicalRecord.created_at,
      updatedAt: medicalRecord.updated_at,
      eventType: medicalRecord.event_type,
      sportType: medicalRecord.sport_type,
      eventTitle: medicalRecord.event_title,
      patientInfo: medicalRecord.patient_info,
      notes: medicalRecord.notes,
      eventVenue: medicalRecord.event_venue,
      eventStreet: medicalRecord.event_street,
      eventCity: medicalRecord.event_city,
      eventState: medicalRecord.event_state,
      eventZip: medicalRecord.event_zip,
      createdByFullName: medicalRecord.created_by_full_name,
      createdByEmail: medicalRecord.created_by_email,
      createdByPhoneNo: medicalRecord.created_by_phone_no,
    };

    return data;
  }
}
