import React, { Component } from 'react';
import { connect } from 'react-redux';
import MedicalRecordWaiverList from 'components/MedicalRecordWaiverList'

export class ParentDashboard extends Component {
  render() {
    return (<div className="parentDashboard">
      <div>
        <MedicalRecordWaiverList />
        <hr />
        <div className="mt-5"></div>

        {this.props.user.role === "Parent" &&
          <div>
            <p>
              <strong>
                Who is Go4, and why do they have a copy of my child’s electronic treatment record?
              </strong>
            </p>
            <p>
              Answer:  Go4 is a company, and a website, that helps match athletic event operators
              with qualified athletic trainers, just like the athletic trainer who treated your child.
              Go4 is storing and securely maintaining an electronic copy of the athletic trainer’s
              record of treatment for your child pursuant to something called a “business associate agreement.”
              Putting aside the legalese, the athletic trainer has engaged Go4 to store and securely
              maintain the electronic health records they generate at athletic events.
              In addition, Go4 helps the athletic trainer process and appropriately respond to
              requests from parents and legal guardians of minor athletes, and from adult athletes,
              for copies of their electronic records relating to treatment they received from the
              athletic trainer.
            </p>
            <p className="go4blue">-</p>
            <p>
              <strong>
                Why am I being asked to sign and return a HIPAA acknowledgement and authorization to Go4?
              </strong>
            </p>
            <p>
              Because we need a reasonable assurance that you are authorized to request and obtain a
              copy of your child’s electronic treatment record, and because both your athletic trainer
              and Go4 want to be completely transparent with you about how the record is going to
              be maintained, to whom it can be disclosed, and for what reasons. Go4 is committed
              to protecting your personal information and the protected health information of your
              child; Go4 will never sell them to anyone.
            </p>
            <p className="go4blue">-</p>
            <p>
              <strong>
                How can I request an electronic record of the treatment my child received from the athletic trainer?
              </strong>
            </p>
            <p>
              After signing the medical record waiver, click on the view/share link under the action column to
              download a copy of your child’s EMR.
            </p>
            <p className="go4blue">-</p>
            <p>
              <strong>
                Who should I contact with any additional questions?
              </strong>
            </p>
            <p>
              Send us an email at <a href="mailto:contactus@go4.io">contactus@go4.io</a>.
            </p>
          </div>
        }
      </div>
    </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    user: state.session.currentUser,
    medicalRecordWaivers: state.medicalRecordWaivers.medicalRecordWaivers,
  };
}

function mapDispatchToProps(dispatch) {
  return {
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ParentDashboard);
