// @flow
import { combineReducers } from 'redux'
import { default as details } from './Details';
import type { EventDetailsFormType } from './Details';
import { default as shifts } from './Shifts';
import type { ShiftFormStateType } from './Shifts';
import { default as eap } from './EAP';
import type { EAPStateType } from './EAP';
import {
  CLEAR_FORM,
  CREATE_EVENT,
  SAVE_AS_DRAFT,
  VALIDATE_FORM,
  INIT_FORM,
  AUTO_UPDATE_EVENT,
  UPDATE_EVENT,
  CANCEL_EVENT,
  PUBLISH_DRAFT,
  RELOAD_FORM,
  DUPLICATE_EVENT,
  PREPOPULATE_FIELDS,
} from './actions';
import { basicCreator } from 'redux/ducks/helpers';

// Constants
// -----------------------------
export const FORM_STORAGE_KEY = 'Go4Ellis/EventWizard/Form';

// Action Creators
// -----------------------------
export const initForm = basicCreator(INIT_FORM);

export const reloadForm = basicCreator(RELOAD_FORM);

export const createEvent = basicCreator(CREATE_EVENT);

export const clearForm = basicCreator(CLEAR_FORM);

export const saveAsDraft = basicCreator(SAVE_AS_DRAFT);

export const updateEvent = basicCreator(UPDATE_EVENT);

export const autoUpdateEvent = basicCreator(AUTO_UPDATE_EVENT);

export const cancelEvent = basicCreator(CANCEL_EVENT);

export const publishDraft = basicCreator(PUBLISH_DRAFT);

export const duplicateEvent = basicCreator(DUPLICATE_EVENT);

export const prepopulateFields = basicCreator(PREPOPULATE_FIELDS);

export const validateForm = (pageNum: number) => ({
  type: VALIDATE_FORM,
  payload: {
    pageNum
  }
})

export default combineReducers({ details, shifts, eap })


export type EventFormType = {
  details: EventDetailsFormType,
  shifts: ShiftFormStateType,
  eap: EAPStateType
}


