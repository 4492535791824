import React, { Component } from 'react';

import { connect } from 'react-redux';
import { get } from 'lodash';

import {
  approveAthleticTrainer,
  approveJobModification,
  decline,
  declineJobModification,
} from 'redux/ducks/Job';

import AthleticTrainerModal from 'components/AthleticTrainerModal';
import EditJobModal from 'components/EditJobModal';
import ShiftExtensionModal from './ShiftExtensionModal';
import zipcode_to_timezone from 'zipcode-to-timezone';
import { rateTypeNameFinder } from 'helpers/rateTypeName';
import { formatDateTimeTz } from 'helpers/datetime';
import { formatter } from 'helpers/formatter';
import { isContractEo } from 'helpers/contracts';
import moment from 'moment-timezone';

class ShiftJobItem extends Component {
  state = {
    cancelModalOpen: false,
  }

  toggleCancelModal = () => this.setState({ cancelModalOpen: !this.state.cancelModalOpen });

  formatDate(date) {
    return moment(date).format("MM/DD/YYYY  -  h:mmA");
  }

  formatState(state) {
    let result = '';

    if (state === 'eo_accepted_at') {
      result = 'Pending Confirmation';
    } else if (state === 'pending') {
      result = 'Accept';
    } else if (state === 'checked_in') {
      result = 'Checked In';
    } else if (state === 'confirmed') {
      result = 'Confirmed';
    } else if (state === 'waitlist') {
      result = 'Waitlist';
    } else if (state === 'callout') {
      result = 'Callout';
    } else if (state === 'decommitted') {
      result = 'Callout';
    } else if (state === 'paid') {
      result = 'Paid';
    } else if (state === 'withdrawn') {
      result = 'Withdrawn';
    }

    return result;
  }

  formatTime(time) {
    return moment(time).format("h:mmA");
  }

  onApprove(event) {
    event.preventDefault();

    if (this.props.job.currentState === 'pending') {
      this.props.approveTrainer(this.props.job);
    }
  }

  onDecline(event) {
    event.preventDefault();

    if (this.canRejectJob()) {
      this.props.decline(this.props.job.id);
    }
  }

  canRejectJob = () => {
    return isContractEo(this.props.eoEmail) && ['pending'].includes(this.props.job.currentState);
  }

  hasPendingExtension = () => {
    return get(this.props, 'job.jobModification.currentState') === 'pending';
  };

  onExtensionApprove = () => {
    this.toggleCancelModal();
    this.props.approveExtension(this.props.job);
  };

  onExtensionDecline = () => {
    this.toggleCancelModal();
    this.props.declineExtension(this.props.job);
  };

  onRemoveAt = () => {
    this.props.decline(this.props.job.id);
  };

  rateType = () => {
    return rateTypeNameFinder(this.props.rateTypes, this.props.job.rateTypeId)
  };

  showContactInfo = () => {
    const startTime = moment(this.props.job.startTime);
    const now = moment();
    const endTime = moment(this.props.job.endTime);
    const difference = moment.duration(startTime - now).asDays();
    return (
      difference <= 7 &&
      ['confirmed', 'checked_in'].includes(this.props.job.currentState) &&
      now < endTime
    );
  };


  render() {
    const { job, shift, event } = this.props;
    const tz = zipcode_to_timezone.lookup(shift.eventLocation.address.zipCode) || moment.tz.guess();
    return (
      <div className="shiftJobItem">
        <div>
          <img src={job.user?.profilePic || require('styles/BlankAvatar.svg')} alt="profile" />
          <AthleticTrainerModal withoutContactInfo={!this.showContactInfo()} user={job.user} />
        </div>
        <div className="applied">
          Applied: {this.formatDate(job.appliedDate)}
        </div>
        <div className="jobTimes">
          {(shift.startTime !== job.startTime || shift.endTime !== job.endTime) &&
            `${formatDateTimeTz(job.startTime, tz)} - ${formatDateTimeTz(job.endTime, tz)}`
          }
        </div>
        <div className="payRate">
          {(event.payRate !== job.payRate || event.rateTypeId !== job.rateTypeId) &&
            `${formatter.format(job.payRate)} / ${this.rateType()}`}
        </div>
        <div className="shiftStateRow">
          <div className="shiftState">
            <a href="" className={job.currentState} onClick={this.onApprove.bind(this)}>
              {this.formatState(job.currentState)}
            </a>
            {this.hasPendingExtension() && <button type="button" className="btn btn-link extension-request" onClick={this.toggleCancelModal}>Respond to Shift Modification Request</button>}
            {(this.props.error && this.props.error.id === job.id) &&
              <p className="accept-error">Approval Failed</p>
            }
          </div>
          {this.canRejectJob() && <a href="" className="decline" onClick={this.onDecline.bind(this)}>
            Reject
          </a>}
        </div>
        {this.hasPendingExtension() &&
          <ShiftExtensionModal
            isOpen={this.state.cancelModalOpen}
            toggle={this.toggleCancelModal}
            job={job}
            onApprove={this.onExtensionApprove}
            onDecline={this.onExtensionDecline}
          />}
        <div className="actions">
          {
            ['confirmed', 'checked_in'].includes(job.currentState) && this.hasPendingExtension() === false &&
            <EditJobModal job={job} shift={shift}>
              <i className="fas fa-pencil-alt" />
            </EditJobModal>
          }
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    eoEmail: state.session.currentUser.email,
    error: state.job.error,
    rateTypes: state.enums.rateTypes,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    approveTrainer: (job) => dispatch(approveAthleticTrainer(job)),
    approveExtension: (job) => dispatch(approveJobModification(job)),
    declineExtension: (job) => dispatch(declineJobModification(job)),
    decline: (id) => dispatch(decline(id)),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ShiftJobItem);
