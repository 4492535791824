import React from "react";
import nataImg from "NATA-Logo.svg";

const defaultText = (
  <p>
    All Athletic Trainers on Go4 are members of the National Athletic
    Trainers’ Association, NATA.
  </p>
);
const NataDefinition = ({ className = "", text }) => (
  <section className={`nata-banner ${className}`}>
    <div className="blue-background">
      <div className="banner-inner">
        <img src={nataImg} alt="NATA" className="nata-img" />
        {text || defaultText}
      </div>
    </div>
  </section>
);

export default NataDefinition;
