import React from "react";
import cx from "classnames";

const HomeList = ({
  list = [],
  maxThree,
  center,
  className,
  largerText,
  mobileSingleColumn,
  uppercase,
  withBorderTop,
  withLeftBorder,
}) => (
  <div
    className={cx("homeList", className, {
      withLeftBorder,
      withBorderTop,
      uppercase,
      largerText,
      maxThree,
      mobileSingleColumn,
      center,
    })}
  >
    {list.map((item, i) => (
      <p key={i}>{item}</p>
    ))}
  </div>
);

export default HomeList;
