import React, { Component } from "react";
import { Link } from "react-router-dom";
import HomeContactForm from "../../components/HomeContactForm";
import HomePostEventForm from "../../components/HomePostEventForm";
import HomeCTAForm from "../../components/HomeCTAForm";
import FAQS from "../../components/FAQS";
import ATSkills from "../../components/ATSkills";
import SportsList from "../../components/SportsList";
import WhoUsesList from "../../components/WhoUsesList";
import SectionBanner from "../../components/SectionBanner";
import StateList from "../../components/StateList";
import ATActionButton from "../../components/ATActionButton";
import ForAts from "../../components/ForAts";
import CommonTopSection from "../../components/CommonTopSection";
import DownLoadApp from "../../components/DownLoadApp";
import ScheduleADemo from "../../components/ScheduleADemo";
import WhoIsEllis from "../../components/WhoIsEllis";
import NataDefinition from "../../components/NataDefinition";
import LandingUnderTop from "../../components/LandingUnderTop";
import NataATDefinition from "../../components/NataATDefinition";
import logo from "logo-white-blue.svg";
import logo4 from "logo-4-white.svg";
import eapIcon from "EAP-Icon-2.svg";
import HealthModal from "./HealthModal";
import HomeHowItWorks from "./HomeHowItWorks";
import EMRBanner from "../../components/EMRBanner/EMRBanner";
import SeminarContactForm from "../../components/SeminarContactForm";
import nataImg from "NATA-Logo.svg";

class HomeScene2 extends Component {
  getForm() {
    return this.props.isUSCS ? <SeminarContactForm /> : <HomeCTAForm />;
  }
  render() {
    return (
      <div className="homeScene2">
        {!this.props.noPopup && <HealthModal />}
        <section className="top-section">
          <CommonTopSection
            text={
              <>
                <img src={logo4} alt="Go4" className="go4ellis-img" />
              </>
            }
            form={this.getForm()}
          />
        </section>
        {/*
          <LandingUnderTop
            highLight="Need to hire an Athletic Trainer?"
            text={
              <>
                {
                  "The Go4Ellis platform connects schools, teams and sports organizations with on-demand Athletic Trainers for games, practices, camps, clinics and tournaments. No contracts, no minimums and"
                }
                <strong>you set your own pay rate</strong>
                {". All Athletic Trainers on Go4Ellis are members of the "}
              </>
            }
          />
          */}
        <HomeHowItWorks />
        <section className="at-for">
          <SectionBanner text="Athletic Trainers For:" />
          <div className="grouping">
            <div>Games</div>
            <div>Practices</div>
            <div>Tournaments</div>
          </div>
          <div className="grouping">
            <div>Camps</div>
            <div>Supplemental Coverage</div>
            <div>Clinics</div>
          </div>
          <div className="grouping">
            <div>Middle School</div>
            <div>High School</div>
            <div>College Club Sports</div>
          </div>
          <div className="grouping">
            <div>Youth Sports</div>
            <div>Industrial Setting</div>
            <div>Physician Extender</div>
          </div>
          <div className="at-link">
            <button>
              <Link to="athletic-trainer">
                <span>
                  Are you an Athletic Trainer looking for per diem work? Click
                  here.
                </span>
              </Link>
            </button>
          </div>
        </section>
        <ScheduleADemo />
        <section className="eap">
          <div className="inner">
            <div className="eap-top">
              <img alt="EAP" src={eapIcon} />
            </div>
            <div className="eap-bottom">
              <h1>EMERGENCY ACTION PLAN</h1>
              <p>
                When you post an event on Go4, the platform automatically
                generates an Emergency Action Plan, or EAP, with the locations
                of the nearest police and fire stations and trauma centers as
                well as emergency instructions.
              </p>
            </div>
          </div>
        </section>
        <FAQS />
        <section className="at-skills-services">
          <div className="top-banner">
            <h5>{"ATHLETIC TRAINER SKILLS & SERVICES"}</h5>
            <p>
              Athletic Trainers are multi-skilled health care professionals who
              collaborate with physicians to provide preventative services,
              emergency care, clinical diagnosis, therapeutic intervention and
              rehabilitation of injuries.
            </p>
          </div>
          <ATSkills />
          <ATActionButton />
        </section>
        <EMRBanner isHomePage={true} />
        <section className="at-sports">
          <div className="top-banner">
            <h5>{"Sports"}</h5>
            <p>
              The Athletic Trainers on Go4 work with athletes and players
              in all types of sports and settings.
            </p>
          </div>
          <SportsList />
          <ATActionButton />
        </section>
        <NataATDefinition />
        <section className="who-uses">
          <SectionBanner text="Who uses go4" />
          <WhoUsesList />
        </section>
        <NataDefinition />
        <section className="locations">
          <SectionBanner text="Locations" />
          <StateList />
          <ATActionButton />
        </section>
        <ForAts />
        <DownLoadApp />
        <WhoIsEllis />
      </div>
    );
  }
}

export default HomeScene2;
