class EmergencyEquipmentSerializer {
  static serialize(equipment) {
    return equipment
  }

  static deserialize(equipment) {
    return {
      id: equipment.id,
      name: equipment.name,
      location: equipment.location,
    }
  }
}

export default EmergencyEquipmentSerializer;