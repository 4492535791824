import React, { Component } from 'react';
import { connect } from 'react-redux';
import { UncontrolledAlert } from 'reactstrap';
import { clearResendConfirmation, resendConfirmation } from 'redux/ducks/Confirmation';
import { get } from 'lodash';

class ResendConfirmationScene extends Component {
  state = {
    email: this.props.location?.state?.email || '',
    success: undefined,
  };

  componentDidMount() {
    this.props.clearResendConfirmation();
  }

  handleEmailChange = (e) => {
    this.setState({ email: e.currentTarget.value });
  };

  onSubmit = (e) => {
    e.preventDefault();

    this.props.resendConfirmation(this.state.email);
  };

  successMessage = () => {
    return (
      <div className="resend-success-message gotham-medium-font">
        <div>
          <i className="material-icons" style={{ verticalAlign: 'text-bottom' }}>
            check
          </i>{' '}
          Your Verification Link Has Been Sent
        </div>
        <div className="nimbus-regular-font">Check your email in order to verify your account.</div>
      </div>
    );
  };

  failureMessage = () => {
    return (
      <div className="resend-failure-message nimbus-regular-font">
        Sorry, that email is not connected to a Go4 account.
      </div>
    );
  };

  render() {
    return (
      <form className="v2ResendConfirmationScene nimbus-regular-font" onSubmit={this.onSubmit}>
        <div className="v2-title gotham-bold-font">
          <p className="v2-title-p">Verify Account</p>
        </div>
        {get(this.props, 'location.state.redirected') && (
          <UncontrolledAlert color="danger">
            Sorry, there was a problem with that link. Please enter your email below to try again.
          </UncontrolledAlert>
        )}
        <div className="resendConfirmationForm">
          <label>You need to verify your email first before accessing your account.</label>
          <input
            type="email"
            name="email"
            value={this.state.email}
            onChange={this.handleEmailChange}
            className="nimbus-regular-font"
          />
          {this.props.submitted && !this.props.success && this.failureMessage()}
          {this.props.success ? (
            this.successMessage()
          ) : (
            <button className="btn plain-button gotham-bold-font" disabled={!this.state.email}>
              resend confirmation
            </button>
          )}

          <div className="confirmation-footer gotham-regular-font">
            Need help?&nbsp;<a href="mailto:contactus@go4.io">Contact support</a>.
          </div>
        </div>
      </form>
    );
  }
}

const mapStateToProps = (state) => ({
  submitted: state.confirmation.success || state.confirmation.error,
  success: state.confirmation.success && !state.confirmation.error,
});

const mapDispatchToProps = (dispatch) => ({
  resendConfirmation: (email) => dispatch(resendConfirmation(email)),
  clearResendConfirmation: () => dispatch(clearResendConfirmation()),
});

export default connect(mapStateToProps, mapDispatchToProps)(ResendConfirmationScene);
