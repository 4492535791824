import React from 'react';
import { connect } from 'react-redux';
import { getEnumName } from 'helpers/enums';

const PostDetailsPanel = (props) => {
  const { details, enums } = props;
  const { eventSettings, sports, settingDetails } = enums;
  const { eventSettingId, sportId, settingDetailId } = details;
  const eventSetting = getEnumName(eventSettings, eventSettingId);
  const settingDetail = getEnumName(settingDetails, settingDetailId);
  const sportType = getEnumName(sports, sportId);

  return (
    <div className="post-details-panel">
      <div className="event-details">
        <div className="event-title gotham-bold-font">{details.title}</div>
        <div>
          {`${eventSetting} / ${settingDetail}`}
          {eventSetting === 'Sports' && ` / ${sportType}`}
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({ enums: state.enums });

export default connect(mapStateToProps)(PostDetailsPanel);
