import {
  getCertification,
  HEAD_TITLES,
  TITLES,
} from 'components/AthleticTrainerModal/credentialHelpers';
import React, { Component, useEffect, useState } from 'react';
import { Modal, ModalBody, ModalHeader } from 'reactstrap';
import heic2any from 'heic2any';
import PdfDocument from 'components/AthleticTrainerModal/PdfDocument';
import moment from 'moment';
import { get } from 'lodash';

function MaybeHeicImage(props) {
  const [value, setState] = useState({
    loading: true,
    imageSrc: null,
    rotation: 0,
  });

  function usePromise(url) {
    useEffect(() => {
      let isMounted = true;

      fetch(url)
        .then((res) => res.blob())
        .then((blob) =>
          heic2any({
            blob,
            toType: 'image/jpeg',
            quality: 0.5,
          })
        )
        .then((conversionResult) => {
          var url = URL.createObjectURL(conversionResult);
          if (isMounted) {
            setState({
              ...state,
              loading: false,
              imageSrc: url,
            });
          }
        })
        .catch((e) => {
          if (isMounted) {
            setState({
              ...state,
              loading: false,
              imageSrc: props.src,
            });
            console.error(e);
          }
        });

      return () => {
        isMounted = false;
      };
    }, []);

    return value;
  }

  const state = usePromise(props.src);
  if (state.loading) {
    return (
      <p>
        <a href={props.src} target="_blank" rel="noopener noreferrer">
          Loading image. This might take a while... Alternatively click here to download locally.
        </a>
      </p>
    );
  }

  let styles = {
    rotateIcons: {
      height: 30,
      width: 30,
    },
    imageWrapper: {
      minHeight: '800px',
      backgroundImage: `url(${state.imageSrc})`,
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'center',
      backgroundSize: 'contain',
      width: 'auto',
      transform: `rotate(${state.rotation}deg)`,
    },
  };

  let setRotation = (deg) => {
    setState({
      ...state,
      rotation: state.rotation + deg,
    });
  };

  return (
    <div>
      <img
        style={styles.rotateIcons}
        src={require('./rotate-left.svg')}
        onClick={() => setRotation(-90)}
        alt="rotate left"
      />
      <img
        style={styles.rotateIcons}
        src={require('./rotate-right.svg')}
        onClick={() => setRotation(90)}
        alt="rotate right"
      />
      <div
        style={styles.imageWrapper}
        {...props}
        src={state.imageSrc}
        onClick={() => window.open(props.src, '_blank')}
      ></div>
      <hr></hr>
    </div>
  );
}

class CredentialModal extends Component {
  state = {
    modalOpen: false,
    numPages: null,
    pageNumber: 1,
  };

  onToggle = () => {
    this.setState((prevState) => ({ ...prevState, modalOpen: !this.state.modalOpen }));
  };

  displayTitles = (credential) => {
    if (credential.type === 'state_certification_or_license') {
      return (
        <p className="credential-type">
          {credential.states_valid} State Certification: {` `}
          {<span>Valid thru {moment(credential.valid_through).format('MM/DD/YYYY')}</span>}
        </p>
      );
    }
    if (credential.type === 'cpr_certification') {
      return (
        <p className="credential-type">
          {TITLES[credential.type]}:{' '}
          <span>Valid thru {moment(credential.valid_through).format('MM/DD/YYYY')}</span>
          <br />
          Certified by: <span style={{ fontWeight: 'normal' }}>
            {credential.certification}
          </span>{' '}
        </p>
      );
    }

    if (credential.valid_through && credential.type !== 'resume') {
      return (
        <p className="credential-type">
          {TITLES[credential.type]}:{' '}
          {<span>Valid thru {moment(credential.valid_through).format('MM/DD/YYYY')}</span>}
        </p>
      );
    }

    return <p className="credential-type">{TITLES[credential.type]}</p>;
  };

  expandModalTitle = (credential) => {
    if (credential.type === 'state_certification_or_license') {
      return `${credential.states_valid} ${HEAD_TITLES[credential.type]}`;
    }
    return HEAD_TITLES[credential.type];
  };

  displayCheckCredentialLink = (credential) => {
    if (credential.type === 'national_certification') {
      return 'https://cert.bocatc.org/bocssa/f?p=CRTSSA:17800:15058053896022:';
    }
    if (credential.type === 'state_certification_or_license') {
      return 'https://bocatc.org/state-regulation-map/';
    }
    return '#';
  };

  render() {
    const { credential, at } = this.props;
    const certified = get(at, 'profile.educationExperience.firstYearCertified');
    return (
      <div className="credentialModal">
        <div>
          {!!TITLES[credential.type] && (
            <div>
              {this.displayTitles(credential)}
              <div>
                <a className="credential-view" href="#" onClick={this.onToggle}>
                  {(credential.type !== 'recent_work_experience' ||
                    credential.type !== 'current_role') && <span>View</span>}
                </a>
              </div>
            </div>
          )}
        </div>

        <Modal
          isOpen={this.state.modalOpen}
          toggle={this.onToggle}
          modalClassName="credential-modal"
        >
          <ModalHeader toggle={this.onToggle}>
            {this.expandModalTitle(credential)}
            {credential.license_number && (
              <p style={{ margin: 0, fontSize: '20px' }}>
                License number: {credential.license_number}
              </p>
            )}
            {!!certified && credential.type === 'national_certification' && (
              <p style={{ margin: 0, fontSize: '20px' }}>First Year Certified: {certified}</p>
            )}
            {credential.type === 'cpr_certification' && (
              <p style={{ margin: 10, fontSize: '20px' }}>
                Certified by {credential.certification}
              </p>
            )}
            {credential.valid_through && (
              <p style={{ fontSize: '20px' }}>
                Valid thru {moment(credential.valid_through).format('MM/DD/YYYY')}
              </p>
            )}
          </ModalHeader>
          <ModalBody>
            {!!credential.certification && credential.type !== 'cpr_certification' && (
              <div>
                <strong>{getCertification(credential.type)}: </strong> {credential.certification}
              </div>
            )}
            {!!credential.approved_on && (
              <div>
                <strong>Course Completion Date: </strong>{' '}
                {moment(credential.approved_on).format('MM/DD/YYYY')}
              </div>
            )}
            {!!credential.institution && (
              <div>
                <strong>Institution: </strong> {credential.institution}
              </div>
            )}
            {['national_certification', 'state_certification_or_license'].includes(
              credential.type
            ) && (
              <div>
                <a
                  href={this.displayCheckCredentialLink(credential)}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Board of Certification Athletic Trainer Registry
                </a>
              </div>
            )}
            {credential.document && (
              <div>
                <a href={credential.document.url}>Attachment {credential.document.fileName}</a>

                <PdfDocument pdfFile={credential.document.url} />
              </div>
            )}
            {credential.pages &&
              credential.pages.map((page) => (
                <MaybeHeicImage src={page.url} alt={page.pageNumber} key={page.pageNumber} />
              ))}
          </ModalBody>
        </Modal>
      </div>
    );
  }
}

export default CredentialModal;
