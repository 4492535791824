import React from 'react';

export default function Footer(props) {
  return (
    <div className="registrationScene__footer">
      <a href='https://go4.io/privacy-policy/'>
        Privacy Policy
      </a>
      {/* <span> | </span> */}
      <a href='https://go4.io/privacy-policy/'>
        Terms & Conditions
      </a>
      {/* <span> | </span> */}
      <p>
        ©{new Date().getFullYear()} Go4. Patent Pending.
      </p>
    </div>
  )
}