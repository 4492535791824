import React from "react";

const NataATDefinition = () => (
  <section className="nata-definition">
    <div className="nata-definition-inner">
      <div className="text-wrapper">
        <span>THE NATA DEFINITION OF ATHLETIC TRAINERS </span>
        Athletic Trainers (ATs) are highly qualified, multi-skilled health care
        professionals who render service or treatment, under the direction of or
        in collaboration with a physician, in accordance with their education,
        training and the state’s statutes, rules and regulations. As a part of
        the health care team, services provided by athletic trainers include
        primary care, injury and illness prevention, wellness promotion and
        education, emergent care, examination and clinical diagnosis,
        therapeutic intervention and rehabilitation of injuries and medical
        conditions.
      </div>
    </div>
  </section>
);

export default NataATDefinition;
