import React, { Component } from 'react';

import { Link } from 'react-router-dom';

import FAQListItem from './FAQListItem';

export class HomeScene extends Component {
  faqQuestions = [
    {
      question: "How do Athletic Trainers get paid?",
      answer: "At the end of your shift, your money is directly deposited into your bank account. There are no paper checks or chasing down people for money.",
    },
    {
      question: "What is your rate for ATs?",
      answer: "We do not set a rate for ATs. You are free to post your job on Go4 at whatever rate you feel is appropriate. That being said, if you post a job at a low rate, it is not likely to get picked up. Also, if you are posting within a couple of days of your event, it is recommended that you post at a higher than average rate to help ensure it gets picked up.",
    },
    {
      question: "What is the Staffing/Transaction Fee For Event Operators?",
      answer: "Go4 charges Event Operators a flat 12.5% staffing transaction fee on the hours an AT works. By comparison, traditional per diem Athletic Trainer staffing companies markup what they pay ATs by 25% to 100%. So, for example, while you may be paying an AT staffing company $50 an hour for an Athletic Trainer, the AT is only getting $25 an hour.",
    },
    {
      question: "What is the fee for ATs?",
      answer: "ATs are charged a flat 3% on hours worked. That 3% fee is a pass through cost from our payment processor, Stripe, and is fee they charge to move money from one account to another.",
    },
    {
      question: "Are there any membership fees? Short answer: No.",
      answer: "There are no membership fees. Creating and holding on an account on Go4 is completely free.",
    },
    {
      question: "Do I need to post a minimum amount hours or jobs? Are there any contracts? Short answer: No",
      answer: "As event operator you don’t need to post a minimum amount of hours. You could sign up, post one event and never post again. Of course, we hope you find the service so convenient and easy to use that you’ll become a Go4 power user.",
    },
    {
      question: "As an AT do I need to work a certain amount of hours or work certain events? Short answer: No and No.",
      answer: "As an AT, you could work one hour a year and it would it all be good. Also, you are free to pick and choose the events you want to work. It is completely up to you.",
    },
    {
      question: "I’m an AT, do I need my own liability insurance?",
      answer: "Yes, you need liability insurance to work events. Mercer Insurance is NATA’s preferred provider. As an NATA member, you receive 10% off your policy.",
    },
    {
      question: "At the end of the year, do I need to track down the ATs that worked my events to file 1099s? Short answer: No.",
      answer: "The Go4 platform handles all the 1099s for the ATs that worked your event.  Saving your organization the time and man-hours previously wasted  tracking down ATs who worked your event and filing copious tax forms.",
    },
  ];

  componentWillMount() {
    document.body.classList.add('dark');
  }

  componentWillUnmount() {
    document.body.classList.remove('dark');
  }

  render() {
    return (
      <div className="homeScene">
        <div className='hero'>
          <div className='content'>
            <div className='parallelogram'>
              <p>On-Demand / Per Diem</p>
            </div>
            <h1>Athletic Trainers</h1>
            <div className='two-column'>
              <div className='step-text'>
                <p>The <span className='yellow'>GO</span>4<span className='yellow'>ELLIS</span> platform connects you with thou-</p>
                <p>sands of Athletic Trainers looking per-diem work.</p>
                <p>Just post your practice, game, camp, clinic or</p>
                <p>tournament, pick from the ATs that apply and</p>
                <p>you're covered</p>
              </div>
              <div className='actions'>
                <Link className='btn btn-primary sign-up' to='/sign-up'>Sign Up</Link>
                <a className='ask-link' href="mailto:contactus@go4.io">Ask a question</a>
              </div>
            </div>
          </div>
        </div>
        <div className='center-content-images'>
          <img className='center-content' src={require('./CenterContentBlock.png')} />
          <img className='center-content-mobile' src={require('./CenterContentMobile.png')} />
        </div>
        <div className='faqs'>
          <div className='list'>
            {this.faqQuestions.map((faq, index)  => {
              return <FAQListItem answer={faq.answer} question={faq.question} key={index}/>
            })}
          </div>
          <h2>FAQS</h2>
        </div>
        <div className='mission-ellis-images'>
          <img className='mission-ellis' src={require('./MissionEllis.png')} />
          <img className='mission-ellis-mobile' src={require('./MissionEllisMobile.png')} />
        </div>
        <div className='footer-actions'>
          <Link className='btn btn-primary sign-up' to="/sign-up">Sign up</Link>
          <a className='btn btn-primary' href="mailto:contactus@go4.io">Ask a question</a>
        </div>
      </div>
    );
  }
}

export default HomeScene;
