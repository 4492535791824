import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Elements } from "react-stripe-elements";
import ShiftDetailsTable from "./ShiftDetailsTable";
import ShiftDetailsTableMobile from "./ShiftDetailsTableMobile";
import BillingEstimateTable from "./BillingEstimateTable";
import BillingEstimateTableMobile from "./BillingEstimateTableMobile";
import PaymentDetailsPanel from "./PaymentDetailsPanel";
import { connect } from "react-redux";
import { getEstimatedFee } from "helpers/getEstimatedFee";
import moment from 'moment';

class EventBillingSummary extends Component {
  render() {
    const { noEditLink, shifts, eventProfessionId, account } = this.props;

    const maxStartTime = Math.max(...shifts.map(s => moment(s.start)));
    const isAmex = account && account.brand === "American Express";

    const estimatedFee = getEstimatedFee(maxStartTime, eventProfessionId, isAmex);
    const estimatedFeeAch = getEstimatedFee(maxStartTime, eventProfessionId, false);

    return (
      <div className="event-summary">
        <h5>
          Shift Details&nbsp;
          {noEditLink ? null : (
            <Link to="2" className="btn btn-link">
              <i className="fas fa-pencil-alt" /> EDIT
            </Link>
          )}
        </h5>
        <ShiftDetailsTable shifts={shifts} />
        <ShiftDetailsTableMobile shifts={shifts} />
        <div className="payment-details-box">
          <h5>Payment Method</h5>
          <p>
            <strong>Important Note:</strong> You are NOT charged to post an
            event. You are charged after the event is complete, only for the
            shifts that are worked.
          </p>
          <Elements>
            <PaymentDetailsPanel
              ref="payments"
              onChange={this.props.onPaymentTokenChange}
            />
          </Elements>
        </div>
        <h5>Billing Estimate</h5>
        <p className="details-box">
          This is an estimate. You will only be charged for shifts that are
          worked.
        </p>
        <BillingEstimateTable
          shifts={shifts}
          payRate={this.props.payRate}
          rateTypeId={this.props.rateTypeId}
        />
        <BillingEstimateTableMobile
          shifts={shifts}
          payRate={this.props.payRate}
          rateTypeId={this.props.rateTypeId}
        />
        {this.props.account && this.props.account.brand === "American Express" && (
          <p className="amex-notice">
            <strong>Notice</strong>: American Express Services Fees on Go4 are {estimatedFee}%.
            ACH Service Fees are {estimatedFeeAch}%. Learn how to switch your payment
            method <a href="https://support.go4.io/hc/en-us/articles/115007308567-Billings-and-Transactions-Through-Go4" target="_blank" rel="noopener noreferrer">here</a> or <a href="mailto:contactus@go4.io">contact us</a>.
          </p>
        )}
        <div className="form-group">
          <div className="custom-control custom-checkbox">
            <input
              type="checkbox"
              className="custom-control-input"
              id="termsConditionsCheck1"
              checked={this.props.check1Checked}
              onChange={this.props.toggleCheck1}
            />
            <label
              className="custom-control-label"
              htmlFor="termsConditionsCheck1"
            >
              I have read and agree to the <a target="_blank" rel="noopener noreferrer" href="https://go4.io/privacy-policy/">Privacy Policy</a> and <a target="_blank" rel="noopener noreferrer" href="https://go4.io/terms-and-conditions/">Terms and Conditions</a>.
            </label>
          </div>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    account: state.payment.source,
    eventProfessionId: state.createEvent.details.eventProfessionId,
  };
}

export { EventBillingSummary };

export default connect(mapStateToProps)(EventBillingSummary);
