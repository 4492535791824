import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchBlogs, searchBlogs } from '../../redux/ducks/Blog';
import { Link, Route, Switch, useRouteMatch } from 'react-router-dom';
import SingleBlog from './SingleBlog';
import Input from '../../components/UI/Form/Input/Input';
import StandardRed from '../../components/UI/Button/StandardRed/StandardRed';
import debounce from 'lodash/debounce';

const Blog = () => {
  const dispatch = useDispatch();
  const loadMoreRef = useRef();
  const [search, setSearch] = useState('');
  const scrollToRef = useRef();
  const { path } = useRouteMatch();
  const { filteredBlogs: data = [], nextPageToken } = useSelector((s) => s.blog);
  const showLoadingSpinner = useSelector((s) => s.showLoadingSpinner);
  const fetchMoreBlogs = debounce(
    () => {
      if (!showLoadingSpinner && !search) dispatch(fetchBlogs());
    },
    2000,
    {
      leading: true,
      trailing: false,
    }
  );
  useEffect(() => {
    fetchMoreBlogs();
  }, [fetchMoreBlogs]);

  const handleSearch = (e) => {
    e.preventDefault();
    dispatch(searchBlogs(search));
  };
  const handleOnChange = (e) => {
    const { value } = e.target;
    setSearch(value);
  };

  useEffect(() => {
    const handleScroll = (e) => {
      const windowHeight = window.innerHeight;
      const threshold = loadMoreRef?.current?.getBoundingClientRect().top;
      if (threshold < windowHeight) {
        fetchMoreBlogs();
      }
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, [fetchMoreBlogs]);

  return (
    <div className="blog">
      <div ref={scrollToRef} id={'blog-scroll-to'} />
      <div className="top-banner">
        <h1 className="text-uppercase">Blog</h1>
      </div>
      <Switch>
        <Route exact path={path}>
          <form className="d-flex px-4 pt-2 align-items-center" onSubmit={handleSearch}>
            <Input className="search-input ml-2 ps-2" value={search} onChange={handleOnChange} />
            <StandardRed className="m-2 py-2 px-4" type="submit">
              Search
            </StandardRed>
          </form>
          <div className="d-flex flex-wrap">
            {data.map((item) => (
              <Link
                className="blog-snippet col-md-6 col-12 d-flex flex-column align-items-center"
                key={item.id}
                to={`${path}/${item.id}`}
              >
                <article className="py-4 mx-3 d-flex flex-column flex-grow-1">
                  <img alt="blog thumbnail w-100" src={item.thumb} />
                  <h2 className="m-0">{item.title}</h2>
                  <p className="m-0 date">{item.date}</p>
                </article>
              </Link>
            ))}
          </div>
          {nextPageToken && <div ref={loadMoreRef} />}
          <div className="bottom-separator" />
        </Route>
        <Route path={`${path}/:id`} render={SingleBlog} />
      </Switch>
    </div>
  );
};

export default Blog;
