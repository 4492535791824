import { basicCreator } from 'redux/ducks/helpers';
import { removePostIdentifiers } from 'helpers/post';

const constForName = (name) => `go4ellis/Post/${name}`;

export const POST_STORAGE_KEY = 'Go4Ellis/EventWizard/Post';

export const V2_RELOAD_FORM = constForName('RELOAD_FORM');
export const V2_RELOAD_FORM_ERROR = constForName('RELOAD_FORM_ERROR');
export const V2_RELOAD_FORM_SUCCESS = constForName('RELOAD_FORM_SUCCESS');

export const V2_CLEAR_FORM = constForName('CLEAR_FORM');
export const V2_CLEAR_FORM_ERROR = constForName('CLEAR_FORM_ERROR');
export const V2_CLEAR_FORM_SUCCESS = constForName('CLEAR_FORM_SUCCESS');

export const V2_SAVE_LOCAL_FORM = constForName('SAVE_LOCAL_FORM');
export const V2_SAVE_LOCAL_FORM_ERROR = constForName('SAVE_LOCAL_FORM_ERROR');
export const V2_SAVE_LOCAL_FORM_SUCCESS = constForName('SAVE_LOCAL_FORM_SUCCESS');

export const V2_VALIDATE_FORM = constForName('VALIDATE_FORM');

export const SAVE_PROFILE = constForName('SAVE_PROFILE');
export const SAVE_PROFILE_SUCCESS = constForName('SAVE_PROFILE_SUCCESS');
export const SAVE_PROFILE_ERROR = constForName('SAVE_PROFILE_ERROR');

export const SAVE_AS_DRAFT = constForName('SAVE_AS_DRAFT');
export const SAVE_AS_DRAFT_SUCCESS = constForName('SAVE_AS_DRAFT_SUCCESS');
export const SAVE_AS_DRAFT_ERROR = constForName('SAVE_AS_DRAFT_ERROR');

export const PUBLISH_DRAFT = constForName('PUBLISH_DRAFT');
export const PUBLISH_DRAFT_SUCCESS = constForName('PUBLISH_DRAFT_SUCCESS');
export const PUBLISH_DRAFT_ERROR = constForName('PUBLISH_DRAFT_ERROR');

export const DELETE_DOCUMENT = constForName('DELETE_DOCUMENT');
export const DELETE_DOCUMENT_SUCCESS = constForName('DELETE_DOCUMENT_SUCCESS');
export const DELETE_DOCUMENT_ERROR = constForName('DELETE_DOCUMENT_ERROR');

export const UPDATE_EVENT = constForName('UPDATE_EVENT');
export const UPDATE_EVENT_SUCCESS = constForName('UPDATE_EVENT_SUCCESS');
export const UPDATE_EVENT_ERROR = constForName('UPDATE_EVENT_ERROR');

export const INITIALIZE_POST = constForName('INITIALIZE_POST');
export const DUPLICATE_POST = constForName('DUPLICATE_POST');

export const publishDraft = basicCreator(PUBLISH_DRAFT);
export const editEvent = basicCreator(UPDATE_EVENT);
export const saveAsDraft = basicCreator(SAVE_AS_DRAFT);
export const clearV2Form = basicCreator(V2_CLEAR_FORM);
export const saveLocalForm = basicCreator(V2_SAVE_LOCAL_FORM);

export const reloadForm = basicCreator(V2_RELOAD_FORM);
export const initForm = basicCreator(INITIALIZE_POST);
export const saveProfile = basicCreator(SAVE_PROFILE);
export const duplicatePost = ({ shifts, ...event }) => ({
  type: DUPLICATE_POST,
  payload: removePostIdentifiers({
    ...event,
    shifts: Array.isArray(shifts) ? shifts : Object.values(shifts.byId),
  }),
});
