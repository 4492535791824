import React from 'react';
import ReactPaginate from 'react-paginate';
import './Pagination.scss';

const Pagination = (props) => {
  const { pagination } = props;
  if (!pagination || !pagination.current_page) return null;

  return (
    <ReactPaginate
      previousLabel={' ← '}
      nextLabel={' → '}
      breakLabel={'...'}
      breakClassName={'break-me'}
      pageCount={pagination.total_pages || 0}
      marginPagesDisplayed={3}
      pageRangeDisplayed={2}
      initialPage={pagination.current_page - 1}
      onPageChange={props.handlePageClick}
      containerClassName={'paginationV2'}
      subContainerClassName={'pages paginationV2'}
      activeClassName={'active'}
    />
  );
};

export default Pagination;
