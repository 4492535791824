import ShiftSerializer from './ShiftSerializer';
import LocationSerializer from './LocationSerializer';
import moment from 'moment-timezone';

export default class EventSerializer {
  static serialize(post, jpId) {
    const { profile, details, locations, rate, shifts } = post;

    const formData = new FormData();
    const setAttr = (attr, val) => {
      if (val !== undefined) {
        formData.set(`event[${attr}]`, val);
      }
    };

    const setAttrStrict = (attr, val: ?string | ?File) => {
      if (val != undefined) {
        formData.set(`event[${attr}]`, val);
      }
    };

    // Event Setting
    setAttr('event_setting_id', profile.eventSettingId);

    // Setting Detail
    setAttr('setting_detail_id', profile.settingDetailId);
    // setAttrStrict('setting_detail_other', profile.settingDetailOther);

    // Profile
    setAttr('title', profile.title);
    setAttrStrict('sport_id', profile.sportId);
    // setAttrStrict('sport_other', profile.sportOther);
    setAttrStrict('gender_id', profile.genderId);
    setAttrStrict('age_bracket_ids', profile.ageBracketIds?.join(','));
    setAttrStrict('event_participant_numbers_id', profile.participantNumbersId);
    setAttr('job_description_ids', profile.jobDescriptionIds?.join(','));
    // setAttrStrict('job_description_other', details.jobDescriptionOther);
    setAttrStrict('event_code', profile.eventCode);

    // Backend Stuff
    setAttr('is_draft', details.isDraft);
    setAttr('instructions', details.instructions);
    setAttr('event_operator_id', details.eventOperatorId || jpId);
    setAttr('profession_id', details.eventProfessionId);
    setAttrStrict('payment_token', details.paymentToken);
    setAttr('version', 'v2');
    setAttr('schedule_type', details.scheduleType);
    setAttr('locations_no', details.locationsNo);

    // Rate
    setAttr('rate_type_id', rate.rateTypeId);
    setAttr('pay_rate', rate.payRate);

    if (details.documents) {
      details.documents.forEach((document) => {
        formData.append(`documents[]`, document);
      });
    }

    if (profile.eventLogo) {
      formData.append('event_logo', profile.eventLogo);
    }

    for (const location of locations) {
      if (location.documents && location.id) {
        location.documents.forEach((document, docIdx) => {
          formData.append(`location_documents[${location.id}][${docIdx}]`, document);
        });
      }
    }

    shifts
      .map((shift) =>
        ShiftSerializer.serialize(
          shift,
          locations.find((loc) => loc.id === shift.locationId || loc.idx === shift.locationId),
          details.isDraft
        )
      )
      .forEach((shift, idx) => {
        Object.keys(shift)
          .filter(
            (key) =>
              !['event_location_attributes', 'shift_contacts_attributes', 'details'].includes(key)
          )
          .forEach((key) => {
            setAttrStrict(`shifts_attributes][${idx}][${key}`, shift[key]);
          });

        if (shift.details) {
          Object.keys(shift.details).forEach((key) => {
            if (Array.isArray(shift.details[key])) {
              shift.details[key].forEach((val, i) => {
                formData.append(`event[shifts_attributes][${idx}][details][${key}][]`, val);
              });
              if (shift.details[key].length === 0) {
                formData.append(`event[shifts_attributes][${idx}][details][${key}][]`, '');
              }
            } else {
              setAttrStrict(`shifts_attributes][${idx}][details][${key}`, shift.details[key]);
            }
          });
        }

        if (shift.shift_contacts_attributes) {
          shift.shift_contacts_attributes.forEach((key, contact_idx) => {
            Object.keys(key).forEach((contact_attr) => {
              setAttrStrict(
                `shifts_attributes][${idx}][shift_contacts_attributes][${contact_idx}][${contact_attr}`,
                shift.shift_contacts_attributes[contact_idx][contact_attr]
              );
            });
          });
        }

        if (shift.event_location_attributes) {
          Object.keys(shift.event_location_attributes)
            .filter((key) => key !== 'address_attributes')
            .forEach((key) => {
              setAttrStrict(
                `shifts_attributes][${idx}][event_location_attributes[${key}`,
                shift.event_location_attributes[key]
              );
            });
          let address = shift.event_location_attributes.address_attributes;
          if (address) {
            Object.keys(address).forEach((key) => {
              setAttrStrict(
                `shifts_attributes][${idx}][event_location_attributes[address_attributes[${key}`,
                address[key]
              );
            });
          }
        }
      });

    return formData;
  }

  static deserialize(event) {
    const locations = LocationSerializer.deserialize(event);
    const eventShifts = event.shifts.map((shift) =>
      ShiftSerializer.deserialize(shift, event.pay_rate)
    );

    const data = {
      profile: {
        description: event.event_description,
        genderId: event.gender_id,
        participantNumbersId: event.event_participant_numbers_id,
        sportId: event.sport_id,
        title: event.title,
        ageBracketIds: event.age_bracket_ids?.split(','),
        url: event.url,
        totalPaymentToDate: event.total_payment_to_date,
        eventSettingId: event.event_setting_id,
        settingDetailId: event.setting_detail_id,
        jobDescriptionIds: event.job_description_ids?.split(','),
        settingDetailOther: event.setting_detail_other,
        jobDescriptionOther: event.job_description_other,
        sportOther: event.sport_other,
        eventLogo: event.event_logo,
        eventCode: event.event_code,
      },
      details: {
        id: event.id,
        atsHired: event.ats_hired,
        createdAt: event.created_at,
        lastPaymentIssued: event.last_payment_issued,
        description: event.event_description,
        isDraft: event.is_draft,
        existingDocuments: event.documents,
        url: event.url,
        totalPaymentToDate: event.total_payment_to_date,
        eventOperatorId: event.event_operator_id,
        eventProfessionId: event.profession_id,
        sport: event.sport,
        version: event.version,
        scheduleType: event.schedule_type,
        instructions: event.instructions,
        locationsNo: locations.length,
      },
      rate: {
        rateTypeId: event.rate_type_id?.toString(),
        payRate: event.pay_rate,
      },
      locations: locations,
      shifts: eventShifts.flatMap((sh, idx) => ({ ...sh, idx })),
    };

    return data;
  }

  static serializeShiftRate(payload): FormData {
    const formData = new FormData();
    const setAttr = (attr, val) => {
      if (val !== undefined) {
        formData.set(`event[${attr}]`, val);
      }
    };
    const setAttrStrict = (attr, val: ?string | ?File) => {
      if (val !== undefined) {
        formData.set(`event[${attr}]`, val);
      }
    };
    setAttr('id', payload.eventId);
    setAttrStrict(`shifts_attributes][0][id]`, payload.shiftId);
    setAttrStrict(`shifts_attributes][0][pay_rate]`, payload.rate);
    setAttrStrict(`shifts_attributes][0][rate_type_id]`, payload.rateTypeId);
    setAttrStrict(`shifts_attributes][0][boosted_at]`, payload.boostedAt);
    return formData;
  }
}
