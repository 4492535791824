import React, { Component } from 'react';
import { Route } from 'react-router-dom';
import RequiredCredentials from './RequiredCredentials';
import Supplies from './Supplies';
import Details from './Details';

class LocationDetails extends Component {
  render() {
    return (
      <div className="locationDetails">
        <div className="nav-placeholder" />
        <main>
          <Route
            path={`${this.props.baseUrl}/details/credentials`}
            render={() => <RequiredCredentials {...this.props} />}
          />
          <Route
            path={`${this.props.baseUrl}/details/supplies`}
            render={() => <Supplies {...this.props} />}
          />
          <Route
            path={`${this.props.baseUrl}/details/details`}
            render={() => <Details {...this.props} />}
          />
        </main>
      </div>
    );
  }
}

export default LocationDetails;
