import React, { Component } from 'react';
import { connect } from 'react-redux';
import classnames from 'classnames';
import { sortBy, values, min } from 'lodash';
import EventListPagination from './EventListPagination';
import {
  getDraftEvents,
  getUpcomingEvents,
  getUpcomingShifts,
  changeDraftEventsPage,
  changeUpcomingEventsPage,
  changeUpcomingShiftsPage,
} from 'redux/ducks/Event';
import { error } from 'redux/ducks/Flash';
import DatePanel from 'components/DatePanel';
import EventListItem from './EventListItem';
import { alertsForEvent } from 'services/ShiftAlertService';

const moment = require('moment');

export class EventList extends Component {
  componentDidMount() {
    if (this.props.session.authenticated) {
      if (this.props.drafts) {
        this.props.getDraftEvents();
      } else {
        this.props.getUpcomingEvents();
        this.props.getUpcomingShifts();
      }
    } else {
      console.error('NOT AUTHENTICATED');
    }
  }

  componentWillReceiveProps(newProps) {
    if (newProps.error && !this.props.error) {
      this.props.onError('An error occurred when loading the event list');
    }
    if (newProps.session.authenticated && !this.props.session.authenticated) {
      console.error('NOT AUTHENTICATED DEBUG');
      if (newProps.drafts) {
        newProps.getDraftEvents();
      } else {
        newProps.getUpcomingEvents();
        newProps.getUpcomingShifts();
      }
    }
  }

  minStartTime = (event) => {
    const shiftTimes = values(event.shifts.byId).map((s) => s.startTime);
    return min(shiftTimes);
  };

  compareEventDates = (event1, event2) => {
    if (event1.shifts.ids.length === 0) {
      return event2;
    }
    if (event2.shifts.ids.length === 0) {
      return event1;
    }

    const e1StartTime = event1.shifts.byId[event1.shifts.ids[0]].startTime;
    const e2StartTime = event2.shifts.byId[event2.shifts.ids[0]].startTime;

    return moment(e1StartTime).isAfter(e2StartTime);
  };

  groupByDate = () => {
    const dateMap = {
      dates: [],
      byDate: {},
    };

    for (const key in this.props.events) {
      const event = this.props.events[key];

      const shifts = {
        ids: [],
        byId: {},
      };

      let date = '';

      for (const shiftId of event.shifts.ids) {
        const shift = event.shifts.byId[shiftId];
        const startDate = moment(shift.startTime).format('YYYY-MM-DD');

        if (!this.props.drafts && !moment(startDate).isBefore(moment().startOf('day'))) {
          if (!dateMap.dates.includes(startDate)) {
            dateMap.dates.push(startDate);
            dateMap.byDate[startDate] = [];
          }

          if (date === '') {
            date = startDate;
          }

          if (date !== startDate) {
            dateMap.byDate[date].push({
              ...event,
              shifts: { ...shifts },
              alerts: alertsForEvent({ shifts }),
            });
            date = startDate;

            shifts.byId = {};
            shifts.ids = [];
          }

          shifts.byId[shift.id] = shift;
          shifts.ids.push(shift.id);
        }
      }
      if (shifts.ids.length > 0) {
        dateMap.byDate[date].push({
          ...event,
          shifts: { ...shifts },
          alerts: alertsForEvent({ shifts }),
        });
      }
    }

    dateMap.dates = dateMap.dates.sort();

    return dateMap;
  };

  processEvents = () => {
    let result = Object.values(this.props.events);

    if (!this.props.drafts) {
      result = result
        ?.map((event) => {
          for (const shift of Object.values(event.shifts.byId)) {
            const startDate = moment(shift.startTime).format('YYYY-MM-DD');
            const hasAnyModifications = Object.values(shift.jobs.byId).reduce((hasMods, job) => {
              return hasMods || !!job.jobModification;
            }, false);

            if (
              !this.props.drafts &&
              !hasAnyModifications &&
              moment(startDate).isBefore(moment().startOf('day'))
            ) {
              // Commented bellow lines - they also MODIFY the store
              // delete event.shifts.byId[shift.id];
              // remove(event.shifts.ids, (id => id === shift.id));
            }
          }

          return event;
        })
        .filter((e) => e.shifts.ids?.length > 0)
        .map((event) => {
          event.alerts = alertsForEvent(event);
          return event;
        });

      result = sortBy(result, this.minStartTime);
    }

    return result;
  };

  handlePageClick = (data) => {
    let selectedPage = data.selected + 1;

    if (this.props.eventsType === 'draft') {
      this.props.onDraftEventsPage(selectedPage);
    } else if (this.props.eventsType === 'upcoming') {
      this.props.onUpcomingEventsPage(selectedPage);
    }
  };

  render() {
    const dateHash = this.props.grouping === 'date' ? this.groupByDate() : [];
    const events = !this.props.grouping ? this.processEvents() : [];
    return (
      <div className="eventList">
        <ul className="list-table">
          <li className={classnames('head', { draft: this.props.drafts })}>
            <div></div>
            <div>Event</div>
            <div>Shift Details</div>
            <div className="stacked-text">Shifts Available</div>
            <div className="stacked-text">Shift Requests</div>
            <div className="stacked-text">Shifts Filled</div>
            <div>Alerts</div>
            <div>Actions</div>
          </li>
          {!this.props.events && !this.props.error && <div>Loading events...</div>}
          {this.props.events &&
            !this.props.error &&
            !this.props.grouping &&
            events.map((event) => {
              return <EventListItem item={event} key={event.id} />;
            })}
          {this.props.events &&
            !this.props.error &&
            this.props.grouping === 'date' &&
            dateHash.dates.map((date, index) => {
              return (
                <DatePanel
                  date={date}
                  events={dateHash.byDate[date]}
                  key={index}
                  rateTypes={this.props.enums.rateTypes}
                  ListItemComponent={EventListItem}
                />
              );
            })}
        </ul>

        {this.props.events &&
          !this.props.error &&
          this.props.pagination &&
          this.props.pagination.total_pages > 1 && (
            <EventListPagination
              pagination={this.props.pagination}
              handlePageClick={this.handlePageClick}
            />
          )}
      </div>
    );
  }
}

function mapDispatchToProps(dispatch, ownProps) {
  return {
    getDraftEvents: () => dispatch(getDraftEvents()),
    getUpcomingEvents: () => dispatch(getUpcomingEvents()),
    getUpcomingShifts: () => dispatch(getUpcomingShifts()),
    onError: (message) => dispatch(error(message)),
    onDraftEventsPage: (page) => dispatch(changeDraftEventsPage(page)),
    onUpcomingEventsPage: (page) => {
      if (ownProps.grouping === 'date') {
        dispatch(changeUpcomingShiftsPage(page));
      } else {
        dispatch(changeUpcomingEventsPage(page));
      }
    },
  };
}

function mapStateToProps(state, ownProps) {
  const events = ownProps.drafts
    ? state.event.events.draft
    : ownProps.grouping === 'date'
    ? state.event.eventsGroupedByShifts.upcoming
    : state.event.events.upcoming;
  const pagination = ownProps.drafts
    ? state.event.events.draftPagination
    : ownProps.grouping === 'date'
    ? state.event.eventsGroupedByShifts.upcomingPagination
    : state.event.events.upcomingPagination;
  const eventsType = ownProps.drafts ? 'draft' : 'upcoming';

  return {
    events,
    error: state.event.error,
    enums: state.enums,
    session: state.session,
    pagination,
    eventsType,
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(EventList);
