import React, { Component } from 'react';
import Select from 'react-select';
import HorizontalRule from 'components/HorizontalRule';
import { optionsForEnum, multiSelectOptionsForEnum, sportsFilter } from 'helpers/enums';
import { trackMixpanelPageView } from 'helpers/post';
import { getSettingName, getSettingDetailsName } from 'helpers/enums';
import { connect } from 'react-redux';
import classNames from 'classnames';
import ToolTip from 'scenes/V2/ToolTip';

export class EventProfile extends Component<Props> {
  componentDidMount() {
    if (!this.props.editMode) trackMixpanelPageView('create-event/profile', this.props.user);
  }

  onMultiSelectChangeAgeBrackets = (e) => {
    let items = [];
    e.map((it) => items.push(it.value));
    this.props.onChangeProfile('ageBracketIds', items);
  };

  getAgeBracketsByIds = (ageBrackets, ageBracketIds) =>
    ageBracketIds
      ? ageBracketIds.map((id) => ({
        value: (
          ageBrackets.find((a) => `${a.id}` === `${id}`) || {
            id: '',
            name: '',
          }
        ).id,
        label: (
          ageBrackets.find((a) => `${a.id}` === `${id}`) || {
            id: '',
            name: '',
          }
        ).name,
      }))
      : [];

  onFieldChange = (name: string) => (ev: SyntheticInputEvent<HTMLInputElement>) => {
    this.props.onChangeProfile(name, ev.target.value);
  };

  setLocations = (value) => {
    this.props.onDetailsChange('locationsNo', Math.min(value, 50));
  };

  addLocation = (num) => () => {
    const newLocations = Number(this.props.details.locationsNo || 0) + num;
    this.setLocations(newLocations < 1 ? 1 : newLocations);
  };

  getProfileTitle = () => {
    const { profile } = this.props;
    const setting = getSettingName(profile.eventSettingId);
    const settingDetail = getSettingDetailsName(profile.settingDetailId);

    if (setting !== 'Sports') return 'Job Name';

    return `${settingDetail} Name`;
  };

  render() {
    const { profile, enums, details } = this.props;
    const eventParticipantNumbersOptions = optionsForEnum(enums.eventParticipantNumbers);
    const sportOptions = optionsForEnum(sportsFilter(enums.sports));
    const genderOptions = optionsForEnum(enums.genders);
    const ageBracketOptions = multiSelectOptionsForEnum(enums.ageBrackets);
    const settingName = getSettingName(profile.eventSettingId);

    const title = `${profile.title ?? ''}`;
    const sportId = `${profile.sportId ?? ''}`;
    const genderId = `${profile.genderId ?? ''}`;
    const ageBracketIds = profile.ageBracketIds?.filter((id) => id !== '') || [];
    const participantNumbersId = `${profile.participantNumbersId ?? ''}`;
    const eventCode = `${profile.eventCode ?? ''}`;

    return (
      <div className="eventProfile">
        <form
          id="profileForm"
          noValidate
          ref="form"
          className={classNames('', {
            'was-validated': profile.wasValidated,
          })}
        >
          <div className="row">
            <div className="form-group col-sm-12">
              <label htmlFor="title">{this.getProfileTitle()}</label>
              <input
                className="form-control italic-placeholder nimbus-regular-font"
                type="text"
                name="title"
                value={title}
                onChange={this.onFieldChange('title')}
                required
                maxLength="255"
                placeholder="Ex: Soccer Showcase, Basketball Game, etc."
              />
              <div className="invalid-feedback nimbus-regular-font">
                {this.getProfileTitle()} is required
              </div>
            </div>
          </div>
          <HorizontalRule dashed />
          <div className="row">
            {['School', 'Sports'].includes(settingName) && (
              <div className="form-group col-md-12">
                <label htmlFor="sport">Sport</label>
                <select
                  className="form-control custom-select rounded-input nimbus-regular-font"
                  name="sport"
                  value={sportId}
                  onChange={this.onFieldChange('sportId')}
                  required
                >
                  {sportOptions}
                </select>
                <div className="invalid-feedback nimbus-regular-font">Sport is required</div>
              </div>
            )}
          </div>
          <div className="row">
            {['School', 'Sports'].includes(settingName) && (
              <div className="form-group col-md-6">
                <label htmlFor="ageBracket">Age</label>
                <input
                  name="ageBracket"
                  style={{ opacity: 0, height: 0 }}
                  value={ageBracketIds}
                  onChange={() => { }}
                  required
                />
                <Select
                  isMulti
                  name="ageBracket"
                  placeholder="Select all that apply"
                  onChange={this.onMultiSelectChangeAgeBrackets}
                  value={this.getAgeBracketsByIds(enums.ageBrackets, ageBracketIds)}
                  options={ageBracketOptions}
                  classNamePrefix="select"
                  className="nimbus-regular-font"
                />
                <div className="invalid-feedback nimbus-regular-font">Age is required</div>
              </div>
            )}
            {['School', 'Sports'].includes(settingName) && (
              <div className="form-group col-md-6">
                <label htmlFor="gender">Gender</label>
                <select
                  className="form-control custom-select rounded-input nimbus-regular-font"
                  name="gender"
                  value={genderId}
                  onChange={this.onFieldChange('genderId')}
                  required
                >
                  {genderOptions}
                </select>
                <div className="invalid-feedback nimbus-regular-font">Gender is required</div>
              </div>
            )}
          </div>
          <div className="row">
            <div className="form-group col-md-6">
              <label htmlFor="event_participant_numbers">Estimated Number of Participants</label>
              <select
                className="form-control custom-select rounded-input nimbus-regular-font"
                name="event_participant_numbers"
                value={participantNumbersId}
                onChange={this.onFieldChange('participantNumbersId')}
                required
              >
                {eventParticipantNumbersOptions}
              </select>
              <div className="invalid-feedback nimbus-regular-font">
                Estimated Number of Participants is required
              </div>
            </div>
            <div className="form-group col-md-6">
              <label htmlFor="title">Job code / Optional</label>
              <ToolTip
                id="tooltipJobCode"
                top="3px"
                fontSize="14px"
                bodyText="Option to add an identifier code to this job. The code will only be visible on your Finance Export to help with reconciliation."
              />
              <input
                className="form-control nimbus-regular-font"
                type="text"
                name="eventCode"
                value={eventCode}
                onChange={this.onFieldChange('eventCode')}
                maxLength="255"
                required={false}
              />
            </div>
          </div>
          <div className="row">
            <div className="col-sm-12">
              <label>How many locations are there?</label>
              <div className="input-group col-sm-4 p-0">
                <div className="input-group-append">
                  <span
                    type="button"
                    onClick={this.addLocation(-1)}
                    className="input-group-text remove-border-r"
                  >
                    <i className="fas fa-minus minus"></i>
                  </span>
                </div>
                <input
                  type="number"
                  name="locationsNo"
                  value={details.locationsNo}
                  onChange={(e) => this.setLocations(e.target.value)}
                  className={`form-control text-center pl-4 gotham-bold-font`}
                  placeholder="1"
                  min={1}
                  required
                />
                <div className="input-group-append">
                  <span type="button" onClick={this.addLocation(1)} className="input-group-text">
                    <i className="fas fa-plus plus"></i>
                  </span>
                </div>
                <div className="invalid-feedback nimbus-regular-font">
                  Number of locations is required
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  enums: state.enums,
  details: state.post.details,
  profile: state.post.profile,
  user: state.session.currentUser,
});

export default connect(mapStateToProps)(EventProfile);
