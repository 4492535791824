import React, { Component } from 'react';
import { UncontrolledTooltip } from 'reactstrap';

class ToolTip extends Component {
  render() {
    const { id, bodyText, fontSize = "16px", top = "0" } = this.props;
    return (
      <React.Fragment>
        <i
          className="material-icons ml-1 tooltip-icon"
          style={{ fontSize: fontSize, top: top }}
          id={id}
        >
          info
        </i>
        <UncontrolledTooltip target={id}>
          {bodyText}
        </UncontrolledTooltip>
      </React.Fragment>
    );
  }
}

export default ToolTip;