import ApiService from "./ApiService";

import { MedicalRecordSerializer } from "services/serializers";

class MedicalRecordService extends ApiService {
  constructor(session) {
    super(session);

    this.url = "/medical_records";
  }

  get(id) {
    return super.get(`${this.url}/${id}.json`, response => MedicalRecordSerializer.deserialize(response));
  }

  download(id) {
    return super.download(`${this.url}/${id}.pdf`, response => {
      return {
        id,
        pdf: response,
      }
    });
  }
}

export default MedicalRecordService;
