import React from "react";
import PropTypes from "prop-types";
import cx from "classnames";

const SectionBanner = ({ text, className }) => {
  return <div className={cx("sectionBanner", className)}>{text}</div>;
};

export default SectionBanner;

SectionBanner.propTypes = {
  text: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
};
