import React from 'react';

import ReactPaginate from 'react-paginate';
import './EventListPagination.css';

export default function EventListPagination(props) {
  return (
    <ReactPaginate
      previousLabel={' ← '}
      nextLabel={' → '}
      breakLabel={'...'}
      breakClassName={'break-me'}
      pageCount={props.pagination.total_pages || 0}
      marginPagesDisplayed={3}
      pageRangeDisplayed={3}
      initialPage={props.pagination.current_page - 1}
      onPageChange={props.handlePageClick}
      containerClassName={'pagination'}
      subContainerClassName={'pages pagination'}
      activeClassName={'active'}
    />
  );
}
