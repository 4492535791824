import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { switchStep } from 'redux/ducks/Post/Navigation';
import classNames from 'classnames';
import { profileValid, scheduleTypes, postValid, locationMissingErrors } from 'helpers/post';
import LocationNav from './LocationNav';

class Navigation extends Component {
  getSlug = () => this.props.location.pathname.split('/').pop();
  isSelected = (path) => {
    const SLUG_MAP = this.props.editMode
      ? {
          'profile': ['profile', 'tournament'],
          'eap': ['review', 'eap-type'],
          'schedule': ['type', ...Object.values(scheduleTypes), 'agenda', 'schedule-summary'],
          'all-locations': ['location-setup'],
          'rate': ['rate'],
        }
      : {
          'profile': ['profile', 'tournament'],
          'eap': ['review', 'eap-type'],
          'schedule': ['type', ...Object.values(scheduleTypes), 'agenda'],
          'all-locations': ['location-setup'],
          'rate': ['rate', 'schedule-summary'],
        };

    let slug = this.getSlug();

    slug = Object.keys(SLUG_MAP).find((key) => SLUG_MAP[key].includes(slug)) || slug;
    return slug === path;
  };

  profileTo = () => `${this.props.baseUrl}/profile`;
  postTo = () => `${this.props.baseUrl}/post`;

  postDisabled = () =>
    !postValid(this.props) || (!this.props.editMode && !this.props.navigation.visited.includes('post'));

  allLocationDisabled = () => (this.props.authenticated ? !this.props.details.id : !profileValid(this.props.profile));

  onClick = (step) => () => this.props.switchStep(step);

  hasProfileErrors = () => {
    const { wasValidated } = this.props.validations.post;
    return (wasValidated || this.props.editMode) && !profileValid(this.props.profile);
  };

  disabledCluster = (idx) => (step, editModeCallback) => {
    if (this.props.editMode) return editModeCallback();

    return !this.props.visited(idx, step);
  };

  render() {
    const { locations } = this.props;
    const IGNORED_LOCATIONS_NAVIGATION = locations.length === 1 ? [] : ['all-locations', 'profile'];

    return (
      <div className={classNames('navigation gotham-bold-font')}>
        <Link disabled={false} className="job-title" to={this.profileTo()} onClick={this.onClick('profile')}>
          {this.props.jobTitle}
        </Link>
        <Link
          disabled={false}
          className={classNames('', { selected: this.isSelected('profile') })}
          to={this.profileTo()}
          onClick={this.onClick('profile')}
        >
          {this.hasProfileErrors() && <div className="missing-alert">!</div>}
          Profile
        </Link>
        <i className="fas fa-caret-down nav-arrow"></i>

        {!IGNORED_LOCATIONS_NAVIGATION.some(this.isSelected) && (
          <React.Fragment>
            {locations.map((location, index) => {
              const no = index + 1;

              return (
                <LocationNav
                  missingErrors={locationMissingErrors({ ...this.props, location })}
                  oneLocation={locations.length === 1}
                  no={no}
                  key={no}
                  shiftLocation={location}
                  isSelected={this.isSelected}
                  disabledCluster={this.disabledCluster(location.idx)}
                  {...this.props}
                />
              );
            })}
            <i className="fas fa-caret-down nav-arrow"></i>
          </React.Fragment>
        )}
        {locations.length > 1 && (
          <>
            <Link
              disabled={this.allLocationDisabled()}
              className={classNames('', {
                selected: this.isSelected('all-locations'),
              })}
              to={`${this.props.baseUrl}/all-locations`}
              onClick={this.onClick('all-locations')}
            >
              All Locations
            </Link>
            <i className="fas fa-caret-down nav-arrow"></i>
          </>
        )}
        <Link
          disabled={this.postDisabled()}
          className={classNames('', { selected: this.isSelected('post') })}
          onClick={this.onClick('post')}
          to={this.postTo()}
        >
          Summary
        </Link>
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => ({
  ...state.post,
  jobTitle: state.post.profile.title || '',
  authenticated: state.session.authenticated,
  visited: (idx, step) => state.post.navigation.visited.includes(`L${idx}-${step}`),
});

const mapDispatchToProps = (dispatch) => ({
  switchStep: (step) => dispatch(switchStep({ currentStep: step })),
});

export default connect(mapStateToProps, mapDispatchToProps)(Navigation);
