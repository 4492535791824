import React, { useState } from 'react';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { isEmpty } from 'lodash';
import classnames from 'classnames';
import { CREDENTIALS_TO_REPORT, optionsForEnum } from 'helpers/enums';
import { REPORT_ISSUE_REASONS } from '../../../../helpers/enums';
import TrackingService from 'services/TrackingService';

const CredentialsIssueModal = ({ onCancel, job, at, eo, isOpen, ...props }) => {
  const [open, setOpen] = useState(false);
  const [error, setError] = useState({
    credential: '',
    issue: '',
    otherIssue: '',
  });
  const [credential, setCancelReason] = useState('');
  const [issue, setIssue] = useState('');
  const [otherIssue, setOtherIssue] = useState('');

  const onCancelModal = () => {
    if (onCancel) onCancel();
    toggle();
  };

  const toggle = () => {
    if (props.toggle) {
      props.toggle();
      return;
    }
    setOpen(!open);
  };

  const onClick = () => {
    if (isEmpty(credential)) {
      setError({ ...error, credential: 'This field is required' });
      return;
    }

    if (isEmpty(issue)) {
      setError({ ...error, issue: 'This field is required' });
      return;
    }

    if (['Other'].includes(issue) && isEmpty(otherIssue)) {
      setError({ ...error, otherIssue: 'This field is required' });
      return;
    }

    new TrackingService(props.session).track({
      event_name: 'report_credential_issue',
      event_data: {
        jp_id: eo.id,
        jp_email: eo.email,
        jp_company_name: eo.companyName,
        at_id: at.id,
        at_email: at.email,
        at_first_name: at.firstName,
        at_last_name: at.lastName,
        credential_name: credential,
        issue: issue,
        issue_other: issue === 'Other' ? otherIssue : '',
      },
    });

    toggle();
  };

  const onChange = (e) => {
    setCancelReason(e.target.value);
    setIssue('');
    setOtherIssue('');
    setError({
      credential: '',
      issue: '',
      otherIssue: '',
    });
  };

  const onChangeIssue = (e) => {
    setIssue(e.target.value);
    setError({
      credential: '',
      issue: '',
      otherIssue: '',
    });
  };

  const onChangeOtherIssue = (e) => {
    setOtherIssue(e.target.value);
    setError({
      credential: '',
      issue: '',
      otherIssue: '',
    });
  };

  const isModalOpen = typeof isOpen === 'boolean' ? isOpen : open;

  const submitDisabled = () =>
    isEmpty(credential) || isEmpty(issue) || (['Other'].includes(issue) && isEmpty(otherIssue));

  return (
    <div>
      <div className="v2-confirmation-modal" style={{ position: 'absolute' }}>
        <Modal isOpen={isModalOpen} toggle={toggle} className="v2-confirmation-modal-content">
          <ModalHeader className="header" toggle={toggle}>
            Report Credential Issue
          </ModalHeader>
          <ModalBody>
            <p>
              Complete this form to report an issue to Go4 about an athletic trainer's credential.
            </p>
            <div className="form-group pl-0 mt-4">
              <label>
                Credential:<span className="text-danger">*</span>
              </label>
              <select
                className={`form-control rounded-select custom-select ${
                  credential ? '' : 'text-secondary'
                }`}
                name="reason"
                value={credential}
                onChange={onChange}
                required
              >
                {optionsForEnum(CREDENTIALS_TO_REPORT, 'Select a credential')}
              </select>
              {error.credential && <div className="text-danger mt-1 w-100">{error.credential}</div>}
              <div className="form-group pl-0 mt-4">
                <label>
                  Issue:<span className="text-danger">*</span>
                </label>
                <select
                  className="form-control rounded-select custom-select"
                  name="issue"
                  value={issue}
                  onChange={onChangeIssue}
                  required
                  disabled={!credential}
                >
                  {optionsForEnum(REPORT_ISSUE_REASONS, 'Select a Issue')}
                </select>
                {issue === 'Other' && (
                  <div className="mt-3">
                    <input
                      type="text"
                      className="form-control"
                      name="otherIssue"
                      value={otherIssue}
                      onChange={onChangeOtherIssue}
                      required
                    />
                  </div>
                )}
                {error.otherIssue && (
                  <div className="text-danger mt-1 w-100">{error.otherIssue}</div>
                )}
              </div>
              {error.issue && <div className="text-danger mt-1 w-100">{error.issue}</div>}
            </div>
          </ModalBody>
          <ModalFooter className="actions">
            <button
              onClick={onClick}
              className={classnames('btn btn-success', {
                disabled: submitDisabled(),
              })}
            >
              Submit
            </button>
            <button className="btn confirm-btn" onClick={onCancelModal}>
              Go Back
            </button>
          </ModalFooter>
        </Modal>
      </div>
    </div>
  );
};

export default CredentialsIssueModal;
