import React from "react";
import cx from "classnames";

const Input = ({
  className,
  type,
  name,
  value,
  handleChange,
  hasError,
  ...rest
}) => (
  <input
    className={cx("input", className, { error: hasError })}
    name={name}
    type={type}
    value={value}
    onChange={handleChange}
    {...rest}
  />
);

export default Input;
