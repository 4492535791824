import React from 'react';

const ResponsibilitiesPartialForm = ({ shift, ...props }) => {
  return (
    <React.Fragment>
      <div className="row">
        <div className="form-group col-md-12">
          <label htmlFor="contractInfo.requirements_clinical">Responsibilities</label>
          <textarea
            className="form-control"
            style={{
              width: '100%',
              minHeight: '120px',
              border: '1px solid #b9b6ba',
            }}
            type="text"
            name="contractInfo.requirements_clinical"
            value={shift.contractInfo?.requirements_clinical}
            rows={3}
            placeholder={''}
            onChange={props.onFieldChange('contractInfo.requirements_clinical')}
          />
        </div>
        <div className="invalid-feedback">
          {shift.errorMessages['contractInfo.requirements_clinical']}
        </div>
      </div>
    </React.Fragment>
  );
};

export default ResponsibilitiesPartialForm;
