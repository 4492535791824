import { ofType } from 'redux-observable';

import { delay, filter, mapTo } from 'rxjs/operators';

import { endsWith } from 'lodash';

import { DISMISS } from './index';

export const dismissFlashEpic = (action$, store) => {
  return action$.pipe(
    filter(action => endsWith(action.type, 'FLASH_MESSAGE')),
    mapTo({
      type: DISMISS,
    }),
    delay(10000),
  )
};