// Actions
// ========================================================
export const GET_ALL_MEDICAL_RECORD_WAIVERS = 'go4ellis/MedicalRecordWaivers/GET_ALL_MEDICAL_RECORD_WAIVERS';
export const GET_ALL_MEDICAL_RECORD_WAIVERS_SUCCESS = 'go4ellis/MedicalRecordWaivers/GET_ALL_MEDICAL_RECORD_WAIVERS_SUCCESS';
export const GET_ALL_MEDICAL_RECORD_WAIVERS_ERROR = 'go4ellis/MedicalRecordWaivers/GET_ALL_MEDICAL_RECORD_WAIVERS_ERROR';

export const GET_MEDICAL_RECORD_WAIVER_SIGN_URL = 'go4ellis/MedicalRecordWaivers/GET_MEDICAL_RECORD_WAIVER_SIGN_URL';
export const GET_MEDICAL_RECORD_WAIVER_SIGN_URL_SUCCESS = 'go4ellis/MedicalRecordWaivers/GET_MEDICAL_RECORD_WAIVER_SIGN_URL_SUCCESS';
export const GET_MEDICAL_RECORD_WAIVER_SIGN_URL_ERROR = 'go4ellis/MedicalRecordWaivers/GET_MEDICAL_RECORD_WAIVER_SIGN_URL_ERROR';

export const CHECK_SIGNED_MEDICAL_RECORD_WAIVER = 'go4ellis/MedicalRecordWaivers/CHECK_SIGNED_MEDICAL_RECORD_WAIVER';
export const CHECK_SIGNED_MEDICAL_RECORD_WAIVER_SUCCESS = 'go4ellis/MedicalRecordWaivers/CHECK_SIGNED_MEDICAL_RECORD_WAIVER_SUCCESS';
export const CHECK_SIGNED_MEDICAL_RECORD_WAIVER_ERROR = 'go4ellis/MedicalRecordWaivers/CHECK_SIGNED_MEDICAL_RECORD_WAIVER_ERROR';


// Reducer Functions
// ========================================================

function getAllMedicalRecordWaiversSuccess(state, medicalRecordWaivers) {
    return {
      ...state,
      medicalRecordWaivers,
    }
}

function getMedicalRecordWaiverSignUrlSuccess(state, { url, id }) {
  return {
    ...state,
    signUrl: url,
    signId: id,
  }
}

function checkSignedMedicalRecordWaiverSuccess(state, url) {
  return {
    ...state,
    redirectUrl: url,
  }
}

// Action Creator Functions
// ========================================================
export function getAllMedicalRecordWaivers() {
  return { type: GET_ALL_MEDICAL_RECORD_WAIVERS };
}

export function getMedicalRecordSignUrl(id) {
  return { type: GET_MEDICAL_RECORD_WAIVER_SIGN_URL, payload: id };
}

export function checkSignedMedicalRecordWaiver(id) {
  return { type: CHECK_SIGNED_MEDICAL_RECORD_WAIVER, payload: id };
}

// Reducer
// ========================================================
const DEFAULT_STATE = {
  medicalRecordWaivers: [],
  signUrl: null,
  redirectUrl: null,
};

export default function reducer(state = DEFAULT_STATE, action = {}) {
  switch (action.type) {
    case GET_ALL_MEDICAL_RECORD_WAIVERS_SUCCESS:
      return getAllMedicalRecordWaiversSuccess(state, action.payload)
    case GET_MEDICAL_RECORD_WAIVER_SIGN_URL_SUCCESS:
      return getMedicalRecordWaiverSignUrlSuccess(state, action.payload)
    case CHECK_SIGNED_MEDICAL_RECORD_WAIVER_SUCCESS:
      return checkSignedMedicalRecordWaiverSuccess(state, action.payload)
    default: return state;
  }
}