import EventLocationSerializer from '../EventLocationSerializer';
import { groupBy, map } from 'lodash';
import moment from 'moment-timezone';
import zipcode_to_timezone from 'zipcode-to-timezone';
import JobSerializer from '../JobSerializer';
import V2JobSerializer from '../v2/JobSerializer'
import ShiftSerializer from './ShiftSerializer';

export default class DashboardSerializer {
  static applicantsDeserializer(data) {
    const groupedJobs = groupBy(data.jobs, (job) => job.shift_id);
    const shifts = map(data.shifts, (shift) => {
      let shiftLocation = data.locations.find(
        (location) => location.id === shift.event_location_id
      );
      shiftLocation = EventLocationSerializer.deserialize(shiftLocation);
      return {
        ...this.deserializeShift(shift, shiftLocation),
        jobs: groupedJobs[shift.id].map((job) => V2JobSerializer.deserialize(job, shiftLocation, data.athletic_trainers)),
      }
    });
    const groupedShifts = groupBy(shifts, (shift) => shift.eventId);

    const result = data.events.map((event) => ({
      ...this.deserializeEvent(event),
      shifts: groupedShifts[event.id]
    }));

    return result;
  }

  static deserializeEvent(event) {
    return {
      id: event.id,
      title: event.title,
      firstStartTime: moment(event.first_start_time).format(),
      statistics: {
        eventCapacity: event.statistics.event_capacity,
        shiftRequests: event.statistics.shift_requests,
        shiftsFilled: event.statistics.shifts_filled,
      },
    };
  }

  static deserializeJobModification(data) {
    const tz =
      zipcode_to_timezone.lookup(data.shift.event_location.address.zip_code) ||
      moment.tz.guess();

    const endTime = moment.tz(data.end_time, tz);
    const startTime = moment.tz(data.start_time, tz);
    const job = data.shift.jobs.find((job) => job.id === data.job_id);
    const jobStartTime = moment.tz(job.start_time, tz);
    const jobEndTime = moment.tz(job.end_time, tz);
    return {
      id: data.id,
      jobId: data.job_id,
      startTime,
      endTime,
      currentState: data.current_state,
      eventName: data.event_name,
      shift: ShiftSerializer.deserialize(data.shift),
      job: JobSerializer.deserialize(job, data.athletic_trainers),
      jobStartTime,
      jobEndTime,
    };
  }

  static deserializeShift(shift, location) {
    return {
      id: shift.id,
      startTime: shift.start_time,
      endTime: shift.end_time,
      eventId: shift.event_id,
      payRate: shift.pay_rate,
      rateTypeId: shift.rate_type_id,
      shiftCapacity: shift.shift_capacity,
      eventLocation: location,
    };
  }

  static deserializeShiftWithNoApps(shift) {
    const tz =
      zipcode_to_timezone.lookup(shift.event_location.address.zip_code) ||
      moment.tz.guess();

    const endTime = moment.tz(shift.end_time, tz);
    const startTime = moment.tz(shift.start_time, tz);

    const result = {
      id: shift.id,
      startTime: startTime,
      endTime: endTime,
      shiftCapacity: shift.shift_capacity,
      payRate: shift.pay_rate,
      rateTypeId: shift.rate_type_id,
      event: {
        id: shift.event.id,
        title: shift.event.title,
        eventLogo: shift.event.event_logo,
        payRate: shift.event.pay_rate,
        rateTypeId: shift.event.rate_type_id,
        firstStartTime: shift.event.first_start_time,
      },
      location: shift.event_location.name,
      boostedAt: shift.boosted_at,
    };

    if (shift.event_location) {
      result['locationId'] = shift.event_location.id;
    }

    return result;
  }

  static deserializeUser(user) {
    return {
      id: user.id,
      firstName: user.first_name,
      lastName: user.last_name,
      name: `${user.first_name} ${user.last_name}`
    };
  }
}
