import ApiService from './ApiService';

import { PasswordSerializer } from 'services/serializers';

class PasswordService extends ApiService {
  constructor(session) {
    super(session);

    this.url = '/password.json';
  }

  reset(email) {
    return super.post(this.url, PasswordSerializer.serializeReset(email),
      (response) => PasswordSerializer.deserialize(response));
  }

  update({password, confirmation}) {
    return super.patch(this.url, PasswordSerializer.serialize(password, confirmation),
      (response) => PasswordSerializer.deserialize(response));
  }

  validateToken(token) {
    return super.get(`/password/edit.json?reset_password_token=${token}`,
      (response) => PasswordSerializer.deserializeHeaders(response));
  };

}

export default PasswordService;