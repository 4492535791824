export default [
  {
    title: "Lead Software Engineer",
    location: "Work From Home",
    department: "Engineering",
    id: "00002",
    description:
      "The Lead Software Engineer is a key member of the Go4 team and will report directly to the Chief Executive Officer and touches all parts of the company - product development, business requirements, marketing, operations, sales, and customer success. The primary role of the Lead Software Engineer will be to provide technical leadership and direction for the entire Go4 platform.",
    bullits: [
      { text: "Essential Duties and Responsibilities:", head: true },
      "Oversee the software and system architecture at Go4",
      "Design, implement, and test software that connects thousands of healthcare professionals with event operators",
      "Keep servers running, managing tasks, and deployments",
      "Ensure high-quality, well-designed code across the engineering team",
      { text: "What you'll do:", head: true },
      "Wear multiple hats in a growth phase company in a start-up environment from software development to business requirements Responsible for providing technical guidance and leadership for the Go4 team",
      "Monitor and maintain system efficiencies and performance through internal logging and health monitoring tools",
      "Lead deployment activities within customer environments (Dev, QA, Production, etc.)","Design and implement code changes to address identified bugs and/or optimize solutions for scalability and reliability",
      { text: "The Ideal Candidate has:", head: true },
      "5+ years of experience as software engineer with proven experience in leading role",
      "Bachelor’s Degree from accredited University with a a degree in Computer Science, Mathematics, Operations Research, or Engineering",
      "Experience developing software applications across the full-stack",
      "Knowledge of Ruby on Rails, React and React Native is required",
      "Experience with AWS ECS and PostgreSQL is preferred", 
      "An excellent understanding of all data structures and ability to write complex algorithms Excellent communicator, whether in person, on the phone, or in writing",
      "Must enjoy the challenge of technical concepts and possess the ability to communicate those concepts at varying levels",
      "Analytical problem-solving & decision-making - Ability to research, analyze, identify viable options, draw sound conclusions, present findings and make thoughtful recommendations considering overall risk and short-term and long-term impact.",
      "Self-starter who takes initiative and uses perseverance and curiosity to identify a path forward when solutions are not obvious Strong sense of urgency, detail-oriented with the ability to manage and prioritize work flow",
      "Team oriented, interpersonal, highly-motivated, passionate and creative team-player with ability to develop and maintain collaborative relationships with all levels within and external to the organization.",
      { text: "About the Compensation and Working at Go4:", head: true },
      'Location: USA',
      "Competitive Salary and Bonus Structure based on experience",
      "Health, Dental and Voluntary Benefits – You will be eligible to participate in the Company’s health, dental and voluntary benefits program after 30 days of employment.  Go4E contributes 50% to the cost of these programs",
      "Paid Time Off (“PTO”) - Go4E believes that employees should have the freedom to manage their work and personal obligations as they see fit. Accordingly, employees can take as many personal days as they need, subject to advance approval and adequate coverage of work responsibilities",
      "Paid Holidays - New Year’s Day, Martin Luther King Jr. Day, Memorial Day, Independence Day, Labor Day, Election Day, Thanksgiving Day, and Christmas Day",
      "Equity Incentive Plan 401K Plan",
      { text: "About Go4:", head: true },
      "Our mission is to get Athletic Trainers on every sideline, everywhere. They are critical to the health and safety of youth athletes.",
      "Our ethos is make it happen",
      "We believe in problems to solve, not orders to follow",
      "Experiment, try, fail, succeed",
      "Just make it happen",
    ],
  },
];
