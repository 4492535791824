// @flow
import type { DispatchAPI } from 'redux';

import type { Epic } from 'redux-observable';
import { ofType } from 'redux-observable';

import { Observable } from 'rxjs';
import { mergeMap, map, filter, switchMap } from 'rxjs/operators';

import { isEqual, isString, isFunction, isArray, startCase } from 'lodash';

import { postcodeValidator } from 'postcode-validator';
import zipcodes from 'zipcodes';

import type { AppState } from './index';

import { DESTROY_SESSION_SUCCESS, DESTROY_SESSION_ERROR } from './Session';
import { HEAR_ABOUT_US_ADDITIONAL_OPTIONS } from 'redux/ducks/Registration';
import { POST_STORAGE_KEY } from './Post/actions';
import isEmail from 'validator/lib/isEmail';

// Classes
// ========================================================
export class LocalStorageMock {
  store = {};

  clear() {
    this.store = {};
  }

  getItem(key: string) {
    return this.store[key] || null;
  }

  setItem(key: string, value: any) {
    this.store[key] = value.toString();
  }

  removeItem(key: string) {
    delete this.store[key];
  }
}

export function isNumeric(value) {
  if (!value) {
    return false;
  }
  return value.match('^[\\d]+$');
}

export function isRequiredValidation(value) {
  return value === null || value === undefined || value === '';
}

export class Validator {
  constructor(config) {
    this.activeFields = [];
    this.allFields = Object.keys(config);
    this.config = config;
  }

  allValidated() {
    return isEqual(
      this.allFields
        .filter((f) => this.config[f].required || this.config[f].hasValue)
        .slice()
        .sort(),
      this.activeFields
        .filter((f) => this.config[f].required || this.config[f].hasValue)
        .slice()
        .sort()
    );
  }

  validate(target, field = null) {
    if (field && this.activeFields.indexOf(field) === -1 && this.allFields.indexOf(field) > -1) {
      this.activeFields.push(field);
    }

    return this.validateFields(target, this.activeFields);
  }

  validateAll(target) {
    return this.validateFields(target, this.allFields);
  }

  validateFields(target, fields) {
    const result = {};

    for (const field of fields) {
      const fieldErrors = this.validateField(field, target);

      if (fieldErrors) {
        result[field] = fieldErrors;
      }
    }

    return result;
  }

  validateField(field, target) {
    const validations = this.config[field];
    let result = null;

    if (!validations) {
      return result;
    }

    const keys = Object.keys(validations);

    for (const key of keys) {
      switch (key) {
        case 'zipcode':
          result =
            postcodeValidator(target[field], 'US') && !!zipcodes.lookup(target[field])
              ? null
              : 'This field must be a valid US zipcode address';
          break;
        case 'email':
          result = isEmail(target[field]) ? null : 'This field must be a valid email address';
          break;
        case 'hasValue':
          result =
            target[field] === validations[key]
              ? null
              : `This field's value must equal '${validations[key]}'`;
          break;
        case 'length':
          result =
            target[field] && target[field].length === validations[key]
              ? null
              : `This field's required length is ${validations[key]} character${
                  validations[key] === 1 ? '' : 's'
                }`;
          break;
        case 'minLength':
          if (!target[field]) {
            break;
          }

          result =
            target[field].length >= validations[key]
              ? null
              : `This field's minimum length is ${validations[key]} character${
                  validations[key] === 1 ? '' : 's'
                }`;
          break;
        case 'maxLength':
          if (!target[field]) {
            break;
          }
          result =
            target[field].length <= validations[key]
              ? null
              : `This field's maximum length is ${validations[key]} character${
                  validations[key] === 1 ? '' : 's'
                }`;
          break;
        case 'matches':
          result =
            target[field] === target[validations[key]]
              ? null
              : `This field must match the '${startCase(validations[key])}' field`;
          break;
        case 'ofType':
          if (!target[field]) {
            break;
          }
          if (validations[key] === 'number') {
            result = isNumeric(target[field]) ? null : 'This field must contain only digits';
          }
          break;
        case 'required':
          result = isRequiredValidation(target[field]) ? 'This field is required' : null;
          break;
        case 'requiredAlongside':
          result =
            (target[field] && target[validations[key]]) ||
            (!target[field] && !target[validations[key]])
              ? null
              : `This field is required alongside '${startCase(validations[key])}'`;
          break;
        case 'greaterThanOrEqual':
          const otherField = validations[key];
          result =
            target[field] && target[otherField] && target[field] >= target[otherField]
              ? null
              : `${startCase(field)} must be greater or equal to ${startCase(otherField)}`;
          break;
        case 'dateGreaterThanOrEqual':
          const otherDateField = validations[key];
          const date1 = new Date(target[field]);
          const date2 = new Date(target[otherDateField]);
          result =
            target[field] && target[otherDateField] && date1.getTime() >= date2.getTime()
              ? null
              : `${startCase(field)} must be greater or equal to ${startCase(otherDateField)}`;
          break;
        case 'hasDetailsWhenOther':
          const detailsOtherField = validations[key];
          const errorMessage =
            target[field] == 'Other'
              ? 'This field requires more details'
              : 'This field is required';

          result =
            HEAR_ABOUT_US_ADDITIONAL_OPTIONS.includes(target[field]) &&
            isRequiredValidation(target[detailsOtherField])
              ? errorMessage
              : null;
          break;
        default:
          break;
      }

      if (result) {
        break;
      }
    }

    return result;
  }
}

// Functions
// ========================================================
export const repeatedEpic = (interval, startTrigger, action) => (action$, store) =>
  action$.pipe(
    ofType(startTrigger),
    filter((action) => {
      if (action.payload) {
        return !action.payload.repeated;
      } else {
        return true;
      }
    }),
    switchMap(() =>
      Observable.interval(interval)
        .mapTo({ ...action, payload: { ...action.payload, repeated: true, disableSpinner: true } })
        .takeUntil(action$.ofType(DESTROY_SESSION_SUCCESS, DESTROY_SESSION_ERROR))
    )
  );

export function basicCreator(typeName: string) {
  return (payload: any) => ({ type: typeName, payload });
}

export function basicEpic<T>(
  listenFor: string,
  mapperFn: (payload: any, state: AppState) => Promise<T>
): AppEpic {
  return function (action$, store) {
    return action$.pipe(
      ofType(listenFor),
      mergeMap((action) =>
        mapperFn(action.payload, store.getState())
          .then((result) => ({ type: listenFor + '_SUCCESS', payload: result }))
          .catch((error) => ({ type: listenFor + '_ERROR', payload: error }))
      )
    );
  };
}

export function loadFromStorage(key) {
  return JSON.parse(localStorage.getItem(key)) || [];
}

export function mappingEpic(
  listenFor: string | string[],
  mapTo: string | Action | ((Action) => Action)
): AppEpic {
  mapTo = isString(mapTo) ? { type: mapTo } : mapTo;
  const mapper = isFunction(mapTo) ? mapTo : () => mapTo;
  listenFor = isArray(listenFor) ? listenFor : [listenFor];
  return (action$) => action$.pipe(ofType(...listenFor), map(mapper));
}

export function newEventLocation(): EventLocation {
  return {
    address: {
      streetAddress: '',
      city: '',
      state: '',
      zipCode: '',
    },
    name: '',
    details: '',
    latitude: null,
    longitude: null,
    phoneNumber: null,
    formattedAddress: '',
  };
}

export function writeToStorage(key, value) {
  const valueCopy =
    key === POST_STORAGE_KEY
      ? {
          ...value,
          locations: value.locations.map((location) => ({ ...location, documents: [] })),
          eap: {
            ...value.eap,
            forms: value.eap.forms.map((form) => ({
              ...form,
              venueMapUrl: null,
              uploadedPlanPreview: null,
            })),
          },
        }
      : value;

  localStorage.setItem(key, JSON.stringify(valueCopy));
}

// Types
// ========================================================
export type Action = {
  type: string,
  payload: any,
};

export type ActionCreator = (...args: any[]) => Action;

export type Address = {
  id?: ?number,
  streetAddress: string,
  city: string,
  state: string,
  zipCode: string,
};

export type AppEpic = Epic<AppState, Action, empty>;

export type EpicCreator = (...args: any[]) => AppEpic;

export type Dispatch = DispatchAPI<Action>;

export type Enum = {
  name: string,
  id: number,
};

export type Enums = {
  sports: Enum[],
  genders: Enum[],
  rateTypes: Enum[],
  eventTypes: Enum[],
  eventParticipantNumbers: Enum[],
  settingTypes: Enum[],
  professions: Enum[],
  eventSettings: Enum[],
  settingDetails: Enum[],
  jobDescriptions: Enum[],
};

export type EventLocation = {
  id?: ?number,
  address: Address,
  name: string,
  details: string,
  latitude: ?number,
  longitude: ?number,
  phoneNumber: ?string,
  formattedAddress?: string,
};
