import React from 'react';
import { get } from 'lodash';

const FacilityInstruction = ({ field, label, placeHolder, onChange, form }) => {
  const value = get(form, field, '');

  return (
    <div className="pb-2">
      <div className="d-flex">
        <label htmlFor={field} className="job-details-section-title gotham-medium-font">
          {label}
        </label>
        <div className="label-line" />
      </div>
      <textarea
        className="form-control nimbus-regular-font"
        style={{ width: '100%', minHeight: '60px' }}
        name={field}
        id={field}
        rows={2}
        placeholder={placeHolder}
        onChange={onChange}
        value={value}
        type="text"
      />
    </div>
  );
};

export default FacilityInstruction;
