import HorizontalRule from 'components/HorizontalRule';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import ShiftsWithNoApps from './Widgets/ShiftsWithNoApps';
import ApplicantsToReview from './Widgets/ApplicantsToReview';
import { getApplicantsEvents } from 'redux/ducks/Dashboard';
import './DashboardV2.scss';
import classNames from 'classnames';
import ShiftModifications from './Widgets/ShiftModifications';
import ATFeedback from './Widgets/ATFeedback';
import { withRouter } from 'react-router-dom';
import { get } from 'lodash';
import { trackMixpanelPageView } from 'helpers/post';
import { changeProfileField } from 'redux/ducks/Post/Profile';
import Upcoming from 'components/V2/EventList/Upcoming';

class DashboardV2 extends Component {
  state = {
    page: '',
    showAlert: {
      // noAppsShifts: false,
      upcomingJobs: false,
      applicantsToReview: true,
      reviews: true,
    },
  };

  componentDidMount() {
    const urlSearchString = get(this.props.location, 'search');
    const params = new URLSearchParams(urlSearchString);
    const page = params.get('widget');
    const searchIndex = WIDGETS.findIndex((w) => w.id === page);

    this.setState({ page: searchIndex === -1 ? 'upcomingJobs' : page });

    trackMixpanelPageView('/dashboard', this.props.user);

    this.props.changeProfileField('isFirstEvent', false);
  }

  onClickWidget = (id) => {
    this.setState({
      showAlert: { ...this.state.showAlert, [id]: false },
      page: id,
    });
  };

  isActiveWidget = (id) => {
    const widgetStatus = {
      applicantsToReview: this.props.totalPendingApplicants > 0,
      upcomingJobs: false,
      reviews: this.props.reviewsCount > 0,
      noAppsShifts: this.props.shiftsWithNoAppsCount > 0,
      modifications: this.props.modificationsCount > 0,
    };

    return widgetStatus[id] || false;
  };

  onClickResource = (id, link) => {
    window.mixpanel.track(`Resource${id}Click`, {
      eo_id: this.props.user.id,
      eo_email: this.props.user.email,
      eo_first_name: this.props.user.firstName,
      eo_last_name: this.props.user.lastName,
    });
    window.open(link, '_blank');
  };

  render() {
    const hasResources =
      this.props.resources &&
      this.props.resources.some((resource) => {
        return !!resource.title && !!resource.link && !!resource.imageLink;
      });
    return (
      <div className="dashboardV2">
        <div className="header">
          <div className="col-md-12">
            <h1>Dashboard</h1>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-12">
            <div className="widgets">
              {WIDGETS.map(({ id, title, tooltip }) => {
                return (
                  <div
                    key={id}
                    id={id}
                    className={classNames('widget', 'col-md-3', {
                      active: this.state.page === id,
                    })}
                    onClick={() => this.onClickWidget(id)}
                  >
                    <div className="widget-title">{title}</div>
                    <HorizontalRule dashed margin="5px" />
                    {this.state.page === id && id !== 'upcomingJobs' ? (
                      <div className="widget-tooltip">{tooltip}</div>
                    ) : (
                      <div className="widget-count">
                        {id === 'noAppsShifts' &&
                          String(this.props.shiftsWithNoAppsCount).padStart(3, '0')}
                        {id === 'upcomingJobs' && String(this.props.upcomingJobsCount).padStart(3, '0')}
                        {id === 'applicantsToReview' &&
                          String(this.props.totalPendingApplicants).padStart(3, '0')}
                        {id === 'reviews' && String(this.props.reviewsCount).padStart(3, '0')}
                        {id === 'modifications' &&
                          String(this.props.modificationsCount).padStart(3, '0')}
                      </div>
                    )}

                    {this.isActiveWidget(id) && <div className="widget-alert">!</div>}
                  </div>
                );
              })}
            </div>
            {this.state.page === 'noAppsShifts' && (
              <ShiftsWithNoApps {...WIDGETS.find(({ id }) => id === 'noAppsShifts')} />
            )}
            {this.state.page === 'upcomingJobs' && (
              <Upcoming from='dashboard' {...WIDGETS.find(({ id }) => id === 'upcomingJobs')} />
            )}
            {this.state.page === 'applicantsToReview' && (
              <ApplicantsToReview {...WIDGETS.find(({ id }) => id === 'applicantsToReview')} />
            )}
            {this.state.page === 'reviews' && (
              <ATFeedback {...WIDGETS.find(({ id }) => id === 'reviews')} />
            )}
            {this.state.page === 'modifications' && (
              <ShiftModifications {...WIDGETS.find(({ id }) => id === 'modifications')} />
            )}
          </div>
        </div>
        {hasResources && (
          <div className="row">
            <div className="col-lg-12" style={{ paddingTop: '30px' }}>
              <div className="content-feed">
                <div className="content-feed-header">
                  <h6>RESOURCES</h6>
                </div>
                <div className="content-feed-body">
                  {this.props.resources.map((resource, idx) => {
                    if (!resource.title || !resource.link || !resource.imageLink) return null;
                    return (
                      <div
                        key={idx}
                        className="resource"
                        onClick={() => this.onClickResource(idx + 1, resource.link)}
                      >
                        <img alt="resource" src={resource.imageLink}></img>
                        <h6>{resource.title}</h6>
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    reviewsCount: state.dashboard.reviews.pagination.total,
    shiftsWithNoAppsCount: state.dashboard.shifts.pagination.total,
    modificationsCount: state.dashboard.modifications.pagination.total,
    totalPendingApplicants: state.dashboard.applicants.totalPendingApplicants || 0,
    upcomingJobsCount: state.event.events.upcomingPagination.total || 0,
    resources: state.dashboard.resources,
    user: state.session.currentUser,
  };
};

const mapDispatchToProps = (dispatch) => ({
  getApplicantsEvents: () => dispatch(getApplicantsEvents()),
  changeProfileField: (name, value) => dispatch(changeProfileField({ name, value })),
});

const WIDGETS = [
  // {
  //   id: 'noAppsShifts',
  //   title: 'Shifts w/ No Applications',
  //   tooltip:
  //     'Most jobs receive applications within 48 hrs. These jobs have yet to receive applications in 48+ hrs.',
  //   text: 'Boost rates to attract applicants',
  // },
  {
    id: 'upcomingJobs',
    title: 'Upcoming Jobs',
    tooltip:
      '',
    text: 'Upcoming Jobs',
  },
  {
    id: 'applicantsToReview',
    title: 'Applicants to Review',
    tooltip:
      'Unanswered applications expire after 7 days. Once an AT is accepted, they have 24 hrs to confirm the job.',
    text: 'Respond to applicants within 7 days',
  },
  {
    id: 'modifications',
    title: 'Shift Modifications',
    tooltip:
      "ATs can modify the times of a completed shift. You have 24hrs to review before it's auto-accepted.",
    text: 'Review within 24 Hrs',
  },
  {
    id: 'reviews',
    title: 'Provide AT Feedback',
    tooltip:
      'Provide feedback on ATs that recently worked for you. Go4 will not share this feedback with the AT.',
    text: 'Submit your feedback',
  },
];

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(DashboardV2));
