import React from 'react';
import classnames from 'classnames';

const FacilitiesPartialForm = ({ shift, ...props }) => {
  return (
    <React.Fragment>
      <div className="row">
        <div className="form-group col-md-12">
          <label htmlFor="contractInfo.supervising_physician">
            Facilities & Resources Supervising Physician
          </label>
          <textarea
            className="form-control"
            style={{
              width: '100%',
              minHeight: '120px',
              border: '1px solid #b9b6ba',
            }}
            type="text"
            name="contractInfo.supervising_physician"
            value={shift.contractInfo?.supervising_physician}
            rows={3}
            placeholder={''}
            onChange={props.onFieldChange('contractInfo.supervising_physician')}
          />
        </div>
        <div className="invalid-feedback">
          {shift.errorMessages['contractInfo.supervising_physician']}
        </div>
      </div>
      <div className="row">
        <div className="form-group col-md-12">
          <label htmlFor="contractInfo.supplies_and_order">
            Facilities & Resources Supplies Available & Order Process
          </label>
          <textarea
            className="form-control"
            style={{
              width: '100%',
              minHeight: '120px',
              border: '1px solid #b9b6ba',
            }}
            type="text"
            name="contractInfo.supplies_and_order"
            value={shift.contractInfo?.supplies_and_order}
            rows={3}
            placeholder={''}
            onChange={props.onFieldChange('contractInfo.supplies_and_order')}
          />
        </div>
        <div className="invalid-feedback">
          {shift.errorMessages['contractInfo.supplies_and_order']}
        </div>
      </div>
      <div className="row">
        <div className="form-group col-md-12">
          <label htmlFor="contractInfo.equipment_available">
            Facilities & Resources Equipment Available
          </label>
          <textarea
            className="form-control"
            style={{
              width: '100%',
              minHeight: '120px',
              border: '1px solid #b9b6ba',
            }}
            type="text"
            name="contractInfo.equipment_available"
            value={shift.contractInfo?.equipment_available}
            rows={3}
            placeholder={''}
            onChange={props.onFieldChange('contractInfo.equipment_available')}
          />
        </div>
        <div className="invalid-feedback">
          {shift.errorMessages['contractInfo.equipment_available']}
        </div>
      </div>
      <div className="row">
        <div className="form-group col-md-12">
          <label htmlFor="contractInfo.onsite_transportation">
            Facilities & Resources Onsite Transportation
          </label>
          <textarea
            className="form-control"
            style={{
              width: '100%',
              minHeight: '120px',
              border: '1px solid #b9b6ba',
            }}
            type="text"
            name="contractInfo.onsite_transportation"
            value={shift.contractInfo?.onsite_transportation}
            rows={3}
            placeholder={''}
            onChange={props.onFieldChange('contractInfo.onsite_transportation')}
          />
        </div>
        <div className="invalid-feedback">
          {shift.errorMessages['contractInfo.onsite_transportation']}
        </div>
      </div>
      <div className="row">
        <div className="form-group col-md-12">
          <label htmlFor="contractInfo.court_details">
            Facilities & Resources Field/Court Details
          </label>
          <textarea
            className="form-control"
            style={{
              width: '100%',
              minHeight: '120px',
              border: '1px solid #b9b6ba',
            }}
            type="text"
            name="contractInfo.court_details"
            value={shift.contractInfo?.court_details}
            rows={3}
            placeholder={''}
            onChange={props.onFieldChange('contractInfo.court_details')}
          />
        </div>
        <div className="invalid-feedback">
          {shift.errorMessages['contractInfo.court_details']}
        </div>
      </div>
      <div className="row">
        <div className="form-group col-md-12">
          <label htmlFor="contractInfo.emr_subscription">
            Facilities & Resources EMR Subscription
          </label>
          <textarea
            className="form-control"
            style={{
              width: '100%',
              minHeight: '120px',
              border: '1px solid #b9b6ba',
            }}
            type="text"
            name="contractInfo.emr_subscription"
            value={shift.contractInfo?.emr_subscription}
            rows={3}
            placeholder={''}
            onChange={props.onFieldChange('contractInfo.emr_subscription')}
          />
        </div>
        <div className="invalid-feedback">
          {shift.errorMessages['contractInfo.emr_subscription']}
        </div>
      </div>
    </React.Fragment>
  );
};

export default FacilitiesPartialForm;
