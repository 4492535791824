import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Redirect, Route } from 'react-router-dom';

export class ParentRoute extends Component {
  render() {
    const { component: Component, ...rest } = this.props;
    return (
      <Route
        {...rest}
        render={props =>
          (this.props.authenticated && this.props.user.role === "Parent") ? (
            <Component {...props} />
          ) : (
            <Redirect
              to={{
                pathname: "/v2/parent/login",
                state: { from: props.location }
              }}
            />
          )
        }
      />
    )
  }
}

function mapStateToProps(state) {
  return {
    authenticated: state.session.authenticated,
    user: state.session.currentUser,
  };
}

export default connect(
  mapStateToProps,
)(ParentRoute);
