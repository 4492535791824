import React, { useState } from 'react';
import { SESSION_EMAIL_KEY } from "services/SessionService";

const LoginForm = (props) => {
  const [email, setEmail] = useState(
    new URLSearchParams(props.location.search).get("email") ||
    localStorage.getItem(SESSION_EMAIL_KEY) ||
    ''
  );
  const [password, setPassword] = useState('');
  const [rememberMe, setRememberMe] = useState(false);

  const onSubmit = (e) => {
    e.preventDefault();
    props.onSubmit({ email, password, rememberMe });
  }

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  }

  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
  }

  const handleRememberMeChange = (e) => {
    setRememberMe(e.target.checked);
  }

  return (
    <form className="loginForm" onSubmit={onSubmit}>
      <label>Login</label>
      <input type="email"
        name="email"
        placeholder="EMAIL"
        value={email}
        onChange={handleEmailChange} />
      <input type="password"
        name="password"
        placeholder="PASSWORD"
        value={password}
        onChange={handlePasswordChange} />
      <div>
        <label className="checkbox">
          Remember Me
          <input type="checkbox"
            name="rememberMe"
            value={rememberMe}
            onChange={handleRememberMeChange} />
          <span></span>
        </label>
        <a className='forgot-password' href="/forgot-password">Forgot Password?</a>
      </div>
      <button type="submit" className="btn btn-danger bold-button">Login</button>
    </form>
  );
}

export default LoginForm;