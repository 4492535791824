export const TITLES = {
  national_certification: 'BOC Certification',
  state_certification_or_license: 'State Certification or License',
  proof_of_professional_liability_insurance: 'Professional Liability Insurance',
  standing_physician_orders: 'Standing Physician Orders',
  child_abuse_clearances: 'Child Abuse Clearances',
  resume: 'Resume',
  background_check: 'Background Check',
  safe_sport: 'U.S. Center for SafeSport',
  recent_work_experience: 'Recent Work Experience',
  current_role: 'Current Role: ',
  cpr_certification: 'CPR Certification',
  education: 'Education',
  work_experience: 'Work Experience',
};

export const HEAD_TITLES = {
  national_certification: 'BOC Certification',
  state_certification_or_license: 'State Certification or License',
  proof_of_professional_liability_insurance: 'Professional Liability Insurance',
  standing_physician_orders: 'Standing Physician Orders',
  child_abuse_clearances: 'Child Abuse Clearances',
  resume: 'Resume',
  background_check: 'Background Check',
  safe_sport: 'U.S. Center for SafeSport',
  recent_work_experience: 'Recent Work Experience',
  current_role: 'Current Role',
  acls_bls_certification: 'ACLS/BLS Certification',
  license: 'License',
  cpr_certification: 'CPR Certification',
  education: 'Education',
  work_experience: 'Work Experience',
};

export const PRIORITY_FILTER = {
  current_role: 1,
  license: 2,
  acls_bls_certification: 3,
  recent_work_experience: 4,

  national_certification: 1,
  proof_of_professional_liability_insurance: 2,
  state_certification_or_license: 3,
  background_check: 4,
  child_abuse_clearances: 5,
  nata_number: 6,
  npi_number: 7,
  standing_physician_orders: 8,
  safe_sport: 9,
  cpr_certification: 10,
};

export const DOCUMENTS = {
  national_certification: 'Certification',
  state_certification_or_license: 'License',
  license: 'License',
  acls_bls_certification: 'Certification',
  proof_of_professional_liability_insurance: 'PLI',
  standing_physician_orders: 'Standing Physician Orders',
  child_abuse_clearances: 'Child Abuse Clearances',
  resume: 'Resume',
  background_check: 'Background Check',
  safe_sport: 'U.S. Center for SafeSport',
  current_role: 'Current Role:',
  recent_work_experience: 'Recent Work Experience: ',
};

export const SPECIALTIES = {
  cath_lab: 'Cath Lab',
  consciouse_sedation: 'Conscious Sedation',
  er_ed: 'ER/ED (Emergency Room)',
  icu: 'ICU',
  labor_delivery: 'Labor & Delivery (L&D)',
  long_term_care: 'Long Term Care (LTACH)',
  medical_icu: 'Medical ICU',
  med_surg: 'Med-Surg',
  neonatal_intensive_care_unit: 'Neonatal ICU',
  neuro_icu: 'Neuro ICU',
  nursery_rn: 'Nursery RN',
  operating_room_circulator: 'Operating Room Circulator',
  operating_room_scrub_nurse: 'Operating Room Scrub Nurse',
  orthopaedics: 'Orthopedics',
  pacu: 'PACU (Post Anesthesia Care Unit)',
  pediatrics: 'Pediatrics',
  pre_op: 'Pre-Op',
  psych: 'Psych',
  rehab: 'Rehab',
  rnfa: 'RNFA (RN First Assist)',
  skilled_nursing: 'Skilled Nursing',
  stepdown_icu: 'Stepdown ICU',
  surgical_icu: 'Surgical ICU',
  telemetry: 'Telemetry',
  trauma: 'Trauma',
};

export const getCertification = (credentialType) => {
  const CERTIFICATION_NAME_MAP = {
    safe_sport: 'Course Title',
  };

  return CERTIFICATION_NAME_MAP[credentialType] || 'Certification';
};

export const DEGREES = {
  bachelors: "Bachelor's degree",
  masters: "Master's degree",
  doctorate: 'Doctorate or higher',
};
