export default class MedicalRecordWaiverSerializer {
  static deserialize(medicalRecordWaiver) {
    const data = {
      id: medicalRecordWaiver.id,
      signedAt: medicalRecordWaiver.signed_at,
      sentAt: medicalRecordWaiver.sent_at,
      docuSignEnvelopeId: medicalRecordWaiver.docu_sign_envelope_id,
      ownerId: medicalRecordWaiver.owner_id,
      ownerType: medicalRecordWaiver.owner_type,
      status: medicalRecordWaiver.status,
      medicalRecordId: medicalRecordWaiver.medical_record_id,
      eventTitle: medicalRecordWaiver.event_title,
      eventType: medicalRecordWaiver.event_type,
      sportType: medicalRecordWaiver.sport_type,
      injuryDate: medicalRecordWaiver.injury_date,
      createdAt: medicalRecordWaiver.created_at,
      updatedAt: medicalRecordWaiver.updated_at,
    };

    return data;
  }
}