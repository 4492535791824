// Actions
// ========================================================
export const CLEAR_RESEND_CONFIRMATION = 'go4llis/Confirmation/CLEAR_RESEND_CONFIRMATION';

export const RESEND_CONFIRMATION = 'go4ellis/Confirmation/RESEND_CONFIRMATION';
export const RESEND_CONFIRMATION_SUCCESS = 'go4ellis/Confirmation/RESEND_CONFIRMATION_SUCCESS';
export const RESEND_CONFIRMATION_ERROR = 'go4ellis/Confirmation/RESEND_CONFIRMATION_ERROR';

// Action Creators
// ========================================================
export function clearResendConfirmation() {
  return { type: CLEAR_RESEND_CONFIRMATION };
}

export function resendConfirmation(email) {
  return { type: RESEND_CONFIRMATION, payload: { email } };
}

// Reducer
// ========================================================
const DEFAULT_STATE = {
  error: null,
};

export default function reducer(state = DEFAULT_STATE, action = {}) {
  switch (action.type) {
    case CLEAR_RESEND_CONFIRMATION:
      return { ...DEFAULT_STATE };
    case RESEND_CONFIRMATION_SUCCESS:
      return { success: true }
    case RESEND_CONFIRMATION_ERROR:
      return { error: action.payload.message }
    default:
      return state;
  }
}