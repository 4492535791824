import React, { useState } from 'react';
import { Document, Page } from 'react-pdf';
import { pdfjs } from 'react-pdf';
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;


const options = {
  cMapUrl: 'cmaps/',
  cMapPacked: true,
};

export default function PdfDocument({ pdfFile }) {
  const [file, setFile] = useState(pdfFile);
  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);

  function onDocumentLoadSuccess({ numPages: nextNumPages }) {
    setNumPages(nextNumPages);
  }

  const onClickLeft = () => {
    if (pageNumber > 1)
      setPageNumber(pageNumber - 1)
  };

  const onClickRight = () => {
    if (pageNumber < numPages)
      setPageNumber(pageNumber + 1)
  };

  return (
    <div className="Example"
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center"
      }}>
      <div className="Example__container" >
        <div className="Example__container__document">
          {
            <div style={{
              display: "flex",
              flexDirection: 'column',
              alignItems: "center",
              justifyContent: "center"
            }}>
              <Document
                file={file}
                onLoadSuccess={onDocumentLoadSuccess}
                options={options}>
                <Page
                  key={`page_${pageNumber}`}
                  pageNumber={pageNumber}
                />
              </Document>
              {
                (numPages && numPages > 1) &&
                <p style={{
                  opacity: 1,
                  borderRadius: 4,
                  margin: 7
                }}>
                  <button type="button"
                    style={{ borderRadius: 4 }}
                    onClick={onClickLeft}>‹</button>
                  <span style={{ padding: 5 }}>{pageNumber} of {numPages}</span>
                  <button type="button" style={{ borderRadius: 4 }} onClick={onClickRight}>›</button>
                </p>
              }
            </div>
          }
        </div>
      </div>
    </div>
  );
}
