import React, { Component } from 'react';
import classNames from 'classnames';
import { withRouter } from 'react-router-dom/cjs/react-router-dom';
import { connect } from 'react-redux';
import momentTimezone from 'moment-timezone';
import RateBoostModal from 'scenes/Dashboard/V2/RateBoostModal/RateBoostModal';
import { boostRate } from 'redux/ducks/Dashboard';
import { UncontrolledTooltip } from 'reactstrap';
import { SHOW_TOOLTIP_COLUMNS, createOverflowChecker } from 'helpers/dashboard';

class ShiftsWithNoAppsItem extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isOverflowing: {},
    };
    this.columnRefs = {};
    SHOW_TOOLTIP_COLUMNS.shifts.forEach((column) => {
      this.columnRefs[column] = React.createRef();
    });
  }

  componentDidMount() {
    const checkOverflow = createOverflowChecker(
      'shifts',
      this.columnRefs,
      this.setState.bind(this)
    );

    this.resizeObserver = new ResizeObserver(checkOverflow);
    SHOW_TOOLTIP_COLUMNS.shifts.forEach((column) => {
      this.resizeObserver.observe(this.columnRefs[column].current);
    });
  }

  componentWillUnmount() {
    this.resizeObserver.disconnect();
  }

  render() {
    const { item, columns } = this.props;
    const payRate = item.payRate ? item.payRate : item.event.payRate;
    const rateTypeId = item.payRate ? item.rateTypeId : item.event.rateTypeId;

    return (
      <div className="noApps-list-item">
        <div
          className={`${columns['jobName'].size} job-name`}
          id={`jobName-${item.id}`}
          ref={this.columnRefs['jobName']}
        >
          {item.event.title}
          {this.state.isOverflowing['jobName'] && (
            <UncontrolledTooltip target={`jobName-${item.id}`}>
              {item.event.title}
            </UncontrolledTooltip>
          )}
        </div>
        <div
          className={`${columns['startTime'].size}`}
          id={`startTime-${item.id}`}
          ref={this.columnRefs['startTime']}
        >
          {momentTimezone(item.startTime).format('MM/DD/YY hh:mm A')}
          {this.state.isOverflowing['startTime'] && (
            <UncontrolledTooltip target={`startTime-${item.id}`}>
              {momentTimezone(item.startTime).format('MM/DD/YY hh:mm A')}
            </UncontrolledTooltip>
          )}
        </div>
        <div
          className={`${columns['location'].size}`}
          id={`location-${item.id}`}
          ref={this.columnRefs['location']}
        >
          {item.location}
          {this.state.isOverflowing['location'] && (
            <UncontrolledTooltip target={`location-${item.id}`}>
              {item.location}
            </UncontrolledTooltip>
          )}
        </div>
        <div
          className={`${columns['rate'].size}`}
          id={`rate-${item.id}`}
          ref={this.columnRefs['rate']}
        >
          ${payRate && payRate.toFixed(2)} / {rateTypeId === 0 && 'hr'}
          {rateTypeId === 1 && 'fixed'}
          {this.state.isOverflowing['rate'] && (
            <UncontrolledTooltip target={`rate-${item.id}`}>
              ${payRate && payRate.toFixed(2)} / {rateTypeId === 0 && 'hr'}
              {rateTypeId === 1 && 'flat'}
            </UncontrolledTooltip>
          )}
        </div>
        <div className={classNames(`${columns['boostRate'].size}`)}>
          <RateBoostModal shift={item} onSave={this.props.boostRate} from="dashboard">
            {item.boostedAt ? 'BOOST AGAIN' : 'BOOST RATE'}
          </RateBoostModal>
        </div>
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  boostRate: (payload) => dispatch(boostRate(payload)),
});

export default withRouter(connect(null, mapDispatchToProps)(ShiftsWithNoAppsItem));
