import {
  EnumSerializer,
  UserSerializer
} from 'services/serializers';

export default class SessionSerializer {
  static deserialize(response) {
    const data = response.data;
    const headers = response.headers;

    const user = UserSerializer.deserialize(data.user);
    const enums = EnumSerializer.deserialize(data.enums);

    const result = {
      enums: enums,
      session: {
        accessToken: headers['access-token'],
        tokenType: headers['token-type'],
        client: headers.client,
        expiry: headers.expiry,
        uid: headers.uid,
        authenticated: true,
        currentUser: user,
      }
    };

    return result;
  }

  static deserializeParentPatient(response) {
    const data = response.data;
    const headers = response.headers;

    let user;
    if (data.parent){
      user = UserSerializer.deserialize(data.parent);
    } else {
      user = UserSerializer.deserialize(data.patient);
    }

    const result = {
      session: {
        accessToken: headers['access-token'],
        tokenType: headers['token-type'],
        client: headers.client,
        expiry: headers.expiry,
        uid: headers.uid,
        authenticated: true,
        currentUser: user,
      }
    };

    return result;
  }

  static serialize(data) {
    const result = {
      email: data.email,
      password: data.password,
      remember_me: data.rememberMe,
    };

    return result;
  }
}
