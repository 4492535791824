import React, { Component } from 'react';
import HorizontalRule from 'components/HorizontalRule';
import ActionBar from '../../ActionBar';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { isEmpty, startsWith } from 'lodash';
import { changeLocationField, suppliesFieldChange } from 'redux/ducks/Post/Locations/actions';
import { saveAsDraft } from 'redux/ducks/Post/actions';
import { deleteAttachedShiftDocument } from 'redux/ducks/Post/Locations/actions';
import { trackMixpanelPageView } from 'helpers/post';
import { AddButton } from 'components/V2/Buttons';
import ConfirmationModal from 'components/V2/ConfirmationModal';
import { get } from 'lodash';

const domain = process.env.REACT_APP_DOMAIN;

const DETAILS_FIELDS = [
  'jobExpectations',
  'scheduleDetails',
  'dressCode',
  'meals',
  'parking',
  'checkInLocation',
  'reportTo',
  'setupLocation',
  'facilityInformation',
  'restrooms',
  'ambulanceAccessPoint',
  'weatherPlans',
  'otherDetails',
]
class Details extends Component {
  constructor(props) {
    super(props);
    props.initialize('Details');
    this.state = { isModalOpen: false };
  }
  toggle = () => this.setState({ isModalOpen: !this.state.isModalOpen });

  componentDidMount() {
    if (!this.props.editMode)
      trackMixpanelPageView('create-event/details/details', this.props.user);
  }

  onFieldChange = (ev: SyntheticInputEvent<HTMLInputElement>) => {
    const { locationIdx } = this.props;
    const { value, type, checked, name } = ev.target;
    const myValue = type === 'checkbox' ? checked : value;
    this.props.onSuppliesChange(locationIdx, name, myValue);
  };

  onAddDocument = (event: SyntheticInputEvent<HTMLInputElement>) => {
    const target = event.target;
    if (target.files && target.files.length > 0) {
      const file = target.files[0];
      const newFile = new File([file], file.name);
      this.props.changeLocationField(
        this.props.locationIdx,
        'documents',
        this.props.shiftLocation.documents.concat([newFile])
      );
    }
  };

  onDeleteShiftDocument = (event: SyntheticInputEvent<HTMLInputElement>, document: File) => {
    event.preventDefault();
    this.props.changeLocationField(
      this.props.locationIdx,
      'documents',
      this.props.shiftLocation.documents.filter((d) => d !== document)
    );
  };

  onDeleteAttachedDocument = (event: SyntheticInputEvent<HTMLInputElement>, documentId: string) => {
    event.preventDefault();
    this.props.deleteAttachedShiftDocument(documentId, this.props.locationIdx);
  };

  urlFor(previewUrl: string) {
    return startsWith(previewUrl, '/') ? domain + previewUrl : previewUrl;
  }

  onSaveModal = () => {
    this.toggle();
    this.onSaveAndContinue();
  };

  onSaveAndContinue = () => {
    const { baseUrl, authenticated, post } = this.props;
    if (this.displayConfirmationModal()) {
      this.toggle();
      return;
    }

    const callback = () => {
      this.props.history.push(`${baseUrl}/details/credentials`);
    };

    if (!authenticated) {
      callback();
      return;
    }

    this.props.saveAsDraft(post, callback);
  };

  renderItem = ({ field, label, placeHolder, index }) => {
    const { shiftLocation } = this.props;

    return (
      <div className="pb-2">
        <label htmlFor={field} className="job-details-section-title gotham-medium-font">
          {label}
        </label>
        <textarea
          className="form-control nimbus-regular-font"
          style={{ width: '100%', minHeight: '60px' }}
          type="textarea"
          name={field}
          id={field}
          value={get(shiftLocation, `supplies.${field}`, '') || ''}
          rows={2}
          placeholder={placeHolder}
          onChange={this.onFieldChange}
        />
      </div>
    );
  };

  displayConfirmationModal = () => {
    const { shiftLocation } = this.props;
    return shiftLocation.documents.length + shiftLocation.existingDocuments.length === 0 &&
      DETAILS_FIELDS.every(field => isEmpty(get(shiftLocation, `supplies.${field}`, ''))) &&
      !this.state.isModalOpen
  }

  render() {
    const { shiftLocation } = this.props;

    return (
      <div className="v2details">
        <main>
          <header>
            <div className="screen-title">
              Details
            </div>
          </header>
          <div className="details-container">
            {this.renderItem({
              field: 'jobExpectations',
              label: 'Job Responsibilities',
              placeHolder: 'What are the job expectations?',
            })}

            <HorizontalRule dashed margin="1rem" />

            {this.renderItem({
              field: 'scheduleDetails',
              label: 'Schedule Details',
              placeHolder: 'Are there any additional details about the schedule?',
            })}

            <HorizontalRule dashed margin="1rem" />

            {this.renderItem({
              field: 'dressCode',
              label: 'Dress Code',
              placeHolder: 'Does the AT need to wear something specific?',
            })}

            <HorizontalRule dashed margin="1rem" />

            {this.renderItem({
              field: 'meals',
              label: 'Meals',
              placeHolder: 'Are any meals provided?',
            })}

            <HorizontalRule dashed margin="1rem" />

            {this.renderItem({
              field: 'parking',
              label: 'Parking',
              placeHolder: 'Where should the AT park?',
            })}

            <HorizontalRule dashed margin="1rem" />

            {this.renderItem({
              field: 'checkInLocation',
              label: 'Check-In Location',
              placeHolder: 'Where should the AT check in upon arrival?',
            })}

            <HorizontalRule dashed margin="1rem" />

            {this.renderItem({
              field: 'reportTo',
              label: 'Report To',
              placeHolder: 'Who should the AT check-in with upon arrival?',
            })}

            <HorizontalRule dashed margin="1rem" />

            {this.renderItem({
              field: 'setupLocation',
              label: 'Set-Up Location',
              placeHolder: 'Where should the AT set up for the shift?',
            })}

            <HorizontalRule dashed margin="1rem" />

            {this.renderItem({
              field: 'facilityInformation',
              label: 'Facility Information',
              placeHolder: 'Helpful information about the facility',
            })}

            <HorizontalRule dashed margin="1rem" />

            {this.renderItem({
              field: 'restrooms',
              label: 'Restrooms',
              placeHolder: 'Where are the restrooms located?',
            })}

            <HorizontalRule dashed margin="1rem" />

            {this.renderItem({
              field: 'ambulanceAccessPoint',
              label: 'Ambulance Access Point',
              placeHolder: 'Where should ambulances be directed?',
            })}

            <HorizontalRule dashed margin="1rem" />

            {this.renderItem({
              field: 'weatherPlans',
              label: 'Weather Plans/Shelters',
              placeHolder: 'Alert systems and location of shelter',
            })}

            <HorizontalRule dashed margin="1rem" />

            {this.renderItem({
              field: 'otherDetails',
              label: 'Other',
              placeHolder: 'Are there any other details the AT should know about the job?',
            })}

            <HorizontalRule dashed margin="1rem" />

            <div className="shift-documents">
              <p className="job-details-section-title gotham-medium-font">Shift Documents</p>
              <div className="shift-copy nimbus-regular-font">
                Use this for Onboarding Forms, policies, etc. Uploaded documents are emailed to ATs
                before the shift.
              </div>
              <div className="form-group">
                <AddButton
                  className="upload-documents-btn"
                  onClick={(e) => document.getElementById('upload-shifts-doc').click()}
                >
                  Upload Documents
                </AddButton>
                <input
                  className="custom-file-input"
                  type="file"
                  name="upload-shifts-doc"
                  accept="*"
                  id="upload-shifts-doc"
                  hidden
                  onChange={this.onAddDocument}
                />
              </div>
            </div>
            <div className="col-md-9 col-sm-6 shift-documents-display">
              {!isEmpty(shiftLocation) && Array.isArray(shiftLocation.existingDocuments) && (
                <React.Fragment>
                  {shiftLocation.existingDocuments.map((document) => (
                    <div className="py-2 doc-upload-list" key={document.id}>
                      <i className="fas fa-file-alt px-2"></i>
                      <a
                        className="px-2 nimbus-regular-font"
                        target="_blank"
                        rel="noopener noreferrer"
                        href={this.urlFor(document.url)}
                      >
                        {document.filename}
                      </a>
                      <i
                        className="material-icons remove-contact-btn"
                        onClick={(e) => this.onDeleteAttachedDocument(e, document.id)}
                      >
                        close
                      </i>
                    </div>
                  ))}
                </React.Fragment>
              )}
              {Array.isArray(shiftLocation.documents) && (
                <React.Fragment>
                  {shiftLocation.documents.map((document, i) => (
                    <div className="py-2 doc-upload-list" key={i}>
                      <i className="fas fa-file-alt px-2"></i>
                      <span className="px-2 nimbus-regular-font">{document.name}</span>
                      <i
                        className="material-icons remove-contact-btn"
                        onClick={(e) => this.onDeleteShiftDocument(e, document)}
                      >
                        close
                      </i>
                    </div>
                  ))}
                </React.Fragment>
              )}
            </div>
          </div>
          <ActionBar
            editMode={this.props.editMode}
            backTo={`${this.props.baseUrl}/details/supplies`}
            currentStep="details/details"
            onSaveAndContinue={this.onSaveAndContinue}
          />
        </main>
        <ConfirmationModal
          isOpen={this.state.isModalOpen}
          toggle={this.toggle}
          title="No Details Added"
          body="Please confirm there are no details or documents to add for this location."
          onSave={this.onSaveModal}
          cancelText="Go Back"
          onCancel={this.toggle}
        />
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const { post } = state;
  const { locationIdx } = post.navigation;
  const shiftLocation = post.locations[locationIdx];

  return {
    post,
    shiftLocation,
    locationIdx,
    authenticated: state.session.authenticated,
    user: state.session.currentUser,
  };
};

const mapDispatchToProps = (dispatch) => ({
  saveAsDraft: (post, callback) => dispatch(saveAsDraft({ post, callback, from: 'details' })),
  onSuppliesChange: (locationIndex, name, value) =>
    dispatch(suppliesFieldChange({ locationIndex, name, value })),
  changeLocationField: (idx, name, value) => dispatch(changeLocationField({ name, value, idx })),
  deleteAttachedShiftDocument: (documentId, locationId) =>
    dispatch(deleteAttachedShiftDocument({ documentId, locationId })),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Details));
