import React, { Component } from 'react';
import { connect } from 'react-redux';
import EAPForm from 'scenes/CreateEventScene/CreateEventStep3/EAPForm'
import { SAVE_SHIFTS_AND_CONTINUE_SUCCESS } from 'redux/ducks/CreateEvent/Shifts/actions';
import { initForm, } from 'redux/ducks/CreateEvent';
import { initForm as initEAPForm, createEap } from 'redux/ducks/CreateEvent/EAP/actions';
import { keyBy, get } from 'lodash';
import { Link } from 'react-router-dom';

class EditEAPScene extends Component {
  componentDidMount() {
    this.props.initialize(this.props.event, this.props.shift)
  }

  onSubmit = () => {
    this.props.submit(this.props.history, this.props.form);
  }

  render() {
    const { form } = this.props;
    return (
      <div className="editEAPScene">
        <Link to={`/events/${this.props.event.id}/edit`} className='back-link'>
          Back
        </Link>
        <h1 className="title">emergency action plan</h1>
        <EAPForm formInstance={form} />
        <div className="button-row">
          <button onClick={this.onSubmit} className="btn btn-primary btn-danger bold-button">submit</button>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state, ownProps) {
  const { eventId, shiftId } = ownProps.match.params;
  const keyedShiftForms = keyBy(state.createEvent.shifts.shifts, 'id');
  let event = state.event.events.upcoming[eventId];

  if (!event) {
    event = state.event.events.draft[eventId];
  }

  event = { ...event };

  event['shifts'] = keyedShiftForms[shiftId] ?
    { byId: keyedShiftForms, ids: Object.keys(keyedShiftForms) } :
    event.shifts;
  const shift = event.shifts.byId[shiftId];
  const { forms } = state.createEvent.eap;
  const form = forms.find(form => form.eventLocation.id === get(shift, 'eventLocation.id')) || forms[0];

  return {
    event,
    shift,
    form,
  }
}

function mapDispatchToProps(dispatch) {
  return {
    initialize(event, shift) {
      const shifts = Object.values(event.shifts.byId)

      dispatch(initForm(event))
      dispatch({ type: SAVE_SHIFTS_AND_CONTINUE_SUCCESS, payload: shifts });
      dispatch(initEAPForm({ eventLocationId: shift.eventLocation.id }))
    },
    submit(history, form) {
      dispatch(createEap({ history, form, goBack: true }))
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(EditEAPScene);
