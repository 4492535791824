import React, { Component } from 'react';
import { connect } from 'react-redux';
import { changeProfileField } from 'redux/ducks/Post/Profile';
import ActionBar from 'scenes/V2/Shared/ActionBar';
import { isId, trackMixpanelPageView } from 'helpers/post';
import classNames from 'classnames';
import { getSetting } from 'helpers/enums';

const settingsTypes = {
  Sports: 'Coverage for a game, practice, tournament, camp, clinic, or similar.',
  Industrial: 'Coverage at a warehouse, distribution center, job site, or similar.',
  Medical: 'Coverage at a medical clinic, occupational health center, or similar.',
  'Performing Arts': 'Coverage at a theatre, festival, production set, or similar.',
  School: 'Coverage for a school setting.',
}

class EventSetting extends Component {
  constructor(props) {
    super(props);
    props.initialize('EventSetting');
  }

  componentDidMount() {
    if (!this.props.editMode) trackMixpanelPageView('create-event/setting', this.props.user);
  }

  onSelect = (setting) => () => {
    const { eventSettingId, settingDetailId, sportId } = this.props.profile;
    if (setting.id === eventSettingId) return;

    this.props.onChangeProfile('eventSettingId', setting.id);

    if (isId(settingDetailId)) this.props.onChangeProfile('settingDetailId', '');
    if (isId(sportId)) this.props.onChangeProfile('sportId', '');
  };

  onSaveAndContinue = () => {
    if (!isId(this.props.profile.eventSettingId)) return;

    this.props.history.push(`${this.props.baseUrl}/setting-detail`);
  };

  render() {
    const { authenticated, baseUrl, profile, eventSettings } = this.props;

    return (
      <div className="eventSetting">
        <header>
          <div className="screen-title">What’s the job setting?</div>
        </header>
        <main>
          {eventSettings.map((setting) => (
            <button
              key={setting.id}
              className={classNames('setting-button gotham-bold-font', {
                selected: setting.id === profile.eventSettingId,
              })}
              onClick={this.onSelect(setting)}
            >
              {setting.name} / <span className={classNames('setting-tip nimbus-regular-font', {
                selected: setting.id === profile.eventSettingId,
              })}>{settingsTypes[setting.name]}</span>
            </button>
          ))}
        </main>
        <ActionBar
          editMode={this.props.editMode}
          currentStep="setting"
          backTo={authenticated ? '/dashboard' : `${baseUrl}/type`}
          onSaveAndContinue={this.onSaveAndContinue}
          nextDisabled={() => !isId(profile.eventSettingId)}
          backDisabled={authenticated}
        />
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => ({
  eventSettings: getSetting({ hasSchool: state.post.profile.eventSettingId === 3 }),
  profile: state.post.profile,
  authenticated: state.session.authenticated,
  user: state.session.currentUser,
});

const mapDispatchToProps = (dispatch) => ({
  onChangeProfile: (name, value) => dispatch(changeProfileField({ name, value })),
});

export default connect(mapStateToProps, mapDispatchToProps)(EventSetting);
