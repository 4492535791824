import { SERVICE_URL } from "services/ApiService";
import CredentialSerializer from "../CredentialSerializer";
import { get, startsWith } from "lodash";
import ProfileSerializer from "../ProfileSerializer";

export default class AthleticTrainerSerializer {
  static deserialize(at) {
    const result = {
      id: at.id,
      firstName: at.first_name,
      lastName: at.last_name,
      email: at.email,
      phoneNumber: at.phone_no,
      professionId: at.profession_id,
      errors: at.errors,
      shiftsWorked: at.shifts_worked,
      name: `${at.first_name} ${at.last_name}`,
      credentials: [],
      profile: ProfileSerializer.deserialize(at.profile),
      deCommits: at.de_commits,
      callouts: at.callouts,
      npiNumber: at.npi_id,
      nataNumber: at.nata_number,
    }

    if (get(at, 'credentials.length') > 0) {
      for (const credential of at.credentials) {
        result['credentials'].push(CredentialSerializer.deserialize(credential));
      }
    }

    if (at.profile_pic) {
      result['profilePic'] = at.profile_pic.includes("s3.amazonaws.com") ?
        at.profile_pic : (
          !startsWith(at.profile_pic, SERVICE_URL)
            ? `${SERVICE_URL}${at.profile_pic}`
            : at.profile_pic
        );
    }

    return result;
  }
}
