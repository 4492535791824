import React, { Component } from 'react';
import { connect } from 'react-redux';
import { groupBy, map, sum } from 'lodash';
import moment from 'moment';
import { get } from 'lodash';
/* eslint radix: "off" */

import { rateTypeNameFinder } from 'helpers/rateTypeName';
import { isEventCamp } from '../../../../helpers/isEventCamp';
import { formatter } from 'helpers/formatter';
import { getEstimatedFee } from "helpers/getEstimatedFee";

export class BillingEstimateTable extends Component {
  formattedTime(time) {
    return moment(time).format('M/D/Y LT');
  }
  shiftDuration(shift) {
    const momentStart = moment(shift.start);
    const momentEnd = moment(shift.end);
    const duration = moment.duration(momentEnd.diff(momentStart));
    return duration.asHours();
  }

  shiftRate = shift => {
    const rate = this.props.payRate;
    if (this.props.rateTypeId === '1') {
      return rate * shift.capacity;
    } else {
      return rate * this.shiftDuration(shift) * shift.capacity;
    }
  }

  totalShiftRate = () => {
    return sum(this.props.shifts.map(this.shiftRate));
  }

  estimatedServiceFee = () => {
    if (this.props.currentUser.paymentFee != null && this.props.currentUser.paymentFee != undefined) {
      return this.props.currentUser.paymentFee * 100;
    }

    const { shifts, payment, eventProfessionId } = this.props;
    const maxStartTime = Math.max(...shifts.map(s => moment(s.start)));

    const isAmex = payment &&
        payment.source &&
        payment.source.object === "card" &&
        payment.source.brand === "American Express";

    return getEstimatedFee(maxStartTime, eventProfessionId, isAmex);
  }

  render() {
    const { shifts } = this.props;
    return (
      <table className="billingEstimateTable">
        <thead>
          <tr>
            <th>Time</th>
            <th>Location</th>
            <th>Staff Needed</th>
            <th>Shift Hours</th>
            <th>Rate</th>
            <th>Shift Sum</th>
          </tr>
        </thead>
        <tbody>
          {shifts.map((shift, idx) => <tr key={idx}>
            <td>{this.formattedTime(shift.start)} - {this.formattedTime(shift.end)}</td>
            <td className="location-cell">
              {shift.eventLocation.formattedAddress.replace(', USA', '')}
              <br />
              {shift.eventLocation.name}{shift.eventLocation.details && ` / ${shift.eventLocation.details}`}
            </td>
            <td>{shift.capacity}</td>
            {!this.props.isEventCamp &&
              <td>{this.shiftDuration(shift)}</td>
            }
            {this.props.isEventCamp &&
              <td> - </td>
            }
            <td>{formatter.format(this.props.payRate)} / {rateTypeNameFinder(this.props.rateTypes, this.props.rateTypeId)}</td>
            <td className="money-cell">{formatter.format(this.shiftRate(shift))}</td>
          </tr>
          )}
          <tr className="service-fee-row">
            <td colSpan="5" style={{ textAlign: 'left' }}>Service Fee</td>
            <td className="money-cell">{formatter.format(this.totalShiftRate() * this.estimatedServiceFee() / 100)}</td>
          </tr>
          <tr className="grand-total-row">
            <td colSpan="5" />
            <td className="money-cell">
              Total: {formatter.format(this.totalShiftRate() * (1 + this.estimatedServiceFee() / 100))}
            </td>
          </tr>
        </tbody>
      </table>
    );
  }
}

function mapStateToProps(state) {
  return {
    currentUser: state.session.currentUser,
    rateTypes: state.enums.rateTypes,
    payment: state.payment,
    eventTypeId: parseInt(state.createEvent.details.eventTypeId),
    isEventCamp: isEventCamp(state.createEvent.details.eventTypeId, state.enums.eventTypes),
    eventProfessionId: state.createEvent.details.eventProfessionId,
  }
}

export default connect(mapStateToProps)(BillingEstimateTable);
