import axios from 'axios';
import { snakeCase } from 'lodash';

export const SERVICE_URL = process.env.REACT_APP_DOMAIN;
// export const SERVICE_URL = process.env.REACT_APP_DOMAIN_STAGING;

export default class ApiService {
  constructor(session) {
    if (session) {
      this.session = session;
    }
  }

  get(url, mapFunc) {
    return axios.get(SERVICE_URL + url, this.config()).then((response) => mapFunc(response.data));
  }

  download(url, mapFunc) {
    const config = {
      ...this.config(),
      responseType: 'blob',
    };
    return axios.get(SERVICE_URL + url, config).then((response) => mapFunc(response.data));
  }

  search(url, params, mapFunc) {
    let queryString = '';

    if (params) {
      let paramsArray = [];
      queryString += '?';

      for (let param in params) {
        if (Array.isArray(params[param])) {
          params[param].forEach((value) => {
            paramsArray.push(`${snakeCase(param)}[]=${encodeURIComponent(value)}`);
          });
        } else if (params[param]) {
          paramsArray.push(`${snakeCase(param)}=${encodeURIComponent(params[param])}`);
        }
      }

      queryString += paramsArray.join('&');
    }

    return axios
      .get(SERVICE_URL + url + queryString, this.config())
      .then((response) => mapFunc(response.data));
  }

  put(url, body, mapFunc) {
    return axios
      .put(SERVICE_URL + url, body, this.config())
      .then((response) => mapFunc(response.data));
  }

  post(url, body, mapFunc) {
    return axios
      .post(SERVICE_URL + url, body, this.config())
      .then((response) => mapFunc(response.data));
  }

  postWithResponse(url, body, mapFunc) {
    return axios.post(SERVICE_URL + url, body, this.config()).then((response) => mapFunc(response));
  }

  patch(url, body, mapFunc) {
    return axios
      .patch(SERVICE_URL + url, body, this.config())
      .then((response) => mapFunc(response.data));
  }

  delete(url, mapFunc) {
    return axios
      .delete(SERVICE_URL + url, this.config())
      .then((response) => mapFunc(response.data));
  }

  config() {
    const result = {};

    if (this.session) {
      result['headers'] = {
        'Content-Type': 'application/json',
        'access-token': this.session.accessToken,
        'client': this.session.client,
        'expiry': this.session.expiry,
        'token-type': 'Bearer',
        'uid': this.session.uid,
      };
    }

    return result;
  }
}
