import React, { Component } from 'react';
import ActionBar from '../../ActionBar';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { saveAsDraft } from 'redux/ducks/Post/actions';
import classNames from 'classnames';
import HorizontalRule from 'components/HorizontalRule';
import { suppliesFieldChange } from 'redux/ducks/Post/Locations/actions';
import { get } from 'lodash';
import { AddButton } from 'components/V2/Buttons';
import { trackMixpanelPageView } from 'helpers/post';
import { createSession } from 'redux/ducks/Session';

class RequiredCredentials extends Component {
  constructor(props) {
    super(props);
    props.initialize('Credentials');
  }

  componentDidMount() {
    if (!this.props.editMode)
      trackMixpanelPageView('create-event/details/credentials', this.props.user);
  }

  pageIsValid = () => Array.isArray(get(this.props, 'shiftLocation.supplies.requiredCredentials'));

  onSaveAndContinue = () => {
    if (!this.pageIsValid()) return;

    const { baseUrl, post, authenticated } = this.props;
    if (!authenticated) return;

    const callback = () => {
      this.props.history.push(`${baseUrl}/eap/eap-type`);
    };

    this.props.saveAsDraft(post, callback);
  };

  onYes = () => {
    const { requiredCredentials } = this.props.shiftLocation.supplies;
    if (Array.isArray(requiredCredentials) && requiredCredentials.length > 0) return;

    this.props.onSuppliesChange(this.props.locationIdx, 'requiredCredentials', ['']);
  };

  onNo = () => {
    this.props.onSuppliesChange(this.props.locationIdx, 'requiredCredentials', []);
  };

  onChangeCredential = (idx) => (e) => {
    const { value } = e.target;
    const { supplies } = this.props.shiftLocation;

    const newCredentials = [...supplies.requiredCredentials];
    newCredentials[idx] = value;

    this.props.onSuppliesChange(this.props.locationIdx, 'requiredCredentials', newCredentials);
  };

  onAddCredential = () => {
    const { supplies } = this.props.shiftLocation;

    const newCredentials = [...supplies.requiredCredentials];
    newCredentials.push('');

    this.props.onSuppliesChange(this.props.locationIdx, 'requiredCredentials', newCredentials);
  };

  onRemoveCredential = (idx) => () => {
    const { supplies } = this.props.shiftLocation;

    const newCredentials = [...supplies.requiredCredentials];
    newCredentials.splice(idx, 1);

    this.props.onSuppliesChange(this.props.locationIdx, 'requiredCredentials', newCredentials);
  };

  onSubmitLogIn = ({ email, password, rememberMe, role }) => {
    this.props.login({
      email: email,
      password: password,
      rememberMe: rememberMe,
      role: role,
      history: this.props.history,
    });
  };

  render() {
    const { supplies } = this.props.shiftLocation;

    return (
      <div className="requiredCredentials ">
        <div id="attribution"></div>
        <main>
          <header>
            <div className="screen-title">
              Additional Credentials
              <span> / Optional</span>
            </div>
          </header>
          <div className="requiredCredentialsContainer">
            <div className="info-text nimbus-regular-font">
              Do the athletic trainers applying for this job need any credentials or certifications
              in addition to their national and state licenses?
              <i> e.g. Safe Sport Training, Child Abuse Clearance, etc.</i>
            </div>

            <div className="eap-button-section">
              <button
                className={classNames('yes-button gotham-bold-font', {
                  selected:
                    Array.isArray(supplies.requiredCredentials) &&
                    supplies.requiredCredentials.length === 0,
                })}
                onClick={this.onNo}
              >
                No
              </button>
              <div className={classNames('no-section', {
                selected:
                  Array.isArray(supplies.requiredCredentials) &&
                  supplies.requiredCredentials.length > 0,
              })}>
                <button
                  className={classNames('no-button gotham-bold-font', {
                    selected:
                      Array.isArray(supplies.requiredCredentials) &&
                      supplies.requiredCredentials.length > 0,
                  })}
                  onClick={this.onYes}
                >
                  Yes
                </button>
                {Array.isArray(supplies.requiredCredentials) &&
                  supplies.requiredCredentials.length > 0 && (
                    <div className="section-content">
                      <div className="required-credentials">
                        {supplies.requiredCredentials.map((credential, idx) => (
                          <div className="required-credential" key={idx}>
                            <HorizontalRule dashed />
                            <div className="row">
                              <div
                                className="form-group pl-3"
                                style={{ flex: '0 0 88%', maxWidth: '88%' }}
                              >
                                <label htmlFor="title" className='gotham-medium-font'>Name of additional credential</label>
                                <input
                                  className="form-control nimbus-regular-font"
                                  type="text"
                                  name="title"
                                  value={credential}
                                  onChange={this.onChangeCredential(idx)}
                                  required
                                  maxLength="255"
                                />
                                <div className="invalid-feedback">Credential is required</div>
                              </div>
                              <div
                                className="merge-close d-flex flex-column align-items-center align-self-center mt-2"
                                style={{ flex: '0 0 8%', maxWidth: '8%' }}
                              >
                                <i
                                  className="material-icons remove-doc-btn mt-1"
                                  onClick={this.onRemoveCredential(idx)}
                                >
                                  close
                                </i>
                              </div>
                            </div>
                          </div>
                        ))}
                      </div>
                      <AddButton onClick={this.onAddCredential}>ADD ANOTHER DOCUMENT</AddButton>
                    </div>
                  )}
              </div>
            </div>
          </div>
          <ActionBar
            disableFlash
            editMode={this.props.editMode}
            backTo={`${this.props.baseUrl}/details/details`}
            nextDisabled={() => !this.pageIsValid()}
            currentStep="details/credentials"
            onSaveAndContinue={this.onSaveAndContinue}
          />
        </main>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const { post } = state;
  const { locationIdx } = post.navigation;
  const shiftLocation = post.locations[locationIdx];

  return {
    post,
    authenticated: state.session.authenticated,
    locationIdx,
    shiftLocation,
    user: state.session.currentUser,
  };
};

const mapDispatchToProps = (dispatch) => ({
  saveAsDraft: (post, callback) => dispatch(saveAsDraft({ post, callback, from: 'credentials' })),
  onSuppliesChange: (locationIndex, name, value) =>
    dispatch(suppliesFieldChange({ locationIndex, name, value })),
  login: (credentials) => dispatch(createSession(credentials)),
  saveProfile: (post, callback) => dispatch(saveAsDraft({ post, callback, from: 'credentials' })),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(RequiredCredentials));
