import classnames from 'classnames';
import HorizontalRule from 'components/HorizontalRule';
import React, { Component } from 'react';
import PhoneInput from 'react-phone-input-2';
import { isEqual, omit } from 'lodash';
import { connect } from 'react-redux';
import CustomCheckBox from 'components/V2/CustomInputs/CustomCheckBox';

class ReportToContact extends Component {
  state = {
    wasFieldValidated: {
      name: false,
      email: false,
      phone: false,
    },
    idRandom: Math.floor(Math.random() * 1e7),
    useEoData: false,
  };

  onFieldBlur = (field: string) => () => {
    if (!this.state.wasFieldValidated[field]) {
      this.setState((prevState) => {
        return {
          ...prevState,
          wasFieldValidated: {
            ...prevState.wasFieldValidated,
            [field]: true,
          },
        };
      });
    }
  };

  removeContact = (contactIndex) => () => {
    this.props.removeContact(contactIndex);
  };

  onPhoneNumberChange(idx, newNumber) {
    const phoneNo = newNumber.length === 1 && newNumber.at(0) !== '1' ? `1${newNumber}` : newNumber;

    this.props.changeContact(idx, {
      ...this.props.contact,
      phoneNumber: phoneNo,
    });
  }

  showError = (fieldName: string) => {
    const { wasValidated, errors } = this.props;
    return wasValidated && !!errors[fieldName];
  };

  toggleUseEoData = () => {
    const { contacts, changeContact, eoContact } = this.props;
    if (!this.state.useEoData) {
      changeContact(0, eoContact);
    } else {
      const eo = contacts[0];
      const isEoChecked = isEqual(omit(eo, 'id'), eoContact);

      if (isEoChecked) {
        changeContact(0, { name: '', email: '', phoneNumber: '' });
      }
    }
    this.setState({ ...this.state, useEoData: !this.state.useEoData });
  };

  renderMobileGo4ContactBtn = () => {
    const { idx, authenticated } = this.props;

    if (!authenticated || idx !== 0) return null;

    return (
      <CustomCheckBox
        id={`eoContact-${this.state.idRandom}`}
        name={`eoContact-${this.state.idRandom}`}
        checked={this.state.useEoData}
        onClick={this.toggleUseEoData}
        className="account-contact-mobile nimbus-regular-font"
      >
        Same as Go4 account contact
      </CustomCheckBox>
    );
  };

  renderGo4ContactBtn = () => {
    const { idx, authenticated } = this.props;

    if (!authenticated || idx !== 0) return null;

    return (
      <CustomCheckBox
        id={`eoContact-${this.state.idRandom}`}
        name={`eoContact-${this.state.idRandom}`}
        checked={this.state.useEoData}
        onClick={this.toggleUseEoData}
        className="account-contact nimbus-regular-font"
      >
        Same as Go4 account contact
      </CustomCheckBox>
    );
  };

  render() {
    const { contact, canRemove, idx, changeContact, errors } = this.props;

    return (
      <>
        <div className="reportToContact">
          {this.renderMobileGo4ContactBtn()}
          {this.renderGo4ContactBtn()}
        </div>
        <div className="reportToContact form-row">
          <div
            className={classnames('form-group', {
              'col-md-3': canRemove,
              'col-md-4': !canRemove,
            })}
          >
            <label htmlFor="contact_name">Name</label>
            <input
              type="text"
              className={classnames('form-control nimbus-regular-font', {
                'is-invalid': this.showError('name'),
              })}
              onBlur={this.onFieldBlur('name')}
              onChange={(ev) => changeContact(idx, { ...contact, name: ev.currentTarget.value })}
              value={contact.name}
            />
            <div className="invalid-feedback nimbus-regular-font">{errors['name']}</div>
          </div>
          <div className="form-group col-md-4">
            <label htmlFor="contact_email">Email</label>
            <input
              type="email"
              className={classnames('form-control nimbus-regular-font', {
                'is-invalid': this.showError('email'),
              })}
              onBlur={this.onFieldBlur('email')}
              onChange={(ev) =>
                changeContact(idx, {
                  ...contact,
                  email: ev.currentTarget.value,
                })
              }
              value={contact.email}
            />
            <div className="invalid-feedback nimbus-regular-font">{errors['email']}</div>
          </div>
          <div className="form-group col-md-4">
            <label htmlFor="contact_phone">Phone</label>
            <PhoneInput
              name="phone"
              onlyCountries={['us']}
              preferredCountries={['us']}
              enableAreaCodes={false}
              autoFormat={true}
              inputClass="nimbus-regular-font"
              inputStyle={{ width: '100%' }}
              placeholder="Enter phone number"
              country="us"
              onBlur={this.onFieldBlur('phone')}
              onChange={(newNumber) => this.onPhoneNumberChange(idx, newNumber)}
              value={contact.phoneNumber}
              isValid={() => !this.showError('phone')}
            />
            {this.showError('phone') && (
              <div className="invalid-feedback-displayed nimbus-regular-font">
                {errors['phone']}
              </div>
            )}
          </div>
          {canRemove && (
            <div className="col-md-1" style={{ display: 'flex', alignItems: 'center' }}>
              <i className="material-icons remove-contact-btn" onClick={this.removeContact(idx)}>
                close
              </i>
            </div>
          )}
          <HorizontalRule dashed />
        </div>
      </>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const {
    currentUser: { email, name, phone: phoneNumber },
    authenticated,
  } = state.session;
  const contactsLength = ownProps.contacts.filter((contact) => !contact._destroy).length;
  const eoContact = { name, email, phoneNumber };

  return {
    eoContact,
    authenticated,
    canRemove: contactsLength > 1,
  };
};

export default connect(mapStateToProps)(ReportToContact);
