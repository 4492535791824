import React, { Component } from 'react';
import { resetPassword } from 'redux/ducks/ResetPassword';
import { UncontrolledAlert } from 'reactstrap';
import { get } from 'lodash';
import { connect } from 'react-redux';

class ForgotPasswordScene extends Component {
  state = {
    email: '',
    submitted: false,
    success: undefined
  }

  handleEmailChange = (e) => {
    this.setState({ email: e.currentTarget.value })
  }

  onSubmit = (e) => {
    e.preventDefault();

    this.props.resetPassword(this.state.email)
    this.setState({ submitted: true })
  }

  successMessage = () => {
    return (
      <div className="reset-success-message">
        <div><i className="material-icons" style={{verticalAlign: 'text-bottom'}}>check</i> Your Reset Password Link Has Been Sent</div>
        <div>Check your email to reset your password</div>
      </div>
    )
  }

  failureMessage = () => {
    return (
      <div className="reset-failure-message">Sorry, we didn't find an account under that email</div>
    )
  }

  render() {
    return (
      <form className="forgotPasswordScene" onSubmit={this.onSubmit}>
        <img className="forgot-password-icon" src="/forgot-password-icon.png" alt="a flying envelope" />
        <h4>forgot password</h4>
        {get(this.props, 'location.state.redirected') &&
          <UncontrolledAlert color="danger">Sorry, there was a problem with that link. Please enter your email below to try again.</UncontrolledAlert>}
        <label>Enter your email below and we’ll send you a link to <span className="text-danger">reset</span> your password</label>
        <input type="email"
          name="email"
          placeholder="EMAIL"
          value={this.state.email}
          disabled={this.state.submitted}
          onChange={this.handleEmailChange} />
        {this.props.submitted
          ?
          (this.props.success ? this.successMessage() : this.failureMessage())
          : <button className="btn btn-danger bold-button">reset password</button>}
      </form>
    );
  }
}

function mapStateToProps(state) {
  return {
    submitted: state.resetPassword.success || state.resetPassword.error,
    success: state.resetPassword.success && (!state.resetPassword.error)
  }
}

function mapDispatchToProps(dispatch) {
  return {
    resetPassword: (email) => dispatch(resetPassword(email))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ForgotPasswordScene);
