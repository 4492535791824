import { ofType } from 'redux-observable';
import { mergeMap, map } from 'rxjs/operators';
import { StripeService } from 'services';
import { PlaidService } from 'services';

import { basicEpic, mappingEpic } from 'redux/ducks/helpers';
import { SHOW_SPINNER, HIDE_SPINNER } from 'redux/ducks/LoadingSpinner';
import { error, success } from 'redux/ducks/Flash';

import * as actions from './index';
import { CREATE_SESSION_SUCCESS } from '../Session';

// Epics
// ========================================================

export const retrieveSourceEpic = basicEpic(actions.RETRIEVE_SOURCE, (payload, state) =>
  new StripeService(state.session).get()
);

export const mapUpdatePaymentToRetrieveSourceEpics = (action$, store) =>
  action$.pipe(
    ofType(actions.SUBMIT_SUCCESS, CREATE_SESSION_SUCCESS),
    map((action) => ({
      type: actions.RETRIEVE_SOURCE,
      payload: store.getState().session.currentUser.id,
    }))
  );

export function savePaymentEpic(action$, store) {
  return action$.pipe(
    ofType(actions.SUBMIT),
    mergeMap((action) => {
      if (action.payload.token) {
        return new StripeService(store.getState().session, action.payload.id)
          .update(action.payload.token)
          .then((result) => ({ type: actions.SUBMIT_SUCCESS, payload: result }))
          .catch((error) => ({ type: actions.SUBMIT_ERROR, payload: error }));
      } else {
        return new PlaidService(store.getState().session, action.payload.id)
          .update(action.payload.plaid)
          .then((result) => ({ type: actions.SUBMIT_SUCCESS, payload: result }))
          .catch((error) => ({ type: actions.SUBMIT_ERROR, payload: error }));
      }
    })
  );
}

export const createPlaidPublicTokenEpic = (action$, store) => {
  return action$.pipe(
    ofType(
      actions.RETRIEVE_SOURCE_SUCCESS,
      actions.CREATE_PLAID_ACCESS_TOKEN_SUCCESS
    ),
    map((action) => ({
      type: actions.GET_PLAID_PUBLIC_TOKEN,
      payload: action.payload,
    }))
  );
};

export const getPlaidPublicTokenEpic = basicEpic(actions.GET_PLAID_PUBLIC_TOKEN, (payload, state) =>
  new PlaidService(state.session, payload).get()
);

export const createPlaidAccessTokenEpic = basicEpic(
  actions.CREATE_PLAID_ACCESS_TOKEN,
  (payload, state) => new PlaidService(state.session, payload.id).createAccessToken(payload.token)
);

const successMessages = {
  [actions.CREATE_PLAID_ACCESS_TOKEN_SUCCESS]:
    'Micro-deposits can take up to 1 business day. You will receive an email when it has been deposited. Please return to this page to verify.',
  [actions.SUBMIT_SUCCESS]: 'Your Payment Information Was Successfully Updated.',
};

const errorMessages = {
  [actions.CREATE_PLAID_ACCESS_TOKEN_ERROR]: 'There has been an error.',
};

export const successMessagesEpic = mappingEpic(
  [actions.CREATE_PLAID_ACCESS_TOKEN_SUCCESS, actions.SUBMIT_SUCCESS],
  ({ type }) => success(successMessages[type])
);

export const errorMessagesEpic = mappingEpic(
  [actions.CREATE_PLAID_ACCESS_TOKEN_ERROR],
  ({ type, payload }) => error([errorMessages[type], payload.message].join(': '))
);

export const showSpinnerEpic = mappingEpic(
  [
    actions.CREATE_PLAID_ACCESS_TOKEN,
    actions.RETRIEVE_SOURCE,
    actions.SUBMIT,
    actions.CREATE_PLAID_ACCESS_TOKEN,
    actions.GET_PLAID_PUBLIC_TOKEN,
  ],
  SHOW_SPINNER
);

export const hideSpinnerEpic = mappingEpic(
  [
    actions.RETRIEVE_SOURCE_SUCCESS,
    actions.RETRIEVE_SOURCE_ERROR,
    actions.SUBMIT_SUCCESS,
    actions.SUBMIT_ERROR,
    actions.CREATE_PLAID_ACCESS_TOKEN_ERROR,
    actions.CREATE_PLAID_ACCESS_TOKEN_SUCCESS,
    actions.GET_PLAID_PUBLIC_TOKEN_SUCCESS,
    actions.GET_PLAID_PUBLIC_TOKEN_ERROR,
  ],
  HIDE_SPINNER
);
