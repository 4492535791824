import React, { Component } from 'react';
import { connect } from 'react-redux';
import classnames from 'classnames';
import { get, isString } from 'lodash';
import { getEventOperators } from 'redux/ducks/User';
import CollapsibleFilterPanel from 'components/CollapsibleFilterPanel';
import InputOrFlatpickr from 'components/InputOrFlatpickr';
import { sportsFilter } from '../../helpers/enums';

export class EventFiltersPanel extends Component {
  componentWillMount() {
    if (typeof this.props.filters.eventOperator) {
      // this.getEventOperators(); Disabled until typeahead is implemented
    }
  }

  componentWillUnmount() {
    this.props.reset();
  }

  inputColor = (field) => {
    return this.props.filters[field] ? 'selected' : 'blank';
  };

  onFieldChange = (field, event) => {
    const value = isString(event) ? event : get(event, 'target.value');
    this.props.updateFilter(field, value);
  };

  onReset = () => {
    this.props.reset();
  };

  render() {
    return (
      <div className="eventFiltersPanel">
        <CollapsibleFilterPanel
          title='Event Filters'
          applyFilters={this.props.applyFilters}
          onCancel={this.onReset}
          onSubmit={this.props.submit}>
          <div className='form-group search-group'>
            <i className='fa fa-search' />
            <input className='form-control'
              placeholder='SEARCH EVENT NAME'
              onChange={this.onFieldChange.bind(this, 'title')}
              value={this.props.filters.title} />
          </div>
          <div className='form-group'>
            <label>Profession Needed</label>
            <select className={classnames('form-control', 'custom-select', this.inputColor('eventProfession'))}
              onChange={this.onFieldChange.bind(this, 'eventProfession')}
              value={this.props.filters.eventProfession}>
              <option value='' disabled hidden>Select Option</option>
              {optionsForEnum(this.props.professions)}
            </select>
          </div>
          <div className='form-group'>
            <label>Setting</label>
            <select className={classnames('form-control', 'custom-select', this.inputColor('eventSetting'))}
              onChange={this.onFieldChange.bind(this, 'eventSetting')}
              value={this.props.filters.eventSetting}>
              <option value='' disabled hidden>Select Option</option>
              {optionsForEnum(this.props.eventSettings)}
            </select>
          </div>
          <div className='form-group'>
            <label>Setting Detail</label>
            <select className={classnames('form-control', 'custom-select', this.inputColor('settingDetail'))}
              onChange={this.onFieldChange.bind(this, 'settingDetail')}
              value={this.props.filters.settingDetail}>
              <option value='' disabled hidden>Select Option</option>
              {optionsForEnum(this.props.settingDetails)}
            </select>
          </div>
          <div className='form-group'>
            <label>Sport</label>
            <select className={classnames('form-control', 'custom-select', this.inputColor('sport'))}
              onChange={this.onFieldChange.bind(this, 'sport')}
              value={this.props.filters.sport}>
              <option value='' disabled hidden>Select Option</option>
              {optionsForEnum(sportsFilter(this.props.sports))}
            </select>
          </div>
          <div className='form-group'>
            <label>Shift Start Date</label>
            <InputOrFlatpickr
              className={classnames('form-control picker', this.inputColor('startDate'))}
              placeholder='Select Start Date'
              onChange={this.onFieldChange.bind(this, 'startDate')}
              value={this.props.filters.startDate}
              type='date'
            />
          </div>
          <div className='form-group'>
            <label>Shift End Date</label>
            <InputOrFlatpickr
              className={classnames('form-control picker', this.inputColor('endDate'))}
              placeholder='Select End Date'
              onChange={this.onFieldChange.bind(this, 'endDate')}
              value={this.props.filters.endDate}
              type='date'
            />
          </div>
        </CollapsibleFilterPanel>
      </div>
    );
  }
}

function optionsForEnum(enum_vals) {
  return [<option value="" key=''></option>].concat(enum_vals.map(e => <option value={e.id} key={e.name}>{e.name}</option>))
}

function mapStateToProps(state) {
  const { professions, eventSettings, settingDetails, sports } = state.enums;
  return {
    professions,
    eventSettings,
    settingDetails,
    sports,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    getEventOperators: () => dispatch(getEventOperators()),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(EventFiltersPanel);