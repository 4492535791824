import React from "react";
import FAQS from "../../components/FAQS";
import LandingUnderTop from "../../components/LandingUnderTop";
import SectionBanner from "../../components/SectionBanner";
import StateList from "../../components/StateList/StateList";
import StandardRed from "../../components/UI/Button/StandardRed";
import HowItWorks from "./HowItWorks";
import RapidPlacement from "./RapidPlacement/RapidPlacement";
import Settings from "./Settings";
import WhoUses from "./WhoUses";
import NursesTopSection from "./NursesTopSection/NursesTopSection";

const Nurses = () => {
  return (
    <div className="nurses">
      <NursesTopSection />
      <LandingUnderTop
        noBoldText
        highLight="REGISTERED NURSES FOR INDUSTRIAL AND MEDICAL SETTINGS."
        text={
          "The Go4 platform connects companies, healthcare systems and organizations with on-demand Registered Nurses. No contracts, no minimums and you set your own pay rate."
        }
      />
      <div className="separator mx-5 my-5" />
      <RapidPlacement />
      <div className="separator mx-5 my-5" />
      <HowItWorks />
      <div className="separator mx-5 my-5" />
      <Settings />
      <div className="separator mx-5 mt-4" />
      <FAQS type="nurses" />
      <div className="separator mx-5 mb-5" />
      <WhoUses />
      <section className="mx-5 my-4 py-4 d-flex schedule-demo">
        <span>
          SCHEDULE A DEMO AND WE’LL WALK YOU THROUGH HOW TO POST A SHIFT.
        </span>
        <StandardRed
          asAnchor
          buttonType="large"
          className="d-inline-flex"
          href="mailto:demo@go4.io?subject=I%20would%20like%20to%20schedule%20a%20demo.&body=I%20would%20like%20to%20schedule%20a%20demo%20on:"
        >
          SCHEDULE A DEMO
        </StandardRed>
      </section>
      <section>
        <SectionBanner text="Locations" />
        <div className="separator-dashed mx-5" />
        <StateList />
      </section>
    </div>
  );
};

export default Nurses;
