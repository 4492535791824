import { basicCreator } from '../helpers';
import * as actions from './actions';

const getDataSuccessReducer = (state, payload, key) => {
  const dataKeys = {
    shifts: 'shifts',
    applicants: 'events',
    reviews: 'reviews',
    modifications: 'modifications',
  };
  const data = payload[dataKeys[key]];

  const updatedState = {
    ...state,
    [key]: {
      ...state[key],
      [key]: data,
      pagination: {
        per_page: payload.per_page,
        total_pages: payload.total_pages,
        total: payload.total,
        current_page: payload.current_page,
      },
      loaded: true,
    },
  };

  if (key === 'applicants') {
    updatedState[key].uniqApplicants = payload?.athletic_trainers;
    updatedState[key].totalPendingApplicants = payload.job_count;
  }

  return updatedState;
};

const getJobsMetadataReducer = (state, payload) => {
  return {
    ...state,
    applicants: {
      ...state.applicants,
      jobsMetadata: Object.fromEntries(payload?.map(({ id, ...job }) => [id, job]) || []),
    },
  };
};

const resetJobsMetadataReducer = (state) => ({
  ...state,
  applicants: { ...state.applicants, jobsMetadata: {} },
});

const changePageReducer = (state, payload, key) => {
  return {
    ...state,
    [key]: {
      ...state[key],
      pagination: {
        ...state[key].pagination,
        current_page: payload,
      },
    },
  };
};

const changeSearchReducer = (state, payload, key) => ({
  ...state,
  [key]: {
    ...state[key],
    search: payload,
  },
});

const changeSortReducer = (state, payload, key) => ({
  ...state,
  [key]: {
    ...state[key],
    sort: {
      [payload.key]: payload.value,
    },
  },
});

const removeShiftWithNoApplicantsReducer = (state, payload) => {
  const oldShifts = state.shifts.shifts;
  const nonBoostedShifts = oldShifts.filter((shift) => shift.id !== payload.shiftId);
  return {
    ...state,
    shifts: {
      ...state.shifts,
      shifts: nonBoostedShifts,
    },
  };
};

const getResourcesReducer = (state, payload) => {
  return {
    ...state,
    resources: payload.resources,
  };
};

export const changeSortApplicantReducer = (state, payload) => ({
  ...state,
  sortApplicant: {
    ...state.sortApplicant,
    [payload.key]: payload.value,
  },
});

const removeReviewReducer = (state, payload) => {
  const oldReviews = state.reviews.reviews;
  const nonEvaluatedReviews = oldReviews.filter((review) => review.id !== payload.id);
  return {
    ...state,
    reviews: {
      ...state.reviews,
      reviews: nonEvaluatedReviews,
    },
  };
};

const resetPaginationReducer = (state, key) => {
  console.log(key);
  return {
    ...state,
    [key]: {
      ...state[key],
      pagination: {
        per_page: 0,
        total_pages: 0,
        total: 0,
        current_page: 1,
      },
    },
  };
};

// Action Calls

export const resetPagination = (key) => ({
  type: actions.RESET_PAGINATION,
  payload: key,
});
export const getReviews = () => ({ type: actions.GET_REVIEWS });
export const boostRate = basicCreator(actions.BOOST_RATE);
export const updateReview = basicCreator(actions.UPDATE_REVIEW);
export const getShiftsWithNoApplicants = basicCreator(actions.GET_SHIFTS_WITH_NO_APPLICANTS);
export const changeShiftsWithNoApplicantsPage = basicCreator(
  actions.CHANGE_SHIFTS_WITH_NO_APPLICANTS_PAGE
);
export const changeShiftsWithNoApplicantsSort = basicCreator(
  actions.CHANGE_SORT_SHIFTS_WITH_NO_APPLICANTS
);
export const changeApplicantsSearch = basicCreator(actions.CHANGE_SEARCH_APPLICANT);
export const changeApplicantsSort = basicCreator(actions.CHANGE_SORT_APPLICANT);
export const changeReviewsPage = basicCreator(actions.CHANGE_REVIEWS_PAGE);
export const changeReviewsSearch = basicCreator(actions.CHANGE_SEARCH_REVIEWS);
export const changeReviewsSort = basicCreator(actions.CHANGE_SORT_REVIEWS);
export const changeApplicantsEventsPage = basicCreator(actions.CHANGE_APPLICANTS_EVENTS_PAGE);
export const changeShiftsWithNoApplicantsSearch = basicCreator(
  actions.CHANGE_SEARCH_SHIFTS_WITH_NO_APPLICANTS
);

export const getApplicantsEvents = (params = {}) => ({
  type: actions.GET_APPLICANTS_EVENTS,
  payload: { upcoming: true, ...params },
});
export const getShiftModifications = basicCreator(actions.GET_SHIFT_MODIFICATIONS);
export const changeShiftModificationsPage = basicCreator(actions.CHANGE_SHIFT_MODIFICATIONS_PAGE);
export const changeShiftModificationsSearch = basicCreator(
  actions.CHANGE_SEARCH_SHIFT_MODIFICATIONS
);
export const changeShiftModificationsSort = basicCreator(actions.CHANGE_SORT_SHIFT_MODIFICATIONS);

const DEFAULT_STATE = {
  shifts: {
    loaded: false,
    shifts: [],
    pagination: {
      per_page: 0,
      total_pages: 0,
      total: 0,
      current_page: 1,
    },
    search: '',
    sort: {
      start_time: 'asc',
    },
  },
  reviews: {
    loaded: false,
    reviews: [],
    pagination: {
      per_page: 0,
      total_pages: 0,
      total: 0,
      current_page: 1,
    },
    search: '',
    sort: {
      date: 'asc',
    },
  },
  modifications: {
    modifications: [],
    pagination: {
      per_page: 0,
      total_pages: 0,
      total: 0,
      current_page: 1,
    },
    search: '',
    sort: {
      date: 'asc',
    },
  },
  applicants: {
    applicants: [],
    pagination: {
      per_page: 0,
      total_pages: 0,
      total: 0,
      current_page: 1,
    },
    search: '',
    sort: {
      date: 'asc',
    },
    jobsMetadata: {},
  },
  resources: [],
};

export default function reducer(state = DEFAULT_STATE, action = {}) {
  switch (action.type) {
    case actions.GET_SHIFTS_WITH_NO_APPLICANTS_SUCCESS:
      return getDataSuccessReducer(state, action.payload, 'shifts');
    case actions.GET_APPLICANTS_EVENTS_SUCCESS:
      return getDataSuccessReducer(state, action.payload, 'applicants');
    case actions.GET_REVIEWS_SUCCESS:
      return getDataSuccessReducer(state, action.payload, 'reviews');
    case actions.GET_SHIFT_MODIFICATIONS_SUCCESS:
      return getDataSuccessReducer(state, action.payload, 'modifications');
    case actions.GET_JOBS_METADATA:
      return resetJobsMetadataReducer(state);
    case actions.GET_JOBS_METADATA_SUCCESS:
      return getJobsMetadataReducer(state, action.payload);

    case actions.CHANGE_SHIFTS_WITH_NO_APPLICANTS_PAGE:
      return changePageReducer(state, action.payload, 'shifts');
    case actions.CHANGE_APPLICANTS_EVENTS_PAGE:
      return changePageReducer(state, action.payload, 'applicants');
    case actions.CHANGE_REVIEWS_PAGE:
      return changePageReducer(state, action.payload, 'reviews');
    case actions.CHANGE_SHIFT_MODIFICATIONS_PAGE:
      return changePageReducer(state, action.payload, 'modifications');

    case actions.CHANGE_SEARCH_SHIFTS_WITH_NO_APPLICANTS:
      return changeSearchReducer(state, action.payload, 'shifts');
    case actions.CHANGE_SEARCH_APPLICANT:
      return changeSearchReducer(state, action.payload, 'applicants');
    case actions.CHANGE_SEARCH_REVIEWS:
      return changeSearchReducer(state, action.payload, 'reviews');
    case actions.CHANGE_SEARCH_SHIFT_MODIFICATIONS:
      return changeSearchReducer(state, action.payload, 'modifications');

    case actions.CHANGE_SORT_SHIFTS_WITH_NO_APPLICANTS:
      return changeSortReducer(state, action.payload, 'shifts');
    case actions.CHANGE_SORT_APPLICANT:
      return changeSortReducer(state, action.payload, 'applicants');
    case actions.CHANGE_SORT_REVIEWS:
      return changeSortReducer(state, action.payload, 'reviews');
    case actions.CHANGE_SORT_SHIFT_MODIFICATIONS:
      return changeSortReducer(state, action.payload, 'modifications');

    case actions.BOOST_RATE:
      return removeShiftWithNoApplicantsReducer(state, action.payload);
    case actions.GET_RESOURCES_SUCCESS:
      return getResourcesReducer(state, action.payload);
    case actions.UPDATE_REVIEW_SUCCESS:
      return removeReviewReducer(state, action.payload);

    case actions.RESET_PAGINATION:
      return resetPaginationReducer(state, action.payload);
    default:
      return state;
  }
}
