import React from "react";
import SectionBanner from "../../../components/SectionBanner";
import CorneredContainer from "../../../components/UI/CorneredContainer/CorneredContainer";
import WhoUsesList from "../../../components/WhoUsesList";

const WhoUses = () => {
  return (
    <section className="nurses-who-uses">
      <SectionBanner text="Who uses go4" className="mb-4" />
      <div className="pb-4 px-3">
        <CorneredContainer bottomOnly className="pb-2 px-2">
          <WhoUsesList
            redText
            listKey="nurses"
            homeListClassName="px-2 pt-3"
            withBorderTop
            withLeftBorder
            mobileSingleColumn
          />
        </CorneredContainer>
      </div>
    </section>
  );
};

export default WhoUses;
