import React from 'react';
import logoRed from "Go4-logo-V2.svg";
import Footer from '../Footer';
import { useHistory } from 'react-router-dom';

export default function Layout(props) {
  const history = useHistory();

  return (
    <div className="registrationScene">
      <div className='registrationScene__acquaBg'/>
      <div className='registrationScene__greyBg'/>
      <div className="registrationScene__wrapper">
        <img src={logoRed} className="registrationScene__logo" alt="go4-logo" onClick={onClick}/>
        <div className="registrationScene__content">
          {props.children}
        </div>
        <Footer />
      </div>
    </div>
  )

  function onClick() {
    history.push("/");
  }
}