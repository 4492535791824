import React from 'react';

const ExpandableSection = ({item, handleClick}) => (
  <div className={`expandableSection${item.open ? ' open' : ''}`} onClick={handleClick}>
    <div className="panel-top">
      <span className="panel-icon-wrapper"><i className="fas fa-caret-right"/></span>
      <span>{item.title}</span>
    </div>
    <p>{item.content}</p>
  </div>
);

export default ExpandableSection;