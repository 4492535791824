import { ofType } from 'redux-observable';

import { ignoreElements, map, mergeMap, mapTo } from 'rxjs/operators';

import { Observable } from 'rxjs';

import { isEqual, get, set } from 'lodash';

import { basicEpic, loadFromStorage, mappingEpic, writeToStorage } from 'redux/ducks/helpers';
import { EmergencyActionPlanService } from 'services';

import { EventService } from 'services';

import { success, error } from 'redux/ducks/Flash';
import { SHOW_SPINNER, HIDE_SPINNER } from 'redux/ducks/LoadingSpinner';
import * as eventActions from 'redux/ducks/Event';

import * as actions from './actions';
import * as eapActions from './EAP/actions';

import {
  SAVE_DETAILS_AND_CONTINUE,
  DELETE_DOCUMENT,
  DELETE_DOCUMENT_SUCCESS,
  DELETE_DOCUMENT_ERROR,
} from './Details';
import { FORM_STORAGE_KEY } from './index';

import { ShiftForm } from './Shifts';

export * from './EAP/epics';
export * from './Shifts/epics';

// Epics
// ========================================================
export function createEventEpic(action$, store) {
  return action$.pipe(
    ofType(actions.CREATE_EVENT),
    mergeMap((action) => {
      const state = store.getState();
      const event = {
        details: {
          id: state.createEvent.details.id,
          paymentToken: action.payload.paymentToken,
          isDraft: false,
        },
      };
      return new EventService(state.session)
        .update(event)
        .then(() => {
          action.payload.history.push('/dashboard?eventCreated=true');
          return { type: actions.CREATE_EVENT_SUCCESS };
        })
        .catch((error) => ({
          type: actions.CREATE_EVENT_ERROR,
          payload: error,
        }));
    })
  );
}

export function refreshEventAfterUpdate(action$, store) {
  return action$.pipe(ofType(actions.UPDATE_EVENT_SUCCESS), mapTo({ type: actions.CLEAR_FORM }));
}

export function saveDraftEpic(action$, store) {
  return action$.pipe(
    ofType(actions.SAVE_AS_DRAFT),
    mergeMap((action) => {
      const state = { ...store.getState().createEvent };
      state.details.isDraft = true;
      state.shifts.shifts = state.shifts.shifts.filter((s) => !isEqual(s, new ShiftForm()));

      return new EventService(store.getState().session)
        .createOrUpdate(state)
        .then(() => ({ type: actions.SAVE_AS_DRAFT_SUCCESS }))
        .catch((error) => ({
          type: actions.SAVE_AS_DRAFT_ERROR,
          payload: error,
        }));
    })
  );
}

export function clearCachedFormEpic(action$, store) {
  return action$.pipe(
    ofType(actions.CLEAR_FORM),
    map(() => localStorage.removeItem(FORM_STORAGE_KEY)),
    ignoreElements()
  );
}

export const reloadFormEpic = mappingEpic(actions.RELOAD_FORM, () => ({
  type: actions.RELOAD_FORM_SUCCESS,
  payload: loadFromStorage(FORM_STORAGE_KEY),
}));

export const saveDetailsAndContinueEpic = basicEpic(
  SAVE_DETAILS_AND_CONTINUE,
  ({ history }, state) => {
    const details = state.createEvent.details;
    details.eventOperatorId = state.session.currentUser.id;
    details.isDraft = true;
    return new EventService(state.session).createOrUpdate({ details }).then((result) => {
      let formData = loadFromStorage(FORM_STORAGE_KEY);
      writeToStorage(FORM_STORAGE_KEY, { ...formData, details: result });

      if (history) history.push('2');
      return result;
    });
  }
);

export const updateEventEpic = basicEpic(actions.UPDATE_EVENT, ({ history }, state) => {
  return new EventService(state.session).update(state.createEvent).then((event) => {
    history.push('/dashboard');
    return event;
  });
});

export const autoUpdateEventEpic = basicEpic(actions.AUTO_UPDATE_EVENT, (_, state) => {
  return new EventService(state.session).update(state.createEvent);
});

export const cancelEventEpic = basicEpic(actions.CANCEL_EVENT, ({ history, reason }, state) => {
  return new EventService(state.session)
    .cancelEvent({ id: state.createEvent.details.id, reason: reason })
    .then((event) => {
      history.push('/dashboard');
      return event;
    });
});

export const duplicateEventEpic = (action$, store) =>
  action$.pipe(
    ofType(actions.DUPLICATE_EVENT),
    mergeMap((action) => {
      const { history } = action.payload;
      history.push('/create-event/1?duplicated=true');
      return Observable.empty();
    })
  );

export const deleteDocumentEpic = basicEpic(DELETE_DOCUMENT, ({ documentId }, state) => {
  return new EventService(state.session).deleteDocument({
    documentId,
    eventId: state.createEvent.details.id,
  });
});

export const publishDraftEpic = basicEpic(actions.PUBLISH_DRAFT, ({ history }, state) => {
  state.createEvent.details.isDraft = false;
  return new EventService(state.session).update(state.createEvent).then((result) => {
    history.push('/dashboard');
    return result;
  });
});

// TODO: refactor this epic, use safe chaining and guard conditions
export const duplicateAllEapEpic = basicEpic(eapActions.DUPLICATE_ALL_EAP, (_, state) => {
  const lengthForms = state.createEvent.eap.forms.length;

  // Duplicate locations
  let locationToDuplicate =
    state.createEvent.eap.allEventLocations[state.createEvent.eap.currentForm];
  let duplicatedLocations = state.createEvent.eap.allEventLocations;
  for (let i = state.createEvent.eap.currentForm + 1; i < lengthForms; i++) {
    const orgId = get(duplicatedLocations, `[${i}].id`, null);
    const orgAddressId = get(duplicatedLocations, `[${i}].address.id`, null);
    duplicatedLocations[i] = JSON.parse(JSON.stringify(locationToDuplicate));
    set(duplicatedLocations[i], 'id', orgId);
    set(duplicatedLocations[i], 'address.id', orgAddressId);
  }

  // Duplicate the forms
  let formToDuplicate = state.createEvent.eap.forms[state.createEvent.eap.currentForm];
  let duplicatedForms = state.createEvent.eap.forms;
  for (let i = state.createEvent.eap.currentForm + 1; i < lengthForms; i++) {
    let orgEvLoc = duplicatedForms[i].eventLocation.id;
    let orgEvAdrLoc = duplicatedForms[i].eventLocation.address.id;
    duplicatedForms[i] = JSON.parse(JSON.stringify(formToDuplicate));
    duplicatedForms[i].eventLocation.id = orgEvLoc;
    duplicatedForms[i].eventLocation.address.id = orgEvAdrLoc;
  }

  // Upload EAP's and set the reference
  const eapService = new EmergencyActionPlanService(state.session, state.createEvent.details.id);
  let eapUploadReq = [];
  // We also upload the current form (so no more starting from +1 index )
  for (let i = state.createEvent.eap.currentForm; i < lengthForms; i++) {
    eapUploadReq.push(eapService.create(duplicatedForms[i]));
  }

  return Promise.all(eapUploadReq).then((eapUploadRes) => {
    const offset = state.createEvent.eap.currentForm;
    let shiftsUpdatedRef = state.createEvent.shifts.shifts;

    for (let i = 0; i < eapUploadReq.length; i++) {
      duplicatedForms[i + offset].id = eapUploadRes[i].id;
      shiftsUpdatedRef[i + offset].emergencyActionPlanId = eapUploadRes[i].id;
    }

    return Promise.resolve({
      type: eapActions.DUPLICATE_ALL_EAP_SUCCESS,
      payload: {
        forms: JSON.parse(JSON.stringify(duplicatedForms)),
        allEventLocations: JSON.parse(JSON.stringify(duplicatedLocations)),
        shifts: JSON.parse(JSON.stringify(shiftsUpdatedRef)),
      },
    });
  });
});

export const reloadUpcomingEventsEpic = mappingEpic(
  [
    actions.CREATE_EVENT_SUCCESS,
    actions.UPDATE_EVENT_SUCCESS,
    actions.CANCEL_EVENT_SUCCESS,
    actions.PUBLISH_DRAFT_SUCCESS,
  ],
  eventActions.GET_UPCOMING_EVENTS
);

export const reloadUpcomingShiftsEpic = mappingEpic(
  [
    actions.CREATE_EVENT_SUCCESS,
    actions.UPDATE_EVENT_SUCCESS,
    actions.CANCEL_EVENT_SUCCESS,
    actions.PUBLISH_DRAFT_SUCCESS,
  ],
  eventActions.GET_UPCOMING_SHIFTS
);

export const reloadDraftEventsEpic = mappingEpic(
  [
    actions.CREATE_EVENT_SUCCESS,
    actions.UPDATE_EVENT_SUCCESS,
    actions.CANCEL_EVENT_SUCCESS,
    actions.SAVE_AS_DRAFT_SUCCESS,
  ],
  eventActions.GET_DRAFT_EVENTS
);

const successMessages = {
  [actions.CREATE_EVENT_SUCCESS]: 'Your event has been successfully created',
  [actions.UPDATE_EVENT_SUCCESS]: 'Your event has been successfully updated',
  [actions.CANCEL_EVENT_SUCCESS]: 'Your event has been successfully deleted and removed',
  [actions.SAVE_AS_DRAFT_SUCCESS]: 'Draft has been saved',
  [actions.PUBLISH_DRAFT_SUCCESS]: 'Your event has been successfully published',
};

export const successMessagesEpic = mappingEpic(
  [
    actions.CREATE_EVENT_SUCCESS,
    actions.UPDATE_EVENT_SUCCESS,
    actions.CANCEL_EVENT_SUCCESS,
    actions.SAVE_AS_DRAFT_SUCCESS,
    actions.PUBLISH_DRAFT_SUCCESS,
  ],
  ({ type }) => success(successMessages[type])
);

const errorMessages = {
  [actions.CREATE_EVENT_ERROR]: 'Sorry! Could not create event',
  [actions.UPDATE_EVENT_ERROR]: 'Sorry! Could not update event',
  [actions.CANCEL_EVENT_ERROR]: 'Sorry! Could not cancel event',
  [actions.SAVE_AS_DRAFT_ERROR]: 'Sorry! Could not save draft',
};

export const errorMessagesEpic = mappingEpic(
  [
    actions.CREATE_EVENT_ERROR,
    actions.UPDATE_EVENT_ERROR,
    actions.CANCEL_EVENT_ERROR,
    actions.SAVE_AS_DRAFT_ERROR,
  ],
  ({ type, payload }) => error(`${errorMessages[type]}: ${payload.message}`)
);

export const showSpinnerEpic = mappingEpic(
  [
    actions.CREATE_EVENT,
    actions.UPDATE_EVENT,
    actions.SAVE_AS_DRAFT,
    actions.CANCEL_EVENT,
    actions.PUBLISH_DRAFT,
    DELETE_DOCUMENT,
  ],
  SHOW_SPINNER
);

export const hideSpinnerEpic = mappingEpic(
  [
    actions.CREATE_EVENT_ERROR,
    actions.CREATE_EVENT_SUCCESS,
    actions.UPDATE_EVENT_ERROR,
    actions.UPDATE_EVENT_SUCCESS,
    actions.CANCEL_EVENT_ERROR,
    actions.CANCEL_EVENT_SUCCESS,
    actions.SAVE_AS_DRAFT_ERROR,
    actions.SAVE_AS_DRAFT_SUCCESS,
    actions.PUBLISH_DRAFT_ERROR,
    actions.PUBLISH_DRAFT_SUCCESS,
    DELETE_DOCUMENT_SUCCESS,
    DELETE_DOCUMENT_ERROR,
  ],
  HIDE_SPINNER
);
