import { ofType } from 'redux-observable';
import { of } from 'rxjs/observable/of';
import { catchError, mergeMap, map, debounceTime, filter, mapTo } from 'rxjs/operators';
import EventService from 'services/v2/EventService';
import { EventService as V2EventService } from 'services/v2';
import { basicEpic, mappingEpic, repeatedEpic } from 'redux/ducks/helpers';
import { SHOW_SPINNER, HIDE_SPINNER } from 'redux/ducks/LoadingSpinner';
import { get } from 'lodash';
import DashboardService from 'services/v2/DashboardService';
import {
  APPROVE_JOB_MODIFICATION,
  APPROVE_JOB_MODIFICATION_ERROR,
  APPROVE_JOB_MODIFICATION_SUCCESS,
  DECLINE_JOB_MODIFICATION,
  DECLINE_JOB_MODIFICATION_ERROR,
  DECLINE_JOB_MODIFICATION_SUCCESS,
  REMOVE_ATHLETIC_TRAINER_SUCCESS,
  UPDATE_JOB_SUCCESS,
  UPDATE_JOB_V2_SUCCESS,
} from '../Job';
import SettingService from 'services/SettingService';
import { CREATE_SESSION_SUCCESS, RELOAD_SESSION_SUCCESS } from '../Session';
import ReviewService from 'services/ReviewService';
import { Observable } from 'rxjs';
import { GET_UPCOMING_EVENTS } from '../Event';
import * as actions from './actions';

const STORAGE_KEY = 'Go4Ellis/Dashboard';
const SHIFTS_NO_APPLICANTS_STORAGE_KEY = `${STORAGE_KEY}/ShiftsWithNoApplicants`;
const PENDING_APPLICANTS_STORAGE_KEY = `${STORAGE_KEY}/PendingApplicants`;
const SHIFT_MODIFICATIONS_STORAGE_KEY = `${STORAGE_KEY}/ShiftModifications`;

export const repeatRefreshApplicantsEpic = repeatedEpic(30000, actions.GET_APPLICANTS_EVENTS, {
  type: actions.GET_APPLICANTS_EVENTS,
  payload: { disableSpinner: true },
});

export const repeatRefreshShiftsWithNoAppsEpic = repeatedEpic(
  30000,
  actions.GET_SHIFTS_WITH_NO_APPLICANTS,
  {
    type: actions.GET_SHIFTS_WITH_NO_APPLICANTS,
    payload: { disableSpinner: true },
  }
);

export const repeatRefreshShiftModificationsEpic = repeatedEpic(
  30000,
  actions.GET_SHIFT_MODIFICATIONS,
  { type: actions.GET_SHIFT_MODIFICATIONS, payload: { disableSpinner: true } }
);

export function getShiftsWithNoApplicantsEpic(action$, store) {
  return action$.pipe(
    ofType(
      actions.GET_SHIFTS_WITH_NO_APPLICANTS,
      actions.CHANGE_SHIFTS_WITH_NO_APPLICANTS_PAGE,
      actions.CHANGE_SORT_SHIFTS_WITH_NO_APPLICANTS,
      actions.BOOST_RATE_SUCCESS,
      CREATE_SESSION_SUCCESS
    ),
    debounceTime(200),
    mergeMap((action) => {
      const state = store.getState();

      if (state.session.currentUser.role !== 'Event operator') return Observable.empty();

      const { search } = get(state, 'dashboard.shifts');
      let params = {
        per_page: 10,
        page: get(state, 'dashboard.shifts.pagination.current_page') || 1,
        ...(search && { search }),
      };

      const sortParams = get(state, 'dashboard.shifts.sort', {});
      const sortKey = Object.keys(sortParams).find((key) => sortParams[key]);
      if (sortKey) {
        params[`order_${sortKey}_${sortParams[sortKey]}`] = true;
      }

      const service = new DashboardService(state.session);
      return service.getNoApplicants(params);
    }),
    map((shifts) => {
      try {
        localStorage.setItem(SHIFTS_NO_APPLICANTS_STORAGE_KEY, JSON.stringify(shifts));
      } catch (err) {
        console.log(err, 'error saving to local storage');
      }
      return {
        type: actions.GET_SHIFTS_WITH_NO_APPLICANTS_SUCCESS,
        payload: shifts,
      };
    }),
    catchError((error) => {
      console.error('Error in getShiftsWithNoApplicantsEpic:', error);
      return of({
        type: actions.GET_SHIFTS_WITH_NO_APPLICANTS_ERROR,
        payload: error,
      });
    })
  );
}

export function getShiftModificationsEpic(action$, store) {
  return action$.pipe(
    ofType(
      actions.GET_SHIFT_MODIFICATIONS,
      actions.CHANGE_SHIFT_MODIFICATIONS_PAGE,
      actions.CHANGE_SORT_SHIFT_MODIFICATIONS,
      APPROVE_JOB_MODIFICATION_SUCCESS,
      DECLINE_JOB_MODIFICATION_SUCCESS,
      CREATE_SESSION_SUCCESS,
      RELOAD_SESSION_SUCCESS
    ),
    debounceTime(200),
    mergeMap((action) => {
      const state = store.getState();

      if (state.session.currentUser.role !== 'Event operator') return Observable.empty();

      const { search } = get(state, 'dashboard.modifications');
      let params = {
        per_page: 10,
        page: get(state, 'dashboard.modifications.pagination.current_page') || 1,
        ...(search && { search }),
      };

      const sortParams = get(state, 'dashboard.modifications.sort', {});
      const sortKey = Object.keys(sortParams).find((key) => sortParams[key]);
      if (sortKey) {
        params[`order_${sortKey}_${sortParams[sortKey]}`] = true;
      }

      const service = new DashboardService(state.session);
      return service.getShiftModifications(params);
    }),
    map((shifts) => {
      try {
        localStorage.setItem(SHIFT_MODIFICATIONS_STORAGE_KEY, JSON.stringify(shifts));
      } catch (err) {
        console.log(err, 'error saving to local storage');
      }
      return { type: actions.GET_SHIFT_MODIFICATIONS_SUCCESS, payload: shifts };
    }),
    catchError((error) => {
      console.error('Error in getShiftModificationsEpic:', error);
      return of({
        type: actions.GET_SHIFT_MODIFICATIONS_ERROR,
        payload: error,
      });
    })
  );
}

export const boostRateEpic = basicEpic(actions.BOOST_RATE, (payload, state) => {
  const service = new EventService(state.session);
  return service.changeRate(payload);
});

export const reloadEventsAfterBoost = (action$, store) => {
  return action$.pipe(ofType(actions.BOOST_RATE_SUCCESS), mapTo({ type: GET_UPCOMING_EVENTS }));
};

export function getApplicantsEpic(action$, store) {
  return action$.pipe(
    ofType(
      actions.GET_APPLICANTS_EVENTS,
      UPDATE_JOB_SUCCESS,
      UPDATE_JOB_V2_SUCCESS,
      REMOVE_ATHLETIC_TRAINER_SUCCESS,
      actions.CHANGE_APPLICANTS_EVENTS_PAGE,
      actions.CHANGE_SORT_APPLICANT,
      CREATE_SESSION_SUCCESS,
      RELOAD_SESSION_SUCCESS
    ),
    debounceTime(200),
    mergeMap((action) => {
      const state = store.getState();

      if (state.session.currentUser.role !== 'Event operator') return Observable.empty();

      const service = new V2EventService(state.session);
      const search = get(state, 'dashboard.applicants.search');
      let params = {
        per_page: 10,
        page: get(state, 'dashboard.applicants.pagination.current_page') || 1,
        ...(search && { search }),
      };

      const sortParams = get(state, 'dashboard.applicants.sort', {});
      const sortKey = Object.keys(sortParams).find((key) => sortParams[key]);
      if (sortKey) {
        params[`order_${sortKey}_${sortParams[sortKey]}`] = true;
      }

      return service.applicants(params);
    }),
    map((events) => {
      try {
        localStorage.setItem(PENDING_APPLICANTS_STORAGE_KEY, JSON.stringify(events));
      } catch (err) {
        console.log(err, 'error saving to local storage');
      }
      return { type: actions.GET_APPLICANTS_EVENTS_SUCCESS, payload: events };
    }),
    catchError((error) => {
      return of({
        type: actions.GET_APPLICANTS_EVENTS_ERROR,
        payload: error,
      });
    })
  );
}

export const getResourcesEpic = basicEpic(actions.GET_RESOURCES, (payload, state) => {
  const service = new SettingService(state.session);
  return service.getAll();
});

export const triggerGetResources = (action$, store) => {
  return action$.pipe(
    ofType(RELOAD_SESSION_SUCCESS, CREATE_SESSION_SUCCESS),
    mergeMap(() => of({ type: actions.GET_RESOURCES }))
  );
};

export function getReviewsEpic(action$, store) {
  return action$.pipe(
    ofType(
      actions.GET_REVIEWS,
      actions.CHANGE_REVIEWS_PAGE,
      actions.CHANGE_SORT_REVIEWS,
      actions.UPDATE_REVIEW_SUCCESS,
      RELOAD_SESSION_SUCCESS,
      CREATE_SESSION_SUCCESS
    ),
    debounceTime(200),
    mergeMap((action) => {
      const state = store.getState();
      if (state.session.currentUser.role !== 'Event operator') return Observable.empty();

      const { search } = get(state, 'dashboard.reviews');
      let params = {
        page: get(state, 'dashboard.reviews.pagination.current_page') || 1,
        ...(search && { search }),
        per_page: 10,
      };

      const sortParams = get(state, 'dashboard.reviews.sort', {});
      const sortKey = Object.keys(sortParams).find((key) => sortParams[key]);
      if (sortKey) {
        params[`order_${sortKey}_${sortParams[sortKey]}`] = true;
      }

      const service = new ReviewService(state.session);
      return service.getReviews(params);
    }),
    map((reviews) => {
      return { type: actions.GET_REVIEWS_SUCCESS, payload: reviews };
    }),
    catchError((error) => {
      return of({
        type: actions.GET_REVIEWS_ERROR,
        payload: error,
      });
    })
  );
}

export const updateReviewEpic = basicEpic(actions.UPDATE_REVIEW, (payload, state) => {
  return new ReviewService(state.session).update(payload);
});

export const getJobsMetadataEpic = basicEpic(actions.GET_JOBS_METADATA, ({ params }, state) => {
  return new V2EventService(state.session).jobsMetadata(params);
});

export const mapApplicantsToGetJobsMetadataEpic = (action$, store) => {
  return action$.pipe(
    ofType(actions.GET_APPLICANTS_EVENTS_SUCCESS),
    mergeMap((action) => {
      const jobIds = action.payload.events.flatMap((e) =>
        e.shifts.flatMap((s) => s.jobs.map((j) => j.id))
      );
      if (jobIds.length === 0) return Observable.empty();

      return of({ type: actions.GET_JOBS_METADATA, payload: { params: { job_ids: jobIds } } });
    })
  );
};

export const showSpinnerEpic = (action$) =>
  action$.pipe(
    ofType(
      actions.GET_SHIFTS_WITH_NO_APPLICANTS,
      actions.CHANGE_SHIFTS_WITH_NO_APPLICANTS_PAGE,
      actions.CHANGE_SORT_SHIFTS_WITH_NO_APPLICANTS,
      actions.CHANGE_REVIEWS_PAGE,
      actions.GET_REVIEWS,
      actions.CHANGE_SORT_REVIEWS,
      actions.UPDATE_REVIEW,
      actions.GET_APPLICANTS_EVENTS,
      actions.CHANGE_APPLICANTS_EVENTS_PAGE,
      actions.CHANGE_SORT_APPLICANT,
      actions.GET_SHIFT_MODIFICATIONS,
      actions.CHANGE_SHIFT_MODIFICATIONS_PAGE,
      actions.CHANGE_SORT_SHIFT_MODIFICATIONS,
      APPROVE_JOB_MODIFICATION,
      DECLINE_JOB_MODIFICATION
    ),
    filter((action) => {
      if (action.payload) {
        return !action.payload.disableSpinner;
      } else {
        return true;
      }
    }),
    mapTo({ type: SHOW_SPINNER })
  );

export const hideSpinnerEpic = mappingEpic(
  [
    actions.GET_SHIFTS_WITH_NO_APPLICANTS_SUCCESS,
    actions.GET_SHIFTS_WITH_NO_APPLICANTS_ERROR,
    actions.UPDATE_REVIEW_SUCCESS,
    actions.UPDATE_REVIEW_ERROR,
    actions.GET_REVIEWS_ERROR,
    actions.GET_REVIEWS_SUCCESS,
    actions.GET_APPLICANTS_EVENTS_SUCCESS,
    actions.GET_APPLICANTS_EVENTS_ERROR,
    actions.GET_SHIFT_MODIFICATIONS_SUCCESS,
    actions.GET_SHIFT_MODIFICATIONS_ERROR,
    APPROVE_JOB_MODIFICATION_SUCCESS,
    DECLINE_JOB_MODIFICATION_SUCCESS,
    APPROVE_JOB_MODIFICATION_ERROR,
    DECLINE_JOB_MODIFICATION_ERROR,
  ],
  HIDE_SPINNER
);
