import React, { useState } from 'react';
import { useStripe, useElements, CardElement } from '@stripe/react-stripe-js';
import { connect } from 'react-redux';
import { updateToken, submit } from 'redux/ducks/Payment';
import classnames from 'classnames';
import { CARD_OPTIONS } from './helpers';
import ErrorMessage from './ErrorMessage';

const NewCreditCardV2 = ({ userId, updateToken, submit, onSavePayment }) => {
  const stripe = useStripe();
  const elements = useElements();
  const [error, setError] = useState(null);

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (!stripe || !elements) {
      // Stripe.js has not loaded yet. Make sure to disable
      // form submission until Stripe.js has loaded.
      return;
    }

    if (error) {
      elements.getElement('card').focus();
      return;
    }

    const payload = await stripe.createToken(elements.getElement(CardElement));

    if (payload.error) {
      setError(payload.error);
    } else {
      onSavePayment()
      updateToken(payload.token);
      submit(userId, payload.token);
      setError(null);
    }
  };

  return (
    <form onSubmit={handleSubmit} className="new-credit-card-v2">
      <div className="stripe-card">
        <CardElement
          options={CARD_OPTIONS}
          onReady={() => {
            console.log('CardElement [ready]');
          }}
          onChange={(event) => {
            setError(null);
            console.log('CardElement [change]', event);
          }}
          onBlur={() => {
            console.log('CardElement [blur]');
          }}
          onFocus={() => {
            console.log('CardElement [focus]');
          }}
        />
      </div>
      {error && <ErrorMessage>{error.message}</ErrorMessage>}
      <div className="actions center">
        <button
          className={classnames('v2-button gotham-bold-font', {
            disabled: !stripe || !elements,
          })}
          type="submit"
          disabled={!stripe || !elements}
        >
          Save Payment Method
        </button>
      </div>
    </form>
  );
};

const mapStateToProps = (state) => ({
  userId: state.session.currentUser.id,
});
const mapDispatchToProps = (dispatch) => ({
  updateToken: (token) => dispatch(updateToken(token)),
  submit: (userId, token) => dispatch(submit(userId, token)),
});

export default connect(mapStateToProps, mapDispatchToProps)(NewCreditCardV2);
