// Actions
// ========================================================
export const GET_MEDICAL_RECORD = 'go4ellis/MedicalRecord/GET_MEDICAL_RECORD';
export const GET_MEDICAL_RECORD_SUCCESS = 'go4ellis/MedicalRecord/GET_MEDICAL_RECORD_SUCCESS';
export const GET_MEDICAL_RECORD_ERROR = 'go4ellis/MedicalRecord/GET_MEDICAL_RECORD_ERROR';

export const DOWNLOAD_MEDICAL_RECORD = 'go4ellis/MedicalRecord/DOWNLOAD_MEDICAL_RECORD';
export const DOWNLOAD_MEDICAL_RECORD_SUCCESS = 'go4ellis/MedicalRecord/DOWNLOAD_MEDICAL_RECORD_SUCCESS';
export const DOWNLOAD_MEDICAL_RECORD_ERROR = 'go4ellis/MedicalRecord/DOWNLOAD_MEDICAL_RECORD_ERROR';

export const OPEN_MEDICAL_RECORD = 'go4ellis/MedicalRecord/OPEN_MEDICAL_RECORD';
export const OPEN_MEDICAL_RECORD_SUCCESS = 'go4ellis/MedicalRecord/OPEN_MEDICAL_RECORD_SUCCESS';
export const OPEN_MEDICAL_RECORD_ERROR = 'go4ellis/MedicalRecord/OPEN_MEDICAL_RECORD_ERROR';
// Reducer Functions
// ========================================================

function getMedicalRecordSuccess(state, medicalRecord) {
    return {
      ...state,
      medicalRecord,
    }
}

// Action Creator Functions
// ========================================================
export function getMedicalRecord(id) {
  return { type: GET_MEDICAL_RECORD, payload: id };
}

export function downloadMedicalRecord(id) {
  return { type: DOWNLOAD_MEDICAL_RECORD, payload: id };
}

export function openMedicalRecord(id) {
  return { type: OPEN_MEDICAL_RECORD, payload: id };
}

// Reducer
// ========================================================
const DEFAULT_STATE = {
  medicalRecord: {},
};

export default function reducer(state = DEFAULT_STATE, action = {}) {
  switch (action.type) {
    case GET_MEDICAL_RECORD_SUCCESS:
      return getMedicalRecordSuccess(state, action.payload)
    default: return state;
  }
}