import { basicEpic, mappingEpic } from 'redux/ducks/helpers';
import ShiftsService from 'services/ShiftsService';
import * as shiftActions from './actions';
import * as eventActions from 'redux/ducks/Event';
import { get } from 'lodash';

export const deleteShiftEpic = basicEpic(
  shiftActions.DELETE_SHIFT,
  ({ shift, eventId, reason }, state) => {
    return new ShiftsService(state.session, eventId).delete(shift, reason);
  }
);

export const removeLocationEpic = basicEpic(
  shiftActions.REMOVE_LOCATION,
  ({ locationId, event, reason }, state) => {
    const shifts = Object.values(get(event, 'shifts.byId', {}))
      .filter((shift) => get(shift, 'eventLocation.id') === locationId)
      .map((shift) => ({
        ...shift,
        start: shift.startTime,
        end: shift.endTime,
        cancelled: true,
        cancelReason: reason || 'Not Provided',
        locationCancelled: true,
      }));

    if (!event.id || !locationId || !shifts.length)
      return Promise.reject(new Error('Invalid event or location'));

    return new ShiftsService(state.session, event.id).create(shifts);
  }
);

export const triggerLocationDeleted = mappingEpic(
  [shiftActions.REMOVE_LOCATION_SUCCESS],
  eventActions.GET_UPCOMING_EVENTS
);
