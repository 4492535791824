import React, { Component } from 'react';

function withLogoCol(Component) {
  return props => {
    return (
      <div className="withLogoCol">
        <div className="col-5 d-none d-lg-block logo-col">
        <div className="logo">
            <img src={require('logo-4.svg')} alt="4" />
          </div>
        </div>
        <div className="col px-5 px-lg-0 content-col">
          <Component {...props} />
        </div>
      </div>
    )
  }
}

export default withLogoCol;