// @flow
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { UncontrolledAlert, UncontrolledTooltip } from 'reactstrap';
import VenueLocation from './VenueLocation';
import EventDetailsPanel from '../../EventDetailsPanel/EventDetailsPanel';
import GenerateEAPForm from './GenerateEAPForm';
import UploadEAPForm from './UploadEAPForm';
import {
  generateOrUploadChange,
  newPlanUploaded,
  newPlanPreview,
} from 'redux/ducks/CreateEvent/EAP/actions';

import type { Dispatch } from 'redux/ducks/helpers';
import type { EAPFormType } from 'redux/ducks/CreateEvent/EAP';
import { fieldChange } from 'redux/ducks/CreateEvent/Details';

type EAPFormProps = {
  dispatch: Dispatch;
  formInstance: EAPFormType;
};

class EAPForm extends Component<EAPFormProps> {
  fileReader = (() => {
    const reader = new FileReader()
    reader.addEventListener('load', () => {
      this.props.dispatch(newPlanPreview(reader.result))
    });
    return reader;
  })();

  onGenerateOrUploadChange = (ev) => {
    this.props.dispatch(generateOrUploadChange(ev.currentTarget.value));
  }

  onNewUploadedPlan = (plan) => {
    this.fileReader.readAsDataURL(plan);
    this.props.dispatch(newPlanUploaded(plan));
  }

  render() {
    return (
      <div className="eAPForm">
        <div className="eap-form-header">
          <div className="eap-form-title">Emergency Action Plan (EAP)</div>
          <div className="eap-explainer btn-link"><a id="WhatsThisTooltip">What is this?</a><UncontrolledTooltip target="WhatsThisTooltip">An Emergency Action Plan (EAP) is a detailed yet flexible plan created for when catastrophic injuries or scenarios occur. An EAP ensures that an emergency response is rapid, appropriate, controlled and precise. EAPs should be reviewed with all staff on-site including athletic trainers and local emergency medical services.</UncontrolledTooltip></div>
        </div>
        <div className="eap-form-subheader">
          <div className="col-md-9">
            <EventDetailsPanel
              details={this.props.details}
              onFieldChange={this.props.onFieldChange}
            />
          </div>
          <div className="col d-flex flex-column justify-content-center generate-or-upload-col">
            <div className="generate-or-upload">
              <label>Select One</label>
              <div className="custom-control custom-radio">
                <input type="radio" id="generateEAP" value="generate"
                  onChange={this.onGenerateOrUploadChange}
                  name="generateOrUpload" className="custom-control-input"
                  checked={this.props.formInstance.generateOrUpload === "generate"} />
                <label className="custom-control-label" htmlFor="generateEAP">Generate A Plan</label>
              </div>
              <div className="custom-control custom-radio">
                <input type="radio" id="uploadEAP" value="upload"
                  onChange={this.onGenerateOrUploadChange}
                  checked={this.props.formInstance.generateOrUpload === "upload"}
                  name="generateOrUpload" className="custom-control-input" />
                <label className="custom-control-label" htmlFor="uploadEAP">Upload Your Own</label>
              </div>
            </div>
          </div>
        </div>
        <div>
          {this.props.formInstance.id && <UncontrolledAlert className="eap-exists-message">
            <div>Emergency Action Plan On File </div>
            <div>Looks like you’ve used this location before.</div>
            <div>If you’d like to use the same Emergency Action Plan, be sure to confirm that all information below is correct and up-to-date.</div>
          </UncontrolledAlert>}
        </div>
        <VenueLocation eventLocation={this.props.formInstance.eventLocation} />
        {this.props.formInstance.generateOrUpload === "generate" ?
          <GenerateEAPForm {...this.props} /> :
          <UploadEAPForm
            filePreview={this.props.formInstance.uploadedPlanPreview}
            onNewFile={this.onNewUploadedPlan}
          />}
      </div>
    );
  }
}

export const mapStateToProps = (state) => ({
  details: state.createEvent.details
});

export const mapDispatchToProps = (dispatch) => ({
  onFieldChange: (name: string, newValue: any) => dispatch(fieldChange(name, newValue)),
  dispatch
});

export default connect(mapStateToProps, mapDispatchToProps)(EAPForm);