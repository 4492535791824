import React, { Component } from "react";

import { startCase } from "lodash";

import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";

class HealthModal extends Component {
  state = {
    modalOpen: false,
  };

  constructor() {
    super();

    this.onToggle = this.onToggle.bind(this);
  }

  onToggle() {
    this.setState({ modalOpen: !this.state.modalOpen });
  }

  render() {
    return (
      <Modal
        isOpen={this.state.modalOpen}
        toggle={this.onToggle}
        className="heathModal"
        style={{ maxWidth: "750px" }}
      >
        <ModalBody style={{ fontSize: "x-large" }}>
          For COVID-19 testing medical support staff, call <br />
          <a href="tel:+1833-464-2827">1-833-464-2827</a> or email us at{" "}
          <a href="mailto:contactus@go4.io">contactus@go4.io</a>.
          <br />
          For more information <a href="/covid-testing">click here</a>.
        </ModalBody>
        <ModalFooter>
          <div />
          <button
            onClick={this.onToggle}
            className="btn btn-primary btn-danger"
          >
            Close
          </button>
        </ModalFooter>
      </Modal>
    );
  }
}

export default HealthModal;
