import React, { Component } from 'react';
import { connect } from 'react-redux';
import { isEmpty } from 'lodash';
import EventFinancesList from 'components/EventFinancesList';
import FinanceFiltersPanel from 'components/FinanceFiltersPanel';
import { exportEvents, resetPastFilters } from 'redux/ducks/Event';

class FinancesScene extends Component {
  state = { appliedFilters: false };
  applyFilters = (value) => this.setState({ appliedFilters: value });
  onClickExport = (e) => {
    e.preventDefault();
    this.props.exportEvents();
  };
  goToEditProfilePage = (e) => {
    e.preventDefault();
    this.props.history.push('/edit-profile');
  };
  onClearAll = () => {
    this.props.reset({ past: true });
    this.applyFilters(false);
  };

  render() {
    const { per_page, total } = this.props.pagination;
    return (
      <div className="financesScene">
        <div className="events">
          <div className="header">
            <h1>Finances</h1>
            <div className="actions row">
              <FinanceFiltersPanel applyFilters={this.applyFilters} />
              <a className="btn btn-danger px-4" href="#" onClick={this.onClickExport}>
                Export
              </a>
            </div>
          </div>
          <div className="header mb-2 align-items-center">
            <h6 className="results-pagination mr-2 mb-0">
              1-{per_page} of {total} Results
            </h6>
            {this.state.appliedFilters && (
              <a className="clear-filters-link" href="#" onClick={this.onClearAll}>
                Clear all filters
              </a>
            )}
          </div>
          {this.props.loaded && this.props.emptyEvents ? (
            <div className="empty-events">
              {!this.state.appliedFilters ? (
                <h3>
                  You currently have no completed events that you have paid out. Create some new
                  events to get started!
                </h3>
              ) : (
                <h3>No results.</h3>
              )}
            </div>
          ) : (
            <div>
              <p>
                Click EXPORT to receive a detailed report via email.{' '}
                <a className="edit-profile-link" herf="#" onClick={this.goToEditProfilePage}>
                  Click here
                </a>{' '}
                to add additional recipients to the Finance Email list.
              </p>
              <EventFinancesList />
            </div>
          )}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    loaded: state.event.loaded.past,
    emptyEvents: isEmpty(state.event.events.past),
    pagination: state.event.events.pastPagination,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    exportEvents: () => dispatch(exportEvents()),
    reset: () => dispatch(resetPastFilters()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(FinancesScene);
