import * as locationActions from '../Locations/actions';
import * as postActions from 'redux/ducks/Post/actions';
import { pick } from 'lodash';

// Actions
// ========================================================
export const FIELD_CHANGE = 'go4ellis/Post/Details/FIELD_CHANGE';

// Reducer Functions
// ========================================================
const initDetailsReducer = (state, payload) => ({
  ...DEFAULT_STATE,
  ...pick(payload, Object.keys(DEFAULT_STATE)),
});

const fieldChangeReducer = (state, payload: { name: string, value: any }) => {
  return {
    ...state,
    [payload.name]: payload.value,
  };
};

const savedDetailsReducer = (state, payload) => ({
  ...state,
  ...pick(payload.details, Object.keys(DEFAULT_STATE)),
});

function deleteDocumentSuccessReducer(state, { documentId }: { documentId: string }) {
  if (state.existingDocuments) {
    return {
      ...state,
      existingDocuments: state.existingDocuments.filter((d) => d.id !== documentId),
    };
  }

  return state;
}

const reloadFormReducer = (state, payload) => {
  return payload?.details ? { ...payload.details } : state;
};

// Action Creators
// ========================================================
export const changeDetailsField = (payload) => {
  return { type: FIELD_CHANGE, payload };
};

export const deleteAttachedDocument = (payload) => {
  return { type: postActions.DELETE_DOCUMENT, payload };
};

// Reducer
// ========================================================
const DEFAULT_STATE = {
  version: '',
  scheduleType: '',
  isDraft: true,
  id: null,
  paymentToken: '',
  documents: [],
  existingDocuments: [],
  atsHired: 0,
  createdAt: '',
  lastPaymentIssued: '',
  description: '',
  url: '',
  totalPaymentToDate: 0,
  eventOperatorId: null,
  sport: '',
  instructions: '',
  locationsNo: 1,
};

export type DetailsType = typeof DEFAULT_STATE;

export default function reducer(state = DEFAULT_STATE, action = {}) {
  switch (action.type) {
    case FIELD_CHANGE:
      return fieldChangeReducer(state, action.payload);

    case postActions.V2_RELOAD_FORM_SUCCESS:
      return reloadFormReducer(state, action.payload);
    case postActions.V2_CLEAR_FORM:
      return DEFAULT_STATE;
    case postActions.SAVE_AS_DRAFT_SUCCESS:
    case postActions.SAVE_PROFILE_SUCCESS:
      return savedDetailsReducer(state, action.payload);
    case postActions.DELETE_DOCUMENT_SUCCESS:
      return deleteDocumentSuccessReducer(state, action.payload);
    case postActions.DUPLICATE_POST:
    case postActions.INITIALIZE_POST:
      return initDetailsReducer(state, action.payload);

    case locationActions.DELETE_LOCATION:
      return { ...state, locationsNo: state.locationsNo > 1 ? state.locationsNo - 1 : 1 };
    default:
      return state;
  }
}
