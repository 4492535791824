import React, { Component } from 'react';

import classnames from 'classnames';

class CollapsibleFilterPanel extends Component {
  state = {
    showFilters: false,
  };

  onCancel = (event) => {
    event.preventDefault();

    if (!!this.props.applyFilters) this.props.applyFilters(false);
    this.props.onCancel();
  };

  onSubmit = (event) => {
    event.preventDefault();

    if (!!this.props.applyFilters) this.props.applyFilters(true);
    this.setState({ showFilters: false });
    this.props.onSubmit();
  };

  onToggleFilters = () => {
    this.setState({ showFilters: !this.state.showFilters });
  };

  render() {
    return (
      <>
        <a
          className="btn btn-primary filter-btn"
          href="#"
          onClick={(e) => {
            e.preventDefault();
            this.onToggleFilters();
          }}
        >
          <i className='material-icons'>filter_list</i>
          Filter
        </a>
        <div className="collapsibleFilterPanel">
          {this.state.showFilters && (
            <button className='toggle plain-button' onClick={this.onToggleFilters}>
              <i className='material-icons'>keyboard_arrow_right</i>
            </button>
          )}
          <div className={classnames('content', this.state.showFilters ? 'show' : 'hide')}>
            <h2>{this.props.title}</h2>
            <form onSubmit={this.onSubmit}>
              {this.props.children}

              <div className='form-controls'>
                <button className='btn bold-button btn-outline-secondary' onClick={this.onCancel} type='button'>Clear Filters</button>
                <button className='btn btn-danger bold-button' type='submit'>Apply Filters</button>
              </div>
            </form>
          </div>
        </div>
      </>
    );
  }
}

export default CollapsibleFilterPanel;