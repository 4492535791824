import React, { Component } from 'react';

import { Link } from 'react-router-dom';

class ResetPasswordSuccess extends Component {
  render() {
    if (this.props.type === "athletictrainer") {
      return (
        <div className="resetPasswordSuccess">
          <img src="success-checkmark-icon.png" alt="a check mark, indicating success" />
          <p>Your password has successfully been reset. Please login via the App with your new password.</p>
          <a className="badge apple" href="https://itunes.apple.com/st/app/go4ellis/id1186370661">
            <img src={require('../../RegistrationScene/Role/app-store-badge.svg')} alt="Download on the App Store" />
          </a>
          <a className="badge google" href="https://play.google.com/store/apps/details?id=com.go4ellis">
            <img src={require('../../RegistrationScene/Role/google-play-badge.png')} alt="Get it on Google Play" />
          </a>
        </div>
      );
    }
    const getLoginLink = (type) => {
      if (type === "parent") {
        return "/v2/parent/login";
      }
      if (type === "patient") {
        return "/v2/patient/login";
      }
      return "/v2/login";
    }
    const loginLink = getLoginLink(this.props.type);
    return (
      <div className="resetPasswordSuccess">
        <img src="success-checkmark-icon.png" alt="a check mark, indicating success" />
        <p>Your password has successfully been reset. You can now <Link to={loginLink} className="text-danger">log in</Link> with your new password</p>
        <Link to={loginLink} className="login-link">take me to login</Link>
      </div>
    );
  }
}

export default ResetPasswordSuccess;