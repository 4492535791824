import React from 'react';
import { connect } from 'react-redux';
import { Modal } from 'reactstrap';
import { FOUR, INNERCIRCLE, OUTERCIRCLE } from './SpinnerSVG';

const LoadingSpinner = ({ isOpen }) => {
  const randomKey = Math.floor(Math.random() * 1e8);
  return (
    <Modal
      id={`loading-spinner-modal-${randomKey}`}
      key={randomKey}
      isOpen={isOpen}
      modalClassName="loading-modal"
    >
      <div className="spinner-container">
        <FOUR className="four" />
        <INNERCIRCLE className="four spin-clockwise" />
        <OUTERCIRCLE className="four spin-trigonometric" />
      </div>
    </Modal>
  );
};

const mapStateToProps = ({ showLoadingSpinner }) => ({ isOpen: showLoadingSpinner });

export default connect(mapStateToProps)(LoadingSpinner);
