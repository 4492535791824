import React, { Component } from 'react';

class EmergencyEquipment extends Component {
  // TODO: This list should be an enum that loads from the server, like Sport or Gender
  equipmentTypes = [
    'Ice',
    'Automated External Defibrillator (AED)',
    'Ambulance on-site location',
    'Water Source',
    'Medical Supplies',
    'Splint Bag',
    'Crutches',
    'Spine Board',
    'Supplemental Oxygen',
    'Baby Pool',
    'Other'
  ]
  equipmentTypeOptions = () => [<option value='' key=''></option>].concat(this.equipmentTypes.map(name => <option key={name} value={name}>{name}</option>));

  onRemove = (equipmentIndex) => () => {
    this.props.removeEquipment(equipmentIndex)
  }

  onNameChange = (idx) => (ev) => this.props.onChange(idx, { name: ev.currentTarget.value })

  onLocationChange = (idx) => (ev) => this.props.onChange(idx, { location: ev.currentTarget.value })


  render() {
    return (
      <div className="emergencyEquipment">
        <div className="section-header col"><span>Emergency Equipment</span> <button onClick={this.props.clearEquipment} className="btn btn-link">CLEAR FIELDS</button></div>
        <p>Select the equipment or supplies that will be on-site, and where to find them.</p>
        {this.props.equipmentList.filter(e => !e._destroy).map((equipment, idx) => {
          return (
            <div key={idx} className="form-row">
              <div className="input-group col-5">
                <select className="custom-select"
                  value={equipment.name}
                  onChange={this.onNameChange(idx)}
                >
                  {this.equipmentTypeOptions(equipment)}
                </select>
              </div>
              <div className="form-group col-6">
                <input type="text"
                  placeholder="Location"
                  value={equipment.location}
                  onChange={this.onLocationChange(idx)}
                  className="form-control"
                />
              </div>
              <div className="col-1 d-flex flex-column align-items-center">
                <i className="material-icons remove-contact-btn" onClick={this.onRemove(idx)}>close</i>
              </div>
            </div>
          )
        })}
        <button className="btn btn-lg btn-primary" onClick={this.props.addEquipment}>Add Emergency Equipment</button>
      </div>
    );
  }
}

export default EmergencyEquipment;