import React from 'react';
import { sortBy } from 'lodash';

const ShiftList = ({
  ListItem,
  shifts,
  event,
}) => {
  const sortedShifts = sortBy(shifts, 'startTime');
  return (
    <div className="shiftList">
      {sortedShifts.map((shift) => {
        return <ListItem shift={shift} event={event} key={shift.id} />
      })}
    </div>
  );
}

export default ShiftList;
