import React from "react";
import { Link } from "react-router-dom";

const RedHomeButton = ({ href, text, asLink, to, className }) => {
  if (asLink) {
    return (
      <Link className={`redHomeButton ${className || ""}`} to={to}>
        {text}
      </Link>
    );
  }
  return (
    <a className={`redHomeButton ${className || ""}`} role="button" href={href}>
      {text}
    </a>
  );
};

export default RedHomeButton;
