import React, { Component } from "react";
import { Link } from "react-router-dom";

class ResetPasswordSuccess extends Component {

  getLoginLink = (type) => {
    if (type === "parent") {
      return "/v2/parent/login";
    }
    if (type === "patient") {
      return "/v2/patient/login";
    }
    return "/v2/login";
  }

  loginLink = this.getLoginLink(this.props.type);

  render() {
    if (this.props.type === "athletictrainer") {
      return (
        <div className="v2resetPasswordSuccess">
          <p>Your password has successfully been reset. Please login via the App with your new password.</p>
          <div className="badges">
            <a className="badge apple" href="https://itunes.apple.com/st/app/go4ellis/id1186370661">
              <img src={require('./assets/app-store-badge.svg')} alt="Download on the App Store" />
            </a>
            <a className="badge google" href="https://play.google.com/store/apps/details?id=com.go4ellis">
              <img src={require('./assets/google-play-badge.png').default} alt="Get it on Google Play" />
            </a>
          </div>
        </div>
      );
    }

    return (
      <div className="v2resetPasswordSuccess">
        <p>Your password has successfully been reset. You can now <Link to={this.loginLink} className="text-danger">log in</Link> with your new password.</p>
      </div>
    );
  }
}

export default ResetPasswordSuccess;