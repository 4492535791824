import { get } from 'lodash';

class ProfileSerializer {
  static deserialize(profile) {
    if (!profile) return null;

    return {
      completedEducationExperience: profile.completed_education_experience,
      completedPersonalInformation: profile.completed_personal_information,
      completedSettingExperience: profile.completed_setting_experience,
      completedSportExperience: profile.completed_sport_experience,
      contractPreferences: profile.contract_preferences,
      perDiemPreferences: this.deserializePerDiemPreferences(profile.per_diem_preferences),
      educationExperience: this.deserializeEducation(profile.education_experience),
      settingExperience: this.deserializeSetting(profile.setting_experience),
      sportExperience: this.deserializeSport(profile.sport_experience),
    };
  }
  static deserializeEducation(data) {
    if (!data)
      return {
        graduationYear: null,
        firstYearCertified: null,
        isCurrentStudent: false,
      };

    return {
      graduationYear: data.graduation_year,
      firstYearCertified: data.first_year_certified,
      isCurrentStudent: data.is_current_student,
    };
  }
  static deserializeSetting(data) {
    return { fields: data?.fields || [] };
  }
  static deserializeSport(data) {
    return { fields: data?.fields || [] };
  }

  static deserializePerDiemPreferences(data) {
    return {
      settingTypes: data?.setting_types || [],
      sportTypes: data?.sport_types || [],
      jobTypes: {
        perDiem: get(data, 'job_types.per_diem'),
        contract: get(data, 'job_types.contract'),
      },
      maxZipDistance: data?.max_zip_distance || null,
      minShiftDuration: data?.min_shift_duration || null,
      weekSchedule: data?.week_schedule || [],
      weekendSchedule: data?.weekend_schedule || [],
      minHourlyPay: data?.min_hourly_pay || null,
      contactPreferences: data?.contact_preferences || [],
    };
  }
}

export default ProfileSerializer;
