import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Modal, ModalHeader, ModalBody } from 'reactstrap';
import { downloadAllCredentials } from 'redux/ducks/Credentials';
import CredentialModal from 'components/CredentialModal';
import { PRIORITY_FILTER } from './credentialHelpers';
import AthleticTrainerService from 'services/v2/AthleticTrainerService';
import { isContractEo } from 'helpers/contracts';
import { computeReliabilityScore } from 'helpers/reliabilityScore';

export class AthleticTrainerModal extends Component {
  state = {
    modalOpen: false,
    jobCount: 0,
  };

  onOpened = () => {
    const athleticTrainerService = new AthleticTrainerService(this.props.session);
    athleticTrainerService.getATStatistics(this.props.user.id)
      .then((data) => {
        this.setState({ jobCount: data.job_count });
      })
      .catch((error) => {
        console.log(error);
      });
  };

  onDownloadAllCredentials = () => {
    this.props.downloadAllCredentials(this.props.user.id);
  };

  onToggle = () => {
    this.setState({ modalOpen: !this.state.modalOpen });
  };

  render() {
    const user = this.props.user || {
      name: '',
      phone: '',
      credentials: {},
      profilePic: '',
      eventProfessionId: 0,
      npiNumber: '',
    };
    const linkText = this.props.linkText ?
      this.props.linkText : user.name;
    const { professions } = this.props;
    const { credentials } = user;
    const sortedCredentialKeys = Object.keys(credentials).sort((a, b) => {
      let priorityA = PRIORITY_FILTER[credentials[a].type];
      let priorityB = PRIORITY_FILTER[credentials[b].type];
      if (!priorityA) priorityA = 20;
      if (!priorityB) priorityB = 20;

      return priorityA - priorityB;
    })
    const profession = professions.find(p => p.id == user.eventProfessionId)
    const professionName = !!profession ? profession.name : '';

    const reliabilityScore = computeReliabilityScore({
      worked: Number(user.shiftsWorked) || 0,
      callouts: Number(user.callouts) || 0,
      decommits: Number(user.deCommits) || 0,
    });

    return (
      <div className="athleticTrainerModal">
        <a className="toggle" onClick={this.onToggle}>{linkText}</a>
        <Modal isOpen={this.state.modalOpen}
          toggle={this.onToggle}
          onOpened={this.onOpened}
          modalClassName="athletic-trainer-modal">
          <ModalHeader toggle={this.onToggle}>
            Profile
          </ModalHeader>
          <ModalBody>
            <div className="user-info">
              <img src={user.profilePic || require('styles/BlankAvatar.svg')} alt={user.name} />
              <div>
                <b>{user.name}</b>
                <div className="info-grid">
                  {(this.props.withoutContactInfo != true || isContractEo(this.props.session.currentUser.email)) &&
                    <div>
                      <span><i>Profession:</i> {professionName}</span>
                      <span><i>Email:</i> {user.email}</span>
                      {this.props.withoutPhoneNumber != true &&
                        <span><i>Phone:</i> {user.phone}</span>
                      }
                    </div>
                  }
                  <div>
                    <span><i>Go4 Shifts Worked:</i> {user.shiftsWorked}</span>
                    <span><i>{this.props.companyName} Shifts Worked:</i> {this.state.jobCount}</span>
                    <span>
                      <i>Reliability Score:</i>{' '}
                      {reliabilityScore === 'rookie'
                        ? 'Go4 Rookie. No jobs worked yet.'
                        : reliabilityScore + '%'}
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <div className='credentials'>
              <h3>Credentials</h3>
              <div className="download-all-link">
                <button className="plain-button" onClick={this.onDownloadAllCredentials}>
                  Download all
                </button>
              </div>
              <ul>
                {
                  user.npiNumber &&
                  <p><li>National Provider Identifier (NPI) Number: {user.npiNumber}</li></p>
                }
                {sortedCredentialKeys.map((key) => {
                  return (
                    <li key={key}>
                      <CredentialModal credential={credentials[key]} usStates={this.props.usStates} />
                    </li>
                  )
                })}
              </ul>
            </div>
          </ModalBody>
        </Modal>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    professions: state.enums.professions,
    usStates: state.enums.usStates,
    companyName: state.session.currentUser.companyName,
    session: state.session
  };
}

function mapDispatchToProps(dispatch) {
  return {
    downloadAllCredentials: (id) => dispatch(downloadAllCredentials(id)),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(AthleticTrainerModal);
