import { newEventLocation } from 'redux/ducks/helpers';

export default class GoogleSerializer {
  static eventLocationFromGooglePlace(place) {
    const formats = {
      street_number: 'short_name',
      route: 'long_name',
      locality: 'long_name',
      sublocality_level_1: 'long_name',
      administrative_area_level_2: 'long_name',
      administrative_area_level_3: 'long_name',
      neighborhood: 'long_name',
      administrative_area_level_1: 'short_name',
      postal_code: 'short_name',
    };
    const renameKeys = {
      sublocality_level_1: 'city',
      neighborhood: 'city',
      administrative_area_level_1: 'state',
      administrative_area_level_2: 'city',
      administrative_area_level_3: 'city',
      street_number: 'streetNumber',
      postal_code: 'zipCode',
      route: 'streetName',
      locality: 'city',
    };

    const priorityKeys = {
      locality: 5,
      neighborhood: 4,
      sublocality_level_1: 3,
      administrative_area_level_2: 2,
      administrative_area_level_3: 1,
      administrative_area_level_1: 1,
      street_number: 1,
      postal_code: 1,
      route: 1,
    };

    const address = {};
    const sortedComponents =
      place.address_components?.sort(
        (a, b) => priorityKeys[b.types[0]] - priorityKeys[a.types[0]]
      ) || [];

    sortedComponents.forEach((component) => {
      const type = component.types[0];
      const val = component[formats[type]];
      address[renameKeys[type]] = address[renameKeys[type]] || val;
    });

    address.streetAddress =
      address.streetNumber && address.streetName
        ? `${address.streetNumber} ${address.streetName}`
        : null;

    const {
      geometry: { location },
      formatted_address,
      formatted_phone_number,
      place_id,
      vicinity,
      name,
    } = place;
    const latitude = location.lat();
    const longitude = location.lng();
    const formattedAddress = formatted_address || vicinity;

    return {
      name,
      latitude,
      longitude,
      address,
      summary: `${name} - ${formattedAddress}`,
      formattedAddress,
      phoneNumber: formatted_phone_number,
      placeId: place_id,
      details: '',
    };
  }

  static eventLocationFromAutocompleteResult(result) {
    return {
      ...newEventLocation(),
      summary: result.description,
      placeId: result.place_id,
    };
  }
}
