import React, { Component } from 'react';
import { Route } from 'react-router-dom';
import { connect } from 'react-redux';
import { initForm, reloadForm } from 'redux/ducks/Post/actions';
import EventSetting from '../CreatePostScene/EventSetting';
import EventSettingDetail from '../CreatePostScene/EventSettingDetail';
import { LocationScreen } from '../Shared/EventLocation';
import ScheduleSummary from '../Shared/ScheduleSummary';
import EventRate from '../Shared/EventRate';
import { get } from 'lodash';
import ProfileScreen from '../Shared/EventProfile/ProfileScreen';
import LocationsIntroScreen from '../Shared/EventLocation/LocationsIntroScreen';
import LocationDetails from '../Shared/LocationDetails';
import LocationEAP from '../Shared/LocationEAP';
import EventSchedule from '../Shared/EventSchedule';
import PublishOrUpdateStep from '../CreatePostScene/PublishOrUpdateStep/PublishOrUpdateStep';
import Navigation from '../Shared/Navigation';
import Sidebar from 'react-sidebar';
import LocationSetup from '../Shared/EventLocation/LocationSetup';

type Props = {
  event: Object,
  enums: Object,
  initialize: Function,
};

class EditPostScene extends Component<Props> {
  state = { sideBarOpen: false };
  toggleSideBar = () => {
    this.setState({ sideBarOpen: !this.state.sideBarOpen }, () => {
      if (this.state.sideBarOpen) {
        document.body.style.overflow = 'hidden';
      } else {
        document.body.style.overflow = 'auto';
      }
    });
  };

  initialize = (name) => {
    const { alreadyEditing, event, details, match } = this.props;

    if (!event || alreadyEditing) return;

    if (details.id !== Number(match.params.eventId)) {
      const shifts = Object.values(get(event, 'shifts.byId', {}));
      this.props.initialize({ ...event, shifts });
    }
  };

  componentWillUnmount() {
    localStorage.removeItem('showLowRatePopUp');
  }

  renderRoute = (route, Component) => {
    const { match } = this.props;
    return (
      <Route
        path={`${match.url}/${route}`}
        render={(props) => (
          <Component
            {...props}
            editMode
            initialize={this.initialize}
            baseUrl={match.url}
            showAlertCircle={this.showAlertCircle}
          />
        )}
      />
    );
  };

  sidebarStyle = () => {
    const topNavBarHeight = 78;
    const footerHeight = 30;

    if (window.innerWidth < 768)
      return {
        background: '#eef3f5',
        position: 'fixed',
        height: `calc(100vh - ${topNavBarHeight}px)`,
        minWidth: '200px',
        width: '20vw',
        zIndex: '100',
        overflowY: 'auto',
        overflowX: 'hidden',
        top: '78px',
        paddingBottom: `${topNavBarHeight}px`,
      };

    return {
      background: '#eef3f5',
      position: 'fixed',
      height: `calc(100vh - ${topNavBarHeight}px)`,
      maxWidth: '200px',
      minWidth: '130px',
      width: '16vw',
      overflowY: 'auto',
      overflowX: 'hidden',
      top: '78px',
      paddingBottom: `${footerHeight}px`,
    };
  };

  hideSidebar = () => {
    const slugs = this.props.location.pathname.split('/');
    const slug = slugs.pop();
    const flow = slugs.pop();
    return Number.isInteger(Number(flow)) && ['type', 'setting', 'setting-detail'].includes(slug);
  };

  render() {
    return (
      <div className="v2EditPostScene">
        {!this.hideSidebar() && (
          <button className="sidebar-button">
            <i className="fa fa-bars" onClick={this.toggleSideBar} />
          </button>
        )}
        <Sidebar
          sidebar={
            <div className="navigation-wrapper">
              <Navigation
                editMode
                baseUrl={this.props.match.url}
                showAlertCircle={this.showAlertCircle}
                {...this.props}
              />
            </div>
          }
          open={this.state.sideBarOpen}
          docked={window.innerWidth > 768 && !this.hideSidebar()}
          onSetOpen={this.toggleSideBar}
          styles={{
            sidebar: this.sidebarStyle(),
            root: { overflow: 'initial', top: '78px' },
            content: { overflow: 'initial' },
            overlay: { zIndex: 99 },
          }}
          shadow={false}
        />
        <main className="content gotham-regular-font">
          {this.renderRoute('setting', EventSetting)}
          {this.renderRoute('setting-detail', EventSettingDetail)}
          {this.renderRoute('profile', ProfileScreen)}
          {this.renderRoute('location', LocationScreen)}
          {this.renderRoute('all-locations', LocationsIntroScreen)}
          {this.renderRoute('schedule', EventSchedule)}
          {this.renderRoute('schedule-summary', ScheduleSummary)}
          {this.renderRoute('rate', EventRate)}
          {this.renderRoute('details', LocationDetails)}
          {this.renderRoute('eap', LocationEAP)}
          {this.renderRoute('post', PublishOrUpdateStep)}
          {this.renderRoute('location-setup', LocationSetup)}
        </main>
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  let event = state.event.events.upcoming[ownProps.match.params.eventId];

  if (!event) {
    event = state.event.events.draft[ownProps.match.params.eventId];
  }

  const { details } = state.post;
  const alreadyEditing = details.id && details.id.toString() === ownProps.match.params.eventId;

  return {
    ...state.post,
    event,
    enums: state.enums,
    alreadyEditing,
  };
};

const mapDispatchToProps = (dispatch, ownProps) => ({
  initialize: (originalEvent) => dispatch(initForm(originalEvent)),
  reloadForm: () => dispatch(reloadForm()),
});

export default connect(mapStateToProps, mapDispatchToProps)(EditPostScene);
