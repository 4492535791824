/* global google */
import React, { Component } from 'react';
import { GoogleSerializer } from 'services/serializers';
import classNames from 'classnames';
import { AddButton } from 'components/V2/Buttons';

class ServiceProvider extends Component {
  state = {
    isEditing: false
  }

  toggleEdit = () => {
    this.setState({ isEditing: !this.state.isEditing })
  }

  onPlaceSelected = (place) => {
    this.setState({ isEditing: false })
    this.props.onChange(GoogleSerializer.eventLocationFromGooglePlace(place));
  }

  render() {
    const { address, name } = this.props.location;
    const { streetAddress, city, state, zipCode } = address;
    return (
      <div className="serviceProvider">
        <div className="emergency-location-type gotham-medium-font">
          {this.props.name}
        </div>
        <div className={classNames('nimbus-regular-font location-section', {
          'd-none': this.state.isEditing
        })}>
          <div className="location-name">{name}</div>
          <div className="location-name">{streetAddress}</div>
          <div className="location-name">{`${city}, ${state} ${zipCode}`}</div>
          {this.props.location.phoneNumber && <div className="location-name">
            Ph: {this.props.location.phoneNumber}
          </div>}
        </div>
        <AddButton onClick={this.toggleEdit} >
          {this.state.isEditing ? 'Cancel' : 'Edit'}
        </AddButton>
        <input ref='searchBox' className={classNames("form-control nimbus-regular-font italic-placeholder", { 'd-none': !this.state.isEditing })} />
      </div>
    );
  }

  componentDidMount() {
    const autocompleteOptions = {
      componentRestrictions: {
        country: 'us'
      },
      types: ['establishment']
    }
    const autocomplete = new google.maps.places.Autocomplete(this.refs.searchBox, autocompleteOptions);
    autocomplete.addListener('place_changed', () => this.onPlaceSelected(autocomplete.getPlace()))
  }
}

export default ServiceProvider;