import React from "react";
import HowItWorks from "../../../components/HowItWorks";
import SectionBanner from "../../../components/SectionBanner";

import howItWorks1 from "how-it-works-1.png";
import howItWorks2 from "how-it-works2.png";
import howItWorks3 from "how-it-works3.png";
import howItWorks4 from "how-it-works4.png";

const groups = [
  {
    title: "POST YOUR JOB",
    body: "Post your shift and hours you need an AT.",
    image: howItWorks1,
  },
  {
    title: "SET YOUR PAY RATE",
    body: "Go4 let's you set the pay rate that fits into your budget.",
    image: howItWorks2,
  },
  {
    title: "HIRE YOUR ATs",
    body: "Hire your ATs from the ATs that apply to work your shift.",
    image: howItWorks3,
  },
  {
    title: "WE HANDLE THE PAPERWORK",
    body: "We handle the 1099s for the ATs that work your shifts.",
    image: howItWorks4,
  },
];

const IndustrialHowItWorks = ({ altGroups, svg = false }) => (
  <HowItWorks
    groups={altGroups || groups}
    png={!svg}
    banner={<SectionBanner text="How it works" />}
  />
);

export default IndustrialHowItWorks;
