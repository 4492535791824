import React, { Component } from 'react';

import { connect } from 'react-redux';

import { Link } from 'react-router-dom';

import classnames from 'classnames';

import { Menu, Button } from 'antd';

import './MobileNavBar.scss';

const { SubMenu } = Menu;

export class MobileNavBar extends Component {
  render() {
    return (
      <div>
        <div className='mobileNavBarPlaceholder'></div>
        <div className='mobileNavBar'>
          <div className='material-icons' onClick={this.props.toggleCollapsed}>
            reorder
          </div>
          <div
            className='thumbnail'
            style={{
              backgroundImage: `url(${
                this.props.user.profilePic || require('styles/GenericAdd.svg')
              })`,
            }}
            alt='Avatar'
          />
          <div className='user-name'>{this.props.user.name}</div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  user: state.session.currentUser,
});

export default connect(mapStateToProps)(MobileNavBar);
