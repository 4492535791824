class ContactSerializer {
  static deserialize(response) {
    return response;
  }

  static serialize(data) {
    const result = {
      date: data.date,
      sport: data.sport,
      event_type: data.eventType,
      name: data.name,
      email: data.email,
      notes: data.notes,
      location: data.location,
      org: data.org,
      phone: data.phone,
      job_description: data.jobDescription,
      start_date: data.startDate,
      end_date: data.endDate,
      company_name: data.companyName,
      address_zip: data.addressZip,
      city: data.city,
      first_name: data.firstName,
      last_name: data.lastName,
      profession: data.profession,
      state: data.state,
    };

    return result;
  }
}

export default ContactSerializer;
