import React, { Component } from 'react';
import { Modal, ModalBody, ModalFooter } from 'reactstrap';
import './PaidJobModal.scss';

class PaidJobModal extends Component {
  state = { isOpen: false };

  toggle = () => this.setState({ isOpen: !this.state.isOpen });

  render() {
    const { customBodyText, hideContinueButton, onContinue, children, childTextColor } = this.props;

    return (
      <React.Fragment>
        {children && (
          <button
            className={`modal-button plain-button action-button ${childTextColor}`}
            onClick={this.toggle}
          >
            {children}
          </button>
        )}
        <Modal
          isOpen={this.state.isOpen}
          toggle={this.toggle}
          className="paid-job-modal"
          backdrop="static"
        >
          <ModalBody>
            <div className="modalTitle">
              <div className="screen-title">Error</div>
              <p className="modal-description">
                {customBodyText ||
                  'This job has one or more shifts that were worked. To cancel any remaining shifts, you must cancel each shift.'}
              </p>
            </div>
          </ModalBody>
          <ModalFooter className="actions">
            <button className="btn confirm-btn" onClick={this.toggle}>
              Go Back
            </button>
            {!hideContinueButton && (
              <button className="btn btn-success" onClick={onContinue}>
                Continue
              </button>
            )}
          </ModalFooter>
        </Modal>
      </React.Fragment>
    );
  }
}

export default PaidJobModal;
