import React from "react";
import { Route, Switch, useRouteMatch } from "react-router-dom";
import IndentRowWithIcon from "../../components/IndentRowWithIcon";
import AvailableJobsList from "./AvailableJobsList";
import StateJobLinkList from "./StateJobLinkList";
import Go4EllisText from "./Go4EllisText";
import HeaderText from "./HeaderText";
import DownloadIcons from "../../components/DownloadIcons";

const lists = [
  {
    header: "Benefits",
    list: [
      {
        bold: "Flexibilty",
        bullit: "You pick the shifts that fit into your schedule.",
      },
      { bold: "Pay Rate", bullit: "You pick the pay rate." },
      {
        bold: "Direct Deposit",
        bullit: "All payments are through direct deposit.",
      },
      {
        bold: "Experience",
        bullit: "Work with a variety of sports and patient panels.",
      },
    ],
  },
  {
    header: "Requirements",
    list: [
      { bold: "National Certification" },
      { bold: "State Certification or License" },
      { bold: "Standing Physician Orders" },
    ],
  },
];
const JobsStateScene = ({ location, careers }) => {
  const { isExact, url } = useRouteMatch();
  const state = isExact ? "" : location.pathname.split("/")[3];
  const city = isExact ? "" : location.pathname.split("/")[4];
  const locationText = city && state ? `${city}, ${state}` : "natiional";
  const headerText = isExact ? (
    <Go4EllisText className={"larger"} />
  ) : (
    locationText
  );
  return (
    <div className={`${careers ? "pt-0 " : ""}jobsStateScene`}>
      {!careers && <IndentRowWithIcon style={{ marginTop: "30px" }} />}
      <div className={`${careers ? "mt-3 " : ""}body`}>
        <HeaderText
          text={"PER DIEM ATHLETIC TRAINER"}
          teal={!isExact}
          withBorderTop
        >
          {headerText}
        </HeaderText>
        {!isExact && (
          <>
            <Go4EllisText />
            <p className="details">
              Location: <span>{locationText}</span>
            </p>
            <p className="details">Department: Athletic Training</p>
            <div className="detailsDivider" />
          </>
        )}
        <p className="description">
          The Go4 app makes it easy for Athletic Trainers to find per diem
          work and get paid. We are not a staffing company. There NO required
          hours and NO ditated rates. Work only the jobs you want, for the pay
          rates you choose. Go4 is the preferred provider for per diem work
          of the National Athletic Trainers' Association.
        </p>
        {!careers && <DownloadIcons className="jobState" />}
        <div className="lists">
          {lists.map((l) => (
            <div key={l.header} className="list">
              <h4>{l.header}</h4>
              <ul>
                {l.list.map((item) => (
                  <li key={item.bold}>
                    <strong>{item.bold}</strong>
                    {item.bullit ? (
                      <>
                        <strong>: </strong>
                        <span>{item.bullit}</span>
                      </>
                    ) : (
                      ""
                    )}
                  </li>
                ))}
              </ul>
            </div>
          ))}
        </div>
        {careers && <DownloadIcons className="jobState" />}
        {!careers && (
          <Switch>
            <Route path={`${url}/:state/:city`}>
              <AvailableJobsList />
            </Route>
            <Route exact path={url} component={StateJobLinkList} />
          </Switch>
        )}
      </div>
    </div>
  );
};
export default JobsStateScene;
