import { combineReducers } from 'redux';
import eap from './EAP';
import details from './Details';
import locations from './Locations';
import profile from './Profile';
import rate from './Rate';
import shifts from './Shifts';
import navigation from './Navigation';
import * as postActions from './actions';
import { getPageFromStep } from 'helpers/post';

// Action Creators
export const validateForm = (page: 'profile' | 'location' | 'shifts' | 'post') => ({
  type: postActions.V2_VALIDATE_FORM,
  payload: {
    page,
  },
});

// Reducer Functions
const validateFormReducer = (state, { page }) => {
  const step = getPageFromStep(page);
  let newState = { ...state };
  newState[step] = {
    ...newState[step],
    wasValidated: true,
  };
  return newState;
};

const reloadFormReducer = (state, payload) => {
  return payload?.validations ? { ...state, post: { ...payload.validations.post } } : state;
};

const DEFAULT_STATE = {
  locations: {
    wasValidated: false,
  },
  shifts: {
    wasValidated: false,
    valid: false,
  },
  rate: {
    wasValidated: false,
    valid: false,
  },
  post: {
    wasValidated: false,
  },
};

// Reducer
const validations = (state = DEFAULT_STATE, action = {}) => {
  switch (action.type) {
    case postActions.V2_VALIDATE_FORM:
      return validateFormReducer(state, action.payload);
    case postActions.V2_RELOAD_FORM_SUCCESS:
      return reloadFormReducer(state, action.payload);
    case postActions.V2_CLEAR_FORM:
    case postActions.INITIALIZE_POST:
      return DEFAULT_STATE;
    default:
      return state;
  }
};

export default combineReducers({
  eap,
  details,
  locations,
  profile,
  rate,
  shifts,
  validations,
  navigation,
});
