import React from "react";
import logo from "logo-4-white.svg";

const IndentRowWithIcon = ({ style }) => (
  <div className="indentRowWithIcon" style={style}>
    <div className="redTab">
      <img src={logo} alt="logo" />
    </div>
  </div>
);

export default IndentRowWithIcon;
