import classNames from 'classnames';
import React from 'react';

const CustomCheckBox = (props) => {
  const { name, id, checked, onClick, children, className, center } = props;

  return (
    <div
      className={classNames(`customCheckbox`, {
        'center-text': center,
      })}
    >
      <div className={classNames(`custom-control custom-checkbox ${className}`)}>
        <input
          type="checkbox"
          className="custom-control-input"
          name={name}
          id={id}
          onClick={onClick}
          checked={checked}
        />
        <label className="custom-control-label" htmlFor={id}>
          {children}
        </label>
      </div>
    </div>
  );
};

export default CustomCheckBox;
