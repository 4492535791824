import ApiService from './ApiService';

import { EmergencyActionPlanSerializer } from 'services/serializers';

class EmergencyActionPlanService extends ApiService {
  constructor(session, eventId) {
    super(session);
    this.eventId = eventId;
    this.url = `/events/${this.eventId}/emergency_action_plans`;
  }

  get(id) {
    return super.get(`${this.url}/${id}.json`,
      (response) => EmergencyActionPlanSerializer.deserialize(response));
  }

  getAll() {
    return super.get(`${this.url}.json`,
      (response) => response.map(item => EmergencyActionPlanSerializer.deserialize(item)));
  }

  create(emergencyActionPlan) {
    return super.post(`${this.url}.json`,
      EmergencyActionPlanSerializer.serialize(emergencyActionPlan),
      (response) => {
        return EmergencyActionPlanSerializer.deserialize(response)
      }
    )
  }

  update(emergencyActionPlan) {
    return super.patch(`${this.url}/${emergencyActionPlan.id}.json`, { emergency_action_plan: EmergencyActionPlanSerializer.serialize(emergencyActionPlan) },
      (response) => EmergencyActionPlanSerializer.deserialize(response));
  }

  delete(emergencyActionPlan) {
    return super.delete(`${this.url}/${emergencyActionPlan.id}.json`, () => true);
  }
}

export default EmergencyActionPlanService;