import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Menu, Button } from 'antd';
import { CloseOutlined } from '@ant-design/icons';
import './SideBarComponent.scss';

export class SideBarComponent extends Component {
  render() {
    const logoProps = {
      src: require('logo-4.svg'),
      style: {
        marginTop: '40px',
        height: '80px',
        width: '100%',
        marginLeft: 'auto',
        marginRight: 'auto',
      },
    };
    const { user } = this.props;
    return (
      <div className="sideBarComponent">
        <div className="d-flex flex-row-reverse p-2">
          <Button
            type="text"
            className="float-right"
            icon={<CloseOutlined />}
            size={15}
            onClick={() => this.props.click()}
          />
        </div>
        <Menu
          theme="dark"
          mode="inline"
          inlineCollapsed={false}
          defaultSelectedKeys={['dashboard']}
        >
          {user.role === 'Patient' && (
            <Menu.Item key="dashboard">
              <Link onClick={() => this.props.click()} key="dashboard" to="/patient/dashboard">
                Dashboard
              </Link>
            </Menu.Item>
          )}
          {user.role === 'Parent' && (
            <Menu.Item key="dashboard">
              <Link onClick={() => this.props.click()} key="dashboard" to="/parent/dashboard">
                Dashboard
              </Link>
            </Menu.Item>
          )}
          {user.role === 'Event operator' && (
            <Menu.Item key="dashboard">
              <Link onClick={() => this.props.click()} key="dashboard" to="/dashboard">
                Dashboard
              </Link>
            </Menu.Item>
          )}
          {user.role === 'Event operator' && (
            <Menu.ItemGroup key="events" title="Events">
              <Menu.Item key="create_new_event">
                <Link key="create_event" to="/create-event/1">
                  Create New Event
                </Link>
              </Menu.Item>
              <Menu.Item key="upcoming_shifts">
                <Link onClick={() => this.props.click()} key="upcoming_shifts" to="/event-details">
                  Upcoming Shifts
                </Link>
              </Menu.Item>
              <Menu.Item key="drafts">
                <Link onClick={() => this.props.click()} key="drafts" to="/event-drafts">
                  Drafts
                </Link>
              </Menu.Item>
            </Menu.ItemGroup>
          )}
          {user.role === 'Event operator' && (
            <Menu.Item key="finances">
              <Link onClick={() => this.props.click()} key="finances" to="/finances">
                Finances
              </Link>
            </Menu.Item>
          )}
          <Menu.Item key="help">
            <a
              href="https://support.go4.io/hc/en-us/categories/115000299368-Job-Posters/"
              target="_blank"
              rel="noopener noreferrer"
            >
              Help
            </a>
          </Menu.Item>
          {user.role === 'Event operator' && (
            <Menu.ItemGroup key="eo" title="Event Operator">
              <Menu.Item key="edit_profile">
                <Link onClick={() => this.props.click()} key="edit_profile" to="/edit-profile">
                  Edit Profile
                </Link>
              </Menu.Item>
              <Menu.Item key="edit_payment_information">
                <Link
                  onClick={() => this.props.click()}
                  key="edit_payment_information"
                  to="/edit-payment"
                >
                  Edit Payment Information
                </Link>
              </Menu.Item>
              <Menu.Item key="log_out">
                <Link onClick={() => this.props.click()} key="logout" to="/logout">
                  Log Out
                </Link>
              </Menu.Item>
            </Menu.ItemGroup>
          )}
          {user.role !== 'Event operator' && (
            <Menu.Item key="log_out">
              <Link onClick={() => this.props.click()} key="logout" to="/logout">
                Log Out
              </Link>
            </Menu.Item>
          )}
        </Menu>

        <img {...logoProps} className="logo" alt="Go4" />
      </div>
    );
  }
}

const mapStateToProps = (state) => ({ user: state.session.currentUser });

export default connect(mapStateToProps)(SideBarComponent);
