import React, { useRef } from "react";

const UploadResume = ({ file = {}, hasError, handleUpload }) => {
  const inputRef = useRef(null);
  const handleClick = () => inputRef.current.click();
  return (
    <div className={`uploadResume ${hasError ? "error" : ""}`}>
      <input ref={inputRef} type="file" onChange={handleUpload} />
      <button type="button" onClick={handleClick}>
        Choose File
      </button>
      <span className={file.name ? "withFile" : ""}>
        {file.name || "No file chosen"}
      </span>
    </div>
  );
};

export default UploadResume;
