import React from 'react';
import classnames from 'classnames';
import ResponsibilitiesPartialForm from './ResponsibilitiesPartialForm';
import FacilitiesPartialForm from './FacilitiesPartialForm';

const ContractPartialForm = ({ shift, ...props }) => {
  const limitRows = (text, maxRows) => {
    if (!text) return '';

    const lines = text.split('\n');
    if (lines.length > maxRows) {
      return lines.slice(0, maxRows).join('\n');
    }
    return text;
  };

  const isTrueValue = value => {
    return String(value).toLowerCase() == "true"
  }

  return (
    <React.Fragment>
      <div className="row">
        <div className="form-group col-md-12">
          <label htmlFor="contractInfo.report_to">Report To</label>
          <input
            type="text"
            name="contractInfo.report_to"
            value={shift.contractInfo?.report_to}
            onChange={props.onFieldChange('contractInfo.report_to')}
            onBlur={props.onFieldBlur.bind(this, 'contractInfo.report_to')}
            className={classnames('form-control', {
              'is-invalid': props.showError('contractInfo.report_to'),
            })}
            placeholder=""
          />
          <div className="invalid-feedback">
            {shift.errorMessages['contractInfo.report_to']}
          </div>
        </div>
      </div>
      <div className="row">
        <div className="form-group col onsite-contacts-check-col col-md-3">
          <div className="custom-control custom-checkbox">
            <input
              checked={isTrueValue(shift.contractInfo?.signing_bonus)}
              onClick={() =>
                props.onFieldChange('contractInfo.signing_bonus')(
                  !isTrueValue(shift.contractInfo?.signing_bonus)
                )
              }
              type="checkbox"
              className="custom-control-input"
              id={'contractInfo.signing_bonus'}
            />
            <label
              className="custom-control-label"
              htmlFor={'contractInfo.signing_bonus'}
            >
              SIGNING BONUS!
            </label>
          </div>
        </div>
        <div className="form-group col onsite-contacts-check-col col-md-3">
          <div className="custom-control custom-checkbox">
            <input
              checked={isTrueValue(shift.contractInfo?.overtime)}
              onClick={() =>
                props.onFieldChange('contractInfo.overtime')(
                  !isTrueValue(shift.contractInfo?.overtime)
                )
              }
              type="checkbox"
              className="custom-control-input"
              id={'contractInfo.overtime'}
            />
            <label
              className="custom-control-label"
              htmlFor={'contractInfo.overtime'}
            >
              OVERTIME!
            </label>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="form-group col-md-12">
          <label htmlFor="schedule">Schedule</label>
          <textarea
            className="form-control"
            style={{
              width: '100%',
              minHeight: '90px',
              border: '1px solid #b9b6ba',
            }}
            type="text"
            name="schedule"
            value={shift.schedule}
            rows={3}
            placeholder={
              'Mon - Fri | 30-40hrs | Afternoons + Evenings\nXX/XX - XX/XX | XX Weeks\nSome Sat / Dependent on Sports Schedule'
            }
            onChange={(event) => {
              const limitedValue = limitRows(event.target.value, 3);
              props.onFieldChange('schedule')(limitedValue);
            }}
          />
        </div>
        <div className="invalid-feedback">
          {shift.errorMessages['schedule']}
        </div>
      </div>
      <div className="row">
        <div className="form-group col-md-12">
          <label htmlFor="contractInfo.perks">Travel Job List Perks</label>
          <textarea
            className="form-control"
            style={{
              width: '100%',
              minHeight: '120px',
              border: '1px solid #b9b6ba',
            }}
            type="text"
            name="contractInfo.perks"
            value={shift.contractInfo?.perks}
            rows={4}
            placeholder={
              '> Student loan repayment assistance\n> Relocation stipend\n> License renewal reimbursement\n+ MORE'
            }
            onChange={(event) => {
              const limitedValue = limitRows(event.target.value, 4);
              props.onFieldChange('contractInfo.perks')(limitedValue);
            }}
          />
          <div className="invalid-feedback">
            {shift.errorMessages['contractInfo.perks']}
          </div>
        </div>
      </div>
      <div className="row">
        <div className="form-group col-md-12">
          <label htmlFor="contractInfo.perks_details">Perks Job Details</label>
          <textarea
            className="form-control"
            style={{
              width: '100%',
              minHeight: '120px',
              border: '1px solid #b9b6ba',
            }}
            type="text"
            name="contractInfo.perks_details"
            value={shift.contractInfo?.perks_details}
            rows={4}
            placeholder={
              'Enter perks available to AT if accepted to work a travel job.'
            }
            onChange={props.onFieldChange('contractInfo.perks_details')}
          />
          <div className="invalid-feedback">
            {shift.errorMessages['contractInfo.perks_details']}
          </div>
        </div>
      </div>
      <ResponsibilitiesPartialForm {...props} shift={shift} />
      <FacilitiesPartialForm {...props} shift={shift} />
      <div className="row">
        <div className="form-group col-md-12">
          <label htmlFor="contractInfo.requirements">Requirements</label>
          <textarea
            className="form-control"
            style={{
              width: '100%',
              minHeight: '120px',
              border: '1px solid #b9b6ba',
            }}
            type="text"
            name="contractInfo.requirements"
            value={shift.contractInfo?.requirements}
            rows={4}
            placeholder={
              '> 3 years experience in a secondary school or similar setting\n> Active BOC certification\n> License to practice athletic training in [STATE]'
            }
            onChange={props.onFieldChange('contractInfo.requirements')}
          />
        </div>
        <div className="invalid-feedback">
          {shift.errorMessages['contractInfo.requirements']}
        </div>
      </div>
      <div className="row">
        <div className="form-group col-md-6">
          <label htmlFor="contractInfo.student_athlete_population">
            Student Athlete Population
          </label>
          <input
            className="form-control"
            style={{ width: '100%', border: '1px solid #b9b6ba' }}
            type="number"
            name="contractInfo.student_athlete_population"
            value={shift.contractInfo?.student_athlete_population}
            rows={4}
            placeholder={"Student Athlete Population"}
            onChange={props.onFieldChange('contractInfo.student_athlete_population')}
          />
        </div>
      </div>
      <div className="row">
        <div className="form-group col-md-12">
          <label htmlFor="contractInfo.school_sports">School Shift List</label>
          <textarea
            className="form-control"
            style={{
              width: '100%',
              minHeight: '120px',
              border: '1px solid #b9b6ba',
            }}
            type="text"
            name="contractInfo.school_sports"
            value={shift.contractInfo.school_sports}
            rows={4}
            placeholder={'School Student Sports list'}
            onChange={props.onFieldChange('contractInfo.school_sports')}
          />
        </div>
        <div className="invalid-feedback">
          {shift.errorMessages['contractInfo.school_sports']}
        </div>
      </div>
      <div className="row">
        <div className="form-group col-md-12">
          <label htmlFor="contractInfo.location_about">About Location</label>
          <textarea
            className="form-control"
            style={{
              width: '100%',
              minHeight: '120px',
              border: '1px solid #b9b6ba',
            }}
            type="text"
            name="contractInfo.location_about"
            value={shift.contractInfo?.location_about}
            rows={4}
            placeholder={`Some information about ${shift.eventLocation.address.city || 'City'
              }, ${shift.eventLocation.address.state || 'State'}`}
            onChange={props.onFieldChange('contractInfo.location_about')}
          />
        </div>
        <div className="invalid-feedback">
          {shift.errorMessages['contractInfo.locationAbout']}
        </div>
      </div>
    </React.Fragment>
  );
};

export default ContractPartialForm;
