import React, { Component } from 'react';
import { connect } from 'react-redux';
import LoginForm from './LoginForm';
import { UncontrolledAlert } from 'reactstrap';
import { createSession } from 'redux/ducks/Session';

export class LoginScene extends Component {

  onSubmit = ({ email, password, rememberMe, role }) => {
    this.props.login({
      email: email,
      password: password,
      rememberMe: rememberMe,
      role: role,
      history: this.props.history
    });
  }

  render() {
    return (
      <div className="loginScene">
        <img src={require('Go4_Logo_Login.png')} alt="Go4" />
        <h2>Healthcare Providers on Demand</h2>
        {this.props.error &&
          <UncontrolledAlert color="danger">{this.props.error.message || 'Invalid email or password'}</UncontrolledAlert>
        }
        <LoginForm onSubmit={this.onSubmit} />
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  login: (credentials) => dispatch(createSession(credentials)),
})

const mapStateToProps = (state, ownProps) => {
  const error = state.session.error;
  const session = state.session;

  if (!error && session && state.session.authenticated) {
    if (session.currentUser.role === "Parent") {
      ownProps.history.push('/parent/dashboard');
    } else if (session.currentUser.role === "Patient") {
      ownProps.history.push('/patient/dashboard');
    } else {
      ownProps.history.push('/dashboard');
    }
  }

  return {
    error,
    session,
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(LoginScene);