import React from "react";
import nataImg from "NATA-Logo.svg";

const CommonTopSection = ({
  text,
  form,
  customTab,
  darkerTextBackground,
  noTextBackground,
}) => (
  <div className="commonTopSection">
    <div className="left-top top-sub-section">
      {customTab}
      <div
        className={`text-container ${darkerTextBackground ? "darker" : ""} ${
          noTextBackground ? "transparent" : ""
        }`}
      >
        {text}
      </div>
    </div>
    <div className="right-bottom top-sub-section">{form}</div>
  </div>
);
export default CommonTopSection;
