import React, { Component } from 'react';
import classNames from 'classnames';
import { withRouter } from 'react-router-dom/cjs/react-router-dom';
import { deleteEvent } from 'redux/ducks/Event';
import { connect } from 'react-redux';
import ConfirmationModal from 'components/V2/ConfirmationModal';
import { clearV2Form, duplicatePost, initForm } from 'redux/ducks/Post/actions';
import { get } from 'lodash';
import { completeness } from 'helpers/post';
import { EMPTY_DATE, SHOW_TOOLTIP_COLUMNS, createOverflowChecker } from 'helpers/dashboard';
import { UncontrolledTooltip } from 'reactstrap';

class DraftListItem extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isOverflowing: {},
      isDeleteModalOpen: false,
    };
    this.columnRefs = {};
    SHOW_TOOLTIP_COLUMNS.drafts.forEach((column) => {
      this.columnRefs[column] = React.createRef();
    });
  }

  componentDidMount() {
    const checkOverflow = createOverflowChecker(
      'drafts',
      this.columnRefs,
      this.setState.bind(this)
    );

    this.resizeObserver = new ResizeObserver(checkOverflow);
    SHOW_TOOLTIP_COLUMNS.drafts.forEach((column) => {
      this.resizeObserver.observe(this.columnRefs[column].current);
    });
  }

  componentWillUnmount() {
    this.resizeObserver.disconnect();
  }

  toggleDeleteModal = () => this.setState({ isDeleteModalOpen: !this.state.isDeleteModalOpen });

  onEditEvent = (event) => {
    this.props.initialize({
      ...event,
      shifts: Object.values(get(event, 'shifts.byId', {})),
    });
    this.props.history.push({ pathname: `/v2/create-event/profile`, state: { draft: true } });
  };

  onPostEvent = (event) => {
    if (completeness(event)) {
      this.props.initialize({
        ...event,
        shifts: Object.values(get(event, 'shifts.byId', {})),
      });
      this.props.history.push({ pathname: `/v2/create-event/post`, state: { draft: true } });
    }
  };

  onDuplicate = () => {
    const { item } = this.props;
    this.props.clearForm();
    this.props.duplicatePost(item);
    this.props.history.push(`/v2/create-event/profile`);
  };

  render() {
    const { item, columns } = this.props;

    return (
      <div className="draft-list-item">
        <div
          className={`${columns['startDate'].size}`}
          id={`startDate-${item.id}`}
          ref={this.columnRefs['startDate']}
        >
          {item.formattedFirstStartTime || EMPTY_DATE}
          {this.state.isOverflowing.startDate && (
            <UncontrolledTooltip target={`startDate-${item.id}`}>
              {item.formattedFirstStartTime || EMPTY_DATE}
            </UncontrolledTooltip>
          )}
        </div>
        <div
          className={`${columns['jobName'].size} job-name`}
          onClick={() => this.onEditEvent(item)}
          id={`jobName-${item.id}`}
          ref={this.columnRefs['jobName']}
        >
          {item.title}
          {this.state.isOverflowing.jobName && (
            <UncontrolledTooltip target={`jobName-${item.id}`}>{item.title}</UncontrolledTooltip>
          )}
        </div>
        {completeness(item) ? (
          <div className={`${columns['status'].size} complete`}>Complete / Ready to post</div>
        ) : (
          <div className={`${columns['status'].size} incomplete`}>Incomplete</div>
        )}

        <div className={classNames(`${columns['actions'].size} event-actions`)}>
          <button className={classNames('plain-button')} onClick={() => this.onEditEvent(item)}>
            EDIT
          </button>
          <button className={classNames('plain-button')} onClick={this.onDuplicate}>
            DUPLICATE
          </button>
          <button className={classNames('plain-button')} onClick={this.toggleDeleteModal}>
            DELETE
          </button>
          <button
            className={classNames('plain-button post-button', {
              visible: completeness(item),
            })}
            onClick={() => this.onPostEvent(item)}
          >
            POST
          </button>
        </div>
        <ConfirmationModal
          isOpen={this.state.isDeleteModalOpen}
          title="Delete Draft"
          body="This cannot be undone. Are you sure?"
          cancelText="Go Back"
          confirmText="Delete"
          toggle={this.toggleDeleteModal}
          onSave={() => this.props.onDelete(item)}
        />
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  initialize: (originalEvent) => dispatch(initForm(originalEvent)),
  onDelete: (event) => dispatch(deleteEvent(event)),
  duplicatePost: (event) => dispatch(duplicatePost(event)),
  clearForm: () => dispatch(clearV2Form()),
});

export default withRouter(connect(null, mapDispatchToProps)(DraftListItem));
