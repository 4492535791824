// Actions
// ========================================================
export const GET_AGGREGATE_INFO = 'go4ellis/AggregateInfo/GET_AGGREGATE_INFO';
export const GET_AGGREGATE_INFO_SUCCESS = 'go4ellis/AggregateInfo/GET_AGGREGATE_INFO_SUCCESS';
export const GET_AGGREGATE_INFO_FAIL = 'go4ellis/AggregateInfo/GET_AGGREGATE_INFO_FAIL';

// Reducer Functions
// ========================================================
function aggregateInfoReducer(state, payload) {
  return {
    ...state,
    ...payload
  };
}

function aggregateErrorReducer(state, payload) {
  return {
    ...state,
    aggAts: 0,
    aggEos: 0,
    aggHoursStaffed: 0
  };
}

// Action Creators
// ========================================================
export function getAggregateInfo() {
  return { type: GET_AGGREGATE_INFO };
}

// Reducer
// ========================================================
const DEFAULT_STATE = { };

export default function reducer(state = DEFAULT_STATE, action = {}) {
  switch (action.type) {
    case GET_AGGREGATE_INFO_SUCCESS:
      return aggregateInfoReducer(state, action.payload);
    case GET_AGGREGATE_INFO_FAIL:
      return aggregateErrorReducer(state);
    default: return state;
  }
}