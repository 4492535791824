import React, { useState } from 'react';
import { Modal, ModalHeader, ModalBody, ModalFooter, FormGroup, Label, Input } from 'reactstrap';
import './LearnMoreModal.scss';
import { connect } from 'react-redux';
import { trackProspectUser } from 'redux/ducks/User';
import { trackMixpanelPageView } from 'helpers/post';

const LearnMoreModal = ({ isOpen, toggle, ...props }) => {
  const [formData, setFormData] = useState({
    organizationName: '',
    firstName: '',
    lastName: '',
    zipCode: '',
    email: '',
  });

  const [errors, setErrors] = useState({});

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const validate = () => {
    let newErrors = {};
    if (!formData.organizationName) newErrors.organizationName = 'Organization Name is required';
    if (!formData.firstName) newErrors.firstName = 'First Name is required';
    if (!formData.lastName) newErrors.lastName = 'Last Name is required';
    if (!formData.zipCode) newErrors.zipCode = 'Zip Code is required';
    if (!formData.email) newErrors.email = 'Email is required';
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = () => {
    if (validate()) {
      props.trackProspectUser(formData);
      trackMixpanelPageView('IntroFormSubmitClick', { id: null, email: formData.email });
      toggle();
    }
  };

  return (
    <Modal className="learn-more-modal" isOpen={isOpen} toggle={toggle}>
      <ModalHeader toggle={toggle}>Learn More about Go4</ModalHeader>
      <div className="horizontal-rule" />
      <ModalBody className="modal-body">
        <p>Tell us about your organization, and our team will reach out.</p>
        <FormGroup>
          <Label for="organizationName">Organization Name</Label>
          <Input
            type="text"
            name="organizationName"
            id="organizationName"
            value={formData.organizationName}
            onChange={handleChange}
            invalid={!!errors.organizationName}
          />
          {errors.organizationName && <div className="text-danger">{errors.organizationName}</div>}
        </FormGroup>
        <FormGroup>
          <Label for="firstName">First Name</Label>
          <Input
            type="text"
            name="firstName"
            id="firstName"
            value={formData.firstName}
            onChange={handleChange}
            invalid={!!errors.firstName}
          />
          {errors.firstName && <div className="text-danger">{errors.firstName}</div>}
        </FormGroup>
        <FormGroup>
          <Label for="lastName">Last Name</Label>
          <Input
            type="text"
            name="lastName"
            id="lastName"
            value={formData.lastName}
            onChange={handleChange}
            invalid={!!errors.lastName}
          />
          {errors.lastName && <div className="text-danger">{errors.lastName}</div>}
        </FormGroup>
        <FormGroup>
          <Label for="zipCode">Zip Code</Label>
          <Input
            type="text"
            name="zipCode"
            id="zipCode"
            value={formData.zipCode}
            onChange={handleChange}
            invalid={!!errors.zipCode}
          />
          {errors.zipCode && <div className="text-danger">{errors.zipCode}</div>}
        </FormGroup>
        <FormGroup>
          <Label for="email">Email</Label>
          <Input
            type="email"
            name="email"
            id="email"
            value={formData.email}
            onChange={handleChange}
            invalid={!!errors.email}
          />
          {errors.email && <div className="text-danger">{errors.email}</div>}
        </FormGroup>
      </ModalBody>
      <div className="horizontal-rule my-0" />
      <ModalFooter>
        <button className="btn btn-primary submit-learn" onClick={handleSubmit}>
          Submit
        </button>
      </ModalFooter>
    </Modal>
  );
};

const mapDispatchToProps = (dispatch) => ({
  trackProspectUser: (contact) =>
    dispatch(trackProspectUser({ contact, event_name: 'prospect_jp' })),
});

export default connect(null, mapDispatchToProps)(LearnMoreModal);
