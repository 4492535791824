import React, { Component } from 'react';

class VerificationSuccessScene extends Component {

  componentWillMount() {
    document.body.classList.add('verification-success');
  }

  componentWillUnmount() {
    document.body.classList.remove('verification-success');
  }

  render() {
    return (
      <div className="verificationSuccess">
        <h1>Verification Success</h1>
        <a href={`${process.env.REACT_APP_DEEPLINK_URL}/jobs`} className="btn btn-primary px-4">Go ahead, find jobs</a>
      </div>
    );
  }
}

export default VerificationSuccessScene;