import ApiService from "./ApiService";

class CredentialsService extends ApiService {
  constructor(session) {
    super(session);

    this.url = "/users";
  }

  downloadAll(userId) {
    return super.download(`${this.url}/${userId}/credentials.pdf`, response => {
      return {
        pdf: response,
      }
    });
  }
}

export default CredentialsService;
