// Actions
// ========================================================
export const GET_JOBS_AVAILABLE = "go4ellis/JobsAvailable/GET_JOBS_AVAILABLE";
export const GET_JOBS_AVAILABLE_SUCCESS =
  "go4ellis/JobsAvailable/GET_JOBS_AVAILABLE_SUCCESS";
export const GET_JOBS_AVAILABLE_FAILURE =
  "go4ellis/JobsAvailable/GET_JOBS_AVAILABLE_FAILURE";

// Reducer Functions
// ========================================================
function fetchReducer(state) {
  return {
    ...state,
    availableJobs: [],
  };
}
function successReducer(state, action) {
  return {
    ...state,
    availableJobs: action.payload,
  };
}
function failureReducer(state, action) {
  return {
    ...state,
    availableJobs: [],
    error: action.payload,
  };
}

// Action Creators
// ========================================================
export function getJobsAvailable(payload) {
  return { type: GET_JOBS_AVAILABLE, payload };
}
export function jobsAvailableSuccess(payload) {
  return { type: GET_JOBS_AVAILABLE_SUCCESS, payload };
}

export function jobsAvailableFailure(payload) {
  return { type: GET_JOBS_AVAILABLE_FAILURE, payload };
}

// Reducer
// ========================================================
const DEFAULT_STATE = { availableJobs: [] };

export default function reducer(state = DEFAULT_STATE, action = {}) {
  switch (action.type) {
    case GET_JOBS_AVAILABLE:
      return fetchReducer(state);
    case GET_JOBS_AVAILABLE_SUCCESS:
      return successReducer(state, action);
    case GET_JOBS_AVAILABLE_FAILURE:
      return failureReducer(state, action);
    default:
      return state;
  }
}
