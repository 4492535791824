import ApiService from "./ApiService";

import { BlogSerializer } from "services/serializers";

class BlogService extends ApiService {
  constructor(session) {
    super(session);

    this.url = "/blog";
  }

  get(nextPageToken) {
    const query = nextPageToken ? `?next_page_token=${nextPageToken}` : "";
    return super.get(`${this.url}.json${query}`, (response) =>
      BlogSerializer.deserialize(response)
    );
  }
}

export default BlogService;
