import { ofType } from "redux-observable";
import { mergeMap, filter, mapTo } from "rxjs/operators";
import { SHOW_SPINNER, HIDE_SPINNER } from "redux/ducks/LoadingSpinner";
import * as actions from "./index";
import { BlogService } from "services";
import { mappingEpic } from "redux/ducks/helpers";

// Epics
// ========================================================
export function getAllBlog(action$, store) {
  return action$.pipe(
    ofType(actions.FETCH),
    mergeMap(() => {
      const { blog } = store.getState();
      return new BlogService()
        .get(blog.nextPageToken)
        .then((result) => actions.blogsSuccess(result))
        .catch((error) => actions.blogsFail(error?.response?.data?.error));
    })
  );
}

export const showSpinnerEpic = mappingEpic([actions.FETCH], SHOW_SPINNER);

export const hideSpinnerEpic = mappingEpic(
  [actions.SUCCESS, actions.FAIL],
  HIDE_SPINNER
);
