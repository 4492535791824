import React from 'react';
import { Button } from 'reactstrap';

const DeleteButton = ({ className = '', children, show = true, ...props }) => {
  if (!show) return null;

  return (
    <Button onClick={props.onClick} color="link" className={`delete-button ${className}`}>
      {children}
    </Button>
  );
};

export default DeleteButton;
