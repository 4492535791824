import ApiService from './ApiService';

import { EnumSerializer } from 'services/serializers';

class EnumService extends ApiService {
  constructor(session) {
    super(session);

    this.url = '/enums';
  }

  getAll() {
    return super.get(`${this.url}.json`,
      (response) => EnumSerializer.deserialize(response.enums));
  }
}

export default EnumService;