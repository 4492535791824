import React from "react";
import arrow from "images/arrow-angle-right-red.svg";

const RapidPlacement = ({ body }) => (
  <section className="nurses-rapid-placement px-5 pt-5 pb-4">
    <h2 className="pb-2 mb-3">
      <img alt="arrow" className="mr-2" src={arrow} />
      Rapid Placement
    </h2>
    <p>
      {
        body ? body :
          "The on-demand Go4 platform allows you to staff open positions quickly and efficiently."
      }
    </p>
  </section>
);

export default RapidPlacement;
