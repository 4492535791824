const constForName = (name) => `go4ellis/Dashboard/${name}`;

export const GET_SHIFTS_WITH_NO_APPLICANTS = constForName('GET_SHIFTS_WITH_NO_APPLICANTS');
export const GET_SHIFTS_WITH_NO_APPLICANTS_SUCCESS = constForName(
  'GET_SHIFTS_WITH_NO_APPLICANTS_SUCCESS'
);
export const GET_SHIFTS_WITH_NO_APPLICANTS_ERROR = constForName(
  'GET_SHIFTS_WITH_NO_APPLICANTS_ERROR'
);
export const CHANGE_SHIFTS_WITH_NO_APPLICANTS_PAGE = constForName(
  'CHANGE_SHIFTS_WITH_NO_APPLICANTS_PAGE'
);
export const CHANGE_SORT_SHIFTS_WITH_NO_APPLICANTS = constForName(
  'CHANGE_SORT_SHIFTS_WITH_NO_APPLICANTS'
);
export const CHANGE_SEARCH_SHIFTS_WITH_NO_APPLICANTS = constForName(
  'CHANGE_SEARCH_SHIFTS_WITH_NO_APPLICANTS'
);

export const BOOST_RATE = constForName('BOOST_RATE');
export const BOOST_RATE_SUCCESS = constForName('BOOST_RATE_SUCCESS');
export const BOOST_RATE_ERROR = constForName('BOOST_RATE_ERROR');

export const GET_APPLICANTS_EVENTS = constForName('GET_APPLICANTS_EVENTS');
export const GET_APPLICANTS_EVENTS_SUCCESS = constForName('GET_APPLICANTS_EVENTS_SUCCESS');
export const GET_APPLICANTS_EVENTS_ERROR = constForName('GET_APPLICANTS_EVENTS_ERROR');
export const CHANGE_SEARCH_APPLICANT = constForName('CHANGE_SEARCH_APPLICANT');
export const CHANGE_SORT_APPLICANT = constForName('CHANGE_SORT_APPLICANT');
export const CHANGE_APPLICANTS_EVENTS_PAGE = constForName('CHANGE_APPLICANTS_EVENTS_PAGE');

export const GET_RESOURCES = constForName('GET_RESOURCES');
export const GET_RESOURCES_SUCCESS = constForName('GET_RESOURCES_SUCCESS');
export const GET_RESOURCES_ERROR = constForName('GET_RESOURCES_ERROR');

export const GET_REVIEWS = constForName('GET_REVIEWS');
export const GET_REVIEWS_SUCCESS = constForName('GET_REVIEWS_SUCCESS');
export const GET_REVIEWS_ERROR = constForName('GET_REVIEWS_ERROR');
export const CHANGE_REVIEWS_PAGE = constForName('CHANGE_REVIEWS_PAGE');
export const CHANGE_SORT_REVIEWS = constForName('CHANGE_SORT_REVIEWS');
export const CHANGE_SEARCH_REVIEWS = constForName('CHANGE_SEARCH_REVIEWS');

export const UPDATE_REVIEW = constForName('UPDATE_REVIEW');
export const UPDATE_REVIEW_SUCCESS = constForName('UPDATE_REVIEW_SUCCESS');
export const UPDATE_REVIEW_ERROR = constForName('UPDATE_REVIEW_ERROR');

export const GET_SHIFT_MODIFICATIONS = constForName('GET_SHIFT_MODIFICATIONS');
export const GET_SHIFT_MODIFICATIONS_SUCCESS = constForName('GET_SHIFT_MODIFICATIONS_SUCCESS');
export const GET_SHIFT_MODIFICATIONS_ERROR = constForName('GET_SHIFT_MODIFICATIONS_ERROR');
export const CHANGE_SEARCH_SHIFT_MODIFICATIONS = constForName('CHANGE_SEARCH_SHIFT_MODIFICATIONS');
export const CHANGE_SORT_SHIFT_MODIFICATIONS = constForName('CHANGE_SORT_SHIFT_MODIFICATIONS');
export const CHANGE_SHIFT_MODIFICATIONS_PAGE = constForName('CHANGE_SHIFT_MODIFICATIONS_PAGE');

export const GET_JOBS_METADATA = constForName('GET_JOBS_METADATA');
export const GET_JOBS_METADATA_SUCCESS = constForName('GET_JOBS_METADATA_SUCCESS');
export const GET_JOBS_METADATA_ERROR = constForName('GET_JOBS_METADATA_ERROR');

export const RESET_PAGINATION = constForName('RESET_PAGINATION');
