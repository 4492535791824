import React from 'react';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';

export default ({isOpen, toggle, onRemove}) => (
  <Modal 
    isOpen={isOpen} 
    toggle={toggle}
  >
    <ModalHeader toggle={toggle}>Are you sure?</ModalHeader>
    <ModalBody>If you remove this staff, the shift will be re-posted on the job feed. If there is a waitlist for this shift, please ACCEPT a new applicant.</ModalBody>
    <ModalFooter><div />
      <button 
        className="btn btn-primary btn-danger heading-font" 
        onClick={onRemove}
      >
        Yes, remove
      </button>
    </ModalFooter>
  </Modal>
);