export default class PaymentSerializer {
  static deserialize(payment) {
    const result = {
      amountDisbursed: payment.amount_disbursed,
      amountWithdrawn: payment.amount_withdrawn,
      atAmountPaid: payment.at_amount_paid,
      atServiceCharge: payment.at_service_charge,
      billableID: payment.billable_id,
      chargeID: payment.charge_id,
      description: payment.description,
      eoServiceCharge: payment.eo_service_charge,
      eoTotalCharge: payment.eo_total_charge,
      paidAt: payment.paid_at,
      transactionID: payment.transaction_id,
      userID: payment.user_id,
    };

    return result;
  }
}