import { ofType } from "redux-observable";
import { mergeMap } from "rxjs/operators";
import { SHOW_SPINNER, HIDE_SPINNER } from "redux/ducks/LoadingSpinner";
import * as actions from "./index";
import { JobsAvailableService } from "services";
import { mappingEpic } from "redux/ducks/helpers";

// Epics
// ========================================================
export function getAllJobsAvailable(action$, store) {
  return action$.pipe(
    ofType(actions.GET_JOBS_AVAILABLE),
    mergeMap(action => {
      return new JobsAvailableService()
        .getAll(action.payload)
        .then(result => actions.jobsAvailableSuccess(result))
        .catch(error =>
          actions.jobsAvailableFailure(error.response.data.error)
        );
    })
  );
}

export const showSpinnerEpic = mappingEpic(
  [actions.GET_JOBS_AVAILABLE],
  SHOW_SPINNER
);

export const hideSpinnerEpic = mappingEpic(
  [actions.GET_JOBS_AVAILABLE_SUCCESS, actions.GET_JOBS_AVAILABLE_FAILURE],
  HIDE_SPINNER
);
