import React from "react";
import { Link } from "react-router-dom";

const ATActionButton = () => (
  <div className="aTActionButton">
    <button>
      <Link to="/athletic-trainer">
        <span className="skill-link">
          Are you an Athletic Trainer looking for per diem work? Click here.
        </span>
      </Link>
    </button>
  </div>
);

export default ATActionButton;
