import React, { Component } from 'react';
import { connect } from 'react-redux';
import { getTimezone } from 'helpers/post';
import moment from 'moment-timezone';
import { get } from 'lodash';
import Job from './Job';

class JobsForShift extends Component {
  render() {
    const { jobs, shift, pendingApplicants, from, isOpen, rateTypes, jobsMetadata } = this.props;

    const order = [
      'pending',
      'eo_accepted_at',
      'confirmed',
      'checked_in',
      'paid',
      'waitlist',
      'callout',
    ];

    if (!jobs.length && isOpen && from === 'upcoming') {
      this.props.handleExpand();
      return null;
    }

    const orderedJobs = jobs.sort((a, b) => {
      const stateOrder = order.indexOf(a.currentState) - order.indexOf(b.currentState);
      if (stateOrder !== 0) {
        return stateOrder;
      }
      return a.createdAt - b.createdAt;
    });

    return (
      <div className="shift-jobs">
        <div className="row shift-jobs-headers">
          <div className="col-3">AT NAME</div>
          <div className="col-2">STATUS</div>
          <div className="col-5">ACTION</div>
          <div className="col-2">ADD’L INFO</div>
        </div>

        {orderedJobs.map((job) => {
          const allApplied = get(jobsMetadata, `[${job.id}].appliedToAllShiftsLocation`, false);

          return (
            <Job
              key={job.id}
              job={job}
              pendingApplicants={pendingApplicants}
              shift={shift}
              from={from}
              rateTypes={rateTypes}
              allApplied={allApplied}
            />
          );
        })}
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const tz = getTimezone(ownProps.shift.eventLocation);
  const jobs = ownProps.jobs;
  const updatedJobs = jobs.map((job) => ({
    ...job,
    startTime: moment.tz(get(job, 'startTime', ''), tz).format('MM-DD-YYYY HH:mm'),
    endTime: moment.tz(get(job, 'endTime', ''), tz).format('MM-DD-YYYY HH:mm'),
    startDate: moment.tz(get(job, 'startTime', ''), tz).format('MM-DD-YYYY'),
    endDate: moment.tz(get(job, 'endTime', ''), tz).format('MM-DD-YYYY'),
    start: moment.tz(get(job, 'startTime', ''), tz).format('HH:mm'),
    end: moment.tz(get(job, 'endTime', ''), tz).format('HH:mm'),
  }));

  return {
    pendingApplicants: state.user.pendingApplicants,
    jobsMetadata: state.dashboard.applicants.jobsMetadata,
    jobs: updatedJobs,
    rateTypes: state.enums.rateTypes,
  };
};

export default connect(mapStateToProps)(JobsForShift);
