import React, { Component } from 'react';
import { Link } from 'react-router-dom';

class NotFound extends Component {

  componentWillMount() {
    document.body.classList.add('not-found');
  }

  componentWillUnmount() {
    document.body.classList.remove('not-found');
  }

  render() {
    return (
      <div className="notFound">
        <h1>The page you are looking for doesn't exist</h1>
        <Link className="btn btn-primary" to="/dashboard">Return to the homepage</Link>
      </div>
    );
  }
}

export default NotFound;