// @flow
import { CLEAR_FORM, INIT_FORM } from '../actions';
import type { EventLocation } from 'redux/ducks/helpers';
import { newEventLocation } from 'redux/ducks/helpers';
import * as actions from './actions';
import { SAVE_SHIFTS_AND_CONTINUE_SUCCESS } from '../Shifts/actions';
import { findIndex, get } from 'lodash';
import { RELOAD_FORM_SUCCESS, DUPLICATE_EVENT } from '../actions';

// Types
// -------------------------------------
type Action = { type: string, payload: any };
type EmergencyLocation = EventLocation;

export class Contact {
  role = '';
  name = '';
  phoneNumber = '';
};

export class Equipment {
  name = '';
  location = '';
  constructor(name: string) {
    this.name = name;
  }
}

export type EAPFormType = {
  id?: ?number;
  eventLocation: EventLocation;
  hospital: EmergencyLocation;
  policeStation: EmergencyLocation;
  fireDepartment: EmergencyLocation;
  venueMapUrl: ?string;
  emergencyContacts: Contact[];
  emergencyEquipment: Equipment[];
  instructions: ?string;
  uploadedPlan: ?File;
  uploadedPlanPreview: ?string;
  generateOrUpload: "generate" | "upload";
  hasNavigatedForward: boolean;
};

export type EAPStateType = {
  forms: EAPFormType[];
  currentForm: number;
  allEventLocations: EventLocation[];
};

const mockLocation: EventLocation = {
  name: 'Arcweb HQ',
  details: '',
  latitude: 39.94992,
  longitude: -75.1472907,
  formattedAddress: '234 Market Street, Philadelphia PA 19106',
  phoneNumber: null,
  address: {
    streetAddress: '234 Market Street',
    city: 'Philadelphia',
    state: 'PA',
    zipCode: '19106'
  }
}

const mockLocation2: EventLocation = {
  name: 'Arcweb HQ2',
  details: '',
  latitude: 39.94992,
  longitude: -75.1472907,
  formattedAddress: '234 Market Street, Philadelphia PA 19106',
  phoneNumber: null,
  address: {
    streetAddress: '234 Market Street',
    city: 'Philadelphia',
    state: 'PA',
    zipCode: '19106'
  }
}

const mockLocation3: EventLocation = {
  name: 'Arcweb HQ3',
  details: '',
  latitude: 39.94992,
  longitude: -75.1472907,
  formattedAddress: '234 Market Street, Philadelphia PA 19106',
  phoneNumber: null,
  address: {
    streetAddress: '234 Market Street',
    city: 'Philadelphia',
    state: 'PA',
    zipCode: '19106'
  }
}

export function newEapForm(eventLocation: EventLocation = newEventLocation()): EAPFormType {
  return {
    eventLocation,
    hospital: newEventLocation(),
    policeStation: newEventLocation(),
    fireDepartment: newEventLocation(),
    venueMapUrl: null,
    emergencyContacts: [new Contact()],
    emergencyEquipment: [new Equipment(''), new Equipment('')],
    instructions: '',
    generateOrUpload: "generate",
    uploadedPlan: null,
    uploadedPlanPreview: null,
    hasNavigatedForward: false
  }
};

export const INITIAL_FORM = 0;

export function initialState(): EAPStateType {
  return {
    forms: [newEapForm()],
    currentForm: INITIAL_FORM,
    allEventLocations: []
  }
}

function formMappingReducer(updateFn: (EAPFormType, any) => EAPFormType): (EAPStateType, Action) => EAPStateType {
  return function (state: EAPStateType, payload: any): EAPStateType {
    return {
      ...state,
      forms: state.forms.map((form, idx) => {
        if (idx === state.currentForm) {
          return updateFn(form, payload);
        } else {
          return form;
        }
      })
    }
  }
}

function mapByIndexReducer(updateFn: (EAPFormType, any) => EAPFormType): (EAPStateType, Action) => EAPStateType {
  return function (state: EAPStateType, { index = state.currentForm, ...payload }: any): EAPStateType {
    return {
      ...state,
      forms: state.forms.map((form, idx) => {
        if (idx === index) {
          return updateFn(form, payload);
        } else {
          return form;
        }
      })
    }
  }
}

const removeContactReducer = formMappingReducer(
  (form, { index }) => {
    let emergencyContacts: Contact[] = [...form.emergencyContacts],
      contact: Contact = emergencyContacts[index];

    if (!!contact.id) {
      emergencyContacts.splice(index, 1, ({ _destroy: true, id: contact.id }: any))
    } else {
  emergencyContacts = emergencyContacts.filter((val, i) => i !== index);
}

return {
  ...form,
  emergencyContacts
}
  }
)

const addContactReducer = formMappingReducer(
  form => ({
    ...form,
    emergencyContacts: [...form.emergencyContacts, new Contact()]
  })
)

const contactChangedReducer = formMappingReducer(
  (form, { index, contact }) => ({
    ...form,
    emergencyContacts: form.emergencyContacts.map((c, i) => {
      if (i === index) {
        return Object.assign({}, c, contact);
      } else {
        return c
      }
    })
  })
)

const removeEquipmentReducer = formMappingReducer(
  (form, { index }) => {
    let emergencyEquipment: Equipment[] = [...form.emergencyEquipment],
      equipment: Equipment = emergencyEquipment[index];

    if (!!equipment.id) {
      emergencyEquipment.splice(index, 1, ({ _destroy: true, id: equipment.id }: any))
    } else {
  emergencyEquipment = emergencyEquipment.filter((val, i) => i !== index);
}

return {
  ...form,
  emergencyEquipment
}
  }
)

const addEquipmentReducer = formMappingReducer(
  form => ({
    ...form,
    emergencyEquipment: [...form.emergencyEquipment, new Equipment('')]
  })
)

const equipmentChangedReducer = formMappingReducer(
  (form, { index, equipment }) => ({
    ...form,
    emergencyEquipment: form.emergencyEquipment.map((e, i) => {
      if (i === index) {
        return Object.assign({}, e, equipment);
      } else {
        return e
      }
    })
  })
)

const addHospitalReducer = mapByIndexReducer(
  (form, { hospital }) => ({
    ...form,
    hospital
  })
);

const addFireDepartmentReducer = mapByIndexReducer(
  (form, { fireDepartment }) => ({
    ...form,
    fireDepartment
  })
);

const addPoliceStationReducer = mapByIndexReducer(
  (form, { policeStation }) => ({
    ...form,
    policeStation
  })
);

const updateMapReducer = formMappingReducer(
  (form, { map }) => ({
    ...form,
    venueMapUrl: map
  })
);

const instructionsUpdatedReducer = formMappingReducer(
  (form, instructions) => ({
    ...form,
    instructions
  })
)

function showNextEapReducer(state) {
  const length = state.forms.length;
  state.forms[state.currentForm].hasNavigatedForward = true;
  return {
    ...state,
    forms: [...state.forms],
    currentForm: state.currentForm >= (length - 1) ? INITIAL_FORM : state.currentForm + 1
  }
}

function showPrevEapReducer(state) {
  const length = state.forms.length;
  return {
    ...state,
    currentForm: state.currentForm <= INITIAL_FORM ? length - 1 : state.currentForm - 1
  }
}

function initFormReducer(state, payload) {
  let forms = payload.emergencyActionPlans;
  let locations = state.allEventLocations;
  let locationsIncluded = forms.map(form => form.eventLocation.id)
  let locationsNotIncluded = locations.filter(loc => !locationsIncluded.includes(loc.id))
  forms.push(...locationsNotIncluded.map(newEapForm))
  let currentForm;
  if (payload.eventLocationId) {
    currentForm = findIndex(forms, form => form.eventLocation.id === payload.eventLocationId);
  } else {
    currentForm = INITIAL_FORM;
  }
  return {
    ...state,
    forms,
    currentForm,
  }
}

function reloadFormReducer(state, payload) {
  let newState = { ...state };

  if (payload.shifts) {
    newState.allEventLocations = payload.shifts.map(shift => shift.eventLocation);
  }

  if (payload.eap) {
    newState.forms = [];

    for (const eap of payload.eap) {
      newState.forms.push(eap);
    }
  }

  return newState;
}

const generateOrUploadChangeReducer = formMappingReducer((form, generateOrUpload) => {
  return {
    ...form,
    generateOrUpload
  }
})

function createSuccessReducer(state, payload) {
  state.forms[state.currentForm].id = payload.id;
  return showNextEapReducer(state);
}

const newUploadedEapReducer = formMappingReducer((form, uploadedPlan) => ({
  ...form,
  uploadedPlan
}));

const newUploadedEapPreviewReducer = formMappingReducer((form, uploadedPlanPreview) => ({
  ...form,
  uploadedPlanPreview
}));

const clearEquipmentReducer = formMappingReducer((form) => {
  return {
    ...form,
    emergencyEquipment: newEapForm().emergencyEquipment
  }
});

const clearContactsReducer = formMappingReducer((form) => {
  return {
    ...form,
    emergencyContacts: [...form.emergencyContacts.filter((contact) => !!contact.id)
      .map((serverContact) => ({ _destroy: true, id: serverContact.id })),
    newEapForm().emergencyContacts
    ]
  }
})

function duplicateAllEapSuccessReducer(state, payload) {
  return {
    ...state,
    forms: payload.payload.forms,
    allEventLocations: payload.payload.allEventLocations
  }
}

export default (state: EAPStateType = initialState(), action: Action): EAPStateType => {
  switch (action.type) {
    case RELOAD_FORM_SUCCESS:
      return reloadFormReducer(state, action.payload);
    case actions.EAP_FORM_INIT_SUCCESS:
      return initFormReducer(state, action.payload);
    case actions.DUPLICATE_ALL_EAP_SUCCESS:
      return duplicateAllEapSuccessReducer(state, action.payload)
    case actions.SHOW_NEXT_EAP:
      return showNextEapReducer(state);
    case actions.SHOW_PREV_EAP:
      return showPrevEapReducer(state);
    case actions.ADD_CONTACT:
      return addContactReducer(state, action.payload);
    case actions.REMOVE_CONTACT:
      return removeContactReducer(state, action.payload);
    case actions.CONTACT_UPDATED:
      return contactChangedReducer(state, action.payload)
    case actions.CLEAR_CONTACTS:
      return clearContactsReducer(state, action.payload);
    case actions.ADD_EQUIPMENT:
      return addEquipmentReducer(state, action.payload);
    case actions.REMOVE_EQUIPMENT:
      return removeEquipmentReducer(state, action.payload);
    case actions.EQUIPMENT_UPDATED:
      return equipmentChangedReducer(state, action.payload);
    case actions.CLEAR_EQUIPMENT:
      return clearEquipmentReducer(state, action.payload);
    case actions.FETCH_NEAREST_HOSPITAL_SUCCESS:
      return addHospitalReducer(state, action.payload);
    case actions.FETCH_NEAREST_FIRE_DEPARTMENT_SUCCESS:
      return addFireDepartmentReducer(state, action.payload);
    case actions.FETCH_NEAREST_POLICE_STATION_SUCCESS:
      return addPoliceStationReducer(state, action.payload);
    case actions.MAP_UPDATED:
      return updateMapReducer(state, action.payload);
    case SAVE_SHIFTS_AND_CONTINUE_SUCCESS:
      return { ...state, allEventLocations: action.payload.map(shift => shift.eventLocation) };
    case INIT_FORM:
      return state;
    case actions.INSTRUCTIONS_UPDATED:
      return instructionsUpdatedReducer(state, action.payload);
    case actions.GENERATE_OR_UPLOAD_CHANGE:
      return generateOrUploadChangeReducer(state, action.payload);
    case actions.NEW_PLAN_UPLOADED:
      return newUploadedEapReducer(state, action.payload);
    case actions.NEW_PLAN_PREVIEW:
      return newUploadedEapPreviewReducer(state, action.payload);
    case actions.CREATE_EAP_SUCCESS:
      return createSuccessReducer(state, action.payload);
    case CLEAR_FORM:
      return initialState();
    default: return state;
  }
}