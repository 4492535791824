import React, { Component } from 'react';
import { Route } from 'react-router-dom';
import EmergencyActionPlan from './EmergencyActionPlan';
import ReviewEAP from './ReviewEAP/ReviewEAP';

class LocationEAP extends Component {
  constructor(props) {
    super(props);
    props.initialize('LocationEAP');
  }

  render() {
    return (
      <div className="locationEAP">
        <div className="nav-placeholder" />
        <main>
          <Route
            path={`${this.props.baseUrl}/eap/eap-type`}
            render={() => <EmergencyActionPlan {...this.props} />}
          />
          <Route
            path={`${this.props.baseUrl}/eap/review`}
            render={() => <ReviewEAP {...this.props} />}
          />
        </main>
      </div>
    );
  }
}

export default LocationEAP;
