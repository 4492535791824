class ApplySerializer {
  static deserialize(response) {
    return response;
  }
  static serialize({
    resume,
    jobId,
    firstName,
    lastName,
    phone,
    email,
    address,
    city,
    state,
    zip,
    lead,
    coverLetter,
  }) {
    const formData = new FormData();
    formData.append("resume", resume);
    formData.append("shift_id", jobId);
    formData.append("first_name", firstName);
    formData.append("last_name", lastName);
    formData.append("phone", phone);
    formData.append("email", email);
    formData.append("address", address);
    formData.append("city", city);
    formData.append("state", state);
    formData.append("zip", zip);
    formData.append("lead", lead);
    formData.append("cover_letter", coverLetter);

    return formData;
  }
}

export default ApplySerializer;
