import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { trackMixpanelPageView } from 'helpers/post';

class NavBar extends Component {
  trackLogin = () => {
    trackMixpanelPageView('IntroLogInClick')
  }

  render() {
    const { isCreateEvent, isEditEvent, pathname, logo, logoWidth, logoUrl } = this.props;
    const logoProps = {
      src: logo,
      style: { width: logoWidth },
    };
    const slug = pathname ? pathname.split('/').pop() : '';

    return isCreateEvent || isEditEvent ? (
      <div>
        <div className="navBarV2CreateEventPlaceholder"></div>
        <div className="navBarV2CreateEvent">
          <i className="material-icons" onClick={this.props.toggleCollapsed}>
            reorder
          </i>
          <Link to={logoUrl ? logoUrl : '/'}>
            <img {...logoProps} className="logo" alt="Go4" />
          </Link>
        </div>
      </div>
    ) : (
      <nav className="navBarV2">
        <Link to={logoUrl ? logoUrl : '/'}>
          <img {...logoProps} className="logo" alt="Go4" />
        </Link>
        {slug === 'intro' && (
          <Link
            to="/"
            onClick={this.trackLogin}
            className="login-btn gotham-medium-font">
            <div>LOG IN</div>
          </Link>
        )}
      </nav>
    );
  }
}

export default NavBar;
