import React, { Component } from 'react';
import { connect } from 'react-redux';
import EventList, { scenes } from 'components/V2/EventList/EventList';
import {
  changeReviewsPage,
  changeReviewsSearch,
  changeReviewsSort,
  getReviews,
  resetPagination,
} from 'redux/ducks/Dashboard';
import { FEEDBACK_COLUMNS } from 'helpers/dashboard';

class ATFeedback extends Component {
  onChangePage = (data) => {
    const page = data.selected + 1;
    this.props.onChangePage(page);
  };

  reloadData = () => {
    this.props.resetPagination();
    this.props.getReviews();
  }

  render() {
    const { reviews, pagination, search, text, sort } = this.props;
    const key = Object.keys(sort)[0];
    const value = sort[key];
    return (
      <div className="feedback">
        <EventList
          title={text}
          onChangeSearch={this.props.changeSearch}
          searchValue={search}
          data={reviews}
          reloadData={this.reloadData}
          pagination={pagination}
          onChangePage={this.onChangePage}
          column={scenes.FEEDBACK}
          columns={FEEDBACK_COLUMNS}
          emptyMessage="There are no athletic trainers to provide feedback on."
          sort={{ key, value }}
          onChangeSort={this.props.onChangeSort}
        />
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  reviews: state.dashboard.reviews.reviews,
  pagination: state.dashboard.reviews.pagination,
  search: state.dashboard.reviews.search,
  sort: state.dashboard.reviews.sort,
});

const mapDispatchToProps = (dispatch) => ({
  getReviews: () => dispatch(getReviews()),
  onChangePage: (page) => dispatch(changeReviewsPage(page)),
  changeSearch: (search) => dispatch(changeReviewsSearch(search)),
  onChangeSort: (key, value) => dispatch(changeReviewsSort({ key, value })),
  resetPagination: () => dispatch(resetPagination('reviews')),
});

export default connect(mapStateToProps, mapDispatchToProps)(ATFeedback);
