class InvitationSerializer {
  static deserializeValidTokenResponse(response) {
    return {
      parent: {
        email: response.parent.email,
        phone: response.parent.phone_number
      }
    };
  }

  static deserializeValidTokenResponsePatient(response) {
    return {
      patient: {
        email: response.patient.email,
        phone: response.patient.phone_number
      }
    };
  }

  static deserializeAcceptResponse(response) {
    return response;
  }

  static serializePatient(patient, token) {
    const phone = patient.phone?.length === 10 ? `1${patient.phone}` : patient.phone;

    return {
      patient: {
        email: patient.email,
        invitation_token: token,
        first_name: patient.firstName,
        last_name: patient.lastName,
        address_zip: patient.addressZip,
        phone_no: phone,
        password: patient.password,
        password_confirmation: patient.passwordConfirmation,
      }
    };
  }

  static serializeParent(parent, token) {
    const phone = parent.phone?.length === 10 ? `1${parent.phone}` : parent.phone;

    return {
      parent: {
        email: parent.email,
        invitation_token: token,
        first_name: parent.firstName,
        last_name: parent.lastName,
        address_zip: parent.addressZip,
        phone_no: phone,
        password: parent.password,
        password_confirmation: parent.passwordConfirmation,
      }
    };
  }
}

export default InvitationSerializer;
