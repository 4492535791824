import React, { Component } from 'react';
import { Modal, ModalHeader, ModalBody } from 'reactstrap';
import { Table } from 'antd';
import { stateRates } from './Constants';

const columns = [
  {
    key: 'state',
    title: 'STATE',
    dataIndex: 'state',
  },
  {
    key: 'rate',
    title: 'AVG TRANSACTED RATE',
    dataIndex: 'rate',
    width: '50%',
  }
]
class AverageRatesModal extends Component {
  state = {
    modalOpen: false,
  };

  onToggle = () => {
    this.setState({ modalOpen: !this.state.modalOpen });
  };

  onClick = (e) => {
    e.preventDefault();
    this.onToggle();
  }

  render() {
    return (
      <div className="averageRatesModal">
        <a className="average-rates-link" href="#" onClick={this.onClick}>Click here to see avg. rates in your state</a>
        <Modal
          isOpen={this.state.modalOpen}
          toggle={this.onToggle}
          modalClassName="rates-modal"
        >
          <ModalHeader toggle={this.onToggle}></ModalHeader>
          <ModalBody>
           <Table
              columns={columns}
              dataSource={stateRates}
              pagination={false}
              size="small"
              scroll={{ y: 400 }}
           />
          <div className='choosing-rates-tips'>
            <p>The above are average transacted rates on the platform.</p>
            <p>Factors to consider when choosing a rate:</p>
            <ul>
              <li>Time until event from date posted</li>
              <li>Length of shift</li>
              <li>Total payout of shift</li>
              <li>Time of day</li>
              <li>Day of week</li>
              <li>Time of year</li>
              <li>Geography</li>
            </ul>
          </div>
          </ModalBody>
        </Modal>
      </div>
    );
  }
}

export default AverageRatesModal;