import ApiService from './ApiService';

import { AggregateInfoSerializer } from 'services/serializers';

class AggregateInfoService extends ApiService {
  constructor() {
    super();

    this.url = '/stats';
  }

  get() {
    return super.get(`${this.url}.json`,
      (response) => AggregateInfoSerializer.deserialize(response));
  }
}

export default AggregateInfoService;