import React, { Component } from 'react';

import { connect } from 'react-redux';

import { values, min, sortBy, reverse } from 'lodash';

import {
  getPastEvents,
  changePastEventsPage,
} from 'redux/ducks/Event';
import ReactPaginate from 'react-paginate';
import { error } from 'redux/ducks/Flash';

import EventFinancesListItem from './EventFinancesListItem';


export class EventFinancesList extends Component {
  componentDidMount() {
    this.props.getEvents();
  }

  componentWillReceiveProps(newProps) {
    if (newProps.error && !this.props.error) {
      this.props.onError('An error occurred when loading the event list');
    }
  }

  minStartTime = (event) => {
    const shiftTimes = values(event.shifts.byId).map(s => s.startTime);
    return min(shiftTimes);
  }

  handlePageClick = data => {
    let selectedPage = data.selected + 1;

    this.props.onPastEventsPage(selectedPage);
  };

  render() {
    return (
      <div className="eventFinancesList">
        <ul className="list-table">
          <li className="head">
            <div></div>
            <div>Event</div>
            <div className="stacked-text"><span>Shift Details</span></div>
            <div className="stacked-text">Staff Hired</div>
            <div><div>Date of Last</div><div>Payment Issued</div></div>
            <div><div>Total Payment</div><div>to Date</div></div>
            <div><div>Duplicate</div><div>Event</div></div>
          </li>
          {reverse(sortBy(values(this.props.events), this.minStartTime)).map((event) => {
            return <EventFinancesListItem item={event} key={event.id} />
          })}
        </ul>

        {this.props.events && !this.props.error && this.props.pagination && this.props.pagination.total_pages > 1 &&
          <ReactPaginate
            previousLabel={' ← '}
            nextLabel={' → '}
            breakLabel={'...'}
            breakClassName={'break-me'}
            pageCount={this.props.pagination.total_pages || 0}
            marginPagesDisplayed={2}
            pageRangeDisplayed={2}
            initialPage={this.props.pagination.current_page - 1}
            onPageChange={this.handlePageClick}
            containerClassName={'pagination'}
            subContainerClassName={'pages pagination'}
            activeClassName={'active'}
          />
        }
      </div>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return {
    getEvents: (params) => dispatch(getPastEvents()),
    onError: (message) => dispatch(error(message)),
    onPastEventsPage: (page) => dispatch(changePastEventsPage(page)),
  };
}

function mapStateToProps(state) {
  return {
    events: state.event.events.past,
    error: state.event.error,
    pagination: state.event.events.pastPagination
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(EventFinancesList);