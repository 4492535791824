import HorizontalRule from 'components/HorizontalRule';
import React from 'react';

const UploadDoc = (props) => {
  const fileReader = (() => {
    const reader = new FileReader();
    reader.addEventListener('load', () => {
      props.onFieldChange('uploadedPlanPreview', reader.result);
    });
    return reader;
  })();

  const addEAPDocument = (ev) => {
    const file = ev.currentTarget.files[0];
    fileReader.readAsDataURL(file);
    props.onFieldChange('uploadedPlan', file);
  };

  const deleteEAPDocument = (e: SyntheticEvent<HTMLInputElement>, document: File) => {
    e.preventDefault();
    props.onFieldChange('uploadedPlan', null);
    props.onFieldChange('uploadedPlanPreview', null);
  };

  const hasOwnEAP = () => {
    return !!props.uploadedPlanPreview || !!props.uploadedPlan;
  };

  const getEAPName = () =>
    props.uploadedPlan?.name ||
    decodeURIComponent(props.uploadedPlanPreview?.split('/').pop()) ||
    '';

  return (
    <div className="upload-eap-doc gotham-regular-font">
      <HorizontalRule dashed />
      <label className="custom-file-label upload-eap-doc-button" htmlFor="upload-eap-doc">
        UPLOAD EAP
      </label>
      <input
        className="custom-file-input"
        type="file"
        name="upload-eap-doc"
        accept="*"
        id="upload-eap-doc"
        hidden
        onChange={addEAPDocument}
      />
      {hasOwnEAP() && (
        <div className="py-2 doc-upload-list">
          <i className="fas fa-file-alt px-2"></i>
          <span className="px-2 nimbus-regular-font">{getEAPName()}</span>
          <i className="material-icons remove-btn" onClick={deleteEAPDocument}>
            close
          </i>
        </div>
      )}
    </div>
  );
};

export default UploadDoc;
