import moment from "moment";
class BlogSerializer {
  static deserialize(blog = {}) {
    const { items, nextPageToken } = blog;
    const processed = items.map((item) => {
      return {
        ...item,
        thumb: ((item.images || [])[0] || {}).url,
        date: moment(item.published).format("MMMM DD, YYYY"),
      };
    });
    return { posts: processed, nextPageToken };
  }

  static serialize(blog) {
    const result = {
      // TODO
    };

    return blog;
  }
}

export default BlogSerializer;
