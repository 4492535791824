import React from 'react';

import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import moment from 'moment';

export default ({isOpen, toggle, onDecline, onApprove, job}) => (
  <Modal 
    isOpen={isOpen} 
    toggle={toggle}
  >
    <ModalHeader toggle={toggle}>Modification Requested</ModalHeader>
    <ModalBody>
      <p>{job.user.name} has requested a modification for this shift:</p>
      <p>Original Shift Time: {moment(job.startTime).format('h:mm a')} - {moment(job.endTime).format('h:mm a')}</p>
      <p>Modified Shift Time: {moment(job.jobModification.startTime).format('h:mm a')} - {moment(job.jobModification.endTime).format('h:mm a')}</p>
    </ModalBody>
    <ModalFooter>
      <button
        className="btn btn-primary btn-secondary" 
        onClick={onDecline}
      >
        decline
      </button>
      <button 
        className="btn btn-primary btn-danger" 
        onClick={onApprove}
      >
        approve
      </button>
    </ModalFooter>
  </Modal>
);