import React from "react";

const Input = ({ type, name, value, handleChange, hasError }) => (
  <input
    className={`applyFormInput ${hasError ? "error" : ""}`}
    name={name}
    type={type}
    value={value}
    onChange={handleChange}
  />
);

export default Input;
