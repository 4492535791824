import React, { Component } from 'react';
import { Link, withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Collapse } from 'reactstrap';
import classNames from 'classnames';

class NavLink extends Component {
  state = {
    showDropdown: false,
  };

  constructor() {
    super();

    this.onClick = this.onClick.bind(this);
    this.setWrapperRef = this.setWrapperRef.bind(this);
  }

  componentDidMount() {
    document.addEventListener('mousedown', this.onClickOutside);
    const slug = this.props.location.pathname.split('/')[1];
    if (this.props.link.links && this.props.link.links.some((link) => link.url.includes(slug))) {
      this.setState({ showDropdown: true });
    }
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.onClickOutside);
  }

  activeClass() {
    let result = '';

    if (
      this.currentPath().includes(this.props.link.url) ||
      this.currentPath().includes(this.props.link.otherUrl)
    ) {
      result = 'active';
    }

    return result;
  }

  currentPath() {
    return this.props.location.pathname;
  }

  onClick() {
    if (this.props.link.links) {
      this.setState({ showDropdown: !this.state.showDropdown });
    }
  }

  setWrapperRef(node) {
    this.wrapperRef = node;
  }

  render() {
    return (
      <li
        className={classNames(`navLinkV2 dropdown ${this.activeClass()}`, {
          'simple': this.props.link.simple,
          'show-dropdown': this.state.showDropdown,
        })}
        ref={this.setWrapperRef}
      >
        {this.props.link.external ? (
          <a href={this.props.link.url} target="_blank" rel="noopener noreferrer">
            <div>{this.props.link.text}</div>
          </a>
        ) : (
          <Link to={this.props.link.url} onClick={this.onClick}>
            <div>{this.props.link.text}</div>
            {this.props.link.links && <i className="fas fa-angle-down" />}
          </Link>
        )}
        {this.props.link.links && (
          <div>
            <Collapse className="dropdown-items" isOpen={this.state.showDropdown}>
              {this.props.link.links.map((link, index) => {
                return (
                  <Link
                    key={index}
                    to={link.url}
                    onClick={this.onDismissDropdown}
                    className={classNames('', {
                      active:
                        this.currentPath().includes(link.url) ||
                        this.currentPath().includes(link.otherUrl),
                    })}
                  >
                    <div>{link.text}</div>
                  </Link>
                );
              })}
            </Collapse>
          </div>
        )}
      </li>
    );
  }
}

NavLink.propTypes = {
  text: PropTypes.string,
  thumbnail: PropTypes.string,
  url: PropTypes.string,
};

export default withRouter(NavLink);
