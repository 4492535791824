import React, { Component } from 'react';
import classNames from 'classnames';
import { withRouter } from 'react-router-dom/cjs/react-router-dom';
import { connect } from 'react-redux';
import ConfirmationModal from 'components/V2/ConfirmationModal';
import {
  approveJobModification,
  declineJobModification,
} from 'redux/ducks/Job';
import { getEstimatedFee } from 'helpers/getEstimatedFee';
import { isAmericanExpress } from 'helpers/post';
import { UncontrolledTooltip } from 'reactstrap';
import { SHOW_TOOLTIP_COLUMNS, createOverflowChecker } from 'helpers/dashboard';

class ShiftModificationsItem extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isOverflowing: {},
      isDeclineModalOpen: false,
    };
    this.columnRefs = {};
    SHOW_TOOLTIP_COLUMNS.modifications.forEach((column) => {
      this.columnRefs[column] = React.createRef();
    });
  }

  componentDidMount() {
    const checkOverflow = createOverflowChecker(
      'modifications',
      this.columnRefs,
      this.setState.bind(this)
    );

    this.resizeObserver = new ResizeObserver(checkOverflow);
    SHOW_TOOLTIP_COLUMNS.modifications.forEach((column) => {
      this.resizeObserver.observe(this.columnRefs[column].current);
    });
  }

  componentWillUnmount() {
    this.resizeObserver.disconnect();
  }

  toggleDeclineModal = () =>
    this.setState({ isDeclineModalOpen: !this.state.isDeclineModalOpen });

  calculatePay = () => {
    const { item, payment } = this.props;
    if (item.job.payRateId === 1) return item.job.payRate;

    const { jobStartTime, jobEndTime, startTime, endTime } = item;
    const jobHours = jobEndTime.diff(jobStartTime, 'hours', true);
    const modifiedHours = endTime.diff(startTime, 'hours', true);
    const estimatedFee = getEstimatedFee(
      startTime,
      0,
      isAmericanExpress(payment)
    );
    if (jobHours < modifiedHours) {
      const newPay = item.job.payRate * modifiedHours;
      const newFee = (estimatedFee * newPay) / 100;
      return (newPay + newFee).toFixed(2);
    }
    const pay = item.job.payRate * jobHours;
    const fee = (estimatedFee * pay) / 100;
    return (pay + fee).toFixed(2);
  };

  render() {
    const { item, columns } = this.props;

    return (
      <div className="modification-list-item">
        <div
          className={`${columns['startDate'].size}`}
          id={`startDate-${item.id}`}
          ref={this.columnRefs['startDate']}
        >
          {item.startTime.format('MM/DD/YY')}
          {this.state.isOverflowing.startDate && (
            <UncontrolledTooltip target={`startDate-${item.id}`}>
              {item.startTime.format('MM/DD/YY')}
            </UncontrolledTooltip>
          )}
        </div>
        <div
          className={`${columns['jobName'].size} job-name`}
          id={`jobName-${item.id}`}
          ref={this.columnRefs['jobName']}
        >
          {item.eventName}
        </div>
        {this.state.isOverflowing.jobName && (
          <UncontrolledTooltip target={`jobName-${item.id}`}>
            {item.eventName}
          </UncontrolledTooltip>
        )}
        <div
          className={`${columns['at'].size}`}
          id={`at-${item.id}`}
          ref={this.columnRefs['at']}
        >
          {item.job.user.name}
          {this.state.isOverflowing.at && (
            <UncontrolledTooltip target={`at-${item.id}`}>
              {item.job.user.name}
            </UncontrolledTooltip>
          )}
        </div>
        <div
          className={`${columns['modifiedTime'].size}`}
          id={`modifiedTime-${item.id}`}
          ref={this.columnRefs['modifiedTime']}
        >
          {item.startTime.format('h:mm A')} - {item.endTime.format('h:mm A')}
          {this.state.isOverflowing.modifiedTime && (
            <UncontrolledTooltip target={`modifiedTime-${item.id}`}>
              {item.startTime.format('h:mm A')} -{' '}
              {item.endTime.format('h:mm A')}
            </UncontrolledTooltip>
          )}
        </div>
        <div
          className={`${columns['modifiedPay'].size}`}
          id={`modifiedPay-${item.id}`}
          ref={this.columnRefs['modifiedPay']}
        >
          ${this.calculatePay()}
          {this.state.isOverflowing.modifiedTime && (
            <UncontrolledTooltip target={`modifiedTime-${item.id}`}>
              ${this.calculatePay()}
            </UncontrolledTooltip>
          )}
        </div>
        <div
          className={classNames(
            `${columns['actions'].size} modification-actions`
          )}
        >
          <button
            className={classNames('plain-button accept')}
            onClick={() => this.props.approve(item.job)}
          >
            ACCEPT
          </button>
          <button
            className={classNames('plain-button')}
            onClick={() => this.toggleDeclineModal()}
          >
            DECLINE
          </button>
        </div>
        <ConfirmationModal
          isOpen={this.state.isDeclineModalOpen}
          title="Decline Request"
          body={`Are you sure you want to decline this shift modification request from ${item.job.user.name}?`}
          cancelText="Go Back"
          confirmText="Decline"
          toggle={this.toggleDeclineModal}
          onSave={() => this.props.decline(item.job)}
        />
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    payment: state.payment,
  };
};

const mapDispatchToProps = (dispatch) => ({
  approve: (job) => dispatch(approveJobModification(job)),
  decline: (job) => dispatch(declineJobModification(job)),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(ShiftModificationsItem)
);
