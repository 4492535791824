class ConfirmationSerializer {
  static deserialize(response) {
    return {
      success: response.success,
      data: response.data,
      message: response.message
    }
  }

  static serializeResend(email) {
    return {
      email,
    }
  }
}

export default ConfirmationSerializer;