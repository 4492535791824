import ApiService from "./ApiService";

class DeeplinkSmsService extends ApiService {
  constructor(session) {
    super(session);

    this.url = "/deeplink_sms";
  }

  create({phone, deeplink}) {
    return super.post(
      `${this.url}.json`,
      { deeplink, phone_no: phone },
      (response) => response
    );
  }
}

export default DeeplinkSmsService;
