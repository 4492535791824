import { basicEpic } from 'redux/ducks/helpers';
import { PasswordService } from 'services';
import { RESET_PASSWORD, UPDATE_PASSWORD, VALIDATE_TOKEN } from './index';

export const resetPasswordEpic = basicEpic(
  RESET_PASSWORD,
  ({email}, state) => new PasswordService(state.session).reset(email)
);

export const updatePasswordEpic = basicEpic(
  UPDATE_PASSWORD,
  (payload, state) => new PasswordService(state.resetPassword.headers).update(payload)
);

export const validateTokenEpic = basicEpic(
  VALIDATE_TOKEN,
  (payload, state) => new PasswordService(state.session).validateToken(payload),
);