import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Button } from 'reactstrap';
import { hasToVerifyMicroDeposits } from '../../helpers/payment';
import { retrieveSource } from 'redux/ducks/Payment';

export const InfoBanner = ({ payment, retrieveSource, user, ...props }) => {
  useEffect(() => {
    if (payment.loadedSource) return;

    if (user.id) {
      console.log("InfoBanner::retrieveSource(user.id);");
      retrieveSource(user.id);
    }
  }, []);

  if (
    !hasToVerifyMicroDeposits(payment) ||
    props.location.pathname === '/edit-payment' ||
    !props.authenticated
  )
    return null;

  const onClickBanner = () => {
    props.history.push('/edit-payment');
  };

  return (
    <div className="info-banner">
      <Button color="info" className="message" onClick={onClickBanner}>
        <span className="message-text">
          Please verify the micro-deposits sent to your bank account.
        </span>
      </Button>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    payment: state.payment,
    user: state.session.currentUser,
    authenticated: state.session.authenticated,
  };
};

const mapDispatchToProps = (dispatch) => ({
  retrieveSource: (userId) => dispatch(retrieveSource(userId)),
});

export default connect(mapStateToProps, mapDispatchToProps)(InfoBanner);
