import React from "react";

const JobDetails = ({ item, noDescription, className }) => (
  <div className="jobDetails">
    <h2>{item.title}</h2>
    <p className={`details ${className || ""}`}>
      <strong>
        <span>{`Location: ${item.location}`}</span>
        <span>{`Department: ${item.department}`}</span>
        {/* <span>{`Job ID: ${item.id}`}</span> */}
      </strong>
    </p>
    <div className="divider" />
    {!noDescription && <p>{item.description}</p>}
  </div>
);

export default JobDetails;
