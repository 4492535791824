import React from "react";
import corner from "images/corner.svg";
import clsx from "classnames";

const CorneredContainer = ({ bottomOnly, children, className }) => {
  return (
    <section
      className={clsx("position-relative cornered-container", className)}
    >
      {!bottomOnly && (
        <>
          <img className={`position-absolute`} alt="corner" src={corner} />
          <img
            className={`position-absolute top-right`}
            alt="corner"
            src={corner}
          />
        </>
      )}
      <img
        className={`position-absolute bottom-left`}
        alt="corner"
        src={corner}
      />
      <img
        className={`position-absolute bottom-right`}
        alt="corner"
        src={corner}
      />
      {children}
    </section>
  );
};

export default CorneredContainer;
