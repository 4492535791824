// Actions

import { basicCreator } from 'redux/ducks/helpers';

// ========================================================
const constForName = (name) => `go4ellis/Post/Locations/${name}`;
export const FIELD_CHANGE = constForName('FIELD_CHANGE');
export const ADD_NEW_REPORT_TO_CONTACTS = constForName('ADD_NEW_REPORT_TO_CONTACTS');
export const REMOVE_REPORT_FROM_CONTACTS = constForName('REMOVE_REPORT_FROM_CONTACTS');
export const CHANGE_REPORT_FROM_CONTACTS = constForName('CHANGE_REPORT_FROM_CONTACTS');
export const VALIDATE_FORM = constForName('VALIDATE_FORM');
export const ADD_NEW_LOCATION = constForName('ADD_NEW_LOCATION');
export const ADD_MULTIPLE_LOCATIONS = constForName('ADD_MULTIPLE_LOCATIONS');
export const SUPPLIES_FIELD_CHANGE = constForName('SUPPLIES_FIELD_CHANGE');
export const DELETE_LOCATION = constForName('DELETE_LOCATION');
export const DELETE_SHIFT_DOCUMENT = constForName('DELETE_SHIFT_DOCUMENT');

// Action Creators
// ========================================================

export const changeLocationField = basicCreator(FIELD_CHANGE);
export const onAddNewReportToContact = basicCreator(ADD_NEW_REPORT_TO_CONTACTS);
export const removeReportFromContact = basicCreator(REMOVE_REPORT_FROM_CONTACTS);
export const onChangeReportFromContact = basicCreator(CHANGE_REPORT_FROM_CONTACTS);
export const validateForm = basicCreator(VALIDATE_FORM);
export const addNewLocation = basicCreator(ADD_NEW_LOCATION);
export const addMultipleLocations = basicCreator(ADD_MULTIPLE_LOCATIONS);
export const suppliesFieldChange = basicCreator(SUPPLIES_FIELD_CHANGE);
export const deleteLocation = basicCreator(DELETE_LOCATION);
export const deleteAttachedShiftDocument = basicCreator(DELETE_SHIFT_DOCUMENT);
