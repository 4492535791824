class StripeSerializer {
  static deserialize(source) {
    const result = {
      id: source.id,
      last4: source.last4,
      object: source.object,
    };

    if (source.object === 'card') {
      result['addressCity'] = source.address_city;
      result['addressLine1'] = source.address_line1;
      result['addressLine2'] = source.address_line2;
      result['addressState'] = source.address_state;
      result['addressZip'] = source.address_zip;
      result['expMonth'] = source.exp_month;
      result['expYear'] = source.exp_year;
      result['name'] = source.name;
      result['brand'] = source.brand;
    } else {
      result['name'] = source.account_holder_name;
      result['accountType'] = source.account_holder_type;
      result['routingNumber'] = source.routing_number;
    }

    return result;
  }

  static serializeSource(source) {
    const result = {
      address_city: source.addressCity,
      address_line1: source.addressLine1,
      address_line2: source.addressLine2,
      address_state: source.addressState,
      address_zip: source.addressZip,
      cvc: source.cvc,
      exp_month: source.expMonth,
      exp_year: source.expYear,
      name: `${source.firstName} ${source.lastName}`,
      number: source.number,
      object: source.object,
    };

    return result;
  }
}

export default StripeSerializer;