// @flow
import type { Enums, Action } from "redux/ducks/helpers";

// Imported Actions
// ========================================================
import { CREATE_SESSION_SUCCESS } from "redux/ducks/Session";

// Actions
// ========================================================
export const GET_ENUMS = "go4ellis/Enum/GET_ENUMS";
export const LOAD_ENUMS_SUCCESS = "go4ellis/Enum/LOAD_ENUMS_SUCCESS";
export const LOAD_ENUMS_ERROR = "go4ellis/Enum/LOAD_ENUMS_ERROR";

// Reducer Functions
// ========================================================
function getEnumsReducer(state: Enums, payload: Enums): Enums {
  return {
    ...state,
    ...payload,
  };
}

// Action Creators
// ========================================================
export function getEnums() {
  return { type: GET_ENUMS };
}

// Reducer
// ========================================================
const DEFAULT_STATE: Enums = {
  sports: [],
  genders: [],
  eventTypes: [],
  settingTypes: [],
  rateTypes: [],
  eventParticipantNumbers: [],
  ageBrackets: [],
  professions: [],
  eventSettings: [],
  settingDetails: [],
  jobDescriptions: [],
  usStates: [
    { name: "Alabama", abbreviation: "AL" },
    { name: "Alaska", abbreviation: "AK" },
    { name: "Arizona", abbreviation: "AZ" },
    { name: "Arkansas", abbreviation: "AR" },
    { name: "California", abbreviation: "CA" },
    { name: "Colorado", abbreviation: "CO" },
    { name: "Connecticut", abbreviation: "CT" },
    { name: "District of Columbia", abbreviation: "DC" },
    { name: "Delaware", abbreviation: "DE" },
    { name: "Florida", abbreviation: "FL" },
    { name: "Georgia", abbreviation: "GA" },
    { name: "Hawaii", abbreviation: "HI" },
    { name: "Idaho", abbreviation: "ID" },
    { name: "Illinois", abbreviation: "IL" },
    { name: "Indiana", abbreviation: "IN" },
    { name: "Iowa", abbreviation: "IA" },
    { name: "Kansas", abbreviation: "KS" },
    { name: "Kentucky", abbreviation: "KY" },
    { name: "Louisiana", abbreviation: "LA" },
    { name: "Maine", abbreviation: "ME" },
    { name: "Maryland", abbreviation: "MD" },
    { name: "Massachusetts", abbreviation: "MA" },
    { name: "Michigan", abbreviation: "MI" },
    { name: "Minnesota", abbreviation: "MN" },
    { name: "Mississippi", abbreviation: "MS" },
    { name: "Missouri", abbreviation: "MO" },
    { name: "Montana", abbreviation: "MT" },
    { name: "Nebraska", abbreviation: "NE" },
    { name: "Nevada", abbreviation: "NV" },
    { name: "New Hampshire", abbreviation: "NH" },
    { name: "New Jersey", abbreviation: "NJ" },
    { name: "New Mexico", abbreviation: "NM" },
    { name: "New York", abbreviation: "NY" },
    { name: "North Carolina", abbreviation: "NC" },
    { name: "North Dakota", abbreviation: "ND" },
    { name: "Ohio", abbreviation: "OH" },
    { name: "Oklahoma", abbreviation: "OK" },
    { name: "Oregon", abbreviation: "OR" },
    { name: "Pennsylvania", abbreviation: "PA" },
    { name: "Puerto Rico", abbreviation: "PR" },
    { name: "Rhode Island", abbreviation: "RI" },
    { name: "South Carolina", abbreviation: "SC" },
    { name: "South Dakota", abbreviation: "SD" },
    { name: "Tennessee", abbreviation: "TN" },
    { name: "Texas", abbreviation: "TX" },
    { name: "Utah", abbreviation: "UT" },
    { name: "Vermont", abbreviation: "VT" },
    { name: "Virginia", abbreviation: "VA" },
    { name: "Washington", abbreviation: "WA" },
    { name: "West Virginia", abbreviation: "WV" },
    { name: "Wisconsin", abbreviation: "WI" },
    { name: "Wyoming", abbreviation: "WY" },
  ],
};

export default function reducer(
  state: Enums = DEFAULT_STATE,
  action: Action
): Enums {
  switch (action.type) {
    case GET_ENUMS:
    case LOAD_ENUMS_SUCCESS:
      return getEnumsReducer(state, action.payload);
    case CREATE_SESSION_SUCCESS:
      return getEnumsReducer(state, action.payload.enums);
    default:
      return state;
  }
}
