import React, { Component } from 'react';
import HorizontalRule from 'components/HorizontalRule';
import { get } from 'lodash';
import { UncontrolledAlert } from 'reactstrap';
import { updatePassword, validateToken } from 'redux/ducks/ResetPassword';
import { connect } from 'react-redux';
import { parse } from 'query-string';
import ResetPasswordForm from './ResetPasswordForm';
import ResetPasswordSuccess from './ResetPasswordSuccess';

class ResetPasswordScene extends Component {
  componentDidMount() {
    const session = parse(this.props.location.search);
    this.props.validateToken(session['token']);
  }

  render() {
    return (
      <div className="v2resetPasswordScene nimbus-regular-font" >
        <div className="v2-title gotham-bold-font">
          <p className='v2-title-p'>
            {this.props.success ? "Password updated" : "Set new Password"}
          </p>
        </div>
        {
          this.props.success ?
            <ResetPasswordSuccess {...(this.props.data)} /> :
            <ResetPasswordForm {...this.props} />
        }
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  success: state.resetPassword.success && (!state.resetPassword.error),
  ...state.resetPassword
})

const mapDispatchToProps = (dispatch) => ({
  updatePassword: (password, confirmation, headers, token) => dispatch(updatePassword({ headers, password, confirmation, token })),
  validateToken: (token) => dispatch(validateToken(token)),
})

export default connect(mapStateToProps, mapDispatchToProps)(ResetPasswordScene);