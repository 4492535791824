import React from "react";

const HeaderText = ({ text, children = "", teal, noSlash, withBorderTop }) => (
  <h3
    className={`headerText${withBorderTop ? " withBorderTop mt-4 pt-4" : ""}`}
  >
    {text} {!noSlash && <span className={teal ? "teal" : ""}>/</span>}{" "}
    {children}
  </h3>
);

export default HeaderText;
