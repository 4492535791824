// @flow
import type { Action } from "redux/ducks/helpers";

// Actions
// ========================================================
export const DOWNLOAD_ALL_CREDENTIALS = "go4ellis/Credentials/DOWNLOAD_ALL_CREDENTIALS";
export const DOWNLOAD_ALL_CREDENTIALS_SUCCESS = "go4ellis/Credentials/DOWNLOAD_ALL_CREDENTIALS_SUCCESS";
export const DOWNLOAD_ALL_CREDENTIALS_ERROR = "go4ellis/Credentials/DOWNLOAD_ALL_CREDENTIALS_ERROR";

// Reducer Functions
// ========================================================

// Action Creators
// ========================================================
export function downloadAllCredentials(userId) {
  return { type: DOWNLOAD_ALL_CREDENTIALS, payload: userId };
}

// Reducer
// ========================================================
const DEFAULT_STATE = {
};

export default function reducer(
  state = DEFAULT_STATE,
  action: Action
) {
  switch (action.type) {
    default:
      return state;
  }
}
