import React, { Component } from 'react';
import { Link, withRouter } from 'react-router-dom';

import PropTypes from 'prop-types';

class NavLink extends Component {
  state = {
    showDropdown: false,
  };

  constructor() {
    super();

    this.onDismissDropdown = this.onDismissDropdown.bind(this);
    this.onClick = this.onClick.bind(this);
    this.onClickOutside = this.onClickOutside.bind(this);
    this.setWrapperRef = this.setWrapperRef.bind(this);
  }

  componentDidMount() {
    document.addEventListener('mousedown', this.onClickOutside);
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.onClickOutside);
  }

  activeClass() {
    let result = '';

    if (this.currentPath().includes(this.props.link.url)) {
      result = 'active';
    }

    return result;
  }

  currentPath() {
    return this.props.location.pathname;
  }

  onClick() {
    if (this.props.link.links) {
      this.setState({showDropdown: !this.state.showDropdown});
    }
  }

  onClickOutside(event) {
    if (this.wrapperRef && !this.wrapperRef.contains(event.target)) {
      this.setState({ showDropdown: false });
    }
  }

  onDismissDropdown() {
    this.setState({ showDropdown: false});
  }

  setWrapperRef(node) {
    this.wrapperRef = node;
  }

  render() {
    const dropdownConfig = this.props.link.links ? {
      'aria-expanded': 'false',
      'aria-haspopup': 'true',
      'data-toggle': 'dropdown',
      'id': `${this.props.link.text}-dropdown`,
      'className': 'data-toggle',
      'role': 'button',
    } : null;

    return (
      <li className={`navLink dropdown ${this.activeClass()} ${this.props.link.simple ? 'simple':''}`} ref={this.setWrapperRef}>
        { this.props.link.external ?
          <a href={this.props.link.url} target="_blank" {...dropdownConfig} rel="noopener noreferrer">
            <div>{this.props.link.text}</div>
          </a>
          : <Link to={this.props.link.url}  {...dropdownConfig} onClick={this.onClick}>
              <div>{this.props.link.text}</div>
              {this.props.link.links &&
                <i className='fas fa-angle-down' />
              }
              {this.props.link.thumbnail &&
                <div className="thumbnail" style={{backgroundImage: `url(${this.props.link.thumbnail})`}} alt="" />
              }
            </Link>
        }
        {this.props.link.links &&
          <div className={`dropdown-menu ${this.state.showDropdown ? 'show':''}`}
               aria-labelledby={`${this.props.link.text}-dropdown`}>
            {this.props.link.links.map((link, index) => {
              return <Link className="dropdown-item" key={index} to={link.url} onClick={this.onDismissDropdown}>{link.text}</Link>
            })}
          </div>
        }
      </li>
    )
  }
}

NavLink.propTypes = {
  text: PropTypes.string,
  thumbnail: PropTypes.string,
  url: PropTypes.string,
}

export default withRouter(NavLink);