import { ofType } from 'redux-observable';
import { mergeMap, map, filter } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { UserService, InvitationService, ContactService } from 'services';
import { get } from 'lodash';

import {
  CHECK_ACCOUNT,
  CHECK_ACCOUNT_ERROR,
  CHECK_ACCOUNT_SUCCESS,
  SUBMIT_PROFILE,
  SUBMIT_PROFILE_ERROR,
  SUBMIT_PROFILE_SUCCESS,
  VALIDATE_INVITATION_TOKEN,
  VALIDATE_INVITATION_TOKEN_SUCCESS,
  VALIDATE_INVITATION_TOKEN_ERROR,
  ACCEPT_INVITATION,
  ACCEPT_INVITATION_SUCCESS,
  ACCEPT_INVITATION_ERROR,
  REGISTRATION_POST_EVENT,
  REGISTRATION_POST_EVENT_ERROR,
  REGISTRATION_POST_EVENT_SUCCESS,
  REGISTRATION_POST_EVENT_PREFILL,
  CONTRACT_SUBMIT,
  CONTRACT_SUBMIT_ERROR,
  CONTRACT_SUBMIT_SUCCESS,
} from './index';

import { ERROR_FLASH_MESSAGE, dismiss, error } from '../Flash/index';
import { CREATE_SESSION, CREATE_SESSION_SUCCESS } from '../Session/index';

import { SHOW_SPINNER, HIDE_SPINNER } from 'redux/ducks/LoadingSpinner';
import { mappingEpic, basicEpic } from 'redux/ducks/helpers';

// Epics
// ========================================================
export function registerUserEpic(action$, store) {
  return action$.pipe(
    ofType(SUBMIT_PROFILE),
    mergeMap((action) => {
      return new UserService(store.getState().session)
        .create(store.getState().registration.user)
        .then((result) => ({ type: SUBMIT_PROFILE_SUCCESS, payload: result }))
        .catch((error) => ({ type: SUBMIT_PROFILE_ERROR, payload: error }));
    })
  );
}

export function longTermContractEpic(action$, store) {
  return action$.pipe(
    ofType(CONTRACT_SUBMIT),
    mergeMap((action) => {
      return new ContactService(store.getState().session)
        .post(store.getState().registration.user)
        .then((result) => {
          const { history } = action.payload;
          history.push('/sign-up/contract/confirmation');
          return { type: CONTRACT_SUBMIT_SUCCESS, payload: result };
        })
        .catch((error) => ({ type: CONTRACT_SUBMIT_ERROR, payload: error }));
    })
  );
}

export function registerAndPostEventEpic(action$, store) {
  return action$.pipe(
    ofType(REGISTRATION_POST_EVENT),
    mergeMap((action) => {
      return new UserService(store.getState().session)
        .create(store.getState().registration.user)
        .then((result) => {
          return {
            type: REGISTRATION_POST_EVENT_SUCCESS,
            payload: {
              location: action.payload.location,
              history: action.payload.history,
              callbackAction: REGISTRATION_POST_EVENT_PREFILL,
              callbackPayload: {
                user: store.getState().registration.user,
                history: action.payload.history,
              },
            },
          };
        })
        .catch((error) => ({ type: REGISTRATION_POST_EVENT_ERROR, payload: error }));
    })
  );
}

export const errorMessagesEpic = mappingEpic(
  [REGISTRATION_POST_EVENT_ERROR],
  ({ type, payload }) => {
    const data = get(payload, 'response.data');
    let errorMessage = typeof data === 'string' ? data : payload.message;
    if (errorMessage === 'Validation failed: Email has already been taken')
      errorMessage =
        'There is already an account associated with this email. Please log in instead.';
    return error(errorMessage);
  }
);

export const hideErrorMessagesEpic = mappingEpic([REGISTRATION_POST_EVENT_SUCCESS], () =>
  dismiss()
);

export function checkAccountExists(action$, store) {
  return action$.pipe(
    ofType(CHECK_ACCOUNT),
    mergeMap((action) => {
      const { history } = action.payload;
      return new UserService(store.getState().session)
        .checkAccountExists(store.getState().registration.user.email)
        .then((result) => {
          history.push('/sign-up/post-event/profile');
          return { type: CHECK_ACCOUNT_SUCCESS };
        })
        .catch((error) => {
          if (error?.response?.status === 403) {
            history.push(
              `/v2/login?email=${encodeURIComponent(store.getState().registration.user.email)}`
            );
          }
          return { type: CHECK_ACCOUNT_ERROR, payload: error };
        });
    })
  );
}

export function acceptInvitationEpic(action$, store) {
  return action$.pipe(
    ofType(ACCEPT_INVITATION),
    mergeMap((action) => {
      return new InvitationService(store.getState().session)
        .accept(store.getState().registration.user, action.payload)
        .then((result) => ({ type: ACCEPT_INVITATION_SUCCESS, payload: action.payload }))
        .catch((error) => ({ type: ACCEPT_INVITATION_ERROR, payload: error }));
    })
  );
}

export function autoLoginAfterAccept(action$, store) {
  return action$.pipe(
    ofType(ACCEPT_INVITATION_SUCCESS, REGISTRATION_POST_EVENT_SUCCESS),
    map((action) => {
      var role = 'EventOperator';
      if (action.payload.location.includes('parent')) {
        role = 'Parent';
      }
      if (action.payload.location.includes('patient')) {
        role = 'Patient';
      }
      const { callbackAction, callbackPayload } = action.payload;
      return {
        type: CREATE_SESSION,
        payload: {
          history: action.payload.history,
          email: store.getState().registration.user.email,
          password: store.getState().registration.user.password,
          rememberMe: false,
          role,
          callbackAction,
          callbackPayload,
        },
      };
    })
  );
}

export const registrationPostEventPrefillEpic = (action$, store) =>
  action$.pipe(
    ofType(REGISTRATION_POST_EVENT_PREFILL),
    mergeMap((action) => {
      const { history } = action.payload;
      history.push('/create-event/1?signUp=true');
      return Observable.empty();
    })
  );

export const validateTokenEpic = basicEpic(VALIDATE_INVITATION_TOKEN, (payload, state) =>
  new InvitationService(null).validateInvitationToken(payload)
);

const getError = (error) => {
  if (!error || !error.response || !error.response.data || !error.response.data.errors) {
    return 'An unknown error occurred';
  }
  return error.response.data.errors.join('. ');
};

export const showInvalidTokenError = mappingEpic(VALIDATE_INVITATION_TOKEN_ERROR, (action) => {
  window.scrollTo(0, 0);
  return {
    type: ERROR_FLASH_MESSAGE,
    payload: `The link has expired or is invalid, please check make sure the click you entered is valid. ${getError(
      action.payload
    )}`,
  };
});

export const acceptInvitationError = mappingEpic(ACCEPT_INVITATION_ERROR, (action) => {
  window.scrollTo(0, 0);
  return {
    type: ERROR_FLASH_MESSAGE,
    payload: getError(action.payload),
  };
});

export const catchApiErrorsEpic = (action$, store) => {
  return action$.pipe(
    ofType([CHECK_ACCOUNT_ERROR, REGISTRATION_POST_EVENT_ERROR]),
    filter((action) => action.payload?.response?.status === 403),
    map((action) => {
      return {
        type: ERROR_FLASH_MESSAGE,
        payload: 'There is already an account associated with this email. Please log in instead.',
      };
    })
  );
};

export const showSpinnerEpic = mappingEpic(
  [
    CHECK_ACCOUNT,
    SUBMIT_PROFILE,
    VALIDATE_INVITATION_TOKEN,
    CONTRACT_SUBMIT,
    REGISTRATION_POST_EVENT,
  ],
  SHOW_SPINNER
);

export const hideSpinnerEpic = mappingEpic(
  [
    CHECK_ACCOUNT_ERROR,
    SUBMIT_PROFILE_SUCCESS,
    SUBMIT_PROFILE_ERROR,
    VALIDATE_INVITATION_TOKEN_SUCCESS,
    VALIDATE_INVITATION_TOKEN_ERROR,
    CREATE_SESSION_SUCCESS,
    CONTRACT_SUBMIT_ERROR,
    CONTRACT_SUBMIT_SUCCESS,
    REGISTRATION_POST_EVENT_ERROR,
    REGISTRATION_POST_EVENT_SUCCESS,
  ],
  HIDE_SPINNER
);
