import React, { useEffect } from "react";
import { connect } from "react-redux";
import { getJobsAvailable } from "../../../redux/ducks/JobsAvailable";
import { useParams } from "react-router-dom";
import DownloadIcons from "../../../components/DownloadIcons";

const AvailableJobsList = ({ jobs, getJobs }) => {
  const { city, state } = useParams();

  useEffect(() => {
    if (state) getJobs({ state, city });
  }, [getJobs, state, city]);
  if (jobs.length === 0) return null;

  return (
    <>
      <h4 className="currentJobsHeader">{`Current Jobs on the Go4 platform in ${city}, ${state}`}</h4>
      <ul className="jobs">
        {jobs.map((j) => (
          <li key={j.id}>
            <p>
              <span>{`${j.sport} ${j.eventType} `}</span>
              {j.rate}
            </p>
            <p>{`${j.address} ${j.addressLine2}`}</p>
            <p>{j.startTime}</p>
            <div className="divider" />
          </li>
        ))}
      </ul>

      <p className="downloadInstructions">
        To see more Athletic Trainer jobs in <span>{`${city} ${state}`}</span>,
        by clicking the link below to download the app.
      </p>
      <DownloadIcons className="jobState" />
    </>
  );
};

function mapStateToProps({ jobsAvailable }) {
  return {
    jobs: jobsAvailable.availableJobs,
  };
}

function mapDisaptchToProps(dispatch) {
  return {
    getJobs: (payload) => dispatch(getJobsAvailable(payload)),
  };
}
export default connect(mapStateToProps, mapDisaptchToProps)(AvailableJobsList);
