import { AthleticTrainerSerializer } from 'services/serializers/v2';
import ApiService from '../ApiService';

class AthleticTrainerService extends ApiService {
  constructor(session) {
    super(session);

    this.url = '/v2/athletic_trainers';
  }

  getAT(id) {
    return super.get(`${this.url}/${id}.json`,
      (response) => {
        return AthleticTrainerSerializer.deserialize(response)
      }
    )
  }

  getATStatistics(id) {
    return super.get(`${this.url}/${id}/statistics.json`,
      (response) => response)
  }
}

export default AthleticTrainerService;
