import { ofType } from 'redux-observable';

import { of } from 'rxjs/observable/of';
import { catchError, mergeMap, map, debounceTime } from 'rxjs/operators';

import { basicEpic, mappingEpic } from 'redux/ducks/helpers';

import * as actions from './index';

import { SHOW_SPINNER, HIDE_SPINNER } from 'redux/ducks/LoadingSpinner';

import MedicalRecordWaiverService from 'services/MedicalRecordWaiverService'

// Epics
// ========================================================
export function getAllMedicalRecordWaivers(action$, store) {
  return action$.pipe(
    ofType(actions.GET_ALL_MEDICAL_RECORD_WAIVERS),
    debounceTime(200),
    mergeMap(action => new MedicalRecordWaiverService(store.getState().session).getAll()),
    map((medicalRecordWaivers) => {
      return { type: actions.GET_ALL_MEDICAL_RECORD_WAIVERS_SUCCESS, payload: medicalRecordWaivers };
    }),
    catchError((error) => {
      return of({
        type: actions.GET_ALL_MEDICAL_RECORD_WAIVERS_ERROR,
        payload: error,
      });
    })
  )
}

export function getMedicalRecordUrlWaivers(action$, store) {
  return action$.pipe(
    ofType(actions.GET_MEDICAL_RECORD_WAIVER_SIGN_URL),
    debounceTime(200),
    mergeMap(action => new MedicalRecordWaiverService(store.getState().session).sign(action.payload)),
    map((medicalRecordWaiver) => {
      return { type: actions.GET_MEDICAL_RECORD_WAIVER_SIGN_URL_SUCCESS, payload: medicalRecordWaiver };
    }),
    catchError((error) => {
      return of({
        type: actions.GET_MEDICAL_RECORD_WAIVER_SIGN_URL_ERROR,
        payload: error,
      });
    })
  )
}

export function checkSignedMedicalRecord(action$, store) {
  return action$.pipe(
    ofType(actions.CHECK_SIGNED_MEDICAL_RECORD_WAIVER),
    debounceTime(200),
    mergeMap(action => new MedicalRecordWaiverService(store.getState().session).checkSigned(action.payload)),
    map((url) => {
      return { type: actions.CHECK_SIGNED_MEDICAL_RECORD_WAIVER_SUCCESS, payload: url };
    }),
    catchError((error) => {
      return of({
        type: actions.CHECK_SIGNED_MEDICAL_RECORD_WAIVER_ERROR,
        payload: error,
      });
    })
  )
}

export const showSpinnerEpic = mappingEpic(
  [
    actions.GET_ALL_MEDICAL_RECORD_WAIVERS,
    actions.GET_MEDICAL_RECORD_WAIVER_SIGN_URL,
    actions.CHECK_SIGNED_MEDICAL_RECORD_WAIVER
  ],
  SHOW_SPINNER
)

export const hideSpinnerEpic = mappingEpic(
  [
    actions.GET_ALL_MEDICAL_RECORD_WAIVERS_SUCCESS,
    actions.GET_ALL_MEDICAL_RECORD_WAIVERS_ERROR,
    actions.GET_MEDICAL_RECORD_WAIVER_SIGN_URL_SUCCESS,
    actions.GET_MEDICAL_RECORD_WAIVER_SIGN_URL_ERROR,
    actions.CHECK_SIGNED_MEDICAL_RECORD_WAIVER_SUCCESS,
    actions.CHECK_SIGNED_MEDICAL_RECORD_WAIVER_ERROR,
  ],
  HIDE_SPINNER
)