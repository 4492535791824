import React from 'react';
import moment from 'moment';
import { uniq } from 'lodash';
import { rateTypeNameFinder, isRateType } from 'helpers/rateTypeName';
import { ZONE_ABBREVIATIONS, MILITARY_FORMAT } from 'helpers/datetime';

const ShiftSummary = (props) => {
  const { shift } = props;
  const rateTypeName = rateTypeNameFinder(props.rateTypes, shift.rateTypeId);

  const showRate = () => {
    const { rateTypeId, payRate } = shift;

    return props.editMode || (isRateType(rateTypeId) && Number(payRate) > 0);
  };

  return (
    <React.Fragment>
      <div className="shift-summary-mobile">
        <div>
          DATE:{' '}
          <p>
            {uniq([shift.startDate, shift.endDate])
              .map((date) => moment(date, MILITARY_FORMAT).format('MM/DD/YY'))
              .join(' - ')}
          </p>
        </div>
        <div>
          TIME:{' '}
          <p>
            {moment(shift.start, 'HH:mm').format('h:mm A')}
            {' - '}
            {moment(shift.end, 'HH:mm').format('h:mm A')} {ZONE_ABBREVIATIONS[props.timezone]}
          </p>
        </div>
        <div>
          ATs NEEDED: <p>{shift.capacity}</p>
        </div>
        {showRate() && (
          <div>
            RATE:{' '}
            <p>
              ${shift.payRate} / {rateTypeName === 'flat' ? 'fixed' : rateTypeName}
            </p>
          </div>
        )}
      </div>
      <table className="nimbus-regular-font">
        <thead>
          <tr>
            <th>DATE</th>
            <th>TIME</th>
            <th>ATs NEEDED</th>
            {showRate() && <th>RATE</th>}
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>
              {uniq([shift.startDate, shift.endDate])
                .map((date) => moment(date, MILITARY_FORMAT).format('MM/DD/YY'))
                .join(' - ')}
            </td>
            <td>
              {moment(shift.start, 'HH:mm').format('h:mm A')}
              {' - '}
              {moment(shift.end, 'HH:mm').format('h:mm A')} {ZONE_ABBREVIATIONS[props.timezone]}
            </td>
            <td>{shift.capacity}</td>
            {showRate() && (
              <td>
                ${shift.payRate} / {rateTypeName === 'flat' ? 'fixed' : rateTypeName}
              </td>
            )}
          </tr>
        </tbody>
      </table>
    </React.Fragment>
  );
};

export default ShiftSummary;
