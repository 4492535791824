//@flow
import moment from 'moment-timezone';
import zipcode_to_timezone from 'zipcode-to-timezone';
import validator from 'validator'

import { trim } from 'lodash';
import type { ShiftFormType, ReportToContact } from '.';
/* eslint radix: "off" */

// Validators
// -------------------------------

function startTimeIsAfterEndTime(shift) {
  const parsedStart = moment(shift.start);
  const parsedEnd = moment(shift.end);
  return parsedStart >= parsedEnd;
}

function dateTimeIsInvalid(time) {
  return !time || !moment(time)
}

function shiftTimeHasPassed(shift) {
  let timeZone = zipcode_to_timezone.lookup(shift.eventLocation?.address.zipCode);

  let momentInstance;
  if (timeZone) {
    momentInstance = moment().tz(timeZone);
  } else {
    momentInstance = moment().tz(moment.tz.guess());
  }

  return !shift.id &&
    moment(shift.start).isBefore(momentInstance, 'minute');
}

const dateHasWrongFormat = (date) => !(/[0-9]{4}-[0-9]{2}-[0-9]{2}/.test(date));

function shiftCapacityIsInvalid(shift) {
  return !shift.capacity || shift.capacity < 1;
}


function validateReportToContact(reportToContact: ReportToContact) {
  if (reportToContact._destroy) {
    return {};
  }
  let messages = {};
  if (!trim(reportToContact.name)) {
    messages.name = 'Contact name is required';
  }
  if (!trim(reportToContact.email)) {
    messages.email = 'Contact email is required';
  }
  if (!trim(reportToContact.phoneNumber)) {
    messages.phone = 'Contact phone is required';
  } else if (!validator.isMobilePhone(reportToContact.phoneNumber)) {
    messages.phone = 'Phone number is not valid';
  }
  return messages;
}

export const addErrorsToShift = (shift: ShiftFormType) => {
  let messages = {};
  if (!trim(shift.eventLocation.name)) {
    messages.name = 'Venue Name is required';
  }
  if (!(shift.eventLocation.id || shift.eventLocation.placeId)) {
    messages.address = 'You must select a place from the list of results'
  }
  if (!trim(shift.eventLocation.formattedAddress)) {
    messages.address = 'Address is required';
  }
  // TODO(cosmin): Fix this!
  //if (!trim(shift.countValue)) {
  //  messages.countValue = 'Number of Playing Surfaces is required';
  //}
  if (shiftCapacityIsInvalid(shift)) {
    messages.capacity = 'You need at least one AT per shift';
  }
  if (startTimeIsAfterEndTime(shift)) {
    messages.start = 'Start Time must be before End Time';
    messages.end = 'Start Time must be before End Time';
  }
  if (dateTimeIsInvalid(shift.start)) {
    messages.start = 'Start Time is invalid';
  }
  if (dateTimeIsInvalid(shift.end)) {
    messages.end = 'End Time is invalid';
  }
  if (shiftTimeHasPassed(shift)) {
    messages.start = 'New shifts must be in the future';
  }
  let reportToContactsErrors = []
  let hasError = false;
  for (let i = 0; i < shift.reportToContacts.length; ++i) {
    let error = validateReportToContact(shift.reportToContacts[i]);
    reportToContactsErrors.push(error);
    if (Object.keys(error).length != 0) {
      hasError = true;
    }
  }
  if (hasError) {
    messages.reportToContacts = reportToContactsErrors;
  }
  // if (trim(shift.schedule) && (!trim(shift.approxHours) || shift.approxHours <= 0)) {
  //   messages.approxHours = 'Approx hours is required'
  // }
  // if (trim(shift.approxHours) && !trim(shift.schedule)) {
  //   messages.schedule = 'Schedule is required'
  // }
  return {
    ...shift,
    errorMessages: messages
  };
}