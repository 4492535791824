// Actions
// ========================================================
export const FETCH = "go4ellis/Blog/FETCH";
export const SUCCESS = "go4ellis/Blog/SUCCESS";
export const SEARCH = "go4ellis/Blog/SEARCH";
export const FAIL = "go4ellis/Blog/FAIL";

// Reducer Functions
// ========================================================
function fetchReducer(state) {
  return {
    ...state,
    loading: true,
  };
}
function successReducer(state, payload) {
  const posts = [...(state.data.length ? state.data : []), ...payload.posts];
  return {
    ...state,
    loading: false,
    data: posts,
    filteredBlogs: posts,
    error: undefined,
    nextPageToken: payload.nextPageToken,
  };
}
function searchReducer(state, payload) {
  const match = new RegExp(payload, "gi");
  console.log(match);
  return {
    ...state,
    filteredBlogs: state.data.filter((b) => b.content && match.test(b.content)),
  };
}
function failReducer(state, error) {
  return {
    ...state,
    data: [],
    filteredBlogs: [],
    loading: false,
    error,
  };
}

// Action Creators
// ========================================================
export function fetchBlogs() {
  return { type: FETCH };
}
export function searchBlogs(payload) {
  return { type: SEARCH, payload };
}
export function blogsSuccess(payload) {
  return { type: SUCCESS, payload };
}
export function blogsFail(error) {
  return { type: FAIL, error };
}

// Reducer
// ========================================================
const DEFAULT_STATE = { data: [] };

export default function reducer(state = DEFAULT_STATE, action = {}) {
  switch (action.type) {
    case FETCH:
      return fetchReducer(state);
    case SUCCESS:
      return successReducer(state, action.payload);
    case SEARCH:
      return searchReducer(state, action.payload);
    case FAIL:
      return failReducer(state, action.error);
    default:
      return state;
  }
}
