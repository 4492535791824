// Actions
// ========================================================
export const DISMISS = "go4ellis/Flash/DISMISS";
export const ERROR_FLASH_MESSAGE = "go4ellis/Flash/ERROR_FLASH_MESSAGE";
export const SUCCESS_FLASH_MESSAGE = "go4ellis/Flash/SUCCESS_FLASH_MESSAGE";

// Reducer Functions
// ========================================================
function dismissReducer(state) {
  return {
    ...DEFAULT_STATE,
  };
}

function errorReducer(state, message) {
  return {
    ...state,
    message,
    type: "error",
  };
}

function successReducer(state, { message, scrollIntoView }) {
  return {
    ...state,
    message,
    scrollIntoView,
    type: "success",
  };
}

// Action Creators
// ========================================================
export function dismiss() {
  return { type: DISMISS };
}

export function error(message) {
  return { type: ERROR_FLASH_MESSAGE, payload: message };
}

export function success(message, scrollIntoView) {
  return { type: SUCCESS_FLASH_MESSAGE, payload: { message, scrollIntoView } };
}

// Reducer
// ========================================================
export const DEFAULT_STATE = {
  message: "",
  type: "",
};

export default function reducer(state = DEFAULT_STATE, action = {}) {
  switch (action.type) {
    case DISMISS:
      return dismissReducer(state);
    case ERROR_FLASH_MESSAGE:
      return errorReducer(state, action.payload);
    case SUCCESS_FLASH_MESSAGE:
      return successReducer(state, action.payload);
    default:
      return state;
  }
}
