export const rateTypeNameFinder = (rateTypes, item) => {
  let rateTypeId;
  if (rateTypes && rateTypes.length > 0) {
    if (typeof item === 'number') {
      rateTypeId = item;
    } else if (typeof item === 'string') {
      rateTypeId = parseInt(item);
    } else if (typeof item === 'object') {
      rateTypeId = item.rateTypeId;
    } else {
      return '';
    }
    let rateType = rateTypes.filter((e) => e.id == rateTypeId)[0];

    if (rateType && rateType.name.toLowerCase() === 'flat') {
      return 'fixed';
    }

    return rateType ? rateType.name : '';
  }
  return '';
};

export const isRateType = (rateTypeId) => {
  return Number.isInteger(parseInt(rateTypeId));
};
