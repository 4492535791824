import React, { Component } from 'react';

import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter
} from 'reactstrap';

class ConfirmationModal extends Component {

  state = {
    open: false,
  };

  onConfirm = () => {
    this.props.onConfirm();
    this.toggle();
  };

  toggle = () => {
    this.setState({ open: !this.state.open });
  };

  onCancel = () => {
    this.props.onCancel();
    this.toggle();
  }

  chooseStyle = () => {
    if (!this.props.canCancel) return {};

    return {
      justifyContent: 'space-between',
      paddingRight: '40px',
      paddingLeft: '40px'
    }
  }

  render() {
    return (
      <div className="confirmationModal">
        <button className='modal-button plain-button' onClick={this.toggle}>{this.props.children}</button>
        <Modal isOpen={this.state.open} toggle={this.toggle} className='confirmation-modal-content'>
          <ModalHeader toggle={this.toggle}>
            {this.props.title || 'Are you sure?'}
          </ModalHeader>
          <ModalBody>
            {this.props.body}
          </ModalBody>
          <ModalFooter style={this.chooseStyle()}>
            {this.props.canCancel && <button className='btn btn-link link-button' onClick={this.onCancel}>
              {this.props.cancelText || 'Cancel'}
            </button>}
            <button className='btn btn-danger bold-button' onClick={this.onConfirm}>
              {this.props.confirmText || 'Confirm'}
            </button>
          </ModalFooter>
        </Modal>
      </div>
    );
  }
}

export default ConfirmationModal;