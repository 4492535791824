import EditJobModal from 'components/V2/EditJobModal';
import moment from 'moment-timezone';
import React, { Component } from 'react';
import ExperienceModal from 'scenes/Dashboard/V2/ExperienceModal/ExperienceModal';
import { approveAthleticTrainer, decline, removeJob } from 'redux/ducks/Job';
import { getAthleticTrainer } from 'redux/ducks/User';
import { connect } from 'react-redux';
import { getTimezone } from 'helpers/post';
import ConfirmationModal from 'components/V2/ConfirmationModal';
import classNames from 'classnames';
import { hasToVerifyMicroDeposits } from 'helpers/payment';
import { MILITARY_FORMAT } from 'helpers/datetime';
import { get } from 'lodash';
import { withRouter } from 'react-router-dom';
import { UncontrolledTooltip } from 'reactstrap';
import { features, isEnabledFeature } from 'helpers/feature_flags';
const STATUS_PANEL = {
  pending: {
    name: 'APPLIED',
    statusColor: 'black',
  },
  eo_accepted_at: {
    name: 'PENDING CONFIRMATION',
    statusColor: 'black',
  },
  confirmed: {
    name: 'CONFIRMED',
    statusColor: 'teal',
  },
  checked_in: {
    name: 'CHECKED IN',
    statusColor: 'teal',
  },
  waitlist: {
    name: 'WAITLIST',
    statusColor: 'black',
  },
  callout: {
    name: 'CALLOUT',
    statusColor: 'grey',
  },
  decommitted: {
    name: 'CALLOUT',
    statusColor: 'grey',
  },
  paid: {
    name: 'PAID',
    statusColor: 'black',
  },
  backout: {
    name: 'WITHDRAWN',
    statusColor: 'black',
  },
  callout_shift_change: {
    name: 'CALLOUT',
    statusColor: 'grey',
  },
};

const DISPLAY_BUTTONS = {
  pending: ['accept', 'decline'],
  eo_accepted_at: ['remove'],
  confirmed: ['edit', 'cancel'],
  checked_in: ['edit'],
  waitlist: ['remove'],
  callout: [],
  paid: [],
  decommitted: [],
  backout: [],
};

const MAP_BUTTON_NAME = {
  accept: {
    buttonText: 'ACCEPT',
    color: 'teal',
  },
  decline: {
    buttonText: 'DECLINE',
    color: 'grey',
  },
  edit: {
    buttonText: 'EDIT JOB',
    color: 'teal',
  },
  remove: {
    buttonText: 'REMOVE',
    color: 'grey',
  },
  cancel: {
    buttonText: 'CANCEL',
    color: 'grey',
  },
};

export const getStateStatus = (state) => {
  return STATUS_PANEL[state] || { name: 'UNKNOWN', statusColor: 'grey' };
};

class JobActions extends Component {
  constructor(props) {
    super(props);
    this.state = {
      openEditJob: false,
      openDeclineModal: false,
      openRemoveModal: false,
      openCancelModal: false,
      verifyPaymentModal: false,
    };
  }

  toggleEditJob = () => {
    this.setState((prevState) => ({
      ...prevState,
      openEditJob: !this.state.openEditJob,
    }));
  };

  toggleVerifyPaymentModal = () => {
    this.setState((prevState) => ({
      ...prevState,
      verifyPaymentModal: !this.state.verifyPaymentModal,
    }));
  };

  toggleDeclineModal = () => {
    this.setState((prevState) => ({
      ...prevState,
      openDeclineModal: !this.state.openDeclineModal,
    }));
  };

  toggleRemoveModal = () => {
    this.setState((prevState) => ({
      ...prevState,
      openRemoveModal: !this.state.openRemoveModal,
    }));
  };

  toggleCancelModal = () => {
    this.setState((prevState) => ({
      ...prevState,
      openCancelModal: !this.state.openCancelModal,
    }));
  };

  onApprove = (job) => {
    this.props.approveTrainer(job);
  };

  onDecline = (id) => {
    this.props.decline(id);
  };

  onRemove = (id) => {
    this.props.remove(id);
  };

  buttonAction = (button) => () => {
    const { job, payment } = this.props;

    switch (button) {
      case 'accept':
        if (hasToVerifyMicroDeposits(payment) && !get(payment, 'source.id')) {
          this.toggleVerifyPaymentModal();
          break;
        }

        this.onApprove(job);
        break;
      case 'decline':
        this.toggleDeclineModal();
        break;
      case 'edit':
        this.toggleEditJob();
        break;
      case 'remove':
        this.toggleRemoveModal();
        break;
      case 'cancel':
        this.toggleCancelModal();
        break;
      default:
        break;
    }
  };

  getAtTById = (ATId) => {
    const { pendingApplicants } = this.props;
    const ATIndex = pendingApplicants.findIndex((at) => at.id === ATId);

    if (ATIndex !== -1) return;

    this.props.getAthleticTrainer(ATId);
  };

  goToVerifyPayment = () => {
    this.props.history.push('/edit-payment');
  };

  render() {
    const { job, pendingApplicants, shift, jobsMetadata, currentUser } = this.props;
    const { user } = job;
    const buttons = DISPLAY_BUTTONS[job.currentState] || [];
    const tz = getTimezone(shift.eventLocation);
    const notPassedShift = moment(shift.endTime).isAfter(moment());
    const canModifyJob = moment(job.endTime, MILITARY_FORMAT).add(12, 'hours').isAfter(moment());
    const acceptDisabled = get(jobsMetadata, `[${job.id}].overlappingWithAnotherJob`, null);
    const canDoAction = job.currentState === 'pending';
    return (
      <React.Fragment>
        <div className={classNames('button-cell')}>
          <ExperienceModal
            onClick={() => this.getAtTById(user.id)}
            onApprove={() => this.onApprove(job)}
            onDecline={() => this.onDecline(job.id)}
            onRemove={() => this.onRemove(job.id)}
            id={user.id}
            pendingApplicants={pendingApplicants}
            from={this.props.from}
            canDoAction={canDoAction}
            job={job}
            acceptDisabled={acceptDisabled}
          >
            VIEW PROFILE
          </ExperienceModal>
        </div>
        <div className={classNames('button-cell')}>
          {buttons.includes('accept') && notPassedShift && (
            <button
              id={`accept-${job.id}`}
              key={`key-button-accept`}
              className={classNames(
                `plain-button action-button ${MAP_BUTTON_NAME['accept'].color}`,
                {
                  disabled: acceptDisabled,
                }
              )}
              onClick={this.buttonAction('accept')}
              disabled={acceptDisabled}
            >
              {MAP_BUTTON_NAME['accept'].buttonText}
            </button>
          )}
          {acceptDisabled && (
            <UncontrolledTooltip target={`accept-${job.id}`}>
              You already accepted {user.name} for a shift during this time.
            </UncontrolledTooltip>
          )}
        </div>
        <div className={classNames('button-cell')}>
          {buttons.includes('edit') && canModifyJob && (
            <EditJobModal
              {...this.props}
              key={job.id}
              job={job}
              tz={tz}
              user={user}
              isOpen={this.state.openEditJob}
              toggleEditJob={this.toggleEditJob}
              childTextColor={MAP_BUTTON_NAME['accept'].color}
              location={shift.eventLocation}
            >
              <span onClick={this.props.onEdit}>{MAP_BUTTON_NAME['edit'].buttonText}</span>
            </EditJobModal>
          )}
        </div>
        <div className={classNames('button-cell')}>
          {buttons.includes('decline') && notPassedShift && (
            <button
              key={`key-button-decline`}
              className={`plain-button action-button ${MAP_BUTTON_NAME['decline'].color}`}
              onClick={this.buttonAction('decline')}
            >
              {MAP_BUTTON_NAME['decline'].buttonText}
            </button>
          )}
          {buttons.includes('remove') && notPassedShift && (
            <button
              key={`key-button-remove`}
              className={`plain-button action-button ${MAP_BUTTON_NAME['remove'].color}`}
              onClick={this.buttonAction('remove')}
            >
              {MAP_BUTTON_NAME['remove'].buttonText}
            </button>
          )}
          {buttons.includes('cancel') && notPassedShift && (
            <button
              key={`key-button-cancel`}
              className={`plain-button action-button ${MAP_BUTTON_NAME['cancel'].color}`}
              onClick={this.buttonAction('cancel')}
            >
              {MAP_BUTTON_NAME['cancel'].buttonText}
            </button>
          )}
        </div>
        <ConfirmationModal
          isOpen={this.state.openDeclineModal}
          toggle={this.toggleDeclineModal}
          title="Decline Application"
          body={`Are you sure you want to decline this application from ${user.firstName} ${user.lastName}?`}
          onSave={() => this.onDecline(job.id)}
          cancelText="Go Back"
          confirmText="Decline"
          onCancel={this.toggleDeclineModal}
        />
        <ConfirmationModal
          isOpen={this.state.openRemoveModal}
          toggle={this.toggleRemoveModal}
          title="Remove Application"
          body={`Are you sure you want to remove this application from ${user.firstName} ${user.lastName}?`}
          onSave={() => this.onRemove(job.id)}
          cancelText="Go Back"
          confirmText="Remove"
          onCancel={this.toggleRemoveModal}
        />
        <ConfirmationModal
          isOpen={this.state.openCancelModal}
          toggle={this.toggleCancelModal}
          title="Confirm Cancellation"
          body={
            <React.Fragment>
              <p>{`Are you sure you want to cancel ${user.firstName} ${user.lastName} from working this shift?`}</p>
              {job.currentState === 'confirmed' &&
                isEnabledFeature(features.CANCELATION_POLICY, currentUser) && (
                  <p>
                    <span style={{ fontWeight: 'bold', color: 'red' }}>Warning:</span>
                    {` If you remove this athletic trainer you will be charged per the `}
                    <a
                      href="https://www.go4.io/go4-marketplace-standards/"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Go4 Cancellation Policy
                    </a>
                    .
                  </p>
                )}
            </React.Fragment>
          }
          onSave={() => this.onRemove(job.id)}
          cancelText="Go Back"
          confirmText="Cancel"
          onCancel={this.toggleCancelModal}
        />
        <ConfirmationModal
          isOpen={this.state.verifyPaymentModal}
          toggle={this.toggleVerifyPaymentModal}
          title="Verify Payment Source"
          body={`You must verify your bank account with micro deposits before accepting applicants.`}
          onSave={this.goToVerifyPayment}
          cancelText="Cancel"
          confirmText="Verify"
          onCancel={this.toggleVerifyPaymentModal}
        />
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  payment: state.payment,
  jobsMetadata: state.dashboard.applicants.jobsMetadata,
  currentUser: state.session.currentUser,
});

const mapDispatchToProps = (dispatch) => ({
  approveTrainer: (job) => dispatch(approveAthleticTrainer(job)),
  decline: (id) => dispatch(decline(id)),
  remove: (id) => dispatch(removeJob(id)),
  getAthleticTrainer: (id) => dispatch(getAthleticTrainer(id)),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(JobActions));
