class PasswordSerializer {
  static deserialize(response) {
    return {
      success: response.success,
      data: response.data,
      message: response.message
    }
  }

  static deserializeHeaders(headers) {
    const result = {
      accessToken: headers['access-token'],
      client: headers.client,
      clientId: headers.client_id,
      config: headers.config,
      resetPassword: headers.reset_password,
      token: headers.token,
      uid: headers.uid,
    };

    return result;
  }

  static serialize(password, confirmation) {
    const result = {
      password,
      password_confirmation: confirmation
    };

    return result;
  }

  static serializeReset(email) {
    return {
      email,
      redirect_url: `${process.env.REACT_APP_FRONTEND_DOMAIN}/v2/reset-password`
    }
  }
}

export default PasswordSerializer;