import React, { Component } from 'react';
import { parse, stringify } from 'query-string';

class ChangePasswordScene extends Component {

  componentWillMount() {
    document.body.classList.add('change-password');
  }

  componentWillUnmount() {
    document.body.classList.remove('change-password');
  }

  render() {
    const session = parse(this.props.location.search);
    const url = session.deep_link;
    session.deep_link = undefined;
    return (
      <div className="changePassword">
        <h1>Change your password!</h1>
        <p>Please open the following link on your mobile device.</p>
        <a href={`${url}?${stringify(session)}`} className="btn btn-primary px-4">
          Change password
        </a>
      </div>
    );
  }
}

export default ChangePasswordScene;