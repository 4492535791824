import React from "react";
import RedHomeButton from "../RedHomeButton";
import appStore from "app-store.svg";
import googlePlayStore from "google-play.svg";
import redDownloadArrow from "red-download-arrow.svg";

const DownLoadApp = ({ noButton, tealBackground, darkBackground, isEMR }) => {
  return (
    <section
      className={`downLoadApp ${darkBackground ? "darkBackground" : ""} ${
        tealBackground ? "tealBackground" : ""
      } ${noButton ? "noButton" : ""}`}
    >
      <div className={`text-with-arrow${isEMR ? " emr" : ""}`}>
        <span>Download The App</span>
        {isEMR && (
          <p>
            And start using the{" "}
            <span className="text-nowrap">EMR RIGHT NOW!</span>
          </p>
        )}
      </div>
      <div className={`download-icons${isEMR ? " emr" : ""}`}>
        <a href="https://itunes.apple.com/st/app/go4ellis/id1186370661?mt=8">
          <img alt="app store" src={appStore} />
        </a>
        <a href="https://play.google.com/store/apps/details?id=com.go4ellis&hl=en_US">
          <img alt="google play store" src={googlePlayStore} />
        </a>
        {isEMR && (
          <img
            className="downloadArrow"
            src={redDownloadArrow}
            alt="download-arrow"
          />
        )}
      </div>
      {!noButton && (
        <div className="learn-more">
          <RedHomeButton
            text="Learn More"
            asLink
            to="/athletic-trainer/"
            className="skinner"
          />
        </div>
      )}
    </section>
  );
};

export default DownLoadApp;
