// @flow
import React, { Component } from 'react';
import { startsWith } from 'lodash';

const domain = process.env.REACT_APP_DOMAIN;

class UploadEAPForm extends Component<Object, Object> {
  onFileChange = (ev: SyntheticEvent<HTMLInputElement>) => {
    const file = ev.currentTarget.files[0];
    this.props.onNewFile(file);
  }

  urlFor(previewUrl: string) {
    return startsWith(previewUrl, '/') ? domain + previewUrl : previewUrl;
  }

  render() {
    return (
      <div className="uploadEAPForm">
        <div className="section-header col">Upload Custom Emergency Action Plan</div>
        <section>
          <div className="row">
            <div className="col-md-4">
              <section>
                <input onChange={this.onFileChange} type="file" name="uploaded_pdf" className="custom-file-input" accept="image/*,application/pdf" />
                <label className="btn btn-primary custom-file-label" htmlFor="uploaded_pdf">Upload New File</label>
              </section>
            </div>
            <div className="col-md-8 text-center">
              {this.props.filePreview && <object className="pdf-preview" data={this.urlFor(this.props.filePreview)} alt="A preview of the new plan" >
                <p>Looks like you can't view this file. <a target="_blank" href={this.urlFor(this.props.filePreview)}>Click here to download.</a></p>
              </object>}
            </div>
          </div>
        </section>
      </div>
    );
  }
}

export default UploadEAPForm;