import React, { Component } from 'react';
import { groupBy, map } from 'lodash';
import moment from 'moment';

import './ShiftDetailsTableMobile.scss'

class ShiftDetailsTableMobile extends Component {
  formattedTime(time) {
    return moment(time).format('M/D/Y LT');
  }
  render() {
    const { shifts } = this.props;
    return (
      <div className="shiftDetailsTableMobile">
        {shifts.map((shift, idx) =>
          <div className="item">
            <p>
              <h6>Time:</h6>
              {this.formattedTime(shift.start)} - {this.formattedTime(shift.end)}
            </p>
            {shift.schedule && <p>
              <h6>Schedule:</h6>
              <span>{shift.schedule}</span>
            </p>}
            <p>
              <h6>Location: </h6>
              {shift.eventLocation.formattedAddress.replace(', USA', '')}
              <br />
              {shift.eventLocation.name}{shift.eventLocation.details && ` / ${shift.eventLocation.details}`}
            </p>
            <p>
              <h6>Staff Needed: </h6>
              {shift.capacity}
            </p>
            <p>
              <h6>Emergency Action Plan: </h6>
              {shift.emergencyActionPlanId ? 'Complete' : <span className="text-danger">Not Optimized</span>}
            </p>
          </div>
        )}
      </div>
    );
  }
}

export default ShiftDetailsTableMobile;