import React from 'react';

const ContractsPayRange = ({ details, rateTypes, onFieldChange }) => {
  const onMinTotalPayChange = (e) => {
    if (details.rateTypeId === '1') {
      onFieldChange('payRate')(e);
    }

    onFieldChange('minTotalPay')(e);
  };

  return (
    <div className="row pt-2">
      <div className="col-md-3">
        <div>
          <div>
            <label htmlFor="rate_type">Please select a Pay Rate</label>
          </div>
          <div className="custom-control custom-radio custom-control-inline">
            <input
              className="custom-control-input"
              id="HourlyRateType"
              type="radio"
              name="rate_type"
              value="0"
              checked={details.rateTypeId === '0'}
              onChange={onFieldChange('rateTypeId')}
            />
            <label htmlFor="HourlyRateType" className="custom-control-label radio-inline">
              Hourly Rate
            </label>
          </div>
          <div className="custom-control custom-radio custom-control-inline">
            <input
              className="custom-control-input"
              id="GameRateType"
              type="radio"
              name="rate_type"
              value="1"
              checked={details.rateTypeId === '1'}
              onChange={onFieldChange('rateTypeId')}
            />
            <label htmlFor="GameRateType" className="custom-control-label radio-inline">
              Salary
            </label>
          </div>
        </div>
      </div>
      {details.rateTypeId === '0' && (
        <>
          <div className="form-group col-md-3">
            <label htmlFor="pay_rate">Minimum Pay Rate</label>
            <div className="input-group mb-3">
              <div className="input-group-prepend">
                <span className="input-group-text">$</span>
              </div>
              <input
                className="form-control"
                type="number"
                name="pay_rate"
                min="1"
                max="100000"
                step="1"
                aria-label="Amount (to the nearest dollar)"
                value={details.payRate}
                placeholder="0"
                onChange={onFieldChange('payRate')}
                required
              />
              <div className="invalid-feedback">
                Minimum Pay rate is required to be between $0-$100,000
              </div>
            </div>
          </div>
          <div className="form-group col-md-3">
            <label htmlFor="max_pay_rate">Maximum Pay Rate</label>
            <div className="input-group mb-3">
              <div className="input-group-prepend">
                <span className="input-group-text">$</span>
              </div>
              <input
                className="form-control"
                type="number"
                name="max_pay_rate"
                min="1"
                max="100000"
                step="1"
                aria-label="Amount (to the nearest dollar)"
                value={details.maxPayRate}
                placeholder="0"
                onChange={onFieldChange('maxPayRate')}
                required
              />
              <div className="invalid-feedback">
                Maximum Pay rate is required to be between $0-$100,000
              </div>
            </div>
          </div>
        </>
      )}
      {details.rateTypeId === '1' && (
        <>
          <div className="form-group col-md-3">
            <label htmlFor="pay_rate">Minimum Total Pay</label>
            <div className="input-group mb-3">
              <div className="input-group-prepend">
                <span className="input-group-text">$</span>
              </div>
              <input
                className="form-control"
                type="number"
                name="min_total_pay"
                min="1"
                max="100000"
                step="1"
                aria-label="Amount (to the nearest dollar)"
                value={details.minTotalPay}
                placeholder="0"
                onChange={onMinTotalPayChange}
                required
              />
              <div className="invalid-feedback">
                Minimum Total Pay is required to be between $0-$100,000
              </div>
            </div>
          </div>
          <div className="form-group col-md-3">
            <label htmlFor="pay_rate">Maximum Total Pay</label>
            <div className="input-group mb-3">
              <div className="input-group-prepend">
                <span className="input-group-text">$</span>
              </div>
              <input
                className="form-control"
                type="number"
                name="max_total_pay"
                min="1"
                max="100000"
                step="1"
                aria-label="Amount (to the nearest dollar)"
                value={details.maxTotalPay}
                placeholder="0"
                onChange={onFieldChange('maxTotalPay')}
                required
              />
              <div className="invalid-feedback">
                Maximum Total Pay is required to be between $0-$100,000
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default ContractsPayRange;
