import React, { useEffect, useRef } from 'react';
import { connect } from 'react-redux';
import { Collapse } from 'reactstrap';
import cx from 'classnames';
import { dismiss } from 'redux/ducks/Flash';

export const FlashMessage = ({
  onDismiss,
  message,
  noNavBar,
  type,
  withFixedHeader,
  scrollIntoView,
  onlyErrors,
}) => {
  const messageRef = useRef(null);
  useEffect(() => {
    if (withFixedHeader && (type === 'error' || scrollIntoView)) {
      messageRef.current.scrollIntoView({
        alignToTop: false,
        behavior: 'smooth',
      });
    }
  }, [withFixedHeader, type]);

  if (onlyErrors && type !== 'error') {
    return null;
  }

  return (
    <div className={`flashMessage ${withFixedHeader ? 'withFixedHeader' : ''}`}>
      <div className={cx('scroll-to', { noNavBar })} ref={messageRef} />
      <Collapse isOpen={type !== ''}>
        <div className={`message ${type}`}>
          <span className="message-text">{message}</span>
          <button className="flash-close" onClick={onDismiss}>
            <i className="fa fa-times" />
          </button>
        </div>
      </Collapse>
    </div>
  );
};

function mapStateToProps(state) {
  return {
    message: state.flash.message,
    type: state.flash.type,
    scrollIntoView: state.flash.scrollIntoView,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    onDismiss: () => dispatch(dismiss()),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(FlashMessage);
