import ApiService from './ApiService';

class TrackingService extends ApiService {
  constructor(session) {
    super(session);
    this.url = '/tracking';
  }

  track = (event) => {
    return super.post(this.url + '.json', event, (response) => response);
  };

  trackMixpanel = (event) => {
    return super.post(this.url + '/mixpanel.json', event, (response) => response);
  };
}

export default TrackingService;
