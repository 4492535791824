import React from "react";
import HomeList from "../HomeList";

const list = {
  covid: [
    "15 Minute Response",
    "Home Market Foods",
    "Quaker Oats",
    "AstraZeneca",
    "New York Hospitals",
    "Stacy's Pita Chip",
    "Frito-Lay Inc",
    "Pepsi",
    "Tesla",
    "Gatorade",
    "Pivot",
    "Wakefern Food",
  ],
  industrial: [
    "AstraZeneca",
    "New York Hospitals",
    "Stacy's Pita Chip",
    "Frito-Lay Inc",
    "Pepsi",
    "Tesla",
    "Gatorade",
    "Pivot",
    "Wakefern Food",
    "Home Market Foods",
    "Quaker Oats",
    "—",
  ],
  nurses: [
    "15 Minute Response",
    "Home Market",
    "Pop Warner",
    "US Club Soccer",
    "AstraZeneca",
    "New York Hospitals",
    "Quaker Oats",
    "US Lacrosse",
    "Boston University",
    "PA Rugby",
    "Red Bull 3x3",
    "US BMX",
    "Frito-Lay Inc",
    "Pepsi",
    "Stacy's Pita Chip",
    "USA Football",
    "Gatorade",
    "Pivot",
    "Tesla",
    "Wakefern Food",
  ],
  default: [
    "3D LACROSSE",
    "3STEP",
    "ADRENALINE LACROSSE",
    "ALOHA TOURNAMENTS",
    "AMERICAN JUNIOR GOLF ASSOCIATION",
    "BIG HOUSE SPORTS COMPLEX",
    "BIG SHOTS",
    "BOSTON UNIVERSITY",
    "BUTLER UNIVERSITY",
    "COLORADO SOCCER ASSOCIATION",
    "CORRIGAN SPORTS ENTERPRISES",
    "EDP SOCCER",
    "FLORIDA YOUTH SOCCER ASSOCIATION",
    "G3 PRO CAMPS",
    "GATORADE 5V5",
    "GLOBAL PREMIER SOCCER",
    "ILLINOIS RUGBY",
    "LEGACY GLOBAL",
    "MAIN LINE FIELD HOCKEY",
    "MINNESOTA YOUTH SOCCER",
    "MIT",
    "MYSTIX FIELD HOCKEY",
    "NATIONAL FLAG FOOTBALL",
    "NXT",
    "OREGON RUGBY",
    "PENN STATE UNIVERSITY",
    "PENNSYLVANIA RUGBY",
    "PETE & JAMEER NELSON FOUNDATION",
    "PHILADELPHIA YOUTH BASKETBALL",
    "POINT GUARD COLLEGE",
    "POP WARNER",
    "PRO FOOTBALL HALL OF FAME",
    "RED BULL 3X3 TOURNAMENTS",
    "STACK SPORTS",
    "THE CHICAGOLAND YOUTH FOOTBALL",
    "THE HOOP GROUP",
    "TK VOLLEYBALL",
    "TRILOGY LACROSSE",
    "UNIVERSITY OF FLORIDA",
    "UNIVERSITY OF MARYLAND",
    "US CLUB SOCCER",
    "US LACROSSE",
    "USA BMX",
    "USA FOOTBALL",
    "USA JUDO",
    "USA ROLLERSPORTS",
    "WOMEN'S FOOTBALL ALLIANCE",
    "ZERO GRAVITY",
  ],
};

const WhoUsesList = ({
  darkText,
  listKey = "default",
  redText,
  homeListClassName,
  withBorderTop,
  withLeftBorder,
  mobileSingleColumn,
}) => (
  <div
    className={`whoUsesList${darkText ? " darkText" : ""}${redText ? " redText" : ""
      }`}
  >
    <HomeList
      list={list[listKey]}
      uppercase
      className={homeListClassName}
      withBorderTop={withBorderTop}
      withLeftBorder={withLeftBorder}
      mobileSingleColumn={mobileSingleColumn}
    />
  </div>
);

export default WhoUsesList;
