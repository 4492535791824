import React from "react";
import appStore from "iOSAppStoreIcon.svg";
import googlePlayStore from "google-play-badge.svg";

const DownloadIcons = ({ className }) => (
  <div className={`downloadIcons ${className || ""}`}>
    <a href="https://itunes.apple.com/st/app/go4ellis/id1186370661?mt=8">
      <img alt="app store" src={appStore} />
    </a>
    <a href="https://play.google.com/store/apps/details?id=com.go4ellis&hl=en_US">
      <img alt="google play store" src={googlePlayStore} />
    </a>
  </div>
);

export default DownloadIcons;
