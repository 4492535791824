import React, { Component } from 'react';

import { connect } from 'react-redux';

import { injectStripe } from 'react-stripe-elements';

import PlaidLink from 'react-plaid-link';

import moment from 'moment';

import * as Sentry from '@sentry/browser';

import {
  updateCheckField,
  updatePlaidToken,
  validateCheckField,
  createPlaidAccessToken,
} from 'redux/ducks/Payment';

const thirteenYearsAgo =
  typeof jest === 'undefined' ? moment().subtract(13, 'years').format('MM/DD/YYYY') : '01/01/2001';

const plaid_public_key = process.env.REACT_APP_PLAID_PUBLIC_KEY;
const plaid_env = process.env.REACT_APP_PLAID_ENV;

export class Checking extends Component {
  state = {
    editing: true,
    DOBValid: false,
    DOBShowMsg: false,
    DOBErrorMsg: 'Invalid date input',
  };

  componentWillMount() {
    if (this.props.loadedSource && !this.props.loadingSource) {
      if (this.hasSource() && !this.props.publicToken) {
        this.setState({ ...this.state, editing: false });
      } else {
        this.setState({ ...this.state, editing: true });
      }
    }
  }

  componentDidUpdate(prevProps) {
    if (
      this.props.loadedSource &&
      !this.props.loadingSource &&
      prevProps.publicToken !== this.props.publicToken
    )
      this.setState({ ...this.state, editing: !!this.props.publicToken });
  }

  hasSource = () => {
    return this.props.source.id && this.props.source.object === 'bank_account';
  };

  onFieldBlur = (field) => {
    if (field === 'dateOfBirth') {
      if (this.state.DOBValid) {
        this.setState({ ...this.state, DOBShowMsg: false });
      } else {
        this.setState({ ...this.state, DOBShowMsg: true });
      }
    }

    this.props.validate(field);
  };

  handleOnSuccess = (public_token, metadata) => {
    if (metadata.account.verification_status === 'pending_manual_verification') {
      this.props.createPlaidAccessToken(public_token, metadata.account_id, this.props.user.id);
      window.scroll(0, 0);
    } else {
      this.props.updatePlaidToken(public_token, metadata.account_id);
      this.props.validate('token');
    }
  };

  handleOnExit = (err, metadata) => {
    // The user exited the Link flow.
    Sentry.withScope((scope) => {
      scope.setUser({
        id: this.props.user.id,
        email: this.props.user.email,
        first_name: this.props.user.firstName,
        last_name: this.props.user.lastName,
      });
      scope.setExtra('err', err);
      scope.setExtra('metadata', metadata);
      Sentry.captureMessage(
        `Plaid onExit() called with non-empty err for user_id=${this.props.user.id}`
      );
    });
    if (err != null) {
      // The user encountered a Plaid API error prior to exiting.
      Sentry.withScope((scope) => {
        scope.setUser({
          id: this.props.user.id,
          email: this.props.user.email,
          first_name: this.props.user.firstName,
          last_name: this.props.user.lastName,
        });
        scope.setExtra('err', err);
        scope.setExtra('metadata', metadata);
        Sentry.captureMessage(
          `Plaid onExit() called with non-empty err for user_id=${this.props.user.id}`
        );
      });
    }
  };

  onStripeFieldBlur = (field) => {
    this.props.validate(field);
    this.updateStripeToken();
  };

  updateValidation = (fieldName, condition) => {
    let newval = false;
    if (condition) {
      newval = true;
    }

    this.setState({ ...this.state, [fieldName]: newval });
  };

  onFieldChange = (field, event) => {
    const value = event.currentTarget.value;
    if (field === 'dateOfBirth') {
      const dateInput = moment(value, 'MM/DD/YYYY');
      this.updateValidation('DOBValid', dateInput._isValid && !dateInput.isAfter(thirteenYearsAgo));
    }

    this.props.updateField(field, event.currentTarget.value);
  };

  toggleEditing = () => {
    this.setState({ ...this.state, editing: !this.state.editing });
  };

  updateStripeToken = () => {
    if (
      this.props.check.accountType &&
      this.props.check.accountNumber &&
      this.props.check.routingNumber
    ) {
      this.props.stripe
        .createToken('bank_account', {
          account_holder_name: `${this.props.user.firstName} ${this.props.user.lastName}`,
          account_holder_type: this.props.check.accountType,
          account_number: this.props.check.accountNumber,
          country: 'US',
          currency: 'usd',
          routing_number: this.props.check.routingNumber,
        })
        .then((result) => this.props.updateToken(result.token));
    }
  };

  render() {
    const sectionClass =
      this.hasSource() || (this.props.source.id && this.props.source.object === 'card')
        ? 'section no-dashed-line'
        : 'section';
    const showVerifyMicroDeposits = !this.props.check.plaid.token && this.props.publicToken;

    const connectButtonClass = showVerifyMicroDeposits ? 'toggle-button-white' : 'toggle-button';

    return (
      <div className="checking">
        <div className={sectionClass}>
          <h3>payment information</h3>
          <p>
            All financial transactions are processed securely through{' '}
            <a href="https://stripe.com/" target="_blank" rel="noopener noreferrer">
              Stripe
            </a>
            {'.'}
          </p>
          <p>
            Bank account verification is processed securely through{' '}
            <a href="https://plaid.com/" target="_blank" rel="noopener noreferrer">
              Plaid
            </a>
            {'.'}
          </p>

          {this.hasSource() && (
            <div className="source-info">
              <p>Account Information on File:</p>
              <div className="row">
                <div className="col-md-5">
                  <i>Current Account Number:</i>
                  <span>xxxx xxxx xxxx {this.props.source.last4}</span>
                </div>
                <div className="col-md-4">
                  <i>Routing Number:</i>
                  <span>{this.props.source.routingNumber}</span>
                </div>
              </div>
            </div>
          )}
          {this.state.editing ? (
            <div className="row">
              {this.props.check.plaid.token && (
                <div className="col-md-7 d-flex align-items-center">
                  <span className="responsive-text">
                    Bank account connected! Please click the <strong>'UPDATE PAYMENT'</strong>{' '}
                    button to save
                  </span>
                </div>
              )}
              {!this.props.check.plaid.token && this.props.publicToken && (
                <div className="btn-wrapper col-md-6">
                  <PlaidLink
                    clientName="Go4Ellis"
                    env={plaid_env}
                    product={['auth']}
                    selectAccount={true}
                    publicKey={plaid_public_key}
                    user={{
                      legalName: this.props.user.firstName + ' ' + this.props.user.lastName,
                      emailAddress: this.props.user.email,
                    }}
                    token={this.props.publicToken}
                    onExit={this.handleOnExit}
                    onClick={(event) => event.preventDefault()}
                    onSuccess={this.handleOnSuccess}
                    style={{
                      'width': '100%',
                      'font-size': '14px',
                    }}
                    className="btn btn-micro gotham-bold-font"
                  >
                    VERIFY YOUR MICRO DEPOSITS
                  </PlaidLink>
                </div>
              )}
              {!this.props.check.plaid.token && (
                <div className="col-md-6">
                  <PlaidLink
                    clientName="Go4Ellis"
                    env={plaid_env}
                    product={['auth']}
                    selectAccount={true}
                    publicKey={plaid_public_key}
                    user={{
                      legalName: this.props.user.firstName + ' ' + this.props.user.lastName,
                      emailAddress: this.props.user.email,
                    }}
                    onExit={this.handleOnExit}
                    onClick={(event) => event.preventDefault()}
                    onSuccess={this.handleOnSuccess}
                    style={{
                      width: '100%',
                    }}
                    className={connectButtonClass}
                  >
                    CONNECT A BANK ACCOUNT
                  </PlaidLink>
                </div>
              )}
            </div>
          ) : (
            <div className="col-md-6 px-0">
              <PlaidLink
                clientName="Go4Ellis"
                env={plaid_env}
                product={['auth']}
                selectAccount={true}
                publicKey={plaid_public_key}
                user={{
                  legalName: this.props.user.firstName + ' ' + this.props.user.lastName,
                  emailAddress: this.props.user.email,
                }}
                onExit={this.handleOnExit}
                onClick={(event) => event.preventDefault()}
                onSuccess={this.handleOnSuccess}
                style={{
                  width: '100%',
                }}
                className="toggle-button"
              >
                CONNECT A BANK ACCOUNT
              </PlaidLink>
            </div>
          )}
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    user: state.session.currentUser,
    check: state.payment.check,
    loadedSource: state.payment.loadedSource,
    loadingSource: state.payment.loadingSource,
    source: state.payment.source,
    states: state.enums.usStates,
    validation: state.payment.validation.check,
    publicToken: state.payment.publicToken,
    object: state.payment.object,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    updateField: (field, value) => dispatch(updateCheckField(field, value)),
    updatePlaidToken: (token, account_id) => dispatch(updatePlaidToken(token, account_id)),
    createPlaidAccessToken: (token, account_id, id) =>
      dispatch(createPlaidAccessToken(token, account_id, id)),
    validate: (field) => dispatch(validateCheckField(field)),
  };
}

export default injectStripe(connect(mapStateToProps, mapDispatchToProps)(Checking));
