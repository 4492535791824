import { get } from 'lodash';

function isEventIndustrial(professionId, professions, eventTypeId, eventTypes) {
    if (!eventTypes || !eventTypeId || !professions || !professionId) {
        return true;
    }
    let AT = get(professions.find(elem => elem.name == "Athletic Trainer"), 'id', -1)
    let SPORTS = get(eventTypes.find(elem => elem.name == "Sports"), 'id', -1)
    let SCHOOL = get(eventTypes.find(elem => elem.name == "School"), 'id', -1)
    return !(eventTypeId == SPORTS || (professionId == AT && eventTypeId == SCHOOL))
}

export {
    isEventIndustrial
}