import React from "react";
import phonePartial from "iPhone-X-PSD-Mockup-01-no-shadow.png";
import SectionBanner from "../SectionBanner";

const text = [
  {
    title: "SET YOUR OWN SCHEDULE",
    para:
      "You pick the shifts that you want to work, when and where you want to work them. There are no minimum requirements. Work as many as you like or as few.",
  },
  {
    title: "THE RATES YOU CHOOSE",
    para:
      "We do not dictate rates. So you are free to pick up only the shifts that meet your financial criteria.",
  },
  {
    title: "DIRECT DEPOSIT",
    para:
      "All payments on Go4 are through direct deposit. No more paper checks or chasing down people for your money.",
  },
];

const ForAts = () => {
  return (
    <section className="forAts">
      <SectionBanner text="For Athletic Trainers" />
      <div className="body">
        <div className="phone-background">
          <div className="img-wrapper">
            <img className="phone-img" alt="phone" src={phonePartial} />
          </div>
        </div>
        <div className="for-at-text">
          {text.map((item, i) => {
            return (
              <div key={i}>
                {i !== 0 && <div className="for-at-divider" />}
                <h3>
                  <span>{">"}</span>
                  {item.title}
                </h3>
                <p>{item.para}</p>
              </div>
            );
          })}
        </div>
      </div>
    </section>
  );
};

export default ForAts;
