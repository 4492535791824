import React from 'react';
import './WhoIsEllis.scss';
import ellis from 'ellis-logo-text.png';

const WhoIsEllis = () => {
  return (
    <div className="whoIsEllis">
      <div className="body">
        <div className="heading">
          <div className="who-is">
            who is
          </div>
          <img alt="Ellis" src={ellis}/>
        </div>
        <div className="text">
          <span>
          A practicing Athletic Trainer, the idea for the platform was born from the effort Ellis Mair put into finding Athletic Trainers for all the camps, clinics, practices and tournaments run by NXTSports. The name ‘Go4Ellis’ comes from how Ellis answers the walkie talkie when a call goes out for help.
          </span>
        </div>
      </div>
    </div>
   );
}

export default WhoIsEllis;
