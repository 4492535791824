import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
import ReactGA from 'react-ga';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import { Provider } from 'react-redux';
import { StripeProvider } from 'react-stripe-elements';
import moment from 'moment';
import 'rxjs';

import App from 'scenes/App';

import ScrollToTop from 'components/ScrollToTop';

import store from './redux/config';

import 'flatpickr/dist/themes/light.css';
import 'styles/styles.css';

import * as Sentry from '@sentry/browser';
import LogRocket from 'logrocket';
import { unregister } from './registerServiceWorker';

import RegistrationScene from 'scenes/RegistrationScene';
import LoadingSpinner from 'components/LoadingSpinner';

moment.suppressDeprecationWarnings = true;
ReactGA.initialize('UA-120431567-1');

require('formdata-polyfill');

const stripeKey = process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY;

Sentry.init({
  dsn: process.env.REACT_APP_SENTRY_DSN,
  serverName: 'go4ellis-frontend',
});
if (process.env.NODE_ENV !== 'development') {
  LogRocket.init(process.env.REACT_APP_LOG_ROCKET_ID, {
    network: {
      requestSanitizer: (request) => {
        if (request.body instanceof FormData) {
          request.body = JSON.stringify(Object.fromEntries(request.body));
        }

        return request;
      },
    },
  });
}

ReactDOM.render(
  <Provider store={store}>
    <StripeProvider apiKey={stripeKey}>
      <BrowserRouter>
        <ScrollToTop>
          <LoadingSpinner />
          <Suspense fallback={<LoadingSpinner />}>
            <Switch>
              <Route path='/sign-up' component={RegistrationScene} />
              <App />
            </Switch>
          </Suspense>
        </ScrollToTop>
      </BrowserRouter>
    </StripeProvider>
  </Provider>,
  document.getElementById('root')
);

unregister();
