import {
  GET_DASHBOARD_EVENTS_SUCCESS,
  GET_DRAFT_EVENTS_SUCCESS,
  GET_PAST_EVENTS_SUCCESS,
  GET_UPCOMING_EVENTS_SUCCESS,
  GET_UPCOMING_SHIFTS_SUCCESS,
} from '../Event';

// Actions
// ========================================================
const constForName = (name) => `go4ellis/User/${name}`;
export const APPLY_FILTERS = constForName('APPLY_FILTERS');
export const GET_ATHLETIC_TRAINERS = constForName('GET_ATHLETIC_TRAINERS');
export const GET_ATHLETIC_TRAINERS_SUCCESS = constForName('GET_ATHLETIC_TRAINERS_SUCCESS');
export const GET_ATHLETIC_TRAINERS_ERROR = constForName('GET_ATHLETIC_TRAINERS_ERROR');
export const GET_EVENT_OPERATORS = constForName('GET_EVENT_OPERATORS');
export const GET_EVENT_OPERATORS_SUCCESS = constForName('GET_EVENT_OPERATORS_SUCCESS');
export const GET_EVENT_OPERATORS_ERROR = constForName('GET_EVENT_OPERATORS_ERROR');
export const GET_USERS = constForName('GET_USERS');
export const GET_USERS_SUCCESS = constForName('GET_USERS_SUCCESS');
export const GET_USERS_ERROR = constForName('GET_USERS_ERROR');
export const RESET_FILTERS = constForName('RESET_FILTERS');
export const RESET_USER = constForName('RESET_USER');
export const UPDATE_FILTER = constForName('UPDATE_FILTER');
export const UPDATE_USER = constForName('UPDATE_USER');
export const UPDATE_USER_SUCCESS = constForName('UPDATE_USER_SUCCESS');
export const UPDATE_USER_ERROR = constForName('UPDATE_USER_ERROR');
export const SEND_DEEPLINK_SMS = constForName('SEND_DEEPLINK_SMS');
export const SEND_DEEPLINK_SMS_SUCCESS = constForName('SEND_DEEPLINK_SMS_SUCCESS');
export const SEND_DEEPLINK_SMS_ERROR = constForName('SEND_DEEPLINK_SMS_ERROR');
export const GET_ATHLETIC_TRAINER = constForName('GET_ATHLETIC_TRAINER');
export const GET_ATHLETIC_TRAINER_SUCCESS = constForName('GET_ATHLETIC_TRAINER_SUCCESS');
export const GET_ATHLETIC_TRAINER_ERROR = constForName('GET_ATHLETIC_TRAINER_ERROR');
export const TRACK_PROSPECT_USER = constForName('TRACK_PROSPECT_USER');

// Reducer Functions
// ========================================================
function errorReducer(state, payload) {
  return {
    ...state,
    errors: payload,
  };
}

function getAthleticTrainersReducer(state, payload) {
  return {
    ...state,
    athleticTrainers: payload,
  };
}

function getEventOperatorsReducer(state, payload) {
  return {
    ...state,
    eventOperators: payload,
  };
}

function getUsersReducer(state, users) {
  return {
    ...state,
    users,
  };
}

function resetFiltersReducer(state) {
  return {
    ...state,
    filters: {
      name: '',
      role: '',
    },
  };
}

function resetReducer(state) {
  return {
    ...DEFAULT_STATE,
  };
}

function updateFilterReducer(state, payload) {
  return {
    ...state,
    filters: {
      ...state.filters,
      [payload.filter]: payload.value,
    },
  };
}

function updateReducer(state, payload) {
  return {
    ...state,
    user: payload,
    updated: true,
  };
}

function deeplinkSentSuccessReducer(state, payload) {
  return {
    ...state,
    deeplinkSmsSent: true,
    deeplinkSmsError: null,
  };
}

function deeplinkSentErrorReducer(state, payload) {
  return {
    ...state,
    deeplinkSmsSent: false,
    deeplinkSmsError: payload,
  };
}

const getAthleticTrainerSuccessReducer = (state, payload) => ({
  ...state,
  pendingApplicants: [...state.pendingApplicants, payload],
});

// Action Creators
// ========================================================
export function applyFilters() {
  return { type: APPLY_FILTERS };
}

export function getAllUsers() {
  return { type: GET_USERS };
}

export function getAthleticTrainers() {
  return { type: GET_ATHLETIC_TRAINERS };
}

export function getEventOperators() {
  return { type: GET_EVENT_OPERATORS };
}

export function reset() {
  return { type: RESET_USER };
}

export function resetFilters() {
  return { type: RESET_FILTERS };
}

export function update(user) {
  return { type: UPDATE_USER, payload: user };
}

export function updateFilter(filter, value) {
  return {
    type: UPDATE_FILTER,
    payload: {
      filter: filter,
      value: value,
    },
  };
}

export function sendDeeplinkSms(phone, deeplink) {
  return { type: SEND_DEEPLINK_SMS, payload: { phone, deeplink } };
}

export const getAthleticTrainer = (id) => ({
  type: GET_ATHLETIC_TRAINER,
  payload: id,
});

export const trackProspectUser = (data) => ({
  type: TRACK_PROSPECT_USER,
  payload: data,
});

// Reducer
// ========================================================
const DEFAULT_STATE = {
  deeplinkSmsSent: false,
  deeplinkSmsError: null,
  errors: {},
  updated: false,
  user: {},
  athleticTrainers: [],
  eventOperators: [],
  pendingApplicants: [],
  users: [],
  filters: {
    name: '',
    role: '',
  },
};

export default function reducer(state = DEFAULT_STATE, action = {}) {
  switch (action.type) {
    case GET_ATHLETIC_TRAINERS_SUCCESS:
      return getAthleticTrainersReducer(state, action.payload);
    case GET_EVENT_OPERATORS_SUCCESS:
      return getEventOperatorsReducer(state, action.payload);
    case GET_USERS_SUCCESS:
      return getUsersReducer(state, action.payload);
    case RESET_FILTERS:
      return resetFiltersReducer(state);
    case RESET_USER:
      return resetReducer(state);
    case UPDATE_FILTER:
      return updateFilterReducer(state, action.payload);
    case UPDATE_USER_ERROR:
      return errorReducer(state, action.payload);
    case UPDATE_USER_SUCCESS:
      return updateReducer(state, action.payload);
    case GET_DASHBOARD_EVENTS_SUCCESS:
    case GET_DRAFT_EVENTS_SUCCESS:
    case GET_PAST_EVENTS_SUCCESS:
    case GET_UPCOMING_EVENTS_SUCCESS:
    case GET_UPCOMING_SHIFTS_SUCCESS:
      return getAthleticTrainersReducer(state, action.payload.athletic_trainers || []);
    case SEND_DEEPLINK_SMS_SUCCESS:
      return deeplinkSentSuccessReducer(state, action.payload);
    case SEND_DEEPLINK_SMS_ERROR:
      return deeplinkSentErrorReducer(state, action.payload);
    case GET_ATHLETIC_TRAINER_SUCCESS:
      return getAthleticTrainerSuccessReducer(state, action.payload);
    default:
      return state;
  }
}
