import classNames from 'classnames';
import React from 'react';

const CustomRadioCheckBox = (props) => {
  const {
    id,
    name,
    checked,
    labelClassName,
    value,
    required,
    onChange,
    children,
    disabled,
    modalVerticalAlign,
  } = props;
  return (
    <div className="customRadioCheckBox">
      <div
        className={classNames('custom-control custom-radio custom-control-inline', {
          'adjust-modal-vertical-align': modalVerticalAlign,
        })}
      >
        <input
          className="form-control custom-control-input"
          id={id}
          type="radio"
          name={name}
          value={value}
          checked={checked}
          onChange={onChange}
          required={required}
          disabled={!!disabled}
        />
        <label
          htmlFor={id}
          className={classNames(`custom-control-label radio-inline ${labelClassName}`, {
            disabled: !!disabled,
          })}
        >
          {children}
        </label>
      </div>
    </div>
  );
};

export default CustomRadioCheckBox;
