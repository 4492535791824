// @flow
import React, { Component } from 'react';
import { UncontrolledAlert } from 'reactstrap';
import { parse } from 'query-string';

type ResetPasswordFormProps = {
  location: Object,
  updatePassword: (string, string, Object) => void,
  error: ?string,
};

type ResetPasswordFormState = {
  password: string,
  confirmation: string
};

class ResetPasswordForm extends Component<ResetPasswordFormProps, ResetPasswordFormState> {
  state = {
    password: '',
    confirmation: ''
  }

  handlePasswordChange = (e: SyntheticEvent<HTMLInputElement>) => {
    this.setState({ password: e.currentTarget.value })
  }

  handleConfirmationChange = (e: SyntheticEvent<HTMLInputElement>) => {
    this.setState({ confirmation: e.currentTarget.value })
  }

  onSubmit = (e: SyntheticEvent<HTMLInputElement>) => {
    e.preventDefault();
    const session = parse(this.props.location.search);
    session.accessToken = session['access-token'];
    this.props.updatePassword(this.state.password, this.state.confirmation, session)
  }

  render() {
    return (
      <form className="resetPasswordForm" onSubmit={this.onSubmit}>
        <img className="forgot-password-icon" src="/set-password-icon.png" alt="a flying envelope" />
        <h4>set new password</h4>
        {this.props.error && <UncontrolledAlert color="danger">Sorry, we weren't able to change your password. Please check your connection and try again later.</UncontrolledAlert>}
        <label>Enter and confirm your <span className="text-danger">new</span> password below</label>
        <input type="password"
          name="password"
          placeholder="ENTER NEW PASSWORD (must be at least 8 characters)"
          value={this.state.password}
          onChange={this.handlePasswordChange} />
        <input type="password"
          name="password_confirmation"
          placeholder="CONFIRM PASSWORD"
          value={this.state.confirmation}
          onChange={this.handleConfirmationChange} />
        <button disabled={this.state.password.length < 8 || this.state.password !== this.state.confirmation} className="btn btn-danger bold-button w-100 mb-4">reset password</button>
      </form>
    );
  }
}

export default ResetPasswordForm;