// @flow
import React, { Component } from 'react';
import { isEmpty, get, isObject } from 'lodash';
import { connect } from 'react-redux';
import ShiftForm from '../ShiftForm';
import moment from 'moment';
import {
  addShift,
  removeShift,
  cancelShift,
  fieldChange,
  newAddressInput,
  googlePlaceSelected
} from 'redux/ducks/CreateEvent/Shifts';
import { decline } from 'redux/ducks/Job';
import ShiftJobsList from './ShiftJobsList';
import RemoveATModal from './RemoveATModal';
import CancelShiftModal from './CancelShiftModal';

import type { AppState } from 'redux/ducks';
import { rateTypeNameFinder } from 'helpers/rateTypeName'
import { isContractEo } from 'helpers/contracts';

type ShiftPanelListProps = {
  shifts: ShiftForm[];
  removeShift: number => Function;
  onFieldChange: number => Function;
  onNewAddressInput: number => Function;
  reuseLocation: boolean;
  googlePlaceSelected: number => Function;
  addShift: () => void;
  cancel: ?number => void;
  editMode: boolean;
  remove: Function;
  allowNoShifts: boolean;
};

type ShiftPanelListState = {
  showRemoveATModal: boolean;
  jobIDToRemove: ?number;
  showCancelShiftModal: boolean;
  shiftIdxToCancel: ?number;
  goToChangePayRate: ?Function;
};

class ShiftPanelList extends Component<ShiftPanelListProps, ShiftPanelListState> {
  state = {
    showRemoveATModal: false,
    jobIDToRemove: null,
    showCancelShiftModal: false,
    shiftIdxToCancel: null
  };

  toggleRemoveATModal = () => this.setState({ showRemoveATModal: !this.state.showRemoveATModal })

  toggleCancelShiftModal = () => this.setState({ showCancelShiftModal: !this.state.showCancelShiftModal })

  onRemove = () => {
    this.props.remove(this.state.jobIDToRemove);
    this.toggleRemoveATModal();
  }

  onCancel = (reason) => {
    this.props.cancel(this.state.shiftIdxToCancel, reason);
    this.toggleCancelShiftModal();
  }

  onCancelClick = (idx: number) => () => {
    this.setState({
      showCancelShiftModal: true,
      shiftIdxToCancel: idx
    });
  }

  onRemoveClick = (id: number) => {
    this.setState({
      jobIDToRemove: id,
      showRemoveATModal: true
    })
  }

  canCancelShift = (shift) => {
    if (isContractEo(this.props.user.email)) return false;

    if (moment().utc().isAfter(moment(shift.startTime).utc())) return false

    return !shift.jobs?.some(({ currentState }) => ['checked_in', 'paid'].includes(currentState))
  }

  render() {
    const {
      shifts,
      removeShift,
      onFieldChange,
      onNewAddressInput,
      reuseLocation,
      reuseOnsiteContacts,
      googlePlaceSelected,
      addShift,
      editMode = false,
      user,
      allowNoShifts = false,
      goToChangePayRate = () => { },
    } = this.props;
    const canRemoveFirstShift = allowNoShifts || shifts.length > 1

    const renderAddShiftButton = () => {
      if (isContractEo(user.email)) return null;

      return (
        <div className="add-shift mt-4">
          <button className="btn btn-lg btn-primary" onClick={addShift}>Add Another Shift</button>
          {this.props.rateType === 'flat' &&
            <label className="note-label">Note: Each shift worked will be paid the Flat Rate amount.</label>}
        </div>
      );
    }

    return (
      <div className="shiftPanelList">
        {shifts.filter(s => !s.cancelled).map(
          (shift) => {
            let idx = shift.idx
            return (
              <div className="mt-3" key={idx}>
                <span className="shift-form-label h5">Shift {idx + 1}</span>
                {this.canCancelShift(shift) && <>
                  {editMode && shift.id ?
                    <button type="button"
                      className="btn text-danger btn-link pt-0"
                      onClick={this.onCancelClick(idx)}
                    >
                      DELETE THIS SHIFT
                    </button> :
                    canRemoveFirstShift && <span className="link-button" onClick={removeShift(idx)} >Remove Shift</span>}
                </>}
                <ShiftForm shift={shift} onFieldChange={onFieldChange(idx)}
                  goToChangePayRate={goToChangePayRate}
                  onNewAddressInput={onNewAddressInput(idx)}
                  addressFieldDisabled={reuseLocation}
                  onsiteContactsDisabled={reuseOnsiteContacts}
                  showReuseLocationCheckbox={idx === 0 && !isContractEo(user.email)}
                  showReuseOnsiteContactCheckbox={idx === 0 && !editMode && !isContractEo(user.email)}
                  onGooglePlaceSelected={googlePlaceSelected(idx)}
                  shiftId={idx}
                  editMode={editMode}
                  onEditEap={this.props.onEditEap}
                />
                {(!isEmpty(shift.jobs) && !shift.jobs?.every(({ currentState }) => inactiveStates.includes(currentState))) &&
                  <ShiftJobsList shift={shift} jobs={shift.jobs} onRemove={this.onRemoveClick} />}
              </div>)
          }
        )
        }

        {renderAddShiftButton()}

        <div className="col" id="attribution">
        </div>
        <RemoveATModal
          isOpen={this.state.showRemoveATModal}
          toggle={this.toggleRemoveATModal}
          onRemove={this.onRemove}
        />
        <CancelShiftModal
          isOpen={this.state.showCancelShiftModal}
          toggle={this.toggleCancelShiftModal}
          onCancel={this.onCancel}
        />
      </div>
    );
  }
}

const mapStateToProps = (state: AppState) => {
  let shifts = state.createEvent.shifts.shifts.map((shift, idx) => { shift.idx = idx; return shift; })
  const rateType = rateTypeNameFinder(state.enums.rateTypes, state.createEvent.details.rateTypeId);

  return {
    user: state.session.currentUser,
    rateType,
    shifts,
    reuseLocation: state.createEvent.shifts.reuseLocation,
    reuseOnsiteContacts: state.createEvent.shifts.reuseOnsiteContacts,
  }
}

const mapDispatchToProps = dispatch => ({
  removeShift: (idx) => () => { dispatch(removeShift(idx)) },
  addShift: () => dispatch(addShift()),
  onFieldChange: (idx) => (fieldName) => {
    return (ev) => {
      const value = isObject(ev) ? get(ev, 'target.value') : ev;
      dispatch(fieldChange(idx, fieldName, value));
    }
  },
  googlePlaceSelected: (idx) => ({ placeId }) => { dispatch(googlePlaceSelected(idx, placeId)) },
  onNewAddressInput: (idx) => (ev) => {
    dispatch(newAddressInput({ index: idx, input: ev.target.value }));
  },
  remove: (id) => dispatch(decline(id)),
  cancel: (index, cancelReason) => dispatch(cancelShift({ index, cancelReason }))
})

export const inactiveStates = [
  'waitlist',
  'declined',
  'eo_removed_at',
  'decommitted',
  'withdrawn',
  'unapplied',
  'callout',
  'expired_no_at_confirm',
  'expired_no_eo_confirm',
];
export { ShiftPanelList }
export default connect(mapStateToProps, mapDispatchToProps)(ShiftPanelList);
