import React, { Component } from 'react';
import classnames from 'classnames';
import { type LocationType } from 'redux/ducks/Post/Locations';
import HorizontalRule from 'components/HorizontalRule';
import ReportToContactList from '../ReportToContactList';
import { get } from 'lodash';
import { GoogleSerializer } from 'services/serializers';
import { getSettingName } from 'helpers/enums';

declare var google: any;

type Props = {
  shiftLocation: LocationType,
  onChangeLocation: (args: { name: string, value: any }) => void,
  onGooglePlaceSelected: (args: { placeId: string }) => void,
  onAddNewReportToContact: () => void,
  onRemoveReportFromContact: (contactIndex: number) => void,
  onChangeReportFromContact: (idx: number, contact: Object) => void,
};

export class EventLocation extends Component<Props> {
  constructor(props) {
    super(props);
    this.addressRef = React.createRef();
    this.locationNameRef = React.createRef();
    this.state = {
      wasFieldValidated: {
        address: false,
        name: false,
        countValue: false,
      },
    };
  }

  initAutocomplete = (e) => {
    const autocompleteOptions = {
      componentRestrictions: {
        country: 'us',
      },
    };

    this.autocompleteAddress = new google.maps.places.Autocomplete(
      this.addressRef.current,
      autocompleteOptions
    );

    this.autocompleteLocationName = new google.maps.places.Autocomplete(
      this.locationNameRef.current,
      autocompleteOptions
    );

    this.autocompleteAddress.addListener('place_changed', () => {
      const places = this.autocompleteAddress.getPlace();
      this.props.onGooglePlaceSelected(
        GoogleSerializer.eventLocationFromAutocompleteResult(places)
      );
    });

    this.autocompleteLocationName.addListener('place_changed', () => {
      const places = this.autocompleteLocationName.getPlace();
      this.props.onGooglePlaceSelected(
        GoogleSerializer.eventLocationFromAutocompleteResult(places)
      );
    });
  };

  componentDidMount() {
    if (this.addressRef.current || this.locationNameRef.current) {
      this.initAutocomplete();
    }
  }

  componentDidUpdate() {
    if (
      (!this.autocompleteAddress && this.addressRef.current) ||
      (!this.autocompleteLocationName && this.locationNameRef.current)
    ) {
      this.initAutocomplete();
    }
  }

  handleKeyPress = (e) => {
    if (e.key === 'Enter' && !(e.target && e.target.tagName.toLowerCase() === 'textarea')) {
      e.preventDefault();
      e.stopPropagation();
    }
  };

  onFieldChange = (name: string) => (e) => {
    this.props.onChangeLocation({ name, value: e.target.value });
  };

  onFieldBlur = (field: string) => () => {
    if (!this.state.wasFieldValidated[field]) {
      this.setState((prevState) => {
        return {
          ...prevState,
          wasFieldValidated: {
            ...prevState.wasFieldValidated,
            [field]: true,
          },
        };
      });
    }
  };

  showError = (fieldName: string) => {
    const { shiftLocation, wasValidated } = this.props;
    return (
      (wasValidated || get(this.state.wasFieldValidated, fieldName)) &&
      !!get(shiftLocation, `errorMessages[${fieldName}]`)
    );
  };

  add = (value) => () => {
    const { shiftLocation, onChangeLocation } = this.props;
    const { countValue } = shiftLocation;
    const newValue = countValue + value;
    onChangeLocation({
      name: 'countValue',
      value: newValue > 1 ? newValue : 1,
    });
  };

  render() {
    const {
      profile,
      wasValidated,
      shiftLocation,
      onAddNewReportToContact,
      onRemoveReportFromContact,
      onChangeReportFromContact,
    } = this.props;
    const { reportToContacts } = shiftLocation;
    const settingName = getSettingName(profile.eventSettingId);

    return (
      <div className="eventLocation">
        <div className="row">
          <div className="form-group col-md-12">
            <label htmlFor="name">Location Name</label>
            <input
              type="text"
              name="name"
              ref={this.locationNameRef}
              value={shiftLocation.name}
              disabled={false}
              onChange={(e) =>
                this.props.onChangeLocation({
                  name: 'name',
                  value: e.target.value,
                })
              }
              onBlur={this.onFieldBlur('name')}
              className={classnames('form-control italic-placeholder nimbus-regular-font', {
                'is-invalid': this.showError('name'),
              })}
              placeholder="What is the commonly used name for this location."
            />
            <div className="invalid-feedback nimbus-regular-font">
              {get(shiftLocation, 'errorMessages.name')}
            </div>
          </div>
        </div>
        <div className="row countType">
          <div className="form-group col-md-12">
            <label htmlFor="address">Address</label>
            <input
              name="address"
              className={classnames('form-control italic-placeholder nimbus-regular-font', {
                'is-invalid': this.showError('address'),
              })}
              placeholder="Start typing and choose from the suggestions."
              ref={this.addressRef}
              value={shiftLocation.formattedAddress}
              disabled={false}
              autoComplete="off"
              onChange={(e) =>
                this.props.onChangeLocation({
                  name: 'formattedAddress',
                  value: e.target.value,
                })
              }
              onBlur={this.onFieldBlur('address')}
            />
            <div className="invalid-feedback nimbus-regular-font">
              {get(shiftLocation, 'errorMessages.address')}
            </div>
          </div>
          {settingName === 'Sports' && (
            <div className="form-group col-md-12">
              <label htmlFor="countValue">How many fields/courts/etc will be used?</label>
              <div className="input-group col-md-4 p-0">
                <div className="input-group-append">
                  <span
                    type="button"
                    onClick={this.add(-1)}
                    className="input-group-text lbr remove-border-r"
                  >
                    <i className="fas fa-minus"></i>
                  </span>
                </div>
                <input
                  key={`count-${shiftLocation.idx}`}
                  type="number"
                  name="countValue"
                  value={shiftLocation.countValue}
                  onChange={(e) =>
                    this.props.onChangeLocation({
                      name: 'countValue',
                      value: e.target.value,
                    })
                  }
                  onBlur={this.onFieldBlur('countValue')}
                  className={classnames(
                    'form-control italic-placeholder text-center pl-4 w-25 gotham-bold-font',
                    {
                      'is-invalid': this.showError('countValue'),
                    }
                  )}
                  placeholder="e.g. 2"
                />
                <div className="input-group-append">
                  <span type="button" onClick={this.add(1)} className="input-group-text rbr">
                    <i className="fas fa-plus"></i>
                  </span>
                </div>
                <div className="invalid-feedback nimbus-regular-font">
                  {get(shiftLocation, 'errorMessages.countValue')}
                </div>
              </div>
            </div>
          )}
        </div>
        <div className="p-0 m-0" id="attribution"></div>
        <HorizontalRule dashed />
        <React.Fragment>
          <div>
            <label className="job-contact-copy nimbus-regular-font">
              <span className="gotham-medium-font">Job Contact </span>/ Contact info is only visible
              to athletic trainers once they confirm a job.
            </label>
          </div>
          <ReportToContactList
            contacts={reportToContacts || []}
            removeContact={onRemoveReportFromContact}
            changeContact={onChangeReportFromContact}
            {...this.state}
            wasValidated={wasValidated}
            errors={
              get(shiftLocation, `errorMessages.reportToContacts`) ??
              new Array(shiftLocation.reportToContacts.length).fill({})
            }
            emptyError={get(shiftLocation, `errorMessages.emptyReportToContacts`)}
          />

          <div className="d-flex button-row">
            <button className="report-contact-btn" onClick={onAddNewReportToContact}>
              ADD CONTACT
            </button>
          </div>
        </React.Fragment>
      </div>
    );
  }
}

export default EventLocation;
