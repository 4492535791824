import { filter, some, get, flatMap, uniq } from 'lodash';

const EAP_INCOMPLETE_ALERT = 'EAP - Not Optimized';
const NEW_DECOMMIT_ALERT = 'Callout';
const NEW_JOB_MODIFICATION_ALERT = 'SHIFT MODIFICATION REQUESTED';

const hasCapacity = (shift) => {
  const approvedStates = ['eo_accepted_at', 'confirmed', 'checked_in', 'paid'];
  const approvedJobs = filter(shift.jobs.byId, job => approvedStates.includes(job.currentState))
  return approvedJobs.length < shift.shiftCapacity;
}

const jobIsDecommitted = job => job.currentState === 'decommitted' || job.currentState === 'callout';
const jobHasPendingModification = job => get(job, 'jobModification.currentState') === 'pending';

export function alertsForShift(shift) {
  const result = [];
  if (!shift.emergencyActionPlanId) {
    result.push(EAP_INCOMPLETE_ALERT)
  }

  if (some(shift.jobs.byId, jobIsDecommitted) && hasCapacity(shift)) {
    result.push(NEW_DECOMMIT_ALERT);
  }

  if (some(shift.jobs.byId, jobHasPendingModification)) {
    result.push(NEW_JOB_MODIFICATION_ALERT);
  }

  return uniq(result);
}

export function alertsForEvent({ shifts }) {
  return uniq(flatMap(shifts.byId, alertsForShift));
}
