import React, { Component } from 'react';
import ConfirmationModal from 'components/V2/ConfirmationModal';
import moment from 'moment';
import EditShiftModal from '../EditShiftModal';
import { getDayName, shiftDuration, shiftRate } from 'helpers/post';
import { DeleteButton } from 'components/V2/Buttons';
import { capitalize, get } from 'lodash';
import { rateTypeNameFinder } from 'helpers/rateTypeName';
import { connect } from 'react-redux';
import DeleteModal from 'components/V2/ConfirmationModal/DeleteModal';
import { MILITARY_FORMAT } from 'helpers/datetime';
import { shiftStatistics } from 'helpers/dashboard';

class ShiftDetails extends Component {
  state = { isOpenDeleteModal: false };
  toggleDeleteModal = () => this.setState({ isOpenDeleteModal: !this.state.isOpenDeleteModal });

  setPrecision = (amount) => Number(amount).toFixed(2);
  canEdit = (shift) => {
    return moment(shift.endTime, MILITARY_FORMAT).isAfter(moment());
  };

  canDelete = () => this.props.shifts.length > 1;

  render() {
    const { shift, shifts, idx, rateTypes, editMode } = this.props;
    const rateTypeName = rateTypeNameFinder(rateTypes, shift.rateTypeId);
    const startDateMoment = moment(shift.startDate, MILITARY_FORMAT);
    const endDateMoment = moment(shift.endDate, MILITARY_FORMAT);
    const jobs = Object.values(get(shift, 'jobs.byId', {}));
    const statistics = shiftStatistics(jobs);

    return (
      <div className="shiftDetails nimbus-regular-font">
        <table>
          <thead>
            <tr>
              <th>SHIFT</th>
              <th>{getDayName(shift).toUpperCase()}</th>
              <th>TIME</th>
              <th>ATs NEEDED</th>
              {!shift.isMultiDay && <th>HOURS</th>}
              <th>RATE</th>
              <th>SHIFT SUM</th>
              <th>
                {this.canEdit(shift) && (
                  <EditShiftModal
                    shift={shift}
                    shiftCount={shifts.length}
                    history={this.props.history}
                  >
                    Edit
                  </EditShiftModal>
                )}
              </th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>{idx + 1}</td>
              <td>
                {startDateMoment.isSame(endDateMoment, 'day')
                  ? startDateMoment.format('MM/DD/YYYY')
                  : startDateMoment.format('MM/DD/YYYY') +
                    ' - ' +
                    endDateMoment.format('MM/DD/YYYY')}
              </td>
              <td>
                {moment(shift.start, 'h:mm A').format('h:mm A')}
                {' - '}
                {moment(shift.end, 'h:mm A').format('h:mm A')}
              </td>
              <td>{shift.capacity}</td>
              {!shift.isMultiDay && <td>{Number(shiftDuration(shift))}</td>}
              <td>
                ${this.setPrecision(shift.payRate)}/{capitalize(rateTypeName)}
              </td>
              <td>${this.setPrecision(shiftRate(shift))}</td>
              <td>
                <DeleteButton
                  show={this.canDelete()}
                  onClick={this.toggleDeleteModal}
                  className="custom-delete-btn"
                >
                  {editMode ? 'CANCEL' : 'DELETE'}
                </DeleteButton>
                {!!editMode ? (
                  <DeleteModal
                    isOpen={this.state.isOpenDeleteModal}
                    title="Cancel Shift"
                    body={`Canceling this shift on ${startDateMoment.format(
                      'MM/DD/YYYY'
                    )} will remove athletic trainers who applied or confirmed to work.`}
                    cancelText="Cancel"
                    toggle={this.toggleDeleteModal}
                    onDelete={this.props.onDeleteShift}
                    item={shift}
                    user={this.props.user}
                    eventName={this.props.eventName}
                    capacityFilled={statistics.capacityFilled}
                  />
                ) : (
                  <ConfirmationModal
                    isOpen={this.state.isOpenDeleteModal}
                    toggle={this.toggleDeleteModal}
                    title="Delete Shift"
                    body={`Are you sure you want to delete this shift on ${endDateMoment.format(
                      'MM/DD/YYYY'
                    )}?`}
                    onSave={() => {
                      this.props.onDeleteShift(shift.idx);
                      this.toggleDeleteModal();
                    }}
                    confirmText="Delete"
                    cancelText="Go Back"
                  />
                )}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  rateTypes: state.enums.rateTypes,
  user: state.session.currentUser,
  eventName: state.post.profile.title,
});

export default connect(mapStateToProps)(ShiftDetails);
