// @flow
import React, { Component } from "react";
import { connect } from "react-redux";
import classnames from "classnames";
import { validateInvitationToken, acceptInvitation } from 'redux/ducks/Registration';
import { parse } from 'query-string';
import {
  registrationFieldUpdate,
  parentRegistrationFieldValidate
} from "redux/ducks/Registration";
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'

type ParentProps = {
  history: Object,
  updateField: Function,
  user: Object,
  validateField: Function,
  validation: Object
};

class Parent extends Component {
  constructor(props: ParentProps) {
    super(props)
  }

  componentWillMount() {
    const session = parse(this.props.location.search);
    this.props.validateInvitationToken(session['invitation_token'], this.props.location.pathname);
  }

  onFieldChange = field => {
    return (ev: SyntheticInputEvent<HTMLInputElement>) => {
      this.props.updateField(field, ev.currentTarget.value);
    };
  };

  onPhoneNumberChange(newNumber) {
    const phoneNo = newNumber.length === 1 && newNumber.at(0) !== '1' ? `1${newNumber}` : newNumber;
    this.props.updateField('phone', phoneNo);
  }

  onFieldValidate = field => {
    this.props.validateField(field);
  };

  onTermsChanged = e => {
    this.props.updateField("terms", e.target.checked);
    this.props.validateField("terms");
  };

  onSubmit = e => {
    e.preventDefault();
    const session = parse(this.props.location.search);
    const token = session['invitation_token'];

    this.props.acceptInvitation(token, this.props.history, this.props.location.pathname);
  };

  render() {
    const {
      user,
      validation,
      location
    } = this.props;
    if (this.errors) {
      return
    }
    return (
      <div className="parent">
        {location.pathname.includes('parent') ? <h2>Parent Sign Up</h2> : <h2>Patient Sign Up</h2>}
        <p>Sign up to see the medical record</p>
        <form id="parentForm" noValidate autoComplete="false">
          <div className="form-group">
            <label htmlFor="email">Email</label>
            <input
              type="email"
              className="form-control disabled"
              name="email"
              defaultValue={user.email}
              disabled
            />
            <div className="invalid-feedback">{validation.errors.email}</div>
          </div>
          <div className="form-group">
            <label htmlFor="firstName">First Name</label>
            <input
              type="text"
              className={classnames("form-control", {
                "is-invalid": validation.errors.firstName
              })}
              name="firstName"
              onChange={this.onFieldChange("firstName")}
              onBlur={this.onFieldValidate.bind(this, "firstName")}
            />
            <div className="invalid-feedback">{validation.errors.firstName}</div>
          </div>
          <div className="form-group">
            <label htmlFor="lastName">Last Name</label>
            <input
              type="text"
              className={classnames("form-control", {
                "is-invalid": validation.errors.lastName
              })}
              name="lastName"
              onChange={this.onFieldChange("lastName")}
              onBlur={this.onFieldValidate.bind(this, "lastName")}
            />
            <div className="invalid-feedback">{validation.errors.lastName}</div>
          </div>
          <div className="row">
            <div className="col form-group">
              <label htmlFor="phone">Phone Number</label>
              {/* <input type="tel" className="form-control" name="phone" onChange={this.onFieldChange('phone')} required minLength="10" maxLength="10" /> */}
              <PhoneInput
                name="phone"
                onlyCountries={["us"]}
                preferredCountries={["us"]}
                enableAreaCodes={false}
                autoFormat={true}
                placeholder="Enter phone number"
                country='us'
                onChange={this.onPhoneNumberChange.bind(this)}
              />
            </div>
            <div className="col form-group">
              <label htmlFor="addressZip">Zip Code</label>
              <input
                type="text"
                className={classnames("form-control", {
                  "is-invalid": validation.errors.addressZip
                })}
                name="addressZip"
                onChange={this.onFieldChange("addressZip")}
                onBlur={this.onFieldValidate.bind(this, "addressZip")}
              />
              <div className="invalid-feedback">{validation.errors.addressZip}</div>
            </div>
          </div>
          <div className="form-group">
            <label htmlFor="password">Password</label>
            <input
              type="password"
              autoComplete="false"
              className={classnames("form-control", {
                "is-invalid": validation.errors.password
              })}
              placeholder="(must be at least 8 characters)"
              name="password"
              onChange={this.onFieldChange("password")}
              onBlur={this.onFieldValidate.bind(this, "password")}
            />
            <div className="invalid-feedback">{validation.errors.password}</div>
          </div>
          <div className="form-group">
            <label htmlFor="passwordConfirmation">Confirm Password</label>
            <input
              type="password"
              autoComplete="false"
              className={classnames("form-control", {
                "is-invalid": validation.errors.passwordConfirmation
              })}
              name="passwordConfirmation"
              onChange={this.onFieldChange("passwordConfirmation")}
              onBlur={this.onFieldValidate.bind(this, "passwordConfirmation")}
            />
            <div className="invalid-feedback">
              {validation.errors.passwordConfirmation}
            </div>
          </div>
          <label className="checkbox">
            I have read and agree to the <a target="_blank" rel="noopener noreferrer" href="https://go4.io/privacy-policy/">Privacy Policy</a> and <a target="_blank" rel="noopener noreferrer" href="https://go4.io/terms-and-conditions/">Terms and Conditions</a>.
            <input type="checkbox" name="terms" checked={user.terms} onChange={this.onTermsChanged} />
            <span></span>
          </label>
          <button
            type="submit"
            className="btn btn-danger bold-button"
            disabled={!validation.validAccount}
            onClick={this.onSubmit}
          >
            Save and Continue
          </button>
        </form>
      </div>
    );
  }
}

function mapStateToProps(state, ownProps) {
  const error = state.session.error;
  const session = state.session;

  if (!error && session && state.session.authenticated) {
    if (session.currentUser.role === "Parent") {
      ownProps.history.push('/parent/dashboard');
    } else if (session.currentUser.role !== "Patient") {
      ownProps.history.push('/patient/dashboard');
    } else {
      ownProps.history.push('/dashboard');
    }
  }

  return {
    user: state.registration.user,
    validation: state.registration.parentValidation,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    updateField: (field, value) =>
      dispatch(registrationFieldUpdate(field, value)),
    validateField: field => dispatch(parentRegistrationFieldValidate(field)),
    validateInvitationToken: (token, location) => dispatch(validateInvitationToken(token, location)),
    acceptInvitation: (token, history, location) => dispatch(acceptInvitation(token, history, location)),
  };
}

export { Parent };

export default connect(mapStateToProps, mapDispatchToProps)(Parent);
