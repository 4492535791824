// @flow
import type { Address } from 'redux/ducks/helpers';

export default class AddressSerializer {
  static deserialize(address: SerializedAddress): Address {
    const NULL_ADDRESS: SerializedAddress = {
      street_address: '',
      city: '',
      state: '',
      zip_code: '',
    };
    const addressToDeserialize = address || NULL_ADDRESS;
    const result = {
      id: addressToDeserialize.id,
      streetAddress: addressToDeserialize.street_address,
      city: addressToDeserialize.city,
      state: addressToDeserialize.state,
      zipCode: addressToDeserialize.zip_code,
    };

    return result;
  }

  static serialize(address: Address): SerializedAddress {
    return {
      id: address.id,
      street_address: address.streetAddress,
      city: address.city,
      state: address.state,
      zip_code: address.zipCode,
    };
  }
}

export type SerializedAddress = {
  id?: ?number,
  street_address: string,
  city: string,
  state: string,
  zip_code: string,
};
