import React, { Component } from 'react';

import AthleticTrainerModal from 'components/AthleticTrainerModal';
import { formatter } from 'helpers/formatter';
import moment from 'moment';
import { isEmpty } from 'lodash';

class ShiftFinancesJobListItem extends Component {
  formatPaidAt() {
    let result = '';

    if (this.props.job.payment) {
      result = moment(this.props.job.payment.paidAt).format("MM/DD/YYYY");
    }

    return result;
  }

  calculateTimeWorked = () => {
    const start = new Date(this.props.job.startTime);
    const end = new Date(this.props.job.endTime);

    const shiftLength = Math.abs(
      start.getTime() - end.getTime()
    ) / (1000 * 3600);

    const hours = Math.floor(shiftLength);
    const minutes = Math.round((shiftLength % 1) * 60);

    return `${hours}h / ${minutes}m`;
  }

  amountPaid = (payment) => {
    if (!payment) return 0;

    return (Number(payment.atAmountPaid) + Number(payment.atServiceCharge)).toFixed(2);
  }

  render() {
    const { job } = this.props;

    return (
      <div className="shiftFinancesJobListItem">
        <div>
          <img src={job.user.profilePic || require('styles/BlankAvatar.svg')} alt="profile" />
          <AthleticTrainerModal user={job.user} withoutContactInfo={true} />
        </div>
        <div>
          <b>Worked:</b> {this.calculateTimeWorked()}
        </div>
        <div>
          <b>Date Paid:</b> {this.formatPaidAt()}
        </div>
        <div className="col flex-column justify-content-center align-items-start">
          <div>
            <b>Paid:</b> {formatter.format(this.amountPaid(job.payment))}
          </div>
          {!isEmpty(job.jobModification) &&
            <div>
              <b>Modification:</b> {formatter.format(this.amountPaid(job.jobModification.payment))}
            </div>
          }
        </div>
      </div>
    );
  }
}

export default ShiftFinancesJobListItem;