import ApiService from './ApiService';

import { ConfirmationSerializer } from 'services/serializers';

class ConfirmationService extends ApiService {
  constructor(session) {
    super(session);

    this.url = '/confirmation.json';
  }

  resend(email) {
    return super.post(this.url, ConfirmationSerializer.serializeResend(email),
      (response) => ConfirmationSerializer.deserialize(response));
  }

}

export default ConfirmationService;