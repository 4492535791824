import classNames from 'classnames';
import HorizontalRule from 'components/HorizontalRule';
import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Collapse, CardBody, Card, CardHeader } from 'reactstrap';
import { switchLocation } from 'redux/ducks/Post/Navigation';
import { profileValid, scheduleTypes, rateValid } from 'helpers/post';
import { get } from 'lodash';

const LocationNav = (props) => {
  const { no, shiftLocation, oneLocation, missingErrors, editMode, disabledCluster } = props;
  const { locationIdx } = props.navigation;

  const onClick = (step) => () => props.switchStep(step);

  const locationTo = () => `${props.baseUrl}/location`;
  const rateTo = () => `${props.baseUrl}/rate`;
  const suppliesTo = () => `${props.baseUrl}/details/supplies`;
  const detailsTo = () => `${props.baseUrl}/details/details`;
  const credentialsTo = () => `${props.baseUrl}/details/credentials`;
  const eapTo = () => {
    const { eap } = props;
    const form = get(eap, `forms[${eap.currentForm}]`, {});
    if (!form.id || get(form, 'eventLocation.id') !== shiftLocation.id)
      return `${props.baseUrl}/eap/eap-type`;

    return `${props.baseUrl}/eap/review`;
  };

  const scheduleTo = () => {
    const { baseUrl, details, editMode } = props;

    if (editMode) return `${baseUrl}/schedule-summary`;
    if (!details.scheduleType) return `${baseUrl}/schedule/type`;

    const to = details.scheduleType === scheduleTypes.RECURRING
      ? scheduleTypes.ONETIME
      : details.scheduleType;

    return `${baseUrl}/schedule/${to}`;
  };
  const toggle = () => {
    props.switchLocation(no - 1);
    props.switchStep('location');
    props.history.push(locationTo());
  };

  const eapDisabled = () => !props.authenticated || detailsDisabled() || !props.details.id;
  const rateDisabled = () => scheduleDisabled() || props.shifts.length < 1;
  const scheduleDisabled = () => !shiftLocation.placeId && !shiftLocation.id;
  const detailsDisabled = () => rateDisabled() || !rateValid(shiftLocation);
  const credentialsDisabled = () => detailsDisabled();
  const locationDisabled = () => {
    if (!props.authenticated) return !profileValid(props.profile);

    return !(props.details.id || !!shiftLocation.name);
  };

  const hasErrors = (step) => {
    const { wasValidated } = props.validations.post;
    return (wasValidated || editMode) && !get(missingErrors, `[${step}]`);
  };

  return (
    <Card className="location-nav">
      {!oneLocation && (
        <>
          <CardHeader
            className={classNames('', {
              selected: no - 1 === locationIdx,
              disabled: disabledCluster('location', locationDisabled),
            })}
            onClick={() => {
              if (disabledCluster('location', locationDisabled)) return;

              toggle();
              props.switchStep('location');
            }}
          >
            {hasErrors('valid') && <div className="missing-alert">!</div>}
            Location {no}
          </CardHeader>
          <HorizontalRule margin={10} />
        </>
      )}

      <Collapse isOpen={no - 1 === locationIdx}>
        <CardBody>
          <Link
            disabled={disabledCluster('location', locationDisabled)}
            to={locationTo()}
            className={classNames('', { selected: props.isSelected('location') })}
            onClick={onClick('location')}
          >
            {hasErrors('locationValid') && <div className="missing-alert">!</div>}
            Location
          </Link>
          <i className="fas fa-caret-down nav-arrow"></i>
          <Link
            disabled={disabledCluster('schedule', scheduleDisabled)}
            to={scheduleTo()}
            className={classNames('', { selected: props.isSelected('schedule') })}
            onClick={onClick('schedule')}
          >
            {hasErrors('scheduleValid') && <div className="missing-alert">!</div>}
            Schedule
          </Link>
          <i className="fas fa-caret-down nav-arrow"></i>
          <Link
            disabled={disabledCluster('rate', rateDisabled)}
            to={rateTo()}
            className={classNames('', { selected: props.isSelected('rate') })}
            onClick={onClick('rate')}
          >
            {hasErrors('rateValid') && <div className="missing-alert">!</div>}
            Rate
          </Link>
          <i className="fas fa-caret-down nav-arrow"></i>
          <Link
            disabled={disabledCluster('supplies', detailsDisabled)}
            to={suppliesTo()}
            className={classNames('', { selected: props.isSelected('supplies') })}
            onClick={onClick('supplies')}
          >
            Supplies
          </Link>
          <i className="fas fa-caret-down nav-arrow"></i>
          <Link
            disabled={disabledCluster('details', detailsDisabled)}
            to={detailsTo()}
            className={classNames('', { selected: props.isSelected('details') })}
            onClick={onClick('details')}
          >
            Details
          </Link>
          <i className="fas fa-caret-down nav-arrow"></i>
          <Link
            disabled={disabledCluster('credentials', credentialsDisabled)}
            to={credentialsTo()}
            className={classNames('', { selected: props.isSelected('credentials') })}
            onClick={onClick('credentials')}
          >
            Credentials
          </Link>
          <i className="fas fa-caret-down nav-arrow"></i>
          <Link
            disabled={disabledCluster('eap', eapDisabled)}
            to={eapTo()}
            className={classNames('', { selected: props.isSelected('eap') })}
            onClick={onClick('eap')}
          >
            {hasErrors('eapValid') && <div className="missing-alert">!</div>}
            EAP
          </Link>
          {!oneLocation && <HorizontalRule margin={10} />}
        </CardBody>
      </Collapse>
    </Card>
  );
};

const mapDispatchToProps = (dispatch) => ({
  switchLocation: (idx) => dispatch(switchLocation({ locationIdx: idx })),
});

export default connect(null, mapDispatchToProps)(LocationNav);
