import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import NavLink from 'components/V2/NavLink';
import { connect } from 'react-redux';
import { clearV2Form } from 'redux/ducks/Post/actions';

class SideNavBar extends Component {
  render() {
    return (
      <nav className="sideNavBar">
        {this.props.user.role === 'Event operator' && (
          <div className="create-job">
            <Link
              className="btn btn-primary"
              to="/v2/create-event/setting"
              onClick={() => {
                this.props.clearV2Form();
              }}
            >
              Create New Job
            </Link>
          </div>
        )}
        <ul
          className={
            this.props.authenticated ? 'links' : 'links unauthed-links'
          }
        >
          {this.props.links &&
            this.props.links.map((link, index) => {
              return <NavLink key={index} link={link} />;
            })}
        </ul>
      </nav>
    );
  }
}

NavLink.contextTypes = {
  currentUser: PropTypes.object,
  location: PropTypes.object,
  router: PropTypes.object,
};

const mapStateToProps = (state) => ({
  user: state.session.currentUser,
});

const mapDispatchToProps = (dispatch) => ({
  clearV2Form: () => dispatch(clearV2Form()),
});

export default connect(mapStateToProps, mapDispatchToProps)(SideNavBar);
