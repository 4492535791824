import React from 'react';

const ExistingBankAccount = ({ account }) => {
  if (!account || !account.id || account.object !== 'bank_account') return null;

  return (
    <div>
      <div className="align-items-center bank-details">
        Account Number: **** **** **** {account.last4}
      </div>
      <div className="align-items-center bank-details">Routing Number: {account.routingNumber}</div>
    </div>
  );
};

export default ExistingBankAccount;
