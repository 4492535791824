import { ofType } from 'redux-observable';

import { of } from 'rxjs/observable/of';
import { catchError, mergeMap, map, debounceTime } from 'rxjs/operators';

import { mappingEpic } from "redux/ducks/helpers";
import { CredentialsService } from 'services';

import {
  DOWNLOAD_ALL_CREDENTIALS,
  DOWNLOAD_ALL_CREDENTIALS_SUCCESS,
  DOWNLOAD_ALL_CREDENTIALS_ERROR,
} from './index';
import { SHOW_SPINNER, HIDE_SPINNER } from "redux/ducks/LoadingSpinner";

// Epics
// ========================================================
export function downloadAllCredentials(action$, store) {
  return action$.pipe(
    ofType(DOWNLOAD_ALL_CREDENTIALS),
    debounceTime(200),
    mergeMap(action => new CredentialsService(store.getState().session).downloadAll(action.payload)),
    map((credentials) => {
      const blob = credentials.pdf;
      const filename = `credentials_${Date.now()}.pdf`;
      if (window.navigator.msSaveOrOpenBlob) {
        window.navigator.msSaveBlob(blob, filename);
      } else {
        var elem = window.document.createElement('a');
        elem.href = window.URL.createObjectURL(blob);
        elem.download = filename;
        elem.target = 'blank';
        document.body.appendChild(elem);
        elem.click();
        document.body.removeChild(elem);
      }

      return { type: DOWNLOAD_ALL_CREDENTIALS_SUCCESS, payload: credentials };
    }),
    catchError((error) => {
      return of({
        type: DOWNLOAD_ALL_CREDENTIALS_ERROR,
        payload: error,
      });
    })
  )
}

export const showSpinnerEpic = mappingEpic(
  [
    DOWNLOAD_ALL_CREDENTIALS
  ],
  SHOW_SPINNER
);

export const hideSpinnerEpic = mappingEpic(
  [
    DOWNLOAD_ALL_CREDENTIALS_SUCCESS,
    DOWNLOAD_ALL_CREDENTIALS_ERROR,
  ],
  HIDE_SPINNER
);
