import React, { useState } from "react";
import { Link, withRouter } from "react-router-dom";
import stateList from "../../statesTitle.json";

const MobileStateCityList = ({ location }) => {
  const [list, setList] = useState(stateList);
  const handleClick = name => {
    const updated = list.map(s => {
      if (name === s.name) {
        return {
          ...s,
          open: !s.open
        };
      }
      return s;
    });
    setList(updated);
  };
  return (
    <div className="mobileStateCityList">
      {list.map(s => (
        <div key={s.name} className={`stateExpansionPanel`}>
          <div className="panelTop" onClick={() => handleClick(s.name)}>
            <h3 className="stateName">{s.name}</h3>
            <i className={`fas ${!s.open ? "fa-angle-down" : "fa-angle-up"}`} />
          </div>
          <ul className={!!s.open ? "open" : ""}>
            {s.cities.map(city => (
              <li key={city}>
                <Link to={`${location.pathname}/${s.abbreviation}/${city}`}>
                  {city}
                </Link>
              </li>
            ))}
          </ul>
        </div>
      ))}
    </div>
  );
};

export default withRouter(MobileStateCityList);
