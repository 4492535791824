import React, { Component } from 'react';
import { connect } from 'react-redux';
import InputOrFlatpickr from 'components/InputOrFlatpickr';
import classnames from 'classnames';
import moment from 'moment-timezone';
import zipcode_to_timezone from 'zipcode-to-timezone';
import { rateTypeNameFinder } from 'helpers/rateTypeName';

import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';

import { updateJob } from '../../redux/ducks/Job';

class EditJobModal extends Component {
  state = {
    open: false,
    wasValidated: true,
    startTime: this.props.job.startTime,
    endTime: this.props.job.endTime,
    rateTypeId: this.props.job.rateTypeId,
    payRate: this.props.job.payRate,
  };

  onConfirm = () => {
    this.props.updateJob({
      ...this.props.job,
      startTime: this.state.startTime,
      endTime: this.state.endTime,
      rateTypeId: this.state.rateTypeId,
      payRate: this.state.payRate,
    });
    this.toggle();
  };

  toggle = () => {
    this.setState({ open: !this.state.open });
  };

  showError = (fieldName: string) => {
    const { job, wasValidated } = this.props;
    return wasValidated && !!job.errorMessages[fieldName];
  };

  render() {
    const { shift, job, rateTypes } = this.props;
    const tz = zipcode_to_timezone.lookup(shift.eventLocation.address.zipCode) || moment.tz.guess();

    return (
      <div className="editJobModal">
        <button className="modal-button plain-button" onClick={this.toggle}>
          {this.props.children}
        </button>
        <Modal isOpen={this.state.open} toggle={this.toggle} className="edit-job-modal-content">
          <ModalHeader toggle={this.toggle}>{'Edit Job'}</ModalHeader>
          <ModalBody>
            <div className="form-group col-md">
              <label htmlFor="startTime">Start Time</label>
              <div className="input-group">
                <InputOrFlatpickr
                  type="datetime"
                  name="startTime"
                  value={moment.tz(job.startTime, tz).format()}
                  options={dateTimePickerOptions}
                  onChange={(value) => {
                    if (value) {
                      this.setState({
                        startTime: moment.tz(value, tz).format(),
                      });
                    }
                  }}
                  className={classnames('form-control', {
                    'is-invalid': this.showError('startTime'),
                  })}
                  placeholder="Pick start time"
                />
                <div className="input-group-append">
                  <span className="input-group-text">
                    <i className="far fa-calendar-alt"></i>
                  </span>
                </div>
              </div>
            </div>
            <div className="form-group col-md">
              <label htmlFor="endTime">End Time</label>
              <div className="input-group">
                <InputOrFlatpickr
                  name="endTime"
                  value={moment.tz(job.endTime, tz).format()}
                  options={dateTimePickerOptions}
                  type="datetime"
                  onChange={(value) => {
                    if (value) {
                      this.setState({
                        endTime: moment.tz(value, tz).format(),
                      });
                    }
                  }}
                  className={classnames('form-control', {
                    'is-invalid': this.showError('endTime'),
                  })}
                  placeholder="Pick end time"
                />
                <div className="input-group-append">
                  <span className="input-group-text">
                    <i className="far fa-clock"></i>
                  </span>
                </div>
              </div>
            </div>
            <div className="form-group col-md">
              <div>
                <label htmlFor="rate_type">Rate Type</label>
              </div>
              <div className="custom-control custom-radio custom-control-inline">
                <input
                  className="custom-control-input"
                  id="HourlyRateType"
                  type="radio"
                  name="rate_type"
                  checked={this.state.rateTypeId == '0'}
                  value="0"
                  onChange={(ev) => this.setState({ rateTypeId: ev.target.value })}
                />
                <label htmlFor="HourlyRateType" className="custom-control-label radio-inline">
                  Hourly Rate
                </label>
              </div>
              <div className="custom-control custom-radio custom-control-inline">
                <input
                  className="custom-control-input"
                  id="rateType"
                  type="radio"
                  name="rate_type"
                  checked={this.state.rateTypeId == '1'}
                  value="1"
                  onChange={(ev) => this.setState({ rateTypeId: ev.target.value })}
                  // TOOD(cosmin): Add location changes
                />
                <label htmlFor="rateType" className="custom-control-label radio-inline">
                  Fixed Rate
                </label>
              </div>
            </div>
            <div className="form-group col-md">
              <label htmlFor="pay_rate">Pay Rate</label>
              <div className="input-group mb-3">
                <div className="input-group-prepend">
                  <span className="input-group-text">$</span>
                </div>
                <input
                  className="form-control"
                  type="number"
                  name="pay_rate"
                  min="0"
                  max="100000"
                  aria-label="Amount (to the nearest dollar)"
                  placeholder="0"
                  value={this.state.payRate}
                  onChange={(ev) => this.setState({ payRate: ev.target.value })}
                />
                <div className="input-group-append">
                  <span className="input-group-text">
                    {rateTypeNameFinder(rateTypes, this.state.rateTypeId)}
                  </span>
                </div>
                <div className="invalid-feedback">
                  Pay rate is required to be between $0-$100,000
                </div>
              </div>
            </div>
          </ModalBody>
          <ModalFooter>
            <a onClick={this.toggle}>Cancel</a>
            <button
              className="btn btn-danger"
              onClick={this.onConfirm}
              // TOOD(cosmin): Add proper validation
              disabled={moment.tz(this.state.startTime, tz) >= moment.tz(this.state.endTime, tz)}
            >
              Confirm
            </button>
          </ModalFooter>
        </Modal>
      </div>
    );
  }
}

const dateTimePickerOptions = {
  enableTime: true,
  dateFormat: 'Y-m-d H:i',
  altFormat: 'm/d/y h:i K',
  altInput: true,
};

function mapStateToProps(state) {
  return {
    rateTypes: state.enums.rateTypes,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    updateJob: (job) => dispatch(updateJob(job)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(EditJobModal);
